import {CustomTimescale} from '../../overall-health';
import {Dimension} from '../../../../../../interfaces/dimension';
import {FunctionComponent} from 'react';
import {TIMESCALE_VALUES} from '../../../../../../constants/timescale-value';
import {useTranslation} from 'react-i18next';
import Dimensions from './@components/dimensions';
import styles from './styles.module.scss';
import Timescale from './@components/timescale';

interface ElementProps {
  dimensions: Dimension[];
  endDateInQueryParams?: string | null;
  onCustomTimescaleSelected: (dates: CustomTimescale) => void;
  onDimensionsChange: (dimensions: Dimension) => void;
  onTimescaleChange: (selectedTimescale: TIMESCALE_VALUES) => void;
  selectedDimensions: Dimension[];
  selectedTimescale?: TIMESCALE_VALUES;
  startDateInQueryParams?: string | null;
}

const Filters: FunctionComponent<ElementProps> = ({
  dimensions,
  endDateInQueryParams,
  onCustomTimescaleSelected,
  onDimensionsChange,
  onTimescaleChange,
  selectedDimensions,
  selectedTimescale,
  startDateInQueryParams,
}) => {
  const {t} = useTranslation('measure');

  return (
    <div className={styles.container}>
      <div className={styles.column}>
        <div className={styles.title}>
          {t('overall-health.components.filters.title')}
        </div>
      </div>

      <div className={styles.column}>
        <Dimensions
          dimensions={dimensions}
          selectedDimensions={selectedDimensions}
          onDimensionsChange={onDimensionsChange}
        />

        <Timescale
          endDateInQueryParams={endDateInQueryParams}
          onCustomTimescaleSelected={onCustomTimescaleSelected}
          onTimescaleChange={onTimescaleChange}
          selectedTimescale={selectedTimescale}
          startDateInQueryParams={startDateInQueryParams}
        />
      </div>
    </div>
  );
};

export default Filters;
