import {Field, Form, Formik} from 'formik';
import {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import AutoresizeTextarea from '../../form/autoresize-textarea/autoresize-textarea';
import Button from '../../form/button/button';
import FormControl from '../../form/form-control';
import PreviousQuestionLink from '../@components/previous-question-link';
import styles from './styles.module.scss';

interface ElementProps {
  questionText: string;
  onResponse: (value: string) => void;
  onPreviousLinkClick?: () => void;
  answer?: string;
}

interface FormValues {
  answer: string;
}

const OpenEndedText: FunctionComponent<ElementProps> = ({
  questionText,
  onResponse,
  onPreviousLinkClick,
  answer,
}) => {
  const {t} = useTranslation('components');

  const INITIAL_VALUES = {
    answer: answer || '',
  };

  const validationSchema = Yup.object().shape({
    answer: Yup.string()
      .required(t('questionnaire.open-ended-text.form-error-required'))
      .min(50, t('questionnaire.open-ended-text.form-error-minimum'))
      .max(1000, t('questionnaire.open-ended-text.form-error-maximum')),
  });

  const onSubmit = (values: FormValues) => {
    onResponse(values.answer);
  };

  return (
    <>
      <div className={styles.container}>
        {onPreviousLinkClick ? (
          <PreviousQuestionLink onLinkClick={onPreviousLinkClick} />
        ) : null}

        <div className={styles.question}>{questionText}</div>

        <Formik
          initialValues={INITIAL_VALUES}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({errors, touched, isValid}) => (
            <Form>
              <div className={styles.textareaContainer}>
                <FormControl
                  error={
                    Boolean(errors.answer && touched.answer)
                      ? errors.answer
                      : undefined
                  }
                >
                  <Field
                    as={AutoresizeTextarea}
                    name="answer"
                    rows={5}
                    placeholder={t(
                      'questionnaire.open-ended-text.textarea-placeholder'
                    )}
                  />
                </FormControl>
              </div>

              <Button type="submit" variant="secondary" disabled={!isValid}>
                {t('questionnaire.open-ended-text.next-button')}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default OpenEndedText;
