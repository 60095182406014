import {FunctionComponent} from 'react';
import {Navigate, Route, Routes} from 'react-router';
import MeetingsIndex from './index/meetings-index';
import MeetingsShow from './show/meetings-show';

const Meetings: FunctionComponent = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<MeetingsIndex />} />

        <Route path=":meetingId/*" element={<MeetingsShow />} />

        <Route
          path="*"
          element={<Navigate replace to="/application/dashboard" />}
        />
      </Routes>
    </>
  );
};

export default Meetings;
