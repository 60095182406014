import axios from 'axios';
import NProgress from 'nprogress';

export type InstillAPIError = {payload: any};

const InstillAPI = axios.create({
  baseURL: window.env.REACT_APP_INSTILL_API_BASE_URL,
});

NProgress.configure({showSpinner: false});

InstillAPI.interceptors.request.use((config) => {
  NProgress.start();
  return config;
});

InstillAPI.interceptors.response.use((response) => {
  NProgress.done();
  return response;
});

export function setAuthorizationToken(token: string) {
  InstillAPI.defaults.headers.common['Authorization'] = `bearer ${token}`;
}

export function revokeAuthorizationToken() {
  delete InstillAPI.defaults.headers.common['Authorization'];
}

export default InstillAPI;
