import {Dimension} from '../../../../../../../../interfaces/dimension';
import {FunctionComponent, useCallback, useState} from 'react';
import {ReactComponent as IconChevron} from '../../../../../../../@components/kit/icons/chevron.svg';
import {useTransition, animated} from 'react-spring';
import {useTranslation} from 'react-i18next';
import List from './@components/list/list';
import styles from './styles.module.scss';
import useOnClickOutside from '../../../../../../../@hooks/use-on-click-outside';

interface ElementProps {
  dimensions: Dimension[];
  onDimensionsChange: (dimension: Dimension) => void;
  selectedDimensions?: Dimension[];
}

const Dimensions: FunctionComponent<ElementProps> = ({
  dimensions,
  onDimensionsChange,
  selectedDimensions,
}) => {
  const {t} = useTranslation('measure');

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [container, setContainer] = useState<HTMLElement | null>(null);

  const onToggleButonClicked = useCallback(() => {
    setIsDropdownVisible(!isDropdownVisible);
  }, [isDropdownVisible]);

  const onCloseDimensionDropdown = useCallback(() => {
    setIsDropdownVisible(false);
  }, []);

  useOnClickOutside(container, onCloseDimensionDropdown);

  const transitions = useTransition(isDropdownVisible, {
    from: {
      opacity: 0,
      y: 50,
    },
    enter: {
      opacity: 1,
      y: 0,
    },
    leave: {
      opacity: 0,
      y: 50,
    },
  });

  return (
    <div className={styles.container} ref={setContainer}>
      <button
        type="button"
        className={
          isDropdownVisible
            ? `${styles.button} ${styles.isOpen}`
            : styles.button
        }
        onClick={onToggleButonClicked}
      >
        {t(
          'overall-health.components.filters.components.dimensions.button-label',
          {count: selectedDimensions!.length}
        )}

        <IconChevron className={styles.buttonIcon} />
      </button>

      {transitions(
        (props, item) =>
          item && (
            <animated.div className={styles.dropdownContainer} style={props}>
              <List
                dimensions={dimensions}
                onDimensionsChange={onDimensionsChange}
                selectedDimensions={selectedDimensions}
              />
            </animated.div>
          )
      )}
    </div>
  );
};

export default Dimensions;
