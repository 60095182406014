import {FunctionComponent} from 'react';
import {JobPosition} from '../../../../../../../../interfaces/job-position';
import styles from './styles.module.scss';
import Item from './@components/item/item';

interface ElementProps {
  jobPositions: JobPosition[];
  onJobPositionClicked: (jobPosition: JobPosition) => void;
}

const List: FunctionComponent<ElementProps> = ({
  jobPositions,
  onJobPositionClicked,
}) => {
  return (
    <ul className={styles.list}>
      {jobPositions.map((jobPosition, index) => (
        <li className={styles.item} key={index}>
          <Item
            jobPosition={jobPosition}
            onJobPositionClicked={onJobPositionClicked}
          />
        </li>
      ))}
    </ul>
  );
};

export default List;
