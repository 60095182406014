import {
  JobPosition,
  JobPositionCreate,
  JobPositionCreateRaw,
  JobPositionRaw,
  JobPositionUpdate,
  JobPositionUpdateRaw,
} from '../../interfaces/job-position';

export const sanitizeJobPositionsFromRaw = (
  data: JobPositionRaw[]
): JobPosition[] => {
  return data.map((jobPosition) => sanitizeJobPositionFromRaw(jobPosition));
};

export const sanitizeJobPositionFromRaw = (
  data: JobPositionRaw
): JobPosition => {
  return {
    createdAt: data.created_at,
    isActive: data.is_active,
    title: data.title,
    uuid: data.uuid,
  };
};

export const sanitizeJobPositionCreateForRaw = (
  data: JobPositionCreate
): JobPositionCreateRaw => {
  return {
    title: data.title,
  };
};

export const sanitizeJobPositionUpdateForRaw = (
  data: JobPositionUpdate
): JobPositionUpdateRaw => {
  return {
    title: data.title,
    is_active: data.isActive,
  };
};
