import {Field, FieldProps} from 'formik';
import {FunctionComponent} from 'react';
import {ReactComponent as IconChat} from '../../../../../../../@components/kit/icons/message-circle.svg';
import {ReactComponent as IconMail} from '../../../../../../../@components/kit/icons/mail.svg';
import {ReactComponent as IconPhone} from '../../../../../../../@components/kit/icons/phone.svg';
import {ReactComponent as IconSlack} from '../../../../../../../@components/kit/icons/slack.svg';
import {FullUserProfile} from '../../../../../../../../interfaces/user-profile';
import {useTranslation} from 'react-i18next';
import {WORK_STYLE_TRANSLATION_MAP} from '../../../../../../../../constants/work-style-preferences';
import {WorkStylePreferencesValues} from '../../../../../../../../interfaces/work-style-preferences';
import Label from '../../../../../../../@components/kit/form/label';
import profileStyles from '../../../../styles.module.scss';
import Select from '../../../../../../../@components/kit/form/select';
import styles from './styles.module.scss';
import Tag from '../../../../../../../@components/tag';

interface ElementProps {
  isInEditMode: boolean;
  profile: FullUserProfile;
  workStyleValues?: WorkStylePreferencesValues;
}

const EditAverageResponseTimes: FunctionComponent<{
  profile: FullUserProfile;
  workStyleValues?: WorkStylePreferencesValues;
}> = ({profile, workStyleValues}) => {
  const {t} = useTranslation('common');

  return (
    <ul className={styles.editionList}>
      <li className={styles.editionListItem}>
        <Field name="workStylePreferences.averageResponseTimes.phone">
          {({field}: FieldProps<string>) => (
            <>
              <Label htmlFor={field.name}>
                {t(
                  'constants.work-style-preferences.keys.average-response-times.phone.label'
                )}
              </Label>

              <Select id={field.name} {...field}>
                <option value="" disabled>
                  {t(
                    'constants.work-style-preferences.keys.average-response-times.phone.placeholder'
                  )}
                </option>

                {workStyleValues?.averageResponseTimes.map((value) => {
                  return (
                    <option key={value} value={value}>
                      {t(
                        WORK_STYLE_TRANSLATION_MAP[
                          value as keyof typeof WORK_STYLE_TRANSLATION_MAP
                        ],
                        {ns: 'common'}
                      )}
                    </option>
                  );
                })}
              </Select>
            </>
          )}
        </Field>
      </li>

      <li className={styles.editionListItem}>
        <Field name="workStylePreferences.averageResponseTimes.textMessage">
          {({field}: FieldProps<string>) => (
            <>
              <Label htmlFor={field.name}>
                {t(
                  'constants.work-style-preferences.keys.average-response-times.text-message.label'
                )}
              </Label>

              <Select id={field.name} {...field}>
                <option value="" disabled>
                  {t(
                    'constants.work-style-preferences.keys.average-response-times.text-message.placeholder'
                  )}
                </option>

                {workStyleValues?.averageResponseTimes.map((value) => {
                  return (
                    <option key={value} value={value}>
                      {t(
                        WORK_STYLE_TRANSLATION_MAP[
                          value as keyof typeof WORK_STYLE_TRANSLATION_MAP
                        ],
                        {ns: 'common'}
                      )}
                    </option>
                  );
                })}
              </Select>
            </>
          )}
        </Field>
      </li>

      <li className={styles.editionListItem}>
        <Field name="workStylePreferences.averageResponseTimes.email">
          {({field}: FieldProps<string>) => (
            <>
              <Label htmlFor={field.name}>
                {t(
                  'constants.work-style-preferences.keys.average-response-times.email.label'
                )}
              </Label>

              <Select id={field.name} {...field}>
                <option value="" disabled>
                  {t(
                    'constants.work-style-preferences.keys.average-response-times.email.placeholder'
                  )}
                </option>

                {workStyleValues?.averageResponseTimes.map((value) => {
                  return (
                    <option key={value} value={value}>
                      {t(
                        WORK_STYLE_TRANSLATION_MAP[
                          value as keyof typeof WORK_STYLE_TRANSLATION_MAP
                        ],
                        {ns: 'common'}
                      )}
                    </option>
                  );
                })}
              </Select>
            </>
          )}
        </Field>
      </li>

      <li className={styles.editionListItem}>
        <Field name="workStylePreferences.averageResponseTimes.chat">
          {({field}: FieldProps<string>) => (
            <>
              <Label htmlFor={field.name}>
                {t(
                  'constants.work-style-preferences.keys.average-response-times.chat.label'
                )}
              </Label>

              <Select id={field.name} {...field}>
                <option value="" disabled>
                  {t(
                    'constants.work-style-preferences.keys.average-response-times.chat.placeholder'
                  )}
                </option>

                {workStyleValues?.averageResponseTimes.map((value) => {
                  return (
                    <option key={value} value={value}>
                      {t(
                        WORK_STYLE_TRANSLATION_MAP[
                          value as keyof typeof WORK_STYLE_TRANSLATION_MAP
                        ],
                        {ns: 'common'}
                      )}
                    </option>
                  );
                })}
              </Select>
            </>
          )}
        </Field>
      </li>
    </ul>
  );
};

const ReadOnlyAverageResponseTimes: FunctionComponent<{
  profile: FullUserProfile;
}> = ({profile}) => {
  const {t} = useTranslation();
  const {workStylePreferences} = profile;

  return (
    <ul className={profileStyles.boxItemList}>
      {workStylePreferences?.averageResponseTimes?.email && (
        <li className={profileStyles.boxItemSublistItem}>
          <Tag>
            <IconMail className={profileStyles.boxItemIcon} />
            {t(
              WORK_STYLE_TRANSLATION_MAP[
                workStylePreferences?.averageResponseTimes
                  ?.email as keyof typeof WORK_STYLE_TRANSLATION_MAP
              ],
              {ns: 'common'}
            )}
          </Tag>
        </li>
      )}

      {workStylePreferences?.averageResponseTimes?.chat && (
        <li className={profileStyles.boxItemSublistItem}>
          <Tag>
            <IconSlack className={profileStyles.boxItemIcon} />
            {t(
              WORK_STYLE_TRANSLATION_MAP[
                workStylePreferences?.averageResponseTimes
                  ?.chat as keyof typeof WORK_STYLE_TRANSLATION_MAP
              ],
              {ns: 'common'}
            )}
          </Tag>
        </li>
      )}

      {workStylePreferences?.averageResponseTimes?.textMessage && (
        <li className={profileStyles.boxItemSublistItem}>
          <Tag>
            <IconChat className={profileStyles.boxItemIcon} />
            {t(
              WORK_STYLE_TRANSLATION_MAP[
                workStylePreferences?.averageResponseTimes
                  ?.textMessage as keyof typeof WORK_STYLE_TRANSLATION_MAP
              ],
              {ns: 'common'}
            )}
          </Tag>
        </li>
      )}

      {workStylePreferences?.averageResponseTimes?.phone && (
        <li className={profileStyles.boxItemSublistItem}>
          <Tag>
            <IconPhone className={profileStyles.boxItemIcon} />
            {t(
              WORK_STYLE_TRANSLATION_MAP[
                workStylePreferences?.averageResponseTimes
                  ?.phone as keyof typeof WORK_STYLE_TRANSLATION_MAP
              ],
              {ns: 'common'}
            )}
          </Tag>
        </li>
      )}
    </ul>
  );
};

const AverageResponseTimes: FunctionComponent<ElementProps> = ({
  isInEditMode,
  profile,
  workStyleValues,
}) => {
  if (isInEditMode) {
    return (
      <EditAverageResponseTimes
        profile={profile}
        workStyleValues={workStyleValues}
      />
    );
  }

  return <ReadOnlyAverageResponseTimes profile={profile} />;
};

export default AverageResponseTimes;
