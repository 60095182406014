import {ChangeEvent, FunctionComponent} from 'react';
import {RadioGroupOption} from '../../radio-group';
import {ReactComponent as IconCheck} from '../../../../../../@components/kit/icons/check.svg';
import styles from './styles.module.scss';

interface ElementProps {
  hasError: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  option: RadioGroupOption;
  selectedValue: string;
}

const Item: FunctionComponent<ElementProps> = ({
  hasError,
  onChange,
  option,
  selectedValue,
  ...props
}) => {
  let classes = styles.container;

  if (hasError) {
    classes = `${classes} ${styles.error}`;
  }

  return (
    <div className={classes}>
      <input
        checked={selectedValue === option.value}
        className={styles.input}
        id={option.label}
        onChange={onChange}
        type="radio"
        value={option.value}
        {...props}
      />

      <label className={styles.label} htmlFor={option.label}>
        <div className={styles.iconContainer}>
          <IconCheck className={styles.icon} />
        </div>

        <div className={styles.labelText}>{option.label}</div>
      </label>
    </div>
  );
};

export default Item;
