import {FunctionComponent, PropsWithChildren} from 'react';
import isBrowser from '../../../utils/is-browser';
import ReactDOM from 'react-dom';

const Portal: FunctionComponent<PropsWithChildren<{}>> = ({children}) => {
  if (!isBrowser()) return null;

  return ReactDOM.createPortal(children, document.body);
};

export default Portal;
