import InstillAPI from '../axios-instance';

export interface SendReminderEmailPayload {
  workEmail: string;
  companyUuid: string;
}

export async function sendReminderEmail(
  companyUuid: string,
  workEmail: string
) {
  try {
    const sanitizedPayload = {
      work_email: workEmail,
    };

    const response = await InstillAPI.post(
      `/companies/${companyUuid}/user-invites/remind-user`,
      sanitizedPayload
    );

    return response;
  } catch (error) {
    throw new Error(`Send Reminder Email Error: ${error as string}`);
  }
}
