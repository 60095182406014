import {CoreValueForm} from '../../../../../../interfaces/company-core-value';
import {Form, Formik} from 'formik';
import {FunctionComponent, useCallback} from 'react';
import {ReactComponent as IconSave} from '../../../../../@components/kit/icons/save.svg';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import Button from '../../../../../@components/kit/form/button';
import Dialog from '../../../../../@components/kit/dialog';
import MediaForm from '../media-form';
import styles from './styles.module.scss';
import ValueForm from '../value-form';

interface ElementProps {
  isDialogVisible: boolean;
  onAddValueConfirm: (value: CoreValueForm[]) => void;
  onClose: () => void;
  prefixEventName?: string;
}

export interface Errors {
  [key: string]: string;
}

export interface AddCoreValueFormSchema {
  coreValue: CoreValueForm;
}

const INITIAL_VALUES = {
  coreValue: {
    name: '',
    description: '',
    expectations: {
      exceeded: [],
      met: [],
      missed: [],
    },
    media: [],
  },
};

const AddCoreValueDialog: FunctionComponent<ElementProps> = ({
  isDialogVisible,
  onAddValueConfirm,
  onClose,
  prefixEventName,
}) => {
  const {t} = useTranslation('dashboard');

  const validationSchema = Yup.object().shape({
    coreValue: Yup.object().shape({
      name: Yup.string().required(
        t(
          'components.interactive-values.components.add-core-value-dialog.form.name.error'
        )
      ),
      description: Yup.string().required(
        t(
          'components.interactive-values.components.add-core-value-dialog.form.description.error'
        )
      ),
    }),
  });

  const onCloseDialog = useCallback(
    (resetForm: () => void) => {
      resetForm();
      onClose();
    },
    [onClose]
  );

  return (
    <>
      <Formik
        initialValues={INITIAL_VALUES}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={(values, {resetForm}) => {
          onAddValueConfirm([values.coreValue]);
          resetForm();
        }}
      >
        {({values, errors, touched, isValid, resetForm}) => (
          <Dialog
            variant="medium"
            isOpen={isDialogVisible}
            onClose={() => onCloseDialog(resetForm)}
          >
            <Form>
              <Dialog.Header
                title={t(
                  'components.interactive-values.components.add-core-value-dialog.title'
                )}
              />

              <Dialog.Content>
                <ValueForm
                  formValues={values.coreValue}
                  errors={errors}
                  touched={touched}
                />

                <MediaForm
                  formValues={values.coreValue}
                  prefixEventName={prefixEventName}
                />

                <Button
                  type="submit"
                  disabled={!isValid}
                  aria-disabled={!isValid}
                >
                  {t(
                    'components.interactive-values.components.add-core-value-dialog.confirm-button'
                  )}
                  <IconSave className={styles.icon} />
                </Button>
              </Dialog.Content>
            </Form>
          </Dialog>
        )}
      </Formik>
    </>
  );
};

export default AddCoreValueDialog;
