import {Actions} from '../../../../constants/permissions/actions';
import {Field, FieldProps, Form, Formik} from 'formik';
import {FunctionComponent, useState} from 'react';
import {getUserProfileForCompany} from '../../../../services/user-profiles';
import {Roles} from '../../../../constants/roles';
import {useCurrentAuthUserState} from '../../../@atoms/current-auth-user';
import {useSafeCurrentCompany} from '../../../@atoms/current-company';
import {useTranslation} from 'react-i18next';
import {useUserPermission} from '../../../@hooks/use-user-permission';
import * as Yup from 'yup';
import Button from '../../../@components/kit/form/button';
import FormFooter from '../../../@components/kit/form/form-footer';
import headingStyles from '../../../../styles/classes/heading.module.scss';
import RoleItem from './@components/role-item';
import styles from './styles.module.scss';

type RoleValues = Record<Roles, string>;

export interface ElementProps {
  onSubmit: (values: RoleValues) => void;
  formInitialValues: RoleValues;
  onPermissionsButtonClick?: (isOpen: boolean) => void;
  onSecondaryButtonClick?: () => void;
}

const InviteUsers: FunctionComponent<ElementProps> = ({
  onSubmit,
  formInitialValues,
  onPermissionsButtonClick,
  onSecondaryButtonClick,
}) => {
  const {t} = useTranslation('components');
  const [isFormEmpty, setIsFormEmpty] = useState(true);

  const [currentAuthUser] = useCurrentAuthUserState();
  const currentCompany = useSafeCurrentCompany();

  const userProfile = getUserProfileForCompany(
    currentAuthUser!,
    currentCompany
  );
  const can = useUserPermission(userProfile.role);

  const validateEmails = (emails: string | undefined) => {
    if (!emails) return true;

    const invitedEmails = emails.split(',');
    const trimmedEmails = invitedEmails.map((email) => email.trim());

    const emailListSchema = Yup.array().of(Yup.string().email());

    return emailListSchema.isValidSync(trimmedEmails);
  };

  const validationSchema = Yup.object().shape({
    owner: Yup.string().test('owner', '', (value) => validateEmails(value)),
    admin: Yup.string().test('admin', '', (value) => validateEmails(value)),
    manager: Yup.string().test('manager', '', (value) => validateEmails(value)),
    member: Yup.string().test('member', '', (value) => validateEmails(value)),
  });

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnMount
    >
      {({isValid}) => (
        <Form className={styles.form} onChange={() => setIsFormEmpty(false)}>
          <header className={styles.formHeader}>
            <div className={headingStyles.h5}>
              {t('invite-users.form.instructions')}
            </div>

            {onPermissionsButtonClick && (
              <div>
                <button
                  onClick={() => onPermissionsButtonClick(true)}
                  type="button"
                  className={styles.dialogButton}
                >
                  {t('invite-users.form.permissions.toggle-button')}
                </button>
              </div>
            )}
          </header>

          <div className={styles.formContent}>
            <ul>
              <li className={styles.roleItem}>
                <Field name="owner">
                  {({field}: FieldProps<string>) => (
                    <RoleItem
                      label={t('invite-users.form.roles.tags.owners')}
                      isDisabled={!can(Actions.INVITE_OWNER)}
                      id={field.name}
                      {...field}
                    />
                  )}
                </Field>
              </li>

              <li className={styles.roleItem}>
                <Field name="admin">
                  {({field}: FieldProps<string>) => (
                    <RoleItem
                      label={t('invite-users.form.roles.tags.admins')}
                      isDisabled={!can(Actions.INVITE_ADMIN)}
                      {...field}
                    />
                  )}
                </Field>
              </li>

              <li className={styles.roleItem}>
                <Field name="manager">
                  {({field}: FieldProps<string>) => (
                    <RoleItem
                      label={t('invite-users.form.roles.tags.managers')}
                      {...field}
                    />
                  )}
                </Field>
              </li>

              <li className={styles.roleItem}>
                <Field name="member">
                  {({field}: FieldProps<string>) => (
                    <RoleItem
                      label={t('invite-users.form.roles.tags.members')}
                      {...field}
                    />
                  )}
                </Field>
              </li>
            </ul>
          </div>

          <FormFooter>
            <Button type="submit" disabled={!isValid || isFormEmpty}>
              {t('invite-users.form.submit-button')}
            </Button>

            {onSecondaryButtonClick && (
              <Button onClick={onSecondaryButtonClick} variant="secondary">
                {t('invite-users.form.skip-button')}
              </Button>
            )}
          </FormFooter>
        </Form>
      )}
    </Formik>
  );
};

export default InviteUsers;
