import {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import {Module} from '../../../../../interfaces/module';
import Tag from '../../../../@components/tag';
import NoteItem from './@components/note-item';
import styles from './styles.module.scss';

interface ElementProps {
  module: Module;
  totalModules: number;
}

const AssessmentDetailsSummary: FunctionComponent<ElementProps> = ({
  module,
  totalModules,
}) => {
  const {t} = useTranslation('assessments');

  return (
    <div className={styles.summary}>
      <h1 className={styles.title}>{module.title}</h1>

      <ul className={styles.tagList}>
        <li className={styles.tagListItem}>
          <Tag variant="light">{module.name}</Tag>
        </li>

        <li className={styles.tagListItem}>
          <Tag variant="light">
            {t('components.details-summary.tags.module-text', {
              current: module.rank,
              total: totalModules,
            })}
          </Tag>
        </li>

        <li className={styles.tagListItem}>
          {module.estimatedDuration !== null && (
            <Tag variant="light">
              {module.estimatedDuration > 1
                ? t('components.details-summary.tags.minutes-text', {
                    duration: module.estimatedDuration,
                  })
                : t('components.details-summary.tags.minute-text')}
            </Tag>
          )}
        </li>
      </ul>

      <div className={styles.description}>{module.description}</div>

      {module.notes && (
        <ul className={styles.noteList}>
          {module.notes.map((note, index) => (
            <li className={styles.noteListItem} key={index}>
              <NoteItem note={note} />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default AssessmentDetailsSummary;
