import {
  AverageResponseTimes,
  AverageResponseTimesRaw,
  FeedbackPreferences,
  FeedbackPreferencesRaw,
  WorkHours,
  WorkHoursRaw,
  WorkStylePreferences,
  WorkStylePreferencesFormValues,
  WorkStylePreferencesRaw,
  WorkStylePreferencesValues,
  WorkStylePreferencesValuesRaw,
} from '../../interfaces/work-style-preferences';

export const sanitizeWorkStylePreferencesValuesFromRaw = (
  data: WorkStylePreferencesValuesRaw
): WorkStylePreferencesValues => {
  return {
    averageResponseTimes: data.average_response_times,
    feedbackCadence: data.feedback_cadence,
    oneOnOneCadence: data.one_on_one_cadence,
    workPlace: data.work_place,
  };
};

export const sanitizeWorkStylePreferencesToRaw = (
  data: WorkStylePreferencesFormValues
) => {
  const payload = {} as WorkStylePreferencesRaw;

  // Work Hours
  if (data.workHours.startTimeHour || data.workHours.startTimeMinute) {
    let startTime;

    if (data.workHours.startTimeHour) {
      startTime = data.workHours.startTimeHour;
    }

    if (data.workHours.startTimeMinute) {
      startTime = `${startTime}:${data.workHours.startTimeMinute}`;
    } else {
      startTime = `${startTime}:00`;
    }

    payload['work_hours'] = {...payload['work_hours'], start_time: startTime};
  }

  if (data.workHours.endTimeHour || data.workHours.endTimeMinute) {
    let endTime;

    if (data.workHours.endTimeHour) {
      endTime = data.workHours.endTimeHour;
    }

    if (data.workHours.endTimeMinute) {
      endTime = `${endTime}:${data.workHours.endTimeMinute}`;
    } else {
      endTime = `${endTime}:00`;
    }

    payload['work_hours'] = {...payload['work_hours'], end_time: endTime};
  }

  // Average Response Time
  if (data.averageResponseTimes.chat) {
    payload['average_response_times'] = {
      ...payload['average_response_times'],
      chat: data.averageResponseTimes.chat,
    };
  }

  if (data.averageResponseTimes.email) {
    payload['average_response_times'] = {
      ...payload['average_response_times'],
      email: data.averageResponseTimes.email,
    };
  }

  if (data.averageResponseTimes.phone) {
    payload['average_response_times'] = {
      ...payload['average_response_times'],
      phone: data.averageResponseTimes.phone,
    };
  }

  if (data.averageResponseTimes.textMessage) {
    payload['average_response_times'] = {
      ...payload['average_response_times'],
      text_message: data.averageResponseTimes.textMessage,
    };
  }

  // Feedback Preferences
  if (data.feedbackPreferences.cadence) {
    payload['feedback_preferences'] = {
      ...payload['feedback_preferences'],
      cadence: data.feedbackPreferences.cadence,
    };
  }

  if (data.feedbackPreferences.deliveryMethod) {
    payload['feedback_preferences'] = {
      ...payload['feedback_preferences'],
      delivery_method: data.feedbackPreferences.deliveryMethod,
    };
  }

  // Other values that are not objects
  if (data.workPlace) {
    payload['work_place'] = data.workPlace;
  }

  if (data.oneOnOneCadence) {
    payload['one_on_one_cadence'] = data.oneOnOneCadence;
  }

  return payload;
};

export const sanitizeWorkStylePreferencesFromRaw = (
  data: WorkStylePreferencesRaw
): WorkStylePreferences | null => {
  if (!data) return null;

  return {
    averageResponseTimes: sanitizeAverageResponseTimesFromRaw(
      data.average_response_times
    ),
    feedbackPreferences: sanitizeFeedbackPreferencesFromRaw(
      data.feedback_preferences
    ),
    oneOnOneCadence: data.one_on_one_cadence,
    workHours: sanitizeWorkHoursFromRaw(data.work_hours),
    workPlace: data.work_place,
  };
};

export const sanitizeAverageResponseTimesFromRaw = (
  data: AverageResponseTimesRaw
): AverageResponseTimes | null => {
  if (!data) return null;

  return {
    chat: data.chat,
    email: data.email,
    phone: data.phone,
    textMessage: data.text_message,
  };
};

export const sanitizeFeedbackPreferencesFromRaw = (
  data: FeedbackPreferencesRaw
): FeedbackPreferences | null => {
  if (!data) return null;

  return {
    cadence: data.cadence,
    deliveryMethod: data.delivery_method,
  };
};

export const sanitizeWorkHoursFromRaw = (
  data: WorkHoursRaw
): WorkHours | null => {
  if (!data) return null;

  return {
    startTime: data.start_time,
    endTime: data.end_time,
  };
};
