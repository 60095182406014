import axios from 'axios';
import {PresignedUploadURLConfig} from '../../../interfaces/presigned-upload-url-config';

export interface UploadToPresignedURLPayload {
  config: PresignedUploadURLConfig;
  file: File | Blob;
}

export async function uploadToPresignedURL(
  config: PresignedUploadURLConfig,
  file: File | Blob
) {
  try {
    const formData = new FormData();
    const {url, fields} = config;

    Object.keys(fields).forEach((fieldName) => {
      const field = fields[fieldName];

      if (field) {
        formData.append(fieldName, field);
      }
    });

    formData.append('file', file);

    const response = await axios.post(url, formData);

    if (response.status !== 204) {
      throw new Error('Upload failed');
    }

    return `${url}${fields['key']}`;
  } catch (error) {
    console.error(error);
  }
}
