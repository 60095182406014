import {
  ChangeEvent,
  FormEvent,
  FunctionComponent,
  useCallback,
  useState,
} from 'react';
import {DEFAULT_NUMBER_OF_ITEMS_PER_PAGE} from '../../../../../../constants/filtering/pagination';
import {DEFAULT_TOASTIFY_CONFIGURATION} from '../../../../../../constants/toastify-configuration';
import {
  DIRECTORY_EMPLOYEES_SORTABLE_COLUMNS,
  SUPPORTED_SORTABLE_COLUMNS,
} from '../../../../../../constants/filtering/sort-by';
import {EventName} from '../../../../../../constants/analytics/event-name';
import {EventType} from '../../../../../../constants/analytics/event-type';
import {
  InviteUsersEvent,
  SearchTriggeredEvent,
} from '../../../../../../interfaces/analytics/event-properties';
import {
  ORDER_BY_PARAMETERS,
  SUPPORTED_ORDER_BY_PARAMETERS,
} from '../../../../../../constants/filtering/order-by';
import {ReactComponent as IconPlus} from '../../../../../@components/kit/icons/plus.svg';
import {SortedColumn} from '../../../../../../interfaces/filtering/sorted-column';
import {StringParam, createEnumParam, useQueryParams} from 'use-query-params';
import {toast, ToastOptions} from 'react-toastify';
import {useCompanyEmployees} from '../../../../../@hooks/queries';
import {useDebounce} from 'react-use';
import {useSafeCurrentCompany} from '../../../../../@atoms/current-company';
import {useSendReminderEmail} from '../../../../../@hooks/mutations';
import {useTranslation} from 'react-i18next';
import getActiveSortedColumnFromQueryParams from '../../../../../../utils/get-active-sorted-column-from-query-params';
import InviteUsersDialog, {
  RoleValues,
} from '../../../@components/invite-users-dialog';
import List from './@components/list';
import NoResultsBox from '../../../../@components/no-results-box';
import Pagination from '../../../../../@components/pagination';
import RoundedCard from '../../../../../@components/rounded-card';
import SearchForm from '../../../../@components/search-form/search-form';
import Spinner from '../../../../../@components/spinner';
import styles from './styles.module.scss';
import useAnalytics from '../../../../../@hooks/use-analytics';
import usePagination from '../../../../../@hooks/use-pagination';

const MINIMUM_SEARCH_LETTERS = 2;
const DELAY_FOR_SEARCH_DEBOUNCE_IN_MS = 500;

const EmployeeList: FunctionComponent = () => {
  const {t} = useTranslation('people');

  const currentCompany = useSafeCurrentCompany();
  const {trackEvent} = useAnalytics();

  const [isInviteDialogVisible, setIsInviteDialogVisible] = useState(false);

  const [queryParameters, setQueryParameters] = useQueryParams({
    orderBy: createEnumParam(SUPPORTED_ORDER_BY_PARAMETERS),
    sortBy: createEnumParam(SUPPORTED_SORTABLE_COLUMNS.DIRECTORY_EMPLOYEES),
    query: StringParam,
  });

  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(
    queryParameters.query
  );

  const isInviteUsersButtonVisible =
    !currentCompany.companySettings.isHRISProviderUKG;

  const {
    currentPage,
    goToNextPage,
    goToPreviousPage,
    offset,
    onResetPagination,
    pageInQueryParams,
    setPageInQueryParams,
  } = usePagination({itemsPerPage: DEFAULT_NUMBER_OF_ITEMS_PER_PAGE});

  const employees = useCompanyEmployees({
    variables: {
      companyUuid: currentCompany.uuid,
      currentPage,
      itemsPerPage: DEFAULT_NUMBER_OF_ITEMS_PER_PAGE,
      limit: DEFAULT_NUMBER_OF_ITEMS_PER_PAGE,
      includeAllUsers: true,
      offset,
      orderBy: queryParameters.orderBy ?? undefined,
      query: queryParameters.query ?? undefined,
      sortBy:
        (queryParameters.sortBy as DIRECTORY_EMPLOYEES_SORTABLE_COLUMNS) ??
        undefined,
    },
  });

  const sendReminderEmail = useSendReminderEmail();

  const [activeSortedColumn, setActiveSortedColumn] =
    useState<SortedColumn | null>(
      getActiveSortedColumnFromQueryParams({
        orderBy: queryParameters.orderBy,
        sortBy: queryParameters.sortBy,
        supportedColumnsToSort: SUPPORTED_SORTABLE_COLUMNS.DIRECTORY_EMPLOYEES,
      })
    );

  const onColumnClicked = useCallback(
    (column: SortedColumn) => {
      setActiveSortedColumn(column);

      setQueryParameters({
        ...queryParameters,
        sortBy: column.columnName as DIRECTORY_EMPLOYEES_SORTABLE_COLUMNS,
        orderBy: column.order as ORDER_BY_PARAMETERS,
      });

      if (!pageInQueryParams) return;

      setPageInQueryParams(1);
      onResetPagination();
    },
    [
      onResetPagination,
      pageInQueryParams,
      queryParameters,
      setPageInQueryParams,
      setQueryParameters,
    ]
  );

  const onSendReminder = useCallback(
    async (workEmail: string) => {
      const response = await sendReminderEmail.mutateAsync({
        companyUuid: currentCompany.uuid,
        workEmail,
      });
      if (!response) return;

      trackEvent({
        eventName: EventName.PEOPLE.MANAGE_DIRECTORY.SEND_REMINDER,
        eventType: EventType.BUTTON_CLICKED,
      });

      const feedbackMessage = t(
        'manage-directory.components.employee-list.feedback-messages.send-reminder'
      );

      toast.success(feedbackMessage, {
        ...(DEFAULT_TOASTIFY_CONFIGURATION as ToastOptions),
      });

      return response;
    },
    [trackEvent, sendReminderEmail, currentCompany, t]
  );

  const onSearch = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setDebouncedSearchQuery(value);

      const eventProperties = {
        searchQuery: value,
      } as SearchTriggeredEvent;

      trackEvent({
        eventName: EventName.PEOPLE.MANAGE_DIRECTORY.SEARCH,
        eventType: EventType.SEARCH_TRIGGERED,
        eventProperties,
      });
    },
    [trackEvent]
  );

  const onClearSearch = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      setQueryParameters({
        ...queryParameters,
        query: undefined,
      });

      trackEvent({
        eventName: EventName.PEOPLE.MANAGE_DIRECTORY.CLEAR_SEARCH,
        eventType: EventType.BUTTON_CLICKED,
      });
    },
    [queryParameters, setQueryParameters, trackEvent]
  );

  const onInviteUserButtonClicked = useCallback(() => {
    trackEvent({
      eventName: EventName.PEOPLE.MANAGE_DIRECTORY.INVITE_USERS,
      eventType: EventType.BUTTON_CLICKED,
    });

    setIsInviteDialogVisible(true);
  }, [trackEvent]);

  const onSendInviteSuccess = useCallback(
    (userInvites: RoleValues) => {
      const emailsPerRole = Object.keys(userInvites).reduce(
        (memo, key) => {
          const value = userInvites[key as keyof typeof userInvites];
          const emails = value.split(',').map((email: string) => email.trim());

          return {
            ...memo,
            [key]: emails,
          };
        },
        {admin: [], manager: [], member: [], owner: []}
      );

      const eventProperties = {
        adminCount: emailsPerRole.admin.length,
        managerCount: emailsPerRole.manager.length,
        memberCount: emailsPerRole.member.length,
        ownerCount: emailsPerRole.owner.length,
      } as InviteUsersEvent;

      trackEvent({
        eventName: EventName.PEOPLE.MANAGE_DIRECTORY.SEND_INVITES,
        eventType: EventType.BUTTON_CLICKED,
        eventProperties,
      });

      setIsInviteDialogVisible(false);
    },
    [trackEvent]
  );

  useDebounce(
    () => {
      if (
        debouncedSearchQuery !== '' &&
        (debouncedSearchQuery?.length ?? 0) < MINIMUM_SEARCH_LETTERS
      ) {
        return;
      }

      setQueryParameters({
        ...queryParameters,
        query: debouncedSearchQuery,
      });

      if (!pageInQueryParams) return;
      onResetPagination();
    },
    DELAY_FOR_SEARCH_DEBOUNCE_IN_MS,
    [debouncedSearchQuery]
  );

  return (
    <>
      {isInviteDialogVisible && (
        <InviteUsersDialog
          isDialogVisible={isInviteDialogVisible}
          onClose={() => setIsInviteDialogVisible(false)}
          onSuccess={onSendInviteSuccess}
        />
      )}

      <RoundedCard.Header className={styles.header}>
        <RoundedCard.Title>
          {t('manage-directory.components.employee-list.title')}

          {isInviteUsersButtonVisible && (
            <button
              type="button"
              className={styles.button}
              aria-label={t(
                'manage-directory.components.employee-list.invite-user-button-accessibility-text'
              )}
              onClick={onInviteUserButtonClicked}
            >
              <IconPlus className={styles.buttonIcon} />
            </button>
          )}
        </RoundedCard.Title>

        <div>
          <SearchForm
            searchQueryInQueryParams={queryParameters.query}
            onSearch={onSearch}
            placeholder={t(
              'manage-directory.components.employee-list.search-box-placeholder'
            )}
            onClearSearch={onClearSearch}
          />
        </div>
      </RoundedCard.Header>

      <RoundedCard.Content>
        {employees.isLoading ? (
          <Spinner />
        ) : (
          <>
            {employees.data?.data.length ? (
              <>
                <List
                  activeSortedColumn={activeSortedColumn}
                  employees={employees.data?.data}
                  onColumnClicked={onColumnClicked}
                  onSendReminder={onSendReminder}
                />

                {employees.data?.meta && (
                  <Pagination
                    currentPage={currentPage}
                    itemsPerPage={DEFAULT_NUMBER_OF_ITEMS_PER_PAGE}
                    onNextPageLinkClicked={goToNextPage}
                    onPreviousPageLinkClicked={goToPreviousPage}
                    totalItems={employees.data?.meta.totalEntries}
                  />
                )}
              </>
            ) : (
              <NoResultsBox />
            )}
          </>
        )}
      </RoundedCard.Content>
    </>
  );
};

export default EmployeeList;
