import {
  DragEvent,
  FunctionComponent,
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
} from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as IconImage} from '../../icons/image.svg';
import {ReactComponent as IconEdit} from '../../icons/edit.svg';
import styles from './styles.module.scss';

interface ElementProps extends InputHTMLAttributes<HTMLInputElement> {
  fileCallback: (file: File) => void;
  pictureUrl?: string | null;
  hasError?: boolean;
  label?: string;
}

const ImageUpload: FunctionComponent<ElementProps> = ({
  fileCallback,
  pictureUrl,
  hasError = false,
  label,
  ...props
}) => {
  const [previewUrl, setPreviewUrl] = useState('');
  const {t} = useTranslation('components');
  const fileInput = useRef(null);

  const handleOndragOver = (event: DragEvent) => {
    event.preventDefault();
  };

  const handleOndrop = (event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();

    const imageFile = event.dataTransfer.files[0];

    handleFile(imageFile);
  };

  const handleFile = (file: File) => {
    fileCallback(file);
    setPreviewUrl(URL.createObjectURL(file));
  };

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files) {
      let imageFile = event.target.files[0];
      handleFile(imageFile);
    }
  };

  const handleClick = () => {
    if (fileInput.current) {
      (fileInput.current as HTMLInputElement).click();
    }
  };

  useEffect(() => {
    if (pictureUrl) {
      setPreviewUrl(pictureUrl);
    }
  }, [pictureUrl]);

  return (
    <>
      <div
        className={styles.dropzone}
        onDragOver={handleOndragOver}
        onDrop={handleOndrop}
        onClick={handleClick}
      >
        {previewUrl ? (
          <>
            <div className={styles.imageContainer}>
              <img className={styles.image} src={previewUrl} alt="" />
            </div>

            <IconEdit className={styles.editIcon} />
          </>
        ) : (
          <>
            <IconImage className={styles.icon} />

            <p className={styles.label}>
              {label ?? t('profile-image-upload.default-label')}
            </p>
          </>
        )}

        <input
          type="file"
          accept="image/*"
          ref={fileInput}
          hidden
          onChange={handleFileInputChange}
          {...props}
        />
      </div>
    </>
  );
};

export default ImageUpload;
