export enum Actions {
  ADD_EMPLOYEE,
  ADD_TEAM,
  ADD_VALUE,
  DELETE_TEAM,
  DELETE_VALUE,
  EDIT_LEADERSHIP_MANIFESTO,
  EDIT_MISSION,
  EDIT_ORGANIZATION_STRUCTURE,
  EDIT_TEAM,
  EDIT_VALUE,
  EDIT_VISION,
  INVITE_ADMIN,
  INVITE_OWNER,
  UPDATE_KUDO_VISIBILITY,
  UPDATE_ROLE_TO_ADMIN,
  UPDATE_ROLE_TO_MANAGER,
  UPDATE_ROLE_TO_MEMBER,
  UPDATE_ROLE_TO_OWNER,
  VIEW_COMPANY_SETTINGS,
  VIEW_JOBS,
  VIEW_MANAGE_ASSESSMENTS,
  VIEW_MANAGE_DIRECTORY,
  VIEW_ACTION_STEP_STATUS,
}
