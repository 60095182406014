import {Actions} from '../../../../../../constants/permissions/actions';
import {format, formatDistanceToNow} from 'date-fns';
import {FunctionComponent, useCallback, useMemo} from 'react';
import {getUserProfileForCompany} from '../../../../../../services/user-profiles';
import {Kudo} from '../../../../../../interfaces/kudo';
import {ReactComponent as IconEye} from '../../../../../@components/kit/icons/eye.svg';
import {ReactComponent as IconEyeOff} from '../../../../../@components/kit/icons/eye-off.svg';
import {Trans, useTranslation} from 'react-i18next';
import {useCurrentAuthUserState} from '../../../../../@atoms/current-auth-user';
import {useCurrentFullUserProfile} from '../../../../../@atoms/current-full-user-profile';
import {useSafeCurrentCompany} from '../../../../../@atoms/current-company';
import {useUserPermission} from '../../../../../@hooks/use-user-permission';
import {utcToZonedTime} from 'date-fns-tz';
import AvatarPlaceholder from '../../../../../@components/avatar-placeholder';
import styles from './styles.module.scss';
import RichTextContainer from '../../../../../@components/rich-text-container/rich-text-container';

interface ElementProps {
  kudo: Kudo;
  onUpdateKudoVisibility: (kudoUuid: string, isHidden: boolean) => void;
}

const Item: FunctionComponent<ElementProps> = ({
  kudo,
  onUpdateKudoVisibility,
}) => {
  const {t} = useTranslation('application');

  const currentFullUserProfile = useCurrentFullUserProfile();
  const [currentAuthUser] = useCurrentAuthUserState();
  const currentCompany = useSafeCurrentCompany();

  const userProfile = getUserProfileForCompany(
    currentAuthUser!,
    currentCompany
  );
  const can = useUserPermission(userProfile.role);

  const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timezone = currentFullUserProfile.timeZone ?? browserTimezone;

  const accessibilityDate = format(
    utcToZonedTime(kudo.createdAt, timezone),
    'PPP, h:mm a'
  );

  const isKudoHidden = kudo.isHidden;

  const formatedCreatedAt = useMemo(() => {
    return formatDistanceToNow(utcToZonedTime(kudo.createdAt, timezone), {
      addSuffix: true,
    });
  }, [kudo, timezone]);

  const canUserManageKudo = useMemo(() => {
    const isCurrentUserRecipientOfCurrentKudo =
      currentFullUserProfile.uuid === kudo.toUser.uuid;

    return (
      can(Actions.UPDATE_KUDO_VISIBILITY) || isCurrentUserRecipientOfCurrentKudo
    );
  }, [currentFullUserProfile, can, kudo]);

  const onUpdateVisibilityButtonClicked = useCallback(async () => {
    onUpdateKudoVisibility(kudo.uuid, kudo.isHidden);
  }, [onUpdateKudoVisibility, kudo]);

  return (
    <div className={`${styles.container} ${isKudoHidden ? styles.hidden : ''}`}>
      <div className={styles.column}>
        <div className={styles.pictureContainer}>
          {kudo.fromUser.picture ? (
            <img
              src={kudo.fromUser.picture}
              alt=""
              className={styles.picture}
            />
          ) : (
            <AvatarPlaceholder
              size="small"
              variant="dark-blue"
              name={kudo.fromUser.name}
            />
          )}
        </div>

        <div className={styles.content}>
          <div className={styles.title}>
            <Trans
              t={t}
              i18nKey="components.kudos-list.components.item.title"
              components={{bold: <b />}}
              values={{
                fromUserName: kudo.fromUser.name,
                toUserName: kudo.toUser.name,
                valueName: kudo.value.name,
              }}
            />
          </div>

          <div className={styles.message}>
            <RichTextContainer value={kudo.description} />
          </div>
        </div>
      </div>

      <div className={styles.meta}>
        <div className={styles.date} title={accessibilityDate}>
          {formatedCreatedAt}
        </div>

        {canUserManageKudo && (
          <>
            {isKudoHidden ? (
              <button
                type="button"
                className={styles.kudoButton}
                onClick={onUpdateVisibilityButtonClicked}
                aria-label={t(
                  'components.kudos-list.components.item.show-kudo-accessibility-text'
                )}
              >
                <IconEyeOff className={styles.kudoButtonIcon} />
              </button>
            ) : (
              <button
                type="button"
                className={styles.kudoButton}
                onClick={onUpdateVisibilityButtonClicked}
                aria-label={t(
                  'components.kudos-list.components.item.hide-kudo-accessibility-text'
                )}
              >
                <IconEye className={styles.kudoButtonIcon} />
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Item;
