import {Actions} from '../../../../constants/permissions/actions';
import {CoreValueForm} from '../../../../interfaces/company-core-value';
import {DEFAULT_TOASTIFY_CONFIGURATION} from '../../../../constants/toastify-configuration';
import {EventName} from '../../../../constants/analytics/event-name';
import {EventType} from '../../../../constants/analytics/event-type';
import {FunctionComponent, useCallback, useState} from 'react';
import {getUserProfileForCompany} from '../../../../services/user-profiles';
import {ReactComponent as IconPlus} from '../../../@components/kit/icons/plus.svg';
import {sanitizeCompanyCoreValuesToRaw} from '../../../@sanitizers/company-core-values';
import {toast, ToastOptions} from 'react-toastify';
import {useCompanyCoreValues} from '../../../@hooks/queries';
import {useCreateCompanyCoreValues} from '../../../@hooks/mutations';
import {useCurrentAuthUserState} from '../../../@atoms/current-auth-user';
import {useSafeCurrentCompany} from '../../../@atoms/current-company';
import {useTranslation} from 'react-i18next';
import {useUserPermission} from '../../../@hooks/use-user-permission';
import AddCoreValueDialog from './@components/add-core-value-dialog';
import Item from './@components/item';
import RoundedCard from '../../../@components/rounded-card';
import styles from './styles.module.scss';
import useAnalytics from '../../../@hooks/use-analytics';
import Spinner from '../../../@components/spinner/spinner';

interface ElementProps {
  prefixEventName?: string;
}

const InteractiveValues: FunctionComponent<ElementProps> = ({
  prefixEventName,
}) => {
  const {t} = useTranslation('dashboard');

  const [currentAuthUser] = useCurrentAuthUserState();
  const currentCompany = useSafeCurrentCompany();
  const {buildEventName, trackEvent} = useAnalytics();

  const userProfile = getUserProfileForCompany(
    currentAuthUser!,
    currentCompany
  );
  const can = useUserPermission(userProfile.role);

  const [isAddValueDialogVisible, setIsAddValueDialogVisible] = useState(false);

  const values = useCompanyCoreValues({
    variables: {
      companyUuid: currentCompany.uuid,
    },
  });

  const createCompanyCoreValues = useCreateCompanyCoreValues();

  const onCloseAddValueDialog = useCallback(() => {
    setIsAddValueDialogVisible(false);
  }, []);

  const onAddValueConfirm = useCallback(
    async (coreValue: CoreValueForm[]) => {
      const response = await createCompanyCoreValues.mutateAsync({
        companyCoreValuePayload: sanitizeCompanyCoreValuesToRaw(coreValue),
        companyUuid: currentCompany.uuid,
      });

      if (!response) return;

      trackEvent({
        eventName: prefixEventName
          ? buildEventName(
              EventName.COMPONENTS.INTERACTIVE_VALUES.CREATE_NEW_VALUE,
              prefixEventName
            )
          : EventName.COMPONENTS.INTERACTIVE_VALUES.SAVE_VALUE,
        eventType: EventType.BUTTON_CLICKED,
      });

      values.refetch();
      onCloseAddValueDialog();

      const feedbackMessage = t(
        'components.interactive-values.feedback-messages.add-value'
      );

      toast.success(feedbackMessage, {
        ...(DEFAULT_TOASTIFY_CONFIGURATION as ToastOptions),
      });
    },
    [
      buildEventName,
      createCompanyCoreValues,
      currentCompany,
      onCloseAddValueDialog,
      prefixEventName,
      t,
      trackEvent,
      values,
    ]
  );

  const onAddValueButtonClicked = useCallback(() => {
    trackEvent({
      eventName: buildEventName(
        EventName.COMPONENTS.INTERACTIVE_VALUES.CREATE_NEW_VALUE,
        prefixEventName
      ),
      eventType: EventType.BUTTON_CLICKED,
    });

    setIsAddValueDialogVisible(true);
  }, [buildEventName, trackEvent, prefixEventName]);

  return (
    <>
      <RoundedCard>
        <RoundedCard.Header>
          <RoundedCard.Title>
            {t('components.interactive-values.title')}
          </RoundedCard.Title>

          {can(Actions.ADD_VALUE) && (
            <div className={styles.actions}>
              <button
                aria-label={t(
                  'components.interactive-values.add-value-accessibility-text'
                )}
                className={styles.button}
                onClick={onAddValueButtonClicked}
                type="button"
              >
                <IconPlus className={styles.icon} />
              </button>
            </div>
          )}
        </RoundedCard.Header>

        <RoundedCard.Content>
          {values.isLoading ? (
            <Spinner />
          ) : (
            <ul className={styles.list}>
              {values.data && (
                <>
                  {values.data.map((value, index) => (
                    <li className={styles.item} key={index}>
                      <Item value={value} />
                    </li>
                  ))}

                  {can(Actions.ADD_VALUE) && (
                    <li className={styles.item}>
                      <button
                        aria-label={t(
                          'components.interactive-values.add-value-accessibility-text'
                        )}
                        className={styles.addButton}
                        onClick={() => setIsAddValueDialogVisible(true)}
                        type="button"
                      >
                        <IconPlus className={styles.icon} />
                      </button>
                    </li>
                  )}
                </>
              )}
            </ul>
          )}

          <AddCoreValueDialog
            isDialogVisible={isAddValueDialogVisible}
            onAddValueConfirm={onAddValueConfirm}
            onClose={onCloseAddValueDialog}
            prefixEventName={prefixEventName}
          />
        </RoundedCard.Content>
      </RoundedCard>
    </>
  );
};

export default InteractiveValues;
