import {animated, useTransition} from 'react-spring';
import {AssessmentPageViewEvent} from '../../../interfaces/analytics/event-properties';
import {buildPageTitle} from '../../../services/build-page-title';
import {EventName} from '../../../constants/analytics/event-name';
import {EventType} from '../../../constants/analytics/event-type';
import {FunctionComponent, useCallback, useEffect, useState} from 'react';
import {getIndexFromKeyValue} from '../../../utils/objects/get-index-from-key-value';
import {Helmet} from 'react-helmet-async';
import {Module} from '../../../interfaces/module';
import {Question, QuestionAnswerResponse} from '../../../interfaces/question';
import {useNavigate, useParams} from 'react-router';
import {UserAssessment} from '../../../interfaces/user-assessment';
import {useTranslation} from 'react-i18next';
import {useUpsertAssessmentResponse} from '../../@hooks/mutations/use-upsert-assessment-response';
import {useUserAssessment} from '../../@hooks/queries';
import AnimatedQuestionnaire from './@components/animated-questionnaire';
import AssessmentDetailsSummary from './@components/assessment-details-summary';
import AssessmentHeader from '../../application/@components/assessment-header';
import ProgressBarWithLabel from '../../@components/progress-bar-with-label';
import styles from './styles.module.scss';
import useAnalytics from '../../@hooks/use-analytics';

export interface Assessment {
  name: string;
  category: string;
  description: string;
}

export interface AnswerMap {
  [key: string]: string;
}

const getSummaryRoute = (
  assessmentUuid: string,
  moduleUuid: string,
  _isLastModule: boolean
) => {
  // TODO: For use when we implement multiple modules
  // if (isLastModule) {
  //   return `/assessments-summary/${assessmentUuid}`;
  // }
  return `/assessments-summary/${assessmentUuid}/modules/${moduleUuid}`;
};

const Assessments: FunctionComponent = () => {
  const {t} = useTranslation('applicationPageTitle');

  const {id: assessmentId} = useParams<'id'>();
  const {moduleId} = useParams<string>();

  const navigate = useNavigate();
  const {trackEvent} = useAnalytics();

  const [cacheAnswers, setCacheAnswers] = useState<AnswerMap>({});
  const [currentModule, setCurrentModule] = useState<Module>();
  const [currentQuestionIndex, setCurrentQuestionRank] = useState(0);
  const [isProgressSavedVisible, setIsProgressSavedVisible] = useState(false);
  const [showIntroPanel, setShowIntroPanel] = useState(true);

  const userAssessment = useUserAssessment({
    variables: {
      userAssessmentUuid: assessmentId as string,
    },
    onSuccess: (response) => {
      if (!response) return;
      onFetchUserAssessments(response);
    },
  });

  const upsertResponse = useUpsertAssessmentResponse();

  useEffect(() => {
    if (!cacheAnswers || !Object.entries(cacheAnswers).length) return;

    const answersLength = Object.entries(cacheAnswers).length;
    const questionsLength = currentModule?.questions?.length;

    const isLastModule =
      currentModule?.rank ===
      userAssessment?.data?.assessmentEvent.assessment.modules.length;

    if (questionsLength === answersLength && assessmentId) {
      const url = getSummaryRoute(
        assessmentId,
        currentModule!.uuid,
        isLastModule
      );

      navigate(url);
    }
  }, [cacheAnswers, currentModule, assessmentId, navigate, userAssessment]);

  const introPanelTransition = useTransition(showIntroPanel, {
    from: {x: -500, width: '0%', opacity: 0},
    enter: {x: 0, width: '100%', opacity: 1},
    leave: {x: -500, width: '0%', opacity: 0},
  });

  const trackPageView = useCallback(
    (assessmentName: string) => {
      const eventProperties = {assessmentName} as AssessmentPageViewEvent;

      trackEvent({
        eventName: EventName.PAGE_VIEWS.ASSESSMENT_DETAILS,
        eventType: EventType.PAGE_VIEW,
        eventProperties,
      });
    },
    [trackEvent]
  );

  const onFetchUserAssessments = useCallback(
    async (userAssessment: UserAssessment) => {
      trackPageView(userAssessment.assessmentEvent.assessment.name);

      const modules = userAssessment.assessmentEvent.assessment.modules;

      const moduleIndex = getIndexFromKeyValue(
        modules,
        'uuid',
        moduleId as string
      );

      if (moduleIndex === -1) {
        navigate('/page-not-found');
        return;
      }

      const currentModule = modules[moduleIndex];
      if (!currentModule) return;

      setCurrentModule(currentModule);

      if (userAssessment.userResponses) {
        const cacheAnswers: AnswerMap = {};

        const isModuleCompleted =
          userAssessment.userResponses.length ===
          currentModule?.questions?.length;

        userAssessment.userResponses.forEach((response) => {
          cacheAnswers[response.question_uuid] = response.value;
        });

        setCacheAnswers(cacheAnswers);

        const isLastModule = currentModule.rank === modules.length;

        if (isModuleCompleted && assessmentId) {
          const url = getSummaryRoute(
            assessmentId,
            currentModule!.uuid,
            isLastModule
          );

          navigate(url);
        }

        setCurrentQuestionRank(userAssessment.userResponses.length);
        setShowIntroPanel(false);
      }
    },
    [trackPageView, assessmentId, navigate, moduleId]
  );

  const onUpsertResponse = useCallback(
    async (
      userAssessmentUuid: string,
      moduleUuid: string,
      questionUuid: string,
      value: string
    ) => {
      return await upsertResponse.mutateAsync({
        value,
        userAssessmentUuid,
        moduleUuid,
        questionUuid,
      });
    },
    [upsertResponse]
  );

  const handleResponse = useCallback(
    (response: QuestionAnswerResponse) => {
      if (!userAssessment.data || !currentModule) return;

      const assessmentResponse = onUpsertResponse(
        userAssessment.data.uuid,
        currentModule.uuid,
        response.questionUuid,
        response.answer
      );

      if (!assessmentResponse) return;

      setCacheAnswers({
        ...cacheAnswers,
        [response.questionUuid]: response.answer,
      });
    },
    [cacheAnswers, currentModule, userAssessment, onUpsertResponse]
  );

  if (!userAssessment.data || !currentModule) return null;

  return (
    <>
      <Helmet>
        <title>
          {buildPageTitle([currentModule.name, t('page-title.assessments')])}
        </title>
      </Helmet>

      <div className={styles.container}>
        <AssessmentHeader isProgressSavedVisible={isProgressSavedVisible} />

        <div className={styles.content}>
          {introPanelTransition((style, showPanel) =>
            showPanel ? (
              <animated.div className={styles.animatedIntroPanel} style={style}>
                {userAssessment.data && (
                  <AssessmentDetailsSummary
                    module={currentModule}
                    totalModules={
                      userAssessment.data.assessmentEvent.assessment.modules
                        .length
                    }
                  />
                )}
              </animated.div>
            ) : null
          )}

          <div className={styles.staticIntroPanel}>
            <AssessmentDetailsSummary
              module={currentModule}
              totalModules={
                userAssessment.data.assessmentEvent.assessment.modules.length
              }
            />
          </div>

          <div className={styles.rightPanel}>
            <AnimatedQuestionnaire
              questionnaire={currentModule.questions as Question[]}
              questionnaireAnswers={cacheAnswers}
              onResponse={handleResponse}
              currentQuestionIndex={currentQuestionIndex}
              setCurrentQuestionRank={setCurrentQuestionRank}
              onFirstQuestionChange={() => {
                setShowIntroPanel(false);
                setIsProgressSavedVisible(true);
              }}
            />

            <div className={styles.progressBar}>
              <ProgressBarWithLabel
                currentProgress={currentQuestionIndex}
                total={(currentModule.questions as Question[]).length}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Assessments;
