import {FunctionComponent, PropsWithChildren} from 'react';
import styles from './styles.module.scss';

interface ElementProps {
  spacing?: 'small' | 'none';
}

const MainContent: FunctionComponent<PropsWithChildren<ElementProps>> = ({
  children,
  spacing,
}) => {
  const variantClass = spacing ? styles[spacing] : '';

  return (
    <div className={`${styles.content} ${variantClass}`}>
      <div className={styles.wrapper}>{children}</div>
    </div>
  );
};

export default MainContent;
