import {buildPageTitle} from '../../../services/build-page-title';
import {EventName} from '../../../constants/analytics/event-name';
import {EventType} from '../../../constants/analytics/event-type';
import {FunctionComponent, useCallback, useEffect, useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {StringParam, useQueryParam} from 'use-query-params';
import {
  useCompanyCoreValues,
  useCompanyEmployees,
  useKudo,
} from '../../@hooks/queries';
import {useSafeCurrentCompany} from '../../@atoms/current-company';
import {useTranslation} from 'react-i18next';
import Header from '../@components/header';
import KudoDialog from './@components/kudo-dialog/kudo-dialog';
import KudosList from './@components/kudos-list/kudos-list';
import mainContainerStyles from '../../../styles/classes/main-container.module.scss';
import MainContent from '../@components/main-content';
import RoundedCard from '../../@components/rounded-card';
import useAnalytics from '../../@hooks/use-analytics';

const Kudos: FunctionComponent = () => {
  const {t} = useTranslation(['applicationPageTitle', 'kudos']);

  const currentCompany = useSafeCurrentCompany();
  const {trackEvent} = useAnalytics();

  const [isKudoDialogVisible, setIsKudoDialogVisible] = useState(false);

  const [kudoUuidInQueryParams, setKudoUuidInQueryParams] = useQueryParam(
    'kudoUuid',
    StringParam
  );

  const kudo = useKudo({
    enabled: Boolean(kudoUuidInQueryParams),
    variables: {
      companyUuid: currentCompany.uuid,
      kudoUuid: kudoUuidInQueryParams ?? undefined,
    },
    onSuccess: () => {
      setIsKudoDialogVisible(true);
    },
  });

  const values = useCompanyCoreValues({
    variables: {
      companyUuid: currentCompany.uuid,
    },
  });

  const employees = useCompanyEmployees({
    variables: {
      companyUuid: currentCompany.uuid,
    },
  });

  const onCloseKudoDialog = useCallback(() => {
    setIsKudoDialogVisible(false);
    setKudoUuidInQueryParams(undefined);
  }, [setKudoUuidInQueryParams]);

  useEffect(() => {
    trackEvent({
      eventName: EventName.PAGE_VIEWS.KUDOS,
      eventType: EventType.PAGE_VIEW,
    });
  }, [trackEvent]);

  return (
    <>
      <Helmet>
        <title>
          {buildPageTitle(t('page-title.kudos', {ns: 'applicationPageTitle'}))}
        </title>
      </Helmet>

      <Header title={t('title', {ns: 'kudos'})}></Header>

      <MainContent>
        <main className={mainContainerStyles.container}>
          <RoundedCard>
            <KudosList values={values.data} employees={employees.data?.data} />
          </RoundedCard>
        </main>
      </MainContent>

      {isKudoDialogVisible && kudo.data && (
        <KudoDialog
          kudo={kudo.data}
          isDialogVisible={isKudoDialogVisible}
          onClose={onCloseKudoDialog}
        />
      )}
    </>
  );
};

export default Kudos;
