import axios, {AxiosError} from 'axios';
import {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import {Navigate, Route, Routes} from 'react-router';
import {useLocation} from 'react-router-dom';
import {InstillAPIError} from '../../repositories/instill/axios-instance';
import {
  CompanyNotRegisteredException,
  EmailNotVerifiedException,
} from '../../repositories/instill/mutations';
import OnboardingFlow from '../@components/onboarding-flow';
import Spinner from '../@components/spinner';
import SelectCompanyContextProvider from '../@contexts/company-selector-context';
import Application from '../application';
import MeetingsShow from '../application/sense/meetings/show/meetings-show';
import Assessments from '../assessments';
import AssessmentsSummary from '../assessments-summary';
import Candidates from '../candidates';
import Invite from '../invite';
import Logout from '../logout';
import PageNotFound from '../page-not-found';
import SelectCompany from '../select-company';
import AuthFeedback from './@components/auth-feedback';
import RequireAuth from './@components/require-auth';
import useFetchAndUpsertUser from './@hooks/use-fetch-and-upsert-user';
import RegisterCompany from '../register-company';

const AUTH0_EMAIL_VERIFICATION_CALLBACK_URL = '/auth-callback/email-verified';

const ApplicationRoutes: FunctionComponent = () => {
  const {t} = useTranslation();
  const location = useLocation();
  const {currentAuthUser, isLoading, error, isAccountLinked, userProviders} =
    useFetchAndUpsertUser();

  if (isLoading) {
    return <Spinner fullScreen />;
  }

  if (isAccountLinked) {
    return (
      <AuthFeedback accountLinked={isAccountLinked} providers={userProviders} />
    );
  }

  if (axios.isAxiosError(error)) {
    const serverError = error as AxiosError<InstillAPIError>;
    return <AuthFeedback errorPayload={serverError.response?.data.payload} />;
  } else if (error instanceof EmailNotVerifiedException) {
    if (!location.pathname.includes(AUTH0_EMAIL_VERIFICATION_CALLBACK_URL)) {
      return <AuthFeedback errorPayload={error.payload} />;
    }
  } else if (error instanceof CompanyNotRegisteredException) {
    return <AuthFeedback errorPayload={error.payload} />;
  } else if (error instanceof Error) {
    return <div>{t('error', {message: error.message})}</div>;
  } else if (typeof error === 'string') {
    return <div>{t('error', {message: error})}</div>;
  } else if (error) {
    return <div>{t('error', {message: t('unknown')})}</div>;
  }

  return (
    <div>
      <Routes>
        <Route path="/candidates/*" element={<Candidates />} />

        <Route element={<RequireAuth user={currentAuthUser} />}>
          <Route path="/" element={<Navigate replace to="application" />} />
          <Route
            path="/application/*"
            element={
              <SelectCompanyContextProvider>
                <OnboardingFlow>
                  <Application />
                </OnboardingFlow>
              </SelectCompanyContextProvider>
            }
          />
          <Route path="/logout" element={<Logout />} />

          {/* As of August 1st, company registration is temporarily disabled. Companies must be created through admin portal */}
          <Route path="/register-company/*" element={<RegisterCompany />} />

          <Route
            path="/select-company/"
            element={
              <SelectCompanyContextProvider>
                <SelectCompany />
              </SelectCompanyContextProvider>
            }
          />
        </Route>

        <Route path="assessments/*" element={<Assessments />} />

        <Route path="assessments-summary/*" element={<AssessmentsSummary />} />

        <Route path="/invite/:inviteToken" element={<Invite />} />

        <Route path="/meeting/:meetingId" element={<MeetingsShow />} />

        <Route
          path={AUTH0_EMAIL_VERIFICATION_CALLBACK_URL}
          element={<AuthFeedback emailVerified={true} />}
        />

        <Route path="*" element={<Navigate replace to="/page-not-found" />} />
        <Route path="/page-not-found" element={<PageNotFound />} />
      </Routes>
    </div>
  );
};

export default ApplicationRoutes;
