import {CompanyCoreValue} from '../../../interfaces/company-core-value';
import {createQuery} from 'react-query-kit';
import {fetchCompanyCoreValues} from '../../../repositories/instill';
import {sortByNameAndDescription} from '../../../utils/sort-by/name-and-description';
import {CompanyCoreValuePayload} from '../../../repositories/instill/queries/fetch-company-core-value';

export const useCompanyCoreValues = createQuery<
  CompanyCoreValue[] | undefined,
  CompanyCoreValuePayload
>({
  primaryKey: 'companyCoreValues',
  queryFn: async ({queryKey: [_, variables]}) => {
    const data = await fetchCompanyCoreValues(variables.companyUuid);

    return data ? sortByNameAndDescription(data) : undefined;
  },
});
