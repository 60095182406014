import {formatDate} from '../../../../../utils/date';
import {FunctionComponent} from 'react';
import {InvitedToAssessmentPayload} from '../../../../../interfaces/notifications/invite-to-assessment';
import {Link} from 'react-router-dom';
import {Trans, useTranslation} from 'react-i18next';
import {TRANSLATION_KEY_PREFIX} from '../../notification-event';
import styles from '../../styles.module.scss';

interface ElementProps {
  payload: InvitedToAssessmentPayload;
}

const InvitedToAssessment: FunctionComponent<ElementProps> = ({payload}) => {
  const {t} = useTranslation('common', {keyPrefix: TRANSLATION_KEY_PREFIX});

  return (
    <Link to="/application/measure/your-assessments" className={styles.link}>
      <Trans
        t={t}
        i18nKey="invited-to-assessment"
        components={{bold: <b />}}
        values={{
          assessmentName: payload.assessmentName,
          dueDate: formatDate(payload.dueDate),
        }}
      />
    </Link>
  );
};

export default InvitedToAssessment;
