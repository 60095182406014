import {
  FunctionComponent,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {DEFAULT_TOASTIFY_CONFIGURATION} from '../../../constants/toastify-configuration';
import {
  Notification,
  LiveNotification,
  SupportedNotifications,
} from '../../../interfaces/notification';
import {SocketTopicType} from '../../../constants/socket-topic-type';
import {ToastOptions, toast} from 'react-toastify';
import {useWebSocket} from './web-socket-context';
import NotificationEvent from '../../@components/notification-event';

interface Context {
  updatedPayload: Notification | undefined;
}

const NotificationContext = createContext<Context | null>(null);
NotificationContext.displayName = 'Notification';

export const useNotification = () => {
  const context = useContext(NotificationContext);

  if (!context) {
    throw new Error('Notification must be used within WebSocketProvider');
  }

  return context;
};

const NotificationContextProvider: FunctionComponent<PropsWithChildren<{}>> = ({
  children,
}) => {
  const [updatedPayload, setUpdatedPayload] = useState<Notification>();
  const {subscribe} = useWebSocket();

  const socketCallback = useCallback(
    (response: LiveNotification) => {
      const payload = response.payload as SupportedNotifications;

      setUpdatedPayload(response.payload as Notification);

      const translation = (
        <NotificationEvent event={payload.event} payload={payload.payload} />
      );
      if (!translation) return;

      toast.info(translation, {
        ...(DEFAULT_TOASTIFY_CONFIGURATION as ToastOptions),
        position: 'top-right',
      });
    },
    [setUpdatedPayload]
  );

  useEffect(() => {
    const unsubscribe = subscribe(SocketTopicType.NOTIFICATION, socketCallback);
    return unsubscribe;
  }, [subscribe, socketCallback]);

  const value = {updatedPayload};

  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationContextProvider;
