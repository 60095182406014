import {atom, useRecoilState, useRecoilValue} from 'recoil';
import {Company} from '../../interfaces/company';

const currentCompanyState = atom<Company | null>({
  key: 'currentCompany',
  default: null,
});

export function useCurrentCompanyState() {
  return useRecoilState(currentCompanyState);
}

export function useCurrentCompany() {
  return useRecoilValue(currentCompanyState);
}

export function useSafeCurrentCompany() {
  const company = useRecoilValue(currentCompanyState);

  if (!company) {
    throw Error('Current company is null');
  }

  return company;
}
