import {
  CompanyAssessmentEvent,
  CompanyAssessmentEventRaw,
  CompanyAssessmentEvents,
  CompanyAssessmentEventsRaw,
  CompletionInfo,
  CompletionInfoRaw,
  Module,
  ModuleRaw,
  Population,
  PopulationRaw,
} from '../../interfaces/company-assessment-events';

export const sanitizeCompanyAssessmentEventsFromRaw = (
  data: CompanyAssessmentEventsRaw
): CompanyAssessmentEvents => {
  return {
    assessmentName: data.assessment_name,
    events: data.events.map((assessmentEvent) =>
      sanitizeCompanyAssessmentEventFromRaw(assessmentEvent)
    ),
  };
};

export const sanitizeCompanyAssessmentEventFromRaw = (
  data: CompanyAssessmentEventRaw
): CompanyAssessmentEvent => {
  return {
    dueDate: data.due_date,
    dateInitiated: data.date_initiated,
    modules: sanitizeModulesFromRaw(data.modules),
    occurrence: data.occurrence,
    population: sanitizePopulationFromRaw(data.population),
    uuid: data.uuid,
  };
};

export const sanitizeModulesFromRaw = (data: ModuleRaw[]): Module[] => {
  return data.map((module) => sanitizeModuleFromRaw(module));
};

export const sanitizeModuleFromRaw = (data: ModuleRaw): Module => {
  return {
    completionInfo: sanitizeCompletionInfosFromRaw(data.completion_info),
    name: data.name,
    rank: data.rank,
    uuid: data.uuid,
  };
};

export const sanitizeCompletionInfosFromRaw = (
  data: CompletionInfoRaw[]
): CompletionInfo[] => {
  return data.map((completionInfo) =>
    sanitizeCompletionInfoFromRaw(completionInfo)
  );
};

export const sanitizeCompletionInfoFromRaw = (
  data: CompletionInfoRaw
): CompletionInfo => {
  return {
    isCompleted: data.is_completed,
    userProfileUuid: data.user_profile_uuid,
    name: data.name,
    userAssessmentUuid: data.user_assessment_uuid,
  };
};

export const sanitizePopulationFromRaw = (data: PopulationRaw): Population => {
  return {
    isCompanyWide: data.is_company_wide,
    userProfilesUuids: data.user_profiles_uuids,
  };
};
