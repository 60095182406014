import {createQuery} from 'react-query-kit';
import {fetchUserAssessments} from '../../../repositories/instill/queries';
import {FetchUserAssessmentsPayload} from '../../../repositories/instill/queries/fetch-user-assessments';
import {UserAssessment} from '../../../interfaces/user-assessment';

export const useUserAssessments = createQuery<
  UserAssessment[] | undefined,
  FetchUserAssessmentsPayload
>({
  primaryKey: 'userAssessments',
  queryFn: async ({queryKey: [_, variables]}) => {
    return await fetchUserAssessments(variables.userProfileUuid);
  },
});
