import {createMutation} from 'react-query-kit';
import {deleteValue} from '../../../repositories/instill';
import {DeleteValuePayload} from '../../../repositories/instill/mutations/delete-value';
import {AxiosResponse} from 'axios';

export const useDeleteCompanyCoreValue = createMutation<
  AxiosResponse<any, any> | undefined,
  DeleteValuePayload
>({
  mutationFn: async (variables) => {
    return await deleteValue({
      companyUuid: variables.companyUuid,
      valueUuid: variables.valueUuid,
    });
  },
});
