import {buildPageTitle} from '../../../../services/build-page-title';
import {FunctionComponent, useCallback} from 'react';
import {Helmet} from 'react-helmet-async';
import {ORGANIZATION_CHECKLIST_STEP_KEYS} from '../../@components/setup-checklist/setup-checklist';
import {ReactComponent as IconUsers} from '../../../@components/kit/icons/users.svg';
import {Roles} from '../../../../constants/roles';
import {useInviteUsers} from '../../../@hooks/mutations';
import {useNavigate} from 'react-router-dom';
import {useSafeCurrentCompany} from '../../../@atoms/current-company';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import flattenUserEmailsByRole from '../../../../utils/flatten-user-emails-by-role';
import InviteUsers from '../../@components/invite-users';
import isResponseSuccessful from '../../../../utils/is-response-successful';
import organizationSetupStyles from '../styles.module.scss';
import Permissions from '../../@components/invite-users/@components/permissions';

type RoleValues = Record<Roles, string>;

interface ElementProps {
  stepIdentifier: ORGANIZATION_CHECKLIST_STEP_KEYS;
  updateChecklist: (stepIdentifier: ORGANIZATION_CHECKLIST_STEP_KEYS) => void;
}

const NEXT_STEP_ROUTE = '/application/organization-setup/install-slack-app';

const INITIAL_VALUES: RoleValues = {
  admin: '',
  manager: '',
  member: '',
  owner: '',
};

const InviteYourTeam: FunctionComponent<ElementProps> = ({
  updateChecklist,
  stepIdentifier,
}) => {
  const {t} = useTranslation(['organizationSetup', 'applicationPageTitle']);

  const currentCompany = useSafeCurrentCompany();
  const [isPermissionsDialogVisible, setIsPermissionsDialogVisible] =
    useState(false);

  const navigate = useNavigate();
  const inviteUsers = useInviteUsers();

  const onSkipButtonClick = useCallback(() => {
    updateChecklist(stepIdentifier);
    navigate(NEXT_STEP_ROUTE);
  }, [navigate, updateChecklist, stepIdentifier]);

  const onSubmit = useCallback(
    async (values: RoleValues) => {
      const userInvites = await flattenUserEmailsByRole(values);

      const response = await inviteUsers.mutateAsync({
        userInvites,
        companyUuid: currentCompany.uuid,
      });

      if (isResponseSuccessful(response)) {
        updateChecklist(stepIdentifier);
        navigate(NEXT_STEP_ROUTE);
      }
    },
    [currentCompany, inviteUsers, navigate, updateChecklist, stepIdentifier]
  );

  return (
    <>
      <Helmet>
        <title>
          {buildPageTitle([
            t('page-title.organization-setup.invite-your-team', {
              ns: 'applicationPageTitle',
            }),
            t('page-title.organization-setup.index', {
              ns: 'applicationPageTitle',
            }),
          ])}
        </title>
      </Helmet>

      <div className={organizationSetupStyles.header}>
        <h1 className={organizationSetupStyles.headerTitle}>
          {t('invite-your-team.title')}
        </h1>

        <IconUsers className={organizationSetupStyles.headerIcon} />
      </div>

      <div className={organizationSetupStyles.description}>
        {t('invite-your-team.description')}

        <p>{t('invite-your-team.secondary-description')}</p>
      </div>

      {isPermissionsDialogVisible && (
        <Permissions
          isOpen={isPermissionsDialogVisible}
          onClose={() => setIsPermissionsDialogVisible(false)}
        />
      )}

      <InviteUsers
        formInitialValues={INITIAL_VALUES}
        onSubmit={onSubmit}
        onPermissionsButtonClick={() => setIsPermissionsDialogVisible(true)}
        onSecondaryButtonClick={onSkipButtonClick}
      />
    </>
  );
};

export default InviteYourTeam;
