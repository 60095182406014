import {
  ActionStepSentPayload,
  ActionStepSentRawPayload,
} from '../../../interfaces/notifications/action-step-sent';

const sanitizePayloadFromRaw = (
  data: ActionStepSentRawPayload
): ActionStepSentPayload => {
  return {
    uuid: data.uuid,
    title: data.title,
  };
};

export default sanitizePayloadFromRaw;
