import {Media} from '../../../../interfaces/company-core-value';
import {FunctionComponent} from 'react';
import {MEDIA_TYPE_VIDEO} from '../../../../constants/core-value-media-type';
import Video from './@components/video';
import Imaage from './@components/image';

interface ElementProps {
  media: Media[];
}

const MediaContent: FunctionComponent<ElementProps> = ({media}) => {
  return (
    <>
      {media.map((medium, index) => (
        <div key={index}>
          {medium.type === MEDIA_TYPE_VIDEO ? (
            <Video url={medium.url} />
          ) : (
            <Imaage url={medium.url} />
          )}
        </div>
      ))}
    </>
  );
};

export default MediaContent;
