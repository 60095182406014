import {FunctionComponent, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {CompanyEmployee} from '../../../../../../../../../../../../interfaces/company-employee';
import ReminderDialog from '../reminder-dialog';
import styles from './styles.module.scss';

interface ElementProps {
  employee: CompanyEmployee;
  onSendReminder: (workEmail: string) => void;
}

const PendingState: FunctionComponent<ElementProps> = ({
  employee,
  onSendReminder,
}) => {
  const {t} = useTranslation('people');

  const [isReminderDialogVisible, setIsReminderDialogVisible] = useState(false);

  const onClose = useCallback(() => {
    setIsReminderDialogVisible(false);
  }, [setIsReminderDialogVisible]);

  const onConfirm = useCallback(async () => {
    await onSendReminder(employee.workEmail);

    setIsReminderDialogVisible(false);
  }, [onSendReminder, employee, setIsReminderDialogVisible]);

  return (
    <div className={styles.container}>
      <div className={styles.text}>
        {t(
          'manage-directory.components.employee-list.components.list.components.table-row.components.pending-employee.text'
        )}
      </div>

      <button
        onClick={() => setIsReminderDialogVisible(true)}
        className={styles.button}
      >
        {t(
          'manage-directory.components.employee-list.components.list.components.table-row.components.pending-employee.remind-button'
        )}
      </button>

      <ReminderDialog
        employee={employee}
        isDialogVisible={isReminderDialogVisible}
        onClose={onClose}
        onConfirm={onConfirm}
      />
    </div>
  );
};

export default PendingState;
