import {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import RoundedCard from '../../../../../../../../../@components/rounded-card';
import styles from './styles.module.scss';

interface ElementProps {
  summary: string[];
}

const Summary: FunctionComponent<ElementProps> = ({summary}) => {
  const {t} = useTranslation('sense', {
    keyPrefix:
      'meetings.show.index.components.meeting-details.components.summary',
  });

  return (
    <RoundedCard>
      <RoundedCard.Title>{t('title')}</RoundedCard.Title>

      <RoundedCard.Content>
        <ul className={styles.list}>
          {summary.map((summaryItem, index) => (
            <li className={styles.listItem} key={index}>
              {summaryItem}
            </li>
          ))}
        </ul>
      </RoundedCard.Content>
    </RoundedCard>
  );
};

export default Summary;
