import InstillAPI from '../axios-instance';
import isResponseSuccessful from '../../../utils/is-response-successful';
import {JobPositionUpdate} from '../../../interfaces/job-position';
import {sanitizeJobPositionUpdateForRaw} from '../../../ui/@sanitizers/job-position';

export interface UpdateJobPositionPayload {
  companyUuid: string;
  jobPositionUuid: string;
  values: JobPositionUpdate;
}

export async function updateJobPosition(parameters: UpdateJobPositionPayload) {
  const {companyUuid, values, jobPositionUuid} = parameters;

  const sanitizedPayload = sanitizeJobPositionUpdateForRaw(values);
  const response = await InstillAPI.put(
    `/companies/${companyUuid}/job-positions/${jobPositionUuid}`,
    sanitizedPayload
  );

  if (!isResponseSuccessful(response)) {
    throw Error('No data received');
  }

  return response.data;
}
