import {FunctionComponent} from 'react';
import {Navigate, Route, Routes} from 'react-router';
import MeetingsShowIndex from './index/meetings-show-index';

const MeetingsShow: FunctionComponent = () => {
  return (
    <Routes>
      <Route path="/" element={<MeetingsShowIndex />} />

      <Route
        path="*"
        element={<Navigate replace to="/application/sense/meetings" />}
      />
    </Routes>
  );
};

export default MeetingsShow;
