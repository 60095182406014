import {
  CompanySettings,
  CompanySettingsRaw,
  HRIS_PROVIDER,
} from '../../interfaces/company-settings';

export const sanitizeCompanySettingsFromRaw = (
  data: CompanySettingsRaw
): CompanySettings => {
  return {
    isHRISProviderUKG: data.hris_provider === HRIS_PROVIDER.UKG,
  };
};
