import {FunctionComponent} from 'react';
import {useTransition, animated} from 'react-spring';
import styles from './styles.module.scss';

interface Props {
  isOpen: boolean;
}

const Backdrop: FunctionComponent<Props> = ({isOpen}) => {
  const transition = useTransition(isOpen, {
    from: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
    },
    leave: {
      opacity: 0,
    },
  });

  return (
    <>
      {transition(
        (props, item) =>
          item && <animated.div className={styles.backdrop} style={props} />
      )}
    </>
  );
};

export default Backdrop;
