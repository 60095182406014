import {FunctionComponent, useMemo} from 'react';
import {ActionStepSentPayload} from '../../../interfaces/notifications/action-step-sent';
import {InvitedToAssessmentPayload} from '../../../interfaces/notifications/invite-to-assessment';
import {JobPositionEndedPayload} from '../../../interfaces/notifications/job-position-ended';
import {KudoReceivedPayload} from '../../../interfaces/notifications/kudo';
import {
  LiveNotification,
  NOTIFICATION_EVENT,
} from '../../../interfaces/notification';
import InvitedToAssessment from './@components/invited-to-assessment';
import JobPositionEnded from './@components/job-position-ended/job-position-ended';
import KudoReceived from './@components/kudo-received';
import ActionStepSent from './@components/action-step-sent';

interface ElementProps {
  event: LiveNotification['event'];
  payload: LiveNotification['payload'];
}

export const TRANSLATION_KEY_PREFIX = 'constants.notifications';

const NotificationEvent: FunctionComponent<ElementProps> = ({
  event,
  payload,
}) => {
  const eventTranslation = useMemo(() => {
    switch (event) {
      case NOTIFICATION_EVENT.KUDO_RECEIVED:
        return <KudoReceived payload={payload as KudoReceivedPayload} />;

      case NOTIFICATION_EVENT.INVITED_TO_ASSESSMENT:
        return (
          <InvitedToAssessment
            payload={payload as InvitedToAssessmentPayload}
          />
        );

      case NOTIFICATION_EVENT.ACTION_STEP_SENT:
        return <ActionStepSent payload={payload as ActionStepSentPayload} />;

      case NOTIFICATION_EVENT.JOB_POSITION_ENDED:
        return (
          <JobPositionEnded payload={payload as JobPositionEndedPayload} />
        );

      default:
        return <></>;
    }
  }, [event, payload]);

  return eventTranslation;
};

export default NotificationEvent;
