import {FunctionComponent, useState} from 'react';
import Spinner from '../../../../../@components/spinner';
import styles from './styles.module.scss';

interface ElementProps {
  url: string;
}

const Image: FunctionComponent<ElementProps> = ({url}) => {
  const [loading, setLoading] = useState(true);

  return (
    <>
      {loading && <Spinner />}

      <div className={styles.media}>
        <a href={url} target="_blank" rel="noreferrer noopener">
          <img
            src={url}
            alt=""
            className={styles.image}
            onLoad={() => setLoading(false)}
          />
        </a>
      </div>
    </>
  );
};

export default Image;
