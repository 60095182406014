import {FunctionComponent, PropsWithChildren} from 'react';
import styles from './styles.module.scss';

const Tag: FunctionComponent<
  PropsWithChildren<{
    variant?: string;
    onClick?: () => void;
  }>
> = ({variant, onClick, children}) => {
  let classes = variant ? styles[variant] : '';

  if (onClick) {
    classes = `${classes} ${styles.clickable}`;
  }

  return (
    <div className={`${styles.tag} ${classes}`} onClick={onClick}>
      {children}
    </div>
  );
};

export default Tag;
