import {sanitizeCompanyCoreValueFromRaw} from '../../../ui/@sanitizers/company-core-values';
import {CoreValueFormToRaw} from '../../../interfaces/company-core-value';
import InstillAPI from '../axios-instance';

export interface UpdateCompanyCoreValuePayload {
  payload: CoreValueFormToRaw;
  companyUuid: string;
  coreValueUuid: string;
}

export async function updateCompanyCoreValue(
  payload: CoreValueFormToRaw,
  companyUuid: string,
  coreValueUuid: string
) {
  const response = await InstillAPI.put(
    `/companies/${companyUuid}/values/${coreValueUuid}`,
    payload
  );

  return sanitizeCompanyCoreValueFromRaw(response.data);
}
