import {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import Button from '../../../../@components/kit/form/button';
import Dialog from '../../../../@components/kit/dialog';
import logoSource from './assets/instill-dialog-logo.svg';
import styles from './styles.module.scss';

interface ElementProps {
  isVisible: boolean;
  onClose: () => void;
}

const WelcomeDialog: FunctionComponent<ElementProps> = ({
  isVisible,
  onClose,
}) => {
  const {t} = useTranslation('dashboard');

  return (
    <>
      <Dialog isOpen={isVisible} onClose={onClose}>
        <Dialog.Header />

        <Dialog.Content>
          <div className={styles.container}>
            <img src={logoSource} alt="" />

            <div className={styles.message}>
              {t('components.welcome-dialog.message')}
            </div>

            <div className={styles.messageSubText}>
              {t('components.welcome-dialog.sub-text')}
            </div>

            <Button type="button" onClick={onClose}>
              {t('components.welcome-dialog.button-text')}
            </Button>
          </div>
        </Dialog.Content>
      </Dialog>
    </>
  );
};

export default WelcomeDialog;
