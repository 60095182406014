import {sanitizeCandidateProfileFromRaw} from '../../../ui/@sanitizers/candidate';
import InstillAPI from '../axios-instance';
import isResponseSuccessful from '../../../utils/is-response-successful';

export interface FetchCandidateProfilePayload {
  candidateToken: string;
}

export async function fetchCandidateProfile({
  candidateToken,
}: FetchCandidateProfilePayload) {
  try {
    // set token as header for Authorization Bearer only for this request

    const response = await InstillAPI.get('/candidate/', {
      headers: {
        Authorization: `Bearer ${candidateToken}`,
      },
    });

    if (!response.data || !isResponseSuccessful(response)) {
      throw Error('No data received');
    }

    return sanitizeCandidateProfileFromRaw(response.data);
  } catch (error) {
    console.error(error);
  }
}
