export const LINKEDIN_ORGANIZATION_REGEX =
  /^(https?:\/\/)?(www\.)?linkedin\.com\/(company|school)\/[a-zA-Z0-9\-_]+\/?$/;

export const LINKEDIN_PERSONAL_REGEX =
  /^(https?:\/\/)?(www\.)?linkedin\.com\/(in)\/[a-zA-Z0-9\-_]+\/?$/;

export const EMAIL_DOMAIN_REGEX =
  /([-\]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

export const WEBSITE_URL_REGEX =
  /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w?[a-zA-Z-_%/@?]+)*([^/\w?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/;

export const WEBSITE_PROTOCOL_REGEX =
  /^(http(s)?:\/\/)[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/;
