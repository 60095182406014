import {FunctionComponent} from 'react';
import {FullUserProfile} from '../../../../../../interfaces/user-profile';
import {useTranslation} from 'react-i18next';
import KudosList, {KUDOS_SOURCES} from '../../../kudos-list';
import profileStyles from '../../styles.module.scss';

interface ElementProps {
  source: KUDOS_SOURCES;
  profile: FullUserProfile;
}

const MAXIMUM_NUMBER_OF_KUDOS = 10;

const KudosContainer: FunctionComponent<ElementProps> = ({profile, source}) => {
  const {t} = useTranslation('application');

  return (
    <div className={profileStyles.box}>
      <h2 className={profileStyles.boxTitle}>
        {t('components.profile-details.components.kudos-list.title')}
      </h2>

      <KudosList
        maximumNumberOfKudos={MAXIMUM_NUMBER_OF_KUDOS}
        source={source}
        user={profile}
      />
    </div>
  );
};

export default KudosContainer;
