import {CultureVitalSign} from '../../../../../../../../../../../../interfaces/meeting';
import {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import styles from './styles.module.scss';

interface ElementProps {
  cultureVitalSign: CultureVitalSign;
}

const SelectedTabView: FunctionComponent<ElementProps> = ({
  cultureVitalSign,
}) => {
  const {t} = useTranslation('sense', {
    keyPrefix:
      'meetings.show.index.components.meeting-details.components.culture-vitals.components.selected-tab-view',
  });

  const feedbackArray = cultureVitalSign.feedback.split('. '); // this could be done at BE

  return (
    <div className={styles.container}>
      <div className={styles.title}>{t('feedback-title')}</div>

      <ul className={styles.list}>
        {feedbackArray.map((sentence, index) => (
          <li key={index} className={styles.listItem}>
            {sentence}
          </li>
        ))}
      </ul>

      <div className={styles.title}>{t('examples-title')}</div>

      <ul className={styles.list}>
        {cultureVitalSign.examples.map((example, index) => (
          <li key={index} className={styles.listItem}>
            {example.explanation}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SelectedTabView;
