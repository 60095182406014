import {CoreValueForm} from '../../../../../../../../interfaces/company-core-value';
import {fetchCoreValues} from '../../../../../../../../repositories/instill';
import {Field, FieldProps, FormikErrors, FormikTouched} from 'formik';
import {FunctionComponent, useCallback, useState} from 'react';
import {useSafeCurrentCompany} from '../../../../../../../@atoms/current-company';
import {useTranslation} from 'react-i18next';
import BehaviorsForm from '../../../../../../@components/behaviors-form';
import {CoreValue} from '../../../../../../../../interfaces/core-value';
import FormControl from '../../../../../../../@components/kit/form/form-control';
import FormGroup from '../../../../../../../@components/kit/form/form-group';
import Label from '../../../../../../../@components/kit/form/label';
import MutableSelect, {
  Option,
} from '../../../../../../../@components/kit/form/hybrid-select/mutable-select';
import styles from './styles.module.scss';
import RichTextEditor from '../../../../../../../@components/kit/form/rich-text-editor';

interface Errors {
  [key: string]: string;
}

interface CoreValueFormSchema {
  coreValue: CoreValueForm;
}

interface ElementProps {
  formValues: CoreValueForm;
  errors?: FormikErrors<CoreValueFormSchema> | Errors;
  touched?: FormikTouched<CoreValueFormSchema>;
}

const ValueForm: FunctionComponent<ElementProps> = ({
  formValues,
  errors,
  touched,
}) => {
  const {t} = useTranslation('values');
  const currentCompany = useSafeCurrentCompany();

  const [coreValueOptions, setCoreValueOptions] = useState<CoreValue[]>([]);
  const [coreValueNameOptions, setCoreValueNameOptions] = useState<
    Option[] | null
  >(null);

  const onFetchCoreValues = useCallback(async () => {
    const response = await fetchCoreValues();

    if (!response) {
      setCoreValueNameOptions([]);
      return;
    }

    setCoreValueOptions(response);

    const coreValues = response.map((coreValue: CoreValue) => ({
      label: coreValue.name,
      value: coreValue.name,
    }));

    setCoreValueNameOptions(coreValues);
  }, []);

  const getCoreValueOptions = useCallback(() => {
    if (coreValueNameOptions === null) {
      onFetchCoreValues();
    }

    return coreValueNameOptions || [];
  }, [coreValueNameOptions, onFetchCoreValues]);

  const coreValueErrors = errors?.coreValue as CoreValueForm;

  const showCoreValueNameError = Boolean(
    touched?.coreValue?.name && coreValueErrors?.name
  );

  const showCoreValueDescriptionError = Boolean(
    touched?.coreValue?.description && coreValueErrors?.description
  );

  const onEditorStateChange = useCallback(
    (htmlValue: string, form: FieldProps['form']) => {
      form.setFieldValue('coreValue.description', htmlValue);
    },
    []
  );

  return (
    <>
      <div className={styles.row}>
        <Field name={`coreValue.name`}>
          {({field, form, meta}: FieldProps<string>) => (
            <FormGroup>
              <FormControl
                error={
                  showCoreValueNameError ? coreValueErrors?.name : undefined
                }
              >
                <Label isRequired htmlFor={field.name}>
                  {t('components.value.components.value-form.form.name.label')}
                </Label>

                <MutableSelect
                  field={field}
                  form={form}
                  meta={meta}
                  defaultValue={{
                    value: formValues.name,
                    label: formValues.name,
                  }}
                  options={getCoreValueOptions()}
                  placeholder={t(
                    'components.value.components.value-form.form.name.placeholder'
                  )}
                  isMulti={false}
                />
              </FormControl>
            </FormGroup>
          )}
        </Field>

        <Field name={`coreValue.description`}>
          {({form, field}: FieldProps<string>) => (
            <FormGroup className={styles.formGroup}>
              <FormControl
                error={
                  showCoreValueDescriptionError
                    ? coreValueErrors.description
                    : undefined
                }
              >
                <Label isRequired htmlFor={field.name}>
                  {t(
                    'components.value.components.value-form.form.description.label',
                    {
                      companyName: currentCompany.name,
                    }
                  )}
                </Label>

                <RichTextEditor
                  placeholder={t(
                    'components.values.components.value-form.form.description.placeholder'
                  )}
                  initialValue={field.value}
                  onChange={(value) => onEditorStateChange(value, form)}
                />
              </FormControl>
            </FormGroup>
          )}
        </Field>

        <div className={styles.behaviorContainer}>
          <div className={styles.formGroupLabel}>
            {t(
              'components.value.components.value-form.form.norms-and-behaviors.label'
            )}
          </div>

          <BehaviorsForm
            fieldNamePrefix={'coreValue'}
            valueName={formValues.name}
            valueExpectations={formValues.expectations}
            coreValueOptions={coreValueOptions}
          />
        </div>
      </div>
    </>
  );
};

export default ValueForm;
