import {CompanyCoreValue} from '../../../../../../interfaces/company-core-value';
import {DEFAULT_TOASTIFY_CONFIGURATION} from '../../../../../../constants/toastify-configuration';
import {Field, FieldProps, Form, Formik} from 'formik';
import {FullUserProfile} from '../../../../../../interfaces/user-profile';
import {FunctionComponent, useCallback, useMemo} from 'react';
import {toast, ToastOptions} from 'react-toastify';
import {Trans, useTranslation} from 'react-i18next';
import {
  useCompanyCoreValues,
  useCompanyEmployees,
} from '../../../../../@hooks/queries';
import {useCreateKudo} from '../../../../../@hooks/mutations/use-create-kudo';
import {useCurrentFullUserProfile} from '../../../../../@atoms/current-full-user-profile';
import {useSafeCurrentCompany} from '../../../../../@atoms/current-company';
import * as Yup from 'yup';
import Button from '../../../../../@components/kit/form/button/button';
import Dialog from '../../../../../@components/kit/dialog';
import FormControl from '../../../../../@components/kit/form/form-control';
import FormFooter from '../../../../../@components/kit/form/form-footer';
import FormGroup from '../../../../../@components/kit/form/form-group';
import Label from '../../../../../@components/kit/form/label';
import NoKudosLeft from './@components/no-kudos-left';
import Select from '../../../../../@components/kit/form/select';
import styles from './styles.module.scss';
import RichTextEditor from '../../../../../@components/kit/form/rich-text-editor/rich-text-editor';
import {getInnerTextLength} from '../../../../../../utils/html-helpers';
import {sortByName} from '../../../../../../utils/sort-by/name';

interface ElementProps {
  isVisible: boolean;
  onClose: () => void;
  onGiveKudosSuccess: () => void;
  user?: FullUserProfile;
  value?: CompanyCoreValue;
}

const MAXIMUM_NUMBER_OF_CHARACTERS_FOR_MESSAGE = 1000;

interface InitialValues {
  selectedValue: string;
  selectedUser: string;
  message: string;
}

const GiveKudosDialog: FunctionComponent<ElementProps> = ({
  isVisible,
  onClose,
  onGiveKudosSuccess,
  user,
  value,
}) => {
  const {t} = useTranslation('application');

  const currentCompany = useSafeCurrentCompany();
  const currentFullUserProfile = useCurrentFullUserProfile();

  const validationSchema = Yup.object().shape({
    selectedValue: Yup.string().required(
      t('form.error.required', {ns: 'common'})
    ),
    selectedUser: Yup.string().required(
      t('form.error.required', {ns: 'common'})
    ),
    message: Yup.string()
      .max(
        MAXIMUM_NUMBER_OF_CHARACTERS_FOR_MESSAGE,
        t(
          'components.kudos-list.components.give-kudos-dialog.form.message.error'
        )
      )
      .min(
        50,
        t(
          'components.kudos-list.components.give-kudos-dialog.form.message.error'
        )
      )
      .required(t('form.error.required', {ns: 'common'})),
  });

  const coreValues = useCompanyCoreValues({
    variables: {
      companyUuid: currentCompany.uuid,
    },
  });

  const employees = useCompanyEmployees({
    variables: {
      companyUuid: currentCompany.uuid,
    },
  });

  const createKudo = useCreateKudo();

  const valuesForDropdown = useMemo(() => {
    return coreValues.data?.map((value) => {
      return {
        label: value.name,
        value: value.uuid,
      };
    });
  }, [coreValues]);

  const employeesForDropdown = useMemo(() => {
    const filteredEmployees = employees.data?.data.filter((employee) => {
      return employee.userProfileUuid !== currentFullUserProfile.uuid;
    });
    if (!filteredEmployees) return;

    const sortedEmployees = sortByName(filteredEmployees);
    if (!sortedEmployees) return;

    return sortedEmployees.map((employee) => {
      return {
        label: employee.name,
        value: employee.userProfileUuid,
      };
    });
  }, [currentFullUserProfile, employees]);

  const initialValues = useMemo(() => {
    return {
      selectedValue:
        valuesForDropdown?.find(
          (currentValue) => currentValue.value === value?.uuid
        )?.value ?? '',
      selectedUser:
        employeesForDropdown?.find(
          (currentEmployee) => currentEmployee.value === user?.uuid
        )?.value ?? '',
      message: '',
    } as InitialValues;
  }, [valuesForDropdown, value, employeesForDropdown, user]);

  const isFormReady = useMemo(() => {
    if (user) return initialValues.selectedUser;
    if (value) return initialValues.selectedValue;

    return true;
  }, [user, value, initialValues]);

  const isKudoRemainingEqualToZero = useMemo(() => {
    const kudosRemaining = currentFullUserProfile.kudosRemaining ?? 0;

    return kudosRemaining <= 0;
  }, [currentFullUserProfile]);

  const onFormSubmit = useCallback(
    async (values: InitialValues) => {
      const companyUuid = currentCompany.uuid;
      const payload = {
        valueUuid: values.selectedValue,
        toUserUuid: values.selectedUser,
        message: values.message,
      };

      const response = await createKudo.mutateAsync({companyUuid, payload});
      if (!response) return;

      const feedbackMessage = t(
        'components.kudos-list.components.give-kudos-dialog.add-kudo-success-message'
      );
      toast.success(feedbackMessage, {
        ...(DEFAULT_TOASTIFY_CONFIGURATION as ToastOptions),
      });

      onClose();
      onGiveKudosSuccess();
    },
    [currentCompany, createKudo, onGiveKudosSuccess, onClose, t]
  );

  const onEditorStateChange = useCallback(
    (htmlValue: string, form: FieldProps['form']) => {
      form.setFieldValue('message', htmlValue);
    },
    []
  );
  if (!isFormReady) return null;

  return (
    <Dialog isOpen={isVisible} onClose={onClose}>
      <Dialog.Header
        title={t('components.kudos-list.components.give-kudos-dialog.title')}
      />

      <Dialog.Content>
        {isKudoRemainingEqualToZero && <NoKudosLeft />}

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnMount
          onSubmit={onFormSubmit}
        >
          {({errors, touched, isValid}) => (
            <Form>
              <Field name="selectedUser">
                {({field}: FieldProps<string, InitialValues>) => (
                  <FormGroup>
                    <FormControl
                      error={
                        Boolean(errors.selectedUser && touched.selectedUser)
                          ? errors.selectedUser
                          : undefined
                      }
                    >
                      <div
                        className={
                          isKudoRemainingEqualToZero ? styles.disabled : ''
                        }
                      >
                        <Label isRequired>
                          {t(
                            'components.kudos-list.components.give-kudos-dialog.form.recipient.label'
                          )}
                        </Label>

                        <Select
                          disabled={isKudoRemainingEqualToZero}
                          id={field.name}
                          {...field}
                        >
                          <option value="" disabled>
                            {t(
                              'components.kudos-list.components.give-kudos-dialog.form.recipient.placeholder'
                            )}
                          </option>

                          {employeesForDropdown?.map(({label, value}) => (
                            <option key={value} value={value}>
                              {t(label)}
                            </option>
                          ))}
                        </Select>
                      </div>
                    </FormControl>
                  </FormGroup>
                )}
              </Field>

              <Field name="selectedValue">
                {({field}: FieldProps<string, InitialValues>) => (
                  <FormGroup>
                    <FormControl
                      error={
                        Boolean(errors.selectedValue && touched.selectedValue)
                          ? errors.selectedValue
                          : undefined
                      }
                    >
                      <div
                        className={
                          isKudoRemainingEqualToZero ? styles.disabled : ''
                        }
                      >
                        <Label isRequired>
                          {t(
                            'components.kudos-list.components.give-kudos-dialog.form.value.label'
                          )}
                        </Label>

                        <Select
                          disabled={isKudoRemainingEqualToZero}
                          id={field.name}
                          {...field}
                        >
                          <option value="" disabled>
                            {t(
                              'components.kudos-list.components.give-kudos-dialog.form.value.placeholder'
                            )}
                          </option>

                          {valuesForDropdown?.map(({label, value}) => (
                            <option key={value} value={value}>
                              {t(label)}
                            </option>
                          ))}
                        </Select>
                      </div>
                    </FormControl>
                  </FormGroup>
                )}
              </Field>

              <Field name="message">
                {({form, field}: FieldProps<string, InitialValues>) => (
                  <FormGroup>
                    <FormControl
                      error={
                        Boolean(errors.message) ? errors.message : undefined
                      }
                    >
                      <div
                        className={
                          isKudoRemainingEqualToZero ? styles.disabled : ''
                        }
                      >
                        <Label isRequired>
                          {t(
                            'components.kudos-list.components.give-kudos-dialog.form.message.label'
                          )}
                        </Label>
                        <RichTextEditor
                          disabled={isKudoRemainingEqualToZero}
                          placeholder={t(
                            'components.kudos-list.components.give-kudos-dialog.form.message.placeholder'
                          )}
                          initialValue={field.value}
                          onChange={(value) => onEditorStateChange(value, form)}
                        />

                        <div className={styles.characterCount}>
                          <Trans
                            t={t}
                            i18nKey="components.kudos-list.components.give-kudos-dialog.form.message.character-count"
                            values={{
                              currentLength: getInnerTextLength(field.value),
                              totalLength:
                                MAXIMUM_NUMBER_OF_CHARACTERS_FOR_MESSAGE,
                            }}
                          />
                        </div>
                      </div>
                    </FormControl>
                  </FormGroup>
                )}
              </Field>

              <FormFooter>
                <Button
                  type="submit"
                  disabled={!isValid || isKudoRemainingEqualToZero}
                >
                  {t(
                    'components.kudos-list.components.give-kudos-dialog.form.submit-button'
                  )}
                </Button>

                <Button variant="secondary" onClick={onClose}>
                  {t(
                    'components.kudos-list.components.give-kudos-dialog.form.cancel-button'
                  )}
                </Button>
              </FormFooter>
            </Form>
          )}
        </Formik>
      </Dialog.Content>
    </Dialog>
  );
};

export default GiveKudosDialog;
