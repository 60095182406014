import {fetchAndUpsertUser, isUserLinked} from '../../../repositories/instill';
import {setAuthorizationToken} from '../../../repositories/instill/axios-instance';
import {useAuth0} from '@auth0/auth0-react';
import {useCurrentAuthUserState} from '../../@atoms/current-auth-user';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import popStorageKey from '../../../utils/local-storage/pop';

const EMAIL_VERIIFIED_STORAGE_KEY = 'Instill-emailVerified';

function useFetchAndUpsertUser() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<unknown | null>(null);
  const [userProviders, setUserProviders] = useState<string[]>([]);
  const [isAccountLinked, setIsAccountLinked] = useState<boolean>(false);
  const [currentAuthUser, setCurrentAuthUser] = useCurrentAuthUserState();
  const navigate = useNavigate();

  const {
    isLoading: auth0IsLoading,
    error: auth0Error,
    isAuthenticated,
    getAccessTokenSilently,
    getIdTokenClaims,
  } = useAuth0();

  useEffect(() => {
    async function execute() {
      if (auth0IsLoading || auth0Error) return;

      if (!isAuthenticated) {
        setCurrentAuthUser(null);
        setIsLoading(false);

        return;
      }

      if (currentAuthUser) return;

      try {
        setIsLoading(true);

        const ignoreCache = !!popStorageKey(EMAIL_VERIIFIED_STORAGE_KEY);
        const accessToken = await getAccessTokenSilently({ignoreCache});

        setAuthorizationToken(accessToken);

        const idToken = await getIdTokenClaims();

        if (!idToken) return;

        const response = await fetchAndUpsertUser(idToken.__raw, accessToken);

        if (isUserLinked(response)) {
          setIsLoading(false);
          setIsAccountLinked(true);
          setUserProviders(response.providers);

          return;
        }

        const currentUser = response.createdUser;
        setCurrentAuthUser(currentUser);

        setError(null);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    }

    execute();
  }, [
    auth0Error,
    auth0IsLoading,
    currentAuthUser,
    getAccessTokenSilently,
    getIdTokenClaims,
    isAuthenticated,
    navigate,
    setCurrentAuthUser,
  ]);

  return {
    currentAuthUser,
    isLoading,
    error: auth0Error || error,
    isAccountLinked,
    userProviders,
  };
}

export default useFetchAndUpsertUser;
