import {Actions} from '../../../constants/permissions/actions';
import {getUserProfileForCompany} from '../../../services/user-profiles';
import {Navigate, Route, Routes} from 'react-router';
import {useCurrentAuthUserState} from '../../@atoms/current-auth-user';
import {useSafeCurrentCompany} from '../../@atoms/current-company';
import {useTranslation} from 'react-i18next';
import {useUserPermission} from '../../@hooks/use-user-permission';
import Employees from './employees';
import FeedbackBanner from '../../@components/feedback-banner';
import Header from '../@components/header';
import MainContent from '../@components/main-content';
import ManageDirectory from './manage-directory';
import Teams from './teams';

function People() {
  const {t} = useTranslation('people');

  const [currentAuthUser] = useCurrentAuthUserState();
  const currentCompany = useSafeCurrentCompany();

  const userProfile = getUserProfileForCompany(
    currentAuthUser!,
    currentCompany!
  );
  const can = useUserPermission(userProfile.role);

  let links = [
    {
      route: '/application/people/employees',
      label: t('components.header.sub-navigation.links.employees'),
    },
    {
      route: '/application/people/teams',
      label: t('components.header.sub-navigation.links.teams'),
    },
  ];

  if (can(Actions.VIEW_MANAGE_DIRECTORY)) {
    links.push({
      route: '/application/people/manage-directory',
      label: t('components.header.sub-navigation.links.manage-directory'),
    });
  }

  return (
    <>
      <FeedbackBanner text={t('components.feedback-banner.text')} />

      <Header title={t('components.header.title')}>
        <Header.SubNavigation links={links} />
      </Header>

      <MainContent>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Navigate replace to="employees" />
              </>
            }
          />

          <Route path="/employees/*" element={<Employees />} />
          <Route path="/teams" element={<Teams />} />
          <Route path="/manage-directory" element={<ManageDirectory />} />
          <Route path="*" element={<Navigate replace to="employees" />} />
        </Routes>
      </MainContent>
    </>
  );
}

export default People;
