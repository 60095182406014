import {NotificationSettings} from '../../../interfaces/notification-settings';
import {sanitizeNotificationSettingsForRaw} from '../../../ui/@sanitizers/notification-settings';
import InstillAPI from '../axios-instance';
import isResponseSuccessful from '../../../utils/is-response-successful';

export interface UpdateNotificationSettingsPayload {
  payload: NotificationSettings;
  companyUuid: string;
}

export async function updateNotificationSettings(
  companyUuid: string,
  payload: NotificationSettings
) {
  const sanitizedPayload = sanitizeNotificationSettingsForRaw(payload);

  const response = await InstillAPI.put(
    `/companies/${companyUuid}/user-profiles/me/notification-settings`,
    sanitizedPayload
  );

  if (!isResponseSuccessful(response)) {
    throw Error('No data received');
  }

  return response.data;
}
