import {Team, TeamRaw, TeamUpsert, TeamUpsertRaw} from '../../interfaces/team';
import {sanitizeUserProfileFromRaw} from './user-profile';

export const sanitizeTeamsFromRaw = (data: TeamRaw[]): Team[] => {
  return data.map((team) => sanitizeTeamFromRaw(team));
};

export const sanitizeTeamFromRaw = (data: TeamRaw): Team => {
  return {
    uuid: data.uuid,
    name: data.name,
    colorHex: data.color_hex,
    teamSize: data.team_size,
    manager: data.manager
      ? sanitizeUserProfileFromRaw(data.manager)
      : undefined,
    description: data.description,
    members: data.members.map((member) => sanitizeUserProfileFromRaw(member)),
  };
};

export const sanitizeTeamForRaw = (data: TeamUpsert): TeamUpsertRaw => {
  return {
    name: data.name,
    description: data.description,
    color_hex: data.colorHex,
    members_uuids: data.membersUuids,
    manager_uuid: data.managerUuid,
  };
};

export const sanitizeCreateTeamForRaw = (data: TeamUpsert): TeamUpsertRaw => {
  return {
    name: data.name,
    description: data.description,
    color_hex: data.colorHex,
    manager_uuid: data.managerUuid,
    members_uuids: data.membersUuids,
  };
};
