import {Signature} from '../../../interfaces/signature';
import {
  sanitizeSignatureFromRaw,
  sanitizeSignatureToRaw,
} from '../../../ui/@sanitizers/signature';
import InstillAPI from '../axios-instance';

export interface CreateSignaturePayload {
  signaturePayload: Signature;
  companyUuid: string;
}

export async function createSignature(
  signaturePayload: Signature,
  companyUuid: string
) {
  const signature = sanitizeSignatureToRaw(signaturePayload);

  const response = await InstillAPI.post(
    `/signatures/companies/${companyUuid}`,
    signature
  );

  return sanitizeSignatureFromRaw(response.data);
}
