import {FunctionComponent} from 'react';
import {CultureDesignModuleResult} from '../../../../../../interfaces/user-assessment-module-result';
import styles from './styles.module.scss';
import {useTranslation} from 'react-i18next';

interface ElementProps {
  moduleResult: CultureDesignModuleResult;
}

const CultureDesignSummary: FunctionComponent<ElementProps> = ({
  moduleResult,
}) => {
  const {t} = useTranslation('assessmentSummary');

  return (
    <>
      <div className={styles.titleRow}>
        <h2 className={styles.heading}>
          {t(
            'components.module-summary.components.culture-design-summary.heading'
          )}
        </h2>
      </div>
      <p>
        {t(
          'components.module-summary.components.culture-design-summary.introduction'
        )}
      </p>

      <ul className={styles.list}>
        {moduleResult.coreValues.map((coreValue) => (
          <li className={styles.item}>
            <div className={styles.valueTitle}>{coreValue.title}</div>
            <p>{coreValue.description}</p>
          </li>
        ))}
      </ul>
    </>
  );
};

export default CultureDesignSummary;
