import {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import linkSlackStyles from '../../../../../../styles.module.scss';

const WorkspaceAlreadyLinked: FunctionComponent = () => {
  const {t} = useTranslation('linkSlack', {
    keyPrefix:
      'components.workspace.components.instruction.components.workspace-already-linked',
  });

  return (
    <div className={linkSlackStyles.box}>
      <div className={linkSlackStyles.boxTitle}>{t('title')}</div>
    </div>
  );
};

export default WorkspaceAlreadyLinked;
