import {
  Kudo,
  KudoFormValues,
  KudoRaw,
  KudoUser,
  KudoUserRaw,
  KudoValue,
  KudoValueRaw,
  KudoVisibility,
  KudoVisibilityRaw,
} from '../../interfaces/kudo';

export const sanitizeKudosFromRaw = (data: KudoRaw[]): Kudo[] => {
  return data.map((item: KudoRaw) => sanitizeKudoFromRaw(item));
};

export const sanitizeKudoFromRaw = (data: KudoRaw): Kudo => {
  return {
    createdAt: data.created_at,
    description: data.description,
    fromUser: sanitizeKudoUserFromRaw(data.from_user),
    isHidden: data.is_hidden,
    toUser: sanitizeKudoUserFromRaw(data.to_user),
    uuid: data.uuid,
    value: sanitizeKudoValueFromRaw(data.value),
    valueId: data.value_id,
  };
};

export const sanitizeKudoUserFromRaw = (data: KudoUserRaw): KudoUser => {
  return {
    name: data.name,
    uuid: data.uuid,
    picture: data.signed_picture_url,
  };
};

export const sanitizeKudoValueFromRaw = (data: KudoValueRaw): KudoValue => {
  return {
    name: data.name,
    description: data.description,
    uuid: data.uuid,
  };
};

export const sanitizeKudoToRaw = (data: KudoFormValues) => {
  return {
    description: data.message,
    to_user_uuid: data.toUserUuid,
    value_uuid: data.valueUuid,
  };
};

export const sanitizeKudoVisibilityToRaw = (
  data: KudoVisibility
): KudoVisibilityRaw => {
  return {
    is_hidden: data.isHidden,
  };
};
