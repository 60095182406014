import {FunctionComponent} from 'react';
import styles from './styles.module.scss';

interface ElementProps {
  currentProgress: number;
  total: number;
  variant?: 'small' | 'xsmall' | 'flexible';
}

const ProgressBar: FunctionComponent<ElementProps> = ({
  currentProgress,
  total,
  variant,
}) => {
  const progress = Math.round((currentProgress / total) * 100);

  return (
    <>
      <div
        className={`${styles.bar} ${variant ? styles[variant] : 'flexible'}`}
      >
        <div className={styles.progress} style={{width: `${progress}%`}} />
      </div>
    </>
  );
};

export default ProgressBar;
