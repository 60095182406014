import {FunctionComponent, PropsWithChildren, useMemo, useState} from 'react';
import {useCurrentAuthUserState} from '../../../@atoms/current-auth-user';
import {useCurrentFullUserProfile} from '../../../@atoms/current-full-user-profile';
import {useTransition, animated} from 'react-spring';
import NotificationCenter from './@components/notification-center';
import styles from './styles.module.scss';
import SubNavigation from './@components/sub-navigation';
import UserNavigation from './@components/user-navigation';

interface ElementProps {
  title?: string;
  surtitle?: string;
  spacing?: string;
  variant?: string;
}

interface HeaderComponent
  extends FunctionComponent<PropsWithChildren<ElementProps>> {
  SubNavigation: typeof SubNavigation;
}

const Header: HeaderComponent = ({
  children,
  spacing,
  surtitle,
  title,
  variant,
}) => {
  const currentFullUserProfile = useCurrentFullUserProfile();
  const [currentAuthUser] = useCurrentAuthUserState();

  const [isNavigationVisible, setIsNavigationVisible] = useState(false);
  const [container, setContainer] = useState<HTMLDivElement | null>(null);

  const transitions = useTransition(isNavigationVisible, {
    from: {
      opacity: 0,
      y: 50,
    },
    enter: {
      opacity: 1,
      y: 0,
    },
    leave: {
      opacity: 0,
      y: 50,
    },
  });

  const userProfilePicture =
    currentFullUserProfile.picture ?? currentAuthUser?.picture;

  const userName =
    currentFullUserProfile.preferredName ||
    currentFullUserProfile.name ||
    currentAuthUser?.name;

  const additionnalClasses = useMemo(() => {
    const classes = [];

    if (spacing) classes.push(styles[spacing]);
    if (variant) classes.push(styles[variant]);

    if (!classes.length) return;

    return classes.join(' ');
  }, [spacing, variant]);

  return (
    <header className={`${styles.header} ${additionnalClasses}`}>
      <div className={styles.wrapper}>
        <div className={styles.metaHeader}>
          <NotificationCenter />

          <div className={styles.user} ref={setContainer}>
            <button
              type="button"
              className={styles.button}
              onClick={() => setIsNavigationVisible(true)}
            >
              {userProfilePicture && (
                <img
                  src={userProfilePicture}
                  alt=""
                  className={styles.userAvatar}
                  role="presentation"
                />
              )}

              <div className={styles.userName}>{userName}</div>
            </button>

            {transitions(
              (props, item) =>
                item && (
                  <animated.div
                    className={styles.userNavigationContainer}
                    style={props}
                  >
                    <UserNavigation
                      clickOutsideElement={container}
                      onClose={() => setIsNavigationVisible(false)}
                    />
                  </animated.div>
                )
            )}
          </div>
        </div>

        <h1 className={styles.title}>
          {surtitle && <div className={styles.surtitle}>{surtitle}</div>}

          {title && title}
        </h1>

        {children && <div className={styles.yieldContainer}>{children}</div>}
      </div>
    </header>
  );
};

Header.SubNavigation = SubNavigation;

export default Header;
