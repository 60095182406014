import {NewRecruitRaw} from '../../../interfaces/map-your-organization';
import InstillAPI from '../axios-instance';
import isResponseSuccessful from '../../../utils/is-response-successful';

export interface UpsertOpenRolesPayload {
  openRolesPayload: NewRecruitRaw[];
  companyUuid: string;
}

export async function upsertOpenRoles(
  openRolesPayload: NewRecruitRaw[],
  companyUuid: string
) {
  const response = await InstillAPI.post(
    `/companies/${companyUuid}/user-profiles/me/recruits`,
    openRolesPayload
  );

  if (!isResponseSuccessful(response)) {
    throw Error('No data received');
  }

  return response.data;
}
