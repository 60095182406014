import {createMutation} from 'react-query-kit';
import {updateWorkStylePreferences} from '../../../repositories/instill/mutations';
import {UpdateWorkStylePreferencesPayload} from '../../../repositories/instill/mutations/update-work-style-preferences';
import {WorkStylePreferences} from '../../../interfaces/work-style-preferences';

export const useUpdateWorkStylePreferences = createMutation<
  WorkStylePreferences | undefined,
  UpdateWorkStylePreferencesPayload
>({
  mutationFn: async (variables) => {
    return await updateWorkStylePreferences(
      variables.workStylePreferences,
      variables.companyUuid
    );
  },
});
