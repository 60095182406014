import {FunctionComponent} from 'react';
import {NavLink} from 'react-router-dom';
import {ReactComponent as IconSadSmiley} from '../../../../../../../@components/kit/icons/sad-smiley.svg';
import {useTranslation} from 'react-i18next';
import styles from './styles.module.scss';

const NoReportsBox: FunctionComponent = () => {
  const {t} = useTranslation('measure');

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <IconSadSmiley className={styles.icon} />

        <div className={styles.text}>
          {t('your-reports.components.no-report.text')}
        </div>
      </div>

      <NavLink
        to="/application/measure/your-assessments"
        className={styles.button}
      >
        {t('your-reports.components.no-report.view-assessments-button')}
      </NavLink>
    </div>
  );
};

export default NoReportsBox;
