import {Actions} from '../../../../../../../../constants/permissions/actions';
import {Dispatch, FunctionComponent, SetStateAction} from 'react';
import {getUserProfileForCompany} from '../../../../../../../../services/user-profiles';
import {ReactComponent as IconDelete} from '../../../../../../../@components/kit/icons/trash.svg';
import {ReactComponent as IconEdit} from '../../../../../../../@components/kit/icons/edit.svg';
import {ReactComponent as IconSave} from '../../../../../../../@components/kit/icons/save.svg';
import {useCurrentAuthUserState} from '../../../../../../../@atoms/current-auth-user';
import {useSafeCurrentCompany} from '../../../../../../../@atoms/current-company';
import {useTranslation} from 'react-i18next';
import {useUserPermission} from '../../../../../../../@hooks/use-user-permission';
import Button from '../../../../../../../@components/kit/form/button';
import styles from './styles.module.scss';

interface ElementProps {
  isEditMode: boolean;
  onDelete: () => void;
  setIsEditMode: Dispatch<SetStateAction<boolean>>;
}

const Header: FunctionComponent<ElementProps> = ({
  isEditMode,
  onDelete,
  setIsEditMode,
}) => {
  const {t} = useTranslation('values');

  const [currentAuthUser] = useCurrentAuthUserState();
  const currentCompany = useSafeCurrentCompany();
  const userProfile = getUserProfileForCompany(
    currentAuthUser!,
    currentCompany
  );
  const can = useUserPermission(userProfile.role);

  return (
    <div className={styles.container}>
      <div className={styles.actions}>
        {isEditMode ? (
          <Button
            variant="secondary"
            type="button"
            onClick={() => setIsEditMode(false)}
          >
            {t('components.value.components.header.cancel-button')}
          </Button>
        ) : (
          <>
            {can(Actions.DELETE_VALUE) && (
              <button
                className={styles.warningButton}
                type="button"
                onClick={onDelete}
              >
                {t('components.value.components.header.delete-value-button')}
                <IconDelete className={styles.icon} />
              </button>
            )}
          </>
        )}

        {can(Actions.EDIT_VALUE) && (
          <>
            {isEditMode ? (
              <Button type="submit">
                {t('components.value.components.header.save-changes-button')}

                <IconSave className={`${styles.icon} ${styles.small}`} />
              </Button>
            ) : (
              <button
                type="button"
                className={styles.button}
                onClick={() => setIsEditMode(!isEditMode)}
              >
                {t('components.value.components.header.edit-value-button')}
                <IconEdit className={styles.icon} />
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Header;
