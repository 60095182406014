import {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import {
  CONFIDENTIALITY,
  PERSONALIZED_REPORT,
} from '../../../../../../../constants/module-notes';
import {SupportedNoteValue} from '../../../../../../../interfaces/module';
import {ReactComponent as IconLock} from '../../../../../../@components/kit/icons/lock.svg';
import {ReactComponent as IconBookOpen} from '../../../../../../@components/kit/icons/book-open.svg';
import styles from './styles.module.scss';

interface ElementProps {
  note: SupportedNoteValue;
}

const NoteItem: FunctionComponent<ElementProps> = ({note}) => {
  const {t} = useTranslation('assessments');

  const content = (note: SupportedNoteValue) => {
    switch (note) {
      case CONFIDENTIALITY:
        return (
          <>
            <IconLock className={styles.icon} />
            {t('components.details-summary.components.notes.confidentiality')}
          </>
        );
      case PERSONALIZED_REPORT:
        return (
          <>
            <IconBookOpen className={styles.icon} />
            {t(
              'components.details-summary.components.notes.personalized-report'
            )}
          </>
        );
      default:
        return null;
    }
  };

  return <div className={styles.container}>{content(note)}</div>;
};

export default NoteItem;
