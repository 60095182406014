import * as echarts from 'echarts';
import 'echarts-wordcloud';
import {FunctionComponent, useCallback, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {MeetingTopics} from '../../../../../../../../../../interfaces/meeting';
import RoundedCard from '../../../../../../../../../@components/rounded-card';
import styles from './styles.module.scss';

interface ElementProps {
  topics: MeetingTopics[];
}

const Summary: FunctionComponent<ElementProps> = ({topics}) => {
  const {t} = useTranslation('sense', {
    keyPrefix:
      'meetings.show.index.components.meeting-details.components.topic-cloud',
  });

  const chartRef = useRef<HTMLDivElement>(null);

  const getColorBasedOnRelevance = useCallback((relevance: number) => {
    let color = '#ff0000';

    if (relevance > 90 && relevance < 100) {
      color = '#3AD0F8';
    } else if (relevance > 80) {
      color = '#60C3ED';
    } else if (relevance > 60) {
      color = '#F6C75C';
    } else if (relevance > 40) {
      return 'red';
    }

    return {
      color,
    };
  }, []);

  useEffect(() => {
    if (chartRef.current) {
      const chart = echarts.init(chartRef.current);
      const onChartResize = () => chart.resize();

      const option = {
        tooltip: {},
        series: [
          {
            type: 'wordCloud',
            gridSize: 5,
            sizeRange: [20, 50],
            rotationRange: [0, 0],
            shape: 'circle',
            width: '100%',
            height: '100%',
            shrinkToFit: true,
            emphasis: {
              textStyle: {
                shadowBlur: 10,
                shadowColor: '#333',
              },
            },
            data: topics.map((topic) => {
              return {
                name: topic.topic,
                value: topic.relevance,
                textStyle: getColorBasedOnRelevance(topic.relevance),
              };
            }),
          },
        ],
      };

      chart.setOption(option);

      window.addEventListener('resize', onChartResize);

      return () => {
        window.removeEventListener('resize', onChartResize);
      };
    }
  }, [topics, getColorBasedOnRelevance]);

  return (
    <RoundedCard>
      <RoundedCard.Title>{t('title')}</RoundedCard.Title>

      <div className={styles.container}>
        <div ref={chartRef} className={styles.chart} />
      </div>
    </RoundedCard>
  );
};

export default Summary;
