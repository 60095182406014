import {KudoFormValues} from '../../../interfaces/kudo';
import {sanitizeKudoToRaw} from '../../../ui/@sanitizers/kudo';
import InstillAPI from '../axios-instance';
import isResponseSuccessful from '../../../utils/is-response-successful';

export interface CreateKudoPayload {
  companyUuid: string;
  payload: KudoFormValues;
}

export async function createKudo(companyUuid: string, payload: KudoFormValues) {
  const formattedPayload = sanitizeKudoToRaw(payload);

  const response = await InstillAPI.post(
    `/companies/${companyUuid}/kudos`,
    formattedPayload
  );

  if (!isResponseSuccessful(response)) {
    throw Error('No data received');
  }

  return response.data;
}
