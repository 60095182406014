import {FunctionComponent, useCallback, useState} from 'react';
import {useCurrentAuthUserState} from '../../../@atoms/current-auth-user';
import {useTranslation} from 'react-i18next';
import logoSource from '../../../../assets/images/logo-icon.png';
import MobileNavigationMenu from './@components/menu';
import NotificationCenter from '../header/@components/notification-center';
import styles from './styles.module.scss';
import useBodyScrollLock from '../../../@hooks/use-body-scroll-lock';

const MobileNavigation: FunctionComponent = () => {
  const {t} = useTranslation('application');

  const [currentAuthUser] = useCurrentAuthUserState();

  const [isMobileNavigationVisible, setIsMobileNavigationVisible] =
    useState(false);

  const onBurgerMenuClicked = useCallback(() => {
    setIsMobileNavigationVisible(!isMobileNavigationVisible);
  }, [isMobileNavigationVisible]);

  const burgerButtonClassname = isMobileNavigationVisible
    ? `${styles.burgerButton} ${styles.active}`
    : styles.burgerButton;

  const buttonAccessibilityAriaLabel = isMobileNavigationVisible
    ? t('components.mobile-navigation.close-menu-accessibility-text')
    : t('components.mobile-navigation.open-menu-accessibility-text');

  useBodyScrollLock(isMobileNavigationVisible);

  return (
    <div>
      <header className={styles.header}>
        <div className={styles.headerColumn}>
          <button
            type="button"
            className={burgerButtonClassname}
            onClick={onBurgerMenuClicked}
            aria-label={buttonAccessibilityAriaLabel}
          >
            <span className={styles.burgerButtonLines}></span>
            <span className={styles.burgerButtonLines}></span>
            <span className={styles.burgerButtonLines}></span>
          </button>
        </div>

        <div className={styles.headerColumn}>
          <img src={logoSource} className={styles.logo} alt="" />
        </div>

        <div className={styles.headerColumn}>
          <NotificationCenter />

          {currentAuthUser?.picture && (
            <img
              src={currentAuthUser?.picture}
              alt=""
              className={styles.userAvatar}
              role="presentation"
            />
          )}
        </div>
      </header>

      {isMobileNavigationVisible && (
        <MobileNavigationMenu
          isOpen={isMobileNavigationVisible}
          onClose={onBurgerMenuClicked}
        />
      )}
    </div>
  );
};

export default MobileNavigation;
