import {FunctionComponent} from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import styles from './styles.module.scss';

const NoOpenPositions: FunctionComponent = () => {
  const {t} = useTranslation('jobs', {
    keyPrefix:
      'candidates.components.candidate-list.components.add-candidate-dialog.components.form',
  });

  return (
    <>
      <div className={styles.message}>{t('no-job-positions-message')}</div>

      <Link to="/application/jobs/job-positions" className={styles.button}>
        {t('go-to-job-position-button-text')}
      </Link>
    </>
  );
};

export default NoOpenPositions;
