import {AddCandidateFormProps} from '../form';
import {formatDate} from '../../../../../../../../../../utils/date';
import {FunctionComponent, useMemo} from 'react';
import {JobPosition} from '../../../../../../../../../../interfaces/job-position';
import {useTranslation} from 'react-i18next';
import Button from '../../../../../../../../../@components/kit/form/button';
import FormFooter from '../../../../../../../../../@components/kit/form/form-footer';
import styles from './styles.module.scss';

interface ElementProps {
  jobPositions: JobPosition[];
  formValues: AddCandidateFormProps;
  onEditValues: () => void;
  onSubmit: () => void;
}

const Summary: FunctionComponent<ElementProps> = ({
  formValues,
  jobPositions,
  onEditValues,
  onSubmit,
}) => {
  const {t} = useTranslation('jobs');

  const recipients = useMemo(() => {
    return formValues.emails.split(',').map((email) => email.trim());
  }, [formValues]);

  const selectedJobPosition = jobPositions.find(
    (jobPosition) => jobPosition.uuid === formValues.jobPosition
  );
  if (!selectedJobPosition) return <></>;

  return (
    <>
      <div className={styles.message}>
        {t(
          'candidates.components.candidate-list.components.add-candidate-dialog.components.summary.message'
        )}
      </div>

      <ul className={styles.list}>
        <li className={styles.row}>
          <div className={styles.rowKey}>
            {t(
              'candidates.components.candidate-list.components.add-candidate-dialog.components.summary.labels.job-position'
            )}
          </div>

          <div className={styles.rowValue}>{selectedJobPosition.title}</div>
        </li>

        <li className={styles.row}>
          <div className={styles.rowKey}>
            {t(
              'candidates.components.candidate-list.components.add-candidate-dialog.components.summary.labels.due-date'
            )}
          </div>

          <div className={styles.rowValue}>
            {formatDate(formValues.dueDate.toString())}
          </div>
        </li>

        <li className={styles.row}>
          <div className={styles.rowKey}>
            {t(
              'candidates.components.candidate-list.components.add-candidate-dialog.components.summary.labels.recipients'
            )}
          </div>

          <div className={styles.rowValue}>
            <ul className={styles.recipientList}>
              {recipients.map((recipient, index) => (
                <li className={styles.recipientItem} key={index}>
                  {recipient}
                </li>
              ))}
            </ul>
          </div>
        </li>
      </ul>

      <FormFooter>
        <Button onClick={onEditValues} variant="secondary">
          {t(
            'candidates.components.candidate-list.components.add-candidate-dialog.components.summary.update-assessment-details-button'
          )}
        </Button>

        <Button onClick={onSubmit}>
          {t(
            'candidates.components.candidate-list.components.add-candidate-dialog.components.summary.send-assessment-button'
          )}
        </Button>
      </FormFooter>
    </>
  );
};

export default Summary;
