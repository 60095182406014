import InstillAPI from '../axios-instance';
import isResponseSuccessful from '../../../utils/is-response-successful';

interface fileObject {
  mime: string;
  extension: string;
}

type fetchPresignedUploadURLOptions = {
  companyUuid: string;
};

export interface FetchPresignedUploadURLPayload {
  companyUuid?: string;
  extension: string;
  mime: string;
  ressource: fetchPresignedUploadURLResourceType;
}

export enum fetchPresignedUploadURLResourceType {
  VALUE_MEDIA = 'valueMedia',
  SIGNATURE = 'signature',
  COMPANY_LOGO = 'companyLogo',
  USER_PROFILE = 'userProfile',
}

const getUploadUrl = (
  resource: string,
  options?: fetchPresignedUploadURLOptions
) => {
  if (options) {
    if (resource === fetchPresignedUploadURLResourceType.VALUE_MEDIA) {
      return `/companies/${options.companyUuid}/upload/value-media`;
    }

    if (resource === fetchPresignedUploadURLResourceType.SIGNATURE) {
      return `/companies/${options.companyUuid}/upload/signature`;
    }

    if (resource === fetchPresignedUploadURLResourceType.USER_PROFILE) {
      return `/companies/${options.companyUuid}/user-profiles/me/picture`;
    }
  }

  if (resource === fetchPresignedUploadURLResourceType.COMPANY_LOGO) {
    return `/companies/upload/logo`;
  }

  throw Error('Unsupported resource');
};

export async function fetchPresignedUploadURL(
  resource: string,
  fileObject: fileObject,
  options?: fetchPresignedUploadURLOptions
) {
  try {
    const response = await InstillAPI.post(
      getUploadUrl(resource, options),
      fileObject
    );

    if (!response.data || !isResponseSuccessful(response)) {
      throw Error('No data received');
    }
    return response.data;
  } catch (error) {
    console.error(error);
  }
}
