import {FunctionComponent, useCallback} from 'react';
import {JobPosition} from '../../../../../../../../../../interfaces/job-position';
import {useTranslation} from 'react-i18next';
import styles from './styles.module.scss';

interface ElementProps {
  jobPosition: JobPosition;
  onJobPositionClicked: (jobPosition: JobPosition) => void;
}

const Item: FunctionComponent<ElementProps> = ({
  jobPosition,
  onJobPositionClicked,
}) => {
  const {t} = useTranslation('jobs', {
    keyPrefix:
      'job-positions.components.job-position-list.components.list.components.item',
  });

  const isDisabled = !jobPosition.isActive;
  const itemClass = `${styles.container} ${
    isDisabled ? styles.isDisabled : ''
  }`;

  const onClick = useCallback(() => {
    onJobPositionClicked(jobPosition);
  }, [onJobPositionClicked, jobPosition]);

  return (
    <div className={itemClass}>
      <button className={styles.button} onClick={onClick}>
        <div className={styles.title}>{jobPosition.title}</div>

        {isDisabled && (
          <span className={styles.tag}>{t('is-disabled-tag')}</span>
        )}
      </button>
    </div>
  );
};

export default Item;
