import {Actions} from '../../../../../../constants/permissions/actions';
import {
  ChangeEvent,
  FormEvent,
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {CompanyEmployee} from '../../../../../../interfaces/company-employee';
import {DEFAULT_NUMBER_OF_ITEMS_PER_PAGE} from '../../../../../../constants/filtering/pagination';
import {DEFAULT_TOASTIFY_CONFIGURATION} from '../../../../../../constants/toastify-configuration';
import {EventName} from '../../../../../../constants/analytics/event-name';
import {EventType} from '../../../../../../constants/analytics/event-type';
import {getUserProfileForCompany} from '../../../../../../services/user-profiles';
import {
  ORDER_BY_PARAMETERS,
  SUPPORTED_ORDER_BY_PARAMETERS,
} from '../../../../../../constants/filtering/order-by';
import {ReactComponent as IconPlus} from '../../../../../@components/kit/icons/plus.svg';
import {SearchTriggeredEvent} from '../../../../../../interfaces/analytics/event-properties';
import {SortedColumn} from '../../../../../../interfaces/filtering/sorted-column';
import {StringParam, createEnumParam, useQueryParams} from 'use-query-params';
import {
  SUPPORTED_SORTABLE_COLUMNS,
  TEAMS_SORTABLE_COLUMNS,
} from '../../../../../../constants/filtering/sort-by';
import {TeamUpsert} from '../../../../../../interfaces/team';
import {ToastOptions, toast} from 'react-toastify';
import {useCreateTeam} from '../../../../../@hooks/mutations';
import {useCurrentAuthUserState} from '../../../../../@atoms/current-auth-user';
import {useDebounce} from 'react-use';
import {useSafeCurrentCompany} from '../../../../../@atoms/current-company';
import {useTeams} from '../../../../../@hooks/queries';
import {useTranslation} from 'react-i18next';
import {useUserPermission} from '../../../../../@hooks/use-user-permission';
import Button from '../../../../../@components/kit/form/button';
import CreateTeamDialog from './@components/create-team-dialog/create-team-dialog';
import getActiveSortedColumnFromQueryParameters from '../../../../../../utils/get-active-sorted-column-from-query-params/get-active-sorted-column-from-query-params';
import ListView from './@components/list-view';
import NoResultsBox from '../../../../@components/no-results-box/no-results-box';
import Pagination from '../../../../../@components/pagination/pagination';
import RoundedCard from '../../../../../@components/rounded-card';
import SearchForm from '../../../../@components/search-form/search-form';
import Spinner from '../../../../../@components/spinner/spinner';
import styles from './styles.module.scss';
import useAnalytics from '../../../../../@hooks/use-analytics';
import usePagination from '../../../../../@hooks/use-pagination';

const MINIMUM_SEARCH_LETTERS = 2;
const DELAY_FOR_SEARCH_DEBOUNCE_IN_MS = 500;
const NUMBER_OF_ITEMS_PER_PAGE = 20;

interface ElementProps {
  companyEmployees: CompanyEmployee[];
  onRefreshCompanyEmployees: () => void;
}

const TeamList: FunctionComponent<ElementProps> = ({
  companyEmployees,
  onRefreshCompanyEmployees,
}) => {
  const {t} = useTranslation('people', {
    keyPrefix: 'teams.components.team-list',
  });

  const [isCreateDialogVisible, setIsCreateDialogVisible] = useState(false);

  const createTeam = useCreateTeam();
  const currentCompany = useSafeCurrentCompany();
  const [currentAuthUser] = useCurrentAuthUserState();
  const {trackEvent} = useAnalytics();

  const userProfile = getUserProfileForCompany(
    currentAuthUser!,
    currentCompany
  );

  const can = useUserPermission(userProfile.role);

  const [queryParameters, setQueryParameters] = useQueryParams({
    orderBy: createEnumParam(SUPPORTED_ORDER_BY_PARAMETERS),
    sortBy: createEnumParam(SUPPORTED_SORTABLE_COLUMNS.TEAMS),
    query: StringParam,
  });

  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(
    queryParameters.query
  );

  const {
    currentPage,
    goToNextPage,
    goToPreviousPage,
    offset,
    pageInQueryParams,
    onResetPagination,
    setCurrentPage,
    setPageInQueryParams,
  } = usePagination({
    itemsPerPage: NUMBER_OF_ITEMS_PER_PAGE,
  });

  const [activeSortedColumn, setActiveSortedColumn] =
    useState<SortedColumn | null>(
      getActiveSortedColumnFromQueryParameters({
        orderBy: queryParameters.orderBy,
        sortBy: queryParameters.sortBy,
        supportedColumnsToSort: SUPPORTED_SORTABLE_COLUMNS.EMPLOYEES,
      })
    );

  const teams = useTeams({
    variables: {
      companyUuid: currentCompany.uuid,
      currentPage,
      itemsPerPage: NUMBER_OF_ITEMS_PER_PAGE,
      limit: NUMBER_OF_ITEMS_PER_PAGE,
      offset,
      orderBy: queryParameters.orderBy ?? undefined,
      query: queryParameters.query ?? undefined,
      sortBy: (queryParameters.sortBy as TEAMS_SORTABLE_COLUMNS) ?? undefined,
    },
  });

  const isCreateTeamButtonVisible = useMemo(() => {
    return (
      can(Actions.ADD_TEAM) && !currentCompany.companySettings.isHRISProviderUKG
    );
  }, [can, currentCompany]);

  const onColumnClicked = useCallback(
    (column: SortedColumn) => {
      setActiveSortedColumn(column);

      setQueryParameters({
        ...queryParameters,
        sortBy: column.columnName as TEAMS_SORTABLE_COLUMNS,
        orderBy: column.order as ORDER_BY_PARAMETERS,
      });

      if (!pageInQueryParams) return;

      setCurrentPage(1);
      setPageInQueryParams(1);
    },
    [
      pageInQueryParams,
      setCurrentPage,
      queryParameters,
      setQueryParameters,
      setPageInQueryParams,
    ]
  );

  const onSearch = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setDebouncedSearchQuery(value);

      const eventProperties = {
        searchQuery: value,
      } as SearchTriggeredEvent;

      trackEvent({
        eventName: EventName.PEOPLE.TEAMS.SEARCH,
        eventType: EventType.SEARCH_TRIGGERED,
        eventProperties,
      });
    },
    [trackEvent]
  );

  const onClearSearch = useCallback(
    (event: FormEvent) => {
      event.preventDefault();

      setQueryParameters({
        ...queryParameters,
        query: undefined,
      });

      trackEvent({
        eventName: EventName.PEOPLE.TEAMS.CLEAR_SEARCH,
        eventType: EventType.BUTTON_CLICKED,
      });
    },
    [queryParameters, setQueryParameters, trackEvent]
  );

  useDebounce(
    () => {
      if (
        debouncedSearchQuery !== '' &&
        (debouncedSearchQuery?.length ?? 0) < MINIMUM_SEARCH_LETTERS
      ) {
        return;
      }

      setQueryParameters({
        ...queryParameters,
        query: debouncedSearchQuery,
      });

      if (!pageInQueryParams) return;
      onResetPagination();
    },
    DELAY_FOR_SEARCH_DEBOUNCE_IN_MS,
    [debouncedSearchQuery]
  );

  const openCreateTeamDialog = () => {
    setIsCreateDialogVisible(true);

    trackEvent({
      eventName: EventName.PEOPLE.TEAMS.CREATE_TEAM.OPEN_DIALOG,
      eventType: EventType.BUTTON_CLICKED,
    });
  };

  const closeCreateTeamDialog = useCallback(() => {
    setIsCreateDialogVisible(false);

    trackEvent({
      eventName: EventName.PEOPLE.TEAMS.CREATE_TEAM.CLOSE_DIALOG,
      eventType: EventType.BUTTON_CLICKED,
    });
  }, [trackEvent]);

  const onCreateNewTeam = useCallback(
    async (values: TeamUpsert) => {
      await createTeam.mutateAsync({
        companyUuid: currentCompany.uuid,
        payload: values,
      });

      teams.refetch();
      closeCreateTeamDialog();

      const feedbackMessage = t('success-message');
      toast.success(feedbackMessage, {
        ...(DEFAULT_TOASTIFY_CONFIGURATION as ToastOptions),
      });

      trackEvent({
        eventName: EventName.PEOPLE.TEAMS.CREATE_TEAM.SUCCESS,
        eventType: EventType.BUTTON_CLICKED,
      });

      onRefreshCompanyEmployees();
    },
    [
      createTeam,
      trackEvent,
      onRefreshCompanyEmployees,
      closeCreateTeamDialog,
      currentCompany,
      t,
      teams,
    ]
  );

  const onRefreshTeams = useCallback(() => {
    teams.refetch();
  }, [teams]);

  return (
    <>
      <RoundedCard.Header>
        <RoundedCard.Title>
          {t('title')}

          {isCreateTeamButtonVisible && (
            <Button className={styles.addButton} onClick={openCreateTeamDialog}>
              <IconPlus className={styles.icon} />
            </Button>
          )}
        </RoundedCard.Title>

        <div>
          <SearchForm
            searchQueryInQueryParams={queryParameters.query}
            onSearch={onSearch}
            placeholder={t('search-box-placeholder')}
            onClearSearch={onClearSearch}
          />
        </div>
      </RoundedCard.Header>

      <RoundedCard.Content>
        {teams.isLoading ? (
          <Spinner />
        ) : (
          <>
            {teams.data?.data?.length ? (
              <>
                <ListView
                  activeSortedColumn={activeSortedColumn}
                  companyEmployees={companyEmployees}
                  onColumnClicked={onColumnClicked}
                  onRefreshTeams={onRefreshTeams}
                  onRefreshCompanyEmployees={onRefreshCompanyEmployees}
                  teams={teams.data.data}
                />

                {teams.data?.meta && (
                  <Pagination
                    currentPage={currentPage}
                    itemsPerPage={DEFAULT_NUMBER_OF_ITEMS_PER_PAGE}
                    onNextPageLinkClicked={goToNextPage}
                    onPreviousPageLinkClicked={goToPreviousPage}
                    totalItems={teams.data?.meta.totalEntries}
                  />
                )}
              </>
            ) : (
              <NoResultsBox message={t('no-result')} />
            )}
          </>
        )}
      </RoundedCard.Content>

      <CreateTeamDialog
        companyEmployees={companyEmployees}
        isDialogVisible={isCreateDialogVisible}
        onClose={closeCreateTeamDialog}
        onSubmit={onCreateNewTeam}
      />
    </>
  );
};

export default TeamList;
