import {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import Button from '../../../../../../@components/kit/form/button';
import styles from './styles.module.scss';

interface ElementProps {
  onClick: () => void;
}

const HeaderButton: FunctionComponent<ElementProps> = ({onClick}) => {
  const {t} = useTranslation('sense', {
    keyPrefix: 'meetings.index.components.header-button',
  });

  return (
    <div className={styles.buttonWrapper}>
      <Button variant="secondary" onClick={onClick}>
        {t('title')}
      </Button>
    </div>
  );
};

export default HeaderButton;
