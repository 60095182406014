import {CoreValueForm} from '../../../../../../interfaces/company-core-value';
import {CoreValueFormSchema} from '../../codify-your-values';
import {EventName} from '../../../../../../constants/analytics/event-name';
import {EventType} from '../../../../../../constants/analytics/event-type';
import {FieldProps, Field, useFormikContext} from 'formik';
import {FunctionComponent, useCallback, useMemo, useState} from 'react';
import {
  MEDIA_TYPE_IMAGE,
  MEDIA_TYPE_VIDEO,
} from '../../../../../../constants/core-value-media-type';
import {ReactComponent as IconAlert} from '../../../../../@components/kit/icons/alert-circle.svg';
import {ReactComponent as IconDelete} from '../../../../../@components/kit/icons/trash.svg';
import {ReactComponent as IconEdit} from '../../../../../@components/kit/icons/edit-circle.svg';
import {ReactComponent as IconFile} from '../../../../../@components/kit/icons/file.svg';
import {ReactComponent as IconMedia} from '../../../../../@components/kit/icons/play-circle.svg';
import {ReactComponent as IconPenEdit} from '../../../../../@components/kit/icons/edit.svg';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import {useCurrentCompany} from '../../../../../@atoms/current-company';
import {useTranslation} from 'react-i18next';
import BehaviorsForm from '../../../../@components/behaviors-form';
import {CoreValue} from '../../../../../../interfaces/core-value';
import FormControl from '../../../../../@components/kit/form/form-control';
import FormGroup from '../../../../../@components/kit/form/form-group/form-group';
import Label from '../../../../../@components/kit/form/label/label';
import MutableSelect from '../../../../../@components/kit/form/hybrid-select/mutable-select/mutable-select';
import Select from 'react-select/creatable';
import styles from './styles.module.scss';
import UploadMedia from '../../../../@components/upload-media';
import useAnalytics from '../../../../../@hooks/use-analytics';
import RichTextEditor from '../../../../../@components/kit/form/rich-text-editor';

interface ElementProps {
  activeFormIndex: number;
  coreValueOptions?: CoreValue[];
  itemIndex: number;
  removeValue: (value: CoreValueForm, index: number) => void;
  setActiveFormIndex: (index: number) => void;
  setFieldValue: (name: string, value: string) => void;
  value: CoreValueForm;
}

const ValueFormItem: FunctionComponent<ElementProps> = ({
  itemIndex,
  activeFormIndex,
  value,
  coreValueOptions,
  setActiveFormIndex,
  setFieldValue,
  removeValue,
}) => {
  const coreValueNameOptions = useMemo(() => {
    return coreValueOptions?.map((coreValue) => ({
      value: coreValue.name,
      label: coreValue.name,
    }));
  }, [coreValueOptions]);

  const {t} = useTranslation(['organizationSetup', 'common', 'components']);
  const {trackEvent} = useAnalytics();
  const currentCompany = useCurrentCompany();

  const [mediaDataBase64, setMediaDataBase64] = useState('');
  const {errors, touched, isValid} = useFormikContext<CoreValueFormSchema>();

  const mediaType = value.media[0]?.type ?? undefined;
  const mediaUrl = value.media[0]?.url ?? undefined;

  const coreValueErrors = errors.values?.[itemIndex] as CoreValueForm;

  const loadPreview = useCallback(() => {
    if (!mediaType) return;

    if (mediaType === MEDIA_TYPE_IMAGE) {
      return <img src={mediaDataBase64} className={styles.mediaImage} alt="" />;
    } else if (mediaType === MEDIA_TYPE_VIDEO) {
      return (
        <iframe
          title="video"
          src={`${mediaUrl}?modestbranding=1&rel=0&cc_load_policy=1&iv_load_policy=3&color=white&controls=0&showinfo=0`}
          className={styles.mediaYoutube}
          allowFullScreen
        />
      );
    }
  }, [mediaDataBase64, mediaType, mediaUrl]);

  const onMediaTabClick = useCallback(() => {
    trackEvent({
      eventName: EventName.ACCOUNT_SET_UP.CODIFY_YOUR_VALUES.UPLOAD_MEDIA,
      eventType: EventType.TAB_BUTTON_CLICKED,
    });
  }, [trackEvent]);

  const showCoreValueNameError = Boolean(
    touched.values?.[itemIndex]?.name && coreValueErrors?.name
  );

  const showCoreValueDescriptionError = Boolean(
    touched.values?.[itemIndex]?.description && coreValueErrors?.description
  );

  const formErrorClassNames = `${styles.errorBorder} ${styles.errorTextColor}`;

  const onEditorStateChange = useCallback(
    (htmlValue: string, form: FieldProps['form'], fieldName: string) => {
      form.setFieldValue(fieldName, htmlValue);
    },
    []
  );
  if (itemIndex !== activeFormIndex) {
    return (
      <div
        className={`${styles.collapsedValue} ${
          !isValid ? formErrorClassNames : ''
        }`}
      >
        <div className={styles.headingContainer}>
          {!isValid && <IconAlert className={styles.errorIcon} />}

          {value.name
            ? value.name
            : t('codify-your-values.form.minimized-default-title', {
                index: itemIndex + 1,
              })}
        </div>

        <div className={styles.iconContainer}>
          <button type="button" onClick={() => setActiveFormIndex(itemIndex)}>
            <IconEdit className={styles.icon} />
          </button>

          <button type="button" onClick={() => removeValue(value, itemIndex)}>
            <IconDelete className={styles.icon} />
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.activeValue}>
      <div className={styles.form}>
        <Tabs
          className={styles.formContainer}
          selectedTabClassName={styles.selectedItem}
          selectedTabPanelClassName={styles.selectedTab}
        >
          <TabPanel className={styles.tab}>
            {coreValueNameOptions && (
              <Field name={`values.${itemIndex}.name`}>
                {({field, form, meta}: FieldProps<string>) => (
                  <FormGroup>
                    <FormControl
                      error={
                        showCoreValueNameError ? coreValueErrors?.name : null
                      }
                    >
                      <Label isRequired htmlFor={field.name}>
                        {t('codify-your-values.form.name.label')}
                      </Label>

                      <MutableSelect
                        field={field}
                        form={form}
                        meta={meta}
                        defaultValue={{value: value.name, label: value.name}}
                        options={coreValueNameOptions}
                        placeholder={t(
                          'codify-your-values.form.name.placeholder'
                        )}
                        isMulti={false}
                      />
                    </FormControl>
                  </FormGroup>
                )}
              </Field>
            )}

            <Field name={`values.${itemIndex}.description`}>
              {({form, field}: FieldProps<string>) => (
                <FormGroup className={styles.formGroup}>
                  <FormControl
                    error={
                      showCoreValueDescriptionError
                        ? coreValueErrors?.description
                        : null
                    }
                  >
                    <Label isRequired htmlFor={field.name}>
                      {t('codify-your-values.form.description.label', {
                        companyName: currentCompany?.name,
                      })}
                    </Label>

                    <RichTextEditor
                      placeholder={t(
                        'codify-your-values.form.description.placeholder'
                      )}
                      initialValue={field.value}
                      onChange={(value) =>
                        onEditorStateChange(value, form, field.name)
                      }
                    />
                  </FormControl>
                </FormGroup>
              )}
            </Field>

            <div className={styles.formGroupLabel}>
              {t('codify-your-values.form.norms-and-behaviors.label')}
            </div>

            {coreValueOptions && (
              <BehaviorsForm
                fieldNamePrefix={`values.${itemIndex}`}
                valueName={value.name}
                valueExpectations={value.expectations}
                coreValueOptions={coreValueOptions}
              />
            )}
          </TabPanel>

          <TabPanel className={styles.tab}>
            <FormGroup>
              <div className={styles.mediaUploadTitle}>
                {t('codify-your-values.form.name.label')}
              </div>

              <Select
                value={{label: value.name, value: value.name}}
                className={styles.selectInput}
                isDisabled={true}
              />

              <Field name={`values.${itemIndex}.media[0].url`}>
                {({field, form}: FieldProps<string>) => (
                  <FormGroup>
                    <div className={styles.mediaUploadTitle}>
                      {t('upload-media.label', {ns: 'components'})}

                      {mediaUrl && (
                        <button
                          type="button"
                          className={styles.button}
                          onClick={() => {
                            setFieldValue(
                              `values.${itemIndex}.media[0].url`,
                              ''
                            );
                          }}
                        >
                          <IconPenEdit className={styles.editIcon} />
                        </button>
                      )}
                    </div>

                    {mediaUrl ? (
                      loadPreview()
                    ) : (
                      <UploadMedia
                        eventNameForEmbedYoutubeVideo={
                          EventName.ACCOUNT_SET_UP.CODIFY_YOUR_VALUES
                            .EMBED_YOUTUBE
                        }
                        field={field}
                        form={form}
                        setMediaDataBase64={setMediaDataBase64}
                        typeFieldName={`values.${itemIndex}.media[0].type`}
                        eventNameForUploadImageFromComputer={
                          EventName.ACCOUNT_SET_UP.CODIFY_YOUR_VALUES
                            .UPLOAD_IMAGE_FROM_COMPUTER
                        }
                      />
                    )}
                  </FormGroup>
                )}
              </Field>
            </FormGroup>
          </TabPanel>

          <div className={styles.sidebar}>
            <TabList className={styles.list}>
              <div className={styles.tabList}>
                <Tab className={styles.item}>
                  <IconFile />
                </Tab>

                <Tab onClick={onMediaTabClick} className={styles.item}>
                  <IconMedia />
                </Tab>
              </div>

              <button
                type="button"
                onClick={() => removeValue(value, itemIndex)}
              >
                <IconDelete className={styles.icon} />
              </button>
            </TabList>
          </div>
        </Tabs>

        <div className={styles.footer}>
          <div className={styles.footerStrip}></div>
        </div>
      </div>
    </div>
  );
};

export default ValueFormItem;
