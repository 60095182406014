interface Parameters {
  currentPage: number;
  itemsPerPage: number;
  offset?: number;
}

function getOffsetValue(parameters: Parameters) {
  if (Number.isInteger(parameters.offset)) {
    return parameters.offset;
  }

  if (parameters.currentPage || parameters.currentPage > 1) {
    return (
      parameters.currentPage * parameters.itemsPerPage - parameters.itemsPerPage
    );
  }

  return 0;
}

export default getOffsetValue;
