import {EventName} from '../../../../../constants/analytics/event-name';
import {EventType} from '../../../../../constants/analytics/event-type';
import {FunctionComponent, useCallback, useMemo} from 'react';
import {PlaybooksLinkClickedEvent} from '../../../../../interfaces/analytics/event-properties';
import {useTranslation} from 'react-i18next';
import RoundedCard from '../../../../@components/rounded-card';
import styles from './styles.module.scss';
import useAnalytics from '../../../../@hooks/use-analytics';

interface PlaybookItem {
  title: string;
  path: string;
}

const PLAYBOOKS_TO_SHOW = 5;

const Playbooks: FunctionComponent = () => {
  const {t} = useTranslation('dashboard');

  const {trackEvent} = useAnalytics();

  const CDN_BASE_URL = window.env.REACT_APP_INSTILL_CDN_BASE_URL;

  const playbookStorage = useMemo(() => {
    const list = [
      {title: 'Better 1 On 1 Meetings', path: '/pdf/better-1-on-1.pdf'},
      {
        title: 'Building Resilient Cultures',
        path: '/pdf/building-resilient-cultures.pdf',
      },
      {
        title: 'Building Virtual Trust',
        path: '/pdf/building-virtual-trust.pdf',
      },
      {title: 'Conscious Leadership', path: '/pdf/conscious-leadership.pdf'},
      {title: 'Developing Leaders', path: '/pdf/developing-leaders.pdf'},
      {title: 'Doing Conflict Right', path: '/pdf/doing-conflict-right.pdf'},
      {title: 'Effective Feedback', path: '/pdf/effective-feedback.pdf'},
      {title: 'Empathetic Leadership', path: '/pdf/empathetic-leadership.pdf'},
      {title: 'Goal Setting', path: '/pdf/goal-setting.pdf'},
      {title: 'Hiring Well', path: '/pdf/hiring-well.pdf'},
      {title: 'Innovative Cultures', path: '/pdf/innovative-cultures.pdf'},
      {
        title: 'Leading Through Crisis',
        path: '/pdf/leading-through-crisis.pdf',
      },
      {title: 'Preventing Burnout', path: '/pdf/preventing-burnout.pdf'},
      {title: 'Psychological Safety', path: '/pdf/psychological-safety.pdf'},
      {
        title: 'Thriving Virtual Teams',
        path: '/pdf/thriving-virtual-teams.pdf',
      },
    ];

    return list.map((item) => {
      return {
        ...item,
        path: `${CDN_BASE_URL}${item.path}`,
      };
    });
  }, [CDN_BASE_URL]);

  const filteredPlaybooks = useMemo(() => {
    const randomPlaybooks: PlaybookItem[] = [];

    if (PLAYBOOKS_TO_SHOW > playbookStorage.length) {
      return playbookStorage;
    }

    while (randomPlaybooks.length < PLAYBOOKS_TO_SHOW) {
      const randomPlaybook =
        playbookStorage[Math.floor(Math.random() * playbookStorage.length)];

      if (!randomPlaybooks.includes(randomPlaybook)) {
        randomPlaybooks.push(randomPlaybook);
      }
    }

    return randomPlaybooks;
  }, [playbookStorage]);

  const onLearnMoreLinkClicked = useCallback(() => {
    trackEvent({
      eventName: EventName.HOME.PLAYBOOKS.LEARN_MORE,
      eventType: EventType.LINK_CLICKED,
    });
  }, [trackEvent]);

  const onLinkClicked = useCallback(
    (playbook: PlaybookItem) => {
      trackEvent({
        eventName: EventName.HOME.PLAYBOOKS.LINK_CLICKED,
        eventProperties: {
          linkTitle: playbook.title,
        } as PlaybooksLinkClickedEvent,
      });
    },
    [trackEvent]
  );

  return (
    <RoundedCard>
      <RoundedCard.Header variant="vertical">
        <RoundedCard.Title>{t('components.playbooks.title')}</RoundedCard.Title>

        <p className={styles.description}>
          {t('components.playbooks.description')}
        </p>
      </RoundedCard.Header>

      <RoundedCard.Content>
        <ul className={styles.list}>
          {filteredPlaybooks.map((playbook) => (
            <li className={styles.item} key={playbook.title}>
              <a
                className={styles.link}
                href={playbook.path}
                onClick={() => onLinkClicked(playbook)}
                rel="noopener noreferrer"
                target="_blank"
              >
                {playbook.title}
              </a>
            </li>
          ))}

          <li className={styles.item}>
            <a
              className={styles.link}
              href={t('components.playbooks.links.learn-more.url')}
              onClick={onLearnMoreLinkClicked}
              rel="noopener noreferrer"
              target="_blank"
            >
              {t('components.playbooks.links.learn-more.label')}
            </a>
          </li>
        </ul>
      </RoundedCard.Content>
    </RoundedCard>
  );
};

export default Playbooks;
