export const sortByNameAndDescription = (list: any[]) => {
  return list
    .filter((value) => value.name && value.description)
    .sort((firstItem, secondItem) => {
      const nameA = firstItem.name.substring(0, 30);
      const nameB = secondItem.name.substring(0, 30);
      let comparison = nameA.localeCompare(nameB);
      if (comparison === 0) {
        const descA = firstItem.description.substring(0, 30);
        const descB = secondItem.description.substring(0, 30);
        comparison = descA.localeCompare(descB);
      }
      return comparison;
    });
};
