import {FONT_FAMILIES} from './constants/constants';
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {Signature} from '../../../../interfaces/signature';
import {useTranslation} from 'react-i18next';
import SignatureCanvas from 'react-signature-canvas';
import SignaturePreset from './@components/signature-preset';
import styles from './styles.module.scss';

interface ElementProps {
  personName: string;
  updateSelection: (signature: Signature | undefined) => void;
}

const SignatureBlock: FunctionComponent<ElementProps> = ({
  personName,
  updateSelection,
}) => {
  const {t} = useTranslation('application');

  const [usingCanvas, setUsingCanvas] = useState(false);
  const [currentFontFamily, setCurrentFontFamily] = useState<string | null>(
    null
  );

  const [currentSignature, setCurrentSignature] = useState<Signature>({
    signatureImageUrl: null,
    signatureSelection: null,
  });

  const signaturePadRef = useRef<SignatureCanvas>(null);

  const nameInitials = useMemo(() => {
    let initials = '';

    const nameSplit = personName.split(' ');

    if (nameSplit.length > 1) {
      nameSplit.pop();
      initials = nameSplit.map((word) => word[0]).join('. ');
    }
    return initials;
  }, [personName]);

  const sanitizedName = useMemo(() => {
    return personName.split(' ').length > 1
      ? `${nameInitials}. ${personName.split(' ').slice(-1)[0]}`
      : personName;
  }, [nameInitials, personName]);

  const handleSignatureSelect = useCallback(
    (fontFamily: string) => {
      setUsingCanvas(false);
      setCurrentFontFamily(fontFamily);

      signaturePadRef.current!.clear();

      setCurrentSignature({
        signatureImageUrl: null,
        signatureSelection: {
          fontFamily: fontFamily,
          letters: sanitizedName,
        },
      });
    },
    [sanitizedName]
  );

  const handleSignatureBegin = useCallback(() => {
    setCurrentFontFamily(null);
    setUsingCanvas(true);
  }, []);

  const handleSignatureEnd = useCallback(() => {
    setCurrentSignature({
      signatureImageUrl: signaturePadRef
        .current!.getTrimmedCanvas()
        .toDataURL(),
      signatureSelection: null,
    });
  }, []);

  const getResetClassName = useCallback(
    (fontFamily: string) => {
      if (currentFontFamily === fontFamily)
        return `${styles.presetWrapper} ${styles.active}`;

      return styles.presetWrapper;
    },
    [currentFontFamily]
  );

  const signaturePadClassName = usingCanvas
    ? `${styles.padContainer} ${styles.active}`
    : styles.padContainer;

  useEffect(() => {
    if (
      (currentSignature.signatureImageUrl &&
        !currentSignature.signatureSelection) ||
      (!currentSignature.signatureImageUrl &&
        currentSignature.signatureSelection)
    ) {
      updateSelection(currentSignature);
    }
  }, [currentSignature, updateSelection]);

  return (
    <div className={styles.signatureBlock}>
      <div className={styles.presetContainer}>
        {FONT_FAMILIES.map((fontFamily, index) => (
          <button
            className={getResetClassName(fontFamily)}
            type="button"
            key={index}
            onClick={() => {
              handleSignatureSelect(fontFamily);
            }}
          >
            <SignaturePreset name={sanitizedName} fontFamily={fontFamily} />
          </button>
        ))}
      </div>

      {t('components.main-content.sign-off-on-values.seperator-text')}

      <div className={signaturePadClassName}>
        <SignatureCanvas
          ref={signaturePadRef}
          onBegin={handleSignatureBegin}
          onEnd={handleSignatureEnd}
          throttle={16}
          clearOnResize={true}
        />
      </div>
    </div>
  );
};

export default SignatureBlock;
