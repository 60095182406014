import {EventName} from '../../../../../../../../../../constants/analytics/event-name';
import {EventType} from '../../../../../../../../../../constants/analytics/event-type';
import {formatDate} from '../../../../../../../../../../utils/date';
import {FunctionComponent, useCallback} from 'react';
import {NavLink} from 'react-router-dom';
import {UserReport} from '../../../../../../../../../../interfaces/user-report';
import {useTranslation} from 'react-i18next';
import {ViewAssessmentResultEvent} from '../../../../../../../../../../interfaces/analytics/event-properties';
import styles from './styles.module.scss';
import Table from '../../../../../../../../../@components/table';
import Tag from '../../../../../../../../../@components/tag';
import useAnalytics from '../../../../../../../../../@hooks/use-analytics';

interface ElementProps {
  report: UserReport;
}

const TableRow: FunctionComponent<ElementProps> = ({report}) => {
  const {t} = useTranslation('measure');

  const {trackEvent} = useAnalytics();

  const viewResultsLink = `/assessments-summary/${report.uuid}/modules/${report.assessmentEvent.assessment.modules[0].uuid}`;

  const onLinkClicked = useCallback(() => {
    const eventProperties = {
      assessmentName: report.assessmentEvent.assessment.name,
    } as ViewAssessmentResultEvent;

    trackEvent({
      eventName: EventName.MEASURE.YOUR_REPORTS.VIEW_RESULT,
      eventType: EventType.BUTTON_CLICKED,
      eventProperties,
    });
  }, [trackEvent, report]);

  return (
    <Table.Row>
      <Table.Data variant="large">
        <Tag>{report.assessmentEvent.assessment.name}</Tag>
      </Table.Data>

      <Table.Data variant="medium">
        {formatDate(report.assessmentEvent.startDate)}
      </Table.Data>

      <Table.Data variant="medium">
        <NavLink
          to={viewResultsLink}
          className={styles.button}
          onClick={onLinkClicked}
        >
          {t('your-reports.components.reports.view-results-button')}
        </NavLink>
      </Table.Data>
    </Table.Row>
  );
};

export default TableRow;
