import {CompanyAssessmentEvents} from '../../../../../../../../../interfaces/company-assessment-events';
import {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import NoResultsBox from '../../../../../../../@components/no-results-box';
import styles from './styles.module.scss';
import TableRow from './@components/table-row';

interface ElementProps {
  companyAssessmentEvents?: CompanyAssessmentEvents;
}

const ListView: FunctionComponent<ElementProps> = ({
  companyAssessmentEvents,
}) => {
  const {t} = useTranslation('measure');

  return (
    <>
      {companyAssessmentEvents?.events.length ? (
        <div>
          <div className={styles.tableHeader}>
            <div className={`${styles.tableData} ${styles.occurenceColumn}`}>
              {t(
                'manage-assessments.components.assessment-events.components.list-view.table.header.occurence'
              )}
            </div>

            <div
              className={`${styles.tableData} ${styles.dateInitiatedColumn}`}
            >
              {t(
                'manage-assessments.components.assessment-events.components.list-view.table.header.date-initiated'
              )}
            </div>

            <div className={`${styles.tableData} ${styles.dueDateColumn}`}>
              {t(
                'manage-assessments.components.assessment-events.components.list-view.table.header.due-date'
              )}
            </div>

            <div className={`${styles.tableData} ${styles.populationColumn}`}>
              {t(
                'manage-assessments.components.assessment-events.components.list-view.table.header.population'
              )}
            </div>

            <div className={`${styles.tableData} ${styles.actionsColumn}`}>
              {t(
                'manage-assessments.components.assessment-events.components.list-view.table.header.actions'
              )}
            </div>
          </div>

          <ul className={styles.content}>
            {companyAssessmentEvents?.events.map((companyAssessmentEvent) => (
              <TableRow
                companyAssessmentEvent={companyAssessmentEvent}
                key={companyAssessmentEvent.uuid}
              />
            ))}
          </ul>
        </div>
      ) : (
        <NoResultsBox
          isMessageVisible={false}
          title={t(
            'manage-assessments.components.assessment-events.components.list-view.no-result-box.title'
          )}
        />
      )}
    </>
  );
};

export default ListView;
