import {sanitizeAuthUserFromRaw} from '../../../ui/@sanitizers/auth-user';
import {AuthUser as AuthUserState} from '../../../interfaces/auth-user';
import InstillAPI from '../axios-instance';
import maskEmail from '../../../utils/email/mask-email';

export class EmailNotVerifiedException extends Error {
  payload: any;

  constructor(message: string, payload: any) {
    super(message);
    this.payload = payload;
  }
}

export class CompanyNotRegisteredException extends Error {
  payload: any;

  constructor(message: string, payload: any) {
    super(message);
    this.payload = payload;
  }
}

export interface CreateUser {
  action: 'created';
  createdUser: AuthUserState;
}

export interface LinkUser {
  action: 'linked';
  providers: string[];
}

export async function fetchAndUpsertUser(
  idToken: string,
  token: string
): Promise<CreateUser | LinkUser> {
  const payload = {
    id_token: idToken,
    auth0_client_id: window.env.REACT_APP_AUTH0_CLIENT_ID,
  };

  const response = await InstillAPI.post('/auth-users/', payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if ('account_linked' in response.data) {
    return {
      action: 'linked',
      providers: response.data.payload.providers,
    };
  }

  if (!response.data.is_email_verified) {
    const payload = {
      status: 'email_not_verified',
      masked_email: maskEmail(response.data.email),
    };

    throw new EmailNotVerifiedException('Email not verified', payload);
  }

  // if (response.data.user_profiles.length === 0) {
  //   const payload = {
  //     status: 'instill_registration_required',
  //   };

  //   throw new CompanyNotRegisteredException('Company not registered', payload);
  // }

  return {
    action: 'created',
    createdUser: sanitizeAuthUserFromRaw(response.data),
  };
}

export function isUserLinked(
  action: CreateUser | LinkUser
): action is LinkUser {
  return action.action === 'linked';
}

export function isUserCreated(
  action: CreateUser | LinkUser
): action is CreateUser {
  return action.action === 'created';
}
