import {sanitizeKudosFromRaw} from '../../../ui/@sanitizers/kudo';
import {sanitizeMetaFromRaw} from '../../../ui/@sanitizers/meta';
import InstillAPI from '../axios-instance';
import isResponseSuccessful from '../../../utils/is-response-successful';

export interface FetchKudosPayload {
  companyUuid: string;
  fromUserId?: string;
  limit?: number;
  offset?: number;
  query?: string | null;
  toUserUuid?: string | null;
  valueUuid?: string | null;
}

export async function fetchKudos(parameters: FetchKudosPayload) {
  const {companyUuid, offset, limit, query, valueUuid, toUserUuid, fromUserId} =
    parameters;

  let url = `/companies/${companyUuid}/kudos`;
  let queryParams: string[] = [];

  if (offset) {
    queryParams.push(`skip=${offset}`);
  }

  if (limit) {
    queryParams.push(`limit=${limit}`);
  }

  if (query) {
    queryParams.push(`q=${query}`);
  }

  if (valueUuid) {
    queryParams.push(`value_uuid=${valueUuid}`);
  }

  if (fromUserId) {
    queryParams.push(`from_user_uuid=${fromUserId}`);
  }

  if (toUserUuid) {
    queryParams.push(`to_user_uuid=${toUserUuid}`);
  }

  if (queryParams) {
    url = `${url}?${queryParams.join('&')}`;
  }

  try {
    const response = await InstillAPI.get(url);

    if (response.data.length || !isResponseSuccessful(response)) {
      return;
    }

    return {
      data: sanitizeKudosFromRaw(response.data.data),
      meta: sanitizeMetaFromRaw(response.data.meta),
    };
  } catch (error) {
    console.error(error);
  }
}
