import {FunctionComponent} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {HexacoProfile} from '../../../../../../interfaces/hexaco-profile';
import RadarChart, {
  getHexacoScoreFromProfile,
} from '../../../../../@components/radar-chart';
import Tag from '../../../../../@components/tag';
import styles from './styles.module.scss';

interface ElementProps {
  moduleResult: HexacoProfile;
}

const formatText = (text: string) => {
  const paragraphs = text.split('\n');

  return paragraphs.map((paragraph, index) => (
    <p key={index} className={styles.paragraph}>
      {paragraph}
    </p>
  ));
};

const HexacoSummary: FunctionComponent<ElementProps> = ({moduleResult}) => {
  const {t} = useTranslation('assessmentSummary');

  return (
    <>
      <div className={styles.titleRow}>
        <Trans
          t={t}
          i18nKey="components.module-summary.components.hexaco-summary.title"
          components={{heading: <span className={styles.heading} />}}
          values={{
            name: moduleResult.personaContent.name,
            summary: moduleResult?.personaContent.summary,
          }}
        />
      </div>

      <ul className={styles.list}>
        {moduleResult?.personaContent.traits.map((trait, index) => (
          <li key={index} className={styles.listItem}>
            <Tag>{trait}</Tag>
          </li>
        ))}
      </ul>

      <div className={styles.chartContainer}>
        <RadarChart hexacoScore={getHexacoScoreFromProfile(moduleResult)} />
      </div>

      <div className={styles.contentRow}>
        <div className={styles.subHeading}>
          {moduleResult.personaContent.description.header}
        </div>

        {formatText(moduleResult.personaContent.description.content)}
      </div>

      <div className={styles.contentRow}>
        <div className={styles.subHeading}>
          {t(
            'components.module-summary.components.hexaco-summary.deep-dive-title'
          )}
        </div>

        {formatText(moduleResult.personaContent.deepDive.content)}
      </div>

      <div className={styles.contentRow}>
        <div className={styles.subHeading}>
          {t(
            'components.module-summary.components.hexaco-summary.well-functioning-group-title'
          )}
        </div>
        {formatText(moduleResult.personaContent.wellFunctioningGroup.content)}
      </div>

      <div className={styles.contentRow}>
        <div className={styles.subHeading}>
          {t(
            'components.module-summary.components.hexaco-summary.out-of-sync-title'
          )}
        </div>

        {formatText(moduleResult.personaContent.outOfSync.content)}
      </div>
    </>
  );
};

export default HexacoSummary;
