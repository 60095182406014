import {FunctionComponent} from 'react';
import {ReactComponent as IconArrow} from '../../@components/kit/icons/arrow.svg';
import {useTranslation} from 'react-i18next';
import styles from './styles.module.scss';

interface ElementProps {
  text: string;
}

const FeedbackBanner: FunctionComponent<ElementProps> = ({text}) => {
  const {t} = useTranslation('components');

  return (
    <div className={styles.banner}>
      <div className={styles.wrapper}>
        {text}

        <a
          className={styles.link}
          href={t('feedback-banner.link-url')}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('feedback-banner.link-text')}

          <IconArrow className={styles.icon} />
        </a>
      </div>
    </div>
  );
};

export default FeedbackBanner;
