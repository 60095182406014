import {Actions} from '../../../../../../../../../../constants/permissions/actions';
import {
  ChangeEvent,
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {CompanyEmployee} from '../../../../../../../../../../interfaces/company-employee';
import {DEFAULT_TOASTIFY_CONFIGURATION} from '../../../../../../../../../../constants/toastify-configuration';
import {EventName} from '../../../../../../../../../../constants/analytics/event-name';
import {EventType} from '../../../../../../../../../../constants/analytics/event-type';
import {formatDate} from '../../../../../../../../../../utils/date';
import {getUserProfileForCompany} from '../../../../../../../../../../services/user-profiles';
import {Link} from 'react-router-dom';
import {ProfileNameLinkClickedEvent} from '../../../../../../../../../../interfaces/analytics/event-properties';
import {Roles} from '../../../../../../../../../../constants/roles';
import {sanitizeCompanyEmployeeToRaw} from '../../../../../../../../../@sanitizers/company-employees';
import {toast, ToastOptions} from 'react-toastify';
import {useCurrentAuthUserState} from '../../../../../../../../../@atoms/current-auth-user';
import {useSafeCurrentCompany} from '../../../../../../../../../@atoms/current-company';
import {useTranslation} from 'react-i18next';
import {useUpdateUserProfileByAdmin} from '../../../../../../../../../@hooks/mutations';
import {useUserPermission} from '../../../../../../../../../@hooks/use-user-permission';
import AvatarPlaceholder from '../../../../../../../../../@components/avatar-placeholder';
import EmployeeTeamTag from '../../../../../../../../../@components/employee-team-tag/employee-team-tag';
import PendingEmployee from './@components/pending-employee';
import Select from '../../../../../../../../../@components/kit/form/select';
import styles from './styles.module.scss';
import Table from '../../../../../../../../../@components/table';
import tableStyles from '../../../../../../../../../../styles/classes/table.module.scss';
import useAnalytics from '../../../../../../../../../@hooks/use-analytics';

interface ElementProps {
  employee: CompanyEmployee;
  onSendReminder: (workEmail: string) => void;
}

interface DropdownContent {
  isDisabled?: boolean;
  label: string;
  value: string;
}

const TableRow: FunctionComponent<ElementProps> = ({
  employee,
  onSendReminder,
}) => {
  const {t} = useTranslation('people');

  const [currentAuthUser] = useCurrentAuthUserState();
  const currentCompany = useSafeCurrentCompany();
  const {trackEvent} = useAnalytics();

  const updateUserProfileByAdmin = useUpdateUserProfileByAdmin();

  const userProfile = getUserProfileForCompany(
    currentAuthUser!,
    currentCompany
  );
  const can = useUserPermission(userProfile.role);
  const permissionLevels = useMemo(() => {
    return [
      {
        value: Roles.OWNER,
        isDisabled: !can(Actions.UPDATE_ROLE_TO_OWNER),
        label: t(
          'manage-directory.components.employee-list.components.list.components.table-row.permissions.owner'
        ),
      },
      {
        value: Roles.ADMIN,
        isDisabled: !can(Actions.UPDATE_ROLE_TO_ADMIN),
        label: t(
          'manage-directory.components.employee-list.components.list.components.table-row.permissions.admin'
        ),
      },
      {
        value: Roles.MANAGER,
        isDisabled: !can(Actions.UPDATE_ROLE_TO_MANAGER),
        label: t(
          'manage-directory.components.employee-list.components.list.components.table-row.permissions.manager'
        ),
      },
      {
        value: Roles.MEMBER,
        isDisabled: !can(Actions.UPDATE_ROLE_TO_MEMBER),
        label: t(
          'manage-directory.components.employee-list.components.list.components.table-row.permissions.member'
        ),
      },
    ];
  }, [t, can]);

  const selectedPermissionFromList = useMemo(() => {
    return permissionLevels.find(
      (permission) => permission.value === employee.role
    );
  }, [permissionLevels, employee]);

  const [selectedPermission, setSelectedPermission] = useState<
    DropdownContent | undefined
  >(selectedPermissionFromList);

  const companyUuid = currentCompany.uuid;
  const userProfileUuid = employee!.userProfileUuid;

  const statuses = useMemo(() => {
    return [
      {
        label: t(
          'manage-directory.components.employee-list.components.list.components.table-row.statuses.active'
        ),
        value: 'true',
      },
      {
        label: t(
          'manage-directory.components.employee-list.components.list.components.table-row.statuses.inactive'
        ),
        value: 'false',
      },
    ];
  }, [t]);

  const selectedStatusFromList = useMemo(() => {
    return statuses.find(
      (status) => status.value === employee.isActive?.toString()
    );
  }, [statuses, employee]);

  const [selectedStatus, setSelectedStatus] = useState<
    DropdownContent | undefined
  >(selectedStatusFromList);

  const isCurrentUserAbleToEditEmployee = useMemo(() => {
    const employeeRole = employee.role;
    const currentUserRole = userProfile.role;

    if (employeeRole === Roles.OWNER) {
      return !(currentUserRole === Roles.OWNER);
    }

    if (employeeRole === Roles.ADMIN) {
      return !(
        currentUserRole === Roles.OWNER || currentUserRole === Roles.ADMIN
      );
    }

    if (employeeRole !== Roles.OWNER && employeeRole !== Roles.ADMIN) {
      return !(
        currentUserRole === Roles.OWNER || currentUserRole === Roles.ADMIN
      );
    }

    return true;
  }, [employee.role, userProfile.role]);

  const isEmployeeInactive = selectedStatus?.value === 'false';
  const hasEmployeeJoined = Boolean(employee.joinedDate);
  const isEmployeeTheCurrentUser =
    employee.userProfileUuid === userProfile.uuid;

  const routeUrlLink = useMemo(() => {
    if (isEmployeeTheCurrentUser) {
      return '/application/my-profile';
    }

    if (!employee.userProfileUuid) return null;

    return `/application/people/employees/${employee.userProfileUuid}`;
  }, [employee, isEmployeeTheCurrentUser]);

  const isPermissionDropdownDisabled = useMemo(() => {
    if (currentCompany.companySettings.isHRISProviderUKG) return true;
    if (isEmployeeInactive || isEmployeeTheCurrentUser) return true;

    return isCurrentUserAbleToEditEmployee;
  }, [
    currentCompany,
    isCurrentUserAbleToEditEmployee,
    isEmployeeInactive,
    isEmployeeTheCurrentUser,
  ]);

  const isStatusDropdownDisabled = useMemo(() => {
    if (currentCompany.companySettings.isHRISProviderUKG) return true;
    if (isEmployeeInactive && !hasEmployeeJoined) return true;

    return isEmployeeTheCurrentUser || isCurrentUserAbleToEditEmployee;
  }, [
    currentCompany,
    hasEmployeeJoined,
    isCurrentUserAbleToEditEmployee,
    isEmployeeInactive,
    isEmployeeTheCurrentUser,
  ]);

  const onPermissionChange = useCallback(
    async (event: ChangeEvent<HTMLSelectElement>) => {
      const value = event.target.value;
      const selectedValue = permissionLevels.find(
        (permission) => permission.value === value
      );

      if (!selectedValue) return;

      const payload = sanitizeCompanyEmployeeToRaw({
        role: selectedValue.value,
      });

      const response = await updateUserProfileByAdmin.mutateAsync({
        companyEmployeePayload: payload,
        companyUuid,
        userProfileUuid,
      });

      if (!response) return;

      trackEvent({
        eventName: EventName.PEOPLE.MANAGE_DIRECTORY.PERMISSIONS_CHANGES,
      });

      const feedbackMessage = t(
        'manage-directory.components.employee-list.components.list.components.table-row.feedback-messages.permission-updated'
      );
      toast.success(feedbackMessage, {
        ...(DEFAULT_TOASTIFY_CONFIGURATION as ToastOptions),
      });

      setSelectedPermission(selectedValue);
    },
    [
      companyUuid,
      permissionLevels,
      t,
      trackEvent,
      updateUserProfileByAdmin,
      userProfileUuid,
    ]
  );

  const onStatusChange = useCallback(
    async (event: ChangeEvent<HTMLSelectElement>) => {
      const value = event.target.value;
      const selectedValue = statuses.find((status) => status.value === value);

      if (!selectedValue) return;

      const payload = sanitizeCompanyEmployeeToRaw({
        isActive: selectedValue.value,
      });

      const response = await updateUserProfileByAdmin.mutateAsync({
        companyEmployeePayload: payload,
        companyUuid,
        userProfileUuid,
      });

      if (!response) return;

      trackEvent({
        eventName: EventName.PEOPLE.MANAGE_DIRECTORY.STATUS_CHANGES,
      });

      const feedbackMessage = t(
        'manage-directory.components.employee-list.components.list.components.table-row.feedback-messages.status-updated'
      );
      toast.success(feedbackMessage, {
        ...(DEFAULT_TOASTIFY_CONFIGURATION as ToastOptions),
      });

      setSelectedStatus(selectedValue);
    },
    [
      companyUuid,
      statuses,
      t,
      trackEvent,
      updateUserProfileByAdmin,
      userProfileUuid,
    ]
  );

  const onProfileLinkClicked = useCallback(() => {
    const eventProperties = {
      profileName: employee.name,
    } as ProfileNameLinkClickedEvent;

    trackEvent({
      eventName: EventName.PEOPLE.EMPLOYEES.PROFILE_CLICK_NAME_COLUMN,
      eventType: EventType.LINK_CLICKED,
      eventProperties,
    });
  }, [trackEvent, employee]);

  return (
    <Table.Row className={tableStyles.tableRow}>
      <Table.Data
        className={`${tableStyles.tableData} ${
          isEmployeeInactive ? styles.inactive : ''
        }`}
        variant="small"
      >
        <div className={tableStyles.mobileHeaderTitle}>
          {t(
            'manage-directory.components.employee-list.components.list.table.header.permissions'
          )}
        </div>

        {selectedPermission && (
          <Select
            defaultValue={selectedPermission.value}
            disabled={isPermissionDropdownDisabled}
            onChange={onPermissionChange}
            variant="borderless"
          >
            {permissionLevels.map((permission, index) => (
              <option
                disabled={permission.isDisabled}
                key={index}
                value={permission.value}
              >
                {permission.label}
              </option>
            ))}
          </Select>
        )}
      </Table.Data>

      <Table.Data
        className={`${tableStyles.tableData} ${
          isEmployeeInactive || !hasEmployeeJoined ? styles.inactive : ''
        }`}
        variant="nameColumn"
      >
        <div className={tableStyles.mobileHeaderTitle}>
          {t(
            'manage-directory.components.employee-list.components.list.table.header.name'
          )}
        </div>

        <div className={tableStyles.overflowContainer}>
          {employee.picture ? (
            <img
              src={employee.picture}
              className={styles.profilePicture}
              alt=""
            />
          ) : (
            <div className={styles.avatarContainer}>
              <AvatarPlaceholder
                size="small"
                variant="dark-blue"
                name={employee.name}
              />
            </div>
          )}

          {routeUrlLink ? (
            <Link
              className={styles.link}
              onClick={onProfileLinkClicked}
              to={routeUrlLink}
            >
              {employee.name}
            </Link>
          ) : (
            <>{employee.name}</>
          )}
        </div>
      </Table.Data>

      <Table.Data
        className={`${tableStyles.tableData} ${
          isEmployeeInactive ? styles.inactive : ''
        }`}
        variant="medium"
      >
        <div className={tableStyles.mobileHeaderTitle}>
          {t(
            'manage-directory.components.employee-list.components.list.table.header.role'
          )}
        </div>

        {employee.jobTitle ? (
          <div>{employee.jobTitle}</div>
        ) : (
          <div className={styles.emptyValue}>—</div>
        )}
      </Table.Data>

      <Table.Data
        className={`${tableStyles.tableData} ${
          isEmployeeInactive ? styles.inactive : ''
        }`}
        variant="large"
      >
        <div className={tableStyles.mobileHeaderTitle}>
          {t(
            'manage-directory.components.employee-list.components.list.table.header.team'
          )}
        </div>

        {employee.team ? (
          <EmployeeTeamTag team={employee.team} />
        ) : (
          <div className={styles.emptyValue}>—</div>
        )}
      </Table.Data>

      <Table.Data className={tableStyles.tableData} variant="small">
        <div className={tableStyles.mobileHeaderTitle}>
          {t(
            'manage-directory.components.employee-list.components.list.table.header.is-active'
          )}
        </div>

        {selectedStatus && (
          <Select
            defaultValue={selectedStatus.value}
            onChange={onStatusChange}
            variant="borderless"
            disabled={isStatusDropdownDisabled}
          >
            {statuses.map((status, index) => (
              <option key={index} value={status.value}>
                {status.label}
              </option>
            ))}
          </Select>
        )}
      </Table.Data>

      <Table.Data
        className={`${tableStyles.tableData} ${
          isEmployeeInactive && employee.joinedDate ? styles.inactive : ''
        }`}
        variant="small"
      >
        <div className={tableStyles.mobileHeaderTitle}>
          {t(
            'manage-directory.components.employee-list.components.list.table.header.date-joined'
          )}
        </div>

        {employee.joinedDate ? (
          formatDate(employee.joinedDate)
        ) : (
          <PendingEmployee
            onSendReminder={onSendReminder}
            employee={employee}
          />
        )}
      </Table.Data>
    </Table.Row>
  );
};

export default TableRow;
