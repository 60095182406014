function formatTimeAsString({hour, minute}: {hour: string; minute?: string}) {
  if (!hour) return;

  let time = hour;

  if (minute) {
    time = `${time}:${minute}`;
  } else {
    time = `${time}:00`;
  }

  return time;
}

export default formatTimeAsString;
