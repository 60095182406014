import {EventName} from '../../../../../../constants/analytics/event-name';
import {EventType} from '../../../../../../constants/analytics/event-type';
import {FunctionComponent, useCallback, useMemo} from 'react';
import {NavLink} from 'react-router-dom';
import {UserAssessment} from '../../../../../../interfaces/user-assessment';
import {useTranslation} from 'react-i18next';
import {ViewAssessmentResultEvent} from '../../../../../../interfaces/analytics/event-properties';
import Modules from './@components/modules';
import ProgressBarWithLabel from '../../../../../@components/progress-bar-with-label';
import RoundedCard from '../../../../../@components/rounded-card';
import styles from './styles.module.scss';
import useAnalytics from '../../../../../@hooks/use-analytics';

interface ElementProps {
  assessment: UserAssessment;
}

const Assessment: FunctionComponent<ElementProps> = ({assessment}) => {
  const {t} = useTranslation('measure');

  const {trackEvent} = useAnalytics();

  const isAssessmentCompleted = useMemo(() => {
    return (
      assessment.completedModulesUuid.length ===
      assessment.assessmentEvent.assessment.modules.length
    );
  }, [assessment]);

  const onViewResultLinkClicked = useCallback(() => {
    const eventProperties = {
      assessmentName: assessment.assessmentEvent.assessment.name,
    } as ViewAssessmentResultEvent;

    trackEvent({
      eventName: EventName.MEASURE.YOUR_ASSESSMENTS.VIEW_RESULT,
      eventType: EventType.BUTTON_CLICKED,
      eventProperties,
    });
  }, [trackEvent, assessment]);

  const onStartAssessmentLinkClicked = useCallback(() => {
    const eventProperties = {
      assessmentName: assessment.assessmentEvent.assessment.name,
    } as ViewAssessmentResultEvent;

    trackEvent({
      eventName: EventName.MEASURE.YOUR_ASSESSMENTS.START_ASSESSMENT,
      eventType: EventType.BUTTON_CLICKED,
      eventProperties,
    });
  }, [trackEvent, assessment]);

  const viewResultsLink = `/assessments-summary/${assessment.uuid}/modules/${assessment.assessmentEvent.assessment.modules[0].uuid}`;
  const startAssessmentLink = `/assessments/${assessment.uuid}/modules/${assessment.assessmentEvent.assessment.modules[0].uuid}`;

  const {assessmentEvent} = assessment;

  return (
    <>
      <RoundedCard>
        <RoundedCard.Header>
          <RoundedCard.Title>
            {assessmentEvent.assessment.name}
          </RoundedCard.Title>
        </RoundedCard.Header>

        <RoundedCard.Content>
          <div className={styles.container}>
            <div className={styles.assessment}>
              <div className={styles.header}>
                {t('your-assessment.components.assessment.overview-title')}
              </div>

              {assessmentEvent.assessment.overview && (
                <div className={styles.description}>
                  {assessmentEvent.assessment.overview}
                </div>
              )}

              <div className={styles.progressBar}>
                <ProgressBarWithLabel
                  currentProgress={assessment.completedModulesUuid.length}
                  total={assessmentEvent.assessment.modules.length}
                />
              </div>

              {isAssessmentCompleted ? (
                <NavLink
                  to={viewResultsLink}
                  className={styles.viewResultsButton}
                  onClick={onViewResultLinkClicked}
                >
                  {t(
                    'your-assessment.components.assessment.view-results-button'
                  )}
                </NavLink>
              ) : (
                <NavLink
                  to={startAssessmentLink}
                  className={styles.startAssessmentButton}
                  onClick={onStartAssessmentLinkClicked}
                >
                  {t('your-assessment.components.assessment.start-button')}
                </NavLink>
              )}
            </div>

            {assessmentEvent.assessment.modules && (
              <div className={styles.modules}>
                <Modules
                  modules={assessmentEvent.assessment.modules}
                  endDate={assessmentEvent.endDate}
                  completedModulesUuid={assessment.completedModulesUuid}
                />
              </div>
            )}
          </div>
        </RoundedCard.Content>
      </RoundedCard>
    </>
  );
};

export default Assessment;
