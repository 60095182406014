import {buildPageTitle} from '../../../../services/build-page-title';
import {EventName} from '../../../../constants/analytics/event-name';
import {EventType} from '../../../../constants/analytics/event-type';
import {FunctionComponent, useCallback, useMemo, useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {ReactComponent as IconCheckCircle} from '../../../@components/kit/icons/check-circle.svg';
import {ReactComponent as IconFile} from '../../../@components/kit/icons/file.svg';
import {ReactComponent as IconMedia} from '../../../@components/kit/icons/play-circle.svg';
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import {useCompanyCoreValues} from '../../../@hooks/queries';
import {useNavigate} from 'react-router';
import {USER_CHECKLIST_STEP_KEYS} from '../../@components/setup-checklist/setup-checklist';
import {useSafeCurrentCompany} from '../../../@atoms/current-company';
import {useTranslation} from 'react-i18next';
import BackButton from '../../@components/back-button';
import Button from '../../../@components/kit/form/button';
import ExpectationList from './@components/expectation-list';
import FormFooter from '../../../@components/kit/form/form-footer';
import FormGroup from '../../../@components/kit/form/form-group';
import Label from '../../../@components/kit/form/label';
import MediaContent from '../../@components/media-content/media-content';
import ProgressBar from '../../../@components/progress-bar';
import RichTextContainer from '../../../@components/rich-text-container';
import styles from './styles.module.scss';
import useAnalytics from '../../../@hooks/use-analytics';
import UserSetupStyles from '../styles.module.scss';

interface ElementProps {
  stepIdentifier: USER_CHECKLIST_STEP_KEYS;
  updateChecklist: (stepIdentifier: USER_CHECKLIST_STEP_KEYS) => void;
}

const NEXT_STEP_ROUTE = '/application/user-setup/sign-off-on-values';

const ReviewCompanyValues: FunctionComponent<ElementProps> = ({
  stepIdentifier,
  updateChecklist,
}) => {
  const {t} = useTranslation('userSetup');
  const currentCompany = useSafeCurrentCompany();

  const [valueIndex, setValueIndex] = useState<number>(0);
  const [isReviewCompleted, setIsReviewCompleted] = useState<boolean>(false);

  const navigate = useNavigate();
  const {trackEvent} = useAnalytics();

  const values = useCompanyCoreValues({
    variables: {
      companyUuid: currentCompany.uuid,
    },
    onSettled(data, error) {
      if (data?.length === 0) {
        updateChecklist(stepIdentifier);
        navigate(NEXT_STEP_ROUTE);
        return;
      }

      if (data?.length === 1) {
        setIsReviewCompleted(true);
      }
    },
  });

  const value = useMemo(() => {
    return values.data ? values.data[valueIndex] : undefined;
  }, [values, valueIndex]);

  const onReviewValuesButtonClicked = useCallback(() => {
    trackEvent({
      eventName: EventName.USER_SETUP.REVIEW_COMPANY_VALUES,
      eventType: EventType.BUTTON_CLICKED,
    });

    updateChecklist(stepIdentifier);
    navigate(NEXT_STEP_ROUTE);
  }, [trackEvent, stepIdentifier, updateChecklist, navigate]);

  if (!values) return null;
  if (!value) return null;
  if (!value.expectations) return null;

  const showNextValue = () => {
    const updatedValueIndex = valueIndex + 1;
    const valuesLengthZeroBased = (values.data?.length ?? 0) - 1;

    setValueIndex(updatedValueIndex);

    if (updatedValueIndex === valuesLengthZeroBased) {
      setIsReviewCompleted(true);
    }
  };

  return (
    <>
      <Helmet>
        <title>
          {buildPageTitle(
            t('page-title.user-setup.review-company-values', {
              ns: 'applicationPageTitle',
            })
          )}
        </title>
      </Helmet>

      <BackButton path="/application/user-setup/enter-your-work-style/" />

      <div className={UserSetupStyles.header}>
        <div className={styles.headerBar}>
          <div className={UserSetupStyles.headerTitle}>
            <h1>
              {t('review-company-values.header.title')}
              <IconCheckCircle className={UserSetupStyles.headerIcon} />
            </h1>
          </div>

          {values.data?.length && (
            <ProgressBar
              currentProgress={valueIndex + 1}
              total={values.data?.length}
              variant="small"
            />
          )}
        </div>
      </div>

      <div className={styles.activeValue}>
        <div className={styles.form}>
          <Tabs
            className={styles.formContainer}
            selectedTabClassName={styles.selectedItem}
            selectedTabPanelClassName={styles.selectedTab}
          >
            <TabPanel className={styles.tab}>
              <FormGroup>
                <Label className={styles.label}>
                  {t('review-company-values.name.label')}
                </Label>

                <div className={`${styles.textarea} }`}>{value.name}</div>
              </FormGroup>

              <FormGroup className={`${styles.formGroup}}`}>
                <Label className={styles.label}>
                  {t('review-company-values.description.label', {
                    companyName: currentCompany.name,
                  })}
                </Label>

                <div className={`${styles.textarea} }`}>
                  <RichTextContainer value={value.description} />
                </div>
              </FormGroup>

              {value.expectations.length > 0 && (
                <>
                  <div className={styles.formGroupLabel}>
                    {t('review-company-values.expectations.label')}
                  </div>

                  <div className={styles.rowContent}>
                    <ExpectationList expectations={value.expectations} />
                  </div>
                </>
              )}
            </TabPanel>

            <TabPanel className={styles.tab}>
              <FormGroup>
                <Label className={styles.label}>
                  {t('review-company-values.name.label')}
                </Label>

                <div className={`${styles.textarea} }`}>{value.name}</div>

                <FormGroup>
                  <div className={styles.mediaContent}>
                    <div className={styles.label}>
                      {t('review-company-values.media.content.label')}
                    </div>
                  </div>

                  <MediaContent media={value.media} />
                </FormGroup>
              </FormGroup>
            </TabPanel>

            <div className={styles.sidebar}>
              <TabList className={styles.list}>
                <Tab className={styles.item}>
                  <IconFile />
                </Tab>

                <Tab className={styles.item}>
                  <IconMedia />
                </Tab>
              </TabList>
            </div>
          </Tabs>
        </div>
      </div>

      <FormFooter>
        <Button onClick={showNextValue} disabled={isReviewCompleted}>
          {t('review-company-values.next-value-button')}
        </Button>

        {isReviewCompleted && (
          <Button onClick={onReviewValuesButtonClicked}>
            {t('review-company-values.sign-off-on-values')}
          </Button>
        )}
      </FormFooter>
    </>
  );
};

export default ReviewCompanyValues;
