import {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import styles from './styles.module.scss';

const NoResultsBox: FunctionComponent = () => {
  const {t} = useTranslation('measure');

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {t('manage-assessments.components.no-results-box.title')}
      </div>

      <div className={styles.text}>
        {t('manage-assessments.components.no-results-box.text')}
      </div>
    </div>
  );
};

export default NoResultsBox;
