import {createQuery} from 'react-query-kit';
import {fetchAssessmentResult} from '../../../repositories/instill';
import {FetchAssessmentResultPayload} from '../../../repositories/instill/queries/fetch-assessment-result';
import {UserAssessmentResult} from '../../../interfaces/user-assessment-result';

export const useAssessmentResult = createQuery<
  UserAssessmentResult | undefined,
  FetchAssessmentResultPayload
>({
  primaryKey: 'assessmentResult',
  queryFn: async ({queryKey: [_, variables]}) => {
    return await fetchAssessmentResult(
      variables.userAssessmentUuid,
      variables.moduleUuid
    );
  },
});
