import {AssessmentFormProps} from './@component/assessment-form';
import {
  CompanyAssessment,
  CompanyAssessmentUpsertPayload,
} from '../../../../../../../../../interfaces/company-assessments';
import {CompanyEmployee} from '../../../../../../../../../interfaces/company-employee';
import {DEFAULT_TOASTIFY_CONFIGURATION} from '../../../../../../../../../constants/toastify-configuration';
import {DisableAssessmentFormProps} from './@component/disable-assessment-dialog';
import {EventName} from '../../../../../../../../../constants/analytics/event-name';
import {EventType} from '../../../../../../../../../constants/analytics/event-type';
import {FunctionComponent, useCallback, useState} from 'react';
import {SCHEDULE_TRANSLATION_MAP} from '../../../../../../../../../constants/schedule';
import {SendMessageDialogFormProps} from './@component/send-message-dialog';
import {SendMessageEvent} from '../../../../../../../../../interfaces/analytics/event-properties';
import {toast, ToastOptions} from 'react-toastify';
import {useSafeCurrentCompany} from '../../../../../../../../@atoms/current-company';
import {
  useSendReminderEmailForAssessment,
  useUpdateCompanyAssessment,
  useUpdateCompanyAssessmentSettings,
} from '../../../../../../../../@hooks/mutations';
import {useTranslation} from 'react-i18next';
import DetailsDialog from './@component/details-dialog';
import styles from './styles.module.scss';
import TableRow from './@component/table-row';
import useAnalytics from '../../../../../../../../@hooks/use-analytics';
import {Team} from '../../../../../../../../../interfaces/team';

interface ElementProps {
  companyAssessments: CompanyAssessment[];
  companyEmployees: CompanyEmployee[];
  teams: Team[];
  onRefreshCompanyAssessments: () => void;
}

const ListView: FunctionComponent<ElementProps> = ({
  companyAssessments,
  companyEmployees,
  teams,
  onRefreshCompanyAssessments,
}) => {
  const {t} = useTranslation('measure');

  const currentCompany = useSafeCurrentCompany();
  const {trackEvent} = useAnalytics();

  const [isDetailsDialogVisible, setIsDetailsDialogVisible] = useState(false);

  const [selectedAssessment, setSelectedAssessment] =
    useState<CompanyAssessment | null>(null);

  const sendReminderEmailForAssessment = useSendReminderEmailForAssessment();
  const updateCompanyAssessment = useUpdateCompanyAssessment();
  const updateCompanyAssessmentSettings = useUpdateCompanyAssessmentSettings();

  const onShowDetailsDialog = useCallback(
    (companyAssessment: CompanyAssessment) => {
      setIsDetailsDialogVisible(true);
      setSelectedAssessment(companyAssessment);
    },
    []
  );

  const onCloseDetailsDialog = useCallback(() => {
    setIsDetailsDialogVisible(false);

    setTimeout(() => {
      setSelectedAssessment(null);
    }, 1000);
  }, []);

  const onUpdateCompanyAssessment = useCallback(
    async (
      payload: CompanyAssessmentUpsertPayload,
      companyAssessment: CompanyAssessment
    ) => {
      const assessmentUuid = companyAssessment.assessment.uuid;
      const companyUuid = currentCompany.uuid;

      const response = await updateCompanyAssessment.mutateAsync({
        companyUuid,
        assessmentUuid,
        payload,
      });

      if (!response) return;

      return response;
    },
    [currentCompany, updateCompanyAssessment]
  );

  const getSeperateUuids = useCallback(
    (uuids: string[]) => {
      const teamUuids: string[] = [];
      const companyEmployeesUuids: string[] = [];

      uuids?.forEach((uuid) => {
        if (teams.findIndex((team) => team.uuid === uuid) !== -1) {
          teamUuids.push(uuid);
        } else {
          companyEmployeesUuids.push(uuid);
        }
      });

      return {
        teamUuids,
        companyEmployeesUuids,
      };
    },
    [teams]
  );

  const onUpdateSettings = useCallback(
    async (
      values: AssessmentFormProps,
      companyAssessment: CompanyAssessment
    ) => {
      const {teamUuids, companyEmployeesUuids} = getSeperateUuids(values.uuids);

      const payload = {
        dueDate: values.dueDate,
        schedule: values.schedule,
        population: {
          isCompanyWide: values.isCompanyWide,
          userProfileUuids: values.isCompanyWide ? [] : companyEmployeesUuids,
          teamUuids: values.isCompanyWide ? [] : teamUuids,
        },
      };

      const assessmentUuid = companyAssessment.assessment.uuid;
      const companyUuid = currentCompany.uuid;

      const response = await updateCompanyAssessmentSettings.mutateAsync({
        companyUuid,
        assessmentUuid,
        payload,
      });

      if (!response) return;

      const feedbackMessage = t(
        'manage-assessments.components.assessments.components.list-view.feedback-messages.update-settings-success'
      );

      toast.success(feedbackMessage, {
        ...(DEFAULT_TOASTIFY_CONFIGURATION as ToastOptions),
      });

      return response;
    },
    [currentCompany, updateCompanyAssessmentSettings, t, getSeperateUuids]
  );

  const onTrackEnableAssessmentEvent = useCallback(
    (values: AssessmentFormProps, companyAssessment: CompanyAssessment) => {
      const scheduleTranslationKey =
        SCHEDULE_TRANSLATION_MAP[
          values.schedule as keyof typeof SCHEDULE_TRANSLATION_MAP
        ];

      const eventProperties = {
        assessmentName: companyAssessment.assessment.name,
        dueDate: values.dueDate,
        message: values.message,
        population: values.isCompanyWide
          ? t('constants.company-wide-label', {ns: 'common'})
          : values.uuids.length,
        schedule: t(scheduleTranslationKey, {ns: 'common'}),
      } as SendMessageEvent;

      trackEvent({
        eventName: EventName.MEASURE.MANAGE_ASSESSMENTS.SEND_ASSESSMENT,
        eventType: EventType.BUTTON_CLICKED,
        eventProperties,
      });
    },
    [trackEvent, t]
  );

  const onSubmitEnableAssessmentDialog = useCallback(
    async (
      values: AssessmentFormProps,
      companyAssessment: CompanyAssessment
    ) => {
      const {teamUuids, companyEmployeesUuids} = getSeperateUuids(values.uuids);

      const payload = {
        isActive: true,
        schedule: values.schedule,
        dueDate: values.dueDate,
        message: values.message,
        population: {
          isCompanyWide: values.isCompanyWide,
          userProfileUuids: values.isCompanyWide ? [] : companyEmployeesUuids,
          teamUuids: values.isCompanyWide ? [] : teamUuids,
        },
      };

      const response = await onUpdateCompanyAssessment(
        payload,
        companyAssessment
      );

      onTrackEnableAssessmentEvent(values, companyAssessment);

      const feedbackMessage = t(
        'manage-assessments.components.assessments.components.list-view.feedback-messages.enable-assessment-success'
      );

      toast.success(feedbackMessage, {
        ...(DEFAULT_TOASTIFY_CONFIGURATION as ToastOptions),
      });

      return response;
    },
    [
      onTrackEnableAssessmentEvent,
      onUpdateCompanyAssessment,
      t,
      getSeperateUuids,
    ]
  );

  const onSubmitDisableAssessmentDialog = useCallback(
    async (
      values: DisableAssessmentFormProps,
      companyAssessment: CompanyAssessment
    ) => {
      const payload = {
        isActive: false,
        message: values.message,
      };

      const response = await onUpdateCompanyAssessment(
        payload,
        companyAssessment
      );

      const eventProperties = {
        assessmentName: companyAssessment.assessment.name,
        message: values.message === '' ? '–' : values.message,
      };

      trackEvent({
        eventName: EventName.MEASURE.MANAGE_ASSESSMENTS.TURN_OFF_ASSESSMENT,
        eventType: EventType.BUTTON_CLICKED,
        eventProperties,
      });

      const feedbackMessage = t(
        'manage-assessments.components.assessments.components.list-view.feedback-messages.disable-assessment-success'
      );

      toast.success(feedbackMessage, {
        ...(DEFAULT_TOASTIFY_CONFIGURATION as ToastOptions),
      });

      return response;
    },
    [trackEvent, onUpdateCompanyAssessment, t]
  );

  const onSubmitSendMessageDialog = useCallback(
    async (
      values: SendMessageDialogFormProps,
      companyAssessment: CompanyAssessment
    ) => {
      const assessmentUuid = companyAssessment.assessment.uuid;
      const companyUuid = currentCompany.uuid;

      const response = await sendReminderEmailForAssessment.mutateAsync({
        companyUuid,
        assessmentUuid,
        payload: values,
      });

      if (!response) return;

      const feedbackMessage = t(
        'manage-assessments.components.assessments.components.list-view.feedback-messages.send-message-success'
      );

      toast.success(feedbackMessage, {
        ...(DEFAULT_TOASTIFY_CONFIGURATION as ToastOptions),
      });

      return response;
    },
    [currentCompany, sendReminderEmailForAssessment, t]
  );

  return (
    <>
      <div>
        <div className={styles.tableHeader}>
          <div className={`${styles.tableData} ${styles.nameColumn}`}>
            {t(
              'manage-assessments.components.assessments.components.list-view.table.header.assessment'
            )}
          </div>

          <div className={`${styles.tableData} ${styles.scheduleColumn}`}>
            {t(
              'manage-assessments.components.assessments.components.list-view.table.header.schedule'
            )}
          </div>

          <div className={`${styles.tableData} ${styles.startDateColumn}`}>
            {t(
              'manage-assessments.components.assessments.components.list-view.table.header.start-date'
            )}
          </div>

          <div className={`${styles.tableData} ${styles.populationColumn}`}>
            {t(
              'manage-assessments.components.assessments.components.list-view.table.header.population'
            )}
          </div>

          <div className={`${styles.tableData} ${styles.actionsColumn}`}>
            {t(
              'manage-assessments.components.assessments.components.list-view.table.header.actions'
            )}
          </div>
        </div>

        <ul className={styles.content}>
          {companyAssessments.map((companyAssessment, index) => (
            <TableRow
              teams={teams}
              companyAssessment={companyAssessment}
              companyEmployees={companyEmployees}
              key={companyAssessment.assessment.uuid}
              onNameClicked={onShowDetailsDialog}
              onRefreshCompanyAssessments={onRefreshCompanyAssessments}
              onSubmitDisableAssessmentDialog={onSubmitDisableAssessmentDialog}
              onSubmitEnableAssessmentDialog={onSubmitEnableAssessmentDialog}
              onSubmitSendMessageDialog={onSubmitSendMessageDialog}
              onUpdateAssessmentSettings={onUpdateSettings}
            />
          ))}
        </ul>
      </div>

      <DetailsDialog
        companyAssessment={selectedAssessment}
        isVisible={isDetailsDialogVisible}
        onClose={onCloseDetailsDialog}
      />
    </>
  );
};

export default ListView;
