import InstillAPI from '../axios-instance';

export interface CompleteActionStepPayload {
  companyUuid: string;
  actionStepUuid: string;
}

export const completeActionStep = async (
  CompleteActionStepPayload: CompleteActionStepPayload
) => {
  const {companyUuid, actionStepUuid} = CompleteActionStepPayload;

  const response = await InstillAPI.post(
    `/companies/${companyUuid}/action-steps/me/${actionStepUuid}/completed`
  );

  return response;
};
