import {buildPageTitle} from '../../../services/build-page-title';
import {CompanyRegistrationPersonalInformationEvent} from '../../../interfaces/analytics/event-properties';
import {EventName} from '../../../constants/analytics/event-name';
import {EventType} from '../../../constants/analytics/event-type';
import {fetchPresignedUploadURLResourceType} from '../../../repositories/instill/queries/fetch-presigned-url-config';
import {Field, FieldProps, useFormikContext} from 'formik';
import {FormEvent, FunctionComponent, useCallback, useMemo} from 'react';
import {Helmet} from 'react-helmet-async';
import {Link} from 'react-router-dom';
import {ReactComponent as IconChevronLeft} from '../../@components/kit/icons/chevron.svg';
import {SignupFormValues} from '../register-company';
import {useTranslation} from 'react-i18next';
import {
  useFetchPresignedUploadUrl,
  useUploadToPresignedUrl,
} from '../../@hooks/mutations';
import Button from '../../@components/kit/form/button';
import FormControl from '../../@components/kit/form/form-control';
import FormFooter from '../../@components/kit/form/form-footer';
import FormGroup from '../../@components/kit/form/form-group';
import ImageUpload from '../../@components/kit/form/image-upload';
import Label from '../../@components/kit/form/label';
import Select from '../../@components/kit/form/select';
import styles from '../styles.module.scss';
import useAnalytics from '../../@hooks/use-analytics';

interface ElementProps {
  onSubmit: () => void;
}

const roleOptions = [
  {
    label:
      'personal-information.form.roles.options.chief-executive-officer.label',
    value: 'Chief Executive Officer',
  },
  {
    label: 'personal-information.form.roles.options.founder-or-cofounder.label',
    value: 'Founder / Co-Founder',
  },
  {
    label: 'personal-information.form.roles.options.chief-people-officer.label',
    value: 'Chief People Officer',
  },
  {
    label: 'personal-information.form.roles.options.chief-hr-officer.label',
    value: 'Chief HR Officer',
  },
  {
    label: 'personal-information.form.roles.options.other-suite.label',
    value: 'Other C-Suite',
  },
  {
    label: 'personal-information.form.roles.options.director.label',
    value: 'Director',
  },
  {
    label: 'personal-information.form.roles.options.manager.label',
    value: 'Manager',
  },
  {
    label:
      'personal-information.form.roles.options.individual-contributor.label',
    value: 'Individual Contributor',
  },
];

const PersonalInformation: FunctionComponent<ElementProps> = ({onSubmit}) => {
  const {t} = useTranslation(['signup', 'common']);
  const {values, errors} = useFormikContext<SignupFormValues>();

  const hasErrors = useMemo(
    () => Boolean(errors.role || errors.isCultureDefined),
    [errors]
  );

  const {trackEvent} = useAnalytics();
  const fetchPresignedUploadURL = useFetchPresignedUploadUrl();
  const uploadToPresignedURL = useUploadToPresignedUrl();

  const handleProfilePictureChange = useCallback(
    async (
      picture: File,
      form: FieldProps['form'],
      field: FieldProps['field']
    ) => {
      if (!picture) return;

      const config = await fetchPresignedUploadURL.mutateAsync({
        ressource: fetchPresignedUploadURLResourceType.COMPANY_LOGO,
        mime: picture.type,
        extension: picture.name.split('.').pop() as string,
      });

      if (!config) return;

      const bucketURL = await uploadToPresignedURL.mutateAsync({
        config,
        file: picture,
      });
      if (!bucketURL) return;

      form.setFieldValue(field.name, bucketURL);
    },
    [fetchPresignedUploadURL, uploadToPresignedURL]
  );

  const handleSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      onSubmit();
    },
    [onSubmit]
  );

  const onDoneButtonClick = useCallback(() => {
    if (hasErrors) return;

    const eventProperties = {
      role: values.role,
    } as CompanyRegistrationPersonalInformationEvent;

    trackEvent({
      eventName: EventName.COMPANY_REGISTRATION.PERSONAL_INFORMATION,
      eventType: EventType.CONTINUE_BUTTON_CLICKED,
      eventProperties,
    });
  }, [hasErrors, trackEvent, values]);

  return (
    <>
      <Helmet>
        <title>
          {buildPageTitle([
            t('page-title.signup.personal-information', {
              ns: 'applicationPageTitle',
            }),
            t('page-title.signup.index', {ns: 'applicationPageTitle'}),
          ])}
        </title>
      </Helmet>

      <form onSubmit={handleSubmit} className={styles.form}>
        <div>
          <Link
            to="/register-company/company-details"
            className={styles.backLink}
          >
            <IconChevronLeft className={styles.backLinkIcon} />
            {t('personal-information.back-button')}
          </Link>
        </div>

        <div className={styles.formTitle}>
          {t('personal-information.title')}
        </div>

        <div className={styles.fields}>
          <Field name="role">
            {({field, form}: FieldProps<string, SignupFormValues>) => (
              <FormGroup>
                <FormControl
                  error={
                    form.errors.role && form.touched.role
                      ? t(form.errors.role, {ns: 'common'})
                      : undefined
                  }
                >
                  <Label htmlFor={field.name}>
                    {t('personal-information.form.roles.label', {
                      companyName: form.values.name,
                    })}
                  </Label>

                  <Select id={field.name} {...field}>
                    <option value="">
                      {t('personal-information.form.roles.placeholder')}
                    </option>

                    {roleOptions.map(({label, value}) => (
                      <option key={value} value={value}>
                        {t(label)}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </FormGroup>
            )}
          </Field>

          <Field name="companyLogo">
            {({field, form}: FieldProps<string, SignupFormValues>) => (
              <div className={styles.companyLogoContainer}>
                <FormGroup>
                  <FormControl
                    error={
                      form.errors.companyLogo && form.touched.companyLogo
                        ? t(form.errors.companyLogo, {ns: 'common'})
                        : undefined
                    }
                  >
                    <Label htmlFor={field.name}>
                      {t('personal-information.form.company-logo.label')}
                    </Label>

                    <div>
                      <ImageUpload
                        label={t(
                          'personal-information.form.company-logo.component-label'
                        )}
                        fileCallback={(file) => {
                          handleProfilePictureChange(file, form, field);
                        }}
                      />
                    </div>
                  </FormControl>
                </FormGroup>
              </div>
            )}
          </Field>
        </div>

        <FormFooter>
          <Button
            type="submit"
            disabled={hasErrors}
            onClick={onDoneButtonClick}
          >
            {t('personal-information.submit-button')}
          </Button>
        </FormFooter>
      </form>
    </>
  );
};

export default PersonalInformation;
