import {createQuery} from 'react-query-kit';
import {
  FetchUserActionStepsPayload,
  fetchUserActionSteps,
} from '../../../repositories/instill/queries/fetch-user-action-steps';
import {UserActionStep} from '../../../interfaces/action-step';

interface FetchUserActionSteps {
  data: UserActionStep[] | undefined;
}

export const useUserActionSteps = createQuery<
  FetchUserActionSteps | undefined,
  FetchUserActionStepsPayload
>({
  primaryKey: 'UserActionSteps',
  queryFn: async ({queryKey: [_, variables]}) => {
    let payload = {
      companyUuid: variables.companyUuid,
      onlyPending: variables.onlyPending,
    } as FetchUserActionStepsPayload;

    if (variables.onlyPending) payload['onlyPending'] = variables.onlyPending;

    return await fetchUserActionSteps(variables);
  },
});
