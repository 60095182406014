import {
  WORK_HOURS_OPTIONS,
  WORK_MINUTES_OPTIONS,
  WORK_STYLE_TRANSLATION_MAP,
} from '../../../../constants/work-style-preferences';
import {Field, FieldProps, useFormikContext} from 'formik';
import {FunctionComponent} from 'react';
import {useCurrentFullUserProfile} from '../../../@atoms/current-full-user-profile';
import {useTranslation} from 'react-i18next';
import {useWorkStyleValues} from '../../../@hooks/queries';
import {WorkStylePreferencesFormValues} from '../../../../interfaces/work-style-preferences';
import Button from '../../../@components/kit/form/button';
import FormControl from '../../../@components/kit/form/form-control';
import FormFooter from '../../../@components/kit/form/form-footer';
import FormGroup from '../../../@components/kit/form/form-group';
import Input from '../../../@components/kit/form/input';
import Label from '../../../@components/kit/form/label';
import Select from '../../../@components/kit/form/select';
import styles from './styles.module.scss';

interface ElementProps {
  onSubmit: () => void;
  onSkip: () => void;
}

const Form: FunctionComponent<ElementProps> = ({onSubmit, onSkip}) => {
  const {t} = useTranslation('userSetup');

  const currentFullUserProfile = useCurrentFullUserProfile();
  const {errors, isValid} = useFormikContext<WorkStylePreferencesFormValues>();

  const {data: workStyleValues} = useWorkStyleValues({
    variables: {},
  });

  return (
    <>
      <form onSubmit={onSubmit} className={styles.form}>
        <div className={styles.formContent}>
          <div className={styles.role}>
            <div className={styles.roleItem}>
              <div className={styles.timerRoleItem}>
                <Field name="workHours.startTimeHour">
                  {({
                    field,
                  }: FieldProps<string, WorkStylePreferencesFormValues>) => (
                    <FormControl
                      error={
                        Boolean(errors.workHours?.startTimeHour)
                          ? errors.workHours?.startTimeHour
                          : undefined
                      }
                    >
                      <Label htmlFor={field.name}>
                        {t('work-style.form.start-time.label')}
                      </Label>

                      <Select id={field.name} {...field}>
                        <option value="" disabled>
                          {t(
                            'constants.work-style-preferences.keys.start-time.hours-label',
                            {ns: 'common'}
                          )}
                        </option>

                        {WORK_HOURS_OPTIONS.map(({label, value}) => (
                          <option key={value} value={value}>
                            {t(label)}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Field>

                <Field name="workHours.startTimeMinute">
                  {({
                    field,
                  }: FieldProps<string, WorkStylePreferencesFormValues>) => (
                    <FormControl>
                      <Label htmlFor={field.name} isIconVisible={false}>
                        &nbsp;
                      </Label>

                      <Select id={field.name} {...field}>
                        <option value="" disabled>
                          {t(
                            'constants.work-style-preferences.keys.start-time.minutes-label',
                            {ns: 'common'}
                          )}
                        </option>

                        {WORK_MINUTES_OPTIONS.map(({label, value}) => (
                          <option key={value} value={value}>
                            {t(label)}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Field>
              </div>
            </div>

            <div className={styles.roleItem}>
              <div className={styles.timerRoleItem}>
                <Field name="workHours.endTimeHour">
                  {({
                    field,
                  }: FieldProps<string, WorkStylePreferencesFormValues>) => (
                    <FormControl
                      error={
                        Boolean(errors.workHours?.endTimeHour)
                          ? errors.workHours?.endTimeHour
                          : undefined
                      }
                    >
                      <Label htmlFor={field.name}>
                        {t('work-style.form.end-time.label')}
                      </Label>

                      <Select id={field.name} {...field}>
                        <option value="" disabled>
                          {t(
                            'constants.work-style-preferences.keys.end-time.hours-label',
                            {ns: 'common'}
                          )}
                        </option>

                        {WORK_HOURS_OPTIONS.map(({label, value}) => (
                          <option key={value} value={value}>
                            {t(label)}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Field>

                <Field name="workHours.endTimeMinute">
                  {({
                    field,
                  }: FieldProps<string, WorkStylePreferencesFormValues>) => (
                    <FormControl>
                      <Label htmlFor={field.name} isIconVisible={false}>
                        &nbsp;
                      </Label>

                      <Select id={field.name} {...field}>
                        <option value="" disabled>
                          {t(
                            'constants.work-style-preferences.keys.end-time.minutes-label',
                            {ns: 'common'}
                          )}
                        </option>

                        {WORK_MINUTES_OPTIONS.map(({label, value}) => (
                          <option key={value} value={value}>
                            {t(label)}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Field>
              </div>
            </div>
          </div>

          <div className={styles.role}>
            <div className={styles.roleItem}>
              <Field name="workPlace">
                {({
                  field,
                }: FieldProps<string, WorkStylePreferencesFormValues>) => (
                  <FormGroup>
                    <FormControl>
                      <Label htmlFor={field.name}>
                        {t('work-style.form.workplace-environment.label')}
                      </Label>

                      <Select id={field.name} {...field}>
                        <option value="" disabled>
                          {t(
                            'work-style.form.workplace-environment.placeholder'
                          )}
                        </option>

                        {workStyleValues?.workPlace.map((value) => (
                          <option key={value} value={value}>
                            {t(
                              WORK_STYLE_TRANSLATION_MAP[
                                value as keyof typeof WORK_STYLE_TRANSLATION_MAP
                              ],
                              {ns: 'common'}
                            )}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </FormGroup>
                )}
              </Field>
            </div>
          </div>

          <div className={styles.role}>
            <div className={styles.roleItem}>
              <Field name="oneOnOneCadence">
                {({
                  field,
                }: FieldProps<string, WorkStylePreferencesFormValues>) => (
                  <FormGroup>
                    <FormControl>
                      <Label htmlFor={field.name}>
                        {t('work-style.form.preferred-cadence.label', {
                          managerName:
                            currentFullUserProfile.manager?.name ||
                            t(
                              'work-style.form.preferred-cadence.default-manager'
                            ),
                        })}
                      </Label>

                      <Select id={field.name} {...field}>
                        <option value="" disabled>
                          {t('work-style.form.preferred-cadence.placeholder')}
                        </option>

                        {workStyleValues?.oneOnOneCadence.map((value) => (
                          <option key={value} value={value}>
                            {t(
                              WORK_STYLE_TRANSLATION_MAP[
                                value as keyof typeof WORK_STYLE_TRANSLATION_MAP
                              ],
                              {ns: 'common'}
                            )}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </FormGroup>
                )}
              </Field>
            </div>
          </div>

          <div className={styles.role}>
            <div className={styles.roleItem}>
              {t('work-style.form.average-response-times')}
            </div>
          </div>

          <div className={styles.role}>
            <div className={styles.roleItem}>
              <Field name="averageResponseTimes.phone">
                {({
                  field,
                }: FieldProps<string, WorkStylePreferencesFormValues>) => (
                  <FormGroup>
                    <FormControl>
                      <Label htmlFor={field.name}>
                        {t('work-style.form.phone.label')}
                      </Label>

                      <Select id={field.name} {...field}>
                        <option value="" disabled>
                          {t('work-style.form.phone.placeholder')}
                        </option>

                        {workStyleValues?.averageResponseTimes.map((value) => (
                          <option key={value} value={value}>
                            {t(
                              WORK_STYLE_TRANSLATION_MAP[
                                value as keyof typeof WORK_STYLE_TRANSLATION_MAP
                              ],
                              {ns: 'common'}
                            )}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </FormGroup>
                )}
              </Field>
            </div>

            <div className={styles.roleItem}>
              <Field name="averageResponseTimes.textMessage">
                {({
                  field,
                }: FieldProps<string, WorkStylePreferencesFormValues>) => (
                  <FormGroup>
                    <FormControl>
                      <Label htmlFor={field.name}>
                        {t('work-style.form.text-message.label')}
                      </Label>

                      <Select id={field.name} {...field}>
                        <option value="" disabled>
                          {t('work-style.form.text-message.placeholder')}
                        </option>

                        {workStyleValues?.averageResponseTimes.map((value) => (
                          <option key={value} value={value}>
                            {t(
                              WORK_STYLE_TRANSLATION_MAP[
                                value as keyof typeof WORK_STYLE_TRANSLATION_MAP
                              ],
                              {ns: 'common'}
                            )}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </FormGroup>
                )}
              </Field>
            </div>
          </div>

          <div className={styles.role}>
            <div className={styles.roleItem}>
              <Field name="averageResponseTimes.email">
                {({
                  field,
                }: FieldProps<string, WorkStylePreferencesFormValues>) => (
                  <FormGroup>
                    <FormControl>
                      <Label htmlFor={field.name}>
                        {t('work-style.form.email.label')}
                      </Label>

                      <Select id={field.name} {...field}>
                        <option value="" disabled>
                          {t('work-style.form.email.placeholder')}
                        </option>

                        {workStyleValues?.averageResponseTimes.map((value) => (
                          <option key={value} value={value}>
                            {t(
                              WORK_STYLE_TRANSLATION_MAP[
                                value as keyof typeof WORK_STYLE_TRANSLATION_MAP
                              ],
                              {ns: 'common'}
                            )}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </FormGroup>
                )}
              </Field>
            </div>

            <div className={styles.roleItem}>
              <Field name="averageResponseTimes.chat">
                {({
                  field,
                }: FieldProps<string, WorkStylePreferencesFormValues>) => (
                  <FormGroup>
                    <FormControl>
                      <Label htmlFor={field.name}>
                        {t('work-style.form.chat.label')}
                      </Label>

                      <Select id={field.name} {...field}>
                        <option value="" disabled>
                          {t('work-style.form.chat.placeholder')}
                        </option>

                        {workStyleValues?.averageResponseTimes.map((value) => (
                          <option key={value} value={value}>
                            {t(
                              WORK_STYLE_TRANSLATION_MAP[
                                value as keyof typeof WORK_STYLE_TRANSLATION_MAP
                              ],
                              {ns: 'common'}
                            )}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </FormGroup>
                )}
              </Field>
            </div>
          </div>

          <div className={styles.role}>
            <div className={styles.roleItem}>
              {t('work-style.form.feedback-preferences')}
            </div>
          </div>

          <div className={styles.role}>
            <div className={styles.roleItem}>
              <Field name="feedbackPreferences.deliveryMethod">
                {({
                  field,
                }: FieldProps<string, WorkStylePreferencesFormValues>) => (
                  <FormGroup>
                    <FormControl>
                      <Label htmlFor={field.name}>
                        {t('work-style.form.delivery-method.label')}
                      </Label>

                      <Input
                        id={field.name}
                        {...field}
                        placeholder={t(
                          'work-style.form.delivery-method.placeholder'
                        )}
                      />
                    </FormControl>
                  </FormGroup>
                )}
              </Field>
            </div>

            <div className={styles.roleItem}>
              <Field name="feedbackPreferences.cadence">
                {({
                  field,
                }: FieldProps<string, WorkStylePreferencesFormValues>) => (
                  <FormGroup>
                    <FormControl>
                      <Label htmlFor={field.name}>
                        {t('work-style.form.cadence.label')}{' '}
                      </Label>

                      <Select id={field.name} {...field}>
                        <option value="" disabled>
                          {t('work-style.form.cadence.placeholder')}
                        </option>

                        {workStyleValues?.feedbackCadence.map((value) => (
                          <option key={value} value={value}>
                            {t(
                              WORK_STYLE_TRANSLATION_MAP[
                                value as keyof typeof WORK_STYLE_TRANSLATION_MAP
                              ],
                              {ns: 'common'}
                            )}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </FormGroup>
                )}
              </Field>
            </div>
          </div>
        </div>

        <FormFooter>
          <Button type="submit" disabled={!isValid}>
            {t('work-style.form.submit-button')}
          </Button>

          <Button onClick={onSkip} variant="secondary">
            {t('work-style.form.skip-button')}
          </Button>
        </FormFooter>
      </form>
    </>
  );
};

export default Form;
