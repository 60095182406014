import {buildPageTitle} from '../../../../services/build-page-title';
import {EventName} from '../../../../constants/analytics/event-name';
import {EventType} from '../../../../constants/analytics/event-type';
import {FunctionComponent, useEffect} from 'react';
import {Helmet} from 'react-helmet-async';
import {useTranslation} from 'react-i18next';
import ActionStepsList from './@components/action-steps-list';
import mainContainerStyles from '../../../../styles/classes/main-container.module.scss';
import RoundedCard from '../../../@components/rounded-card';
import useAnalytics from '../../../@hooks/use-analytics';

const ActionStepStatus: FunctionComponent = () => {
  const {t} = useTranslation('applicationPageTitle');

  const {trackEvent} = useAnalytics();

  useEffect(() => {
    trackEvent({
      eventName: EventName.PAGE_VIEWS.APPLY.ACTION_STEP_STATUS,
      eventType: EventType.PAGE_VIEW,
    });
  }, [trackEvent]);

  return (
    <>
      <Helmet>
        <title>
          {buildPageTitle([
            t('page-title.apply.action-step-status'),
            t('page-title.apply.index'),
          ])}
        </title>
      </Helmet>

      <main className={mainContainerStyles.container}>
        <RoundedCard>
          <ActionStepsList />
        </RoundedCard>
      </main>
    </>
  );
};

export default ActionStepStatus;
