import {AxiosResponse} from 'axios';
import {createMutation} from 'react-query-kit';
import {sendReminderEmail} from '../../../repositories/instill/mutations';
import {SendReminderEmailPayload} from '../../../repositories/instill/mutations/send-reminder-email';

export const useSendReminderEmail = createMutation<
  AxiosResponse<any, any> | undefined,
  SendReminderEmailPayload
>({
  mutationFn: async (variables) => {
    return await sendReminderEmail(variables.companyUuid, variables.workEmail);
  },
});
