import {FunctionComponent, InputHTMLAttributes} from 'react';
import {useFormControlContext} from '../form-control';
import styles from './styles.module.scss';

const Input: FunctionComponent<InputHTMLAttributes<HTMLInputElement>> = ({
  ...props
}) => {
  let classes = styles.input;
  const {hasError} = useFormControlContext();

  if (hasError) {
    classes = `${classes} ${styles.error}`;
  }

  return <input className={classes} {...props} />;
};

export default Input;
