import {buildPageTitle} from '../../../../services/build-page-title';
import {EventName} from '../../../../constants/analytics/event-name';
import {EventType} from '../../../../constants/analytics/event-type';
import {Helmet} from 'react-helmet-async';
import {useCompanyEmployees} from '../../../@hooks/queries';
import {useCallback, useEffect} from 'react';
import {useSafeCurrentCompany} from '../../../@atoms/current-company';
import {useTranslation} from 'react-i18next';
import mainContainerStyles from '../../../../styles/classes/main-container.module.scss';
import RoundedCard from '../../../@components/rounded-card';
import Spinner from '../../../@components/spinner/spinner';
import TeamList from './@components/team-list';
import useAnalytics from '../../../@hooks/use-analytics';

function Teams() {
  const {t} = useTranslation('applicationPageTitle');

  const {trackEvent} = useAnalytics();
  const currentCompany = useSafeCurrentCompany();

  const companyEmployees = useCompanyEmployees({
    variables: {
      companyUuid: currentCompany.uuid,
    },
  });

  const onRefreshCompanyEmployees = useCallback(() => {
    companyEmployees.refetch();
  }, [companyEmployees]);

  useEffect(() => {
    trackEvent({
      eventName: EventName.PAGE_VIEWS.PEOPLE.TEAMS,
      eventType: EventType.PAGE_VIEW,
    });
  }, [trackEvent]);

  return (
    <>
      <Helmet>
        <title>
          {buildPageTitle([
            t('page-title.people.teams', {ns: 'applicationPageTitle'}),
            t('page-title.people.index', {ns: 'applicationPageTitle'}),
          ])}
        </title>
      </Helmet>

      <main className={mainContainerStyles.container}>
        <RoundedCard>
          {companyEmployees.isLoading ? (
            <Spinner />
          ) : (
            <>
              {companyEmployees.data?.data && (
                <TeamList
                  onRefreshCompanyEmployees={onRefreshCompanyEmployees}
                  companyEmployees={companyEmployees.data?.data}
                />
              )}
            </>
          )}
        </RoundedCard>
      </main>
    </>
  );
}

export default Teams;
