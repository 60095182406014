import {fetchUserAssessments} from '../../../../../../repositories/instill';
import {FullUserProfile} from '../../../../../../interfaces/user-profile';
import {FunctionComponent, useCallback, useState} from 'react';
import {HEXACO_ASSESSMENT_SLUG} from '../../../../../../constants/assessments';
import {Link} from 'react-router-dom';
import {useCurrentFullUserProfile} from '../../../../../@atoms/current-full-user-profile';
import {useEffectOnce} from '../../../../../@hooks/use-effect-once';
import {UserAssessment} from '../../../../../../interfaces/user-assessment';
import {useTranslation} from 'react-i18next';
import profileStyles from '../../styles.module.scss';
import RadarChart, {
  getHexacoScoreFromProfile,
} from '../../../../../@components/radar-chart';
import styles from './styles.module.scss';
import Tag from '../../../../../@components/tag';

const LEARN_MORE_EXTERNAL_LINK_URL =
  'https://instillai.notion.site/instillai/INSTILL-HEXACO-Report-and-Descriptions-ab931297f0ab4113990315073228de87';

interface ElementProps {
  profile: FullUserProfile;
  isEmployeeTheCurrentUser: boolean;
}

const Hexaco: FunctionComponent<ElementProps> = ({
  isEmployeeTheCurrentUser,
  profile,
}) => {
  const {t} = useTranslation('application');

  const [hexacoAssessmentUrl, setHexacoAssessmentUrl] = useState<string>();
  const currentFullUserProfile = useCurrentFullUserProfile();

  const buildHexacoAssessmentUrl = useCallback((hexaco: UserAssessment) => {
    const assessmentUuid = hexaco.uuid;
    const moduleUuid = hexaco.assessmentEvent.assessment.modules[0].uuid;

    if (!assessmentUuid || !moduleUuid) return;

    const url = `/assessments/${assessmentUuid}/modules/${moduleUuid}`;

    setHexacoAssessmentUrl(url);
  }, []);

  const onFetchAssessments = useCallback(async () => {
    const response = await fetchUserAssessments(currentFullUserProfile.uuid);
    if (!response) return;

    const hexaco = response.find((assessment) => {
      return (
        assessment.assessmentEvent.assessment.slug === HEXACO_ASSESSMENT_SLUG
      );
    });
    if (!hexaco) return;

    buildHexacoAssessmentUrl(hexaco);
  }, [buildHexacoAssessmentUrl, currentFullUserProfile]);

  useEffectOnce(() => {
    onFetchAssessments();
  });

  return (
    <div className={profileStyles.box}>
      <h2 className={profileStyles.boxTitle}>
        {t('components.profile-details.components.hexaco.title')}
      </h2>

      {!profile.hexacoProfile ? (
        <>
          {isEmployeeTheCurrentUser ? (
            <>
              <div className={styles.hexacoNotCompleted}>
                {t(
                  'components.profile-details.components.hexaco.current-user-not-completed'
                )}
              </div>

              {hexacoAssessmentUrl && (
                <Link className={styles.hexacoButton} to={hexacoAssessmentUrl}>
                  {t(
                    'components.profile-details.components.hexaco.take-assessment-button'
                  )}
                </Link>
              )}
            </>
          ) : (
            <div className={styles.hexacoNotCompleted}>
              {t('components.profile-details.components.hexaco.not-completed')}
            </div>
          )}
        </>
      ) : (
        <>
          {profile.hexacoProfile.personaContent.summary && (
            <div className={styles.description}>
              {profile.hexacoProfile.personaContent.summary}
            </div>
          )}

          <ul className={styles.list}>
            {profile.hexacoProfile.personaContent.traits.map((trait, index) => (
              <li key={index} className={styles.listItem}>
                <Tag>{trait}</Tag>
              </li>
            ))}
          </ul>

          <div className={styles.chart}>
            <RadarChart
              hexacoScore={getHexacoScoreFromProfile(profile.hexacoProfile)}
            />
          </div>

          <a
            href={LEARN_MORE_EXTERNAL_LINK_URL}
            target="_blank"
            rel="noreferrer noopener"
            className={styles.link}
          >
            {t('components.profile-details.components.hexaco.learn-more-link')}
          </a>
        </>
      )}
    </div>
  );
};

export default Hexaco;
