import {sanitizeUserChecklistFromRaw} from '../../../ui/@sanitizers/checklist';
import {USER_CHECKLIST_STEP_KEYS} from '../../../ui/application/@components/setup-checklist/setup-checklist';

import InstillAPI from '../axios-instance';

const MAPPING_FOR_API = {
  [USER_CHECKLIST_STEP_KEYS.CREATED_PROFILE]: 'created_profile',
  [USER_CHECKLIST_STEP_KEYS.MAPPED_ORGANIZATION]: 'mapped_organization',
  [USER_CHECKLIST_STEP_KEYS.SET_WORK_STYLE]: 'set_work_style',
  [USER_CHECKLIST_STEP_KEYS.REVIEWED_COMPANY_VALUES]: 'reviewed_company_values',
  [USER_CHECKLIST_STEP_KEYS.SIGNED_OFF_ON_VALUES]: 'signed_off_on_values',
};

export async function updateUserChecklist(
  currentStep: USER_CHECKLIST_STEP_KEYS,
  companyUuid: string
) {
  const payload = {
    [MAPPING_FOR_API[currentStep]]: true,
  };

  const response = await InstillAPI.put(
    `/companies/${companyUuid}/user-profiles/me/onboarding-checklist`,
    payload
  );

  return sanitizeUserChecklistFromRaw(response.data);
}
