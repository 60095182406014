import {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import styles from './styles.module.scss';

interface ElementProps {
  currentProgress: number;
  total: number;
}

const ProgressBarWithLabel: FunctionComponent<ElementProps> = ({
  currentProgress,
  total,
}) => {
  const {t} = useTranslation('components');
  const progress = Math.round((currentProgress / total) * 100);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>{t('progress-bar-with-label.title')}</div>

        <div className={styles.percentage}>
          {t('progress-bar-with-label.percentage', {percentage: progress})}
        </div>
      </div>

      <div className={styles.bar}>
        <div className={styles.progress} style={{width: `${progress}%`}} />
      </div>
    </div>
  );
};

export default ProgressBarWithLabel;
