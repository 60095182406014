import {CompanyUpsert} from '../../../../../../interfaces/admin/company';
import {DEFAULT_TOASTIFY_CONFIGURATION} from '../../../../../../constants/toastify-configuration';
import {FunctionComponent, useCallback, useState} from 'react';
import {sanitizeCompanyProfileForRaw} from '../../../../../@sanitizers/company';
import {ToastOptions, toast} from 'react-toastify';
import {
  useCurrentCompanyState,
  useSafeCurrentCompany,
} from '../../../../../@atoms/current-company';
import {useTranslation} from 'react-i18next';
import {useUpdateCompany} from '../../../../../@hooks/mutations';
import Button from '../../../../../@components/kit/form/button';
import CompanyProfile from '../../../../../@components/company-profile';
import CompanyUpsertForm from '../../../../@components/company-upsert-form';
import getFullUrl from '../../../../../../utils/get-full-url/get-full-url';
import styles from './styles.module.scss';

const Profile: FunctionComponent = () => {
  const {t} = useTranslation('settings');

  const [, setCurrentCompany] = useCurrentCompanyState();
  const company = useSafeCurrentCompany();

  const updateCompany = useUpdateCompany();

  const [isInEditMode, setIsInEditMode] = useState(false);

  const onFormSubmit = useCallback(
    async (values: CompanyUpsert) => {
      let updatedValues = values;
      updatedValues.websiteUrl = getFullUrl(values.websiteUrl);

      if (values.companyLogo === company.companyLogo) {
        updatedValues.companyLogo = undefined;
      }

      const payload = sanitizeCompanyProfileForRaw(updatedValues);

      const response = await updateCompany.mutateAsync({
        payload,
        companyUuid: company.uuid,
      });
      if (!response) return;

      const feedbackMessage = t(
        'manage-company-profile.components.profile.feedback-messages.update-company'
      );

      toast.success(feedbackMessage, {
        ...(DEFAULT_TOASTIFY_CONFIGURATION as ToastOptions),
      });

      setCurrentCompany(response);
      setIsInEditMode(false);
    },
    [company, updateCompany, setCurrentCompany, t]
  );

  const onCancelButtonClicked = useCallback(() => {
    setIsInEditMode(false);
  }, []);

  return (
    <>
      <header className={styles.header}>
        <Button disabled={isInEditMode} onClick={() => setIsInEditMode(true)}>
          {t('manage-company-profile.components.edit-button')}
        </Button>
      </header>

      {isInEditMode ? (
        <CompanyUpsertForm
          company={company}
          isConfirmationStepNecessary={false}
          onClose={onCancelButtonClicked}
          onSubmit={onFormSubmit}
        />
      ) : (
        <div className={styles.viewContainer}>
          <CompanyProfile company={company} />
        </div>
      )}
    </>
  );
};

export default Profile;
