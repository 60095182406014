import {FunctionComponent} from 'react';
import {useSafeCurrentCompany} from '../../../../../@atoms/current-company';
import {useTranslation} from 'react-i18next';
import {useUserReports} from '../../../../../@hooks/queries';
import ListView from './@components/list-view';
import NoReportsBox from './@components/no-reports-box';
import RoundedCard from '../../../../../@components/rounded-card';
import Spinner from '../../../../../@components/spinner';

const Reports: FunctionComponent = () => {
  const {t} = useTranslation('measure');

  const currentCompany = useSafeCurrentCompany();

  const userReports = useUserReports({
    variables: {
      companyUuid: currentCompany.uuid,
    },
  });

  return (
    <>
      <RoundedCard.Header>
        <RoundedCard.Title>
          {t('your-reports.components.reports.title')}
        </RoundedCard.Title>
      </RoundedCard.Header>

      <RoundedCard.Content>
        {userReports.isLoading ? (
          <Spinner />
        ) : (
          <>
            {userReports.data?.length ? (
              <ListView reports={userReports.data} />
            ) : (
              <NoReportsBox />
            )}
          </>
        )}
      </RoundedCard.Content>
    </>
  );
};

export default Reports;
