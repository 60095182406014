import {UpdateCompanyAssessmentSettingPayload} from '../../../interfaces/company-assessments';
import {sanitizeCompanyAssessmentPayloadToRaw} from '../../../ui/@sanitizers/company-assessments';
import InstillAPI from '../axios-instance';
import isResponseSuccessful from '../../../utils/is-response-successful';

export interface UpdateCompanyAssessmentSettingsPayload {
  companyUuid: string;
  assessmentUuid: string;
  payload: UpdateCompanyAssessmentSettingPayload;
}

export async function updateCompanyAssessmentSettings(
  companyUuid: string,
  assessmentUuid: string,
  payload: UpdateCompanyAssessmentSettingPayload
) {
  const sanitizedPayload = sanitizeCompanyAssessmentPayloadToRaw(payload);

  const response = await InstillAPI.post(
    `/companies/${companyUuid}/assessments/${assessmentUuid}/settings`,
    sanitizedPayload
  );

  if (!isResponseSuccessful(response)) {
    throw Error('No data received');
  }

  return response.data;
}
