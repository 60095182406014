import {FunctionComponent, PropsWithChildren} from 'react';
import styles from './styles.module.scss';

interface ElementProps {
  variant?: 'spaceless';
}

const Content: FunctionComponent<PropsWithChildren<ElementProps>> = ({
  children,
  variant,
}) => {
  const variantClass = variant ? styles[variant] : '';

  return <div className={`${styles.content} ${variantClass}`}>{children}</div>;
};

export default Content;
