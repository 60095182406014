import {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import linkSlackStyles from '../../../../../../styles.module.scss';
import {useSafeCurrentCompany} from '../../../../../../../../@atoms/current-company';

const CompanyNotLinked: FunctionComponent = () => {
  const {t} = useTranslation('linkSlack', {
    keyPrefix:
      'components.user.components.instruction.components.company-not-linked',
  });

  const currentCompany = useSafeCurrentCompany();

  return (
    <div className={linkSlackStyles.box}>
      <div className={linkSlackStyles.boxTitle}>
        {t('title', {companyName: currentCompany.name})}
      </div>

      <div className={linkSlackStyles.boxMessage}>{t('message')}</div>
    </div>
  );
};

export default CompanyNotLinked;
