import {Field, FieldProps, useFormikContext} from 'formik';
import {FunctionComponent, useCallback} from 'react';
import {PROFILE_DETAILS_FORM_VALUES} from '../../profile-details';
import {PRONOUNS} from '../../../../../../constants/pronouns';
import {FullUserProfile} from '../../../../../../interfaces/user-profile';
import {useTranslation} from 'react-i18next';
import FormControl from '../../../../../@components/kit/form/form-control';
import Input from '../../../../../@components/kit/form/input';
import LocationAutofillSelect from '../../../../../@components/kit/form/hybrid-select/location-autofill-select/location-autofill-select';
import profileStyles from '../../styles.module.scss';
import SearchableSelect from '../../../../../@components/kit/form/hybrid-select/searchable-select/searchable-select';
import Tag from '../../../../../@components/tag/tag';
import RichTextEditor from '../../../../../@components/kit/form/rich-text-editor';
import {isRichTextFilled} from '../../../../../../utils/html-helpers';
import RichTextContainer from '../../../../../@components/rich-text-container/rich-text-container';

interface ElementProps {
  isInEditMode: boolean;
  profile: FullUserProfile;
}

const AdvanceProfile: FunctionComponent<ElementProps> = ({
  isInEditMode,
  profile,
}) => {
  const {t} = useTranslation('application');

  const {errors, touched} = useFormikContext<PROFILE_DETAILS_FORM_VALUES>();

  const pronounOptions = [
    {
      label: t('constants.pronouns.options.he-him', {ns: 'common'}),
      value: PRONOUNS.HE_HIM,
    },
    {
      label: t('constants.pronouns.options.she-her', {ns: 'common'}),
      value: PRONOUNS.SHE_HER,
    },
    {
      label: t('constants.pronouns.options.they-them', {ns: 'common'}),
      value: PRONOUNS.THEY_THEM,
    },
  ];

  const selectedPronoun = pronounOptions.find(
    (option) => option.value === profile.pronouns
  );

  const onEditorStateChange = useCallback(
    (htmlValue: string, form: FieldProps['form'], field: string) => {
      form.setFieldValue(field, htmlValue);
    },
    []
  );

  return (
    <div
      className={`${profileStyles.box} ${
        isInEditMode ? profileStyles.editBox : ''
      }`}
    >
      <h2 className={profileStyles.boxTitle}>
        {t('components.profile-details.components.advanced-profile.title')}
      </h2>

      <ul>
        <li className={profileStyles.boxItem}>
          <div className={profileStyles.boxItemKey}>
            {t('components.profile-details.keys.short-bio')}
          </div>

          <div className={profileStyles.boxItemValue}>
            {isInEditMode ? (
              <Field name="shortBio">
                {({form, field}: FieldProps<string>) => (
                  <FormControl
                    error={
                      Boolean(errors.shortBio && touched.shortBio)
                        ? errors.shortBio
                        : undefined
                    }
                  >
                    <RichTextEditor
                      placeholder={t(
                        'constants.user-profile.placeholder.short-bio',
                        {ns: 'common'}
                      )}
                      initialValue={field.value}
                      onChange={(value) =>
                        onEditorStateChange(value, form, field.name)
                      }
                    />
                  </FormControl>
                )}
              </Field>
            ) : (
              <>
                {isRichTextFilled(profile.shortBio) ? (
                  <RichTextContainer value={profile.shortBio as string} />
                ) : (
                  <div className={profileStyles.emptyValue}>–</div>
                )}
              </>
            )}
          </div>
        </li>

        <li className={profileStyles.boxItem}>
          <div className={profileStyles.boxItemKey}>
            {t('components.profile-details.keys.pronouns')}
          </div>

          <div className={profileStyles.boxItemValue}>
            {isInEditMode ? (
              <Field name="pronouns">
                {(fieldProps: FieldProps<string>) => (
                  <FormControl
                    error={
                      Boolean(errors.pronouns && touched.pronouns)
                        ? errors.pronouns
                        : undefined
                    }
                  >
                    <SearchableSelect
                      {...fieldProps}
                      options={pronounOptions}
                      placeholder={t(
                        'constants.user-profile.placeholder.pronouns',
                        {ns: 'common'}
                      )}
                    />
                  </FormControl>
                )}
              </Field>
            ) : (
              <>
                {profile.pronouns ? (
                  <Tag>{selectedPronoun?.label}</Tag>
                ) : (
                  <div className={profileStyles.emptyValue}>–</div>
                )}
              </>
            )}
          </div>
        </li>

        <li className={profileStyles.boxItem}>
          <div className={profileStyles.boxItemKey}>
            {t('components.profile-details.keys.location')}
          </div>

          <div className={profileStyles.boxItemValue}>
            {isInEditMode ? (
              <Field name="location">
                {(fieldProps: FieldProps<string>) => (
                  <FormControl
                    error={
                      Boolean(errors.location && touched.location)
                        ? errors.location
                        : undefined
                    }
                  >
                    <LocationAutofillSelect
                      {...fieldProps}
                      placeholder={t(
                        'constants.user-profile.placeholder.location',
                        {ns: 'common'}
                      )}
                    />
                  </FormControl>
                )}
              </Field>
            ) : (
              <>
                {profile.location ? (
                  <Tag>{profile.location}</Tag>
                ) : (
                  <div className={profileStyles.emptyValue}>–</div>
                )}
              </>
            )}
          </div>
        </li>

        <li className={profileStyles.boxItem}>
          <div className={profileStyles.boxItemKey}>
            {t('components.profile-details.keys.linkedin-url')}
          </div>

          <div className={profileStyles.boxItemValue}>
            {isInEditMode ? (
              <Field name="linkedinUrl">
                {({field}: FieldProps<string>) => (
                  <FormControl
                    error={
                      Boolean(errors.linkedinUrl && touched.linkedinUrl)
                        ? errors.linkedinUrl
                        : undefined
                    }
                  >
                    <Input
                      {...field}
                      placeholder={t(
                        'constants.user-profile.placeholder.linkedin-url',
                        {ns: 'common'}
                      )}
                    />
                  </FormControl>
                )}
              </Field>
            ) : (
              <>
                {profile.linkedinUrl ? (
                  <Tag>{profile.linkedinUrl}</Tag>
                ) : (
                  <div className={profileStyles.emptyValue}>–</div>
                )}
              </>
            )}
          </div>
        </li>

        <li className={profileStyles.boxItem}>
          <div className={profileStyles.boxItemKey}>
            {t('components.profile-details.keys.fun-fact')}
          </div>

          <div className={profileStyles.boxItemValue}>
            {isInEditMode ? (
              <Field name="funFact">
                {({form, field}: FieldProps<string>) => (
                  <FormControl
                    error={
                      Boolean(errors.funFact && touched.funFact)
                        ? errors.funFact
                        : undefined
                    }
                  >
                    <RichTextEditor
                      placeholder={t(
                        'constants.user-profile.placeholder.fun-fact',
                        {ns: 'common'}
                      )}
                      initialValue={field.value}
                      onChange={(state) =>
                        onEditorStateChange(state, form, field.name)
                      }
                    />
                  </FormControl>
                )}
              </Field>
            ) : (
              <>
                {isRichTextFilled(profile.funFact) ? (
                  <RichTextContainer value={profile.funFact as string} />
                ) : (
                  <div className={profileStyles.emptyValue}>–</div>
                )}
              </>
            )}
          </div>
        </li>
      </ul>
    </div>
  );
};

export default AdvanceProfile;
