import {createMutation} from 'react-query-kit';
import {InviteSense} from '../../../interfaces/meeting';
import {inviteSense} from '../../../repositories/instill';
import {InviteSensePayload} from '../../../repositories/instill/mutations/invite-sense';

export const useInviteSense = createMutation<
  InviteSense | undefined,
  InviteSensePayload
>({
  mutationFn: async (variables) => {
    return await inviteSense({
      companyUuid: variables.companyUuid,
      payload: variables.payload,
    });
  },
});
