import {createMutation} from 'react-query-kit';
import {KudoVisibilityPayload} from '../../../repositories/instill/mutations/update-kudo-visibility';
import {KudoVisibilityRaw} from '../../../interfaces/kudo';
import {updateKudoVisibility} from '../../../repositories/instill/mutations';

export const useUpdateKudoVisibility = createMutation<
  KudoVisibilityRaw | undefined,
  KudoVisibilityPayload
>({
  mutationFn: async (variables) => {
    return updateKudoVisibility(
      variables.companyUuid,
      variables.kudoUuid,
      variables.payload
    );
  },
});
