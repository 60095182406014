import {Company} from '../../../../../../../../../interfaces/company';
import {
  CompanyUpsert,
  Company as CompanySuperAdmin,
} from '../../../../../../../../../interfaces/admin/company';
import {FunctionComponent} from 'react';
import {NavLink} from 'react-router-dom';
import {PRICING_PLAN_TRANSLATION_MAP} from '../../../../../../../../../constants/pricing-plan';
import {useTranslation} from 'react-i18next';
import AvatarPlaceholder from '../../../../../../../../@components/avatar-placeholder/avatar-placeholder';
import styles from './styles.module.scss';
import Table from '../../../../../../../../@components/table';
import tableStyles from '../../../../../../../../../styles/classes/table.module.scss';

interface ElementProps {
  company: CompanySuperAdmin;
  onNameClicked: (company: CompanySuperAdmin) => void;
  onRefreshCompanies: () => void;
  onUpdateCompanyProfile: (
    values: CompanyUpsert,
    selectedCompany: Company
  ) => void;
}

const TableRow: FunctionComponent<ElementProps> = ({
  company,
  onNameClicked,
}) => {
  const {t} = useTranslation('adminPortal');

  const pricingPlanTranslationKey =
    PRICING_PLAN_TRANSLATION_MAP[
      company.pricingPlan as keyof typeof PRICING_PLAN_TRANSLATION_MAP
    ];

  return (
    <Table.Row className={tableStyles.tableRow}>
      <Table.Data variant="large" className={tableStyles.tableData}>
        <div className={tableStyles.mobileHeaderTitle}>
          {t('components.company-list.components.table.header.name')}
        </div>

        <div className={styles.companyContainer}>
          {company.companyLogo ? (
            <img
              src={company.companyLogo}
              className={styles.profilePicture}
              alt=""
            />
          ) : (
            <div className={styles.avatarContainer}>
              <AvatarPlaceholder
                size="small"
                variant="dark-blue"
                name={company.name}
              />
            </div>
          )}

          {company.name ? (
            <button
              className={styles.companyLink}
              onClick={() => onNameClicked(company)}
            >
              {company.name}
            </button>
          ) : (
            <div>{company.name}</div>
          )}
        </div>
      </Table.Data>

      <Table.Data variant="medium" className={tableStyles.tableData}>
        <div className={tableStyles.mobileHeaderTitle}>
          {t('components.company-list.components.table.header.active-users')}
        </div>

        {company.joinedUsersCount ? (
          <div>{company.joinedUsersCount}</div>
        ) : (
          <div className={styles.emptyValue}>—</div>
        )}
      </Table.Data>

      <Table.Data variant="medium" className={tableStyles.tableData}>
        <div className={tableStyles.mobileHeaderTitle}>
          {t('components.company-list.components.table.header.pending-invites')}
        </div>

        {company.invitedUsersCount ? (
          <div>{company.invitedUsersCount}</div>
        ) : (
          <div className={styles.emptyValue}>—</div>
        )}
      </Table.Data>

      <Table.Data variant="medium" className={tableStyles.tableData}>
        <div className={tableStyles.mobileHeaderTitle}>
          {t('components.company-list.components.table.header.pricing-plan')}
        </div>

        {company.pricingPlan ? (
          <div>{t(pricingPlanTranslationKey, {ns: 'common'})}</div>
        ) : (
          <div className={styles.emptyValue}>—</div>
        )}
      </Table.Data>

      <Table.Data variant="medium" className={tableStyles.tableData}>
        <NavLink to="/select-company" className={styles.button}>
          {t(
            'components.company-list.components.table.action.dashboard-button'
          )}
        </NavLink>
      </Table.Data>
    </Table.Row>
  );
};

export default TableRow;
