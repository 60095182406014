import {useTranslation} from 'react-i18next';
import styles from './styles.module.scss';
import logoSource from '../../../../../../assets/images/logo-white.png';

function Branding() {
  const {t} = useTranslation('authFeedback');

  return (
    <>
      <div>
        <img src={logoSource} className={styles.logo} alt="" />
        <h1>{t('components.branding.header')}</h1>
      </div>
    </>
  );
}

export default Branding;
