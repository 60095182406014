import {createQuery} from 'react-query-kit';
import {
  FetchMeetingsPayload,
  fetchMeetings,
} from '../../../repositories/instill/queries/fetch-meetings';
import {MeetingListItem} from '../../../interfaces/meeting';
import {Meta} from '../../../interfaces/meta';
import getOffsetValue from '../../../utils/get-offset-value/get-offset-value';

interface FetchMeetingsResponsePayload {
  data: MeetingListItem[];
  meta: Meta;
}

export const useMeetings = createQuery<
  FetchMeetingsResponsePayload | undefined,
  FetchMeetingsPayload
>({
  primaryKey: 'meetings',
  queryFn: async ({queryKey: [_, variables]}) => {
    let payload = {
      offset: getOffsetValue({
        offset: variables.offset,
        currentPage: variables.currentPage ?? 0,
        itemsPerPage: variables.itemsPerPage ?? 0,
      }),
      limit: variables.limit,
      companyUuid: variables.companyUuid,
    } as FetchMeetingsPayload;

    if (variables.sortBy) {
      payload['sortBy'] = variables.sortBy;
    }

    if (variables.orderBy) {
      payload['orderBy'] = variables.orderBy;
    }

    if (variables.query) {
      payload['query'] = variables.query;
    }

    return await fetchMeetings(payload);
  },
});
