import {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import styles from './styles.module.scss';

interface ElementProps {
  firstTakeaway?: string;
  secondTakeaway?: string;
  thirdTakeaway?: string;
}

const Takeaways: FunctionComponent<ElementProps> = ({
  firstTakeaway,
  secondTakeaway,
  thirdTakeaway,
}) => {
  const {t} = useTranslation('assessmentSummary');

  return (
    <ul className={styles.list}>
      <li className={styles.item}>
        <span className={styles.itemTitle}>
          {t(
            'components.module-summary.components.generic-summary.components.takeaways.first-heading'
          )}
        </span>

        {firstTakeaway && (
          <div className={styles.itemDescription}>{firstTakeaway}</div>
        )}
      </li>

      <li className={styles.item}>
        <span className={styles.itemTitle}>
          {t(
            'components.module-summary.components.generic-summary.components.takeaways.second-heading'
          )}
        </span>

        {secondTakeaway && (
          <div className={styles.itemDescription}>{secondTakeaway}</div>
        )}
      </li>

      <li className={styles.item}>
        <span className={styles.itemTitle}>
          {t(
            'components.module-summary.components.generic-summary.components.takeaways.third-heading'
          )}
        </span>

        {thirdTakeaway && (
          <div className={styles.itemDescription}>{thirdTakeaway}</div>
        )}
      </li>
    </ul>
  );
};

export default Takeaways;
