import {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import styles from './styles.module.scss';

const NoKudosLeft: FunctionComponent = () => {
  const {t} = useTranslation('application');

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {t(
          'components.kudos-list.components.give-kudos-dialog.components.no-kudos-left.title'
        )}
      </div>

      <div className={styles.message}>
        {t(
          'components.kudos-list.components.give-kudos-dialog.components.no-kudos-left.message'
        )}
      </div>
    </div>
  );
};

export default NoKudosLeft;
