import InstillAPI from '../axios-instance';
import isResponseSuccessful from '../../../utils/is-response-successful';
import {sanitizeWorkStylePreferencesValuesFromRaw} from '../../../ui/@sanitizers/work-style-preferences';

export async function fetchWorkStyleValues() {
  try {
    const response = await InstillAPI.get(
      '/configurations/workstyle-preferences-values'
    );

    if (!response.data || !isResponseSuccessful(response)) {
      throw Error('No data received');
    }

    return sanitizeWorkStylePreferencesValuesFromRaw(response.data);
  } catch (error) {
    console.error(error);
  }
}
