import {
  FunctionComponent,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';
import {LiveNotification} from '../../../interfaces/notification';
import {sanitizeSocketNotificationFromRaw} from '../../@sanitizers/notification';
import {useAuth0} from '@auth0/auth0-react';
import {useEffectOnce} from '../../@hooks/use-effect-once';
import {useSafeCurrentCompany} from '../../@atoms/current-company';

interface Context {
  subscribe: (
    topic: string,
    callback: (payload: LiveNotification) => void
  ) => void;
}

const WebSocketContext = createContext<Context | null>(null);
WebSocketContext.displayName = 'WebSocket';

const WEB_SOCKET_URL = window.env.REACT_APP_WEB_SOCKET_DOMAIN;

export const useWebSocket = () => {
  const context = useContext(WebSocketContext);

  if (!context) {
    throw new Error('WebSocket must be used within WebSocketProvider');
  }

  return context;
};

const WebSocketContextProvider: FunctionComponent<PropsWithChildren<{}>> = ({
  children,
}) => {
  const {getAccessTokenSilently} = useAuth0();
  const currentCompany = useSafeCurrentCompany();

  const [socket, setSocket] = useState<WebSocket>();

  const initSockets = useCallback(async () => {
    const socket = new WebSocket(WEB_SOCKET_URL);
    setSocket(socket);

    const accesToken = await getAccessTokenSilently();

    const payload = JSON.stringify({
      event: 'authentication',
      payload: {
        company_uuid: currentCompany.uuid,
        access_token: accesToken,
      },
    });

    socket.addEventListener('open', () => {
      socket.send(payload);
    });
  }, [getAccessTokenSilently, currentCompany]);

  const subscribe = useCallback(
    (topic: string, callback: (payload: LiveNotification) => void) => {
      const onMessage = (event: MessageEvent) => {
        const eventData = JSON.parse(event.data);
        const sanitizedPayload = sanitizeSocketNotificationFromRaw(eventData);

        if (topic === eventData.event) {
          callback(sanitizedPayload);
        }
      };

      socket?.addEventListener('message', onMessage);

      return () => {
        socket?.removeEventListener('message', onMessage);
      };
    },
    [socket]
  );

  useEffectOnce(() => {
    if (!WEB_SOCKET_URL) return;

    initSockets();

    return () => socket?.close();
  });

  const value = {subscribe};

  return (
    <WebSocketContext.Provider value={value}>
      {children}
    </WebSocketContext.Provider>
  );
};

export default WebSocketContextProvider;
