import InstillAPI from '../axios-instance';

export async function sendVerificationEmail(idToken: string) {
  const payload = {
    id_token: idToken,
    auth0_client_id: window.env.REACT_APP_AUTH0_CLIENT_ID,
  };

  await InstillAPI.post(`/auth-users/send-verification-email`, payload);
}
