import {fetchPresignedUploadURLResourceType} from '../../../../repositories/instill/queries/fetch-presigned-url-config';
import {Formik, Form, FieldProps} from 'formik';
import {FullUserProfile} from '../../../../interfaces/user-profile';
import {FunctionComponent, useCallback, useMemo, useState} from 'react';
import {getUserProfileForCompany} from '../../../../services/user-profiles';
import {KUDOS_SOURCES} from '../kudos-list';
import {LINKEDIN_PERSONAL_REGEX} from '../../../../constants/regex';
import {
  PHONE_NUMBER_VALIDATION_COUNTRY_CODE,
  PHONE_NUMBER_VALIDATION_STRICT,
} from '../../../../constants/validation-schema';
import {sanitizeUserProfileUpdateToRaw} from '../../../@sanitizers/user-profile';
import {sanitizeWorkStylePreferencesToRaw} from '../../../@sanitizers/work-style-preferences';
import {StringParam, useQueryParam} from 'use-query-params';
import {useCurrentAuthUserState} from '../../../@atoms/current-auth-user';
import {
  useFetchPresignedUploadUrl,
  useUpdateUserProfileAndWorkStylePreferences,
} from '../../../@hooks/mutations';
import {useNavigate} from 'react-router';
import {useSafeCurrentCompany} from '../../../@atoms/current-company';
import {useTranslation} from 'react-i18next';
import {useUploadToPresignedUrl} from '../../../@hooks/mutations';
import {
  WorkHours,
  WorkStylePreferencesFormValues,
} from '../../../../interfaces/work-style-preferences';
import * as Yup from 'yup';
import AdvancedProfile from './@components/advanced-profile';
import BasicProfile from './@components/basic-profile';
import Header from './@components/header';
import Hexaco from './@components/hexaco';
import KudosContainer from './@components/kudos-container';
import OrganizationStructure from './@components/organization-structure';
import styles from './styles.module.scss';
import TeamProfile from './@components/team-profile';
import WorkStylePreferences from './@components/work-style-preferences';

export enum SOURCES {
  MY_PROFILE = 'MY_PROFILE',
  EMPLOYEES = 'EMPLOYEES',
}

interface ElementProps {
  profile: FullUserProfile;
  source?: SOURCES;
}

export interface PROFILE_DETAILS_FORM_VALUES {
  funFact?: string;
  jobTitle?: string;
  linkedinUrl?: string;
  location?: string;
  name: string;
  personalEmail?: string;
  phoneNumber?: string;
  picture?: string;
  preferredName?: string;
  pronouns?: string;
  shortBio?: string;
  timeZone?: string;
  workEmail?: string;
  workStylePreferences: WorkStylePreferencesFormValues;
}

const ProfileDetails: FunctionComponent<ElementProps> = ({source, profile}) => {
  const {t} = useTranslation('application');

  const [editModeInQueryParams, setEditModeInQueryParams] = useQueryParam(
    'editMode',
    StringParam
  );

  const validationSchema = Yup.object()
    .shape(
      {
        name: Yup.string().required(t('form.error.required', {ns: 'common'})),
        personalEmail: Yup.string()
          .email(t('form.error.bad-email-format', {ns: 'common'}))
          .nullable(),
        workEmail: Yup.string()
          .email(t('form.error.bad-email-format', {ns: 'common'}))
          .required(t('form.error.required', {ns: 'common'})),
        linkedinUrl: Yup.string()
          .url(t('form.error.bad-url-format', {ns: 'common'}))
          .nullable()
          .matches(
            LINKEDIN_PERSONAL_REGEX,
            t('form.error.bad-personal-linkedin-url-format', {ns: 'common'})
          ),
        phoneNumber: Yup.string().when('phoneNumber', {
          is: (value: string) => value?.length > 0,
          then: Yup.string()
            .phone(
              PHONE_NUMBER_VALIDATION_COUNTRY_CODE,
              PHONE_NUMBER_VALIDATION_STRICT,
              t('form.error.bad-phone-number-format', {ns: 'common'})
            )
            .required(),
          otherwise: Yup.string(),
        }),
        jobTitle: Yup.string()
          .required(t('form.error.required', {ns: 'common'}))
          .max(255),
        timeZone: Yup.string().required(
          t('form.error.required', {ns: 'common'})
        ),
        workStylePreferences: Yup.object().shape({
          workHours: Yup.object().shape({
            endTimeHour: Yup.string().when('endTimeMinute', {
              is: (value: string) => !!value,
              then: (schema) =>
                schema.required(t('form.error.required', {ns: 'common'})),
              otherwise: (schema) => schema.nullable(),
            }),
            startTimeHour: Yup.string().when('startTimeMinute', {
              is: (value: string) => !!value,
              then: (schema) =>
                schema.required(t('form.error.required', {ns: 'common'})),
              otherwise: (schema) => schema.nullable(),
            }),
          }),
        }),
      },
      [['phoneNumber', 'phoneNumber']]
    )
    .defined();

  const getWorkHoursTime = useCallback((workHours?: WorkHours | null) => {
    const startTime = workHours?.startTime?.split(':');
    const endTime = workHours?.endTime?.split(':');

    const startTimeHour = startTime ? startTime[0].trim() : '';
    const startTimeMinute = startTime ? startTime[1].trim() : '';

    const endTimeHour = endTime ? endTime[0].trim() : '';
    const endTimeMinute = endTime ? endTime[1].trim() : '';

    return {
      startTimeHour,
      startTimeMinute,
      endTimeHour,
      endTimeMinute,
    };
  }, []);

  const initialValues: PROFILE_DETAILS_FORM_VALUES = {
    jobTitle: profile.jobTitle ?? '',
    name: profile.name,
    personalEmail: profile.personalEmail ?? '',
    phoneNumber: profile.phoneNumber ?? '',
    preferredName: profile.preferredName ?? '',
    timeZone: profile.timeZone ?? '',
    workEmail: profile.workEmail,
    shortBio: profile.shortBio ?? '',
    pronouns: profile.pronouns ?? '',
    location: profile.location ?? '',
    linkedinUrl: profile.linkedinUrl ?? '',
    funFact: profile.funFact ?? '',
    picture: profile.picture ?? '',
    workStylePreferences: {
      workPlace: profile.workStylePreferences?.workPlace ?? '',
      oneOnOneCadence: profile.workStylePreferences?.oneOnOneCadence ?? '',
      averageResponseTimes: {
        phone: profile.workStylePreferences?.averageResponseTimes?.phone ?? '',
        textMessage:
          profile.workStylePreferences?.averageResponseTimes?.textMessage ?? '',
        email: profile.workStylePreferences?.averageResponseTimes?.email ?? '',
        chat: profile.workStylePreferences?.averageResponseTimes?.chat ?? '',
      },
      feedbackPreferences: {
        cadence:
          profile.workStylePreferences?.feedbackPreferences?.cadence ?? '',
        deliveryMethod:
          profile.workStylePreferences?.feedbackPreferences?.deliveryMethod ??
          '',
      },
      workHours: getWorkHoursTime(profile.workStylePreferences?.workHours),
    },
  };

  const [currentAuthUser] = useCurrentAuthUserState();
  const currentCompany = useSafeCurrentCompany();

  const userProfile = getUserProfileForCompany(
    currentAuthUser!,
    currentCompany
  );

  const navigate = useNavigate();
  const fetchPresignedUploadURL = useFetchPresignedUploadUrl();
  const uploadToPresignedURL = useUploadToPresignedUrl();

  const updateUserProfileAndWorkStylePreferences =
    useUpdateUserProfileAndWorkStylePreferences();

  const isSourceEqualsToMyProfile = source === SOURCES.MY_PROFILE;

  const [isInEditMode, setIsInEditMode] = useState(() => {
    if (currentCompany.companySettings.isHRISProviderUKG) {
      setEditModeInQueryParams(undefined);
      return false;
    }

    return (
      (Boolean(editModeInQueryParams) && isSourceEqualsToMyProfile) ?? false
    );
  });

  const isEmployeeTheCurrentUser = useMemo(() => {
    return profile.uuid === userProfile.uuid;
  }, [profile, userProfile]);

  const onEditButtonClicked = useCallback(() => {
    if (isEmployeeTheCurrentUser) {
      navigate('/application/my-profile?editMode=on');
    }

    setIsInEditMode(true);
  }, [isEmployeeTheCurrentUser, navigate]);

  const onCancelButtonClicked = useCallback(
    (callback: () => void) => {
      setEditModeInQueryParams(null);
      setIsInEditMode(false);

      if (callback) callback();
    },
    [setEditModeInQueryParams]
  );

  const onFormSubmit = useCallback(
    async (values: PROFILE_DETAILS_FORM_VALUES) => {
      const workStylePreferencesValues =
        values.workStylePreferences as WorkStylePreferencesFormValues;

      let updatedValues = values;
      if (values.picture === profile.picture) {
        updatedValues.picture = undefined;
      }

      const response =
        await updateUserProfileAndWorkStylePreferences.mutateAsync({
          userProfile: sanitizeUserProfileUpdateToRaw(updatedValues),
          workStylePreferences: sanitizeWorkStylePreferencesToRaw(
            workStylePreferencesValues
          ),
          companyUuid: currentCompany.uuid,
        });

      if (!response) return;

      setIsInEditMode(false);
      setEditModeInQueryParams(null);

      window.location.reload();
    },
    [
      setIsInEditMode,
      currentCompany,
      updateUserProfileAndWorkStylePreferences,
      setEditModeInQueryParams,
      profile,
    ]
  );

  const onProfilePictureChange = useCallback(
    async (
      picture: File,
      form: FieldProps['form'],
      field: FieldProps['field']
    ) => {
      if (!picture) return;

      const config = await fetchPresignedUploadURL.mutateAsync({
        ressource: fetchPresignedUploadURLResourceType.USER_PROFILE,
        mime: picture.type,
        extension: picture.name.split('.').pop() as string,
        companyUuid: currentCompany.uuid,
      });
      if (!config) return;

      const bucketURL = await uploadToPresignedURL.mutateAsync({
        config,
        file: picture,
      });
      if (!bucketURL) return;

      form.setFieldValue(field.name, bucketURL);
    },
    [fetchPresignedUploadURL, uploadToPresignedURL, currentCompany]
  );

  return (
    <div className={styles.container}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={onFormSubmit}
      >
        {({resetForm, handleSubmit, isValid}) => (
          <>
            <Form>
              <Header
                handleSubmit={handleSubmit}
                isInEditMode={isInEditMode}
                isSaveButtonDisabled={!isValid}
                isSourceEqualsToMyProfile={isSourceEqualsToMyProfile}
                onCancelButtonClicked={onCancelButtonClicked}
                onEditButtonClicked={onEditButtonClicked}
                onProfilePictureChange={onProfilePictureChange}
                profile={profile}
                resetForm={resetForm}
              />

              <div className={styles.content}>
                <div className={styles.column}>
                  <BasicProfile isInEditMode={isInEditMode} profile={profile} />

                  <AdvancedProfile
                    isInEditMode={isInEditMode}
                    profile={profile}
                  />

                  <WorkStylePreferences
                    isInEditMode={isInEditMode}
                    profile={profile}
                  />
                </div>

                <div className={styles.column}>
                  {profile.team && <TeamProfile team={profile.team} />}

                  <Hexaco
                    isEmployeeTheCurrentUser={isEmployeeTheCurrentUser}
                    profile={profile}
                  />

                  <KudosContainer
                    source={KUDOS_SOURCES.MY_PROFILE}
                    profile={profile}
                  />
                </div>
              </div>
            </Form>

            <div className={styles.organizationStructureContainer}>
              <div className={styles.column}>
                <OrganizationStructure profile={profile} />
              </div>
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};

export default ProfileDetails;
