import {EventName} from '../../../../../../constants/analytics/event-name';
import {EventType} from '../../../../../../constants/analytics/event-type';
import {FunctionComponent, useState} from 'react';
import {UserActionStep} from '../../../../../../interfaces/action-step';
import {useTranslation} from 'react-i18next';
import ActionStepDialog from '../action-step-dialog';
import RoundedCard from '../../../../../@components/rounded-card';
import styles from './styles.module.scss';
import Table from '../../../../../@components/table';
import TableBody from '../table-body';
import TableHead from '../table-head';
import useAnalytics from '../../../../../@hooks/use-analytics';

interface ElementProps {
  actionSteps?: UserActionStep[];
  actionStepUuidInQueryParams?: string | null;
  onRefreshActionSteps: () => void;
}

const ActionStepsList: FunctionComponent<ElementProps> = ({
  actionSteps,
  actionStepUuidInQueryParams,
  onRefreshActionSteps,
}) => {
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [selectedActionStep, setSelectedActionStep] =
    useState<UserActionStep>();

  const {t} = useTranslation('apply', {
    keyPrefix: 'action-steps.components.action-steps-list',
  });

  const {trackEvent} = useAnalytics();

  const handleDialogClose = () => {
    trackEvent({
      eventName: EventName.ACTION_STEPS.ACTION_STEP_DETAIL_DIALOG.CLOSE_DIALOG,
      eventType: EventType.BUTTON_CLICKED,
    });

    setIsDialogVisible(false);
  };

  const handleActionStepClick = (actionStep: UserActionStep) => {
    trackEvent({
      eventName: EventName.ACTION_STEPS.DETAILS,
      eventType: EventType.BUTTON_CLICKED,
    });

    setSelectedActionStep(actionStep);
    setIsDialogVisible(true);
  };

  return (
    <>
      <RoundedCard.Header>
        <RoundedCard.Title>{t('title')}</RoundedCard.Title>
      </RoundedCard.Header>

      {actionSteps?.length ? (
        <RoundedCard.Content>
          <Table>
            <TableHead />

            <TableBody
              handleClick={handleActionStepClick}
              actionSteps={actionSteps}
              actionStepUuidInQueryParams={actionStepUuidInQueryParams}
            />
          </Table>
        </RoundedCard.Content>
      ) : (
        <div className={styles.emptyMessage}>{t('no-action-steps')}</div>
      )}

      {selectedActionStep && (
        <ActionStepDialog
          handleDialogClose={handleDialogClose}
          isDialogVisible={isDialogVisible}
          actionStep={selectedActionStep}
          onRefreshActionSteps={onRefreshActionSteps}
        />
      )}
    </>
  );
};

export default ActionStepsList;
