import InstillAPI from '../axios-instance';

export interface CheckInviteTokenResponse {
  companyLogoUrl: string;
  companyName: string;
  companyUuid: string;
  tokenRedeemed: string;
}

export interface CheckInviteTokenPayload {
  token: string;
}

export async function checkInviteToken(
  inviteToken: string
): Promise<CheckInviteTokenResponse> {
  const result = await InstillAPI.get(`/user-invites/${inviteToken}/check`);

  return {
    companyLogoUrl: result.data.company_logo_url,
    companyName: result.data.company_name,
    companyUuid: result.data.company_uuid,
    tokenRedeemed: result.data.token_redeemed,
  };
}
