import {createMutation} from 'react-query-kit';
import {
  UpdateMeetingFeedbackPayload,
  updateMeetingFeedback,
} from '../../../repositories/instill/mutations/update-meeting-feedback';
import {MeetingFeedback} from '../../../interfaces/meeting';

export const useUpdateMeetingFeedback = createMutation<
  MeetingFeedback | undefined,
  UpdateMeetingFeedbackPayload
>({
  mutationFn: async (variables) => {
    return updateMeetingFeedback(
      variables.companyUuid,
      variables.payload,
      variables.meetingUuid
    );
  },
});
