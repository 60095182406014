import {
  MapYourOrganizationForm,
  MapYourOrganizationFormRaw,
  NewUser,
  NewUserRaw,
  NewRecruit,
  NewRecruitRaw,
  HierarchyRecommendations,
  HierarchyRecommendationsRaw,
  HierarchialUser,
  HierarchialUserRaw,
} from '../../interfaces/map-your-organization';

const sanitizeNewUserToRaw = (data: NewUser): NewUserRaw => {
  return {
    name: data.name,
    email: data.workEmail,
  };
};

const sanitizeNewUsersToRaw = (data: NewUser[]): NewUserRaw[] => {
  return data.map((user) => sanitizeNewUserToRaw(user));
};

export const sanitizeMapYourOrganizationFormToRaw = (
  data: MapYourOrganizationForm
): MapYourOrganizationFormRaw => {
  const payload = {} as MapYourOrganizationFormRaw;

  if (data.manager) {
    payload['manager'] = sanitizeNewUserToRaw(data.manager);
  }

  if (data.reports && data.reports.length) {
    payload['reports'] = sanitizeNewUsersToRaw(data.reports);
  }

  return payload;
};

export const sanitizeNewRecruitsToRaw = (
  data: NewRecruit[]
): NewRecruitRaw[] => {
  return data.map((recruit) => ({
    job_title: recruit.jobTitle,
    uuid: recruit.uuid ?? null,
  }));
};

export const sanitizeHierarchialUserFromRaw = (
  data: HierarchialUserRaw
): HierarchialUser => {
  return {
    name: data.name,
    workEmail: data.work_email,
    uuid: data.uuid,
    picture: data.picture,
    jobTitle: data.job_title,
  };
};

export const sanitizeHierarchyRecommendationFromRaw = (
  data: HierarchyRecommendationsRaw
): HierarchyRecommendations => {
  return {
    manager: data.manager ? sanitizeHierarchialUserFromRaw(data.manager) : null,
    reports: data.reports.map((report: HierarchialUserRaw) => {
      return sanitizeHierarchialUserFromRaw(report);
    }),
  };
};
