import {Actions} from '../../../constants/permissions/actions';
import {FunctionComponent, useMemo} from 'react';
import {getUserProfileForCompany} from '../../../services/user-profiles';
import {Navigate, Route, Routes} from 'react-router';
import {useCurrentAuthUserState} from '../../@atoms/current-auth-user';
import {useSafeCurrentCompany} from '../../@atoms/current-company';
import {useTranslation} from 'react-i18next';
import {useUserPermission} from '../../@hooks/use-user-permission';
import FeedbackBanner from '../../@components/feedback-banner';
import Header from '../@components/header';
import MainContent from '../@components/main-content';
import ManageAssessments from './manage-assessments';
import OverallHealth from './overall-health';
import YourAssessments from './your-assessments';
import YourReports from './your-reports';

const Measure: FunctionComponent = () => {
  const {t} = useTranslation('measure');

  const [currentAuthUser] = useCurrentAuthUserState();
  const currentCompany = useSafeCurrentCompany();

  const userProfile = getUserProfileForCompany(
    currentAuthUser!,
    currentCompany
  );
  const can = useUserPermission(userProfile.role);

  const links = useMemo(() => {
    const links = [
      {
        route: '/application/measure/overall-health',
        label: t('components.header.sub-navigation.links.overall-health'),
      },
      {
        route: '/application/measure/your-assessments',
        label: t('components.header.sub-navigation.links.your-assessments'),
      },
      {
        route: '/application/measure/your-reports',
        label: t('components.header.sub-navigation.links.your-reports'),
      },
    ];

    if (can(Actions.VIEW_MANAGE_ASSESSMENTS)) {
      links.push({
        route: '/application/measure/manage-assessments',
        label: t('components.header.sub-navigation.links.manage-assessments'),
      });
    }

    return links;
  }, [t, can]);

  return (
    <>
      <FeedbackBanner text={t('components.feedback-banner.text')} />

      <Header spacing="small" title={t('components.header.title')}>
        <Header.SubNavigation links={links} />
      </Header>

      <MainContent spacing="small">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Navigate replace to="overall-health" />
              </>
            }
          />

          <Route path="/overall-health" element={<OverallHealth />} />
          <Route path="/manage-assessments/*" element={<ManageAssessments />} />
          <Route path="/your-assessments" element={<YourAssessments />} />
          <Route path="/your-reports" element={<YourReports />} />
          <Route path="*" element={<Navigate replace to="overall-health" />} />
        </Routes>
      </MainContent>
    </>
  );
};

export default Measure;
