import {CompanyEmployee} from '../../../../../../interfaces/company-employee';
import {EventName} from '../../../../../../constants/analytics/event-name';
import {EventType} from '../../../../../../constants/analytics/event-type';
import {Field, FieldArray, FieldArrayRenderProps, FieldProps} from 'formik';
import {FunctionComponent, useCallback, useMemo, useState} from 'react';
import {
  HierarchialUser,
  MapYourOrganizationForm,
} from '../../../../../../interfaces/map-your-organization';
import {useTranslation} from 'react-i18next';
import Button from '../../../../../@components/kit/form/button';
import Label from '../../../../../@components/kit/form/label';
import styles from './styles.module.scss';
import useAnalytics from '../../../../../@hooks/use-analytics';
import UserCheckbox from '../user-checkbox';
import UserDropdown from '../user-dropdown';

interface ElementProps {
  addDirectReportsEventName: string;
  companyEmployees: CompanyEmployee[];
  potentialReports: HierarchialUser[];
  values: MapYourOrganizationForm;
}

export const ReportsForm: FunctionComponent<ElementProps> = ({
  addDirectReportsEventName,
  companyEmployees,
  potentialReports,
  values,
}) => {
  const [activeReportIndex, setActiveReportIndex] = useState<number>(-1);

  const {t} = useTranslation('application');
  const {trackEvent} = useAnalytics();

  const onAddReport = useCallback(
    (push: FieldArrayRenderProps['push'], index: number) => {
      trackEvent({
        eventName:
          addDirectReportsEventName ??
          EventName.COMPONENTS.HIERARCHY_EDIT.ADD_DIRECT_REPORT,
        eventType: EventType.BUTTON_CLICKED,
      });

      setActiveReportIndex(index);
      push({
        name: '',
        workEmail: '',
      });
    },
    [trackEvent, addDirectReportsEventName]
  );

  const isRenderReportButtonVisible = useMemo(() => {
    return !potentialReports || potentialReports.length < 1;
  }, [potentialReports]);

  return (
    <FieldArray name="reports">
      {({push, remove}) => (
        <>
          <Label>
            {t(
              'components.hierarchy-edit.components.reports-form.reports-label'
            )}
          </Label>

          {potentialReports.length > 0 && (
            <div className={styles.existingReportsGrid}>
              {potentialReports.map((report, index) => (
                <UserCheckbox
                  key={index}
                  userData={report}
                  isDisabled={false}
                  onCheck={(userData) => {
                    push({
                      name: userData.name,
                      workEmail: userData.workEmail,
                      isHidden: true,
                    });
                  }}
                  onUncheck={(userData) => {
                    const index = values.reports!.findIndex(
                      (report) => report.workEmail === userData.workEmail
                    );
                    remove(index);
                  }}
                />
              ))}
            </div>
          )}

          {values.reports?.map(
            (report, index) =>
              !report.isHidden && (
                <Field name={`reports.${index}`} key={index}>
                  {(FieldProps: FieldProps<string>) => (
                    <UserDropdown
                      {...FieldProps}
                      activeIndex={activeReportIndex}
                      deleteDynamicItem={remove}
                      itemIndex={index}
                      onAddItemEventName={addDirectReportsEventName}
                      options={companyEmployees}
                      setActiveIndex={setActiveReportIndex}
                      value={report}
                    />
                  )}
                </Field>
              )
          )}

          <div className={styles.group}>
            {isRenderReportButtonVisible && values.reports?.length === 0 ? (
              <Button
                type="button"
                variant="secondary"
                onClick={() => onAddReport(push, values.reports!.length)}
              >
                {t(
                  'components.hierarchy-edit.components.reports-form.add-report-text'
                )}
              </Button>
            ) : (
              <div className={styles.text}>
                {t(
                  'components.hierarchy-edit.components.reports-form.add-report-link-pretext'
                )}

                <button
                  className={styles.linkButton}
                  type="button"
                  onClick={() => onAddReport(push, values.reports!.length)}
                >
                  {t(
                    'components.hierarchy-edit.components.reports-form.add-report-text'
                  )}
                </button>
              </div>
            )}
          </div>
        </>
      )}
    </FieldArray>
  );
};

export default ReportsForm;
