import {Field, FieldProps, FormikErrors, FormikTouched} from 'formik';
import {FunctionComponent, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {CoreValueForm} from '../../../../../../interfaces/company-core-value';
import {CoreValue} from '../../../../../../interfaces/core-value';
import {useSafeCurrentCompany} from '../../../../../@atoms/current-company';
import FormControl from '../../../../../@components/kit/form/form-control';
import FormGroup from '../../../../../@components/kit/form/form-group';
import MutableSelect, {
  Option,
} from '../../../../../@components/kit/form/hybrid-select/mutable-select';
import Label from '../../../../../@components/kit/form/label';
import RichTextEditor from '../../../../../@components/kit/form/rich-text-editor/rich-text-editor';
import BehaviorsForm from '../../../behaviors-form';
import {AddCoreValueFormSchema, Errors} from '../add-core-value-dialog';
import coreValuesFromLocalJson from './core-values.json';
import styles from './styles.module.scss';

interface ElementProps {
  formValues: CoreValueForm;
  errors?: FormikErrors<AddCoreValueFormSchema> | Errors;
  touched?: FormikTouched<AddCoreValueFormSchema>;
}

const ValueForm: FunctionComponent<ElementProps> = ({
  formValues,
  errors,
  touched,
}) => {
  const {t} = useTranslation('dashboard');
  const currentCompany = useSafeCurrentCompany();

  const [coreValueNameOptions, setCoreValueNameOptions] = useState<
    Option[] | null
  >(null);

  const onFetchCoreValues = useCallback(() => {
    const coreValues = coreValuesFromLocalJson.map((coreValue: CoreValue) => ({
      label: coreValue.name,
      value: coreValue.name,
    }));

    setCoreValueNameOptions(coreValues);
  }, []);

  const getCoreValueOptions = useCallback(() => {
    if (coreValueNameOptions === null) {
      onFetchCoreValues();
    }

    return coreValueNameOptions || [];
  }, [coreValueNameOptions, onFetchCoreValues]);

  const coreValueErrors = errors?.coreValue as CoreValueForm;

  const showCoreValueNameError = Boolean(
    touched?.coreValue?.name && coreValueErrors?.name
  );

  const showCoreValueDescriptionError = Boolean(
    touched?.coreValue?.description && coreValueErrors?.description
  );

  const onEditorStateChange = useCallback(
    (htmlValue: string, form: FieldProps['form']) => {
      form.setFieldValue('coreValue.description', htmlValue);
    },
    []
  );

  return (
    <>
      <div className={styles.row}>
        <Field name="coreValue.name">
          {({field, form, meta}: FieldProps<string>) => (
            <FormGroup>
              <FormControl
                error={
                  showCoreValueNameError ? coreValueErrors?.name : undefined
                }
              >
                <Label isRequired htmlFor={field.name}>
                  {t(
                    'components.interactive-values.components.value-form.form.name.label'
                  )}
                </Label>

                <MutableSelect
                  field={field}
                  form={form}
                  meta={meta}
                  defaultValue={{
                    value: formValues.name,
                    label: formValues.name,
                  }}
                  options={getCoreValueOptions()}
                  placeholder={t(
                    'components.interactive-values.components.value-form.form.name.placeholder'
                  )}
                  isMulti={false}
                />
              </FormControl>
            </FormGroup>
          )}
        </Field>

        <Field name="coreValue.description">
          {({form, field}: FieldProps<string>) => (
            <FormGroup>
              <FormControl
                error={
                  showCoreValueDescriptionError
                    ? coreValueErrors.description
                    : undefined
                }
              >
                <Label isRequired htmlFor={field.name}>
                  {t(
                    'components.interactive-values.components.value-form.form.description.label',
                    {
                      companyName: currentCompany.name,
                    }
                  )}
                </Label>
                <RichTextEditor
                  placeholder={t(
                    'components.interactive-values.components.value-form.form.description.placeholder'
                  )}
                  initialValue={field.value}
                  onChange={(value) => onEditorStateChange(value, form)}
                />
              </FormControl>
            </FormGroup>
          )}
        </Field>

        <div className={styles.behaviorContainer}>
          <div className={styles.formGroupLabel}>
            {t(
              'components.interactive-values.components.value-form.form.norms-and-behaviors.label'
            )}
          </div>

          <BehaviorsForm
            fieldNamePrefix="coreValue"
            valueName={formValues.name}
            valueExpectations={formValues.expectations}
            coreValueOptions={coreValuesFromLocalJson}
          />
        </div>
      </div>
    </>
  );
};

export default ValueForm;
