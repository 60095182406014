import {CompanyCoreValue} from '../../../interfaces/company-core-value';
import {CompanyCoreValuePayload} from '../../../repositories/instill/queries/fetch-company-core-value';
import {createQuery} from 'react-query-kit';
import {fetchCompanyCoreValue} from '../../../repositories/instill';

export const useCompanyCoreValue = createQuery<
  CompanyCoreValue | undefined,
  CompanyCoreValuePayload
>({
  primaryKey: 'companyCoreValue',
  queryFn: async ({queryKey: [_, variables]}) => {
    if (!variables.valueUuid) {
      return Promise.reject();
    }

    return await fetchCompanyCoreValue(
      variables.valueUuid,
      variables.companyUuid
    );
  },
});
