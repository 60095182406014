import {
  useDeleteTeam,
  useUpdateTeam,
} from '../../../../../../../@hooks/mutations';
import {CompanyEmployee} from '../../../../../../../../interfaces/company-employee';
import {DEFAULT_TOASTIFY_CONFIGURATION} from '../../../../../../../../constants/toastify-configuration';
import {EventName} from '../../../../../../../../constants/analytics/event-name';
import {EventType} from '../../../../../../../../constants/analytics/event-type';
import {FunctionComponent, useCallback, useState} from 'react';
import {SortedColumn} from '../../../../../../../../interfaces/filtering/sorted-column';
import {Team, TeamUpsert} from '../../../../../../../../interfaces/team';
import {TEAMS_SORTABLE_COLUMNS} from '../../../../../../../../constants/filtering/sort-by';
import {ToastOptions, toast} from 'react-toastify';
import {useSafeCurrentCompany} from '../../../../../../../@atoms/current-company';
import {useTranslation} from 'react-i18next';
import DetailsDialog from './@components/details-dialog/details-dialog';
import SortButton from '../../../../../../@components/sort-button/sort-button';
import Table from '../../../../../../../@components/table';
import TableRow from './@components/table-row';
import tableStyles from '../../../../../../../../styles/classes/table.module.scss';
import useAnalytics from '../../../../../../../@hooks/use-analytics';

interface ElementProps {
  activeSortedColumn: SortedColumn | null;
  companyEmployees: CompanyEmployee[];
  onColumnClicked: (column: SortedColumn) => void;
  onRefreshCompanyEmployees: () => void;
  onRefreshTeams: () => void;
  teams: Team[];
}

const ListView: FunctionComponent<ElementProps> = ({
  activeSortedColumn,
  companyEmployees,
  onColumnClicked,
  onRefreshCompanyEmployees,
  onRefreshTeams,
  teams,
}) => {
  const {t} = useTranslation('people', {
    keyPrefix: 'teams.components.team-list.components.list-view',
  });

  const [isDetailsDialogVisible, setIsDetailsDialogVisible] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState<Team | null>();
  const currentCompany = useSafeCurrentCompany();

  const updateTeam = useUpdateTeam();
  const deleteTeam = useDeleteTeam();

  const companyUuid = currentCompany.uuid;
  const teamUuid = selectedTeam?.uuid;

  const {trackEvent} = useAnalytics();

  const onShowDetailsDialog = useCallback(
    (team: Team) => {
      setSelectedTeam(team);
      setIsDetailsDialogVisible(true);

      trackEvent({
        eventName: EventName.PEOPLE.TEAMS.DETAILS_DIALOG.OPEN_DIALOG,
        eventType: EventType.BUTTON_CLICKED,
      });
    },
    [trackEvent]
  );

  const onCloseDetailsDialog = useCallback(() => {
    setSelectedTeam(null);
    setIsDetailsDialogVisible(false);

    trackEvent({
      eventName: EventName.PEOPLE.TEAMS.DETAILS_DIALOG.CLOSE_DIALOG,
      eventType: EventType.BUTTON_CLICKED,
    });
  }, [trackEvent]);

  const onUpdateTeam = useCallback(
    async (values: TeamUpsert) => {
      if (!teamUuid) return;

      const response = await updateTeam.mutateAsync({
        payload: values,
        teamUuid,
        companyUuid,
      });

      if (!response) return;

      onRefreshTeams();
      onRefreshCompanyEmployees();
      onCloseDetailsDialog();
      setSelectedTeam(undefined);

      const feedbackMessage = t('update-success-message');
      toast.success(feedbackMessage, {
        ...(DEFAULT_TOASTIFY_CONFIGURATION as ToastOptions),
      });

      trackEvent({
        eventName: EventName.PEOPLE.TEAMS.DETAILS_DIALOG.UPDATE_TEAM,
        eventType: EventType.BUTTON_CLICKED,
      });
    },
    [
      companyUuid,
      onCloseDetailsDialog,
      onRefreshCompanyEmployees,
      onRefreshTeams,
      t,
      teamUuid,
      trackEvent,
      updateTeam,
    ]
  );

  const onDeleteTeam = async () => {
    if (!teamUuid) return;

    const response = await deleteTeam.mutateAsync({
      teamUuid,
      companyUuid,
    });

    if (!response) return;

    const feedbackMessage = t('delete-success-message');

    onRefreshTeams();
    onCloseDetailsDialog();
    setSelectedTeam(undefined);

    trackEvent({
      eventName: EventName.PEOPLE.TEAMS.DETAILS_DIALOG.UPDATE_TEAM,
      eventType: EventType.BUTTON_CLICKED,
    });

    toast.success(feedbackMessage, {
      ...(DEFAULT_TOASTIFY_CONFIGURATION as ToastOptions),
    });
  };

  return (
    <>
      <Table>
        <Table.Head className={tableStyles.tableHeader}>
          <Table.Row>
            <Table.Header variant="nameColumn">
              <SortButton
                currentColumnName={TEAMS_SORTABLE_COLUMNS.NAME}
                currentColumnLabel={t('header.name')}
                onColumnClicked={onColumnClicked}
                activeSortedColumn={activeSortedColumn}
              />
            </Table.Header>

            <Table.Header variant="large">
              {t('header.description')}
            </Table.Header>

            <Table.Header variant="large">
              <SortButton
                currentColumnName={TEAMS_SORTABLE_COLUMNS.MANAGER_NAME}
                currentColumnLabel={t('header.manager')}
                onColumnClicked={onColumnClicked}
                activeSortedColumn={activeSortedColumn}
              />
            </Table.Header>

            <Table.Header variant="small">
              <SortButton
                currentColumnName={TEAMS_SORTABLE_COLUMNS.TEAM_SIZE}
                currentColumnLabel={t('header.team-size')}
                onColumnClicked={onColumnClicked}
                activeSortedColumn={activeSortedColumn}
              />
            </Table.Header>

            <Table.Header variant="xsmall">&nbsp;</Table.Header>
          </Table.Row>
        </Table.Head>

        <Table.Content>
          {teams.map((team) => (
            <TableRow
              key={team.uuid}
              onShowDetailsDialog={onShowDetailsDialog}
              team={team}
            />
          ))}
        </Table.Content>
      </Table>

      {selectedTeam && (
        <DetailsDialog
          companyEmployees={companyEmployees}
          isVisible={isDetailsDialogVisible}
          onClose={onCloseDetailsDialog}
          onDeleteTeam={onDeleteTeam}
          onUpdateTeam={onUpdateTeam}
          team={selectedTeam}
        />
      )}
    </>
  );
};

export default ListView;
