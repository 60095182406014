import {CompanyEmployee} from '../../../../../../../../../../../../interfaces/company-employee';
import {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import Button from '../../../../../../../../../../../@components/kit/form/button';
import Dialog from '../../../../../../../../../../../@components/kit/dialog';
import FormFooter from '../../../../../../../../../../../@components/kit/form/form-footer';
import styles from './styles.module.scss';

interface ElementProps {
  employee: CompanyEmployee;
  isDialogVisible: boolean;
  onClose: () => void;
  onConfirm: () => void;
}
const ReminderDialog: FunctionComponent<ElementProps> = ({
  employee,
  isDialogVisible,
  onConfirm,
  onClose,
}) => {
  const {t} = useTranslation('people');

  return (
    <>
      <Dialog isOpen={isDialogVisible} onClose={onClose}>
        <Dialog.Header
          title={t(
            'manage-directory.components.employee-list.components.list.components.table-row.components.reminder-dialog.title',
            {name: employee.name}
          )}
        />

        <Dialog.Content>
          <div className={styles.text}>
            {t(
              'manage-directory.components.employee-list.components.list.components.table-row.components.reminder-dialog.content.form.default-message'
            )}
          </div>

          <FormFooter>
            <Button type="submit" onClick={onConfirm}>
              {t(
                'manage-directory.components.employee-list.components.list.components.table-row.components.reminder-dialog.content.form.submit-button'
              )}
            </Button>

            <Button variant="secondary" onClick={onClose}>
              {t(
                'manage-directory.components.employee-list.components.list.components.table-row.components.reminder-dialog.content.form.cancel-button'
              )}
            </Button>
          </FormFooter>
        </Dialog.Content>
      </Dialog>
    </>
  );
};

export default ReminderDialog;
