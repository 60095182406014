import {isValidEmail} from '../validate';
import {Roles} from '../../constants/roles';

type RoleValues = Record<Roles, string>;

function flattenUserEmailsByRole(values: RoleValues) {
  return Object.keys(values).flatMap((key) => {
    const value = values[key as keyof typeof values];
    const emails = value.split(',').map((email) => email.trim());

    return emails.flatMap((email) => {
      if (!isValidEmail(email)) return [];

      return [{email, role: key}];
    });
  });
}

export default flattenUserEmailsByRole;
