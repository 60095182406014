import {createQuery} from 'react-query-kit';
import {fetchJobPositions} from '../../../repositories/instill/queries';
import {JobPosition} from '../../../interfaces/job-position';
import {Meta} from '../../../interfaces/meta';

interface FetchJobPositionsPayload {
  companyUuid: string;
  limit?: number;
  offset?: number;
}

interface FetchJobPositionsResponsePayload {
  data: JobPosition[];
  meta: Meta;
}

export const useJobPositions = createQuery<
  FetchJobPositionsResponsePayload | undefined,
  FetchJobPositionsPayload
>({
  primaryKey: 'jobPositions',
  queryFn: async ({queryKey: [_, variables]}) => {
    return await fetchJobPositions({
      companyUuid: variables.companyUuid,
      limit: variables.limit,
      offset: variables.offset,
    });
  },
});
