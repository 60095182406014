import {formatDate} from '../../../../../utils/date';
import {FunctionComponent} from 'react';
import {Kudo} from '../../../../../interfaces/kudo';
import {useTranslation} from 'react-i18next';
import Dialog from '../../../../@components/kit/dialog/dialog';
import styles from './styles.module.scss';
import RichTextContainer from '../../../../@components/rich-text-container/rich-text-container';

interface ElementProps {
  isDialogVisible: boolean;
  kudo: Kudo;
  onClose: () => void;
}

const KudoDialog: FunctionComponent<ElementProps> = ({
  isDialogVisible,
  kudo,
  onClose,
}) => {
  const {t} = useTranslation('kudos');

  return (
    <Dialog isOpen={isDialogVisible} onClose={onClose}>
      <Dialog.Header title={t('components.kudo-dialog.title')} />

      <Dialog.Content>
        <ul>
          <li className={styles.item}>
            <div className={styles.title}>
              {t('components.kudo-dialog.labels.recipient')}
            </div>

            <div className={styles.value}>{kudo.toUser.name}</div>
          </li>

          <li className={styles.item}>
            <div className={styles.title}>
              {t('components.kudo-dialog.labels.from')}
            </div>

            <div className={styles.value}>{kudo.fromUser.name}</div>
          </li>

          <li className={styles.item}>
            <div className={styles.title}>
              {t('components.kudo-dialog.labels.value')}
            </div>

            <div className={styles.value}>{kudo.value.name}</div>
          </li>

          <li className={styles.item}>
            <div className={styles.title}>
              {t('components.kudo-dialog.labels.date')}
            </div>

            <div className={styles.value}>{formatDate(kudo.createdAt)}</div>
          </li>

          <li className={styles.item}>
            <div className={styles.title}>
              {t('components.kudo-dialog.labels.description')}
            </div>

            <div className={styles.value}>
              <RichTextContainer value={kudo.description} />
            </div>
          </li>
        </ul>
      </Dialog.Content>
    </Dialog>
  );
};

export default KudoDialog;
