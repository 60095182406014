import {FunctionComponent} from 'react';
import {ReactComponent as IconChevron} from '../../../icons/chevron.svg';
import {useTranslation} from 'react-i18next';
import styles from './styles.module.scss';

interface ElementProps {
  onLinkClick: () => void;
}

const PreviousQuestionLink: FunctionComponent<ElementProps> = ({
  onLinkClick,
}) => {
  const {t} = useTranslation('components');

  return (
    <button type="button" className={styles.link} onClick={onLinkClick}>
      <IconChevron className={styles.icon} />
      {t('questionnaire.components.previous-question-link.label')}
    </button>
  );
};

export default PreviousQuestionLink;
