import {CompanyCoreValue} from '../../../../../../interfaces/company-core-value';
import {FunctionComponent} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import Button from '../../../../../@components/kit/form/button';
import Dialog from '../../../../../@components/kit/dialog';
import styles from './styles.module.scss';

interface ElementProps {
  isDeleteDialogInError: boolean;
  isDialogVisible: boolean;
  onCancel: () => void;
  onClose: () => void;
  onConfirm: () => void;
  value: CompanyCoreValue | null;
}

const DeleteDialog: FunctionComponent<ElementProps> = ({
  isDeleteDialogInError,
  isDialogVisible,
  onCancel,
  onClose,
  onConfirm,
  value,
}) => {
  const {t} = useTranslation('values');

  return (
    <>
      <Dialog isOpen={isDialogVisible} onClose={onClose}>
        <Dialog.Header title={t('components.delete-dialog.title')} />

        <Dialog.Content>
          {isDeleteDialogInError && (
            <div className={styles.error}>
              {t('components.delete-dialog.error-message')}
            </div>
          )}

          <div>
            <Trans
              t={t}
              i18nKey="components.delete-dialog.description"
              values={{
                valueName: value?.name,
              }}
            />
          </div>

          <div className={styles.footer}>
            <Button onClick={onCancel}>
              {t('components.delete-dialog.cancel-button')}
            </Button>

            <Button
              variant="secondary"
              onClick={onConfirm}
              disabled={isDeleteDialogInError}
            >
              {t('components.delete-dialog.confirm-button')}
            </Button>
          </div>
        </Dialog.Content>
      </Dialog>
    </>
  );
};

export default DeleteDialog;
