import {createCompany} from '../../../repositories/instill';
import {createMutation} from 'react-query-kit';
import {Company} from '../../../interfaces/company';
import {CreateCompanyPayload} from '../../../repositories/instill/mutations/create-company';

export const useCreateCompany = createMutation<
  Company | undefined,
  CreateCompanyPayload
>({
  mutationFn: async (variables) => {
    return await createCompany(variables.companyPayload);
  },
});
