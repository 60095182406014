import {FunctionComponent, useState} from 'react';
import {ReactComponent as IconChevron} from '../../../../../../../../../@components/kit/icons/chevron.svg';
import {useTranslation} from 'react-i18next';
import Dialog from '../../../../../../../../../@components/kit/dialog/dialog';
import ItemsList from './@components/items-list';
import RoundedCard from '../../../../../../../../../@components/rounded-card';
import styles from './styles.module.scss';

interface ElementProps {
  participants: string[];
}

const Participants: FunctionComponent<ElementProps> = ({participants}) => {
  const {t} = useTranslation('sense', {
    keyPrefix:
      'meetings.show.index.components.meeting-details.components.participants',
  });
  const [isDialogVisible, setIsDialogVisible] = useState(false);

  const onOpenDialog = () => {
    setIsDialogVisible(true);
  };

  const onCloseDialog = () => {
    setIsDialogVisible(false);
  };

  const trimmedParticipants = participants.slice(
    0,
    Math.min(3, participants.length)
  );

  const isShowAllButtonVisible =
    participants.length !== trimmedParticipants.length;

  return (
    <>
      <RoundedCard>
        <RoundedCard.Title>{t('title')}</RoundedCard.Title>

        <RoundedCard.Content>
          <ItemsList participants={trimmedParticipants} />

          {isShowAllButtonVisible && (
            <footer className={styles.footer}>
              <button
                type="button"
                className={styles.button}
                onClick={onOpenDialog}
              >
                {t('view-all-button')}

                <IconChevron className={styles.buttonIcon} />
              </button>
            </footer>
          )}
        </RoundedCard.Content>
      </RoundedCard>

      <Dialog isOpen={isDialogVisible} onClose={onCloseDialog}>
        <Dialog.Header title={t('title')} />

        <Dialog.Content>
          <ItemsList participants={participants} />
        </Dialog.Content>
      </Dialog>
    </>
  );
};

export default Participants;
