import {FunctionComponent} from 'react';
import {FONT_FAMILIES, FONT_SIZE_MAP} from '../../constants/constants';

interface ElementProps {
  name: string;
  fontFamily?: string;
}

const SignaturePreset: FunctionComponent<ElementProps> = ({
  name,
  fontFamily = FONT_FAMILIES[0],
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" viewBox="0 0 300 100">
      <style type="text/css">
        {`@import url('https://fonts.googleapis.com/css2?family=${fontFamily}&display=swap');`}
      </style>

      <text
        x="50%"
        y="50%"
        fontFamily={fontFamily}
        fontSize={FONT_SIZE_MAP[fontFamily]}
        fill="#000"
        dominantBaseline="middle"
        textAnchor="middle"
      >
        {name}
      </text>
    </svg>
  );
};

export default SignaturePreset;
