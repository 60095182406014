import {
  KudoReceivedPayload,
  KudoReceivedRawPayload,
} from '../../../interfaces/notifications/kudo';

const sanitizePayloadFromRaw = (
  data: KudoReceivedRawPayload
): KudoReceivedPayload => {
  return {
    kudoDescription: data.kudo_description,
    fromUserName: data.from_user_name,
    kudoUuid: data.kudo_uuid,
    valueName: data.value_name,
  };
};

export default sanitizePayloadFromRaw;
