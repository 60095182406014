import './index.scss';
import './init-environment';
import {BrowserRouter} from 'react-router-dom';
import {BrowserTracing} from '@sentry/tracing';
import {HelmetProvider} from 'react-helmet-async';
import * as amplitude from '@amplitude/analytics-browser';
import {initReactI18next} from 'react-i18next';
import {QueryClientProvider, onlineManager} from '@tanstack/react-query';
import {QueryParamProvider} from 'use-query-params';
import {ReactRouter6Adapter} from 'use-query-params/adapters/react-router-6';
import {RecoilRoot} from 'recoil';
import * as Sentry from '@sentry/react';
import ApplicationRoutes from './ui/application-routes';
import Auth0ProviderWithRedirectCallback from './ui/auth0';
import i18n from 'i18next';
import queryClient from './services/query-client';
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './report-web-vitals';
import translations from './locales';

const appPackage = require('../package.json');

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

onlineManager.setOnline(true);

i18n.use(initReactI18next).init({
  defaultNS: 'common',
  fallbackLng: 'en',
  lng: 'en',
  resources: translations,
  returnNull: false,
  interpolation: {
    escapeValue: false,
  },
});

Sentry.init({
  enabled: Boolean(window.env.REACT_APP_SENTRY_DSN),
  dsn: window.env.REACT_APP_SENTRY_DSN,
  environment: window.env.REACT_APP_ENVIRONMENT_NAME,
  integrations: [new BrowserTracing()],
  release: appPackage.version,
  debug: window.env.NODE_ENV !== 'production',
});

if (Boolean(window.env.REACT_APP_AMPLITUDE_API_KEY)) {
  const appVersion = window.env.REACT_APP_AMPLITUDE_ENVIRONMENT_NAME
    ? `${window.env.REACT_APP_AMPLITUDE_ENVIRONMENT_NAME}-${appPackage.version}`
    : appPackage.version;

  amplitude.init(window.env.REACT_APP_AMPLITUDE_API_KEY, undefined, {
    appVersion: appVersion,
  } as any);
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <RecoilRoot>
      <HelmetProvider>
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <QueryParamProvider adapter={ReactRouter6Adapter}>
              <Auth0ProviderWithRedirectCallback>
                <ApplicationRoutes />
              </Auth0ProviderWithRedirectCallback>
            </QueryParamProvider>
          </QueryClientProvider>
        </BrowserRouter>
      </HelmetProvider>
    </RecoilRoot>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
