import {Company} from '../../../interfaces/company';
import {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import AvatarPlaceholder from '../avatar-placeholder';
import styles from './styles.module.scss';

interface ElementProps {
  company: Company;
}

const CompanyProfile: FunctionComponent<ElementProps> = ({company}) => {
  const {t} = useTranslation('components');

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {company.companyLogo ? (
          <img src={company.companyLogo} className={styles.logo} alt="" />
        ) : (
          <div className={styles.logo}>
            <AvatarPlaceholder
              size="medium"
              variant="dark-blue"
              name={company.name}
            />
          </div>
        )}

        <div className={styles.details}>
          <ul className={styles.list}>
            <li className={styles.row}>
              <div className={styles.rowTitle}>
                {t('company-profile.label.name')}
              </div>

              <div className={styles.rowValue}>{company.name}</div>
            </li>

            <li className={styles.row}>
              <div className={styles.rowTitle}>
                {t('company-profile.label.industry')}
              </div>

              <div className={styles.rowValue}>{company.industry}</div>
            </li>

            <li className={styles.row}>
              <div className={styles.rowTitle}>
                {t('company-profile.label.size')}
              </div>

              <div className={styles.rowValue}>{company.size}</div>
            </li>

            <li className={styles.row}>
              <div className={styles.rowTitle}>
                {t('company-profile.label.website')}
              </div>

              <div className={styles.rowValue}>
                {company.websiteUrl ? (
                  <a
                    href={company.websiteUrl}
                    target="blank"
                    rel="noopener noreferrer"
                    className={styles.link}
                  >
                    {company.websiteUrl}
                  </a>
                ) : (
                  <div className={styles.emptyValue}>–</div>
                )}
              </div>
            </li>

            <li className={styles.row}>
              <div className={styles.rowTitle}>
                {t('company-profile.label.email-domain')}
              </div>

              <div className={styles.rowValue}>{company.emailDomain}</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CompanyProfile;
