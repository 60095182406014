import {createQuery} from 'react-query-kit';
import {fetchCompanyUserProfile} from '../../../repositories/instill';
import {FetchCompanyUserProfilePayload} from '../../../repositories/instill/queries/fetch-company-user-profile';
import {FullUserProfile} from '../../../interfaces/user-profile';

export const useCompanyUserProfile = createQuery<
  FullUserProfile | undefined,
  FetchCompanyUserProfilePayload
>({
  primaryKey: 'companyUserProfile',
  queryFn: async ({queryKey: [_, variables]}) => {
    if (!variables.userProfileUuid) {
      return Promise.reject();
    }

    return await fetchCompanyUserProfile({
      companyUuid: variables.companyUuid,
      userProfileUuid: variables.userProfileUuid,
    });
  },
});
