import {sanitizeOrganizationalHealthFromRaw} from '../../../ui/@sanitizers/organizational-health';
import InstillAPI from '../axios-instance';
import isResponseSuccessful from '../../../utils/is-response-successful/is-response-successful';

export interface FetchOrganizationHealthPayload {
  companyUuid: string;
  dimensionsSlug: string[];
  endDate?: string | null;
  startDate?: string | null;
}

export async function fetchOrganizationalHealth(
  parameters: FetchOrganizationHealthPayload
) {
  const {companyUuid, dimensionsSlug, endDate, startDate} = parameters;

  const dimensionsForQuery = dimensionsSlug
    .map((dimensionSlug) => {
      return `dimensions=${dimensionSlug}`;
    })
    .join('&');

  const queryParams = `?start_date=${startDate}&end_date=${endDate}&${dimensionsForQuery}`;
  const url = `/companies/${companyUuid}/organizational-health${queryParams}`;

  const response = await InstillAPI.get(url);

  if (!isResponseSuccessful(response)) {
    throw Error('No data received');
  }

  return sanitizeOrganizationalHealthFromRaw(response.data);
}
