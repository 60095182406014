import {Dimension, DimensionRaw} from '../../interfaces/dimension';

export const sanitizeDimensionsFromRaw = (
  data: DimensionRaw[]
): Dimension[] => {
  return data.map((dimension) => sanitizeDimensionFromRaw(dimension));
};

export const sanitizeDimensionFromRaw = (data: DimensionRaw): Dimension => {
  const {title, slug} = data;

  return {
    isConfigured: data.dimension_configured,
    slug,
    title,
  };
};
