import {
  createContext,
  Dispatch,
  FunctionComponent,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from 'react';

interface Context {
  currentStepIdentifier: string;
  setCurrentStepIdentifier: Dispatch<SetStateAction<string>>;
}

const SetupContext = createContext<Context | null>(null);
SetupContext.displayName = ' Setup';

export const useSetup = () => {
  const context = useContext(SetupContext);
  if (!context) {
    throw new Error('Setup must be used within SetupContextProvider');
  }
  return context;
};

const SetupContextProvider: FunctionComponent<PropsWithChildren<{}>> = ({
  children,
}) => {
  const [currentStepIdentifier, setCurrentStepIdentifier] = useState('');
  const value = {currentStepIdentifier, setCurrentStepIdentifier};

  return (
    <SetupContext.Provider value={value}>{children}</SetupContext.Provider>
  );
};

export default SetupContextProvider;
