import {FunctionComponent} from 'react';
import {ActionStepSentPayload} from '../../../../../interfaces/notifications/action-step-sent';
import {Link} from 'react-router-dom';
import {Trans, useTranslation} from 'react-i18next';
import {TRANSLATION_KEY_PREFIX} from '../../notification-event';
import styles from '../../styles.module.scss';

interface ElementProps {
  payload: ActionStepSentPayload;
}

const ActionStepSent: FunctionComponent<ElementProps> = ({payload}) => {
  const {t} = useTranslation('common', {keyPrefix: TRANSLATION_KEY_PREFIX});

  return (
    <Link
      to={`/application/apply/action-steps?uuid=${payload.uuid}`}
      className={styles.link}
    >
      <Trans
        t={t}
        i18nKey="action-step-sent"
        components={{bold: <b />}}
        values={{
          title: payload.title,
        }}
      />
    </Link>
  );
};

export default ActionStepSent;
