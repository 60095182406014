import {ACTION_STEPS_STATUSES} from '../../../../../../../../constants/action-steps';
import {formatDate} from '../../../../../../../../utils/date';
import {FunctionComponent, useMemo} from 'react';
import {Tooltip} from 'react-tooltip';
import {UserActionStep} from '../../../../../../../../interfaces/action-step';
import {useTranslation} from 'react-i18next';
import ActionTargetIcon from '../action-target-icon';
import Button from '../../../../../../../@components/kit/form/button';
import styles from './styles.module.scss';
import Table from '../../../../../../../@components/table';
import tableStyles from '../../../../../../../../styles/classes/table.module.scss';

export const INDIVIDUAL_TOOLTIP_IDENTIFIER = 'individual-tooltip';
export const COMPANY_TOOLTIP_IDENTIFIER = 'company-tooltip';

const isCompleted = (status: string) => {
  return status === ACTION_STEPS_STATUSES.COMPLETED;
};

interface ElementProps {
  handleClick: (actionStep: UserActionStep) => void;
  actionStep: UserActionStep;
  actionStepUuidInQueryParams?: string | null;
}

const Row: FunctionComponent<ElementProps> = ({
  actionStep,
  actionStepUuidInQueryParams,
  handleClick,
}) => {
  const {t} = useTranslation('apply');

  const isCurrentActionStepSameAsQueryParam =
    actionStepUuidInQueryParams === actionStep.uuid;

  const rowClassName = useMemo(() => {
    let classes = [tableStyles.tableRow];

    if (isCurrentActionStepSameAsQueryParam) {
      classes.push(styles.highlightedRow);
    }

    if (isCompleted(actionStep.status)) {
      classes.push(styles.inactiveRow);
    }

    return classes.join(' ');
  }, [isCurrentActionStepSameAsQueryParam, actionStep]);

  return (
    <>
      <Table.Row className={rowClassName}>
        <Table.Data variant="nameColumn" className={tableStyles.tableData}>
          <div className={tableStyles.mobileHeaderTitle}>
            {t('action-steps.components.table-head.alert')}
          </div>

          <div className={styles.title}>
            <ActionTargetIcon actionStep={actionStep} />

            <Tooltip id={INDIVIDUAL_TOOLTIP_IDENTIFIER} />
            <Tooltip id={COMPANY_TOOLTIP_IDENTIFIER} />

            {actionStep.title}
          </div>
        </Table.Data>

        <Table.Data variant="medium" className={tableStyles.tableData}>
          <div className={tableStyles.mobileHeaderTitle}>
            {t('action-steps.components.table-head.issue')}
          </div>

          {actionStep.dimension}
        </Table.Data>

        <Table.Data variant="large" className={tableStyles.tableData}>
          <div className={tableStyles.mobileHeaderTitle}>
            {t('action-steps.components.table-head.action-steps')}
          </div>

          {actionStep.subtitle}
        </Table.Data>

        <Table.Data variant="small" className={tableStyles.tableData}>
          <div className={tableStyles.mobileHeaderTitle}>
            {t('action-steps.components.table-head.alert-date')}
          </div>

          {formatDate(actionStep.createdAt)}
        </Table.Data>

        <Table.Data variant="xsmall" className={tableStyles.tableData}>
          <div className={styles.actionContent}>
            <Button
              className={styles.actionButton}
              onClick={() => handleClick(actionStep)}
            >
              {t('action-steps.components.table-body.details-button')}
            </Button>
          </div>
        </Table.Data>
      </Table.Row>
    </>
  );
};

export default Row;
