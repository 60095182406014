import {FunctionComponent, PropsWithChildren} from 'react';
import styles from './styles.module.scss';

const Head: FunctionComponent<PropsWithChildren<{className?: string}>> = ({
  children,
  ...props
}) => {
  return (
    <thead className={`${styles.header} ${props.className ?? ''}`}>
      {children}
    </thead>
  );
};

export default Head;
