import {FunctionComponent} from 'react';
import {MeetingListItem} from '../../../../../../../../../interfaces/meeting';
import {MEETINGS_SORTABLE_COLUMNS} from '../../../../../../../../../constants/filtering/sort-by';
import {SortedColumn} from '../../../../../../../../../interfaces/filtering/sorted-column';
import {useTranslation} from 'react-i18next';
import SortButton from '../../../../../../../@components/sort-button';
import Table from '../../../../../../../../@components/table';
import TableRow from './@components/table-row';
import tableStyles from '../../../../../../../../../styles/classes/table.module.scss';

interface ElementProps {
  activeSortedColumn: SortedColumn | null;
  meetings: MeetingListItem[];
  onColumnClicked: (column: SortedColumn) => void;
}

const ListView: FunctionComponent<ElementProps> = ({
  activeSortedColumn,
  meetings,
  onColumnClicked,
}) => {
  const {t} = useTranslation('sense', {
    keyPrefix: 'meetings.index.components.meeting-list.components.list-view',
  });

  return (
    <Table>
      <Table.Head className={tableStyles.tableHeader}>
        <Table.Row variant="no-margin">
          <Table.Header variant="nameColumn">
            <SortButton
              currentColumnName={MEETINGS_SORTABLE_COLUMNS.NAME}
              currentColumnLabel={t('header.name')}
              onColumnClicked={onColumnClicked}
              activeSortedColumn={activeSortedColumn}
            />
          </Table.Header>

          <Table.Header variant="medium">
            <SortButton
              currentColumnName={MEETINGS_SORTABLE_COLUMNS.START_DATE}
              currentColumnLabel={t('header.date')}
              onColumnClicked={onColumnClicked}
              activeSortedColumn={activeSortedColumn}
            />
          </Table.Header>

          <Table.Header variant="large">
            {t('header.participants')}
          </Table.Header>

          <Table.Header variant="medium">
            <SortButton
              currentColumnName={MEETINGS_SORTABLE_COLUMNS.DURATION}
              currentColumnLabel={t('header.duration')}
              onColumnClicked={onColumnClicked}
              activeSortedColumn={activeSortedColumn}
            />
          </Table.Header>

          <Table.Header variant="small">&nbsp;</Table.Header>
        </Table.Row>
      </Table.Head>

      <Table.Content>
        {meetings.map((meeting) => (
          <TableRow
            key={`${meeting.name}–${crypto.randomUUID()}`}
            meeting={meeting}
          />
        ))}
      </Table.Content>
    </Table>
  );
};

export default ListView;
