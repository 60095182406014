import InstillAPI from '../axios-instance';
import isResponseSuccessful from '../../../utils/is-response-successful';

export async function fetchPdfReportUrl(
  assessmentUuid: string,
  moduleUuid: string
) {
  try {
    const response = await InstillAPI.get(
      `/user-assessments/${assessmentUuid}/module/${moduleUuid}/pdf-report`
    );

    if (!response.data || !isResponseSuccessful(response)) {
      throw Error('No data received');
    }

    return response.data;
  } catch (error) {
    console.error(error);
  }
}
