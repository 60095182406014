import {Actions} from '../../../../constants/permissions/actions';
import {buildPageTitle} from '../../../../services/build-page-title';
import {EventName} from '../../../../constants/analytics/event-name';
import {EventType} from '../../../../constants/analytics/event-type';
import {FunctionComponent, useEffect} from 'react';
import {Helmet} from 'react-helmet-async';
import {useCheckPermissionOrRedirect} from '../../../@hooks/use-check-permission-or-redirect';
import {useTranslation} from 'react-i18next';
import mainContainerStyles from '../../../../styles/classes/main-container.module.scss';
import Profile from './@components/profile';
import RoundedCard from '../../../@components/rounded-card';
import useAnalytics from '../../../@hooks/use-analytics';

const ManageCompanyProfile: FunctionComponent = () => {
  const {t} = useTranslation('applicationPageTitle');

  const {trackEvent} = useAnalytics();

  const hasAccess = useCheckPermissionOrRedirect(
    Actions.VIEW_COMPANY_SETTINGS,
    '/application/dashboard'
  );

  useEffect(() => {
    trackEvent({
      eventName: EventName.PAGE_VIEWS.SETTINGS.MANAGE_COMPANY_PROFILE,
      eventType: EventType.PAGE_VIEW,
    });
  }, [trackEvent]);

  if (!hasAccess) return null;

  return (
    <>
      <Helmet>
        <title>
          {buildPageTitle(t('page-title.settings.company-profile'))}
        </title>
      </Helmet>

      <main className={mainContainerStyles.container}>
        <RoundedCard>
          <Profile />
        </RoundedCard>
      </main>
    </>
  );
};

export default ManageCompanyProfile;
