import {useEffect} from 'react';

function resetValue(
  currentValue: string | null,
  lockedValue: string,
  defaultValue: string = ''
) {
  if (currentValue === lockedValue) return defaultValue;

  return currentValue || '';
}

function useBodyScrollLock(isOpen: boolean) {
  useEffect(() => {
    const html = window.document.documentElement;
    const body = window.document.body;
    const htmlStyle = {...html.style};
    const bodyStyle = {...body.style};

    if (isOpen) {
      html.style.overflow = 'visible';
      body.style.overflow = 'hidden';
    }

    return () => {
      html.style.overflow = resetValue(htmlStyle.overflow, 'visible');
      body.style.overflow = resetValue(bodyStyle.overflow, 'hidden');
    };
  }, [isOpen]);
}

export default useBodyScrollLock;
