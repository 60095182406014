enum COMPANY_INDUSTRY {
  ACCOUNTING = 'Accounting',
  BANKING_AND_FINANCIAL_SERVICE = 'Banking & Financial Services',
  CONSULTING_AND_STRATEGY = 'Consulting & Strategy',
  DESIGN_AND_ARCHITECTURE = 'Design & Architechture',
  EDUCATION_AND_TRAINING = 'Education & Training',
  ENGINEERING = 'Engineering',
  GOVERNEMENT_AND_DEFENSE = 'Government & Defence',
  HEALTHCARE_AND_MEDICAL = 'Healthcare & Medical',
  LEGAL = 'Legal',
  TECHNOLOGY = 'Technology',
  OTHER = 'Other',
}

export const COMPANY_INDUSTRY_OPTIONS = [
  {
    label: 'constants.company-industry.accounting',
    value: COMPANY_INDUSTRY.ACCOUNTING,
  },
  {
    label: 'constants.company-industry.bankind-and-financial-services',
    value: COMPANY_INDUSTRY.BANKING_AND_FINANCIAL_SERVICE,
  },
  {
    label: 'constants.company-industry.consulting-and-strategy',
    value: COMPANY_INDUSTRY.CONSULTING_AND_STRATEGY,
  },
  {
    label: 'constants.company-industry.design-and-architechture',
    value: COMPANY_INDUSTRY.DESIGN_AND_ARCHITECTURE,
  },
  {
    label: 'constants.company-industry.education-and-training',
    value: COMPANY_INDUSTRY.EDUCATION_AND_TRAINING,
  },
  {
    label: 'constants.company-industry.engineering',
    value: COMPANY_INDUSTRY.ENGINEERING,
  },
  {
    label: 'constants.company-industry.government-and-defence',
    value: COMPANY_INDUSTRY.GOVERNEMENT_AND_DEFENSE,
  },
  {
    label: 'constants.company-industry.healthcare-medical',
    value: COMPANY_INDUSTRY.HEALTHCARE_AND_MEDICAL,
  },
  {
    label: 'constants.company-industry.legal',
    value: COMPANY_INDUSTRY.LEGAL,
  },
  {
    label: 'constants.company-industry.technology',
    value: COMPANY_INDUSTRY.TECHNOLOGY,
  },
  {
    label: 'constants.company-industry.other',
    value: COMPANY_INDUSTRY.OTHER,
  },
];
