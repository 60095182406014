import {buildPageTitle} from '../../../../services/build-page-title';
import {captureException} from '@sentry/react';
import {CoreValueForm} from '../../../../interfaces/company-core-value';
import {DEFAULT_TOASTIFY_CONFIGURATION} from '../../../../constants/toastify-configuration';
import {
  DeleteValueEvent,
  EditValueEvent,
  ValueShowPageView,
} from '../../../../interfaces/analytics/event-properties';
import {EventName} from '../../../../constants/analytics/event-name';
import {EventType} from '../../../../constants/analytics/event-type';
import {FunctionComponent, useCallback, useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {sanitizeCompanyCoreValueToRaw} from '../../../@sanitizers/company-core-values';
import {toast, ToastOptions} from 'react-toastify';
import {useCompanyCoreValue} from '../../../@hooks/queries';
import {
  useDeleteCompanyCoreValue,
  useUpdateCompanyCoreValue,
} from '../../../@hooks/mutations';
import {useNavigate, useParams} from 'react-router';
import {useSafeCurrentCompany} from '../../../@atoms/current-company';
import {useTranslation} from 'react-i18next';
import DeleteDialog from './@components/delete-dialog/delete-dialog';
import Header from '../../@components/header/header';
import isResponseSuccessful from '../../../../utils/is-response-successful';
import mainContainerStyles from '../../../../styles/classes/main-container.module.scss';
import MainContent from '../../@components/main-content';
import RoundedCard from '../../../@components/rounded-card';
import useAnalytics from '../../../@hooks/use-analytics';
import Value from './@components/value';

const ValuesShow: FunctionComponent = () => {
  const {t} = useTranslation(['values', 'applicationPageTitle']);
  const {uuid: valueUuid} = useParams<'uuid'>();

  const navigate = useNavigate();
  const currentCompany = useSafeCurrentCompany();
  const {trackEvent} = useAnalytics();

  const [isDeleteValueDialogVisible, setIsDeleteValueDialogVisible] =
    useState(false);
  const [isDeleteDialogInError, setIsDeleteDialogInError] = useState(false);

  const value = useCompanyCoreValue({
    enabled: Boolean(valueUuid),
    variables: {
      companyUuid: currentCompany.uuid,
      valueUuid: valueUuid,
    },
    onSuccess(data) {
      trackEvent({
        eventName: EventName.PREFIXES.VALUES_SHOW,
        eventType: EventType.PAGE_VIEW,
        eventProperties: {
          valueName: data?.name,
        } as ValueShowPageView,
      });
    },
  });

  const updateCompanyCoreValue = useUpdateCompanyCoreValue();
  const deleteCompanyCoreValue = useDeleteCompanyCoreValue();

  const onEditValueConfirm = useCallback(
    async (formValue: CoreValueForm) => {
      if (!value.data) return;

      const response = await updateCompanyCoreValue.mutateAsync({
        payload: sanitizeCompanyCoreValueToRaw(formValue),
        companyUuid: currentCompany.uuid,
        coreValueUuid: value.data.uuid,
      });

      if (!response) return;

      value.refetch();

      trackEvent({
        eventName: EventName.VALUES.SHOW.EDIT_VALUE,
        eventType: EventType.BUTTON_CLICKED,
        eventProperties: {
          valueName: value.data.name,
        } as EditValueEvent,
      });

      const feedbackMessage = t('feedback-messages.edit-value');

      toast.success(feedbackMessage, {
        ...(DEFAULT_TOASTIFY_CONFIGURATION as ToastOptions),
      });

      navigate('/application/dashboard');

      return response;
    },
    [trackEvent, updateCompanyCoreValue, currentCompany, t, navigate, value]
  );

  const onDeleteValueConfirm = useCallback(async () => {
    if (!value.data) return;

    const payload = {
      companyUuid: currentCompany.uuid,
      valueUuid: value.data.uuid,
    };

    const response = await deleteCompanyCoreValue.mutateAsync(payload);

    if (!isResponseSuccessful(response)) {
      captureException(response);
      setIsDeleteDialogInError(true);

      return;
    }

    trackEvent({
      eventName: EventName.VALUES.SHOW.DELETE_VALUE,
      eventType: EventType.BUTTON_CLICKED,
      eventProperties: {
        valueName: value.data.name,
      } as DeleteValueEvent,
    });

    setIsDeleteValueDialogVisible(false);

    const feedbackMessage = t('feedback-messages.delete-value');

    toast.success(feedbackMessage, {
      ...(DEFAULT_TOASTIFY_CONFIGURATION as ToastOptions),
    });

    navigate('/application/dashboard');
  }, [trackEvent, deleteCompanyCoreValue, currentCompany, value, navigate, t]);

  const onShowDeleteDialog = useCallback(() => {
    if (!value) return;

    setIsDeleteValueDialogVisible(true);
  }, [value]);

  const onCloseDeleteValueDialog = useCallback(() => {
    setIsDeleteValueDialogVisible(false);
  }, []);

  if (!value.data) return <></>;

  return (
    <>
      <Helmet>
        <title>
          {buildPageTitle([
            value.data?.name ?? '',
            t('page-title.values.index', {ns: 'applicationPageTitle'}),
          ])}
        </title>
      </Helmet>

      <Header title={value.data?.name} />

      <MainContent>
        <main className={mainContainerStyles.container}>
          <RoundedCard>
            <Value
              onDelete={onShowDeleteDialog}
              onEditValueConfirm={onEditValueConfirm}
              value={value.data}
            />
          </RoundedCard>
        </main>
      </MainContent>

      <DeleteDialog
        isDeleteDialogInError={isDeleteDialogInError}
        isDialogVisible={isDeleteValueDialogVisible}
        onCancel={onCloseDeleteValueDialog}
        onClose={onCloseDeleteValueDialog}
        onConfirm={onDeleteValueConfirm}
        value={value.data}
      />
    </>
  );
};

export default ValuesShow;
