import {ChangeEvent, FunctionComponent, InputHTMLAttributes} from 'react';
import {ReactComponent as IconCheck} from '../../icons/check.svg';
import {useFormControlContext} from '../form-control';
import styles from './styles.module.scss';

interface ElementProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const InputCheckbox: FunctionComponent<ElementProps> = ({
  label,
  onChange,
  ...props
}) => {
  let classes = styles.container;
  const {hasError} = useFormControlContext();

  const checkboxId = crypto.randomUUID();

  if (hasError) {
    classes = `${classes} ${styles.error}`;
  }

  return (
    <div className={classes}>
      <input
        className={styles.input}
        onChange={onChange}
        type="checkbox"
        id={checkboxId}
        {...props}
      />

      <label className={styles.label} htmlFor={checkboxId}>
        <div className={styles.iconContainer}>
          <IconCheck className={styles.icon} />
        </div>

        <div className={styles.labelText}>{label}</div>
      </label>
    </div>
  );
};

export default InputCheckbox;
