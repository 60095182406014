import {ReactComponent as GoogleIcon} from '../../../../../@components/kit/icons/google.svg';
import {ReactComponent as InstillIcon} from '../../../../../@components/kit/icons/instill-logo-icon.svg';
import {ReactComponent as RepeatIcon} from '../../../../../@components/kit/icons/repeat.svg';
import {useAuth0} from '@auth0/auth0-react';
import {useTranslation} from 'react-i18next';
import Button from '../../../../../@components/kit/form/button';
import styles from './styles.module.scss';

interface AccountLinkedProps {
  providers: string[];
}

function AccountLinked({providers}: AccountLinkedProps) {
  const {logout} = useAuth0();
  const {t} = useTranslation('authFeedback');

  return (
    <>
      <h1 className={styles.title}>{t('components.account-linked.title')}</h1>

      <p className={styles.text}>{t('components.account-linked.text')}</p>

      <ul className={styles.list}>
        {providers.map((provider) => (
          <li>{t(`components.account-linked.providers.${provider}`)}</li>
        ))}
      </ul>

      <div className={styles.icons}>
        <InstillIcon className={styles.icon} />
        <RepeatIcon className={styles.icon} />
        <GoogleIcon className={styles.icon} />
      </div>

      <Button
        className={styles.button}
        onClick={() => logout({returnTo: window.location.origin})}
      >
        {t('components.account-linked.button')}
      </Button>
    </>
  );
}

export default AccountLinked;
