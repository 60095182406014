import {Actions} from '../../../../../constants/permissions/actions';
import {FunctionComponent} from 'react';
import {getUserProfileForCompany} from '../../../../../services/user-profiles';
import {JobPositionEndedPayload} from '../../../../../interfaces/notifications/job-position-ended';
import {Link} from 'react-router-dom';
import {Trans, useTranslation} from 'react-i18next';
import {TRANSLATION_KEY_PREFIX} from '../../notification-event';
import {useCurrentAuthUserState} from '../../../../@atoms/current-auth-user';
import {useSafeCurrentCompany} from '../../../../@atoms/current-company';
import {useUserPermission} from '../../../../@hooks/use-user-permission';
import styles from '../../styles.module.scss';

interface ElementProps {
  payload: JobPositionEndedPayload;
}

const JOB_POSITIONS_ROUTE = '/application/jobs/job-positions';

const JobPositionEnded: FunctionComponent<ElementProps> = ({payload}) => {
  const {t} = useTranslation('common', {keyPrefix: TRANSLATION_KEY_PREFIX});

  const [currentAuthUser] = useCurrentAuthUserState();
  const currentCompany = useSafeCurrentCompany();

  const userProfile = getUserProfileForCompany(
    currentAuthUser!,
    currentCompany
  );

  const can = useUserPermission(userProfile.role);

  return (
    <>
      {can(Actions.VIEW_JOBS) ? (
        <Link to={JOB_POSITIONS_ROUTE} className={styles.link}>
          <Trans
            t={t}
            i18nKey="job-position-ended"
            components={{bold: <b />}}
            values={{
              jobPositionTitle: payload.jobPositionTitle,
            }}
          />
        </Link>
      ) : (
        <Trans
          t={t}
          i18nKey="job-position-ended"
          components={{bold: <b />}}
          values={{
            jobPositionTitle: payload.jobPositionTitle,
          }}
        />
      )}
    </>
  );
};

export default JobPositionEnded;
