import {buildPageTitle} from '../../../services/build-page-title';
import {COMPANY_INDUSTRY_OPTIONS} from '../../../constants/company-industry-options';
import {COMPANY_SIZE_OPTIONS} from '../../../constants/company-size-options';
import {CompanyRegistrationTellUsAboutYourCompanyEvent} from '../../../interfaces/analytics/event-properties';
import {EventName} from '../../../constants/analytics/event-name';
import {EventType} from '../../../constants/analytics/event-type';
import {Field, FieldProps, useFormikContext} from 'formik';
import {FormEvent, FunctionComponent, useCallback, useMemo} from 'react';
import {Helmet} from 'react-helmet-async';
import {Link} from 'react-router-dom';
import {ReactComponent as IconChevronLeft} from '../../@components/kit/icons/chevron.svg';
import {SignupFormValues} from '../register-company';
import {useTranslation} from 'react-i18next';
import Button from '../../@components/kit/form/button';
import FormControl from '../../@components/kit/form/form-control';
import FormFooter from '../../@components/kit/form/form-footer';
import FormGroup from '../../@components/kit/form/form-group';
import Input from '../../@components/kit/form/input';
import Label from '../../@components/kit/form/label';
import Select from '../../@components/kit/form/select';
import styles from '../styles.module.scss';
import useAnalytics from '../../@hooks/use-analytics';

interface ElementProps {
  onSubmit: () => void;
}

const CompanyDetails: FunctionComponent<ElementProps> = ({onSubmit}) => {
  const {t} = useTranslation(['signup', 'common', 'applicationPageTitle']);
  const {values, errors} = useFormikContext<SignupFormValues>();
  const hasErrors = useMemo(
    () =>
      Boolean(
        errors.name || errors.sizeRange || errors.industry || errors.websiteUrl
      ),
    [errors]
  );

  const {trackEvent} = useAnalytics();

  const handleSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      onSubmit();
    },
    [onSubmit]
  );

  const onContinueButtonClick = useCallback(() => {
    if (hasErrors) return;

    const eventProperties = {
      companyName: values.name,
      companySize: values.sizeRange,
      companyIndustry: values.industry,
      companyWebsiteUrl: values.websiteUrl ?? null,
    } as CompanyRegistrationTellUsAboutYourCompanyEvent;

    trackEvent({
      eventName: EventName.COMPANY_REGISTRATION.TELL_US_ABOUT_COMPANY,
      eventType: EventType.CONTINUE_BUTTON_CLICKED,
      eventProperties,
    });
  }, [hasErrors, trackEvent, values]);

  return (
    <>
      <Helmet>
        <title>
          {buildPageTitle([
            t('page-title.signup.company-details', {
              ns: 'applicationPageTitle',
            }),
            t('page-title.signup.index', {ns: 'applicationPageTitle'}),
          ])}
        </title>
      </Helmet>

      <form onSubmit={handleSubmit} className={styles.form}>
        <div>
          <Link
            to="/register-company/company-admin"
            className={styles.backLink}
          >
            <IconChevronLeft className={styles.backLinkIcon} />
            {t('company-details-form.back-button')}
          </Link>
        </div>

        <div className={styles.formTitle}>
          {t('company-details-form.title', {name: values.adminName})}
        </div>

        <div className={styles.fields}>
          <Field name="name">
            {({field, form}: FieldProps<string, SignupFormValues>) => (
              <FormGroup>
                <FormControl
                  error={
                    form.errors.name && form.touched.name
                      ? t(form.errors.name, {ns: 'common'})
                      : undefined
                  }
                >
                  <Label htmlFor={field.name}>
                    {t('company-details-form.form.name.label')}
                  </Label>

                  <Input
                    placeholder={t(
                      'company-details-form.form.name.placeholder'
                    )}
                    type="text"
                    id={field.name}
                    {...field}
                  />
                </FormControl>
              </FormGroup>
            )}
          </Field>

          <Field name="sizeRange">
            {({field, form}: FieldProps<string, SignupFormValues>) => (
              <FormGroup>
                <FormControl
                  error={
                    form.errors.sizeRange && form.touched.sizeRange
                      ? t(form.errors.sizeRange, {ns: 'common'})
                      : undefined
                  }
                >
                  <Label htmlFor={field.name}>
                    {t('company-details-form.form.size.label')}
                  </Label>

                  <Select id={field.name} {...field}>
                    <option value="" disabled>
                      {t('company-details-form.form.size.placeholder')}
                    </option>

                    {COMPANY_SIZE_OPTIONS.map(({label, value}) => (
                      <option key={value} value={value}>
                        {t(label, {ns: 'common'})}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </FormGroup>
            )}
          </Field>

          <Field name="industry">
            {({field, form}: FieldProps<string, SignupFormValues>) => (
              <FormGroup>
                <FormControl
                  error={
                    form.errors.industry && form.touched.industry
                      ? t(form.errors.industry, {ns: 'common'})
                      : undefined
                  }
                >
                  <Label htmlFor={field.name}>
                    {t('company-details-form.form.industry.label')}
                  </Label>

                  <Select id={field.name} {...field}>
                    <option value="" disabled>
                      {t('company-details-form.form.industry.placeholder')}
                    </option>

                    {COMPANY_INDUSTRY_OPTIONS.map(({label, value}) => (
                      <option key={value} value={value}>
                        {t(label, {ns: 'common'})}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </FormGroup>
            )}
          </Field>

          <Field name="websiteUrl">
            {({field, form}: FieldProps<string, SignupFormValues>) => (
              <FormGroup>
                <FormControl
                  error={
                    form.errors.websiteUrl && form.touched.websiteUrl
                      ? t(form.errors.websiteUrl, {ns: 'common'})
                      : undefined
                  }
                >
                  <Label htmlFor={field.name}>
                    {t('company-details-form.form.website-url.label')}
                  </Label>

                  <Input
                    placeholder={t(
                      'company-details-form.form.website-url.placeholder'
                    )}
                    type="text"
                    id={field.name}
                    {...field}
                  />
                </FormControl>
              </FormGroup>
            )}
          </Field>
        </div>

        <FormFooter>
          <Button
            type="submit"
            disabled={hasErrors}
            onClick={onContinueButtonClick}
          >
            {t('company-details-form.submit-button')}
          </Button>
        </FormFooter>
      </form>
    </>
  );
};

export default CompanyDetails;
