import {FunctionComponent, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {ValueAlignment} from '../../../../../../../../../../../../../../interfaces/meeting';
import {VALUES_ALIGNMENT_SCORES} from '../../../../../../../../../../../../../../constants/values-alignment-scores';
import styles from './styles.module.scss';
import Tag from '../../../../../../../../../../../../../@components/tag';

interface ElementProps {
  value: ValueAlignment;
}

const TAG_VARIANT = {
  [VALUES_ALIGNMENT_SCORES.VERY_LOW]: 'score-very-low',
  [VALUES_ALIGNMENT_SCORES.LOW]: 'score-low',
  [VALUES_ALIGNMENT_SCORES.MEDIUM]: 'score-medium',
  [VALUES_ALIGNMENT_SCORES.HIGH]: 'score-high',
  [VALUES_ALIGNMENT_SCORES.VERY_HIGH]: 'score-very-high',
};

const SCORE_TAG_TRANSLATION_KEY = {
  [VALUES_ALIGNMENT_SCORES.VERY_LOW]:
    'values-alignment.components.item.tags.very-low',
  [VALUES_ALIGNMENT_SCORES.LOW]: 'values-alignment.components.item.tags.low',
  [VALUES_ALIGNMENT_SCORES.MEDIUM]:
    'values-alignment.components.item.tags.medium',
  [VALUES_ALIGNMENT_SCORES.HIGH]: 'values-alignment.components.item.tags.high',
  [VALUES_ALIGNMENT_SCORES.VERY_HIGH]:
    'values-alignment.components.item.tags.very-high',
};

const OverviewItem: FunctionComponent<ElementProps> = ({value}) => {
  const {t} = useTranslation(['sense', 'components']);

  const scoreValue = useMemo(() => {
    if (value.score < 20) {
      return VALUES_ALIGNMENT_SCORES.VERY_LOW;
    } else if (value.score < 40) {
      return VALUES_ALIGNMENT_SCORES.LOW;
    } else if (value.score < 60) {
      return VALUES_ALIGNMENT_SCORES.MEDIUM;
    } else if (value.score < 80) {
      return VALUES_ALIGNMENT_SCORES.HIGH;
    } else {
      return VALUES_ALIGNMENT_SCORES.VERY_HIGH;
    }
  }, [value.score]);

  const progressList = useMemo(() => {
    const score = value.score;
    const defaultArrayLength = 20;

    const scoreOutOfArrayLength = (score * defaultArrayLength) / 100;
    const roundedScore = Math.floor(scoreOutOfArrayLength);

    return Array(defaultArrayLength)
      .fill('')
      .map((_item, index) => {
        return index + 1 <= roundedScore;
      });
  }, [value]);

  const topPerformers = useMemo(() => {
    const sortedPerformances = [...value.speakerPerformances].sort(
      (a, b) => b.score - a.score
    );

    let topPerformers = '';
    const performersLength = Math.min(sortedPerformances.length, 2);

    for (let index = 0; index < performersLength; index++) {
      topPerformers += sortedPerformances[index].name.split(' ')[0];

      if (index !== performersLength - 1) {
        topPerformers += ', ';
      }
    }

    return topPerformers;
  }, [value]);

  return (
    <div className={styles.item}>
      <div className={styles.title}>{value.name}</div>

      <div>
        <Tag variant={TAG_VARIANT[scoreValue]}>
          {t(SCORE_TAG_TRANSLATION_KEY[scoreValue], {ns: 'components'})}
        </Tag>

        <ul className={`${styles.list} ${styles[TAG_VARIANT[scoreValue]]}`}>
          {progressList.map((item, index) => {
            const isHighlighted = Boolean(item);

            return (
              <li
                className={`${styles.listItem} ${
                  isHighlighted ? styles.highlighted : ''
                }`}
                key={index}
              />
            );
          })}
        </ul>
      </div>

      <div className={styles.performers}>
        {t(
          'meetings.show.index.components.meeting-details.components.values-alignment.components.list-item.top-performers.title'
        )}

        {topPerformers}
      </div>
    </div>
  );
};

export default OverviewItem;
