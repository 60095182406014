import {FunctionComponent} from 'react';
import {Navigate, Route, Routes} from 'react-router';
import ValuesShow from './show';

const Values: FunctionComponent = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={<Navigate replace to="/application/dashboard" />}
      />

      <Route path=":uuid" element={<ValuesShow />} />

      <Route path="*" element={<Navigate replace to="/page-not-found" />} />
    </Routes>
  );
};

export default Values;
