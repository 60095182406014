import {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import Table from '../../../../../@components/table';
import tableStyles from '../../../../../../styles/classes/table.module.scss';

const TableHead: FunctionComponent = () => {
  const {t} = useTranslation('apply', {
    keyPrefix: 'action-steps.components.table-head',
  });

  return (
    <Table.Head className={tableStyles.tableHeader}>
      <Table.Row variant="no-margin">
        <Table.Header variant="nameColumn">{t('alert')}</Table.Header>
        <Table.Header variant="medium">{t('issue')}</Table.Header>
        <Table.Header variant="large">{t('action-steps')}</Table.Header>
        <Table.Header variant="small">{t('alert-date')}</Table.Header>
        <Table.Header variant="xsmall"></Table.Header>
      </Table.Row>
    </Table.Head>
  );
};

export default TableHead;
