import {FunctionComponent} from 'react';
import {Notification} from '../../../../../../../../interfaces/notification';
import {useTranslation} from 'react-i18next';
import Item from './@components/item';
import styles from './styles.module.scss';
import useOnClickOutside from '../../../../../../../@hooks/use-on-click-outside';

interface ElementProps {
  clickOutsideElement: HTMLElement | null;
  hasMoreDataToLoad: boolean;
  notifications: Notification[] | undefined;
  onClose: () => void;
  onLoadMore: () => void;
}

const List: FunctionComponent<ElementProps> = ({
  clickOutsideElement,
  hasMoreDataToLoad,
  notifications,
  onClose,
  onLoadMore,
}) => {
  const {t} = useTranslation('application', {
    keyPrefix:
      'components.header.components.notification-center.components.list',
  });

  useOnClickOutside(clickOutsideElement, onClose);

  return (
    <div className={styles.container}>
      <div className={styles.header}>{t('title')}</div>

      {notifications?.length ? (
        <div className={styles.content}>
          <ul>
            {notifications?.map((notification, index) => (
              <Item key={index} notification={notification} />
            ))}
          </ul>

          <footer className={styles.footer}>
            {hasMoreDataToLoad ? (
              <button
                className={styles.button}
                onClick={onLoadMore}
                type="button"
              >
                {t('load-more-button')}
              </button>
            ) : (
              <div className={styles.emptyMessage}>
                {t('no-more-notifications')}
              </div>
            )}
          </footer>
        </div>
      ) : (
        <div className={styles.emptyMessage}>{t('no-notifications')}</div>
      )}
    </div>
  );
};

export default List;
