import {Actions} from '../../constants/permissions/actions';
import {getUserProfileForCompany} from '../../services/user-profiles';
import {useCurrentAuthUserState} from '../@atoms/current-auth-user';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router';
import {useSafeCurrentCompany} from '../@atoms/current-company';
import {useUserPermission} from './use-user-permission';

export const useCheckPermissionOrRedirect = (
  permissionName: Actions,
  redirectPath: string
) => {
  const [currentAuthUser] = useCurrentAuthUserState();
  const currentCompany = useSafeCurrentCompany();

  const navigate = useNavigate();

  const userProfile = getUserProfileForCompany(
    currentAuthUser!,
    currentCompany
  );

  const can = useUserPermission(userProfile.role);
  const hasPermission = can(permissionName);

  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (!hasPermission) {
      navigate(redirectPath);
    }

    setIsInitialized(true);
  }, [hasPermission, navigate, setIsInitialized, redirectPath]);

  // We know upfront if they have access but we want to wait until the useEffect
  // ran to make sure it blocks the rendering even when we're navigating away
  return hasPermission && isInitialized;
};
