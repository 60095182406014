import InstillAPI from '../axios-instance';
import isResponseSuccessful from '../../../utils/is-response-successful';
import {sanitizeCompanyAssessmentsFromRaw} from '../../../ui/@sanitizers/company-assessments';

export interface FetchCompanyAssessmentPayload {
  companyUuid: string;
}

export async function fetchCompanyAssessments(companyUuid: string) {
  try {
    const response = await InstillAPI.get(
      `/companies/${companyUuid}/assessments`
    );

    if (!response.data || !isResponseSuccessful(response)) {
      throw Error('No data received');
    }

    return sanitizeCompanyAssessmentsFromRaw(response.data);
  } catch (error) {
    console.error(error);
  }
}
