import {createMutation} from 'react-query-kit';
import {AxiosResponse} from 'axios';
import {completeActionStep} from '../../../repositories/instill';
import {CompleteActionStepPayload} from '../../../repositories/instill/mutations/complete-action-step';

export const useCompleteActionStep = createMutation<
  AxiosResponse<any, any> | undefined,
  CompleteActionStepPayload
>({
  mutationFn: async (variables) => {
    return await completeActionStep({
      companyUuid: variables.companyUuid,
      actionStepUuid: variables.actionStepUuid,
    });
  },
});
