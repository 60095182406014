import {SortedColumn} from '../../../../interfaces/filtering/sorted-column';
import {FunctionComponent, useCallback, useMemo} from 'react';
import {
  DEFAULT_ORDER_BY_PROPERTY,
  ORDER_BY_PARAMETERS,
} from '../../../../constants/filtering/order-by';
import {ReactComponent as IconChevron} from '../../../@components/kit/icons/chevron.svg';
import styles from './styles.module.scss';

interface ElementProps {
  activeSortedColumn: SortedColumn | null;
  currentColumnLabel: string;
  currentColumnName: string;
  onColumnClicked: (sortedColumn: SortedColumn) => void;
}

const SortButton: FunctionComponent<ElementProps> = ({
  activeSortedColumn,
  currentColumnLabel,
  currentColumnName,
  onColumnClicked,
}) => {
  const iconClassName = useMemo(() => {
    let classNames = [styles.icon];

    if (activeSortedColumn?.columnName === currentColumnName) {
      if (activeSortedColumn.order === ORDER_BY_PARAMETERS.ASC) {
        classNames.push(styles.sortAsc);
      } else {
        classNames.push(styles.sortDesc);
      }
    }

    return classNames.join(' ');
  }, [activeSortedColumn, currentColumnName]);

  const isActive = activeSortedColumn?.columnName === currentColumnName;
  const buttonClassName = isActive
    ? `${styles.button} ${styles.isActive}`
    : styles.button;

  const onClick = useCallback(
    (columnName: string) => {
      const isCurrentColumnAlreadyActive =
        activeSortedColumn?.columnName === columnName;

      let column;

      if (isCurrentColumnAlreadyActive) {
        column = {
          ...activeSortedColumn,
          order:
            activeSortedColumn.order === ORDER_BY_PARAMETERS.ASC
              ? ORDER_BY_PARAMETERS.DESC
              : ORDER_BY_PARAMETERS.ASC,
        };
      } else {
        column = {columnName, order: DEFAULT_ORDER_BY_PROPERTY};
      }

      if (!column) return;

      onColumnClicked(column);
    },
    [activeSortedColumn, onColumnClicked]
  );

  return (
    <button
      onClick={() => onClick(currentColumnName)}
      className={buttonClassName}
    >
      {currentColumnLabel}

      <IconChevron className={iconClassName} />
    </button>
  );
};

export default SortButton;
