import {AxiosResponse} from 'axios';
import {createCompany} from '../../../../repositories/instill/mutations/admin';
import {CreateCompanyPayload} from '../../../../repositories/instill/mutations/admin/create-company';
import {createMutation} from 'react-query-kit';

export const useCreateCompanyForAdminPortal = createMutation<
  AxiosResponse<any, any> | undefined,
  CreateCompanyPayload
>({
  mutationFn: async (variables) => {
    return await createCompany(variables.companyPayload);
  },
});
