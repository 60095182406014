import {FunctionComponent, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Navigate, Route, Routes} from 'react-router';
import {Actions} from '../../../constants/permissions/actions';
import {getUserProfileForCompany} from '../../../services/user-profiles';
import {useCurrentAuthUserState} from '../../@atoms/current-auth-user';
import {useSafeCurrentCompany} from '../../@atoms/current-company';
import {useUserPermission} from '../../@hooks/use-user-permission';
import Header from '../@components/header';
import MainContent from '../@components/main-content';
import Configurations from './configurations';
import ManageCompanyProfile from './manage-company-profile';

const Settings: FunctionComponent = () => {
  const {t} = useTranslation('settings');

  const [currentAuthUser] = useCurrentAuthUserState();
  const currentCompany = useSafeCurrentCompany();

  const userProfile = getUserProfileForCompany(
    currentAuthUser!,
    currentCompany
  );
  const can = useUserPermission(userProfile.role);

  const links = useMemo(() => {
    const list = [
      {
        route: '/application/settings/configurations',
        label: t('components.header.sub-navigation.links.configurations'),
      },
    ];

    if (can(Actions.VIEW_COMPANY_SETTINGS)) {
      list.push({
        route: '/application/settings/manage-company-profile',
        label: t(
          'components.header.sub-navigation.links.manage-company-profile'
        ),
      });
    }

    return list;
  }, [t, can]);

  return (
    <>
      <Header title={t('components.header.title')}>
        <Header.SubNavigation links={links} />
      </Header>

      <MainContent>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Navigate replace to="configurations" />
              </>
            }
          />

          <Route path="/configurations" element={<Configurations />} />

          <Route
            path="/manage-company-profile"
            element={<ManageCompanyProfile />}
          />

          <Route path="*" element={<Navigate replace to="/page-not-found" />} />
        </Routes>
      </MainContent>
    </>
  );
};

export default Settings;
