import {CompanyEmployee} from '../../../interfaces/company-employee';
import {createQuery} from 'react-query-kit';
import {FetchCompanyEmployeePayload} from '../../../repositories/instill/queries/fetch-company-employees';
import {fetchCompanyEmployees} from '../../../repositories/instill';
import {Meta} from '../../../interfaces/meta';
import getOffsetValue from '../../../utils/get-offset-value';

interface FetchCompanyEmployeesResponsePayload {
  data: CompanyEmployee[];
  meta: Meta;
}

export const useCompanyEmployees = createQuery<
  FetchCompanyEmployeesResponsePayload | undefined,
  FetchCompanyEmployeePayload
>({
  primaryKey: 'companyEmployees',
  queryFn: async ({queryKey: [_, variables]}) => {
    let payload = {
      offset: getOffsetValue({
        offset: variables.offset,
        currentPage: variables.currentPage ?? 0,
        itemsPerPage: variables.itemsPerPage ?? 0,
      }),
      limit: variables.limit,
      companyUuid: variables.companyUuid,
    } as FetchCompanyEmployeePayload;

    if (variables.sortBy) {
      payload['sortBy'] = variables.sortBy;
    }

    if (variables.orderBy) {
      payload['orderBy'] = variables.orderBy;
    }

    if (variables.query) {
      payload['query'] = variables.query;
    }

    const response = await fetchCompanyEmployees(
      payload,
      variables.includeAllUsers
    );
    if (!response) return;

    return {
      data: response.data,
      meta: response.meta,
    };
  },
});
