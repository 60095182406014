import {atom, useRecoilState, useRecoilValue, SetterOrUpdater} from 'recoil';
import {FullUserProfile} from '../../interfaces/user-profile';

const currentFullUserProfileState = atom<FullUserProfile | null>({
  key: 'currentFullUserProfile',
  default: null,
});

export function useInitialCurrentFullUserProfileState() {
  return useRecoilState(currentFullUserProfileState);
}

export function useCurrentFullUserProfileState(): [
  FullUserProfile,
  SetterOrUpdater<FullUserProfile | null>
] {
  const state = useRecoilState(currentFullUserProfileState);
  const fullUserProfile = state[0];
  const setFullUserProfile = state[1];

  if (!fullUserProfile) {
    throw Error('Current user profile is null');
  }

  return [fullUserProfile, setFullUserProfile];
}

export function useCurrentFullUserProfile() {
  const fullUserProfile = useRecoilValue(currentFullUserProfileState);

  if (!fullUserProfile) {
    throw Error('Current user profile is null');
  }

  return fullUserProfile;
}
