import {Company} from '../../../../../../../interfaces/company';
import {CompanyUpsert} from '../../../../../../../interfaces/admin/company';
import {FunctionComponent, useCallback, useState} from 'react';
import {StringParam, useQueryParam} from 'use-query-params';
import CompanyProfile from '../../../../../../@components/company-profile';
import CompanyUpsertForm from '../../../../../@components/company-upsert-form';
import Dialog from '../../../../../../@components/kit/dialog';
import Header from './@components/header';
import styles from './styles.module.scss';

interface ElementProps {
  company: Company;
  isDialogVisible: boolean;
  isEditButtonVisible?: boolean;
  onClose: () => void;
  onSubmit: (values: CompanyUpsert, company: Company) => void;
}

const CompanyProfileDialog: FunctionComponent<ElementProps> = ({
  company,
  isDialogVisible,
  onClose,
  isEditButtonVisible = true,
  onSubmit,
}) => {
  const [editModeInQueryParams] = useQueryParam('editMode', StringParam);

  const [isInEditMode, setIsInEditMode] = useState(() => {
    if (!isEditButtonVisible) return false;

    return Boolean(editModeInQueryParams) ?? false;
  });

  const onEditButtonClicked = useCallback(() => {
    setIsInEditMode(true);
  }, []);

  const onCancelButtonClicked = useCallback(() => {
    setIsInEditMode(false);
  }, []);

  const onFormSubmit = useCallback(
    (values: CompanyUpsert) => {
      setIsInEditMode(false);
      onSubmit(values, company);
    },
    [onSubmit, company]
  );

  const onCloseDialog = useCallback(() => {
    setIsInEditMode(false);
    onClose();
  }, [onClose]);

  return (
    <>
      <Dialog variant="large" isOpen={isDialogVisible} onClose={onCloseDialog}>
        <Dialog.Header>
          <Header
            isInEditMode={isInEditMode}
            onEditButtonClicked={onEditButtonClicked}
          />
        </Dialog.Header>

        <Dialog.Content>
          {isInEditMode ? (
            <CompanyUpsertForm
              company={company}
              isConfirmationStepNecessary={true}
              onClose={onCancelButtonClicked}
              onSubmit={onFormSubmit}
            />
          ) : (
            <div className={styles.viewContainer}>
              <CompanyProfile company={company} />
            </div>
          )}
        </Dialog.Content>
      </Dialog>
    </>
  );
};

export default CompanyProfileDialog;
