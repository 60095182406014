import {AxiosResponse} from 'axios';
import {createMutation} from 'react-query-kit';
import {sendReminderEmailForAssessment} from '../../../repositories/instill/mutations';
import {SendReminderEmailForAssessmentPayload} from '../../../repositories/instill/mutations/send-reminder-email-for-assessment';

export const useSendReminderEmailForAssessment = createMutation<
  AxiosResponse<any, any> | undefined,
  SendReminderEmailForAssessmentPayload
>({
  mutationFn: async (variables) => {
    return await sendReminderEmailForAssessment(
      variables.companyUuid,
      variables.assessmentUuid,
      variables.payload
    );
  },
});
