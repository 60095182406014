export const getPropertyFromPath = (object: any, path: string) => {
  const properties = path.split('.');
  let result = object;
  try {
    properties.forEach((property) => {
      result = result[property];
    });
    return result;
  } catch (e) {
    return null;
  }
};
