import {Field, FieldProps, Form, Formik} from 'formik';
import {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import {ToastOptions, toast} from 'react-toastify';
import * as Yup from 'yup';
import {EventName} from '../../../../../../../constants/analytics/event-name';
import {EventType} from '../../../../../../../constants/analytics/event-type';
import {MEETING_URL_REGEX} from '../../../../../../../constants/meetings';
import {DEFAULT_TOASTIFY_CONFIGURATION} from '../../../../../../../constants/toastify-configuration';
import {InviteSense} from '../../../../../../../interfaces/meeting';
import {useSafeCurrentCompany} from '../../../../../../@atoms/current-company';
import Dialog from '../../../../../../@components/kit/dialog/dialog';
import Button from '../../../../../../@components/kit/form/button';
import FormControl from '../../../../../../@components/kit/form/form-control';
import FormFooter from '../../../../../../@components/kit/form/form-footer';
import FormGroup from '../../../../../../@components/kit/form/form-group';
import Input from '../../../../../../@components/kit/form/input';
import Label from '../../../../../../@components/kit/form/label';
import {useInviteSense} from '../../../../../../@hooks/mutations';
import useAnalytics from '../../../../../../@hooks/use-analytics';

interface ElementProps {
  isDialogVisible: boolean;
  onClose: () => void;
}

const initialValues: InviteSense = {
  meetingUrl: '',
};

const InviteSenseDialog: FunctionComponent<ElementProps> = ({
  isDialogVisible,
  onClose,
}) => {
  const {t} = useTranslation('sense', {
    keyPrefix: 'meetings.index.components.invite-sense-dialog',
  });

  const validationSchema = Yup.object().shape({
    meetingUrl: Yup.string()
      .matches(MEETING_URL_REGEX, t('form.validations.invalid-meeting-url'))
      .required(t('form.validations.meeting-url-required')),
  });

  const {trackEvent} = useAnalytics();
  const inviteSense = useInviteSense();
  const currentCompany = useSafeCurrentCompany();

  const onSubmit = async (formValues: InviteSense) => {
    trackEvent({
      eventName: EventName.SENSE.INDEX.INVITE_SENSE,
      eventType: EventType.BUTTON_CLICKED,
    });

    const response = await inviteSense.mutateAsync({
      companyUuid: currentCompany.uuid,
      payload: formValues,
    });

    if (!response) return;

    const feedbackMessage = t('success-message');
    toast.success(feedbackMessage, {
      ...(DEFAULT_TOASTIFY_CONFIGURATION as ToastOptions),
    });

    onClose();
  };

  return (
    <Dialog isOpen={isDialogVisible} onClose={onClose}>
      <Dialog.Header title={t('title')} />

      <Dialog.Content>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnMount
          onSubmit={onSubmit}
        >
          {({errors, touched, isValid}) => (
            <Form>
              <Field name="meetingUrl">
                {({field}: FieldProps<string>) => (
                  <FormGroup>
                    <FormControl
                      error={
                        Boolean(touched.meetingUrl && errors.meetingUrl)
                          ? errors.meetingUrl
                          : undefined
                      }
                    >
                      <Label isRequired htmlFor={field.name}>
                        {t('meeting-link-label')}
                      </Label>

                      <Input
                        type="text"
                        placeholder={t('meeting-link-placeholder')}
                        {...field}
                      />
                    </FormControl>
                  </FormGroup>
                )}
              </Field>

              <FormFooter>
                <Button
                  type="submit"
                  disabled={!isValid}
                  aria-disabled={!isValid}
                >
                  {t('submit-button')}
                </Button>
              </FormFooter>
            </Form>
          )}
        </Formik>
      </Dialog.Content>
    </Dialog>
  );
};

export default InviteSenseDialog;
