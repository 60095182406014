import adminPortal from './admin-portal.json';
import application from './application.json';
import applicationPageTitle from './application-page-title.json';
import apply from './apply.json';
import assessments from './assessments.json';
import assessmentSummary from './assessment-summary.json';
import authFeedback from './auth-feedback.json';
import candidates from './candidates.json';
import common from './common.json';
import components from './components.json';
import dashboard from './dashboard.json';
import design from './design.json';
import invite from './invite.json';
import jobs from './jobs.json';
import kudos from './kudos.json';
import linkSlack from './link-slack.json';
import measure from './measure.json';
import moduleResultFeedback from './module-result-feedback.json';
import myCompanyProfile from './my-company-profile.json';
import myProfile from './my-profile.json';
import organizationSetup from './organization-setup.json';
import pageNotFound from './page-not-found.json';
import people from './people.json';
import selectCompany from './select-company.json';
import sense from './sense.json';
import settings from './settings.json';
import signup from './signup.json';
import userSetup from './user-setup.json';
import values from './values.json';

const translations = {
  adminPortal,
  application,
  applicationPageTitle,
  apply,
  assessments,
  assessmentSummary,
  authFeedback,
  candidates,
  common,
  components,
  dashboard,
  design,
  invite,
  jobs,
  kudos,
  linkSlack,
  measure,
  moduleResultFeedback,
  myCompanyProfile,
  myProfile,
  organizationSetup,
  pageNotFound,
  people,
  selectCompany,
  sense,
  settings,
  signup,
  userSetup,
  values,
};

export default translations;
