import {InviteSense} from '../../../interfaces/meeting';
import {
  sanitizeInviteSenseForRaw,
  sanitizeInviteSenseFromRaw,
} from '../../../ui/@sanitizers/meeting';
import isResponseSuccessful from '../../../utils/is-response-successful';
import InstillAPI from '../axios-instance';

export interface InviteSensePayload {
  companyUuid: string;
  payload: InviteSense;
}

export const inviteSense = async (parameters: InviteSensePayload) => {
  const {companyUuid, payload} = parameters;
  const sanitizedPayload = sanitizeInviteSenseForRaw(payload);

  const response = await InstillAPI.post(
    `/companies/${companyUuid}/meetings/bot`,
    sanitizedPayload
  );

  if (!isResponseSuccessful(response)) {
    throw Error('No data received');
  }

  return sanitizeInviteSenseFromRaw(response.data);
};
