import {buildPageTitle} from '../../../services/build-page-title';
import {EventName} from '../../../constants/analytics/event-name';
import {EventType} from '../../../constants/analytics/event-type';
import {FunctionComponent, useEffect} from 'react';
import {Helmet} from 'react-helmet-async';
import {useCandidateAssessment} from '../../@hooks/queries';
import {useLocation, useNavigate} from 'react-router';
import {useTranslation} from 'react-i18next';
import AssessmentChat from './@components/assessment-chat';
import SidePanel from '../@components/side-panel';
import Spinner from '../../@components/spinner/spinner';
import styles from './styles.module.scss';
import TwoPanePage from '../../@components/two-pane-page';
import useAnalytics from '../../@hooks/use-analytics';

const Assessment: FunctionComponent = () => {
  const {t} = useTranslation('applicationPageTitle');

  const {trackEvent} = useAnalytics();
  const location = useLocation();
  const navigate = useNavigate();

  const {candidateToken, candidateUuid} = location.state;

  const candidateAssessment = useCandidateAssessment({
    variables: {
      candidateToken: candidateToken as string,
      candidateUuid: candidateUuid as string,
    },
  });

  useEffect(() => {
    trackEvent({
      eventName: EventName.PAGE_VIEWS.CANDIDATES.ASSESSMENT,
      eventType: EventType.PAGE_VIEW,
    });
  }, [trackEvent]);

  if (candidateAssessment.isLoading) {
    return <Spinner fullScreen={true} />;
  }

  if (candidateAssessment.error) {
    return null;
  }

  if (candidateAssessment.data!.completedModulesUuid.length === 1) {
    navigate('/candidates/feedback');
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{buildPageTitle(t('page-title.candidates.assessment'))}</title>
      </Helmet>

      <TwoPanePage
        mainPane={
          <div className={styles.mainPane}>
            <AssessmentChat
              candidateToken={candidateToken as string}
              candidateUuid={candidateUuid as string}
              userAssessmentUuid={candidateAssessment.data!.uuid}
              assessmentModule={
                candidateAssessment.data!.assessmentEvent.assessment.modules[0]
              }
            />
          </div>
        }
        secondaryPane={<SidePanel />}
      />
    </>
  );
};

export default Assessment;
