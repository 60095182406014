import {sanitizeUserAssessmentFromRaw} from '../../../ui/@sanitizers/user-assessment';
import InstillAPI from '../axios-instance';
import isResponseSuccessful from '../../../utils/is-response-successful';

export interface FetchUserAssessmentsPayload {
  userProfileUuid: string;
}

export async function fetchUserAssessments(userProfileUuid: string) {
  try {
    const queryParam = `user_profile_uuid=${userProfileUuid}`;
    const response = await InstillAPI.get(`/user-assessments?${queryParam}`);

    if (!response.data || !isResponseSuccessful(response)) {
      throw Error('No data received');
    }

    return sanitizeUserAssessmentFromRaw(response.data);
  } catch (error) {
    console.error(error);
  }
}
