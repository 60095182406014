import InstillAPI from '../axios-instance';
import isResponseSuccessful from '../../../utils/is-response-successful';
import {sanitizeMeetingFromRaw} from '../../../ui/@sanitizers/meeting';

export interface FetchMeetingPayload {
  companyUuid: string;
  meetingUuid?: string;
}

export async function fetchMeeting(parameters: FetchMeetingPayload) {
  const {companyUuid, meetingUuid} = parameters;
  const url = `/companies/${companyUuid}/meetings/${meetingUuid}`;

  try {
    const response = await InstillAPI.get(url);

    if (!response.data || !isResponseSuccessful(response)) {
      throw Error('No data received');
    }

    return sanitizeMeetingFromRaw(response.data);
  } catch (error) {
    console.error(error);
  }
}
