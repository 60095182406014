import {EventName} from '../../../../constants/analytics/event-name';
import {EventType} from '../../../../constants/analytics/event-type';
import {FunctionComponent} from 'react';
import {NavLink} from 'react-router-dom';
import {useSafeCurrentCompany} from '../../../@atoms/current-company';
import {useTranslation} from 'react-i18next';
import {useUserActionSteps} from '../../../@hooks/queries/use-user-action-steps';
import ActionStepsList from './@components/action-steps-list';
import RoundedCard from '../../../@components/rounded-card';
import styles from './styles.module.scss';
import useAnalytics from '../../../@hooks/use-analytics';

const MAXIMUM_NUMBER_OF_ACTION_STEPS_ON_DASHBOARD = 5;

const ActionSteps: FunctionComponent = () => {
  const {t} = useTranslation('dashboard');

  const currentCompany = useSafeCurrentCompany();

  const {trackEvent} = useAnalytics();

  const handleLearnMoreClick = () => {
    trackEvent({
      eventName: EventName.ACTION_STEPS.NAVIGATE_FROM_DASHBOARD,
      eventType: EventType.LINK_CLICKED,
    });
  };

  const actionSteps = useUserActionSteps({
    variables: {
      companyUuid: currentCompany.uuid,
      onlyPending: true,
      limit: MAXIMUM_NUMBER_OF_ACTION_STEPS_ON_DASHBOARD,
    },
  });

  const actionStepsCount =
    actionSteps.data?.data?.length ===
    MAXIMUM_NUMBER_OF_ACTION_STEPS_ON_DASHBOARD
      ? `${MAXIMUM_NUMBER_OF_ACTION_STEPS_ON_DASHBOARD}+`
      : actionSteps.data?.data?.length;

  return (
    <>
      <RoundedCard>
        <RoundedCard.Header>
          <RoundedCard.Title>
            {t('components.action-steps.title', {
              actionStepsCount: actionStepsCount,
            })}
          </RoundedCard.Title>
        </RoundedCard.Header>

        <RoundedCard.Content>
          <div className={styles.flexContainer}>
            <ActionStepsList actionSteps={actionSteps.data?.data} />

            <div className={styles.linkContainer}>
              <NavLink
                to="/application/apply/action-steps"
                className={styles.link}
                onClick={handleLearnMoreClick}
              >
                {t('components.action-steps.see-more-link')}
              </NavLink>
            </div>
          </div>
        </RoundedCard.Content>
      </RoundedCard>
    </>
  );
};

export default ActionSteps;
