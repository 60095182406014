import {FunctionComponent, useMemo} from 'react';
import styles from './styles.module.scss';

interface ElementProps {
  name: string;
  size?: 'big' | 'medium' | 'small';
  variant?: 'dark-blue';
}

const AvatarPlaceholder: FunctionComponent<ElementProps> = ({
  name,
  variant,
  size,
}) => {
  const variantClass = useMemo(() => {
    const classes = [];

    if (variant) classes.push(styles[variant]);
    if (size) classes.push(styles[size]);

    return classes.join(' ');
  }, [size, variant]);

  const initials = useMemo(() => {
    if (!name) return '!';

    const splitName = name.split(' ');

    if (splitName.length > 1) {
      return `${splitName[0][0]}${splitName[splitName.length - 1][0]}`;
    } else {
      return `${name[0]}${name[1]}`;
    }
  }, [name]);

  return (
    <div className={`${styles.avatar} ${variantClass}`}>
      <div className={styles.initials}>{initials}</div>
    </div>
  );
};

export default AvatarPlaceholder;
