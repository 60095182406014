import {CompanyAssessment} from '../../../../../../../../../../../interfaces/company-assessments';
import {CompanyEmployee} from '../../../../../../../../../../../interfaces/company-employee';
import {FunctionComponent} from 'react';
import {SOURCES} from '../table-row';
import {useTranslation} from 'react-i18next';
import AssessmentForm, {AssessmentFormProps} from '../assessment-form';
import Dialog from '../../../../../../../../../../@components/kit/dialog';
import styles from './styles.module.scss';
import {Team} from '../../../../../../../../../../../interfaces/team';

interface ElementProps {
  companyAssessment: CompanyAssessment;
  companyEmployees: CompanyEmployee[];
  teams: Team[];
  isVisible: boolean;
  onClose: () => void;
  onSubmit: (
    values: AssessmentFormProps,
    companyAssessment: CompanyAssessment
  ) => void;
}

const EnableAssessmentDialog: FunctionComponent<ElementProps> = ({
  companyAssessment,
  companyEmployees,
  teams,
  isVisible,
  onClose,
  onSubmit,
}) => {
  const {t} = useTranslation('measure');

  return (
    <Dialog isOpen={isVisible} onClose={onClose} variant="small">
      <Dialog.Header>
        <div className={styles.title}>{companyAssessment.assessment.name}</div>

        <div className={styles.subtitle}>
          {t(
            'manage-assessments.components.assessments.components.enable-assessment-dialog.subtitle'
          )}
        </div>
      </Dialog.Header>

      <Dialog.Content>
        <AssessmentForm
          companyAssessment={companyAssessment}
          companyEmployees={companyEmployees}
          teams={teams}
          onClose={onClose}
          onSubmit={onSubmit}
          source={SOURCES.ENABLE_ASSESSMENT}
        />
      </Dialog.Content>
    </Dialog>
  );
};

export default EnableAssessmentDialog;
