import {createQuery} from 'react-query-kit';
import {fetchCurrentCompanyEmployee} from '../../../repositories/instill';
import {FetchCurrentCompanyEmployeePayload} from '../../../repositories/instill/queries/fetch-current-company-employee';
import {FullUserProfile} from '../../../interfaces/user-profile';

export const useCurrentCompanyEmployee = createQuery<
  FullUserProfile | undefined,
  FetchCurrentCompanyEmployeePayload
>({
  primaryKey: 'companyEmployee',
  queryFn: async ({queryKey: [_, variables]}) => {
    return await fetchCurrentCompanyEmployee(variables.companyUuid);
  },
});
