import {AuthUser} from '../interfaces/auth-user';
import {Company} from '../interfaces/company';
import {UserProfile} from '../interfaces/user-profile';

export const getUserProfileForCompany = (
  authUser: AuthUser,
  company: Company
): UserProfile => {
  const userProfile = authUser?.userProfiles.find(
    (userProfile) => userProfile.companyUuid === company.uuid
  );

  if (!userProfile) {
    throw new Error('UserProfile not found');
  }

  return userProfile;
};

export const updateUserProfileForCompany = (
  authUser: AuthUser,
  company: Company,
  userProfile: Partial<UserProfile>
): any => {
  const index = authUser.userProfiles.findIndex(
    (userProfile) => userProfile.companyUuid === company.uuid
  );

  if (index === -1) {
    throw new Error('UserProfile not found');
  }

  const initialUserProfile = authUser.userProfiles[index];
  return Object.assign([], authUser.userProfiles, {
    [index]: {...initialUserProfile, ...userProfile},
  });
};
