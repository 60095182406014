import {Actions} from '../../constants/permissions/actions';
import {Roles} from '../../constants/roles';
import {can} from '../../services/permissions';

export function useUserPermission(role: string) {
  const updatedRole =
    Object.values(Roles).find((roleFromConstant) => {
      return roleFromConstant === role;
    }) ?? Roles.MEMBER;

  return (action: Actions) => {
    return can(action, updatedRole);
  };
}
