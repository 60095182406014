import {CandidateProfile} from '../../../interfaces/candidate';
import {createQuery} from 'react-query-kit';
import {fetchCandidateProfile} from '../../../repositories/instill';
import {FetchCandidateProfilePayload} from '../../../repositories/instill/queries/fetch-candidate-profile';

export const useCandidateProfile = createQuery<
  CandidateProfile | undefined,
  FetchCandidateProfilePayload
>({
  primaryKey: 'candidateProfile',
  queryFn: async ({queryKey: [_, variables]}) => {
    if (!variables.candidateToken) {
      return Promise.reject();
    }

    return await fetchCandidateProfile(variables);
  },
});
