import {FunctionComponent, useMemo, useState} from 'react';
import {ReactComponent as IconChevron} from '../../../../../../../../../../../../../@components/kit/icons/chevron.svg';
import {useTranslation} from 'react-i18next';
import {ValueAlignment} from '../../../../../../../../../../../../../../interfaces/meeting';
import styles from './styles.module.scss';

interface ElementProps {
  value: ValueAlignment;
}

const DetailedItem: FunctionComponent<ElementProps> = ({value}) => {
  const {t} = useTranslation('sense', {
    keyPrefix:
      'meetings.show.index.components.meeting-details.components.values-alignment.components.list-item.components.detailed-item',
  });

  const [isMoreContentVisible, setIsMoreContentVisible] = useState(false);

  const toggleMoreDetails = () => {
    setIsMoreContentVisible(!isMoreContentVisible);
  };

  const feedbackArray = value.feedback.split('. ');

  const feedback = useMemo(() => {
    if (isMoreContentVisible) {
      return feedbackArray;
    }

    return feedbackArray.splice(0, Math.min(feedbackArray.length, 3));
  }, [feedbackArray, isMoreContentVisible]);

  const buttonTranslation = useMemo(() => {
    return isMoreContentVisible ? t('show-less') : t('show-more');
  }, [isMoreContentVisible, t]);

  return (
    <div className={styles.container}>
      <div className={styles.title}>{t('feedback')}</div>

      <ul className={styles.list}>
        {feedback.map((sentence, index) => (
          <li key={index}>{sentence}</li>
        ))}
      </ul>

      {isMoreContentVisible && (
        <>
          <div className={styles.title}>{t('examples')}</div>

          <ul className={styles.list}>
            {value.examples.map((example, index) => (
              <li className={styles.listItem} key={index}>
                {example.explanation}
              </li>
            ))}
          </ul>
        </>
      )}

      <button
        type="button"
        className={styles.button}
        onClick={toggleMoreDetails}
      >
        <span>{buttonTranslation}</span>

        <IconChevron
          className={`${styles.buttonIcon} ${
            isMoreContentVisible ? styles.asc : ''
          }`}
        />
      </button>
    </div>
  );
};

export default DetailedItem;
