import {captureException} from '@sentry/react';
import {DEFAULT_TOASTIFY_CONFIGURATION} from '../../../../../../../../constants/toastify-configuration';
import {EventName} from '../../../../../../../../constants/analytics/event-name';
import {EventType} from '../../../../../../../../constants/analytics/event-type';
import {FunctionComponent, useCallback, useState} from 'react';
import {ACTION_STEPS_STATUSES} from '../../../../../../../../constants/action-steps';
import {ToastOptions, toast} from 'react-toastify';
import {useCompleteActionStep} from '../../../../../../../@hooks/mutations';
import {UserActionStep} from '../../../../../../../../interfaces/action-step';
import {useSafeCurrentCompany} from '../../../../../../../@atoms/current-company';
import {useTranslation} from 'react-i18next';
import Button from '../../../../../../../@components/kit/form/button';
import ConfirmDialog from '../confirm-dialog';
import isResponseSuccessful from '../../../../../../../../utils/is-response-successful';
import RichTextContainer from '../../../../../../../@components/rich-text-container';
import styles from './styles.module.scss';
import useAnalytics from '../../../../../../../@hooks/use-analytics';

interface ElementProps {
  handleDialogClose: () => void;
  actionStep: UserActionStep;
  onRefreshActionSteps: () => void;
}

const isCompleteButtonDisabled = (actionStep: UserActionStep) => {
  return actionStep.status === ACTION_STEPS_STATUSES.COMPLETED;
};

const ActionStepDetails: FunctionComponent<ElementProps> = ({
  actionStep,
  onRefreshActionSteps,
  handleDialogClose,
}) => {
  const {t} = useTranslation('apply', {
    keyPrefix:
      'action-steps.components.action-step-dialog.components.action-step-details',
  });

  const {trackEvent} = useAnalytics();
  const currentCompany = useSafeCurrentCompany();
  const completeActionStep = useCompleteActionStep();

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const handleCompletedClick = () => {
    trackEvent({
      eventName:
        EventName.ACTION_STEPS.ACTION_STEP_DETAIL_DIALOG.OPEN_CONFIRM_DIALOG,
      eventType: EventType.BUTTON_CLICKED,
    });
    setShowConfirmModal(true);
  };

  const handleCompleteConfirm = useCallback(async () => {
    const payload = {
      companyUuid: currentCompany.uuid,
      actionStepUuid: actionStep.uuid,
    };

    const response = await completeActionStep.mutateAsync(payload);

    if (!isResponseSuccessful(response)) {
      captureException(response);

      return;
    }

    trackEvent({
      eventName:
        EventName.ACTION_STEPS.ACTION_STEP_DETAIL_DIALOG.COMPLETE_DIALOG
          .COMPLETE,
      eventType: EventType.BUTTON_CLICKED,
    });

    const feedbackMessage = t('success-message');

    onRefreshActionSteps();
    handleDialogClose();

    toast.success(feedbackMessage, {
      ...(DEFAULT_TOASTIFY_CONFIGURATION as ToastOptions),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCancel = () => {
    trackEvent({
      eventName:
        EventName.ACTION_STEPS.ACTION_STEP_DETAIL_DIALOG.COMPLETE_DIALOG.CANCEL,
      eventType: EventType.BUTTON_CLICKED,
    });
    setShowConfirmModal(false);
  };

  if (showConfirmModal)
    return (
      <ConfirmDialog
        handleCompleteConfirm={handleCompleteConfirm}
        onCancel={onCancel}
      />
    );

  return (
    <>
      <div className={styles.dimension}>{actionStep.dimension}</div>

      <div className={styles.subtitle}>{actionStep.subtitle}</div>

      <RichTextContainer
        className={styles.description}
        value={actionStep.description}
      />

      <div className={styles.footer}>
        <Button
          disabled={isCompleteButtonDisabled(actionStep)}
          onClick={handleCompletedClick}
        >
          {t('complete-button')}
        </Button>
      </div>
    </>
  );
};

export default ActionStepDetails;
