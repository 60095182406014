import {buildPageTitle} from '../../../services/build-page-title';
import {
  COMPANY_ONBOARDING_COMPLETE_STORAGE_KEY,
  REDIRECT_URL_ON_LOGIN_STORAGE_KEY,
} from '../../../constants/local-storage-keys';
import {EventName} from '../../../constants/analytics/event-name';
import {EventType} from '../../../constants/analytics/event-type';
import {FunctionComponent, useCallback, useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {useEffectOnce} from '../../@hooks/use-effect-once';
import {useNavigate} from 'react-router-dom';
import {useSafeCurrentCompany} from '../../@atoms/current-company';
import {useTranslation} from 'react-i18next';
import ActionSteps from '../@components/action-steps';
import CultureChecklist from './@components/culture-checklist';
import Header from '../@components/header';
import InteractiveValues from '../@components/interactive-values';
import mainContainerStyles from '../../../styles/classes/main-container.module.scss';
import MainContent from '../@components/main-content';
import MeetingList from './@components/meetings-list';
import Playbooks from './@components/playbooks';
import popStorageKey from '../../../utils/local-storage/pop';
import styles from './styles.module.scss';
import useAnalytics from '../../@hooks/use-analytics';
import WelcomeDialog from './@components/welcome-dialog';

const Dashboard: FunctionComponent = () => {
  const currentCompany = useSafeCurrentCompany();

  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const {t} = useTranslation('applicationPageTitle');

  const navigate = useNavigate();
  const {trackEvent} = useAnalytics();

  useEffectOnce(() => {
    const complete = !!popStorageKey(COMPANY_ONBOARDING_COMPLETE_STORAGE_KEY);

    setIsDialogVisible(complete);

    trackEvent({
      eventName: EventName.PAGE_VIEWS.DASHBOARD,
      eventType: EventType.PAGE_VIEW,
    });

    if (
      localStorage.getItem(REDIRECT_URL_ON_LOGIN_STORAGE_KEY) === '/link-slack'
    ) {
      popStorageKey(REDIRECT_URL_ON_LOGIN_STORAGE_KEY);
      navigate('/link-slack');
    }
  });

  const onClose = useCallback(() => {
    setIsDialogVisible(false);
  }, [setIsDialogVisible]);

  return (
    <>
      <Helmet>
        <title>{buildPageTitle(t('page-title.dashboard'))}</title>
      </Helmet>

      <Header title={currentCompany.name} />

      <MainContent>
        <main className={mainContainerStyles.container}>
          <div className={styles.column}>
            <InteractiveValues prefixEventName={EventName.PREFIXES.HOME} />

            <ActionSteps />
          </div>

          <div className={styles.column}>
            <CultureChecklist />

            <MeetingList />

            <Playbooks />
          </div>

          <WelcomeDialog isVisible={isDialogVisible} onClose={onClose} />
        </main>
      </MainContent>
    </>
  );
};

export default Dashboard;
