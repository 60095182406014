import {Fragment, FunctionComponent, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {Meeting} from '../../../interfaces/meeting';
import {Transcript} from '../../../interfaces/transcript';
import {formatDate} from '../../../utils/date';
import RoundedCard from '../rounded-card/rounded-card';
import styles from './styles.module.scss';

interface ElementProps {
  transcript: Transcript[];
  meeting: Meeting;
}

const MEETING_TIMESTAMP_FORMAT = 'HH.mm aaa';

const AudioTranscriptPlayer: FunctionComponent<ElementProps> = ({
  transcript,
  meeting,
}) => {
  const {t} = useTranslation('sense', {
    keyPrefix: 'meetings.show.transcript',
  });

  const playerRef = useRef<HTMLAudioElement>(null);
  const wordsRef = useRef<HTMLSpanElement>(null);

  const formatTimestamp = (
    meetingStartDate: string,
    offsetInSeconds: number
  ) => {
    const date = new Date(meetingStartDate);

    date.setTime(date.getTime() + offsetInSeconds * 1000);

    return formatDate(date.toString(), MEETING_TIMESTAMP_FORMAT);
  };

  useEffect(() => {
    const onTimeUpdate = () => {
      const activeWordIndex = transcript.findIndex((word) => {
        return word.start > playerRef.current!.currentTime;
      });

      let newIndex = 0;
      let iterator = 0;

      // remove the styling from other spans so that we get the effect of current word highlighted
      wordsRef.current?.childNodes.forEach((element, index) => {
        if (element instanceof Element) {
          if (element.nodeName === 'SPAN') {
            iterator = iterator + 1;
            if (iterator === activeWordIndex) {
              newIndex = index;
            }
          }

          element.classList.remove('my-class');
        }
      });

      const wordElement = wordsRef.current?.childNodes[newIndex];

      if (wordElement instanceof Element) {
        wordElement.classList.add('my-class');
      }
    };

    playerRef.current?.addEventListener('timeupdate', onTimeUpdate);

    return () =>
      // eslint-disable-next-line react-hooks/exhaustive-deps
      playerRef.current?.removeEventListener('timeupdate', onTimeUpdate);
  }, [transcript]);

  return (
    <div className={styles.container}>
      <RoundedCard spacing="xsmall">
        <audio
          controls
          ref={playerRef}
          src={meeting.presignedAudioUrl!}
          className={styles.audioPlayer}
        />
      </RoundedCard>

      <div className={styles.mainContainer}>
        <div className={styles.transcriptionContainer}>
          <RoundedCard.Title>{t('transcription.title')}</RoundedCard.Title>

          <span ref={wordsRef} className={styles.textWrapper}>
            {transcript.map((word, index) => (
              <Fragment key={index}>
                {transcript[index - 1]?.speaker !==
                  transcript[index]?.speaker && (
                  <div className={styles.interventionSpeaker}>
                    {transcript[index]?.speaker ||
                      t('transcription.components.speaker-alt')}
                    <span className={styles.interventionTimestamp}>
                      {formatTimestamp(
                        meeting.startDate,
                        transcript[index].start
                      )}
                    </span>
                  </div>
                )}

                <span className={styles.interventionText}>
                  {word.text}&nbsp;
                </span>
              </Fragment>
            ))}
          </span>
        </div>
      </div>
    </div>
  );
};

export default AudioTranscriptPlayer;
