import {FunctionComponent} from 'react';
import {ValueAlignment} from '../../../../../../../../../../../../interfaces/meeting';
import DetailedItem from './@component/detailed-item';
import OverviewItem from './@component/overview-item';
import styles from './styles.module.scss';

interface ElementProps {
  value: ValueAlignment;
}

const ListItem: FunctionComponent<ElementProps> = ({value}) => {
  return (
    <li className={styles.item}>
      <OverviewItem value={value} />

      <DetailedItem value={value} />
    </li>
  );
};

export default ListItem;
