import {createMutation} from 'react-query-kit';
import {
  UpsertOpenRolesPayload,
  upsertOpenRoles,
} from '../../../repositories/instill/mutations/upsert-open-roles';
import {AxiosResponse} from 'axios';

export const useUpsertOpenRoles = createMutation<
  AxiosResponse<any, any> | undefined,
  UpsertOpenRolesPayload
>({
  mutationFn: async (variables) => {
    return await upsertOpenRoles(
      variables.openRolesPayload,
      variables.companyUuid
    );
  },
});
