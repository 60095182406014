import {CompanyUpsert} from '../../../../../../../interfaces/admin/company';
import {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import CompanyUpsertForm from '../../../../../@components/company-upsert-form/company-upsert-form';
import Dialog from '../../../../../../@components/kit/dialog';

interface ElementProps {
  isVisible: boolean;
  onClose: () => void;
  onSubmit: (values: CompanyUpsert) => void;
}

const AddCompanyDialog: FunctionComponent<ElementProps> = ({
  onSubmit,
  isVisible,
  onClose,
}) => {
  const {t} = useTranslation('adminPortal');

  return (
    <Dialog variant="medium" isOpen={isVisible} onClose={onClose}>
      <Dialog.Header
        title={t('components.company-list.components.add-company-dialog.title')}
      />

      <Dialog.Content>
        <CompanyUpsertForm
          isConfirmationStepNecessary={false}
          onClose={onClose}
          onSubmit={onSubmit}
        />
      </Dialog.Content>
    </Dialog>
  );
};

export default AddCompanyDialog;
