import {Field, FieldProps, useFormikContext} from 'formik';
import {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import {useSafeCurrentCompany} from 'ui/@atoms/current-company';
import {timeZoneOptions} from '../../../../../../constants/time-zone-options';
import {FullUserProfile} from '../../../../../../interfaces/user-profile';
import FormControl from '../../../../../@components/kit/form/form-control/form-control';
import SearchableSelect from '../../../../../@components/kit/form/hybrid-select/searchable-select/searchable-select';
import Input from '../../../../../@components/kit/form/input/input';
import Tag from '../../../../../@components/tag/tag';
import {PROFILE_DETAILS_FORM_VALUES} from '../../profile-details';
import profileStyles from '../../styles.module.scss';

interface ElementProps {
  isInEditMode: boolean;
  profile: FullUserProfile;
}

const BasicProfile: FunctionComponent<ElementProps> = ({
  isInEditMode,
  profile,
}) => {
  const {t} = useTranslation('application');

  const {errors, touched} = useFormikContext<PROFILE_DETAILS_FORM_VALUES>();
  const currentCompany = useSafeCurrentCompany();

  const isHRISProviderUKG = currentCompany.companySettings.isHRISProviderUKG;

  return (
    <div
      className={`${profileStyles.box} ${
        isInEditMode ? profileStyles.editBox : ''
      }`}
    >
      <h2 className={profileStyles.boxTitle}>
        {t('components.profile-details.components.basic-profile.title')}
      </h2>

      <ul>
        <li className={profileStyles.boxItem}>
          <div className={profileStyles.boxItemKey}>
            {t('components.profile-details.keys.name')}
          </div>

          <div className={profileStyles.boxItemValue}>
            {isInEditMode ? (
              <Field name="name">
                {({field}: FieldProps<string>) => (
                  <FormControl
                    error={
                      Boolean(errors.name || touched.name)
                        ? errors.name
                        : undefined
                    }
                  >
                    <Input
                      type="text"
                      placeholder={t(
                        'constants.user-profile.placeholder.name',
                        {ns: 'common'}
                      )}
                      disabled={isHRISProviderUKG && !!profile.name}
                      {...field}
                    />
                  </FormControl>
                )}
              </Field>
            ) : (
              <Tag>{profile.name}</Tag>
            )}
          </div>
        </li>

        <li className={profileStyles.boxItem}>
          <div className={profileStyles.boxItemKey}>
            {t('components.profile-details.keys.prefered-name')}
          </div>

          <div className={profileStyles.boxItemValue}>
            {isInEditMode ? (
              <Field name="preferredName">
                {({field}: FieldProps<string>) => (
                  <Input
                    type="text"
                    placeholder={t(
                      'constants.user-profile.placeholder.preferred-name',
                      {ns: 'common'}
                    )}
                    {...field}
                  />
                )}
              </Field>
            ) : (
              <>
                {profile.preferredName ? (
                  <Tag>{profile.preferredName}</Tag>
                ) : (
                  <div className={profileStyles.emptyValue}>–</div>
                )}
              </>
            )}
          </div>
        </li>

        <li className={profileStyles.boxItem}>
          <div className={profileStyles.boxItemKey}>
            {t('components.profile-details.keys.phone-number')}
          </div>

          <div className={profileStyles.boxItemValue}>
            {isInEditMode ? (
              <Field name="phoneNumber">
                {({field}: FieldProps<string>) => (
                  <FormControl
                    error={
                      Boolean(errors.phoneNumber || touched.phoneNumber)
                        ? errors.phoneNumber
                        : undefined
                    }
                  >
                    <Input
                      type="text"
                      placeholder={t(
                        'constants.user-profile.placeholder.phone-number',
                        {ns: 'common'}
                      )}
                      {...field}
                    />
                  </FormControl>
                )}
              </Field>
            ) : (
              <>
                {profile.phoneNumber ? (
                  <Tag>{profile.phoneNumber}</Tag>
                ) : (
                  <div className={profileStyles.emptyValue}>–</div>
                )}
              </>
            )}
          </div>
        </li>

        <li className={profileStyles.boxItem}>
          <div className={profileStyles.boxItemKey}>
            {t('components.profile-details.keys.work-email')}
          </div>

          <div className={profileStyles.boxItemValue}>
            {isInEditMode ? (
              <Field name="workEmail">
                {({field}: FieldProps<string>) => (
                  <FormControl
                    error={
                      Boolean(errors.workEmail || touched.workEmail)
                        ? errors.workEmail
                        : undefined
                    }
                  >
                    <Input
                      type="text"
                      placeholder={t(
                        'constants.user-profile.placeholder.work-email',
                        {ns: 'common'}
                      )}
                      disabled={isHRISProviderUKG && !!profile.workEmail}
                      {...field}
                    />
                  </FormControl>
                )}
              </Field>
            ) : (
              <Tag>{profile.workEmail}</Tag>
            )}
          </div>
        </li>

        <li className={profileStyles.boxItem}>
          <div className={profileStyles.boxItemKey}>
            {t('components.profile-details.keys.personal-email')}
          </div>

          <div className={profileStyles.boxItemValue}>
            {isInEditMode ? (
              <Field name="personalEmail">
                {({field}: FieldProps<string>) => (
                  <FormControl
                    error={
                      Boolean(errors.personalEmail && touched.personalEmail)
                        ? errors.personalEmail
                        : undefined
                    }
                  >
                    <Input
                      type="text"
                      placeholder={t(
                        'constants.user-profile.placeholder.personal-email',
                        {ns: 'common'}
                      )}
                      {...field}
                    />
                  </FormControl>
                )}
              </Field>
            ) : (
              <>
                {profile.personalEmail ? (
                  <Tag>{profile.personalEmail}</Tag>
                ) : (
                  <div className={profileStyles.emptyValue}>–</div>
                )}
              </>
            )}
          </div>
        </li>

        <li className={profileStyles.boxItem}>
          <div className={profileStyles.boxItemKey}>
            {t('components.profile-details.keys.timezone')}
          </div>

          <div className={profileStyles.boxItemValue}>
            {isInEditMode ? (
              <Field name="timeZone">
                {({field, form, meta}: FieldProps<string>) => (
                  <FormControl
                    error={
                      Boolean(errors.timeZone || touched.timeZone)
                        ? errors.timeZone
                        : undefined
                    }
                  >
                    <SearchableSelect
                      isDisabled={isHRISProviderUKG && !!profile.timeZone}
                      placeholder={t(
                        'constants.user-profile.placeholder.time-zone',
                        {ns: 'common'}
                      )}
                      field={field}
                      form={form}
                      meta={meta}
                      options={timeZoneOptions}
                    />
                  </FormControl>
                )}
              </Field>
            ) : (
              <>
                {profile.timeZone ? (
                  <Tag>{profile.timeZone}</Tag>
                ) : (
                  <div className={profileStyles.emptyValue}>–</div>
                )}
              </>
            )}
          </div>
        </li>

        <li className={profileStyles.boxItem}>
          <div className={profileStyles.boxItemKey}>
            {t('components.profile-details.keys.job-title')}
          </div>

          <div className={profileStyles.boxItemValue}>
            {isInEditMode ? (
              <Field name="jobTitle">
                {({field}: FieldProps<string>) => (
                  <FormControl
                    error={
                      Boolean(errors.jobTitle || touched.jobTitle)
                        ? errors.jobTitle
                        : undefined
                    }
                  >
                    <Input
                      disabled={isHRISProviderUKG && !!profile.jobTitle}
                      type="text"
                      placeholder={t(
                        'constants.user-profile.placeholder.job-title',
                        {ns: 'common'}
                      )}
                      {...field}
                    />
                  </FormControl>
                )}
              </Field>
            ) : (
              <>
                {profile.jobTitle ? (
                  <Tag>{profile.jobTitle}</Tag>
                ) : (
                  <div className={profileStyles.emptyValue}>–</div>
                )}
              </>
            )}
          </div>
        </li>
      </ul>
    </div>
  );
};

export default BasicProfile;
