import {FunctionComponent} from 'react';
import {Helmet} from 'react-helmet-async';
import {buildPageTitle} from '../../../../services/build-page-title';
import {useTranslation} from 'react-i18next';

const AssessmentSummary: FunctionComponent = () => {
  const {t} = useTranslation('applicationPageTitle');
  return (
    <>
      <Helmet>
        <title>
          {buildPageTitle([
            'assessment or module name',
            t('page-title.assessments-summary.index'),
          ])}
        </title>
      </Helmet>

      <div>Assessment Summary</div>
    </>
  );
};

export default AssessmentSummary;
