import {useCallback, useEffect, useMemo, useState} from 'react';
import {NumberParam, useQueryParam} from 'use-query-params';

interface Parameters {
  itemsPerPage: number;
  updateQueryParameter?: boolean;
}

export default function usePagination(parameters: Parameters) {
  const {updateQueryParameter = true, itemsPerPage} = parameters;

  const [pageInQueryParams, setPageInQueryParams] = useQueryParam(
    'page',
    NumberParam
  );

  const [currentPage, setCurrentPage] = useState(pageInQueryParams ?? 1);

  const goToNextPage = useCallback(() => {
    setCurrentPage(currentPage + 1);
  }, [currentPage]);

  const goToPreviousPage = useCallback(() => {
    setCurrentPage(currentPage - 1);
  }, [currentPage]);

  const onResetPagination = useCallback(() => {
    setCurrentPage(1);
  }, []);

  const offset = useMemo(() => {
    return currentPage * itemsPerPage - itemsPerPage;
  }, [currentPage, itemsPerPage]);

  useEffect(() => {
    if (!updateQueryParameter) return;

    setPageInQueryParams(currentPage);
  }, [updateQueryParameter, setPageInQueryParams, currentPage]);

  return {
    currentPage,
    goToNextPage,
    goToPreviousPage,
    offset,
    onResetPagination,
    pageInQueryParams,
    setCurrentPage,
    setPageInQueryParams,
  };
}
