import {CircularProgressbar, buildStyles} from 'react-circular-progressbar';
import {CULTURE_VITAL_SIGN_COLORS} from '../../../../../../../../../../../../constants/culture-vital-signs';
import {CultureVitalSign} from '../../../../../../../../../../../../interfaces/meeting';
import {FunctionComponent} from 'react';
import styles from './styles.module.scss';

interface ElementProps {
  activeVitalSignIndex: number;
  cultureVitalSigns: CultureVitalSign[];
  onTabClick: (tabIndex: number) => void;
}

const DEFAULT_TRAIL_COLOR = '#f5f5f5';

const List: FunctionComponent<ElementProps> = ({
  cultureVitalSigns,
  onTabClick,
  activeVitalSignIndex,
}) => {
  const isTabActive = (index: number) => {
    return activeVitalSignIndex === index;
  };

  const pathColor = (index: number) =>
    CULTURE_VITAL_SIGN_COLORS[
      cultureVitalSigns[index].slug as keyof typeof CULTURE_VITAL_SIGN_COLORS
    ].NORMAL;

  return (
    <ul className={styles.list}>
      {cultureVitalSigns.map((vitalSign, index) => (
        <li
          key={index}
          className={`${styles.listItem} ${
            isTabActive(index) ? styles.active : ''
          }`}
          onClick={() => onTabClick(index)}
        >
          {vitalSign.name}

          <CircularProgressbar
            value={vitalSign.score}
            text={`${vitalSign.score}%`}
            className={styles.score}
            strokeWidth={7}
            styles={buildStyles({
              pathColor: pathColor(index),
              trailColor: DEFAULT_TRAIL_COLOR,
            })}
          />
        </li>
      ))}
    </ul>
  );
};

export default List;
