import InstillAPI from '../axios-instance';
import isResponseSuccessful from '../../../utils/is-response-successful';
import {sanitizeSingleUserAssessmentFromRaw} from '../../../ui/@sanitizers/user-assessment';

export interface FetchUserAssessmentPayload {
  userAssessmentUuid: string;
}

export async function fetchUserAssessment(userAssessmentUuid: string) {
  try {
    const response = await InstillAPI.get(
      `/user-assessments/${userAssessmentUuid}`
    );

    if (!response.data || !isResponseSuccessful(response)) {
      throw Error('No data received');
    }

    return sanitizeSingleUserAssessmentFromRaw(response.data);
  } catch (error) {
    throw new Error('Could not fetch user assessment');
  }
}
