import {
  ChangeEvent,
  FormEvent,
  FunctionComponent,
  useCallback,
  useState,
} from 'react';
import {useTranslation} from 'react-i18next';
import {useDebounce} from 'react-use';
import {StringParam, createEnumParam, useQueryParams} from 'use-query-params';
import {EventName} from '../../../../../../../constants/analytics/event-name';
import {EventType} from '../../../../../../../constants/analytics/event-type';
import {
  ORDER_BY_PARAMETERS,
  SUPPORTED_ORDER_BY_PARAMETERS,
} from '../../../../../../../constants/filtering/order-by';
import {
  MEETINGS_SORTABLE_COLUMNS,
  SUPPORTED_SORTABLE_COLUMNS,
} from '../../../../../../../constants/filtering/sort-by';
import {SearchTriggeredEvent} from '../../../../../../../interfaces/analytics/event-properties';
import {SortedColumn} from '../../../../../../../interfaces/filtering/sorted-column';
import getActiveSortedColumnFromQueryParameters from '../../../../../../../utils/get-active-sorted-column-from-query-params/get-active-sorted-column-from-query-params';
import {useSafeCurrentCompany} from '../../../../../../@atoms/current-company';
import Pagination from '../../../../../../@components/pagination';
import RoundedCard from '../../../../../../@components/rounded-card';
import Spinner from '../../../../../../@components/spinner';
import {useMeetings} from '../../../../../../@hooks/queries/use-meetings';
import useAnalytics from '../../../../../../@hooks/use-analytics';
import usePagination from '../../../../../../@hooks/use-pagination';
import NoResultsBox from '../../../../../@components/no-results-box';
import SearchForm from '../../../../../@components/search-form';
import ListView from './@components/list-view/list-view';

const DELAY_FOR_SEARCH_DEBOUNCE_IN_MS = 500;
const MINIMUM_SEARCH_LETTERS = 2;
const NUMBER_OF_ITEMS_PER_PAGE = 20;

const MeetingsList: FunctionComponent = () => {
  const {t} = useTranslation('sense', {
    keyPrefix: 'meetings.index.components.meeting-list',
  });

  const {trackEvent} = useAnalytics();
  const currentCompany = useSafeCurrentCompany();

  const [queryParameters, setQueryParameters] = useQueryParams({
    orderBy: createEnumParam(SUPPORTED_ORDER_BY_PARAMETERS),
    sortBy: createEnumParam(SUPPORTED_SORTABLE_COLUMNS.MEETINGS),
    query: StringParam,
  });

  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(
    queryParameters.query
  );

  const [hasSearched, setHasSearched] = useState(false);

  const [activeSortedColumn, setActiveSortedColumn] =
    useState<SortedColumn | null>(
      getActiveSortedColumnFromQueryParameters({
        orderBy: queryParameters.orderBy,
        sortBy: queryParameters.sortBy,
        supportedColumnsToSort: SUPPORTED_SORTABLE_COLUMNS.MEETINGS,
      })
    );

  const {
    currentPage,
    goToNextPage,
    goToPreviousPage,
    offset,
    onResetPagination,
    pageInQueryParams,
    setCurrentPage,
    setPageInQueryParams,
  } = usePagination({itemsPerPage: NUMBER_OF_ITEMS_PER_PAGE});

  const meetings = useMeetings({
    variables: {
      companyUuid: currentCompany.uuid,
      currentPage,
      itemsPerPage: NUMBER_OF_ITEMS_PER_PAGE,
      limit: NUMBER_OF_ITEMS_PER_PAGE,
      offset,
      orderBy: queryParameters.orderBy ?? undefined,
      query: queryParameters.query ?? undefined,
      sortBy:
        (queryParameters.sortBy as MEETINGS_SORTABLE_COLUMNS) ?? undefined,
    },
  });

  const onSearch = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setDebouncedSearchQuery(value);

      const eventProperties = {
        searchQuery: value,
      } as SearchTriggeredEvent;

      setHasSearched(true);

      trackEvent({
        eventName: EventName.SENSE.INDEX.SEARCH,
        eventType: EventType.SEARCH_TRIGGERED,
        eventProperties,
      });
    },
    [trackEvent]
  );

  const onClearSearch = useCallback(
    (event: FormEvent) => {
      event.preventDefault();

      setQueryParameters({
        ...queryParameters,
        query: undefined,
      });

      setHasSearched(false);

      trackEvent({
        eventName: EventName.SENSE.INDEX.CLEAR_SEARCH,
        eventType: EventType.BUTTON_CLICKED,
      });
    },
    [queryParameters, setQueryParameters, trackEvent]
  );

  const onColumnClicked = useCallback(
    (column: SortedColumn) => {
      setActiveSortedColumn(column);

      setQueryParameters({
        ...queryParameters,
        sortBy: column.columnName as MEETINGS_SORTABLE_COLUMNS,
        orderBy: column.order as ORDER_BY_PARAMETERS,
      });

      if (!pageInQueryParams) return;

      setCurrentPage(1);
      setPageInQueryParams(1);
    },
    [
      pageInQueryParams,
      setCurrentPage,
      queryParameters,
      setQueryParameters,
      setPageInQueryParams,
    ]
  );

  useDebounce(
    () => {
      if (
        debouncedSearchQuery !== '' &&
        (debouncedSearchQuery?.length ?? 0) < MINIMUM_SEARCH_LETTERS
      ) {
        return;
      }

      setQueryParameters({
        ...queryParameters,
        query: debouncedSearchQuery,
      });

      if (!pageInQueryParams) return;
      onResetPagination();
    },
    DELAY_FOR_SEARCH_DEBOUNCE_IN_MS,
    [debouncedSearchQuery]
  );

  return (
    <>
      <RoundedCard.Header>
        <RoundedCard.Title>{t('title')}</RoundedCard.Title>

        <div>
          <SearchForm
            searchQueryInQueryParams={queryParameters.query}
            onSearch={onSearch}
            placeholder={t('search-box-placeholder')}
            onClearSearch={onClearSearch}
          />
        </div>
      </RoundedCard.Header>

      <RoundedCard.Content>
        {meetings.isLoading ? (
          <Spinner />
        ) : (
          <>
            {meetings.data?.data.length ? (
              <>
                <ListView
                  meetings={meetings.data.data}
                  activeSortedColumn={activeSortedColumn}
                  onColumnClicked={onColumnClicked}
                />

                {meetings.data?.meta && (
                  <Pagination
                    currentPage={currentPage}
                    itemsPerPage={NUMBER_OF_ITEMS_PER_PAGE}
                    onNextPageLinkClicked={goToNextPage}
                    onPreviousPageLinkClicked={goToPreviousPage}
                    totalItems={meetings.data?.meta.totalEntries}
                  />
                )}
              </>
            ) : (
              <>
                {hasSearched ? (
                  <NoResultsBox />
                ) : (
                  <NoResultsBox
                    title={t('no-meetings-text')}
                    isMessageVisible={false}
                  />
                )}
              </>
            )}
          </>
        )}
      </RoundedCard.Content>
    </>
  );
};

export default MeetingsList;
