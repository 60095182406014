export const WORK_HOURS_OPTIONS = [
  {label: '1', value: '1'},
  {label: '2', value: '2'},
  {label: '3', value: '3'},
  {label: '4', value: '4'},
  {label: '5', value: '5'},
  {label: '6', value: '6'},
  {label: '7', value: '7'},
  {label: '8', value: '8'},
  {label: '9', value: '9'},
  {label: '10', value: '10'},
  {label: '11', value: '11'},
  {label: '12', value: '12'},
  {label: '13', value: '13'},
  {label: '14', value: '14'},
  {label: '15', value: '15'},
  {label: '16', value: '16'},
  {label: '17', value: '17'},
  {label: '18', value: '18'},
  {label: '19', value: '19'},
  {label: '20', value: '20'},
  {label: '21', value: '21'},
  {label: '22', value: '22'},
  {label: '23', value: '23'},
  {label: '24', value: '24'},
];

export const WORK_MINUTES_OPTIONS = [
  {label: '00', value: '0'},
  {label: '15', value: '15'},
  {label: '30', value: '30'},
  {label: '45', value: '45'},
];

export const WORK_STYLE_TRANSLATION_MAP = {
  DAILY: 'constants.work-style-preferences.values.daily',
  DAY: 'constants.work-style-preferences.values.day',
  ENTIRELY_OFFICE: 'constants.work-style-preferences.values.entirely-office',
  ENTIRELY_VIRTUAL: 'constants.work-style-preferences.values.entirely-virtual',
  EVERY_OTHER_WEEK: 'constants.work-style-preferences.values.every-other-week',
  FEW_DAYS: 'constants.work-style-preferences.values.few-days',
  FEW_HOURS: 'constants.work-style-preferences.values.few-hours',
  HYBRID: 'constants.work-style-preferences.values.hybrid',
  IMMEDIATE: 'constants.work-style-preferences.values.immediate',
  MONTHLY: 'constants.work-style-preferences.values.monthly',
  MOSTLY_OFFICE: 'constants.work-style-preferences.values.mostly-office',
  MOSTLY_VIRTUAL: 'constants.work-style-preferences.values.mostly-virtual',
  NO_CONTACT: 'constants.work-style-preferences.values.no-contact',
  TWICE_PER_WEEK: 'constants.work-style-preferences.values.twice-per-week',
  WEEK: 'constants.work-style-preferences.values.week',
  WEEKLY: 'constants.work-style-preferences.values.weekly',
};
