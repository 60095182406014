import {FunctionComponent, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {NavLink} from 'react-router-dom';
import {EventName} from '../../../../../constants/analytics/event-name';
import {MeetingListItem} from '../../../../../interfaces/meeting';
import {useSafeCurrentCompany} from '../../../../@atoms/current-company';
import Button from '../../../../@components/kit/form/button/button';
import RoundedCard from '../../../../@components/rounded-card';
import Spinner from '../../../../@components/spinner';
import {useMeetings} from '../../../../@hooks/queries/use-meetings';
import useAnalytics from '../../../../@hooks/use-analytics';
import InviteSenseDialog from '../../../sense/meetings/index/@components/invite-sense-dialog/invite-sense-dialog';
import styles from './styles.module.scss';

const NUMBER_OF_MEETINGS_TO_FETCH = 3;

const MeetingList: FunctionComponent = () => {
  const {t} = useTranslation('dashboard');

  const [isInviteSenseDialogVisible, setIsInviteSenseDialogVisible] =
    useState(false);

  const onOpenInviteSenseModal = () => {
    setIsInviteSenseDialogVisible(true);
  };

  const onCloseInviteSenseModal = () => {
    setIsInviteSenseDialogVisible(false);
  };

  const {trackEvent} = useAnalytics();
  const currentCompany = useSafeCurrentCompany();

  const meetings = useMeetings({
    variables: {
      companyUuid: currentCompany.uuid,
      limit: NUMBER_OF_MEETINGS_TO_FETCH,
    },
  });

  const trackMeetingClicked = (meeting: MeetingListItem) => {
    trackEvent({
      eventName: EventName.HOME.MEETINGS_LIST.LINK_CLICKED,
      eventProperties: {
        linkTitle: meeting.name,
      },
    });
  };

  return (
    <RoundedCard>
      <RoundedCard.Header variant="vertical">
        <RoundedCard.Title>
          {t('components.meetings-list.title')}
        </RoundedCard.Title>

        <div className={styles.description}>
          {t('components.meetings-list.description')}
        </div>
      </RoundedCard.Header>

      <RoundedCard.Content>
        {meetings.isLoading ? (
          <Spinner />
        ) : meetings.data?.data.length ? (
          <ul>
            {meetings.data.data.map((meeting, index) => (
              <li className={styles.item} key={index}>
                <NavLink
                  className={styles.link}
                  onClick={() => trackMeetingClicked(meeting)}
                  to={`../sense/meetings/${meeting.uuid}`}
                >
                  {meeting.name}
                </NavLink>
              </li>
            ))}
          </ul>
        ) : (
          <div className={styles.emptyMessage}>
            {t('components.meetings-list.no-meetings-text')}
            <div className={styles.buttonWrapper}>
              <Button onClick={onOpenInviteSenseModal}>
                {t('components.meetings-list.add-meeting-button')}
              </Button>
            </div>
          </div>
        )}
      </RoundedCard.Content>

      <InviteSenseDialog
        isDialogVisible={isInviteSenseDialogVisible}
        onClose={onCloseInviteSenseModal}
      />
    </RoundedCard>
  );
};

export default MeetingList;
