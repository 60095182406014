import {createQuery} from 'react-query-kit';
import {UserAssessment} from '../../../interfaces/user-assessment';
import {
  FetchCandidateAssessmentPayload,
  fetchCandidateAssessment,
} from '../../../repositories/instill/queries/fetch-candidate-assessment';

export const useCandidateAssessment = createQuery<
  UserAssessment | undefined,
  FetchCandidateAssessmentPayload
>({
  primaryKey: 'candidateAssessment',
  queryFn: async ({queryKey: [_, variables]}) => {
    if (!variables.candidateToken || !variables.candidateUuid) {
      return Promise.reject();
    }

    return await fetchCandidateAssessment({
      candidateToken: variables.candidateToken,
      candidateUuid: variables.candidateUuid,
    });
  },
});
