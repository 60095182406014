import {ChangeEvent, FunctionComponent, useCallback, useMemo} from 'react';
import {Dimension} from '../../../../../../../../../../../../interfaces/dimension';
import {ReactComponent as IconCheck} from '../../../../../../../../../../../@components/kit/icons/check.svg';
import styles from './styles.module.scss';

interface ElementProps {
  dimension: Dimension;
  isListDisabled: boolean;
  onDimensionsChange: (dimensions: Dimension) => void;
  selectedDimensions?: Dimension[];
}

const Item: FunctionComponent<ElementProps> = ({
  dimension,
  isListDisabled,
  onDimensionsChange,
  selectedDimensions,
}) => {
  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onDimensionsChange(dimension);
    },
    [onDimensionsChange, dimension]
  );

  const isChecked = useMemo(() => {
    return selectedDimensions?.includes(dimension);
  }, [selectedDimensions, dimension]);

  const isDisabled = useMemo(() => {
    return isChecked && isListDisabled;
  }, [isListDisabled, isChecked]);

  const buttonClasses = isDisabled
    ? `${styles.isDisabled} ${styles.button}`
    : styles.button;

  const checkboxId = crypto.randomUUID();

  return (
    <label className={buttonClasses}>
      <input
        checked={isChecked}
        className={styles.input}
        onChange={onChange}
        disabled={isDisabled}
        type="checkbox"
        id={checkboxId}
      />

      <div className={styles.iconContainer}>
        <IconCheck className={styles.icon} />
      </div>

      <div className={styles.labelText}>{dimension.title}</div>
    </label>
  );
};

export default Item;
