import {sanitizeJobPositionsFromRaw} from '../../../ui/@sanitizers/job-position';
import {sanitizeMetaFromRaw} from '../../../ui/@sanitizers/meta';
import InstillAPI from '../axios-instance';
import isResponseSuccessful from '../../../utils/is-response-successful';

export interface FetchJobPositionsPayload {
  companyUuid: string;
  limit?: number;
  offset?: number;
}

export async function fetchJobPositions(parameters: FetchJobPositionsPayload) {
  const {companyUuid, limit, offset} = parameters;

  let url = `/companies/${companyUuid}/job-positions`;
  let queryParams: string[] = [];

  if (offset) {
    queryParams.push(`skip=${offset}`);
  }

  if (limit) {
    queryParams.push(`limit=${limit}`);
  }

  if (queryParams) {
    url = `${url}?${queryParams.join('&')}`;
  }

  try {
    const response = await InstillAPI.get(url);

    if (!response.data || !isResponseSuccessful(response)) {
      throw Error('No data received');
    }

    return {
      data: sanitizeJobPositionsFromRaw(response.data.data),
      meta: sanitizeMetaFromRaw(response.data.meta),
    };
  } catch (error) {
    console.error(error);
  }
}
