import {CompanyAssessment} from '../../../interfaces/company-assessments';
import {createQuery} from 'react-query-kit';
import {fetchCompanyAssessments} from '../../../repositories/instill';
import {FetchCompanyAssessmentPayload} from '../../../repositories/instill/queries/fetch-company-assessments';

export const useCompanyAssessments = createQuery<
  CompanyAssessment[] | undefined,
  FetchCompanyAssessmentPayload
>({
  primaryKey: 'companyAssessments',
  queryFn: async ({queryKey: [_, variables]}) => {
    return await fetchCompanyAssessments(variables.companyUuid);
  },
});
