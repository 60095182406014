function maskEmail(email: string) {
  const emailSplit = email.split(new RegExp('@|\\.'));

  const [username, domain, ...tldList] = emailSplit;

  const usernameMasked = username[0] + '*'.repeat(username.length - 1);
  const domainMasked = domain[0] + '*'.repeat(domain.length - 1);
  const tld = tldList.join('.');

  return `${usernameMasked}@${domainMasked}.${tld}`;
}

export default maskEmail;
