import InstillAPI from '../axios-instance';
import isResponseSuccessful from '../../../utils/is-response-successful';

export interface UpsertAssessmentResponsePayload {
  moduleUuid: string;
  questionUuid: string;
  userAssessmentUuid: string;
  value: string;
}

interface ResponsePayload {
  value: string;
}

export async function upsertAssessmentResponse(
  assessmentResponsePayload: ResponsePayload,
  userAssessmentUuid: string,
  moduleUuid: string,
  questionUuid: string
) {
  const response = await InstillAPI.post(
    `/user-assessments/${userAssessmentUuid}/module/${moduleUuid}/question/${questionUuid}/response`,
    assessmentResponsePayload
  );

  if (!isResponseSuccessful(response)) {
    throw Error('No data received');
  }

  return response.data;
}
