import {ActionStep, ActionStepRaw} from '../../interfaces/action-step';

export const sanitizeActionStepsFromRaw = (
  data: ActionStepRaw[]
): ActionStep[] => {
  return data.map((actionStep) => sanitizeActionStepFromRaw(actionStep));
};

export const sanitizeActionStepFromRaw = (data: ActionStepRaw): ActionStep => {
  return {
    completedActionSteps: data.completed_action_steps,
    jobTitle: data.job_title,
    name: data.name,
    pendingActionSteps: data.pending_action_steps,
    picture: data.picture,
    userProfileUuid: data.user_profile_uuid,
  };
};
