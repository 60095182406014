import {AnalyticsEventProperties} from '../../interfaces/analytics/event-properties';
import {useCallback} from 'react';
import * as amplitude from '@amplitude/analytics-browser';

interface UserProperty {
  key: string;
  value: string;
}

export default function useAnalytics() {
  const trackEvent = useCallback(
    ({
      eventName,
      eventType,
      eventProperties,
    }: {
      eventName: string;
      eventType?: string;
      eventProperties?: AnalyticsEventProperties;
    }) => {
      if (!eventName) return;

      amplitude.track(eventName, {
        ...eventProperties,
        eventType,
      });
    },
    []
  );

  const setUserProperties = useCallback((properties: UserProperty[]) => {
    if (!properties) return;

    const identifyObj = new amplitude.Identify();

    properties.map((property) => {
      return identifyObj.set(property.key, property.value);
    });

    amplitude.identify(identifyObj);
  }, []);

  const setUserIdentifier = useCallback((identifier: string) => {
    amplitude.setUserId(identifier);
  }, []);

  const buildEventName = useCallback((eventName: string, prefix?: string) => {
    if (!prefix) return eventName;

    return `${prefix} – ${eventName}`;
  }, []);

  return {
    buildEventName,
    setUserIdentifier,
    setUserProperties,
    trackEvent,
  };
}
