import {CompanyEmployee} from '../../../../../../../../../interfaces/company-employee';
import {EMPLOYEES_SORTABLE_COLUMNS} from '../../../../../../../../../constants/filtering/sort-by';
import {FunctionComponent} from 'react';
import {SortedColumn} from '../../../../../../../../../interfaces/filtering/sorted-column';
import {useTranslation} from 'react-i18next';
import SortButton from '../../../../../../../@components/sort-button';
import styles from './styles.module.scss';
import Table from '../../../../../../../../@components/table';
import TableRow from './@components/table-row';

interface ElementProps {
  activeSortedColumn: SortedColumn | null;
  employees: CompanyEmployee[];
  onColumnClicked: (column: SortedColumn) => void;
}

const ListView: FunctionComponent<ElementProps> = ({
  activeSortedColumn,
  employees,
  onColumnClicked,
}) => {
  const {t} = useTranslation('people');

  return (
    <Table>
      <Table.Head className={styles.tableHeader}>
        <Table.Row variant="no-margin">
          <Table.Header variant="nameColumn">
            <SortButton
              currentColumnName={EMPLOYEES_SORTABLE_COLUMNS.NAME}
              currentColumnLabel={t(
                'employees.components.employee-list.components.list-view.header.name'
              )}
              onColumnClicked={onColumnClicked}
              activeSortedColumn={activeSortedColumn}
            />
          </Table.Header>

          <Table.Header variant="large">
            <SortButton
              currentColumnName={EMPLOYEES_SORTABLE_COLUMNS.JOB_TITLE}
              currentColumnLabel={t(
                'employees.components.employee-list.components.list-view.header.role'
              )}
              onColumnClicked={onColumnClicked}
              activeSortedColumn={activeSortedColumn}
            />
          </Table.Header>

          <Table.Header variant="large">
            <SortButton
              currentColumnName={EMPLOYEES_SORTABLE_COLUMNS.TEAM}
              currentColumnLabel={t(
                'employees.components.employee-list.components.list-view.header.team'
              )}
              onColumnClicked={onColumnClicked}
              activeSortedColumn={activeSortedColumn}
            />
          </Table.Header>

          <Table.Header variant="medium">
            {t(
              'employees.components.employee-list.components.list-view.header.hexaco'
            )}
          </Table.Header>
        </Table.Row>
      </Table.Head>

      <Table.Content>
        {employees.map((employee) => (
          <TableRow
            key={`${employee.name}–${crypto.randomUUID()}`}
            employee={employee}
          />
        ))}
      </Table.Content>
    </Table>
  );
};

export default ListView;
