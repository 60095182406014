import {FunctionComponent} from 'react';
import {ReactComponent as IconCopy} from '../../../@components/kit/icons/copy.svg';
import {ReactComponent as IconSearch} from '../../../@components/kit/icons/search.svg';
import {ReactComponent as IconShare} from '../../../@components/kit/icons/share.svg';
import {ReactComponent as IconType} from '../../../@components/kit/icons/type.svg';
import {useTranslation} from 'react-i18next';
import Dialog from '../../../@components/kit/dialog';
import styles from './styles.module.scss';

interface ElementProps {
  isVisible: boolean;
  onClose: () => void;
}

const HowToGetYoutubeUrlDialog: FunctionComponent<ElementProps> = ({
  isVisible,
  onClose,
}) => {
  const {t} = useTranslation('components');

  return (
    <Dialog isOpen={isVisible} onClose={onClose}>
      <Dialog.Header title={t('how-to-get-youtube-url-dialog.title')} />

      <Dialog.Content>
        <ul className={styles.list}>
          <li className={styles.item}>
            <div className={styles.content}>
              <div className={styles.stepIdentifier}>
                {t('how-to-get-youtube-url-dialog.steps.title', {
                  index: 1,
                })}
              </div>

              <div className={styles.stepContent}>
                {t('how-to-get-youtube-url-dialog.steps.step-1')}
              </div>
            </div>

            <div>
              <IconSearch className={styles.icon} />
            </div>
          </li>

          <li className={styles.item}>
            <div className={styles.content}>
              <div className={styles.stepIdentifier}>
                {t('how-to-get-youtube-url-dialog.steps.title', {
                  index: 2,
                })}
              </div>

              <div className={styles.stepContent}>
                {t('how-to-get-youtube-url-dialog.steps.step-2')}
              </div>
            </div>

            <div>
              <IconShare className={styles.icon} />
            </div>
          </li>

          <li className={styles.item}>
            <div className={styles.content}>
              <div className={styles.stepIdentifier}>
                {t('how-to-get-youtube-url-dialog.steps.title', {
                  index: 3,
                })}
              </div>

              <div className={styles.stepContent}>
                {t('how-to-get-youtube-url-dialog.steps.step-3')}
              </div>
            </div>

            <div>
              <IconCopy className={styles.icon} />
            </div>
          </li>

          <li className={styles.item}>
            <div className={styles.content}>
              <div className={styles.stepIdentifier}>
                {t('how-to-get-youtube-url-dialog.steps.title', {
                  index: 4,
                })}
              </div>

              <div className={styles.stepContent}>
                {t('how-to-get-youtube-url-dialog.steps.step-4')}
              </div>
            </div>

            <div>
              <IconType className={styles.icon} />
            </div>
          </li>
        </ul>
      </Dialog.Content>
    </Dialog>
  );
};

export default HowToGetYoutubeUrlDialog;
