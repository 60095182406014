import {FunctionComponent, useEffect, useState} from 'react';
import {Field, FieldProps} from 'formik';
import {useTranslation} from 'react-i18next';
import {NewRecruit} from '../../../../../../../../interfaces/map-your-organization';
import FormGroup from '../../../../../../../@components/kit/form/form-group/form-group';
import Label from '../../../../../../../@components/kit/form/label/label';
import Input from '../../../../../../../@components/kit/form/input/input';
import styles from './styles.module.scss';
import Button from '../../../../../../../@components/kit/form/button';
import {ReactComponent as IconDelete} from '../../../../../../../@components/kit/icons/trash.svg';
import {ReactComponent as IconPenEdit} from '../../../../../../../@components/kit/icons/edit.svg';
import {ReactComponent as IconAlert} from '../../../../../../../@components/kit/icons/alert-circle.svg';
import FormControl from '../../../../../../../@components/kit/form/form-control';
import {getPropertyFromPath} from '../../../../../../../../utils/objects/get-property-from-path';

interface ElementProps extends FieldProps {
  activeIndex: number;
  itemIndex: number;
  setActiveIndex: (index: number) => void;
  deleteItem: (index: number) => void;
  value: NewRecruit;
}

const AddRecruit: FunctionComponent<ElementProps> = ({
  form,
  field,
  activeIndex,
  itemIndex,
  value,
  setActiveIndex,
  deleteItem,
}) => {
  const {t} = useTranslation('application');

  const [jobTitleTouched, setJobTitleTouched] = useState(false);

  useEffect(() => {
    if (itemIndex !== activeIndex) {
      setJobTitleTouched(true);
    }
  }, [activeIndex, itemIndex]);

  const isJobTitleValid = !(
    jobTitleTouched && getPropertyFromPath(form.errors, field.name)?.jobTitle
  );

  const handleEdit = () => {
    setActiveIndex(itemIndex);
  };

  const handleDelete = () => {
    deleteItem(itemIndex);
  };

  const handleCollapse = () => {
    setJobTitleTouched(true);
    setActiveIndex(-1);
  };

  const boxTitle =
    value.jobTitle ||
    t(
      'components.hierarchy-edit.components.recruits-form.new-recruit.empty-recruit-title',
      {
        index: itemIndex + 1,
      }
    );

  const boxTitleClassName = isJobTitleValid
    ? styles.boxTitle
    : `${styles.boxTitle} ${styles.errorTextColor}`;

  const collapseContainerClassName = isJobTitleValid
    ? styles.collapseContainer
    : `${styles.collapseContainer} ${styles.errorBorder}`;

  const flexLabelClassName = isJobTitleValid
    ? styles.flexLabel
    : `${styles.flexLabel} ${styles.errorTextColor}`;

  if (activeIndex !== itemIndex) {
    return (
      <>
        <div className={collapseContainerClassName}>
          <div className={styles.textContainer}>
            <div className={styles.boxHeadingContainer}>
              {!isJobTitleValid && <IconAlert className={styles.errorIcon} />}
              <div className={boxTitleClassName}>{boxTitle}</div>
            </div>

            <span className={styles.activePill}>
              {t('components.hierarchy-edit.main.custom-add-indication')}
            </span>
          </div>

          <div className={styles.action}>
            <button type="button" onClick={handleEdit}>
              <IconPenEdit className={styles.icon} />
            </button>

            <button type="button" onClick={handleDelete}>
              <IconDelete className={styles.icon} />
            </button>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className={styles.fieldBox}>
        <Field name={`${field.name}.jobTitle`}>
          {({field}: FieldProps<string>) => (
            <FormGroup className={styles.formGroup}>
              <FormControl
                error={
                  !isJobTitleValid
                    ? t(
                        'components.hierarchy-edit.components.recruits-form.new-recruit.job-title.error'
                      )
                    : undefined
                }
              >
                <Label isRequired className={flexLabelClassName}>
                  {t(
                    'components.hierarchy-edit.components.recruits-form.new-recruit.job-title.label'
                  )}
                </Label>

                <Input
                  placeholder={t(
                    'components.hierarchy-edit.components.recruits-form.new-recruit.job-title.placeholder'
                  )}
                  onBlurCapture={() => setJobTitleTouched(true)}
                  {...field}
                />
              </FormControl>
            </FormGroup>
          )}
        </Field>

        <div className={styles.buttonContainer}>
          <Button type="button" variant="secondary" onClick={handleCollapse}>
            {t(
              'components.hierarchy-edit.components.recruits-form.new-recruit.button-text'
            )}
          </Button>
        </div>
      </div>
    </>
  );
};

export default AddRecruit;
