import {FunctionComponent, useState} from 'react';
import {useTransition, animated, UseTransitionProps} from 'react-spring';
import {
  MultipleCheckboxOptions,
  Question,
  QuestionAnswerResponse,
  SevenPointLikertOptions,
  SevenPointLikertOptionsWithLabels,
} from '../../../../../interfaces/question';
import {wait} from '../../../../../utils/wait/wait';
import SevenPointLikert from '../../../../@components/kit/questionnaire/seven-point-likert';
import {AnswerMap} from '../../show';
import SevenPointLikertWithLabels from '../../../../@components/kit/questionnaire/seven-point-likert-with-labels';
import OpenEndedText from '../../../../@components/kit/questionnaire/open-ended-text';
import MultipleCheckbox from '../../../../@components/kit/questionnaire/multiple-checkbox';
import NumericalSlider from '../../../../@components/kit/questionnaire/numerical-slider';
import {
  SEVEN_POINT_LIKERT,
  SEVEN_POINT_LIKERT_WITH_LABELS,
  OPEN_ENDED_TEXT,
  NUMERICAL,
  MULTIPLE_CHECKBOX,
} from '../../../../../constants/question-type';
import styles from './styles.module.scss';

interface ElementProps {
  questionnaire: Question[];
  questionnaireAnswers: AnswerMap;
  onResponse: (response: QuestionAnswerResponse) => void;
  currentQuestionIndex: number;
  setCurrentQuestionRank: (rank: number) => void;
  onFirstQuestionChange?: () => void;
  onLastQuestionChange?: () => void;
}

const ANIMATE_FROM_RIGHT: UseTransitionProps = {
  from: {x: 500, opacity: 0},
  enter: {x: 0, opacity: 1},
  leave: {x: -500, opacity: 0},
};

const ANIMATE_FROM_LEFT: UseTransitionProps = {
  from: {x: -500, opacity: 0},
  enter: {x: 0, opacity: 1},
  leave: {x: +500, opacity: 0},
};

const DELAY = 500;

const AnimatedQuestionnaire: FunctionComponent<ElementProps> = ({
  questionnaire,
  questionnaireAnswers,
  onResponse,
  currentQuestionIndex,
  setCurrentQuestionRank,
  onFirstQuestionChange,
  onLastQuestionChange,
}) => {
  const [questionTransitionConfig, setQuestionTransitionConfig] =
    useState<UseTransitionProps>(ANIMATE_FROM_RIGHT);

  const questionTransition = useTransition(
    [currentQuestionIndex],
    questionTransitionConfig
  );

  const handleNextQuestion = async () => {
    await wait(DELAY);

    setCurrentQuestionRank(currentQuestionIndex + 1);

    if (questionTransitionConfig === ANIMATE_FROM_LEFT) {
      setQuestionTransitionConfig(ANIMATE_FROM_RIGHT);
    }

    if (currentQuestionIndex === 0) {
      onFirstQuestionChange?.();
    }

    if (currentQuestionIndex === questionnaire.length - 1) {
      onLastQuestionChange?.();
      setCurrentQuestionRank(0);
    }
  };

  const handlePreviousQuestion = async () => {
    setCurrentQuestionRank(currentQuestionIndex - 1);

    if (questionTransitionConfig === ANIMATE_FROM_RIGHT) {
      setQuestionTransitionConfig(ANIMATE_FROM_LEFT);
    }

    if (currentQuestionIndex === 0) {
      onFirstQuestionChange?.();
      return;
    }

    if (currentQuestionIndex === questionnaire.length - 1) {
      onLastQuestionChange?.();
    }
  };

  const handleResponse = (value: string, currentQuestionUuid: string) => {
    onResponse({
      questionUuid: currentQuestionUuid,
      answer: value,
    });
    handleNextQuestion();
  };

  return (
    <div className={styles.questionsContainer}>
      {questionTransition((style, currentQuestion) => (
        <animated.div className={styles.question} style={style}>
          {questionnaire[currentQuestion].type === SEVEN_POINT_LIKERT && (
            <SevenPointLikert
              questionText={questionnaire[currentQuestion].title}
              lowerBandText={
                (
                  questionnaire[currentQuestion]
                    .options as SevenPointLikertOptions
                ).leftBandText || ''
              }
              upperBandText={
                (
                  questionnaire[currentQuestion]
                    .options as SevenPointLikertOptions
                ).rightBandText || ''
              }
              onPreviousLinkClick={
                currentQuestionIndex === 0 ? undefined : handlePreviousQuestion
              }
              onResponse={(value: string) => {
                handleResponse(value, questionnaire[currentQuestion].uuid);
              }}
              answer={questionnaireAnswers[questionnaire[currentQuestion].uuid]}
            />
          )}

          {questionnaire[currentQuestion].type ===
            SEVEN_POINT_LIKERT_WITH_LABELS && (
            <SevenPointLikertWithLabels
              questionText={questionnaire[currentQuestion].title}
              options={
                questionnaire[currentQuestion]
                  .options as SevenPointLikertOptionsWithLabels
              }
              onPreviousLinkClick={
                currentQuestionIndex === 0 ? undefined : handlePreviousQuestion
              }
              onResponse={(value: string) => {
                handleResponse(value, questionnaire[currentQuestion].uuid);
              }}
              answer={questionnaireAnswers[questionnaire[currentQuestion].uuid]}
            />
          )}

          {questionnaire[currentQuestion].type === OPEN_ENDED_TEXT && (
            <OpenEndedText
              questionText={questionnaire[currentQuestion].title}
              onPreviousLinkClick={
                currentQuestionIndex === 0 ? undefined : handlePreviousQuestion
              }
              onResponse={(value: string) => {
                handleResponse(value, questionnaire[currentQuestion].uuid);
              }}
              answer={questionnaireAnswers[questionnaire[currentQuestion].uuid]}
            />
          )}

          {questionnaire[currentQuestion].type === MULTIPLE_CHECKBOX && (
            <MultipleCheckbox
              questionText={questionnaire[currentQuestion].title}
              options={
                questionnaire[currentQuestion]
                  .options as MultipleCheckboxOptions
              }
              onPreviousLinkClick={
                currentQuestionIndex === 0 ? undefined : handlePreviousQuestion
              }
              onResponse={(value: string) => {
                handleResponse(value, questionnaire[currentQuestion].uuid);
              }}
              answer={questionnaireAnswers[questionnaire[currentQuestion].uuid]}
            />
          )}

          {questionnaire[currentQuestion].type === NUMERICAL && (
            <NumericalSlider
              questionText={questionnaire[currentQuestion].title}
              onPreviousLinkClick={
                currentQuestionIndex === 0 ? undefined : handlePreviousQuestion
              }
              onResponse={(value: string) => {
                handleResponse(value, questionnaire[currentQuestion].uuid);
              }}
              answer={questionnaireAnswers[questionnaire[currentQuestion].uuid]}
            />
          )}
        </animated.div>
      ))}
    </div>
  );
};

export default AnimatedQuestionnaire;
