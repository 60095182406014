import {UserReport} from '../../../../../../../../interfaces/user-report';
import {useTranslation} from 'react-i18next';
import {FunctionComponent} from 'react';
import Table from '../../../../../../../@components/table';
import TableRow from './@components/table-row';

interface ElementProps {
  reports: UserReport[];
}

const ListView: FunctionComponent<ElementProps> = ({reports}) => {
  const {t} = useTranslation('measure');

  return (
    <Table>
      <Table.Head>
        <Table.Row>
          <Table.Header variant="large">
            {t(
              'your-reports.components.reports.components.list-view.table.header.assessment-name'
            )}
          </Table.Header>

          <Table.Header variant="medium">
            {t(
              'your-reports.components.reports.components.list-view.table.header.start-date'
            )}
          </Table.Header>

          <Table.Header variant="medium">
            {t(
              'your-reports.components.reports.components.list-view.table.header.actions'
            )}
          </Table.Header>
        </Table.Row>
      </Table.Head>

      <Table.Content>
        {reports.map((report, index) => (
          <TableRow report={report} key={index} />
        ))}
      </Table.Content>
    </Table>
  );
};

export default ListView;
