import {FunctionComponent} from 'react';
import {UserActionStep} from '../../../../../../interfaces/action-step';
import Dialog from '../../../../../@components/kit/dialog';
import ActionStepDetails from './@component/action-step-details/action-step-details';

interface ElementProps {
  handleDialogClose: () => void;
  isDialogVisible: boolean;
  onRefreshActionSteps: () => void;
  actionStep: UserActionStep;
}

const ActionStepDialog: FunctionComponent<ElementProps> = ({
  handleDialogClose,
  isDialogVisible,
  actionStep,
  onRefreshActionSteps,
}) => {
  if (!actionStep) return null;

  return (
    <Dialog isOpen={isDialogVisible} onClose={handleDialogClose}>
      <Dialog.Header title={actionStep.title} />

      <Dialog.Content>
        <ActionStepDetails
          handleDialogClose={handleDialogClose}
          actionStep={actionStep}
          onRefreshActionSteps={onRefreshActionSteps}
        />
      </Dialog.Content>
    </Dialog>
  );
};

export default ActionStepDialog;
