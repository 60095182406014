import {Actions} from '../../../../../../../../../../constants/permissions/actions';
import {CompanyEmployee} from '../../../../../../../../../../interfaces/company-employee';
import {FunctionComponent, useState} from 'react';
import {getUserProfileForCompany} from '../../../../../../../../../../services/user-profiles';
import {ReactComponent as IconDelete} from '../../../../../../../../../@components/kit/icons/trash.svg';
import {ReactComponent as IconPenEdit} from '../../../../../../../../../@components/kit/icons/edit.svg';
import {Team, TeamUpsert} from '../../../../../../../../../../interfaces/team';
import {useCurrentAuthUserState} from '../../../../../../../../../@atoms/current-auth-user';
import {useSafeCurrentCompany} from '../../../../../../../../../@atoms/current-company';
import {useTranslation} from 'react-i18next';
import {useUserPermission} from '../../../../../../../../../@hooks/use-user-permission';
import Button from '../../../../../../../../../@components/kit/form/button';
import Dialog from '../../../../../../../../../@components/kit/dialog';
import styles from './styles.module.scss';
import TeamSummary from './@components/team-summary';
import UpsertTeamForm from '../../../upsert-team-form';

interface ElementProps {
  companyEmployees: CompanyEmployee[];
  isVisible: boolean;
  onClose: () => void;
  onDeleteTeam: () => void;
  onUpdateTeam: (values: TeamUpsert) => void;
  team: Team;
}

const DetailsDialog: FunctionComponent<ElementProps> = ({
  companyEmployees,
  isVisible,
  onClose,
  onDeleteTeam,
  onUpdateTeam,
  team,
}) => {
  const {t} = useTranslation('people', {
    keyPrefix:
      'teams.components.team-list.components.list-view.components.details-dialog',
  });

  const [isInEditionMode, setIsInEditionMode] = useState(false);

  const currentCompany = useSafeCurrentCompany();
  const [currentAuthUser] = useCurrentAuthUserState();

  const userProfile = getUserProfileForCompany(
    currentAuthUser!,
    currentCompany
  );

  const can = useUserPermission(userProfile.role);

  return (
    <Dialog isOpen={isVisible} onClose={onClose}>
      <Dialog.Header>
        <div className={styles.header}>
          <div className={styles.titleContainer}>
            <div
              className={styles.colorTag}
              style={{backgroundColor: team.colorHex}}
            />

            <div className={styles.title}>{team.name}</div>
          </div>

          {!isInEditionMode && (
            <div>
              {can(Actions.EDIT_TEAM) && (
                <Button
                  aria-label={t('edit-team-accessibility-text', {
                    teamName: team.name,
                  })}
                  className={styles.button}
                  onClick={() => setIsInEditionMode(true)}
                >
                  <IconPenEdit className={styles.editIcon} />
                </Button>
              )}

              {can(Actions.DELETE_TEAM) && (
                <Button
                  aria-label={t('delete-team-accessibility-text', {
                    teamName: team.name,
                  })}
                  className={styles.button}
                  onClick={onDeleteTeam}
                >
                  <IconDelete className={styles.deleteIcon} />
                </Button>
              )}
            </div>
          )}
        </div>
      </Dialog.Header>

      <Dialog.Content>
        {isInEditionMode ? (
          <UpsertTeamForm
            companyEmployees={companyEmployees}
            onClose={() => setIsInEditionMode(false)}
            onSubmit={onUpdateTeam}
            team={team}
          />
        ) : (
          <TeamSummary team={team} />
        )}
      </Dialog.Content>
    </Dialog>
  );
};

export default DetailsDialog;
