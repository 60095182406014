import {buildPageTitle} from '../../../services/build-page-title';
import {EventName} from '../../../constants/analytics/event-name';
import {EventType} from '../../../constants/analytics/event-type';
import {FunctionComponent} from 'react';
import {Helmet} from 'react-helmet-async';
import {useCurrentAuthUserState} from '../../@atoms/current-auth-user';
import {useEffectOnce} from '../../@hooks/use-effect-once';
import {useNavigate} from 'react-router';
import {useTranslation} from 'react-i18next';
import CompanyList from './@components/company-list';
import FeedbackBanner from '../../@components/feedback-banner';
import Header from '../@components/header';
import mainContainerStyles from '../../../styles/classes/main-container.module.scss';
import MainContent from '../@components/main-content';
import RoundedCard from '../../@components/rounded-card';
import useAnalytics from '../../@hooks/use-analytics';

const AdminPortal: FunctionComponent = () => {
  const {t} = useTranslation(['adminPortal', 'applicationPageTitle']);

  const [currentAuthUser] = useCurrentAuthUserState();
  const navigate = useNavigate();
  const {trackEvent} = useAnalytics();

  useEffectOnce(() => {
    trackEvent({
      eventName: EventName.PAGE_VIEWS.ADMIN_PORTAL,
      eventType: EventType.PAGE_VIEW,
    });
  });

  if (!currentAuthUser?.isSuperAdmin) {
    navigate('/application/dashboard');
    return null;
  }

  return (
    <>
      <Helmet>
        <title>
          {buildPageTitle(
            t('page-title.admin-portal', {ns: 'applicationPageTitle'})
          )}
        </title>
      </Helmet>

      <FeedbackBanner text={t('components.feedback-banner.text')} />

      <Header title={t('components.header.title')} />

      <MainContent>
        <main className={mainContainerStyles.container}>
          <RoundedCard>
            <CompanyList />
          </RoundedCard>
        </main>
      </MainContent>
    </>
  );
};

export default AdminPortal;
