import {CompanyEmployee} from '../../../../../../../../interfaces/company-employee';
import {DIRECTORY_EMPLOYEES_SORTABLE_COLUMNS} from '../../../../../../../../constants/filtering/sort-by';
import {FunctionComponent} from 'react';
import {SortedColumn} from '../../../../../../../../interfaces/filtering/sorted-column';
import {useTranslation} from 'react-i18next';
import SortButton from '../../../../../../@components/sort-button';
import styles from './styles.module.scss';
import Table from '../../../../../../../@components/table';
import TableRow from './@components/table-row';

interface ElementProps {
  activeSortedColumn: SortedColumn | null;
  employees: CompanyEmployee[];
  onColumnClicked: (column: SortedColumn) => void;
  onSendReminder: (workEmail: string) => void;
}

const List: FunctionComponent<ElementProps> = ({
  activeSortedColumn,
  employees,
  onColumnClicked,
  onSendReminder,
}) => {
  const {t} = useTranslation('people');

  return (
    <Table>
      <Table.Head className={styles.tableHeader}>
        <Table.Row>
          <Table.Header variant="small">
            <SortButton
              currentColumnName={
                DIRECTORY_EMPLOYEES_SORTABLE_COLUMNS.PERMISSION_LEVEL
              }
              currentColumnLabel={t(
                'manage-directory.components.employee-list.components.list.table.header.permissions'
              )}
              onColumnClicked={onColumnClicked}
              activeSortedColumn={activeSortedColumn}
            />
          </Table.Header>

          <Table.Header variant="nameColumn">
            <SortButton
              currentColumnName={DIRECTORY_EMPLOYEES_SORTABLE_COLUMNS.NAME}
              currentColumnLabel={t(
                'manage-directory.components.employee-list.components.list.table.header.name'
              )}
              onColumnClicked={onColumnClicked}
              activeSortedColumn={activeSortedColumn}
            />
          </Table.Header>

          <Table.Header variant="medium">
            <SortButton
              currentColumnName={DIRECTORY_EMPLOYEES_SORTABLE_COLUMNS.JOB_TITLE}
              currentColumnLabel={t(
                'manage-directory.components.employee-list.components.list.table.header.role'
              )}
              onColumnClicked={onColumnClicked}
              activeSortedColumn={activeSortedColumn}
            />
          </Table.Header>

          <Table.Header variant="large">
            <SortButton
              currentColumnName={DIRECTORY_EMPLOYEES_SORTABLE_COLUMNS.TEAM}
              currentColumnLabel={t(
                'manage-directory.components.employee-list.components.list.table.header.team'
              )}
              onColumnClicked={onColumnClicked}
              activeSortedColumn={activeSortedColumn}
            />
          </Table.Header>

          <Table.Header variant="small">
            <SortButton
              currentColumnName={DIRECTORY_EMPLOYEES_SORTABLE_COLUMNS.IS_ACTIVE}
              currentColumnLabel={t(
                'manage-directory.components.employee-list.components.list.table.header.is-active'
              )}
              onColumnClicked={onColumnClicked}
              activeSortedColumn={activeSortedColumn}
            />
          </Table.Header>

          <Table.Header variant="small">
            <SortButton
              currentColumnName={
                DIRECTORY_EMPLOYEES_SORTABLE_COLUMNS.JOINED_DATE
              }
              currentColumnLabel={t(
                'manage-directory.components.employee-list.components.list.table.header.date-joined'
              )}
              onColumnClicked={onColumnClicked}
              activeSortedColumn={activeSortedColumn}
            />
          </Table.Header>
        </Table.Row>
      </Table.Head>

      <Table.Content>
        {employees.map((employee) => (
          <TableRow
            key={`${employee.name}–${crypto.randomUUID()}`}
            onSendReminder={onSendReminder}
            employee={employee}
          />
        ))}
      </Table.Content>
    </Table>
  );
};

export default List;
