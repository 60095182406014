import InstillAPI from '../axios-instance';

export interface DeleteTeamPayload {
  companyUuid: string;
  teamUuid: string;
}

export const deleteTeam = async (deleteTeamPayload: DeleteTeamPayload) => {
  const {companyUuid, teamUuid} = deleteTeamPayload;

  const response = await InstillAPI.delete(
    `/companies/${companyUuid}/teams/${teamUuid}`
  );

  return response;
};
