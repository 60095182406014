import {FunctionComponent, HTMLAttributes} from 'react';
import styles from './styles.module.scss';

const VARIANT_LARGE = 'large';

type SpinnerProps = HTMLAttributes<HTMLButtonElement> & {
  variant?: typeof VARIANT_LARGE;
  fullScreen?: boolean;
};

const Spinner: FunctionComponent<SpinnerProps> = ({variant, fullScreen}) => {
  const variantClass = variant ? styles[variant] : styles.loader;

  return fullScreen ? (
    <div className={styles.fullScreenContainer}>
      <span className={styles.large} />
    </div>
  ) : (
    <div className={styles.container}>
      <span className={variantClass} />
    </div>
  );
};

export default Spinner;
