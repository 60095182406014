import {COMPANY_INDUSTRY_OPTIONS} from '../../../../../../../constants/company-industry-options';
import {COMPANY_SIZE_OPTIONS} from '../../../../../../../constants/company-size-options';
import {CompanyUpsert} from '../../../../../../../interfaces/admin/company';
import {Field, FieldProps, useFormikContext} from 'formik';
import {FunctionComponent, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import Button from '../../../../../../@components/kit/form/button';
import FormControl from '../../../../../../@components/kit/form/form-control';
import FormFooter from '../../../../../../@components/kit/form/form-footer';
import FormGroup from '../../../../../../@components/kit/form/form-group';
import Input from '../../../../../../@components/kit/form/input';
import Label from '../../../../../../@components/kit/form/label';
import Select from '../../../../../../@components/kit/form/select';

interface ElementProps {
  onClose: () => void;
  isInEditionMode?: boolean;
}

const UpsertCompanyForm: FunctionComponent<ElementProps> = ({
  onClose,
  isInEditionMode = false,
}) => {
  const {t} = useTranslation('adminPortal');

  const {errors, values, isValid, initialValues, touched} =
    useFormikContext<CompanyUpsert>();

  const submitButtonTranslation = useMemo(() => {
    let translationKey =
      'components.company-list.components.upsert-company-form.create-company-button';

    if (isInEditionMode) {
      translationKey =
        'components.company-list.components.upsert-company-form.update-company-button';
    }

    return t(translationKey, {ns: 'adminPortal'});
  }, [isInEditionMode, t]);

  const isSubmitButtonDisabled = useMemo(() => {
    const someValuesHaveChanged = Object.keys(initialValues).some((key) => {
      return (
        initialValues[key as keyof typeof initialValues] !==
        values[key as keyof typeof values]
      );
    });

    if (!someValuesHaveChanged) return true;

    return !isValid;
  }, [initialValues, values, isValid]);

  return (
    <>
      <Field name="name">
        {({field}: FieldProps<string>) => (
          <FormGroup>
            <FormControl
              error={
                Boolean(errors.name && touched.name) ? errors.name : undefined
              }
            >
              <Label isRequired>
                {t(
                  'components.company-list.components.upsert-company-form.label.name',
                  {
                    ns: 'adminPortal',
                  }
                )}
              </Label>

              <Input
                type="text"
                placeholder={t('constants.company-profile.placeholder.name', {
                  ns: 'common',
                })}
                {...field}
              />
            </FormControl>
          </FormGroup>
        )}
      </Field>

      <Field name="industry">
        {({field}: FieldProps<string>) => (
          <FormGroup>
            <FormControl
              error={
                Boolean(errors.industry && touched.industry)
                  ? errors.industry
                  : undefined
              }
            >
              <Label isRequired>
                {t(
                  'components.company-list.components.upsert-company-form.label.industry',
                  {
                    ns: 'adminPortal',
                  }
                )}
              </Label>

              <Select id={field.name} {...field}>
                <option value="" disabled>
                  {t('constants.company-profile.placeholder.industry', {
                    ns: 'common',
                  })}
                </option>

                {COMPANY_INDUSTRY_OPTIONS.map(({label, value}) => (
                  <option key={value} value={value}>
                    {t(label, {ns: 'common'})}
                  </option>
                ))}
              </Select>
            </FormControl>
          </FormGroup>
        )}
      </Field>

      <Field name="size">
        {({field}: FieldProps<string>) => (
          <FormGroup>
            <FormControl
              error={
                Boolean(errors.size && touched.size)
                  ? errors.industry
                  : undefined
              }
            >
              <Label isRequired>
                {t(
                  'components.company-list.components.upsert-company-form.label.size',
                  {
                    ns: 'adminPortal',
                  }
                )}
              </Label>

              <Select id={field.name} {...field}>
                <option value="" disabled>
                  {t('constants.company-profile.placeholder.size', {
                    ns: 'common',
                  })}
                </option>

                {COMPANY_SIZE_OPTIONS.map(({label, value}) => (
                  <option key={value} value={value}>
                    {t(label, {ns: 'common'})}
                  </option>
                ))}
              </Select>
            </FormControl>
          </FormGroup>
        )}
      </Field>

      <Field name="websiteUrl">
        {({field}: FieldProps<string>) => (
          <FormGroup>
            <FormControl
              error={
                Boolean(errors.websiteUrl && touched.websiteUrl)
                  ? errors.websiteUrl
                  : undefined
              }
            >
              <Label>
                {t(
                  'components.company-list.components.upsert-company-form.label.website',
                  {
                    ns: 'adminPortal',
                  }
                )}
              </Label>

              <Input
                type="text"
                placeholder={t(
                  'constants.company-profile.placeholder.website',
                  {
                    ns: 'common',
                  }
                )}
                {...field}
              />
            </FormControl>
          </FormGroup>
        )}
      </Field>

      <Field name="emailDomain">
        {({field}: FieldProps<string>) => (
          <FormGroup>
            <FormControl
              error={
                Boolean(errors.emailDomain && touched.emailDomain)
                  ? errors.emailDomain
                  : undefined
              }
            >
              <Label isRequired>
                {t(
                  'components.company-list.components.upsert-company-form.label.email-domain',
                  {
                    ns: 'adminPortal',
                  }
                )}
              </Label>

              <Input
                type="text"
                placeholder={t(
                  'constants.company-profile.placeholder.email-domain',
                  {
                    ns: 'common',
                  }
                )}
                {...field}
              />
            </FormControl>
          </FormGroup>
        )}
      </Field>

      <FormFooter>
        <Button disabled={isSubmitButtonDisabled} type="submit">
          {submitButtonTranslation}
        </Button>

        <Button variant="secondary" onClick={onClose}>
          {t(
            'components.company-list.components.upsert-company-form.cancel-button',
            {
              ns: 'adminPortal',
            }
          )}
        </Button>
      </FormFooter>
    </>
  );
};

export default UpsertCompanyForm;
