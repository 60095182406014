import {
  JobPositionEndedPayload,
  JobPositionEndedRawPayload,
} from '../../../interfaces/notifications/job-position-ended';

const sanitizePayloadFromRaw = (
  data: JobPositionEndedRawPayload
): JobPositionEndedPayload => {
  return {
    jobPositionTitle: data.job_position_title,
  };
};

export default sanitizePayloadFromRaw;
