import {sanitizeKudoFromRaw} from '../../../ui/@sanitizers/kudo';
import InstillAPI from '../axios-instance';
import isResponseSuccessful from '../../../utils/is-response-successful';

export interface FetchKudoPayload {
  companyUuid: string;
  kudoUuid?: string;
}

export async function fetchKudo(parameters: FetchKudoPayload) {
  const {companyUuid, kudoUuid} = parameters;
  const url = `/companies/${companyUuid}/kudos/${kudoUuid}`;

  try {
    const response = await InstillAPI.get(url);

    if (!response.data || !isResponseSuccessful(response)) {
      throw Error('No data received');
    }

    return sanitizeKudoFromRaw(response.data);
  } catch (error) {
    console.error(error);
  }
}
