import {FunctionComponent, PropsWithChildren, useState} from 'react';
import {useTransition, animated} from 'react-spring';
import Content from './content';
import DialogContext from './dialog-context';
import Header from './header';
import Modal from '../../../@components/modal';
import styles from './styles.module.scss';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  variant?: 'small' | 'medium' | 'large' | 'xlarge';
}

interface DialogComponent extends FunctionComponent<PropsWithChildren<Props>> {
  Header: typeof Header;
  Content: typeof Content;
}

const Dialog: DialogComponent = ({isOpen, variant, onClose, children}) => {
  const [container, setContainer] = useState<HTMLElement | null>(null);

  const transitions = useTransition(isOpen, {
    from: {
      transform: 'translateY(100%)',
      opacity: 0,
    },
    enter: {
      transform: 'translateY(0)',
      opacity: 1,
    },
    leave: {
      transform: 'translateY(100px)',
      opacity: 0,
    },
  });

  return (
    <DialogContext.Provider value={{onClose}}>
      <Modal isOpen={isOpen} clickOutsideElement={container} onClose={onClose}>
        {transitions(
          (props, item) =>
            item && (
              <div className={styles.wrapper}>
                <animated.div
                  className={`${styles.container} ${
                    variant ? styles[variant] : ''
                  }`}
                  style={props}
                >
                  <div className={styles.innerContainer} ref={setContainer}>
                    {children}
                  </div>
                </animated.div>
              </div>
            )
        )}
      </Modal>
    </DialogContext.Provider>
  );
};

Dialog.Header = Header;
Dialog.Content = Content;

export default Dialog;
