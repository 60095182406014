import {CompanyEmployee} from '../../../../../../interfaces/company-employee';
import {EventType} from '../../../../../../constants/analytics/event-type';
import {Field, FieldProps} from 'formik';
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {getPropertyFromPath} from '../../../../../../utils/objects/get-property-from-path';
import {
  MutableSelect,
  Option,
} from '../../../../../@components/kit/form/hybrid-select/mutable-select';
import {NewUser} from '../../../../../../interfaces/map-your-organization';
import {ReactComponent as IconAlert} from '../../../../../@components/kit/icons/alert-circle.svg';
import {ReactComponent as IconDelete} from '../../../../../@components/kit/icons/trash.svg';
import {ReactComponent as IconPenEdit} from '../../../../../@components/kit/icons/edit.svg';
import {useTranslation} from 'react-i18next';
import AvatarPlaceholder from '../../../../../@components/avatar-placeholder';
import Button from '../../../../../@components/kit/form/button';
import FormControl from '../../../../../@components/kit/form/form-control';
import FormGroup from '../../../../../@components/kit/form/form-group/form-group';
import Input from '../../../../../@components/kit/form/input/input';
import Label from '../../../../../@components/kit/form/label/label';
import styles from './styles.module.scss';
import useAnalytics from '../../../../../@hooks/use-analytics';

interface ElementProps extends FieldProps {
  activeIndex?: number;
  deleteDynamicItem?: (index: number) => void;
  deleteItem?: () => void;
  isCollapsedByDefault?: boolean;
  itemIndex?: number;
  onAddItemEventName?: string;
  options: CompanyEmployee[];
  setActiveIndex?: (index: number) => void;
  value: NewUser | null;
}

const UserDropdown: FunctionComponent<ElementProps> = ({
  activeIndex,
  deleteDynamicItem,
  deleteItem,
  field,
  form,
  isCollapsedByDefault,
  itemIndex,
  onAddItemEventName,
  options,
  setActiveIndex,
  value,
}) => {
  const {t} = useTranslation('application');

  const [nameTouched, setNameTouched] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);
  const [selectedOption, setSelectedOption] = useState<CompanyEmployee | null>(
    null
  );
  const [isCollapsed, setIsCollapsed] = useState(isCollapsedByDefault || false);

  const {trackEvent} = useAnalytics();

  useEffect(() => {
    if (itemIndex !== activeIndex) {
      setNameTouched(true);
      setEmailTouched(true);
    }
  }, [activeIndex, itemIndex]);

  const isNameValid = !(
    nameTouched && getPropertyFromPath(form.errors, field.name)?.name
  );

  const isEmailValid = !(
    emailTouched && getPropertyFromPath(form.errors, field.name)?.workEmail
  );

  const isBoxValid = isNameValid && isEmailValid;

  const isDynamic =
    activeIndex !== undefined &&
    itemIndex !== undefined &&
    setActiveIndex !== undefined &&
    deleteDynamicItem !== undefined;

  const formattedUserListByName = useMemo(() => {
    return options.map((user: CompanyEmployee) => ({
      label: user.name,
      value: user.name,
      payload: {
        workEmail: user.workEmail,
      },
    }));
  }, [options]);

  const optionSelectCallback = useCallback(
    (option: Option) => {
      setEmailTouched(false);

      if (!option.__isNew__) {
        const selectedProfile = options.find(
          (profile: CompanyEmployee) =>
            profile.workEmail === option.payload.workEmail
        );
        if (selectedProfile) {
          setSelectedOption(selectedProfile);
        }
      }

      form.setFieldValue(
        `${field.name}.workEmail`,
        option.payload?.workEmail || null
      );
    },
    [field, form, options]
  );

  const handleEdit = useCallback(() => {
    setIsCollapsed(false);

    if (isDynamic) {
      setActiveIndex(itemIndex);
    }
  }, [isDynamic, itemIndex, setActiveIndex]);

  const handleDelete = useCallback(() => {
    if (isDynamic) {
      deleteDynamicItem(itemIndex);
    } else if (deleteItem) {
      deleteItem();
    }
  }, [deleteItem, deleteDynamicItem, itemIndex, isDynamic]);

  const handleCollapse = useCallback(() => {
    setNameTouched(true);
    setEmailTouched(true);

    setIsCollapsed(true);

    if (onAddItemEventName) {
      trackEvent({
        eventName: onAddItemEventName,
        eventType: EventType.BUTTON_CLICKED,
      });
    }

    if (isDynamic) {
      setActiveIndex(-1);
    }
  }, [trackEvent, onAddItemEventName, setActiveIndex, isDynamic]);

  const defaultName = isDynamic
    ? t(
        'components.hierarchy-edit.components.new-user.empty-person-title-with-index',
        {
          index: itemIndex + 1,
        }
      )
    : t('components.hierarchy-edit.components.new-user.empty-person-title');

  const boxTitle = value?.name || defaultName;

  const boxTitleClassName = isBoxValid
    ? styles.boxTitle
    : `${styles.boxTitle} ${styles.errorTextColor}`;

  const collapseContainerClassName = isBoxValid
    ? styles.collapseContainer
    : `${styles.collapseContainer} ${styles.errorBorder}`;

  if ((isDynamic && activeIndex !== itemIndex) || isCollapsed) {
    return (
      <>
        <div className={collapseContainerClassName}>
          <div>
            {selectedOption && selectedOption.picture ? (
              <img
                src={selectedOption.picture}
                alt=""
                className={styles.picture}
              />
            ) : (
              <AvatarPlaceholder name={value?.name ? value.name : ''} />
            )}
          </div>

          <div className={styles.textContainer}>
            <div className={styles.boxHeadingContainer}>
              {!isBoxValid && <IconAlert className={styles.errorIcon} />}

              <div className={boxTitleClassName}>{boxTitle}</div>
            </div>

            <span className={styles.activePill}>
              {t('components.hierarchy-edit.main.custom-add-indication')}
            </span>
          </div>

          <div className={styles.action}>
            <button type="button" onClick={handleEdit}>
              <IconPenEdit className={styles.icon} />
            </button>

            {isDynamic && (
              <button type="button" onClick={handleDelete}>
                <IconDelete className={styles.icon} />
              </button>
            )}
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className={styles.fieldBox}>
        <Field name={`${field.name}.name`}>
          {({field, form, meta}: FieldProps<string>) => (
            <FormGroup className={styles.formGroup}>
              <FormControl
                error={
                  !isNameValid
                    ? t(
                        'components.hierarchy-edit.components.new-user.name.error'
                      )
                    : undefined
                }
              >
                <div className={styles.innerDeleteRow}>
                  <Label isRequired className={styles.flexLabel}>
                    {t(
                      'components.hierarchy-edit.components.new-user.name.label'
                    )}
                  </Label>

                  <button type="button" onClick={handleDelete}>
                    <IconDelete className={styles.icon} />
                  </button>
                </div>

                <MutableSelect
                  field={field}
                  form={form}
                  meta={meta}
                  defaultValue={{
                    value: (value as NewUser).name,
                    label: (value as NewUser).name,
                  }}
                  options={formattedUserListByName}
                  placeholder={t(
                    'components.hierarchy-edit.components.new-user.name.placeholder'
                  )}
                  createLabel={t(
                    'components.hierarchy-edit.components.new-user.create-label'
                  )}
                  isMulti={false}
                  onBlurCapture={() => setNameTouched(true)}
                  onChangeCallback={optionSelectCallback}
                />
              </FormControl>
            </FormGroup>
          )}
        </Field>

        <Field name={`${field.name}.workEmail`}>
          {({field}: FieldProps<string>) => (
            <FormGroup className={styles.formGroup}>
              <FormControl
                error={
                  !isEmailValid
                    ? t(
                        'components.hierarchy-edit.components.new-user.email.error'
                      )
                    : undefined
                }
              >
                <Label isRequired className={styles.flexLabel}>
                  {t(
                    'components.hierarchy-edit.components.new-user.email.label'
                  )}
                </Label>

                <Input
                  placeholder={t(
                    'components.hierarchy-edit.components.new-user.email.placeholder'
                  )}
                  onBlurCapture={() => {
                    setEmailTouched(true);
                  }}
                  {...field}
                />
              </FormControl>

              <div className={styles.buttonContainer}>
                <Button
                  type="button"
                  variant="secondary"
                  onClick={handleCollapse}
                >
                  {t(
                    'components.hierarchy-edit.components.new-user.button-text'
                  )}
                </Button>
              </div>
            </FormGroup>
          )}
        </Field>
      </div>
    </>
  );
};

export default UserDropdown;
