import {createMutation} from 'react-query-kit';
import {
  JoinCompanyPayload,
  joinCompany,
} from '../../../repositories/instill/mutations/join-company';
import {Company} from '../../../interfaces/company';

export const useJoinCompany = createMutation<
  Company | undefined,
  JoinCompanyPayload
>({
  mutationFn: async (variables) => {
    return await joinCompany(variables.inviteToken);
  },
});
