import React, {useEffect, useRef} from 'react';
import {Box, Flex as F, Text} from 'rebass';
import {motion} from 'framer-motion';
import styles from './styles.module.scss';
import {useTranslation} from 'react-i18next';

type AnswerType = string | number | {label: string} | {label: string}[];

interface ChatGroupProps {
  pretext?: string;
  question: string;
  children: React.ReactNode;
  state: Record<string, AnswerType>;
  name: string;
  noScroll?: boolean;
  renderAnswer?: (answer: AnswerType) => string;
  scrollParent?: React.RefObject<HTMLElement>;
  finaltext?: string;
}

const Flex = motion(F);

const ChatGroup: React.FC<ChatGroupProps> = ({
  pretext,
  question,
  children,
  state,
  name,
  noScroll,
  renderAnswer,
  scrollParent,
  finaltext,
}) => {
  const myRef = useRef<HTMLDivElement>(null);
  const defaultRenderAnswer = (answer: AnswerType): string => {
    if (typeof answer === 'string' || typeof answer === 'number')
      return answer as string;
    if (Array.isArray(answer)) {
      if (answer.length === 0) return 'None';
      if (answer.length === 1) return answer[0].label;
      return answer.reduce((acc, {label}, index, array) => {
        return (acc +=
          index === array.length - 1
            ? `& ${label}.`
            : index === array.length - 2
            ? `${label} `
            : `${label}, `);
      }, '');
    }
    if (typeof answer === 'object') return answer.label;
    return '';
  };

  useEffect(() => {
    if (!noScroll) {
      if (!scrollParent) {
        window.scrollTo({
          top: myRef.current?.offsetTop ?? 0,
          left: 0,
          behavior: 'smooth',
        });
      } else {
        scrollParent.current?.scrollTo({
          top: myRef.current?.offsetTop ?? 0,
          left: 0,
          behavior: 'smooth',
        });
      }
    }
  }, [noScroll, scrollParent]);

  return (
    <div ref={myRef} className={styles.chatGroup}>
      {pretext && <Pretext name={name} text={pretext} />}
      <Question key={name} name={name} question={question} />
      {!state[name] && (
        <Flex
          width="100%"
          initial={{opacity: 0, x: -10}}
          animate={{
            opacity: 1,
            x: 0,
            transition: {
              delay: 0.6,
            },
          }}
          transition={{duration: 0.5}}
        >
          <Box width="100%">{children}</Box>
        </Flex>
      )}
      {state[name] && (
        <Answer
          answer={
            renderAnswer
              ? renderAnswer(state[name])
              : defaultRenderAnswer(state[name])
          }
        />
      )}
      {finaltext && state[name] && (
        <FinalText
          name={name}
          text={finaltext}
          noScroll={noScroll}
          scrollParent={scrollParent}
          myRef={myRef}
        />
      )}
    </div>
  );
};

interface PretextProps {
  name: string;
  text: string;
}

const Pretext: React.FC<PretextProps> = ({name, text}) => {
  return (
    <Flex
      key={name}
      width="100%"
      initial={{opacity: 0, x: -10}}
      animate={{
        opacity: 1,
        x: 0,
        transition: {
          delay: 0.4,
        },
      }}
      transition={{duration: 0.5}}
    >
      <Box maxWidth={['90%', '80%']} variant="pretext">
        <Text variant="question">{text}</Text>
      </Box>
    </Flex>
  );
};

interface FinalTextProps extends PretextProps {
  myRef: React.RefObject<HTMLDivElement>;
  scrollParent?: React.RefObject<HTMLElement>;
  noScroll?: boolean;
}

const FinalText: React.FC<FinalTextProps> = ({
  myRef,
  scrollParent,
  noScroll,
  ...props
}) => {
  useEffect(() => {
    if (!noScroll) {
      if (!scrollParent) {
        window.scrollTo({
          top: myRef.current?.offsetTop ?? 0,
          left: 0,
          behavior: 'smooth',
        });
      } else {
        scrollParent.current?.scrollTo({
          top: myRef.current?.offsetTop ?? 0,
          left: 0,
          behavior: 'smooth',
        });
      }
    }
  }, [noScroll, scrollParent, myRef]);
  return <Pretext {...props} />;
};

interface QuestionProps {
  name: string;
  question: string;
}

const Question: React.FC<QuestionProps> = ({name, question}) => {
  const {t} = useTranslation('components', {keyPrefix: 'chat'});

  return (
    <Flex
      key={name}
      width="100%"
      initial={{opacity: 0, x: -10}}
      animate={{
        opacity: 1,
        x: 0,
        transition: {
          delay: 0.5,
        },
      }}
      transition={{duration: 0.5}}
    >
      <div className={styles.questionContainer}>
        <span className={styles.senderName}>{t('sender')}</span>
        <div className={styles.questionBubble}>
          <Text variant="question">{question}</Text>
        </div>
      </div>
    </Flex>
  );
};

interface AnswerProps {
  answer: string;
}

const Answer: React.FC<AnswerProps> = ({answer}) => {
  return (
    <Flex
      key={answer}
      width="100%"
      justifyContent="flex-end"
      initial={{opacity: 0, x: 10}}
      animate={{opacity: 1, x: 0}}
      transition={{duration: 0.6}}
    >
      <div className={styles.answerBubble}>
        <Text variant="answer">{answer}</Text>
      </div>
    </Flex>
  );
};

export default ChatGroup;
