import {
  UserAssessmentResult,
  UserAssessmentResultRaw,
} from '../../interfaces/user-assessment-result';
import {sanitizeHexacoProfileFromRaw} from './hexaco-profile';
import {
  DEFAULT,
  HEXACO,
  CULTURE_DESIGN,
} from '../../constants/module-algorithms';
import {HexacoProfileRaw} from '../../interfaces/hexaco-profile';
import {
  GenericModuleResult,
  GenericModuleResultRaw,
  CultureDesignModuleResult,
  CultureDesignModuleResultRaw,
} from '../../interfaces/user-assessment-module-result';

export const sanitizeUserAssessmentResultFromRaw = (
  data: UserAssessmentResultRaw
): UserAssessmentResult => {
  let payload = {
    id: data.id,
    moduleName: data.module_name,
    moduleSlug: data.module_slug,
    moduleAlgorithm: data.module_algorithm,
    moduleResult: sanitizeModuleResultFromRaw(
      data.module_result,
      data.module_algorithm
    ),
  } as UserAssessmentResult;

  if (data.module_description) {
    payload['moduleDescription'] = data.module_description;
  }

  return payload;
};

export const sanitizeModuleResultFromRaw = (
  data:
    | HexacoProfileRaw
    | GenericModuleResultRaw
    | CultureDesignModuleResultRaw,
  algorithm: string
) => {
  let result = data as unknown;

  if (algorithm === HEXACO) {
    return sanitizeHexacoProfileFromRaw(result as HexacoProfileRaw);
  } else if (algorithm === DEFAULT) {
    return sanitizeGenericModuleResultFromRaw(result as GenericModuleResultRaw);
  } else if (algorithm === CULTURE_DESIGN) {
    return sanitizeCultureDesignModuleResultFromRaw(
      result as CultureDesignModuleResultRaw
    );
  }

  throw new Error('Invalid algorithm');
};

export const sanitizeGenericModuleResultFromRaw = (
  data: GenericModuleResultRaw
): GenericModuleResult => {
  return {
    userScore: data.user_score,
  };
};

export const sanitizeCultureDesignModuleResultFromRaw = (
  data: CultureDesignModuleResultRaw
): CultureDesignModuleResult => {
  return {
    coreValues: data.core_values,
  };
};
