import {ACTION_TARGETS} from '../../../../../../../../constants/action-steps';
import {
  COMPANY_TOOLTIP_IDENTIFIER,
  INDIVIDUAL_TOOLTIP_IDENTIFIER,
} from '../table-row';
import {FunctionComponent} from 'react';
import {ReactComponent as CompanyIcon} from '../../../../../../../@components/kit/icons/group.svg';
import {ReactComponent as IndividualIcon} from '../../../../../../../@components/kit/icons/person.svg';
import {UserActionStep} from '../../../../../../../../interfaces/action-step';
import {useTranslation} from 'react-i18next';
import styles from './styles.module.scss';

interface ElementProps {
  actionStep: UserActionStep;
}

const ActionTargetIcon: FunctionComponent<ElementProps> = ({actionStep}) => {
  const {t} = useTranslation('apply', {
    keyPrefix: 'action-steps.components.table-body.action-target-icon',
  });

  return actionStep.target === ACTION_TARGETS.COMPANY ? (
    <div
      className={styles.iconContainer}
      data-tooltip-id={COMPANY_TOOLTIP_IDENTIFIER}
      data-tooltip-content={t('company-tooltip')}
    >
      <CompanyIcon className={styles.icon} />
    </div>
  ) : (
    <div
      className={styles.iconContainer}
      data-tooltip-id={INDIVIDUAL_TOOLTIP_IDENTIFIER}
      data-tooltip-content={t('individual-tooltip')}
    >
      <IndividualIcon className={styles.icon} />
    </div>
  );
};

export default ActionTargetIcon;
