let alreadyChecked = false;
let passiveSupported = false;

function noop() {}

function isPassiveSupported() {
  if (alreadyChecked) return passiveSupported;
  alreadyChecked = true;

  try {
    const options = {
      get passive() {
        passiveSupported = true;
        return false;
      },
    };

    window.addEventListener('click', noop, options);

    // @ts-ignore
    window.removeEventListener('click', noop, options);
  } catch (err) {
    passiveSupported = false;
  }

  return passiveSupported;
}

export default isPassiveSupported;
