const SEPARATOR_TOKEN = ' – ';

export function buildPageTitle(token?: string | string[]) {
  const applicationName = 'Instill';

  if (!token) return applicationName;

  if (!Array.isArray(token))
    return `${token}${SEPARATOR_TOKEN}${applicationName}`;

  return [...token, applicationName].join(SEPARATOR_TOKEN);
}
