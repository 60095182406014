import 'chart.js/auto';
import {FunctionComponent, useRef} from 'react';
import {HexacoProfile, HexacoScore} from '../../../interfaces/hexaco-profile';
import {Radar} from 'react-chartjs-2';
import {useTranslation} from 'react-i18next';

export const getHexacoScoreFromProfile = (data: HexacoProfile): HexacoScore => {
  return {
    agreeableness: data.agreeableness,
    conscientiousness: data.conscientiousness,
    emotionality: data.emotionality,
    extroversion: data.extroversion,
    honesty: data.honesty,
    openness: data.openness,
  };
};

const OPTIONS = {
  responsive: true,
  scales: {
    r: {
      ticks: {
        display: false,
      },
      pointLabels: {
        font: {
          size: 14,
        },
      },
    },
  },
  elements: {
    line: {
      borderWidth: 1,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
};

interface ElementProps {
  hexacoScore: HexacoScore;
}

const RadarChart: FunctionComponent<ElementProps> = ({hexacoScore}) => {
  const {t} = useTranslation('components');

  const labelsMap = {
    agreeableness: t('radar-chart.agreeableness-label'),
    conscientiousness: t('radar-chart.conscientiousness-label'),
    emotionality: t('radar-chart.emotionality-label'),
    extroversion: t('radar-chart.extroversion-label'),
    honesty: t('radar-chart.honesty-label'),
    openness: t('radar-chart.openness-label'),
  };

  const data = {
    labels: Object.values(labelsMap),
    datasets: [
      {
        label: 'hexaco',
        data: Object.keys(labelsMap).map(
          (key) => hexacoScore[key as keyof HexacoScore]
        ),
        borderColor: 'transparent',
        pointBackgroundColor: 'transparent',
        pointBorderColor: 'transparent',
        backgroundColor: 'rgba(204, 227, 255, 0.5)',
      },
    ],
  };

  const chartRef = useRef<any>(null);

  return (
    <>
      <Radar ref={chartRef} options={OPTIONS} data={data} />
    </>
  );
};

export default RadarChart;
