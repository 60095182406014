import InstillAPI from '../axios-instance';

export async function fetchSlackLinkingUrl(
  token: string,
  companyUuid: string,
  isForWorkspace: boolean = false
) {
  try {
    const baseResource = '/oauth2/slack/';

    const response = await InstillAPI.get(
      `${baseResource}auth-code?company_uuid=${companyUuid}&for_workspace=${isForWorkspace}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const code = response.data.code;
    if (!code) return null;

    const url = `${window.env.REACT_APP_INSTILL_API_BASE_URL}${baseResource}connect/`;
    if (!isForWorkspace) return `${url}user?code=${code}`;

    return `${url}workspace?code=${code}`;
  } catch (error) {
    console.error(error);
    return null;
  }
}
