import {Actions} from '../../../../../../constants/permissions/actions';
import {EventName} from '../../../../../../constants/analytics/event-name';
import {FullUserProfile} from '../../../../../../interfaces/user-profile';
import {FunctionComponent, useCallback, useState} from 'react';
import {getUserProfileForCompany} from '../../../../../../services/user-profiles';
import {HierarchyEdit} from '../../../hierarchy-edit';
import {useCurrentAuthUserState} from '../../../../../@atoms/current-auth-user';
import {useSafeCurrentCompany} from '../../../../../@atoms/current-company';
import {useTranslation} from 'react-i18next';
import {useUserPermission} from '../../../../../@hooks/use-user-permission';
import Button from '../../../../../@components/kit/form/button';
import profileStyles from '../../styles.module.scss';
import styles from './styles.module.scss';
import View from './@components/view';

interface ElementProps {
  profile: FullUserProfile;
}

const OrganizationStructure: FunctionComponent<ElementProps> = ({profile}) => {
  const {t} = useTranslation('application');

  const [isInEditMode, setIsInEditMode] = useState(false);

  const currentCompany = useSafeCurrentCompany();
  const [currentAuthUser] = useCurrentAuthUserState();

  const userProfile = getUserProfileForCompany(
    currentAuthUser!,
    currentCompany
  );

  const can = useUserPermission(userProfile.role);

  const isEditButtonVisible =
    !currentCompany.companySettings.isHRISProviderUKG &&
    can(Actions.EDIT_ORGANIZATION_STRUCTURE);

  const onEditButtonClick = useCallback(() => {
    setIsInEditMode(!isInEditMode);
  }, [isInEditMode]);

  const onEditCancel = useCallback(() => {
    setIsInEditMode(false);
  }, []);

  return (
    <div className={profileStyles.box}>
      <div className={styles.boxHeader}>
        <h2 className={profileStyles.boxTitle}>
          {t(
            'components.profile-details.components.organization-structure.title'
          )}
        </h2>

        {isEditButtonVisible && (
          <Button
            variant="secondary"
            className={styles.button}
            onClick={onEditButtonClick}
          >
            {isInEditMode
              ? t(
                  'components.profile-details.components.organization-structure.cancel-button-text'
                )
              : t(
                  'components.profile-details.components.organization-structure.edit-button-text'
                )}
          </Button>
        )}
      </div>

      {isInEditMode ? (
        <HierarchyEdit
          addDirectManagerEventName={
            EventName.PROFILE.ORGANIZATION_STRUCTURE.ADD_DIRECT_MANAGER
          }
          addDirectReportsEventName={
            EventName.PROFILE.ORGANIZATION_STRUCTURE.ADD_DIRECT_REPORT
          }
          addJobTitleEventName={
            EventName.PROFILE.ORGANIZATION_STRUCTURE.ADD_JOB_TITLE
          }
          onSecondaryButtonClick={onEditCancel}
          onSubmitSuccess={onEditCancel}
          primaryButtonLabel={t(
            'components.profile-details.components.organization-structure.save-button-text'
          )}
          profile={profile}
          secondaryButtonLabel={t(
            'components.profile-details.components.organization-structure.cancel-button-text'
          )}
        />
      ) : (
        <View profile={profile} />
      )}
    </div>
  );
};

export default OrganizationStructure;
