import {EventName} from '../../../../../constants/analytics/event-name';
import {EventType} from '../../../../../constants/analytics/event-type';
import {FunctionComponent} from 'react';
import {Input} from '../../../../@components/chat/input';
import {Module} from '../../../../../interfaces/module';
import {
  OPEN_ENDED_TEXT,
  SEVEN_POINT_LIKERT_WITH_LABELS,
} from '../../../../../constants/question-type';
import {Select} from '../../../../@components/chat/select';
import {SevenPointLikertOptionsWithLabels} from '../../../../../interfaces/question';
import {useNavigate} from 'react-router';
import {useUpsertCandidateAssessmentResponse} from '../../../../@hooks/mutations';
import Chat from '../../../../@components/chat';
import useAnalytics from '../../../../@hooks/use-analytics';

interface ElementProps {
  candidateToken: string;
  candidateUuid: string;
  userAssessmentUuid: string;
  assessmentModule: Module;
}

const AssessmentChat: FunctionComponent<ElementProps> = ({
  candidateToken,
  candidateUuid,
  userAssessmentUuid,
  assessmentModule,
}) => {
  const {trackEvent} = useAnalytics();
  const navigate = useNavigate();
  const upsertCandidateResponse = useUpsertCandidateAssessmentResponse();

  const getOptions = (labels: string[]) => {
    return labels.map((label, index) => {
      return {label, value: String(index + 1)};
    });
  };

  const onRenderQuestions = () => {
    const questions = assessmentModule.questions.sort(
      (questionFirst, questionSecond) => {
        return questionFirst.rank - questionSecond.rank;
      }
    );

    const questionElements = questions.map((question) => {
      switch (question.type) {
        case OPEN_ENDED_TEXT:
          return (
            <Input
              key={question.uuid}
              name={question.uuid}
              question={question.title}
              onAnswer={(value: string) => {
                onAnswer(value, question.uuid);
              }}
            />
          );
        case SEVEN_POINT_LIKERT_WITH_LABELS:
          return (
            <Select
              key={question.uuid}
              name={question.uuid}
              question={question.title}
              options={getOptions(
                (question.options as SevenPointLikertOptionsWithLabels).labels
              )}
              onAnswer={(value: string) => {
                onAnswer(value, question.uuid);
              }}
            />
          );
        default:
          return null;
      }
    });

    return questionElements as JSX.Element[];
  };

  const onAnswer = (value: string, uuid: string) => {
    const response = upsertCandidateResponse.mutateAsync({
      candidateToken: candidateToken,
      candidateUuid: candidateUuid,
      userAssessmentUuid: userAssessmentUuid,
      moduleUuid: assessmentModule.uuid,
      questionUuid: uuid,
      value: value,
    });

    if (!response) return;

    trackEvent({
      eventName: EventName.CANDIDATES.ASSESSMENT.QUESTION_ANSWERED,
      eventType: EventType.BUTTON_CLICKED,
    });
  };

  const onFinish = () => {
    navigate('/candidates/feedback');
    return null;
  };

  return (
    <Chat onSubmit={onFinish}>
      {onRenderQuestions().map((question) => {
        return question;
      })}
    </Chat>
  );
};

export default AssessmentChat;
