import {
  AssessmentRaw,
  CompanyAssessmentPopulation,
  CompanyAssessmentPopulationRaw,
  CompanyAssessmentRaw,
  CompanyAssessmentUpsertPayload,
  CompanyAssessmentUpsertPayloadRaw,
  CompletionInfoRaw,
  CompletionInfo,
  CompanyAssessment,
} from '../../interfaces/company-assessments';
import {SendMessageDialogFormProps} from '../application/measure/manage-assessments/index/@components/assessments/@components/list-view/@component/send-message-dialog';

export const sanitizeCompanyAssessmentsFromRaw = (
  data: CompanyAssessmentRaw[]
) => {
  return data.map((assessment) => sanitizeCompanyAssessmentFromRaw(assessment));
};

export const sanitizeCompanyAssessmentFromRaw = (
  data: CompanyAssessmentRaw
): CompanyAssessment => {
  return {
    assessment: sanitizeAssessmentFromRaw(data.assessment),
    completionInfo: data.completion_info.map((info) =>
      sanitizeCompanyAssessmentCompletionInfoFromRaw(info)
    ),
    dateInitiated: data.date_initiated,
    dueDate: data.due_date,
    isActive: data.is_active,
    population: sanitizePopulationFromRaw(data.population),
    schedule: data.schedule,
  };
};

export const sanitizeAssessmentFromRaw = (data: AssessmentRaw) => {
  return {
    name: data.name,
    overview: data.overview,
    rank: data.rank,
    uuid: data.uuid,
    supportedSchedules: data.supported_schedules,
  };
};

export const sanitizeCompanyAssessmentPayloadToRaw = (
  data: CompanyAssessmentUpsertPayload
) => {
  const payload = {} as CompanyAssessmentUpsertPayloadRaw;

  if ('isActive' in data) {
    payload['is_active'] = data.isActive;
  }

  if (data.population) {
    payload['population'] = sanitizePopulationToRaw(data.population);
  }

  if (data.schedule) {
    payload['schedule'] = data.schedule;
  }

  if (data.message) {
    payload['message'] = data.message;
  }

  if (data.dueDate) {
    payload['due_date'] = data.dueDate;
  }

  return payload;
};

export const sanitizePopulationFromRaw = (
  data: CompanyAssessmentPopulationRaw
): CompanyAssessmentPopulation | null => {
  if (!data) return null;

  const isCompanyWide = data.is_company_wide;

  if (isCompanyWide) {
    return {
      isCompanyWide: true,
      userProfileUuids: [],
      teamUuids: [],
    };
  }

  return {
    isCompanyWide: false,
    userProfileUuids: data.user_profile_uuids,
    teamUuids: data.team_uuids,
  };
};

export const sanitizePopulationToRaw = (
  data: CompanyAssessmentPopulation
): CompanyAssessmentPopulationRaw => {
  const isCompanyWide = data.isCompanyWide;

  if (isCompanyWide) {
    return {
      is_company_wide: true,
      user_profile_uuids: [],
      team_uuids: [],
    };
  }

  return {
    is_company_wide: false,
    user_profile_uuids: data.userProfileUuids,
    team_uuids: data.teamUuids,
  };
};

export const sanitizeCompanyAssessmentCompletionInfoFromRaw = (
  data: CompletionInfoRaw
): CompletionInfo => {
  return {
    isCompleted: data.is_completed,
    name: data.name,
    userProfileUuid: data.user_profile_uuid,
  };
};

export const sanitizeCompanyAssessmentMessagePayloadToRaw = (
  data: SendMessageDialogFormProps
) => {
  return {
    send_to: data.sendTo,
    message: data.message,
  };
};
