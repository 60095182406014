import {CompanyAssessment} from '../../../../../../../interfaces/company-assessments';
import {CompanyEmployee} from '../../../../../../../interfaces/company-employee';
import {FunctionComponent, useMemo} from 'react';
import {sortByName} from '../../../../../../../utils/sort-by/name';
import {useTranslation} from 'react-i18next';
import ListView from './@components/list-view';
import NoResultsBox from './@components/no-results-box';
import RoundedCard from '../../../../../../@components/rounded-card';
import Spinner from '../../../../../../@components/spinner';
import {Team} from '../../../../../../../interfaces/team';

interface ElementProps {
  companyAssessments?: CompanyAssessment[];
  companyEmployees?: CompanyEmployee[];
  isFetchingInProgress: boolean;
  onRefreshCompanyAssessments: () => void;
  teams: Team[];
}

const Assessments: FunctionComponent<ElementProps> = ({
  companyAssessments,
  companyEmployees,
  isFetchingInProgress,
  onRefreshCompanyAssessments,
  teams,
}) => {
  const {t} = useTranslation('measure');

  const sortedEmployees = useMemo(() => {
    if (!companyEmployees) return;

    return sortByName(companyEmployees);
  }, [companyEmployees]);

  return (
    <>
      <RoundedCard.Header>
        <RoundedCard.Title>
          {t('manage-assessments.components.assessments.title')}
        </RoundedCard.Title>
      </RoundedCard.Header>

      <RoundedCard.Content>
        {isFetchingInProgress ? (
          <Spinner />
        ) : (
          <>
            {companyAssessments && sortedEmployees ? (
              <ListView
                teams={teams}
                companyAssessments={companyAssessments}
                companyEmployees={sortedEmployees}
                onRefreshCompanyAssessments={onRefreshCompanyAssessments}
              />
            ) : (
              <NoResultsBox />
            )}
          </>
        )}
      </RoundedCard.Content>
    </>
  );
};

export default Assessments;
