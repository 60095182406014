import {createQuery} from 'react-query-kit';
import {fetchReports} from '../../../repositories/instill/queries';
import {FetchReportsPayload} from '../../../repositories/instill/queries/fetch-reports';
import {UserReport} from '../../../interfaces/user-report';

export const useUserReports = createQuery<
  UserReport[] | undefined,
  FetchReportsPayload
>({
  primaryKey: 'userReports',
  queryFn: async ({queryKey: [_, variables]}) => {
    return await fetchReports(variables.companyUuid);
  },
});
