import {CompanyAssessmentEvent} from '../../../../../../../../../../../interfaces/company-assessment-events';
import {formatDate} from '../../../../../../../../../../../utils/date';
import {FunctionComponent, useCallback, useMemo, useRef, useState} from 'react';
import {ReactComponent as IconChevron} from '../../../../../../../../../../@components/kit/icons/chevron.svg';
import {Trans, useTranslation} from 'react-i18next';
import AssessmentModule from './@components/modules/assessment-module';
import parentStyles from '../../styles.module.scss';
import styles from './styles.module.scss';
import tableStyles from '../../../../../../../../../../../styles/classes/table.module.scss';
import Tag from '../../../../../../../../../../@components/tag';

interface ElementProps {
  companyAssessmentEvent: CompanyAssessmentEvent;
}

const TableRow: FunctionComponent<ElementProps> = ({
  companyAssessmentEvent,
}) => {
  const {t} = useTranslation('measure');

  const contentRef = useRef<HTMLDivElement | null>(null);

  const [isAssessmentEventVisible, setIsAssessmentEventVisible] =
    useState(true);

  const assessmentCompletedPopulation = useMemo(() => {
    return companyAssessmentEvent.modules.filter((module) => {
      const moduleTotalLength = module.completionInfo.length;
      const completedCompletionInfo = module.completionInfo.filter(
        (info) => info.isCompleted
      ).length;

      return moduleTotalLength === completedCompletionInfo;
    });
  }, [companyAssessmentEvent]);

  const onToggleAssessment = useCallback(() => {
    setIsAssessmentEventVisible(!isAssessmentEventVisible);
  }, [isAssessmentEventVisible]);

  return (
    <li className={styles.item}>
      <div className={styles.row} onClick={onToggleAssessment}>
        <div
          className={`${parentStyles.tableData} ${parentStyles.occurenceColumn}`}
        >
          <div className={tableStyles.mobileHeaderTitle}>
            {t(
              'manage-assessments.components.assessment-events.components.list-view.table.header.occurence'
            )}
          </div>

          {companyAssessmentEvent.occurrence}
        </div>

        <div
          className={`${parentStyles.tableData} ${parentStyles.dateInitiatedColumn}`}
        >
          <div className={tableStyles.mobileHeaderTitle}>
            {t(
              'manage-assessments.components.assessment-events.components.list-view.table.header.date-initiated'
            )}
          </div>

          <Tag>{formatDate(companyAssessmentEvent.dateInitiated)}</Tag>
        </div>

        <div
          className={`${parentStyles.tableData} ${parentStyles.dueDateColumn}`}
        >
          <div className={tableStyles.mobileHeaderTitle}>
            {t(
              'manage-assessments.components.assessment-events.components.list-view.table.header.due-date'
            )}
          </div>

          <Tag>{formatDate(companyAssessmentEvent.dueDate)}</Tag>
        </div>

        <div
          className={`${parentStyles.tableData} ${parentStyles.populationColumn}`}
        >
          <div className={tableStyles.mobileHeaderTitle}>
            {t(
              'manage-assessments.components.assessment-events.components.list-view.table.header.population'
            )}
          </div>

          <Tag>
            {companyAssessmentEvent.population.isCompanyWide ? (
              <>
                {t(
                  'manage-assessments.components.assessment-events.components.list-view.table.population.company-wide-label'
                )}
              </>
            ) : (
              <Trans
                t={t}
                i18nKey="manage-assessments.components.assessment-events.components.list-view.table.population.label"
                values={{
                  length: assessmentCompletedPopulation.length,
                  totalLength:
                    companyAssessmentEvent.population.userProfilesUuids.length,
                }}
              />
            )}
          </Tag>
        </div>

        <div
          className={`${parentStyles.tableData} ${parentStyles.actionsColumn}`}
        >
          <div className={tableStyles.mobileHeaderTitle}>
            {t(
              'manage-assessments.components.assessment-events.components.list-view.table.header.actions'
            )}
          </div>

          <IconChevron
            className={
              isAssessmentEventVisible
                ? `${styles.buttonIcon} ${styles.isHidden}`
                : styles.buttonIcon
            }
          />
        </div>
      </div>

      {isAssessmentEventVisible && (
        <div
          className={styles.moduleRow}
          ref={contentRef}
          style={
            isAssessmentEventVisible
              ? {height: contentRef.current?.scrollHeight + 'px'}
              : {height: '0px'}
          }
        >
          {companyAssessmentEvent.modules?.map((module, index) => (
            <AssessmentModule module={module} index={index} key={index} />
          ))}
        </div>
      )}
    </li>
  );
};

export default TableRow;
