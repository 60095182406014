import {AxiosResponse} from 'axios';
import {createMutation} from 'react-query-kit';
import {
  InviteUsersPayload,
  userInvites,
} from '../../../repositories/instill/mutations/user-invites';

export const useInviteUsers = createMutation<
  AxiosResponse<any, any> | undefined,
  InviteUsersPayload
>({
  mutationFn: async (variables) => {
    return await userInvites({
      userInvites: variables.userInvites,
      companyUuid: variables.companyUuid,
    });
  },
});
