import {sanitizeCompanyChecklistFromRaw} from '../../../ui/@sanitizers/checklist';
import InstillAPI from '../axios-instance';
import isResponseSuccessful from '../../../utils/is-response-successful';

export interface FetchChecklistPayload {
  companyUuid: string;
}

export async function fetchCompanyChecklist(companyUuid: string) {
  try {
    const response = await InstillAPI.get(
      `/companies/${companyUuid}/checklist`
    );

    if (!response.data || !isResponseSuccessful(response)) {
      throw Error('No data received');
    }

    return sanitizeCompanyChecklistFromRaw(response.data);
  } catch (error) {
    console.error(error);
  }
}
