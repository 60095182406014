import {createMutation} from 'react-query-kit';
import {
  UploadToPresignedURLPayload,
  uploadToPresignedURL,
} from '../../../repositories/instill/mutations/upload-to-presigned-url';

export const useUploadToPresignedUrl = createMutation<
  string | undefined,
  UploadToPresignedURLPayload
>({
  mutationFn: async (variables) => {
    return await uploadToPresignedURL(variables.config, variables.file);
  },
});
