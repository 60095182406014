import React, {
  FunctionComponent,
  useState,
  cloneElement,
  Children,
  isValidElement,
  useCallback,
  PropsWithChildren,
} from 'react';
import Backdrop from './backdrop';
import Portal from '../../@components/portal';
import useBodyScrollLock from '../../@hooks/use-body-scroll-lock';
import useEscape from '../../@hooks/use-escape';
import useOnClickOutside from '../../@hooks/use-on-click-outside';

interface Props {
  clickOutsideElement?: HTMLElement | null;
  closeOnClickOutside?: boolean;
  isOpen: boolean;
  onClose: () => void;
}

const Modal: FunctionComponent<PropsWithChildren<Props>> = ({
  isOpen,
  onClose,
  clickOutsideElement,
  closeOnClickOutside = true,
  children,
}) => {
  const [container, setContainer] = useState<HTMLDivElement | null>(null);

  const handleClose = useCallback(() => {
    if (!isOpen) return;
    onClose();
  }, [isOpen, onClose]);

  const handleClickOutside = useCallback(() => {
    if (!closeOnClickOutside) return;
    handleClose();
  }, [closeOnClickOutside, handleClose]);

  useBodyScrollLock(isOpen);
  useOnClickOutside(clickOutsideElement || container, handleClickOutside);
  useEscape(handleClose);

  return (
    <Portal>
      <Backdrop isOpen={isOpen} />

      {Children.map(children, (child) => {
        if (!isValidElement<{ref: React.Ref<any>}>(child)) return;

        return cloneElement(child, {ref: setContainer});
      })}
    </Portal>
  );
};

export default Modal;
