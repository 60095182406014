import {MapYourOrganizationFormRaw} from '../../../interfaces/map-your-organization';
import {sanitizeUserProfileFromRaw} from '../../../ui/@sanitizers/user-profile';
import InstillAPI from '../axios-instance';
import isResponseSuccessful from '../../../utils/is-response-successful';

export interface UpsertUserHierarchyPayload {
  userHierarchyPayload: MapYourOrganizationFormRaw;
  userProfileUuid: string;
  companyUuid: string;
}

export async function upsertUserHierarchy(
  userHierarchyPayload: MapYourOrganizationFormRaw,
  userProfileUuid: string,
  companyUuid: string
) {
  const response = await InstillAPI.post(
    `/companies/${companyUuid}/user-profiles/${userProfileUuid}/hierarchy`,
    {...userHierarchyPayload}
  );

  if (!isResponseSuccessful(response)) {
    throw Error('No data received');
  }

  return sanitizeUserProfileFromRaw(response.data);
}
