export enum EventType {
  BUTTON_CLICKED = 'Button clicked',
  CONTINUE_BUTTON_CLICKED = 'Continue button clicked',
  LINK_CLICKED = 'Link clicked',
  PAGE_VIEW = 'Page View',
  PROFILE_CLICKED = 'Profile clicked',
  SAVE_BUTTON_CLICKED = 'Save button clicked',
  SEARCH_TRIGGERED = 'Search triggered',
  SIGNATURE_CLICKED = 'Signature clicked',
  TAB_BUTTON_CLICKED = 'Tab button clicked',
}
