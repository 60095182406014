import {buildPageTitle} from '../../../services/build-page-title';
import {EventName} from '../../../constants/analytics/event-name';
import {EventType} from '../../../constants/analytics/event-type';
import {FunctionComponent, useEffect} from 'react';
import {Helmet} from 'react-helmet-async';
import {StringParam, useQueryParam} from 'use-query-params';
import {useCandidateProfile} from '../../@hooks/queries';
import {useNavigate} from 'react-router';
import {useTranslation} from 'react-i18next';
import Introduction from './@components/introduction';
import RegisterCandidateForm from './@components/register-candidate-form';
import Spinner from '../../@components/spinner/spinner';
import TwoPanePage from '../../@components/two-pane-page';
import useAnalytics from '../../@hooks/use-analytics';

const Register: FunctionComponent = () => {
  const {t} = useTranslation('applicationPageTitle');

  const [candidateToken] = useQueryParam('token', StringParam);
  const {trackEvent} = useAnalytics();
  const navigate = useNavigate();

  const candidateProfile = useCandidateProfile({
    variables: {
      candidateToken: candidateToken as string,
    },
  });

  useEffect(() => {
    trackEvent({
      eventName: EventName.PAGE_VIEWS.CANDIDATES.REGISTER,
      eventType: EventType.PAGE_VIEW,
    });
  }, [trackEvent]);

  if (candidateProfile.isLoading) {
    return <Spinner fullScreen={true} />;
  }

  if (candidateProfile.error) {
    navigate('/page-not-found');
    return null;
  }

  if (!candidateToken) {
    navigate('/page-not-found');
    trackEvent({eventName: EventName.CANDIDATES.REGISTER.INVALID_TOKEN});

    return null;
  }

  return (
    <>
      <Helmet>
        <title>{buildPageTitle(t('page-title.candidates.register'))}</title>
      </Helmet>

      <TwoPanePage
        mainPane={
          <RegisterCandidateForm
            candidateToken={candidateToken}
            candidateUuid={candidateProfile.data!.uuid}
          />
        }
        secondaryPane={<Introduction />}
      />
    </>
  );
};

export default Register;
