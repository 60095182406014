import {ASSESSMENT_STATUS} from '../../../../../../../../../../constants/assessments';
import {Candidate} from '../../../../../../../../../../interfaces/candidate';
import {formatDate} from '../../../../../../../../../../utils/date';
import {FunctionComponent, useMemo} from 'react';
import {JobPosition} from '../../../../../../../../../../interfaces/job-position';
import {ReactComponent as IconEdit} from '../../../../../../../../../@components/kit/icons/edit.svg';
import {useTranslation} from 'react-i18next';
import parentStyles from '../../styles.module.scss';
import ProgressBar from '../../../../../../../../../@components/progress-bar';
import styles from './styles.module.scss';
import tableStyles from '../../../../../../../../../../styles/classes/table.module.scss';
import Tag from '../../../../../../../../../@components/tag';

interface ElementProps {
  candidate: Candidate;
  jobPositions: JobPosition[];
  onCandidateNameClicked: (candidate: Candidate) => void;
  onEditButtonClicked: (candidate: Candidate) => void;
}

const ASSESSMENT_STATUS_TRANSLATION_MAP = {
  [ASSESSMENT_STATUS.NOT_STARTED]: 'assessment-status.not-started',
  [ASSESSMENT_STATUS.COMPLETED]: 'assessment-status.completed',
  [ASSESSMENT_STATUS.STARTED]: 'assessment-status.started',
};

const TableRow: FunctionComponent<ElementProps> = ({
  candidate,
  jobPositions,
  onCandidateNameClicked,
  onEditButtonClicked,
}) => {
  const {t} = useTranslation('jobs', {
    keyPrefix:
      'candidates.components.candidate-list.components.list-view.table',
  });

  const isActive = candidate.isActive;
  const hasCompletedAssessment =
    candidate.assessmentStatus === ASSESSMENT_STATUS.COMPLETED;

  const assessmentStatus = useMemo(() => {
    const status = candidate.assessmentStatus;
    const translationKey =
      ASSESSMENT_STATUS_TRANSLATION_MAP[
        status as keyof typeof ASSESSMENT_STATUS_TRANSLATION_MAP
      ];

    return t(translationKey);
  }, [candidate, t]);

  const jobPositionTitle = useMemo(() => {
    const selectedJobPosition = jobPositions.find(
      (jobPosition) => jobPosition.uuid === candidate.jobPositionUuid
    );

    return selectedJobPosition?.title;
  }, [candidate, jobPositions]);

  return (
    <li className={styles.item}>
      <div className={isActive ? styles.row : styles.disabledRow}>
        <div
          className={`${parentStyles.tableData} ${parentStyles.jobPositionColumn}`}
        >
          <div className={tableStyles.mobileHeaderTitle}>
            {t('header.job-position')}
          </div>

          {jobPositionTitle}
        </div>

        <div className={`${parentStyles.tableData} ${parentStyles.nameColumn}`}>
          <div className={tableStyles.mobileHeaderTitle}>
            {t('header.candidate-name')}
          </div>

          <div className={tableStyles.overflowContainer}>
            {hasCompletedAssessment ? (
              <Tag onClick={() => onCandidateNameClicked(candidate)}>
                <div className={tableStyles.overflowContent}>
                  {candidate.name}
                </div>
              </Tag>
            ) : (
              <div className={tableStyles.overflowContent}>
                {candidate.name}
              </div>
            )}
          </div>
        </div>

        <div
          className={`${parentStyles.tableData} ${parentStyles.assessmentStatusColumn}`}
        >
          <div className={tableStyles.mobileHeaderTitle}>
            {t('header.assessment-status')}
          </div>

          {isActive ? (
            <Tag>{assessmentStatus}</Tag>
          ) : (
            <Tag variant="disabled">{assessmentStatus}</Tag>
          )}
        </div>

        <div
          className={`${parentStyles.tableData} ${parentStyles.dueDateColumn}`}
        >
          <div className={tableStyles.mobileHeaderTitle}>
            {t('header.due-date')}
          </div>

          {formatDate(candidate.dueDate.toString())}
        </div>

        <div
          className={`${parentStyles.tableData} ${parentStyles.alignmentScoreColumn}`}
        >
          <div className={tableStyles.mobileHeaderTitle}>
            {t('header.alignment-score')}
          </div>

          <div className={styles.centeredContent}>
            {candidate.valuesCalibrationScore > 0 ? (
              <div className={styles.alignmentScoreContainer}>
                <ProgressBar
                  currentProgress={candidate.valuesCalibrationScore}
                  total={100}
                  variant="xsmall"
                />

                {t('alignment-score-value', {
                  score: candidate.valuesCalibrationScore,
                })}
              </div>
            ) : (
              <>–</>
            )}
          </div>
        </div>

        <div
          className={`${parentStyles.tableData} ${parentStyles.actionColumn}`}
        >
          <div className={tableStyles.mobileHeaderTitle}>
            {t('header.actions')}
          </div>

          <div className={styles.actionContent}>
            <button
              type="button"
              onClick={() => onEditButtonClicked(candidate)}
              className={styles.actionButton}
            >
              {t('actions.edit')}

              <IconEdit className={styles.actionButtonIcon} />
            </button>
          </div>
        </div>
      </div>
    </li>
  );
};

export default TableRow;
