import {FunctionComponent, HTMLAttributes, PropsWithChildren} from 'react';
import styles from './styles.module.scss';

interface FormGroupProps
  extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {
  variant?: 'small';
}

const FormGroup: FunctionComponent<FormGroupProps> = ({
  children,
  variant,
  ...props
}) => {
  const variantClass = variant ? styles[variant] : '';

  return (
    <div className={`${styles.formGroup} ${variantClass}`} {...props}>
      {children}
    </div>
  );
};

export default FormGroup;
