import {sanitizeUserSettingsForRaw} from '../../../ui/@sanitizers/settings';
import isResponseSuccessful from '../../../utils/is-response-successful';
import InstillAPI from '../axios-instance';

export interface UpdateUserSettingsPayload {
  companyUuid: string;
  payload: {
    senseAutoJoin: boolean;
  };
}

export const updateUserSettings = async (
  parameters: UpdateUserSettingsPayload
) => {
  const {companyUuid, payload} = parameters;
  const sanitizedPayload = sanitizeUserSettingsForRaw(payload);

  const response = await InstillAPI.put(
    `/companies/${companyUuid}/user-profiles/me/settings`,
    sanitizedPayload
  );

  if (!isResponseSuccessful(response)) {
    throw Error('No data received');
  }

  return response.data;
};
