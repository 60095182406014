import {CompanyEmployee} from '../../../../interfaces/company-employee';
import {EventName} from '../../../../constants/analytics/event-name';
import {Field, FieldArrayRenderProps, FieldProps, Form, Formik} from 'formik';
import {FullUserProfile} from '../../../../interfaces/user-profile';
import {FunctionComponent, useCallback, useMemo, useState} from 'react';
import {
  MapYourOrganizationForm,
  NewUser,
} from '../../../../interfaces/map-your-organization';
import {RecruitsForm} from './@components/recruits-form';
import {ReportsForm} from './@components/reports-form';
import {Roles} from '../../../../constants/roles';
import {
  sanitizeMapYourOrganizationFormToRaw,
  sanitizeNewRecruitsToRaw,
} from '../../../@sanitizers/map-your-organization';
import {sortByName} from '../../../../utils/sort-by/name';
import {
  useCompanyEmployees,
  useHierarchyRecommendations,
} from '../../../@hooks/queries';
import {
  useInviteUsers,
  useUpsertOpenRoles,
  useUpsertUserHierarchy,
} from '../../../@hooks/mutations';
import {useSafeCurrentCompany} from '../../../@atoms/current-company';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import AddManager from './@components/add-manager';
import Button from '../../../@components/kit/form/button';
import Dialog from '../../../@components/kit/dialog';
import Label from '../../../@components/kit/form/label';
import styles from './styles.module.scss';
import UserCheckbox from './@components/user-checkbox';
import UserDropdown from './@components/user-dropdown';

interface ElementProps {
  addDirectManagerEventName: string;
  addDirectReportsEventName: string;
  addJobTitleEventName: string;
  onSecondaryButtonClick: () => void;
  onSubmitSuccess: () => void;
  primaryButtonLabel: string;
  profile: FullUserProfile;
  secondaryButtonLabel: string;
}

interface UninvitedUser {
  email: string;
  name: string;
  role: Roles;
}

export const HierarchyEdit: FunctionComponent<ElementProps> = ({
  addDirectManagerEventName,
  addDirectReportsEventName,
  addJobTitleEventName,
  onSecondaryButtonClick,
  onSubmitSuccess,
  primaryButtonLabel,
  profile,
  secondaryButtonLabel,
}) => {
  const {t} = useTranslation('application');

  const [isInviteDialogOpen, setIsInviteDialogOpen] = useState(false);
  const [dialogText, setDialogText] = useState('');
  const [uninvitedUsers, setUninvitedUsers] = useState<UninvitedUser[]>([]);

  const currentCompany = useSafeCurrentCompany();

  const companyEmployees = useCompanyEmployees({
    variables: {
      companyUuid: currentCompany.uuid,
    },
  });

  const hierarchyRecommendations = useHierarchyRecommendations({
    variables: {
      companyUuid: currentCompany.uuid,
      userProfileUuid: profile.uuid,
    },
  });

  const inviteUsers = useInviteUsers();
  const upsertOpenRoles = useUpsertOpenRoles();
  const upsertUserHierarchy = useUpsertUserHierarchy();

  const companyEmployeesWithoutCurrentUser = useMemo(() => {
    if (!companyEmployees.data) return;

    const sortedEmployees = sortByName(companyEmployees.data?.data);

    return sortedEmployees.filter(
      (companyEmployee: CompanyEmployee) =>
        companyEmployee.workEmail !== profile!.workEmail
    );
  }, [companyEmployees, profile]);

  const potentialManager = hierarchyRecommendations?.data?.manager;
  const potentialReports = hierarchyRecommendations?.data?.reports || [];

  const INITIAL_VALUES: MapYourOrganizationForm = {
    manager: profile?.manager ?? null,
    reports: profile?.reporters ?? [],
    recruits: profile.recruits ?? [],
  };

  const EMPTY_NEW_USER: NewUser = useMemo(() => {
    return {
      name: '',
      workEmail: '',
    };
  }, []);

  const validationSchema = Yup.object().shape({
    manager: Yup.object()
      .shape({
        name: Yup.string().required(),
        workEmail: Yup.string().email().required(),
      })
      .nullable(),
    reports: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().required(),
          workEmail: Yup.string().email().required(),
        })
      )
      .nullable(),
    recruits: Yup.array().of(
      Yup.object()
        .shape({
          jobTitle: Yup.string().required(),
        })
        .nullable()
    ),
  });

  const onAddManager = useCallback(
    (setFieldValue: FieldArrayRenderProps['form']['setFieldValue']) => {
      setFieldValue('manager', EMPTY_NEW_USER);
    },
    [EMPTY_NEW_USER]
  );

  const onCloseDialog = useCallback(() => {
    setIsInviteDialogOpen(false);
  }, []);

  const onConfirmDialog = useCallback(async () => {
    setIsInviteDialogOpen(false);

    await inviteUsers.mutateAsync({
      userInvites: uninvitedUsers,
      companyUuid: currentCompany.uuid,
    });

    onSubmitSuccess();
  }, [onSubmitSuccess, inviteUsers, currentCompany, uninvitedUsers]);

  const onSubmit = useCallback(
    async (values: MapYourOrganizationForm) => {
      await upsertUserHierarchy.mutateAsync({
        userHierarchyPayload: sanitizeMapYourOrganizationFormToRaw(values),
        userProfileUuid: profile!.uuid,
        companyUuid: currentCompany.uuid,
      });

      const newRecruits = sanitizeNewRecruitsToRaw(values.recruits || []);
      const createdNewRoles = await upsertOpenRoles.mutateAsync({
        openRolesPayload: newRecruits,
        companyUuid: currentCompany.uuid,
      });
      if (!createdNewRoles) return;

      const relations: UninvitedUser[] = [];

      if (values.manager) {
        relations.push({
          name: values.manager.name,
          email: values.manager.workEmail,
          role: Roles.MANAGER,
        });
      }

      if (values.reports) {
        const reportInvites = values.reports.map((report) => ({
          name: report.name,
          email: report.workEmail,
          role: Roles.MEMBER,
        }));

        relations.push(...reportInvites);
      }

      const newUsers: UninvitedUser[] = relations.filter(
        (relation: UninvitedUser) => {
          const user = companyEmployeesWithoutCurrentUser?.find(
            (companyEmployee: CompanyEmployee) =>
              companyEmployee.workEmail === relation.email
          );

          return !user;
        }
      );

      const newUsersLength = newUsers.length;

      if (newUsersLength > 0) {
        setUninvitedUsers(newUsers);

        if (newUsersLength === 1) {
          setDialogText(
            t('components.hierarchy-edit.invite-dialog.message-single-user', {
              userName: newUsers[0].name,
            })
          );
        } else if (newUsersLength === 2) {
          setDialogText(
            t('components.hierarchy-edit.invite-dialog.message-two-users', {
              firstUserName: newUsers[0].name,
              secondUserName: newUsers[1].name,
            })
          );
        } else if (newUsersLength > 2) {
          setDialogText(
            t('components.hierarchy-edit.invite-dialog.message-many-users', {
              userName: newUsers[0].name,
              userCount: newUsersLength - 1,
            })
          );
        }

        setIsInviteDialogOpen(true);
      } else {
        onSubmitSuccess();
      }
    },
    [
      companyEmployeesWithoutCurrentUser,
      currentCompany,
      onSubmitSuccess,
      profile,
      t,
      upsertOpenRoles,
      upsertUserHierarchy,
    ]
  );

  return (
    <>
      <Formik
        initialValues={INITIAL_VALUES}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({values, setFieldValue, isValid}) => {
          const isManagerPartOfDirectReports =
            values.manager &&
            values.reports?.some((report) => {
              return (
                report.name === values.manager?.name &&
                report.workEmail === values.manager.workEmail
              );
            });

          const isSubmitButtonDisabled =
            isManagerPartOfDirectReports || !isValid;

          return (
            <Form>
              <div>
                <Label>
                  {t('components.hierarchy-edit.main.manager-label')}
                </Label>

                {potentialManager && (
                  <UserCheckbox
                    userData={potentialManager}
                    isDisabled={false}
                    onCheck={(userData) => {
                      setFieldValue('manager', {
                        name: userData.name,
                        workEmail: userData.workEmail,
                        isHidden: true,
                      });
                    }}
                    onUncheck={(userData) => {
                      setFieldValue('manager', null);
                    }}
                  />
                )}

                {values.manager &&
                  !values.manager!.isHidden &&
                  companyEmployeesWithoutCurrentUser && (
                    <Field name="manager">
                      {(FieldProps: FieldProps) => (
                        <UserDropdown
                          {...FieldProps}
                          value={values.manager}
                          options={companyEmployeesWithoutCurrentUser}
                          deleteItem={() => {
                            setFieldValue('manager', null);
                          }}
                          onAddItemEventName={
                            addDirectManagerEventName ??
                            EventName.COMPONENTS.HIERARCHY_EDIT
                              .ADD_DIRECT_MANAGER
                          }
                          isCollapsedByDefault={values.manager?.name !== ''}
                        />
                      )}
                    </Field>
                  )}

                <div className={styles.pageGroup}>
                  <AddManager
                    potentialManager={potentialManager}
                    currentFormValue={values.manager}
                    onAddManager={() => onAddManager(setFieldValue)}
                  />
                </div>
              </div>

              <div className={styles.seperator} />

              {companyEmployeesWithoutCurrentUser && (
                <div>
                  <ReportsForm
                    addDirectReportsEventName={addDirectReportsEventName}
                    companyEmployees={companyEmployeesWithoutCurrentUser}
                    potentialReports={potentialReports}
                    values={values}
                  />
                </div>
              )}

              {isManagerPartOfDirectReports ? (
                <div className={styles.errorContainer}>
                  {t(
                    'components.hierarchy-edit.main.must-select-different-person-error-message'
                  )}
                </div>
              ) : (
                <></>
              )}

              <div className={styles.seperator} />

              <div>
                <Label>
                  {t('components.hierarchy-edit.main.recruits-label')}
                </Label>

                <div className={styles.pageGroup}>
                  <RecruitsForm
                    addJobTitleEventName={addJobTitleEventName}
                    values={values}
                  />
                </div>
              </div>

              <div className={styles.pageFooter}>
                <Button
                  type="submit"
                  variant="primary"
                  disabled={isSubmitButtonDisabled}
                >
                  {primaryButtonLabel}
                </Button>

                <Button
                  type="button"
                  variant="secondary"
                  onClick={onSecondaryButtonClick}
                >
                  {secondaryButtonLabel}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>

      <Dialog isOpen={isInviteDialogOpen} onClose={onCloseDialog}>
        <Dialog.Header>
          {t('components.hierarchy-edit.invite-dialog.title')}
        </Dialog.Header>

        <Dialog.Content>
          <div>
            <div className={styles.dialogText}>{dialogText}</div>

            <Button type="button" onClick={onConfirmDialog}>
              {t('components.hierarchy-edit.invite-dialog.yes-button-text')}
            </Button>

            <Button type="button" variant="secondary" onClick={onCloseDialog}>
              {t('components.hierarchy-edit.invite-dialog.no-button-text')}
            </Button>
          </div>
        </Dialog.Content>
      </Dialog>
    </>
  );
};

export default HierarchyEdit;
