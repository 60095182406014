import {CompanyChecklist} from '../../../interfaces/checklist';
import {createMutation} from 'react-query-kit';
import {ORGANIZATION_CHECKLIST_STEP_KEYS} from '../../application/@components/setup-checklist';
import {updateCompanyChecklist} from '../../../repositories/instill';

export interface UpdateChecklistPayload {
  currentStep: ORGANIZATION_CHECKLIST_STEP_KEYS;
  companyUuid: string;
}

export const useUpdateChecklist = createMutation<
  CompanyChecklist | undefined,
  UpdateChecklistPayload
>({
  mutationFn: async (variables) => {
    return await updateCompanyChecklist(
      variables.currentStep,
      variables.companyUuid
    );
  },
});
