import {fetchPdfReportUrl} from '../../../../../../repositories/instill';
import {FunctionComponent, useCallback, useMemo, useState} from 'react';
import {ReactComponent as IconDownload} from '../../../../../@components/kit/icons/download.svg';
import {UserAssessmentResult} from '../../../../../../interfaces/user-assessment-result';
import {useTranslation} from 'react-i18next';
import styles from './styles.module.scss';

interface ElementProps {
  assessmentId?: string;
  moduleId?: string;
  result: UserAssessmentResult;
}

const Sidebar: FunctionComponent<ElementProps> = ({
  assessmentId,
  moduleId,
  result,
}) => {
  const {t} = useTranslation('assessmentSummary');

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const isDownloadButtonVisible = useMemo(() => {
    return moduleId && assessmentId && result.moduleSlug === 'hexaco';
  }, [moduleId, assessmentId, result]);

  const onGenerateReportClick = useCallback(async () => {
    if (!assessmentId || !moduleId) return;

    setIsButtonDisabled(true);

    const response = await fetchPdfReportUrl(assessmentId, moduleId);
    if (!response) return;

    setIsButtonDisabled(false);

    window.open(response, '_blank');
  }, [assessmentId, moduleId]);

  return (
    <>
      <h1 className={styles.title}>
        {t('components.module-summary.components.sidebar.title', {
          name: result.moduleName,
        })}
      </h1>

      {result.moduleDescription && (
        <div className={styles.description}>{result.moduleDescription}</div>
      )}

      {isDownloadButtonVisible && (
        <button
          className={styles.link}
          disabled={isButtonDisabled}
          onClick={onGenerateReportClick}
          type="button"
        >
          {t(
            'components.module-summary.components.sidebar.download-report-button-text'
          )}

          <IconDownload className={styles.linkIcon} />
        </button>
      )}
    </>
  );
};

export default Sidebar;
