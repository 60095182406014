import {sanitizeCompanyFromRaw} from '../../../ui/@sanitizers/company';
import InstillAPI from '../axios-instance';
import isResponseSuccessful from '../../../utils/is-response-successful';

export interface FetchCompanyPayload {
  companyUuid?: string;
}

export async function fetchCompany(companyUuid: string) {
  try {
    const response = await InstillAPI.get(`/companies/${companyUuid}`);

    if (!response.data || !isResponseSuccessful(response)) {
      throw Error('No data received');
    }

    return sanitizeCompanyFromRaw(response.data);
  } catch (error) {
    console.error(error);
  }
}
