import {useTranslation} from 'react-i18next';
import styles from './styles.module.scss';

function Quote() {
  const {t} = useTranslation('pageNotFound');

  return (
    <div className={styles.quote}>
      <div className={styles.quoteCitation}>{t('quote.citation')}</div>
      <div className={styles.quoteAuthor}>{t('quote.author')}</div>
    </div>
  );
}

export default Quote;
