import {AxiosResponse} from 'axios';
import {createMutation} from 'react-query-kit';
import {updateNotificationSettings} from '../../../repositories/instill/mutations';
import {UpdateNotificationSettingsPayload} from '../../../repositories/instill/mutations/update-notification-settings';

export const useUpdateNotificationSettings = createMutation<
  AxiosResponse<any, any> | undefined,
  UpdateNotificationSettingsPayload
>({
  mutationFn: async (variables) => {
    return updateNotificationSettings(variables.companyUuid, variables.payload);
  },
});
