import {createQuery} from 'react-query-kit';
import {fetchCurrentUser} from '../../../repositories/instill';
import {AuthUser} from '../../../interfaces/auth-user';

export const useCurrentUser = createQuery<AuthUser | undefined, {}>({
  primaryKey: 'currentUser',
  queryFn: async ({queryKey: [_, variables]}) => {
    return await fetchCurrentUser();
  },
});
