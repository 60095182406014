import {sanitizeFullUserProfileFromRaw} from '../../../ui/@sanitizers/user-profile';
import {UserProfileUpdateRaw} from '../../../interfaces/user-profile';
import InstillAPI from '../axios-instance';

export interface UpdateMyUserProfilePayload {
  payload: UserProfileUpdateRaw;
  companyUuid: string;
}

export async function updateMeUserProfile(
  userProfilePayload: UserProfileUpdateRaw,
  companyUuid: string
) {
  const response = await InstillAPI.put(
    `companies/${companyUuid}/user-profiles/me`,
    userProfilePayload
  );

  return sanitizeFullUserProfileFromRaw(response.data);
}
