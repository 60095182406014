import {FunctionComponent} from 'react';
import {Meeting} from '../../../../../../../../interfaces/meeting';
import {Transcript} from '../../../../../../../../interfaces/transcript';
import AudioTranscriptPlayer from '../../../../../../../@components/audio-transcript-player';
import CultureVitals from './@components/culture-vitals';
import MeetingFeedback from './@components/meeting-feedback/meeting-feedback';
import Participants from './@components/participants';
import ProcessingData from './@components/processing-data';
import SentimentalAnalysis from './@components/sentimental-analysis';
import Summary from './@components/summary';
import TopicCloud from './@components/topic-cloud';
import ValuesAlignment from './@components/values-alignment';
import styles from './styles.module.scss';

interface ElementProps {
  meeting: Meeting;
  transcript: Transcript[];
}

const MeetingDetails: FunctionComponent<ElementProps> = ({
  meeting,
  transcript,
}) => {
  if (meeting.summary.summary.length === 0) {
    return <ProcessingData />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.column}>
        <div className={styles.row}>
          <div className={styles.rowColumn}>
            <SentimentalAnalysis analysis={meeting.sentimentalAnalysis} />
          </div>

          <div className={styles.rowColumn}>
            <Participants participants={meeting.participantsNames} />
          </div>
        </div>

        <Summary summary={meeting.summary.summary} />

        <CultureVitals cultureVitalSigns={meeting.cultureVitalSigns} />

        <ValuesAlignment values={meeting.valuesAlignment} />

        <div className={styles.topicCloudContainer}>
          <TopicCloud topics={meeting.summary.topics.slice(0, 20)} />
        </div>

        <MeetingFeedback meeting={meeting} />
      </div>

      <div className={styles.transcriptColumn}>
        <AudioTranscriptPlayer transcript={transcript} meeting={meeting} />
      </div>
    </div>
  );
};

export default MeetingDetails;
