import {useTranslation} from 'react-i18next';
import {FunctionComponent} from 'react';
import styles from './styles.module.scss';

interface ElementProps {
  isMessageVisible?: boolean;
  message?: string;
  title?: string;
}

const NoResultsBox: FunctionComponent<ElementProps> = ({
  isMessageVisible = true,
  title,
  message,
}) => {
  const {t} = useTranslation('application');

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {title ? title : t('components.no-results-box.title')}
      </div>

      {isMessageVisible && (
        <div className={styles.text}>
          {message ? message : t('components.no-results-box.text')}
        </div>
      )}
    </div>
  );
};

export default NoResultsBox;
