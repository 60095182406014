import {DimensionHealth} from '../../../../../../../../../../interfaces/organizational-health';
import {FunctionComponent, PropsWithChildren, useMemo} from 'react';
import {Link} from 'react-router-dom';
import styles from './styles.module.scss';

interface ElementProps {
  dimension: DimensionHealth;
  highlightedDimensionSlug: string | null;
  isAllowedToManageAssessments: boolean;
  isDimensionConfigured: boolean;
  onClick: () => void;
}

const LinkItem: FunctionComponent<PropsWithChildren<ElementProps>> = ({
  children,
  dimension,
  highlightedDimensionSlug,
  isAllowedToManageAssessments,
  isDimensionConfigured,
  onClick,
}) => {
  const itemStyles = useMemo(() => {
    if (!highlightedDimensionSlug) return styles.item;

    const isHighlighted = highlightedDimensionSlug === dimension.dimensionSlug;

    return isHighlighted ? styles.highlighted : styles.dimmed;
  }, [dimension, highlightedDimensionSlug]);

  if (!isDimensionConfigured && isAllowedToManageAssessments) {
    return (
      <Link
        to="/application/measure/manage-assessments"
        className={styles.linkItem}
      >
        {children}
      </Link>
    );
  }

  return (
    <div onClick={onClick} className={itemStyles}>
      {children}
    </div>
  );
};

export default LinkItem;
