import {ASSESSMENT_POPULATION} from '../../../../../../../../../../../constants/assessment-population';
import {AssessmentFormProps} from '../assessment-form';
import {
  ChangeEvent,
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {CompanyAssessment} from '../../../../../../../../../../../interfaces/company-assessments';
import {CompanyEmployee} from '../../../../../../../../../../../interfaces/company-employee';
import {EventName} from '../../../../../../../../../../../constants/analytics/event-name';
import {EventType} from '../../../../../../../../../../../constants/analytics/event-type';
import {formatDate} from '../../../../../../../../../../../utils/date';
import {Link} from 'react-router-dom';
import {ReactComponent as IconMessage} from '../../../../../../../../../../@components/kit/icons/message-square.svg';
import {ReactComponent as IconSettings} from '../../../../../../../../../../@components/kit/icons/settings.svg';
import {SCHEDULE_TRANSLATION_MAP} from '../../../../../../../../../../../constants/schedule';
import {
  SendMessageEvent,
  UpdateSettingsEvent,
} from '../../../../../../../../../../../interfaces/analytics/event-properties';
import {Trans, useTranslation} from 'react-i18next';
import DisableAssessmentDialog, {
  DisableAssessmentFormProps,
} from '../disable-assessment-dialog';
import EnableAssessmentDialog from '../enable-assessment-dialog';
import parentStyles from '../../styles.module.scss';
import PopulationDialog from '../population-dialog';
import SendMessageDialog, {
  SendMessageDialogFormProps,
} from '../send-message-dialog';
import styles from './styles.module.scss';
import SwitchToggle from '../../../../../../../../../../@components/kit/form/switch-toggle';
import tableStyles from '../../../../../../../../../../../styles/classes/table.module.scss';
import Tag from '../../../../../../../../../../@components/tag';
import UpdateSettingsDialog from '../update-settings-dialog';
import useAnalytics from '../../../../../../../../../../@hooks/use-analytics';
import {Team} from '../../../../../../../../../../../interfaces/team';

export enum SOURCES {
  ENABLE_ASSESSMENT = 'ENABLE_ASSESSMENT',
  UPDATE_SETTINGS = 'UPDATE_SETTINGS',
}

interface ElementProps {
  companyAssessment: CompanyAssessment;
  companyEmployees: CompanyEmployee[];
  teams: Team[];
  onNameClicked: (companyAssessment: CompanyAssessment) => void;
  onRefreshCompanyAssessments: () => void;
  onUpdateAssessmentSettings: (
    values: AssessmentFormProps,
    companyAssessment: CompanyAssessment
  ) => void;
  onSubmitEnableAssessmentDialog: (
    values: AssessmentFormProps,
    companyAssessment: CompanyAssessment
  ) => void;
  onSubmitDisableAssessmentDialog: (
    values: DisableAssessmentFormProps,
    companyAssessment: CompanyAssessment
  ) => void;
  onSubmitSendMessageDialog: (
    values: SendMessageDialogFormProps,
    companyAssessment: CompanyAssessment
  ) => void;
}

const TableRow: FunctionComponent<ElementProps> = ({
  companyAssessment,
  companyEmployees,
  teams,
  onNameClicked,
  onRefreshCompanyAssessments,
  onSubmitDisableAssessmentDialog,
  onSubmitEnableAssessmentDialog,
  onSubmitSendMessageDialog,
  onUpdateAssessmentSettings,
}) => {
  const {t} = useTranslation('measure');

  const {trackEvent} = useAnalytics();

  const [isActive, setIsActive] = useState(companyAssessment.isActive);
  const [isCalculatingPopulation, setIsCalculatingPopulation] = useState(false);

  const [isEnableAssessmentDialogVisible, setIsEnableAssessementDialogVisible] =
    useState(false);

  const [
    isDisableAssessmentDialogVisible,
    setIsDisableAssessementDialogVisible,
  ] = useState(false);

  const [isUpdateSettingsDialogVisible, setIsUpdateSettingsDialogVisible] =
    useState(false);

  const [isSendMessageDialogVisible, setIsSendMessageDialogVisible] =
    useState(false);

  const [isPopulationDialogVisible, setIsPopulationDialogVisible] =
    useState(false);

  const assessmentCompletedPopulation = useMemo(() => {
    return companyAssessment.completionInfo.filter((info) => info.isCompleted);
  }, [companyAssessment]);

  const scheduleTranslationKey =
    SCHEDULE_TRANSLATION_MAP[
      companyAssessment.schedule as keyof typeof SCHEDULE_TRANSLATION_MAP
    ];

  const onCloseEnableAssessmentDialog = useCallback(() => {
    setIsEnableAssessementDialogVisible(false);
    setIsActive(false);
  }, []);

  const onCloseUpdateSettingsDialog = useCallback(() => {
    setIsUpdateSettingsDialogVisible(false);
  }, []);

  const onCloseDisableAssessmentDialog = useCallback(() => {
    setIsDisableAssessementDialogVisible(false);
    setIsActive(true);
  }, []);

  const onCloseSendMessageDialog = useCallback(() => {
    setIsSendMessageDialogVisible(false);
  }, []);

  const onClosePopulationDialog = useCallback(() => {
    setIsPopulationDialogVisible(false);
  }, []);

  const onUpdateSettings = useCallback(() => {
    setIsUpdateSettingsDialogVisible(true);
  }, []);

  const onSendMessage = useCallback(() => {
    setIsSendMessageDialogVisible(true);
  }, []);

  const onAvailabilityToggleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const isChecked = event?.target.checked;

      if (isChecked) {
        setIsEnableAssessementDialogVisible(true);
        setIsDisableAssessementDialogVisible(false);
      } else {
        setIsEnableAssessementDialogVisible(false);
        setIsDisableAssessementDialogVisible(true);
      }
    },
    []
  );

  const onEnableAssessmentFormSubmit = useCallback(
    async (
      values: AssessmentFormProps,
      companyAssessment: CompanyAssessment
    ) => {
      await onSubmitEnableAssessmentDialog(values, companyAssessment);
      onRefreshCompanyAssessments();

      setIsCalculatingPopulation(true);
      setIsEnableAssessementDialogVisible(false);
      setIsActive(true);
    },
    [onRefreshCompanyAssessments, onSubmitEnableAssessmentDialog]
  );

  const onUpdateSettingsFormSubmit = useCallback(
    async (
      values: AssessmentFormProps,
      companyAssessment: CompanyAssessment
    ) => {
      await onUpdateAssessmentSettings(values, companyAssessment);
      onRefreshCompanyAssessments();

      setIsUpdateSettingsDialogVisible(false);

      const scheduleTranslationKey =
        SCHEDULE_TRANSLATION_MAP[
          values.schedule as keyof typeof SCHEDULE_TRANSLATION_MAP
        ];

      const eventProperties = {
        assessmentName: companyAssessment.assessment.name,
        population: values.isCompanyWide
          ? t('constants.company-wide-label', {ns: 'common'})
          : values.uuids.length,
        schedule: t(scheduleTranslationKey, {ns: 'common'}),
      } as UpdateSettingsEvent;

      trackEvent({
        eventName: EventName.MEASURE.MANAGE_ASSESSMENTS.UPDATE_SETTINGS,
        eventType: EventType.BUTTON_CLICKED,
        eventProperties,
      });
    },
    [onRefreshCompanyAssessments, trackEvent, t, onUpdateAssessmentSettings]
  );

  const onDisableAssessmentFormSubmit = useCallback(
    async (
      values: DisableAssessmentFormProps,
      companyAssessment: CompanyAssessment
    ) => {
      await onSubmitDisableAssessmentDialog(values, companyAssessment);
      onRefreshCompanyAssessments();

      setIsDisableAssessementDialogVisible(false);
      setIsActive(false);
    },
    [onRefreshCompanyAssessments, onSubmitDisableAssessmentDialog]
  );

  const onSendMessageFormSubmit = useCallback(
    async (values: SendMessageDialogFormProps) => {
      await onSubmitSendMessageDialog(values, companyAssessment);

      setIsSendMessageDialogVisible(false);

      const selectedSendToValue = ASSESSMENT_POPULATION.find(
        (population) => population.value === values.sendTo
      );
      if (!selectedSendToValue) return;

      const eventProperties = {
        message: values.message,
        population: t(selectedSendToValue.label, {ns: 'common'}),
      } as SendMessageEvent;

      trackEvent({
        eventName: EventName.MEASURE.MANAGE_ASSESSMENTS.SEND_MESSAGE,
        eventType: EventType.BUTTON_CLICKED,
        eventProperties,
      });
    },
    [trackEvent, t, onSubmitSendMessageDialog, companyAssessment]
  );

  const onPopulationTagClicked = useCallback(() => {
    setIsPopulationDialogVisible(true);

    trackEvent({
      eventName: EventName.MEASURE.MANAGE_ASSESSMENTS.POPULATION_DIALOG,
      eventType: EventType.BUTTON_CLICKED,
    });
  }, [trackEvent]);

  const onViewEventsLinkClicked = useCallback(() => {
    trackEvent({
      eventName: EventName.MEASURE.MANAGE_ASSESSMENTS.VIEW_EVENTS,
      eventType: EventType.BUTTON_CLICKED,
    });
  }, [trackEvent]);

  const populationText = useMemo(() => {
    const population = companyAssessment.population;
    const userProfileUuids = population?.userProfileUuids?.length;

    const translationPrefix =
      'manage-assessments.components.assessments.components.list-view.table.population.';

    // the below conditions must be in the order as they are now
    if (population?.isCompanyWide) {
      return t(`${translationPrefix}company-wide-label`);
    }

    if (!!userProfileUuids && !!population?.teamUuids?.length) {
      return t(`${translationPrefix}teams-colleagues-label`);
    }

    if (!!population?.teamUuids?.length) {
      if (population?.teamUuids?.length > 1) {
        return t(`${translationPrefix}multiple-teams-label`);
      }

      return t(`${translationPrefix}team-wide-label`);
    }

    if (userProfileUuids !== 0) {
      return '';
    }
  }, [companyAssessment, t]);

  return (
    <li className={styles.item}>
      <div className={styles.row}>
        <div className={`${parentStyles.tableData} ${parentStyles.nameColumn}`}>
          <div className={tableStyles.mobileHeaderTitle}>
            {t(
              'manage-assessments.components.assessments.components.list-view.table.header.assessment'
            )}
          </div>

          <div className={styles.toggleContainer}>
            <SwitchToggle
              isChecked={isActive}
              onChange={onAvailabilityToggleChange}
            />
          </div>

          {companyAssessment.assessment.overview ? (
            <Tag onClick={() => onNameClicked(companyAssessment)}>
              {companyAssessment.assessment.name}
            </Tag>
          ) : (
            <Tag>{companyAssessment.assessment.name}</Tag>
          )}
        </div>

        <div
          className={`${parentStyles.tableData} ${parentStyles.scheduleColumn}`}
        >
          <div className={tableStyles.mobileHeaderTitle}>
            {t(
              'manage-assessments.components.assessments.components.list-view.table.header.schedule'
            )}
          </div>

          {companyAssessment.schedule ? (
            <Tag>{t(scheduleTranslationKey, {ns: 'common'})}</Tag>
          ) : (
            <Tag variant="disabled">
              {t(
                'manage-assessments.components.assessments.components.list-view.table.non-applicable'
              )}
            </Tag>
          )}
        </div>

        <div
          className={`${parentStyles.tableData} ${parentStyles.startDateColumn}`}
        >
          <div className={tableStyles.mobileHeaderTitle}>
            {t(
              'manage-assessments.components.assessments.components.list-view.table.header.start-date'
            )}
          </div>

          {isActive && companyAssessment.dateInitiated ? (
            <Tag>{formatDate(companyAssessment.dateInitiated)}</Tag>
          ) : (
            <Tag variant="disabled">
              {t(
                'manage-assessments.components.assessments.components.list-view.table.non-applicable'
              )}
            </Tag>
          )}
        </div>

        <div
          className={`${parentStyles.tableData} ${parentStyles.populationColumn}`}
        >
          <div className={tableStyles.mobileHeaderTitle}>
            {t(
              'manage-assessments.components.assessments.components.list-view.table.header.population'
            )}
          </div>

          {isCalculatingPopulation ? (
            <Tag variant="disabled">
              {t(
                'manage-assessments.components.assessments.components.list-view.calculating-population-label'
              )}
            </Tag>
          ) : (
            <>
              {isActive ? (
                <Tag onClick={onPopulationTagClicked}>
                  <div className={styles.pillWidth}>
                    {!!populationText ? (
                      <>{populationText}</>
                    ) : (
                      <Trans
                        t={t}
                        i18nKey="manage-assessments.components.assessments.components.list-view.table.population.label"
                        values={{
                          length: assessmentCompletedPopulation.length,
                          totalLength: companyAssessment.completionInfo.length,
                        }}
                      />
                    )}
                  </div>
                </Tag>
              ) : (
                <Tag variant="disabled">
                  {t(
                    'manage-assessments.components.assessments.components.list-view.table.non-applicable'
                  )}
                </Tag>
              )}
            </>
          )}
        </div>

        <div
          className={`${parentStyles.tableData} ${parentStyles.actionsColumn}`}
        >
          <div className={tableStyles.mobileHeaderTitle}>
            {t(
              'manage-assessments.components.assessments.components.list-view.table.header.actions'
            )}
          </div>

          <Link
            className={styles.actionLink}
            onClick={onViewEventsLinkClicked}
            to={companyAssessment.assessment.uuid}
          >
            {t(
              'manage-assessments.components.assessments.components.list-view.table.actions.view-events-button'
            )}
          </Link>

          <button
            type="button"
            disabled={!isActive}
            onClick={onUpdateSettings}
            className={styles.actionButton}
          >
            <div className={styles.buttonContainer}>
              {t(
                'manage-assessments.components.assessments.components.list-view.table.actions.settings-button'
              )}

              <IconSettings className={styles.buttonIcon} />
            </div>
          </button>

          <button
            type="button"
            disabled={!isActive}
            onClick={onSendMessage}
            className={styles.actionButton}
          >
            <div className={styles.buttonContainer}>
              {t(
                'manage-assessments.components.assessments.components.list-view.table.actions.send-message-button'
              )}

              <IconMessage className={styles.buttonIcon} />
            </div>
          </button>
        </div>
      </div>

      <EnableAssessmentDialog
        companyAssessment={companyAssessment}
        companyEmployees={companyEmployees}
        teams={teams}
        isVisible={isEnableAssessmentDialogVisible}
        onClose={onCloseEnableAssessmentDialog}
        onSubmit={onEnableAssessmentFormSubmit}
      />

      <DisableAssessmentDialog
        companyAssessment={companyAssessment}
        isVisible={isDisableAssessmentDialogVisible}
        onClose={onCloseDisableAssessmentDialog}
        onSubmit={onDisableAssessmentFormSubmit}
      />

      <UpdateSettingsDialog
        teams={teams}
        companyAssessment={companyAssessment}
        companyEmployees={companyEmployees}
        isVisible={isUpdateSettingsDialogVisible}
        onClose={onCloseUpdateSettingsDialog}
        onSubmit={onUpdateSettingsFormSubmit}
      />

      <SendMessageDialog
        companyAssessment={companyAssessment}
        isVisible={isSendMessageDialogVisible}
        onClose={onCloseSendMessageDialog}
        onSubmit={onSendMessageFormSubmit}
      />

      <PopulationDialog
        companyAssessment={companyAssessment}
        isVisible={isPopulationDialogVisible}
        onClose={onClosePopulationDialog}
      />
    </li>
  );
};

export default TableRow;
