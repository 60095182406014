import {EventName} from '../../../../../../../../../../../../constants/analytics/event-name';
import {EventType} from '../../../../../../../../../../../../constants/analytics/event-type';
import {FunctionComponent, useCallback} from 'react';
import {Link} from 'react-router-dom';
import {sortByName} from '../../../../../../../../../../../../utils/sort-by/name';
import {Team} from '../../../../../../../../../../../../interfaces/team';
import {useTranslation} from 'react-i18next';
import styles from './styles.module.scss';
import useAnalytics from '../../../../../../../../../../../@hooks/use-analytics';

interface ElementProps {
  team: Team;
}

const TeamSummary: FunctionComponent<ElementProps> = ({team}) => {
  const {t} = useTranslation('people', {
    keyPrefix:
      'teams.components.team-list.components.list-view.components.details-dialog',
  });

  const {trackEvent} = useAnalytics();

  const orderedTeamMembers = sortByName(team.members);

  const trackClickOnProfile = useCallback(() => {
    trackEvent({
      eventName: EventName.PEOPLE.TEAMS.DETAILS_DIALOG.PROFILE,
      eventType: EventType.BUTTON_CLICKED,
    });
  }, [trackEvent]);

  return (
    <ul className={styles.list}>
      <li>
        <div className={styles.itemLabel}>{t('labels.description')}</div>

        <div className={styles.itemValue}>{team.description}</div>
      </li>

      <li>
        <div className={styles.itemLabel}>{t('labels.manager')}</div>

        <div className={styles.itemValue}>
          {team.manager ? (
            <Link
              to={`/application/people/employees/${team.manager.uuid}`}
              className={styles.itemLink}
              onClick={trackClickOnProfile}
            >
              {team.manager.name}
            </Link>
          ) : (
            <div>—</div>
          )}
        </div>
      </li>

      <li>
        <div className={styles.itemLabel}>
          {t('labels.members', {count: team.members.length})}
        </div>

        <div className={styles.itemValue}>
          <ul className={styles.subList}>
            {orderedTeamMembers.map((employee, index) => (
              <li key={index} className={styles.subListItem}>
                <Link
                  to={`/application/people/employees/${employee.uuid}`}
                  className={styles.itemLink}
                  onClick={trackClickOnProfile}
                >
                  {employee.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </li>
    </ul>
  );
};

export default TeamSummary;
