import {convertFromHTML, convertToHTML} from 'draft-convert';
import {Editor} from 'react-draft-wysiwyg';
import {EditorState} from 'draft-js';
import {FunctionComponent, useCallback, useState} from 'react';
import {isRichTextFilled} from '../../../../../utils/html-helpers';
import {useFormControlContext} from '../form-control';
import styles from './styles.module.scss';

interface ElementProps {
  disabled?: boolean;
  initialValue: string;
  onChange: (value: string) => void;
  placeholder?: string;
}

const RichTextEditor: FunctionComponent<ElementProps> = ({
  disabled = false,
  initialValue,
  onChange,
  placeholder = '',
}) => {
  const [editorState, setEditorState] = useState(() => {
    if (!initialValue) return EditorState.createEmpty();
    return EditorState.createWithContent(convertFromHTML(initialValue));
  });

  const {hasError} = useFormControlContext();

  let wrapperClassName = styles.editorWrapper;

  if (hasError) {
    wrapperClassName = `${wrapperClassName} ${styles.error}`;
  }

  const onEditorStateChange = useCallback(
    (state: EditorState) => {
      if (disabled) return;

      setEditorState(state);
      const htmlContent = convertToHTML(state.getCurrentContent());

      if (!isRichTextFilled(htmlContent)) return onChange('');

      onChange(htmlContent);
    },
    [onChange, disabled]
  );

  return (
    <Editor
      placeholder={placeholder}
      editorState={editorState}
      wrapperClassName={wrapperClassName}
      editorClassName={styles.editor}
      toolbarClassName={styles.editorToolbar}
      onEditorStateChange={onEditorStateChange}
      toolbar={{
        options: ['inline'],
        inline: {
          options: ['bold', 'italic', 'underline'],
        },
      }}
    />
  );
};

export default RichTextEditor;
