import {sanitizeCandidateAlignmentResultFromRaw} from '../../../ui/@sanitizers/candidate-alignment-result';
import InstillAPI from '../axios-instance';
import isResponseSuccessful from '../../../utils/is-response-successful';

export interface FetchCandidateAlignmentResultPayload {
  candidateUuid?: string;
}

export async function fetchCandidateAlignmentResult(candidateUuid: string) {
  try {
    const response = await InstillAPI.get(
      `/user-assessments/candidate/${candidateUuid}/result`
    );

    if (!response.data || !isResponseSuccessful(response)) {
      throw Error('No data received');
    }

    return sanitizeCandidateAlignmentResultFromRaw(response.data.module_result);
  } catch (error) {
    console.error(error);
  }
}
