import {
  sanitizeTeamForRaw,
  sanitizeTeamFromRaw,
} from '../../../ui/@sanitizers/teams';
import {TeamUpsert} from '../../../interfaces/team';
import InstillAPI from '../axios-instance';

export interface UpdateTeamPayload {
  companyUuid: string;
  teamUuid: string;
  payload: TeamUpsert;
}

export async function updateTeam(
  teamPayload: TeamUpsert,
  teamUuid: string,
  companyUuid: string
) {
  const updatedPayload = sanitizeTeamForRaw(teamPayload);

  const response = await InstillAPI.put(
    `/companies/${companyUuid}/teams/${teamUuid}`,
    updatedPayload
  );

  if (!response) return;

  return sanitizeTeamFromRaw(response.data);
}
