import {createQuery} from 'react-query-kit';
import {fetchNotificationSettings} from '../../../repositories/instill';
import {NotificationSettings} from '../../../interfaces/notification-settings';

export const useNotificationSettings = createQuery<
  NotificationSettings | undefined,
  {companyUuid: string}
>({
  primaryKey: 'notificationSettings',
  queryFn: async ({queryKey: [_, variables]}) => {
    return fetchNotificationSettings(variables.companyUuid);
  },
});
