import {
  SCHEDULE,
  SCHEDULE_TRANSLATION_MAP,
} from '../../../../../../../../../../../constants/schedule';
import {CompanyAssessment} from '../../../../../../../../../../../interfaces/company-assessments';
import {CompanyEmployee} from '../../../../../../../../../../../interfaces/company-employee';
import {Field, FieldProps, Form, Formik} from 'formik';
import {FunctionComponent, useCallback, useMemo} from 'react';
import {MutableSelect} from '../../../../../../../../../../@components/kit/form/hybrid-select/mutable-select';
import {ReactComponent as IconCalendar} from '../../../../../../../../../../@components/kit/icons/calendar.svg';
import {SOURCES} from '../table-row';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import AutoresizeTextarea from '../../../../../../../../../../@components/kit/form/autoresize-textarea';
import Button from '../../../../../../../../../../@components/kit/form/button';
import DatePicker from 'react-datepicker';
import FormControl from '../../../../../../../../../../@components/kit/form/form-control';
import FormFooter from '../../../../../../../../../../@components/kit/form/form-footer';
import FormGroup from '../../../../../../../../../../@components/kit/form/form-group';
import InputCheckbox from '../../../../../../../../../../@components/kit/form/input-checkbox';
import Label from '../../../../../../../../../../@components/kit/form/label';
import Select from '../../../../../../../../../../@components/kit/form/select';
import styles from './styles.module.scss';
import {Team} from '../../../../../../../../../../../interfaces/team';

interface ElementProps {
  companyAssessment: CompanyAssessment;
  companyEmployees: CompanyEmployee[];
  teams: Team[];
  source: SOURCES;
  onClose: () => void;
  onSubmit: (
    values: AssessmentFormProps,
    companyAssessment: CompanyAssessment
  ) => void;
}

const ADDITIONAL_NUMBER_OF_DAYS_FOR_DUE_DATE = 30;

export interface AssessmentFormProps {
  dueDate: Date;
  message: string;
  schedule: string;
  isCompanyWide: boolean;
  uuids: string[];
}

const TODAY_DATE = new Date();

const AssessmentForm: FunctionComponent<ElementProps> = ({
  companyAssessment,
  companyEmployees,
  teams,
  onClose,
  onSubmit,
  source,
}) => {
  const {t} = useTranslation('measure');

  const INITIAL_VALUES: AssessmentFormProps = {
    schedule: companyAssessment.schedule ?? '',
    dueDate: companyAssessment.dueDate
      ? new Date(companyAssessment.dueDate)
      : new Date(
          TODAY_DATE.setDate(
            TODAY_DATE.getDate() + ADDITIONAL_NUMBER_OF_DAYS_FOR_DUE_DATE
          )
        ),
    message: '',
    isCompanyWide: companyAssessment.population?.isCompanyWide ?? true,
    uuids: [
      ...(companyAssessment.population?.userProfileUuids ?? []),
      ...(companyAssessment.population?.teamUuids ?? []),
    ],
  };

  const isSourceEqualToEnableAssessment = source === SOURCES.ENABLE_ASSESSMENT;
  const isScheduleDisabled = companyAssessment.isActive;

  const supportedSchedules = useMemo(() => {
    return companyAssessment.assessment.supportedSchedules.map((schedule) => {
      const translationKey =
        SCHEDULE_TRANSLATION_MAP[
          schedule as keyof typeof SCHEDULE_TRANSLATION_MAP
        ];

      return {
        key: schedule,
        value: t(translationKey, {ns: 'common'}),
      };
    });
  }, [companyAssessment, t]);

  const employeesForDropdown = useMemo(() => {
    return companyEmployees.map((employee) => {
      return {
        label: employee.name,
        value: employee.userProfileUuid,
      };
    });
  }, [companyEmployees]);

  const teamsForDropdown = useMemo(() => {
    return teams.map((team) => {
      return {
        label: team.name,
        value: team.uuid,
      };
    });
  }, [teams]);

  const selectedTeamAndEmployees = useMemo(() => {
    const userProfilesUuids = companyAssessment.population?.userProfileUuids;
    const teamsUuids = companyAssessment.population?.teamUuids;

    if (!userProfilesUuids && !teamsUuids) return;

    const employees = employeesForDropdown.filter((employee) => {
      return userProfilesUuids?.includes(employee.value);
    });
    const teams = teamsForDropdown.filter((team) => {
      return teamsUuids?.includes(team.value);
    });

    return [...teams, ...employees];
  }, [employeesForDropdown, teamsForDropdown, companyAssessment]);

  const validationSchema = Yup.object().shape({
    schedule: Yup.string().required(t('form.error.required', {ns: 'common'})),
    dueDate: Yup.date()
      .min(new Date())
      .required(t('form.error.required', {ns: 'common'})),
    message: Yup.string().max(255).nullable(),
  });

  const onDueDateChanged = useCallback(
    (form: FieldProps['form'], field: FieldProps['field'], value: Date) => {
      form.setFieldValue(field.name, value);
    },
    []
  );

  const onFormSubmit = useCallback(
    (values: AssessmentFormProps) => {
      onSubmit(values, companyAssessment);
    },
    [onSubmit, companyAssessment]
  );

  const groupedOptions = [
    {
      label: t(
        'manage-assessments.components.assessments.components.assessment-form.population.drop-down.teams'
      ),
      options: teamsForDropdown,
    },
    {
      label: t(
        'manage-assessments.components.assessments.components.assessment-form.population.drop-down.employees'
      ),
      options: employeesForDropdown,
    },
  ];

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={onFormSubmit}
    >
      {({isValid, values}) => {
        const isDueDateVisible = values.schedule === SCHEDULE.ONE_TIME;

        return (
          <Form>
            <Field name="schedule">
              {({field}: FieldProps<string>) => (
                <FormGroup>
                  <FormControl>
                    <Label isRequired>
                      {t(
                        'manage-assessments.components.assessments.components.assessment-form.schedule.label'
                      )}
                    </Label>

                    {isScheduleDisabled && (
                      <div className={styles.disabledMessage}>
                        {t(
                          'manage-assessments.components.assessments.components.assessment-form.schedule.disabled-message'
                        )}
                      </div>
                    )}

                    <Select
                      disabled={isScheduleDisabled}
                      id={field.name}
                      {...field}
                    >
                      <option value="" disabled>
                        {t(
                          'manage-assessments.components.assessments.components.assessment-form.schedule.placeholder'
                        )}
                      </option>

                      {supportedSchedules.map((schedule, index) => (
                        <option key={index} value={schedule.key}>
                          {schedule.value}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </FormGroup>
              )}
            </Field>

            <FormGroup>
              <Field name="isCompanyWide">
                {({field}: FieldProps<string>) => (
                  <FormControl>
                    <Label isRequired>
                      {t(
                        'manage-assessments.components.assessments.components.assessment-form.population.label'
                      )}
                    </Label>

                    <InputCheckbox
                      checked={values.isCompanyWide}
                      label={t(
                        'manage-assessments.components.assessments.components.assessment-form.population.company-wide-label'
                      )}
                      {...field}
                    />
                  </FormControl>
                )}
              </Field>

              <div className={styles.employeeSelect}>
                <Field name="uuids">
                  {({form, field, meta}: FieldProps<string>) => (
                    <FormControl>
                      <MutableSelect
                        field={field}
                        form={form}
                        meta={meta}
                        disabled={form.values.isCompanyWide}
                        options={groupedOptions}
                        defaultValue={selectedTeamAndEmployees}
                        placeholder={t(
                          'manage-assessments.components.assessments.components.assessment-form.population.placeholder'
                        )}
                        renderValues={true}
                        isMulti={true}
                      />
                    </FormControl>
                  )}
                </Field>
              </div>
            </FormGroup>

            {isDueDateVisible && (
              <Field name="dueDate">
                {({form, field}: FieldProps<string>) => (
                  <FormGroup>
                    <FormControl>
                      <Label isRequired>
                        {t(
                          'manage-assessments.components.assessments.components.assessment-form.due-date.label'
                        )}
                      </Label>

                      <div className={styles.calendarContainer}>
                        <DatePicker
                          minDate={new Date()}
                          onChange={(value: Date) =>
                            onDueDateChanged(form, field, value)
                          }
                          required
                          selected={values.dueDate}
                        />

                        <IconCalendar className={styles.calendarIcon} />
                      </div>
                    </FormControl>
                  </FormGroup>
                )}
              </Field>
            )}

            {isSourceEqualToEnableAssessment && (
              <Field name="message">
                {({field}: FieldProps<string>) => (
                  <FormGroup>
                    <FormControl>
                      <Label>
                        {t(
                          'manage-assessments.components.assessments.components.assessment-form.message.label'
                        )}
                      </Label>

                      <AutoresizeTextarea
                        {...field}
                        placeholder={t(
                          'manage-assessments.components.assessments.components.assessment-form.message.placeholder'
                        )}
                        maxLength={255}
                      />
                    </FormControl>
                  </FormGroup>
                )}
              </Field>
            )}

            <FormFooter>
              <Button variant="secondary" onClick={onClose}>
                {t(
                  'manage-assessments.components.assessments.components.assessment-form.cancel-button'
                )}
              </Button>

              <Button disabled={!isValid} type="submit">
                {isSourceEqualToEnableAssessment ? (
                  <>
                    {t(
                      'manage-assessments.components.assessments.components.assessment-form.submit-button'
                    )}
                  </>
                ) : (
                  <>
                    {t(
                      'manage-assessments.components.assessments.components.assessment-form.update-settings-button'
                    )}
                  </>
                )}
              </Button>
            </FormFooter>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AssessmentForm;
