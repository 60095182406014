import InstillAPI from '../axios-instance';
import isResponseSuccessful from '../../../utils/is-response-successful';
import {sanitizeCoreValuesFromRaw} from '../../../ui/@sanitizers/core-values';

export async function fetchCoreValues() {
  try {
    const response = await InstillAPI.get('/values/');

    if (!response.data || !isResponseSuccessful(response)) {
      throw Error('No data received');
    }

    return sanitizeCoreValuesFromRaw(response.data);
  } catch (error) {
    console.error(error);
  }
}
