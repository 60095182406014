import {Field, Form, Formik} from 'formik';
import {FunctionComponent, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import {MultipleCheckboxOptions} from '../../../../../interfaces/question';
import Button from '../../form/button/button';
import FormControl from '../../form/form-control';
import InputCheckbox from '../../form/input-checkbox';
import PreviousQuestionLink from '../@components/previous-question-link';
import styles from './styles.module.scss';

interface ElementProps {
  questionText: string;
  options: MultipleCheckboxOptions;
  onResponse: (value: string) => void;
  onPreviousLinkClick?: () => void;
  answer?: string;
}

interface FormValues {
  answer: string[];
}

const MultipleCheckbox: FunctionComponent<ElementProps> = ({
  questionText,
  options,
  onResponse,
  onPreviousLinkClick,
  answer,
}) => {
  const {t} = useTranslation('components');

  const answerArrayToString = useCallback((answerArray: string[]) => {
    if (answerArray.length === 0) return;

    return answerArray.join(';');
  }, []);

  const answerStringToArray = (answerString: string | undefined) => {
    if (!answerString) return;

    return answerString.split(';');
  };

  const INITIAL_VALUES = {
    answer: answerStringToArray(answer) || [],
  };

  const validationSchema = Yup.object().shape({
    answer: Yup.array().required().min(1),
  });

  const onSubmit = useCallback(
    (values: FormValues) => {
      const answerString = answerArrayToString(values.answer);

      if (!answerString) return;

      onResponse(answerString);
    },
    [answerArrayToString, onResponse]
  );

  return (
    <>
      <div className={styles.container}>
        {onPreviousLinkClick ? (
          <PreviousQuestionLink onLinkClick={onPreviousLinkClick} />
        ) : null}

        <div className={styles.question}>{questionText}</div>

        <Formik
          initialValues={INITIAL_VALUES}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          validateOnMount
        >
          {({values, errors, touched, isValid}) => (
            <Form>
              <FormControl
                error={
                  Boolean(errors.answer && touched.answer)
                    ? t('questionnaire.multiple-checkbox.form-error')
                    : undefined
                }
              >
                <div className={styles.checkboxContainer}>
                  {options.choices.map((option) => (
                    <Field
                      name="answer"
                      key={option}
                      as={InputCheckbox}
                      label={option}
                      value={option}
                      defaultChecked={values.answer.includes(option)}
                    />
                  ))}
                </div>
              </FormControl>

              <Button type="submit" disabled={!isValid} variant="secondary">
                {t('questionnaire.multiple-checkbox.next-button')}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default MultipleCheckbox;
