import {NavLink} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import styles from './styles.module.scss';
import logoSource from '../../../../assets/images/logo-white.png';

function Error() {
  const {t} = useTranslation('pageNotFound');

  return (
    <>
      <div>
        <img src={logoSource} className={styles.logo} alt="" />

        <h1 className={styles.headerText}>{t('title')}</h1>

        <p className={styles.text}>{t('text')}</p>

        <div>
          <NavLink to="/application/dashboard" className={styles.link}>
            {t('return-home-button-text')}
          </NavLink>
        </div>
      </div>
    </>
  );
}

export default Error;
