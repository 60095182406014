import {Field, FieldProps, useFormikContext} from 'formik';
import {FullUserProfile} from '../../../../../../interfaces/user-profile';
import {FunctionComponent} from 'react';
import {PROFILE_DETAILS_FORM_VALUES} from '../../profile-details';
import {useTranslation} from 'react-i18next';
import {useWorkStyleValues} from '../../../../../@hooks/queries';
import {WORK_STYLE_TRANSLATION_MAP} from '../../../../../../constants/work-style-preferences';
import AverageResponseTimes from './@components/average-response-times';
import FeedbackPreferences from './@components/feedback-preferences';
import FormControl from '../../../../../@components/kit/form/form-control';
import profileStyles from '../../styles.module.scss';
import Select from '../../../../../@components/kit/form/select';
import Tag from '../../../../../@components/tag';
import WorkHours from './@components/work-hours';

interface ElementProps {
  isInEditMode: boolean;
  profile: FullUserProfile;
}

const WorkStyle: FunctionComponent<ElementProps> = ({
  isInEditMode,
  profile,
}) => {
  const {t} = useTranslation(['application', 'common']);

  const {workStylePreferences} = profile;

  const {errors, touched} = useFormikContext<PROFILE_DETAILS_FORM_VALUES>();

  const workStyleValues = useWorkStyleValues({
    variables: {},
  });

  return (
    <div
      className={`${profileStyles.workStylePreferences} ${
        isInEditMode ? profileStyles.editBox : ''
      }`}
    >
      <h2 className={profileStyles.boxTitle}>
        {t('components.profile-details.components.work-style.title')}
      </h2>

      <ul>
        <li className={profileStyles.boxItem}>
          <div className={profileStyles.boxItemKey}>
            {t('components.profile-details.keys.work-hours')}
          </div>

          <div className={profileStyles.boxItemValue}>
            <WorkHours profile={profile} isInEditMode={isInEditMode} />
          </div>
        </li>

        <li className={profileStyles.boxItem}>
          <div className={profileStyles.boxItemKey}>
            {t('components.profile-details.keys.workplace-environment')}
          </div>

          <div className={profileStyles.boxItemValue}>
            {isInEditMode ? (
              <Field name="workStylePreferences.workPlace">
                {({field}: FieldProps<string>) => (
                  <FormControl
                    error={
                      Boolean(errors.shortBio && touched.shortBio)
                        ? errors.shortBio
                        : undefined
                    }
                  >
                    <Select id={field.name} {...field}>
                      {workStyleValues?.data?.workPlace.map((value) => {
                        return (
                          <option key={value} value={value}>
                            {t(
                              WORK_STYLE_TRANSLATION_MAP[
                                value as keyof typeof WORK_STYLE_TRANSLATION_MAP
                              ],
                              {ns: 'common'}
                            )}
                          </option>
                        );
                      })}
                    </Select>
                  </FormControl>
                )}
              </Field>
            ) : (
              <>
                {workStylePreferences?.workPlace ? (
                  <Tag>
                    {t(
                      WORK_STYLE_TRANSLATION_MAP[
                        workStylePreferences?.workPlace as keyof typeof WORK_STYLE_TRANSLATION_MAP
                      ],
                      {ns: 'common'}
                    )}
                  </Tag>
                ) : (
                  <div className={profileStyles.emptyValue}>–</div>
                )}
              </>
            )}
          </div>
        </li>

        <li className={profileStyles.boxItem}>
          <div className={profileStyles.boxItemKey}>
            {t('components.profile-details.keys.preferred-cadence')}
          </div>

          <div className={profileStyles.boxItemValue}>
            {isInEditMode ? (
              <Field name="workStylePreferences.oneOnOneCadence">
                {({field}: FieldProps<string>) => (
                  <Select id={field.name} {...field}>
                    {workStyleValues?.data?.oneOnOneCadence.map((value) => {
                      return (
                        <option key={value} value={value}>
                          {t(
                            WORK_STYLE_TRANSLATION_MAP[
                              value as keyof typeof WORK_STYLE_TRANSLATION_MAP
                            ],
                            {ns: 'common'}
                          )}
                        </option>
                      );
                    })}
                  </Select>
                )}
              </Field>
            ) : (
              <>
                {workStylePreferences?.oneOnOneCadence ? (
                  <Tag>
                    {t(
                      WORK_STYLE_TRANSLATION_MAP[
                        workStylePreferences?.oneOnOneCadence as keyof typeof WORK_STYLE_TRANSLATION_MAP
                      ],
                      {ns: 'common'}
                    )}
                  </Tag>
                ) : (
                  <div className={profileStyles.emptyValue}>–</div>
                )}
              </>
            )}
          </div>
        </li>

        <li className={profileStyles.boxItem}>
          <div className={profileStyles.boxItemKey}>
            {t(
              'constants.work-style-preferences.keys.average-response-times.title',
              {ns: 'common'}
            )}
          </div>

          <div className={profileStyles.boxItemValue}>
            {workStylePreferences?.averageResponseTimes || isInEditMode ? (
              <AverageResponseTimes
                isInEditMode={isInEditMode}
                profile={profile}
                workStyleValues={workStyleValues.data}
              />
            ) : (
              <div className={profileStyles.emptyValue}>–</div>
            )}
          </div>
        </li>

        <li className={profileStyles.boxItem}>
          <div className={profileStyles.boxItemKey}>
            {t(
              'constants.work-style-preferences.keys.feedback-preferences.title',
              {ns: 'common'}
            )}
          </div>

          <div className={profileStyles.boxItemValue}>
            {workStylePreferences?.feedbackPreferences || isInEditMode ? (
              <FeedbackPreferences
                isInEditMode={isInEditMode}
                profile={profile}
                workStyleValues={workStyleValues.data}
              />
            ) : (
              <div className={profileStyles.emptyValue}>–</div>
            )}
          </div>
        </li>
      </ul>
    </div>
  );
};

export default WorkStyle;
