import {createQuery} from 'react-query-kit';
import {fetchNotifications} from '../../../repositories/instill/queries/fetch-notifications';
import {Notification} from '../../../interfaces/notification';
import {NotificationMeta} from '../../@sanitizers/notification';
import getOffsetValue from '../../../utils/get-offset-value/get-offset-value';

interface FetchNotifications {
  data: Notification[] | undefined;
  meta: NotificationMeta;
}

interface FetchNotificationsPayload {
  currentPage: number;
  companyUuid: string;
  itemsPerPage: number;
  limit: number;
  offset: number;
}

export const useNotifications = createQuery<
  FetchNotifications | undefined,
  FetchNotificationsPayload
>({
  primaryKey: 'notifications',
  queryFn: async ({queryKey: [_, variables]}) => {
    const payload = {
      offset: getOffsetValue({
        offset: variables.offset,
        currentPage: variables.currentPage,
        itemsPerPage: variables.itemsPerPage,
      }),
      limit: variables.limit,
      companyUuid: variables.companyUuid,
    } as FetchNotificationsPayload;

    return fetchNotifications(payload);
  },
});
