import {CoreValueForm} from '../../../../../../../../interfaces/company-core-value';
import {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import Button from '../../../../../../../@components/kit/form/button';
import Dialog from '../../../../../../../@components/kit/dialog';
import logoSource from '../../../../../../../../assets/images/logo-icon.png';
import styles from './styles.module.scss';

interface ElementProps {
  isVisible: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  value: CoreValueForm | null;
}

const DeleteValueConfirmationDialog: FunctionComponent<ElementProps> = ({
  onClose,
  isVisible,
  onConfirm,
  value,
}) => {
  const {t} = useTranslation('organizationSetup');

  if (!value) return null;

  const textTranslation = value.name
    ? t('codify-your-values.components.delete-value-confirmation-dialog.text', {
        name: value.name,
      })
    : t(
        'codify-your-values.components.delete-value-confirmation-dialog.text-no-value'
      );

  return (
    <>
      <Dialog isOpen={isVisible} onClose={onClose}>
        <Dialog.Header />

        <Dialog.Content variant="spaceless">
          <div className={styles.container}>
            <img src={logoSource} alt="" className={styles.icon} />

            <div className={styles.title}>
              {t(
                'codify-your-values.components.delete-value-confirmation-dialog.title'
              )}
            </div>

            <div className={styles.text}>{textTranslation}</div>

            <div className={styles.footer}>
              <Button onClick={onClose} variant="secondary">
                {t(
                  'codify-your-values.components.delete-value-confirmation-dialog.cancel-button'
                )}
              </Button>

              <Button onClick={onConfirm}>
                {t(
                  'codify-your-values.components.delete-value-confirmation-dialog.confirm-button'
                )}
              </Button>
            </div>
          </div>
        </Dialog.Content>
      </Dialog>
    </>
  );
};

export default DeleteValueConfirmationDialog;
