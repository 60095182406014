import InstillAPI from '../axios-instance';
import {WorkStylePreferencesRaw} from '../../../interfaces/work-style-preferences';

export interface UpdateWorkStylePreferencesPayload {
  companyUuid: string;
  workStylePreferences: WorkStylePreferencesRaw;
}

export async function updateWorkStylePreferences(
  payload: WorkStylePreferencesRaw,
  companyUuid: string
) {
  const response = await InstillAPI.put(
    `/companies/${companyUuid}/user-profiles/me/work-style-preferences`,
    payload
  );

  return response.data;
}
