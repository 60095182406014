import {CandidateProfile} from '../../../interfaces/candidate';
import {createMutation} from 'react-query-kit';
import {updateCandidateProfile} from '../../../repositories/instill';
import {UpdateCandidateProfilePayload} from '../../../repositories/instill/mutations/update-candidate-profile';

export const useUpdateCandidateProfile = createMutation<
  CandidateProfile | undefined,
  UpdateCandidateProfilePayload
>({
  mutationFn: async (variables) => {
    return await updateCandidateProfile({
      candidateToken: variables.candidateToken,
      name: variables.name,
    });
  },
});
