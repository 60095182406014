import InstillAPI from '../axios-instance';
import isResponseSuccessful from '../../../utils/is-response-successful';
import {sanitizeUserReportFromRaw} from '../../../ui/@sanitizers/user-report';

export interface FetchReportsPayload {
  companyUuid: string;
}

export async function fetchReports(companyUuid: string) {
  try {
    const queryParam = `company_uuid=${companyUuid}`;
    const response = await InstillAPI.get(
      `/user-assessments/me/available-reports?${queryParam}`
    );

    if (!response.data || !isResponseSuccessful(response)) {
      throw Error('No data received');
    }

    return sanitizeUserReportFromRaw(response.data);
  } catch (error) {
    console.error(error);
  }
}
