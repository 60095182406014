import {CompanyCreate} from '../../interfaces/company';
import {fetchCurrentUser} from '../../repositories/instill';
import {Formik} from 'formik';
import {FunctionComponent, useCallback} from 'react';
import {Navigate, Route, Routes, useNavigate} from 'react-router';
import {SELECTED_COMPANY_UUID_KEY} from '../../constants/local-storage-keys';
import {useCreateCompany} from '../@hooks/mutations';
import {useCurrentAuthUserState} from '../@atoms/current-auth-user';
import {useCurrentCompanyState} from '../@atoms/current-company';
import {WEBSITE_URL_REGEX} from '../../constants/regex';
import * as Yup from 'yup';
import CompanyDetails from './company-details';
import EnterYourName from './enter-your-name';
import getFullUrl from '../../utils/get-full-url';
import logoSource from '../../assets/images/logo.png';
import PersonalInformation from './personal-information';
import styles from './styles.module.scss';

export interface SignupFormValues extends CompanyCreate {
  adminName: string;
  name: string;
  role: string;
}

const INITIAL_VALUES: SignupFormValues = {
  adminName: '',
  companyLogo: '',
  industry: '',
  isCultureDefined: true,
  name: '',
  role: '',
  sizeRange: '',
  websiteUrl: '',
};

const DASHBOARD_ROUTE = '/application/dashboard';

const signupFormValidationSchema = Yup.object()
  .shape({
    adminName: Yup.string().required('form.error.required'),
    industry: Yup.string().required('form.error.required'),
    isCultureDefined: Yup.string().oneOf(
      ['true', 'false'],
      'form.error.required'
    ),
    name: Yup.string().required('form.error.required'),
    role: Yup.string().required('form.error.required'),
    sizeRange: Yup.string().required('form.error.required'),
    websiteUrl: Yup.string()
      .matches(WEBSITE_URL_REGEX, 'form.error.required')
      .nullable(),
  })
  .defined();

const RegisterCompany: FunctionComponent = () => {
  const [, setCurrentCompany] = useCurrentCompanyState();
  const [currentAuthUser, setCurrentAuthUser] = useCurrentAuthUserState();
  const navigate = useNavigate();

  const createCompany = useCreateCompany();

  const onSubmit = useCallback(
    async (values: CompanyCreate) => {
      let payload = {
        ...values,
        websiteUrl: getFullUrl(values.websiteUrl),
      } as CompanyCreate;

      if (values.companyLogo) {
        payload.companyLogo = values.companyLogo;
      }

      const response = await createCompany.mutateAsync({
        companyPayload: payload,
      });
      if (!response) return;

      setCurrentCompany(response);

      if (!currentAuthUser?.userProfiles.length) {
        const user = await fetchCurrentUser();
        if (!user) return;

        setCurrentAuthUser(user);
      }

      navigate('/application', {replace: true});
    },
    [
      createCompany,
      currentAuthUser,
      navigate,
      setCurrentAuthUser,
      setCurrentCompany,
    ]
  );

  const companyUuidFromStorage = localStorage.getItem(
    SELECTED_COMPANY_UUID_KEY
  );

  if (companyUuidFromStorage) {
    const company = currentAuthUser?.companies.find(
      (company) => company.uuid === companyUuidFromStorage
    );

    if (company && company.isOnboarded) {
      return <Navigate replace to={DASHBOARD_ROUTE} />;
    }
  }

  return (
    <div className={styles.container}>
      <img
        src={logoSource}
        alt=""
        role="presentation"
        className={styles.logo}
      />

      <Formik
        initialValues={INITIAL_VALUES}
        validationSchema={signupFormValidationSchema}
        onSubmit={onSubmit}
        validateOnMount
      >
        {({handleSubmit}) => (
          <Routes>
            <Route
              path="enter-your-name"
              element={
                <EnterYourName onSubmit={() => navigate('company-details')} />
              }
            />

            <Route
              path="company-details"
              element={
                <CompanyDetails
                  onSubmit={() => navigate('personal-information')}
                />
              }
            />

            <Route
              path="personal-information"
              element={<PersonalInformation onSubmit={handleSubmit} />}
            />

            <Route
              path="*"
              element={<Navigate to="enter-your-name" replace />}
            />
          </Routes>
        )}
      </Formik>
    </div>
  );
};

export default RegisterCompany;
