import {DEFAULT_TOASTIFY_CONFIGURATION} from '../../../../../constants/toastify-configuration';
import {FunctionComponent, useCallback} from 'react';
import {ReactComponent as IconUsers} from '../../../../@components/kit/icons/users.svg';
import {Roles} from '../../../../../constants/roles';
import {toast, ToastOptions} from 'react-toastify';
import {useInviteUsers} from '../../../../@hooks/mutations';
import {useSafeCurrentCompany} from '../../../../@atoms/current-company';
import {useTranslation} from 'react-i18next';
import Dialog from '../../../../@components/kit/dialog';
import flattenUserEmailsByRole from '../../../../../utils/flatten-user-emails-by-role';
import InviteUsers from '../../../@components/invite-users';
import isResponseSuccessful from '../../../../../utils/is-response-successful';
import styles from './styles.module.scss';

export type RoleValues = Record<Roles, string>;

interface ElementProps {
  isDialogVisible: boolean;
  onClose: () => void;
  onSuccess?: (userInvites: RoleValues) => void;
}

const InviteUsersDialog: FunctionComponent<ElementProps> = ({
  isDialogVisible,
  onClose,
  onSuccess,
}) => {
  const {t} = useTranslation('people');
  const currentCompany = useSafeCurrentCompany();

  const inviteUsers = useInviteUsers();

  const onSubmit = useCallback(
    async (values: RoleValues) => {
      const userInvites = await flattenUserEmailsByRole(values);

      const response = await inviteUsers.mutateAsync({
        userInvites,
        companyUuid: currentCompany.uuid,
      });

      if (isResponseSuccessful(response)) {
        if (onSuccess) onSuccess(values);

        const feedbackMessage = t(
          'employees.components.invite-employees.feedback-messages.invite-sent',
          {count: userInvites.length}
        );

        toast.success(feedbackMessage, {
          ...(DEFAULT_TOASTIFY_CONFIGURATION as ToastOptions),
        });
      }
    },
    [currentCompany, inviteUsers, onSuccess, t]
  );

  const INITIAL_VALUES: RoleValues = {
    admin: '',
    manager: '',
    member: '',
    owner: '',
  };

  return (
    <Dialog isOpen={isDialogVisible} onClose={onClose} variant="medium">
      <Dialog.Header>
        <div className={styles.header}>
          <h1 className={styles.headerTitle}>
            {t('employees.components.invite-employees.title')}
          </h1>

          <IconUsers className={styles.headerIcon} />
        </div>
      </Dialog.Header>

      <Dialog.Content>
        <div className={styles.description}>
          {t('employees.components.invite-employees.description')}
        </div>

        <InviteUsers onSubmit={onSubmit} formInitialValues={INITIAL_VALUES} />
      </Dialog.Content>
    </Dialog>
  );
};

export default InviteUsersDialog;
