import {CompanyCoreValue} from '../../../../../../interfaces/company-core-value';
import {FunctionComponent} from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import styles from './styles.module.scss';
import RichTextContainer from '../../../../../@components/rich-text-container/rich-text-container';

const DESCRIPTION_MAX_LENGTH = 100;

interface ElementProps {
  value: CompanyCoreValue;
}

const Item: FunctionComponent<ElementProps> = ({value}) => {
  const {t} = useTranslation('dashboard');

  const description =
    value.description.length > DESCRIPTION_MAX_LENGTH
      ? `${value.description.substring(0, DESCRIPTION_MAX_LENGTH).trim()}…`
      : value.description;

  return (
    <Link
      className={styles.link}
      to={`/application/values/${value.uuid}`}
      aria-label={t(
        'components.interactive-values.components.item.see-value-details-accessibility-text',
        {name: value.name}
      )}
    >
      <header className={styles.header}>
        <div className={styles.title}>{value.name}</div>
      </header>

      <div className={styles.content}>
        <RichTextContainer value={description} />
      </div>
    </Link>
  );
};

export default Item;
