import {fetchSlackLinkingUrl} from '../../../../../../../repositories/instill';
import {FunctionComponent, useCallback} from 'react';
import {REDIRECT_URL_FOR_WORKSPACE} from '../../../../link-slack';
import {REDIRECT_URL_ON_LOGIN_STORAGE_KEY} from '../../../../../../../constants/local-storage-keys';
import {useAuth0} from '@auth0/auth0-react';
import {useSafeCurrentCompany} from '../../../../../../@atoms/current-company';
import {useTranslation} from 'react-i18next';
import linkSlackStyles from '../../../../styles.module.scss';
import logoSource from '../../../../../../../assets/images/logo.png';
import WorkspaceAlreadyLinked from './@components/workspace-already-linked';

const Instruction: FunctionComponent = () => {
  const {t} = useTranslation('linkSlack');

  const currentCompany = useSafeCurrentCompany();
  const {getAccessTokenSilently, isAuthenticated, loginWithRedirect} =
    useAuth0();

  const onClick = useCallback(async () => {
    const accessToken = await getAccessTokenSilently();
    const url = await fetchSlackLinkingUrl(
      accessToken,
      currentCompany.uuid,
      true
    );

    if (!url) return;

    window.location.replace(url);
  }, [currentCompany, getAccessTokenSilently]);

  if (!isAuthenticated) {
    localStorage.setItem(
      REDIRECT_URL_ON_LOGIN_STORAGE_KEY,
      REDIRECT_URL_FOR_WORKSPACE
    );

    loginWithRedirect();
    return <></>;
  }

  if (currentCompany.slackTeamId) return <WorkspaceAlreadyLinked />;

  return (
    <div className={linkSlackStyles.box}>
      <img src={logoSource} alt="" className={linkSlackStyles.boxLogo} />

      <div className={linkSlackStyles.boxTitle}>{t('title')}</div>

      <ul className={linkSlackStyles.boxList}>
        <li className={linkSlackStyles.boxListItem}>
          {t('company-instruction-one')}
        </li>

        <li className={linkSlackStyles.boxListItem}>
          {t('company-instruction-two')}
        </li>

        <li className={linkSlackStyles.boxListItem}>
          {t('company-instruction-three')}
        </li>

        <li className={linkSlackStyles.boxListItem}>
          {t('company-instruction-four')}
        </li>

        <li className={linkSlackStyles.boxListItem}>{t('instruction-one')}</li>

        <li className={linkSlackStyles.boxListItem}>{t('instruction-two')}</li>

        <li className={linkSlackStyles.boxListItem}>
          {t('instruction-three')}
        </li>

        <li className={linkSlackStyles.boxListItem}>{t('instruction-four')}</li>
      </ul>
      <button
        type="button"
        className={linkSlackStyles.boxButton}
        onClick={onClick}
      >
        {t('allow-access-button-text')}
      </button>
    </div>
  );
};

export default Instruction;
