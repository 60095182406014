import {CompanyEmployeeUpdateToRaw} from '../../../interfaces/company-employee';
import InstillAPI from '../axios-instance';

export interface UpddateUserProfileByAdminPayload {
  companyEmployeePayload: CompanyEmployeeUpdateToRaw;
  companyUuid: string;
  userProfileUuid: string;
}

export async function updateUserProfileByAdmin(
  companyEmployeePayload: CompanyEmployeeUpdateToRaw,
  companyUuid: string,
  userProfileUuid: string
) {
  const response = await InstillAPI.put(
    `/companies/${companyUuid}/user-profiles/${userProfileUuid}`,
    companyEmployeePayload
  );

  return response.data;
}
