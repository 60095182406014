import {sanitizeCandidateProfileFromRaw} from '../../../ui/@sanitizers/candidate';
import InstillAPI from '../axios-instance';

export interface UpdateCandidateProfilePayload {
  candidateToken: string;
  name: string;
}

export async function updateCandidateProfile({
  candidateToken,
  name,
}: UpdateCandidateProfilePayload) {
  const payload = {
    name,
  };

  const response = await InstillAPI.put('/candidate', payload, {
    headers: {
      Authorization: `Bearer ${candidateToken}`,
    },
  });

  return sanitizeCandidateProfileFromRaw(response.data);
}
