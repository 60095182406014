import {CompanyAssessmentEvents} from '../../../../../../../interfaces/company-assessment-events';
import {FunctionComponent} from 'react';
import ListView from './@components/list-view/list-view';
import RoundedCard from '../../../../../../@components/rounded-card';
import Spinner from '../../../../../../@components/spinner';

interface ElementProps {
  assessmentEvents?: CompanyAssessmentEvents;
  isFetchingInProgress: boolean;
}

const AssessmentEvents: FunctionComponent<ElementProps> = ({
  assessmentEvents,
  isFetchingInProgress,
}) => {
  return (
    <>
      <RoundedCard.Header>
        <RoundedCard.Title>
          {assessmentEvents && assessmentEvents.assessmentName}
        </RoundedCard.Title>
      </RoundedCard.Header>

      <RoundedCard.Content>
        {isFetchingInProgress ? (
          <Spinner />
        ) : (
          <ListView companyAssessmentEvents={assessmentEvents} />
        )}
      </RoundedCard.Content>
    </>
  );
};

export default AssessmentEvents;
