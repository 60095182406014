import {CompanyEmployee} from '../../../../../../../../../../../interfaces/company-employee';
import {EventName} from '../../../../../../../../../../../constants/analytics/event-name';
import {EventType} from '../../../../../../../../../../../constants/analytics/event-type';
import {FunctionComponent, useCallback} from 'react';
import {getUserProfileForCompany} from '../../../../../../../../../../../services/user-profiles';
import {Link} from 'react-router-dom';
import {ProfileNameLinkClickedEvent} from '../../../../../../../../../../../interfaces/analytics/event-properties';
import {useCurrentAuthUserState} from '../../../../../../../../../../@atoms/current-auth-user';
import {useSafeCurrentCompany} from '../../../../../../../../../../@atoms/current-company';
import {useTranslation} from 'react-i18next';
import AvatarPlaceholder from '../../../../../../../../../../@components/avatar-placeholder';
import EmployeeTeamTag from '../../../../../../../../../../@components/employee-team-tag';
import styles from './styles.module.scss';
import Table from '../../../../../../../../../../@components/table';
import tableStyles from '../../../../../../../../../../../styles/classes/table.module.scss';
import useAnalytics from '../../../../../../../../../../@hooks/use-analytics';

interface ElementProps {
  employee: CompanyEmployee;
}

const TableRow: FunctionComponent<ElementProps> = ({employee}) => {
  const {t} = useTranslation('people');

  const [currentAuthUser] = useCurrentAuthUserState();
  const currentCompany = useSafeCurrentCompany();
  const {trackEvent} = useAnalytics();

  const userProfile = getUserProfileForCompany(
    currentAuthUser!,
    currentCompany
  );
  const isEmployeeTheCurrentUser =
    employee.userProfileUuid === userProfile.uuid;

  const routeUrlLink = isEmployeeTheCurrentUser
    ? '/application/my-profile'
    : `/application/people/employees/${employee.userProfileUuid}`;

  const onProfileLinkClicked = useCallback(() => {
    const eventProperties = {
      profileName: employee.name,
    } as ProfileNameLinkClickedEvent;

    trackEvent({
      eventName: EventName.PEOPLE.EMPLOYEES.PROFILE_CLICK_NAME_COLUMN,
      eventType: EventType.LINK_CLICKED,
      eventProperties,
    });
  }, [trackEvent, employee]);

  return (
    <Table.Row className={tableStyles.tableRow}>
      <Table.Data variant="nameColumn" className={tableStyles.tableData}>
        <div className={tableStyles.mobileHeaderTitle}>
          {t(
            'employees.components.employee-list.components.list-view.header.name'
          )}
        </div>

        <div className={tableStyles.overflowContainer}>
          {employee.picture ? (
            <img
              src={employee.picture}
              className={styles.profilePicture}
              alt=""
            />
          ) : (
            <div className={styles.avatarContainer}>
              <AvatarPlaceholder
                size="small"
                variant="dark-blue"
                name={employee.name}
              />
            </div>
          )}

          <div className={tableStyles.overflowContent}>
            <Link
              className={styles.link}
              onClick={onProfileLinkClicked}
              to={routeUrlLink}
            >
              {employee.name}
            </Link>
          </div>
        </div>
      </Table.Data>

      <Table.Data variant="large" className={tableStyles.tableData}>
        <div className={tableStyles.mobileHeaderTitle}>
          {t(
            'employees.components.employee-list.components.list-view.header.role'
          )}
        </div>

        {employee.jobTitle ? (
          <div>{employee.jobTitle}</div>
        ) : (
          <div className={styles.emptyValue}>—</div>
        )}
      </Table.Data>

      <Table.Data variant="large" className={tableStyles.tableData}>
        <div className={tableStyles.mobileHeaderTitle}>
          {t(
            'employees.components.employee-list.components.list-view.header.team'
          )}
        </div>

        {employee.team ? (
          <EmployeeTeamTag team={employee.team} />
        ) : (
          <div className={styles.emptyValue}>—</div>
        )}
      </Table.Data>

      <Table.Data variant="medium" className={tableStyles.tableData}>
        <div className={tableStyles.mobileHeaderTitle}>
          {t(
            'employees.components.employee-list.components.list-view.header.hexaco'
          )}
        </div>

        {employee.hexacoTrait ? (
          <div className={styles.tag}>{employee.hexacoTrait}</div>
        ) : (
          <div className={styles.emptyValue}>—</div>
        )}
      </Table.Data>
    </Table.Row>
  );
};

export default TableRow;
