// Vendor
import {useEffect} from 'react';
import isBrowser from '../../utils/is-browser';
import isPassiveSupported from '../../utils/is-passive-supported';

const ESCAPE = 27;

function useEscape(onEscape: () => void) {
  useEffect(() => {
    if (!isBrowser()) return;

    function handleKeyDown(event: KeyboardEvent) {
      if (event.keyCode === ESCAPE) {
        onEscape();
      }
    }

    window.addEventListener(
      'keydown',
      handleKeyDown,
      isPassiveSupported() ? {passive: true} : false
    );

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [onEscape]);
}

export default useEscape;
