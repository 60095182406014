import {FunctionComponent} from 'react';
import {UserProfileTeam} from '../../../../../../interfaces/user-profile';
import {useTranslation} from 'react-i18next';
import profileStyles from '../../styles.module.scss';
import Tag from '../../../../../@components/tag/tag';
import EmployeeTeamTag from '../../../../../@components/employee-team-tag/employee-team-tag';

interface ElementProps {
  team: UserProfileTeam;
}

const TeamProfile: FunctionComponent<ElementProps> = ({team}) => {
  const {t} = useTranslation('application');

  return (
    <div className={profileStyles.box}>
      <h2 className={profileStyles.boxTitle}>
        {t('components.profile-details.components.team-profile.labels.title')}
      </h2>

      <ul>
        <li className={profileStyles.boxItem}>
          <div className={profileStyles.boxItemKey}>
            {t(
              'components.profile-details.components.team-profile.labels.name'
            )}
          </div>

          <div className={profileStyles.boxItemValue}>
            <Tag>
              <EmployeeTeamTag team={team} />
            </Tag>
          </div>
        </li>

        <li className={profileStyles.boxItem}>
          <div className={profileStyles.boxItemKey}>
            {t(
              'components.profile-details.components.team-profile.labels.description'
            )}
          </div>

          <div className={profileStyles.boxItemValue}>{team.description}</div>
        </li>

        <li className={profileStyles.boxItem}>
          <div className={profileStyles.boxItemKey}>
            {t(
              'components.profile-details.components.team-profile.labels.size'
            )}
          </div>

          <div className={profileStyles.boxItemValue}>
            <Tag>
              {t(
                'components.profile-details.components.team-profile.size-count',
                {count: team.teamSize}
              )}
            </Tag>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default TeamProfile;
