import {CustomTimescale} from '../../../../overall-health';
import {FunctionComponent, useCallback, useMemo, useState} from 'react';
import {TIMESCALE_VALUES} from '../../../../../../../../constants/timescale-value';
import {useTranslation} from 'react-i18next';
import DatePicker from 'react-datepicker';
import Item from './@components/item/item';
import styles from './styles.module.scss';
import useOnClickOutside from '../../../../../../../@hooks/use-on-click-outside';

interface ElementProps {
  endDateInQueryParams?: string | null;
  onCustomTimescaleSelected: (dates: CustomTimescale) => void;
  onTimescaleChange: (selectedTimescale: TIMESCALE_VALUES) => void;
  selectedTimescale?: TIMESCALE_VALUES;
  startDateInQueryParams?: string | null;
}

const Timescale: FunctionComponent<ElementProps> = ({
  endDateInQueryParams,
  onCustomTimescaleSelected,
  onTimescaleChange,
  selectedTimescale,
  startDateInQueryParams,
}) => {
  const {t} = useTranslation('measure');

  const isSelectedTimescaleCustom = useMemo(() => {
    return selectedTimescale === TIMESCALE_VALUES.CUSTOM;
  }, [selectedTimescale]);

  const [isDatepickerVisible, setIsDatepickerVisible] = useState(false);
  const [container, setContainer] = useState<HTMLDivElement | null>(null);

  const [customStartDate, setCustomStartDate] = useState<Date | null>(
    startDateInQueryParams && isSelectedTimescaleCustom
      ? new Date(startDateInQueryParams)
      : null
  );

  const [customEndDate, setCustomEndDate] = useState<Date | null>(
    endDateInQueryParams && isSelectedTimescaleCustom
      ? new Date(endDateInQueryParams)
      : null
  );

  const nowDate = new Date();
  const [customMinDate] = useState(
    new Date(nowDate.setFullYear(nowDate.getFullYear() - 3))
  );

  const onButtonClicked = useCallback(
    (value: TIMESCALE_VALUES) => {
      onTimescaleChange(value);
      setIsDatepickerVisible(false);
    },
    [onTimescaleChange]
  );

  const onClickOutside = useCallback(() => {
    setIsDatepickerVisible(false);
  }, []);

  const onCustomButtonClicked = useCallback(() => {
    setIsDatepickerVisible(!isDatepickerVisible);
  }, [isDatepickerVisible]);

  const onChange = useCallback(
    (dates: Array<Date | null>) => {
      const [startDate, endDate] = dates;

      setCustomStartDate(startDate);
      setCustomEndDate(endDate);

      if (!startDate || !endDate) return;

      onCustomTimescaleSelected({startDate, endDate});
      setIsDatepickerVisible(false);
    },
    [onCustomTimescaleSelected]
  );

  useOnClickOutside(container, onClickOutside);

  return (
    <ul className={styles.list}>
      <li className={styles.listItem}>
        <Item
          elementIdentifier={TIMESCALE_VALUES.THREE_MONTH}
          selectedElement={selectedTimescale}
          onClick={onButtonClicked}
          label={t(
            'overall-health.components.filters.components.timescale.3-month'
          )}
        />
      </li>

      <li className={styles.listItem}>
        <Item
          elementIdentifier={TIMESCALE_VALUES.SIX_MONTH}
          selectedElement={selectedTimescale}
          onClick={onButtonClicked}
          label={t(
            'overall-health.components.filters.components.timescale.6-month'
          )}
        />
      </li>

      <li className={styles.listItem}>
        <Item
          elementIdentifier={TIMESCALE_VALUES.ONE_YEAR}
          selectedElement={selectedTimescale}
          onClick={onButtonClicked}
          label={t(
            'overall-health.components.filters.components.timescale.1-year'
          )}
        />
      </li>

      <li className={styles.listItem}>
        <Item
          elementIdentifier={TIMESCALE_VALUES.CUSTOM}
          selectedElement={selectedTimescale}
          onClick={onCustomButtonClicked}
          label={t(
            'overall-health.components.filters.components.timescale.custom'
          )}
        />

        <div className={styles.calendarContainer}>
          {isDatepickerVisible && (
            <div ref={setContainer}>
              <DatePicker
                endDate={customEndDate}
                maxDate={new Date()}
                minDate={customMinDate}
                onChange={onChange}
                startDate={customStartDate}
                selectsRange
                inline
                required
              />
            </div>
          )}
        </div>
      </li>
    </ul>
  );
};

export default Timescale;
