import {PersonalizeUserProfile} from '../../../../../interfaces/user-profile';
import {Formik, Field, FieldProps, Form} from 'formik';
import {FunctionComponent} from 'react';
import {LINKEDIN_PERSONAL_REGEX} from '../../../../../constants/regex';
import {MutableSelect} from '../../../../@components/kit/form/hybrid-select/mutable-select';
import {useCurrentFullUserProfile} from '../../../../@atoms/current-full-user-profile';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import AutoresizeTextarea from '../../../../@components/kit/form/autoresize-textarea';
import Button from '../../../../@components/kit/form/button';
import FormControl from '../../../../@components/kit/form/form-control';
import FormFooter from '../../../../@components/kit/form/form-footer';
import FormGroup from '../../../../@components/kit/form/form-group';
import Input from '../../../../@components/kit/form/input';
import Label from '../../../../@components/kit/form/label';
import LocationAutofillSelect from '../../../../@components/kit/form/hybrid-select/location-autofill-select';
import styles from './styles.module.scss';

interface ElementProps {
  onSubmit: (values: PersonalizeUserProfile) => void;
  onSkip: () => void;
}

const PersonalizeYourProfile: FunctionComponent<ElementProps> = ({
  onSubmit,
  onSkip,
}) => {
  const {t} = useTranslation('userSetup');
  const currentFullUserProfile = useCurrentFullUserProfile();

  const EMPTY_VALUES: PersonalizeUserProfile = {
    funFact: currentFullUserProfile.funFact || '',
    linkedinUrl: currentFullUserProfile.linkedinUrl || '',
    location: currentFullUserProfile.location || '',
    pronouns: currentFullUserProfile.pronouns || '',
    shortBio: currentFullUserProfile.shortBio || '',
  };

  const pronounOptions = [
    {
      label: t(
        'create-your-profile.personalize-your-profile.form.pronouns.options.he-him'
      ),
      value: 'he-him',
    },
    {
      label: t(
        'create-your-profile.personalize-your-profile.form.pronouns.options.she-her'
      ),
      value: 'she-her',
    },
    {
      label: t(
        'create-your-profile.personalize-your-profile.form.pronouns.options.they-them'
      ),
      value: 'they-them',
    },
  ];

  const findPronounOption = (value: string) => {
    return pronounOptions.find((option) => option.value === value);
  };

  const validationSchema = Yup.object().shape({
    linkedinUrl: Yup.string()
      .url(t('form.error.bad-url-format', {ns: 'common'}))
      .nullable()
      .matches(
        LINKEDIN_PERSONAL_REGEX,
        t('form.error.bad-personal-linkedin-url-format', {ns: 'common'})
      ),
  });

  return (
    <>
      <p>{t('create-your-profile.personalize-your-profile.title')}</p>

      <Formik
        initialValues={EMPTY_VALUES}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        validateOnMount
      >
        {({errors, touched, isValid}) => (
          <Form>
            <div className={styles.formContainer}>
              <Field name="shortBio">
                {({field, form}: FieldProps) => (
                  <FormGroup className={styles.formGroup}>
                    <FormControl>
                      <Label htmlFor={field.name}>
                        {t(
                          'create-your-profile.personalize-your-profile.form.short-bio.label'
                        )}
                      </Label>

                      <AutoresizeTextarea
                        {...field}
                        placeholder={t(
                          'create-your-profile.personalize-your-profile.form.short-bio.placeholder'
                        )}
                        rows={3}
                      />
                    </FormControl>
                  </FormGroup>
                )}
              </Field>

              <Field name="pronouns">
                {({field, form, meta}: FieldProps) => (
                  <FormGroup className={styles.formGroup}>
                    <FormControl>
                      <Label htmlFor={field.name}>
                        {t(
                          'create-your-profile.personalize-your-profile.form.pronouns.label'
                        )}
                      </Label>

                      <MutableSelect
                        form={form}
                        field={field}
                        meta={meta}
                        options={pronounOptions}
                        defaultValue={findPronounOption(form.values.pronouns)}
                        placeholder={t(
                          'create-your-profile.personalize-your-profile.form.pronouns.placeholder'
                        )}
                      />
                    </FormControl>
                  </FormGroup>
                )}
              </Field>

              <Field name="location">
                {({field, form, meta}: FieldProps) => (
                  <FormGroup className={styles.formGroup}>
                    <FormControl>
                      <Label htmlFor={field.name}>
                        {t(
                          'create-your-profile.personalize-your-profile.form.location.label'
                        )}
                      </Label>

                      <LocationAutofillSelect
                        form={form}
                        field={field}
                        meta={meta}
                        placeholder={t(
                          'create-your-profile.personalize-your-profile.form.location.placeholder'
                        )}
                      />
                    </FormControl>
                  </FormGroup>
                )}
              </Field>

              <Field name="linkedinUrl">
                {({field, form}: FieldProps) => (
                  <FormGroup className={styles.formGroup}>
                    <FormControl
                      error={
                        Boolean(errors.linkedinUrl && touched.linkedinUrl)
                          ? errors.linkedinUrl
                          : undefined
                      }
                    >
                      <Label htmlFor={field.name}>
                        {t(
                          'create-your-profile.personalize-your-profile.form.linkedin-url.label'
                        )}
                      </Label>

                      <Input
                        {...field}
                        placeholder={t(
                          'create-your-profile.personalize-your-profile.form.linkedin-url.placeholder'
                        )}
                      />
                    </FormControl>
                  </FormGroup>
                )}
              </Field>

              <Field name="funFact">
                {({field, form}: FieldProps) => (
                  <FormGroup className={styles.formGroup}>
                    <FormControl>
                      <Label htmlFor={field.name}>
                        {t(
                          'create-your-profile.personalize-your-profile.form.fun-fact.label'
                        )}
                      </Label>

                      <AutoresizeTextarea
                        {...field}
                        placeholder={t(
                          'create-your-profile.personalize-your-profile.form.fun-fact.placeholder'
                        )}
                        rows={2}
                      />
                    </FormControl>
                  </FormGroup>
                )}
              </Field>
            </div>

            <FormFooter>
              <Button type="submit" disabled={!isValid}>
                {t(
                  'create-your-profile.personalize-your-profile.form.save-button-text'
                )}
              </Button>

              <Button variant="secondary" onClick={onSkip}>
                {t(
                  'create-your-profile.personalize-your-profile.form.skip-button-text'
                )}
              </Button>
            </FormFooter>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default PersonalizeYourProfile;
