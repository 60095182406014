import {CoreValueForm} from '../../../../../../interfaces/company-core-value';
import {EventName} from '../../../../../../constants/analytics/event-name';
import {Field, FieldProps} from 'formik';
import {FunctionComponent, useCallback, useMemo} from 'react';
import {
  MEDIA_TYPE_IMAGE,
  MEDIA_TYPE_VIDEO,
} from '../../../../../../constants/core-value-media-type';
import {ReactComponent as IconPenEdit} from '../../../../../@components/kit/icons/edit.svg';
import {useTranslation} from 'react-i18next';
import FormGroup from '../../../../../@components/kit/form/form-group';
import styles from './styles.module.scss';
import UploadMedia from '../../../upload-media';
import useAnalytics from '../../../../../@hooks/use-analytics';

interface ElementProps {
  formValues: CoreValueForm;
  prefixEventName?: string;
}

const MediaForm: FunctionComponent<ElementProps> = ({
  formValues,
  prefixEventName,
}) => {
  const {t} = useTranslation('dashboard');

  const {buildEventName} = useAnalytics();

  const mediaType = formValues.media[0]?.type ?? undefined;
  const mediaUrl = formValues.media[0]?.url ?? undefined;

  const eventNameForEmbedYoutubeVideo = useMemo(() => {
    return buildEventName(
      EventName.COMPONENTS.INTERACTIVE_VALUES.EMBED_YOUTUBE_VIDEO,
      prefixEventName
    );
  }, [buildEventName, prefixEventName]);

  const eventNameForUploadImageFromComputer = useMemo(() => {
    return buildEventName(
      EventName.COMPONENTS.INTERACTIVE_VALUES.UPLOAD_IMAGE_FROM_COMPUTER,
      prefixEventName
    );
  }, [buildEventName, prefixEventName]);

  const loadPreview = useCallback(() => {
    if (!mediaType) return;

    if (mediaType === MEDIA_TYPE_IMAGE) {
      return <img src={mediaUrl} className={styles.mediaImage} alt="" />;
    } else if (mediaType === MEDIA_TYPE_VIDEO) {
      return (
        <iframe
          title="video"
          src={`${mediaUrl}?modestbranding=1&rel=0&cc_load_policy=1&iv_load_policy=3&color=white&controls=0&showinfo=0`}
          className={styles.mediaYoutube}
          frameBorder="0"
          allowFullScreen
        />
      );
    }
  }, [mediaType, mediaUrl]);

  return (
    <Field name={`coreValue.media[0].url`}>
      {({field, form}: FieldProps<string>) => (
        <FormGroup>
          <div className={styles.mediaUploadTitle}>
            {t(
              'components.interactive-values.components.media-form.upload-media.label'
            )}

            {mediaUrl && (
              <button
                type="button"
                className={styles.button}
                onClick={() => {
                  form.setFieldValue(`coreValue.media[0].url`, '');
                }}
              >
                <IconPenEdit className={styles.editIcon} />
              </button>
            )}
          </div>

          {mediaUrl ? (
            loadPreview()
          ) : (
            <UploadMedia
              eventNameForEmbedYoutubeVideo={eventNameForEmbedYoutubeVideo}
              eventNameForUploadImageFromComputer={
                eventNameForUploadImageFromComputer
              }
              field={field}
              form={form}
              typeFieldName={`coreValue.media[0].type`}
            />
          )}
        </FormGroup>
      )}
    </Field>
  );
};

export default MediaForm;
