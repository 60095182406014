import {FunctionComponent, useCallback, useMemo, useState} from 'react';
import {Module} from '../../../../../../../../../../../../../interfaces/company-assessment-events';
import {useTranslation} from 'react-i18next';
import parentStyles from '../../../../styles.module.scss';
import PopulationDialog from './@components/population-dialog';
import styles from './styles.module.scss';
import tableStyles from '../../../../../../../../../../../../../styles/classes/table.module.scss';
import Tag from '../../../../../../../../../../../../@components/tag';
import useAnalytics from '../../../../../../../../../../../../@hooks/use-analytics';
import {EventName} from '../../../../../../../../../../../../../constants/analytics/event-name';
import {EventType} from '../../../../../../../../../../../../../constants/analytics/event-type';

interface ElementProps {
  module: Module;
  index: number;
}

const AssessmentModule: FunctionComponent<ElementProps> = ({module, index}) => {
  const {t} = useTranslation('measure');

  const {trackEvent} = useAnalytics();

  const [isPopulationDialogVisible, setIsPopulationDialogVisible] =
    useState(false);
  const [isViewAction, setisViewAction] = useState(false);

  const completedModules = useMemo(() => {
    return module.completionInfo.filter((module) => module.isCompleted).length;
  }, [module]);

  const getCompletedModulePercentage = useCallback(() => {
    const percentage = (completedModules / module.completionInfo.length) * 100;
    if (!percentage) return 0;

    const hasDecimal = percentage % 1 > 0;
    if (hasDecimal) return percentage.toFixed(2);

    return percentage;
  }, [completedModules, module.completionInfo]);

  const onHidePopulationDialog = useCallback(() => {
    setIsPopulationDialogVisible(false);
  }, []);

  const onShowPopulationDialog = useCallback((isViewAction: boolean) => {
    setIsPopulationDialogVisible(true);
    setisViewAction(isViewAction);
  }, []);

  const onViewButtonClicked = useCallback(() => {
    onShowPopulationDialog(true);

    trackEvent({
      eventName: EventName.MEASURE.MANAGE_ASSESSMENTS.EVENTS.VIEW_REPORT,
      eventType: EventType.BUTTON_CLICKED,
    });
  }, [trackEvent, onShowPopulationDialog]);

  const onDownloadButtonClicked = useCallback(() => {
    onShowPopulationDialog(false);

    trackEvent({
      eventName: EventName.MEASURE.MANAGE_ASSESSMENTS.EVENTS.DOWNLOAD_REPORT,
      eventType: EventType.BUTTON_CLICKED,
    });
  }, [trackEvent, onShowPopulationDialog]);

  return (
    <>
      <div
        className={`${parentStyles.tableData} ${parentStyles.occurenceColumn}`}
      >
        <span className={styles.moduleNumber}>{index + 1}</span>

        <Tag>{module.name}</Tag>
      </div>

      <div
        className={`${parentStyles.tableData} ${parentStyles.dateInitiatedColumn}`}
      >
        <div className={tableStyles.mobileHeaderTitle}>
          {t(
            'manage-assessments.components.assessment-events.components.list-view.table.header.date-initiated'
          )}
        </div>
      </div>

      <div
        className={`${parentStyles.tableData} ${parentStyles.dueDateColumn}`}
      >
        <div className={tableStyles.mobileHeaderTitle}>
          {t(
            'manage-assessments.components.assessment-events.components.list-view.table.header.due-date'
          )}
        </div>
      </div>

      <div
        className={`${parentStyles.tableData} ${parentStyles.populationColumn}`}
      >
        <div className={tableStyles.mobileHeaderTitle}>
          {t(
            'manage-assessments.components.assessment-events.components.list-view.table.header.population'
          )}
        </div>
        {getCompletedModulePercentage()}% ({module.completionInfo.length})
      </div>

      <div
        className={`${parentStyles.tableData} ${parentStyles.actionsColumn}`}
      >
        <div className={tableStyles.mobileHeaderTitle}>
          {t(
            'manage-assessments.components.assessment-events.components.list-view.table.header.actions'
          )}
        </div>

        <button
          type="button"
          className={styles.actionButton}
          onClick={onViewButtonClicked}
        >
          <div className={styles.buttonContainer}>
            {t(
              'manage-assessments.components.assessment-events.components.list-view.table.actions.view-button'
            )}
          </div>
        </button>

        <button
          type="button"
          className={styles.actionButton}
          onClick={onDownloadButtonClicked}
        >
          <div className={styles.buttonContainer}>
            {t(
              'manage-assessments.components.assessment-events.components.list-view.table.actions.download-button'
            )}
          </div>
        </button>
      </div>

      <PopulationDialog
        module={module}
        isVisible={isPopulationDialogVisible}
        onClose={onHidePopulationDialog}
        isViewAction={isViewAction}
      />
    </>
  );
};

export default AssessmentModule;
