import {createQuery} from 'react-query-kit';
import {fetchUserProfileForCompany} from '../../../repositories/instill/queries';
import {FullUserProfile} from '../../../interfaces/user-profile';

export interface FetchFullUserProfilePayload {
  companyUuid?: string;
}

export const useFullUserProfile = createQuery<
  FullUserProfile | undefined,
  FetchFullUserProfilePayload
>({
  primaryKey: 'fullUserProfile',
  queryFn: async ({queryKey: [_, variables]}) => {
    if (!variables.companyUuid) {
      return Promise.reject();
    }

    return await fetchUserProfileForCompany(variables.companyUuid);
  },
});
