import {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import Button from '../../../../../../../@components/kit/form/button';
import styles from './styles.module.scss';

interface ElementProps {
  onCancel: () => void;
  handleCompleteConfirm: () => void;
}

const ConfirmDialog: FunctionComponent<ElementProps> = ({
  onCancel,
  handleCompleteConfirm,
}) => {
  const {t} = useTranslation('apply', {
    keyPrefix:
      'action-steps.components.action-step-dialog.components.confirm-dialog',
  });

  return (
    <>
      <div className={styles.description}>{t('description')}</div>

      <div className={styles.buttonRow}>
        <Button variant="secondary" onClick={onCancel}>
          {t('cancel')}
        </Button>

        <Button onClick={handleCompleteConfirm}>{t('confirm')}</Button>
      </div>
    </>
  );
};

export default ConfirmDialog;
