import {Actions} from './actions';
import {Roles} from '../roles';

export const Permissions: Record<string, Roles[]> = {
  [Actions.ADD_EMPLOYEE]: [Roles.OWNER, Roles.ADMIN],
  [Actions.ADD_TEAM]: [Roles.OWNER, Roles.ADMIN],
  [Actions.ADD_VALUE]: [Roles.OWNER, Roles.ADMIN],
  [Actions.DELETE_TEAM]: [Roles.OWNER, Roles.ADMIN],
  [Actions.DELETE_VALUE]: [Roles.OWNER, Roles.ADMIN],
  [Actions.EDIT_LEADERSHIP_MANIFESTO]: [Roles.OWNER, Roles.ADMIN],
  [Actions.EDIT_MISSION]: [Roles.OWNER, Roles.ADMIN],
  [Actions.EDIT_ORGANIZATION_STRUCTURE]: [Roles.OWNER, Roles.ADMIN],
  [Actions.EDIT_TEAM]: [Roles.OWNER, Roles.ADMIN],
  [Actions.EDIT_VALUE]: [Roles.OWNER, Roles.ADMIN],
  [Actions.EDIT_VISION]: [Roles.OWNER, Roles.ADMIN],
  [Actions.INVITE_ADMIN]: [Roles.OWNER, Roles.ADMIN],
  [Actions.INVITE_OWNER]: [Roles.OWNER],
  [Actions.UPDATE_KUDO_VISIBILITY]: [Roles.OWNER, Roles.ADMIN],
  [Actions.UPDATE_ROLE_TO_ADMIN]: [Roles.OWNER, Roles.ADMIN],
  [Actions.UPDATE_ROLE_TO_MANAGER]: [Roles.OWNER, Roles.ADMIN, Roles.MANAGER],
  [Actions.UPDATE_ROLE_TO_OWNER]: [Roles.OWNER],
  [Actions.UPDATE_ROLE_TO_MEMBER]: [
    Roles.OWNER,
    Roles.ADMIN,
    Roles.MANAGER,
    Roles.MEMBER,
  ],
  [Actions.VIEW_COMPANY_SETTINGS]: [Roles.OWNER, Roles.ADMIN],
  [Actions.VIEW_JOBS]: [Roles.OWNER, Roles.ADMIN, Roles.MANAGER],
  [Actions.VIEW_MANAGE_ASSESSMENTS]: [Roles.OWNER, Roles.ADMIN],
  [Actions.VIEW_MANAGE_DIRECTORY]: [Roles.OWNER, Roles.ADMIN],
  [Actions.VIEW_ACTION_STEP_STATUS]: [Roles.OWNER, Roles.ADMIN],
};
