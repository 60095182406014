import {ActionStep} from '../../../../../../../../../../interfaces/action-step';
import {FunctionComponent} from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import AvatarPlaceholder from '../../../../../../../../../@components/avatar-placeholder';
import styles from './styles.module.scss';
import Table from '../../../../../../../../../@components/table';
import tableStyles from '../../../../../../../../../../styles/classes/table.module.scss';
import Tag from '../../../../../../../../../@components/tag';

interface ElementProps {
  actionStep: ActionStep;
}

const TableRow: FunctionComponent<ElementProps> = ({actionStep}) => {
  const {t} = useTranslation('apply', {
    keyPrefix:
      'action-step-status.components.action-steps-list.components.list-view',
  });

  return (
    <Table.Row className={tableStyles.tableRow}>
      <Table.Data variant="large" className={tableStyles.tableData}>
        <div className={tableStyles.mobileHeaderTitle}>{t('header.name')}</div>

        <div className={tableStyles.overflowContainer}>
          {actionStep.picture ? (
            <img
              src={actionStep.picture}
              className={styles.profilePicture}
              alt=""
            />
          ) : (
            <div className={styles.avatarContainer}>
              <AvatarPlaceholder
                size="small"
                variant="dark-blue"
                name={actionStep.name}
              />
            </div>
          )}

          <div className={tableStyles.overflowContent}>
            <Link
              className={styles.link}
              to={`/application/people/employees/${actionStep.userProfileUuid}`}
            >
              {actionStep.name}
            </Link>
          </div>
        </div>
      </Table.Data>

      <Table.Data variant="large" className={tableStyles.tableData}>
        <div className={tableStyles.mobileHeaderTitle}>
          {t('header.job-position')}
        </div>

        <>
          {actionStep.jobTitle ? (
            <Tag>{actionStep.jobTitle}</Tag>
          ) : (
            <div className={styles.emptyValue}>–</div>
          )}
        </>
      </Table.Data>

      <Table.Data variant="medium" className={tableStyles.tableData}>
        <div className={tableStyles.mobileHeaderTitle}>
          {t('header.pending-action-steps')}
        </div>

        <div className={styles.centeredContent}>
          {actionStep.pendingActionSteps}
        </div>
      </Table.Data>

      <Table.Data variant="medium" className={tableStyles.tableData}>
        <div className={tableStyles.mobileHeaderTitle}>
          {t('header.completed-action-steps')}
        </div>

        <div className={styles.centeredContent}>
          {actionStep.completedActionSteps}
        </div>
      </Table.Data>
    </Table.Row>
  );
};

export default TableRow;
