import {buildPageTitle} from '../../../../services/build-page-title';
import {EventName} from '../../../../constants/analytics/event-name';
import {EventType} from '../../../../constants/analytics/event-type';
import {FunctionComponent} from 'react';
import {Helmet} from 'react-helmet-async';
import {useEffectOnce} from '../../../@hooks/use-effect-once';
import {useTranslation} from 'react-i18next';
import mainContainerStyles from '../../../../styles/classes/main-container.module.scss';
import Reports from './@components/reports';
import RoundedCard from '../../../@components/rounded-card';
import useAnalytics from '../../../@hooks/use-analytics';

const YourReports: FunctionComponent = () => {
  const {t} = useTranslation('applicationPageTitle');

  const {trackEvent} = useAnalytics();

  useEffectOnce(() => {
    trackEvent({
      eventName: EventName.PAGE_VIEWS.MEASURE.YOUR_REPORTS,
      eventType: EventType.PAGE_VIEW,
    });
  });

  return (
    <>
      <Helmet>
        <title>
          {buildPageTitle([
            t('page-title.measure-your-culture.your-reports', {
              ns: 'applicationPageTitle',
            }),
            t('page-title.measure-your-culture.index', {
              ns: 'applicationPageTitle',
            }),
          ])}
        </title>
      </Helmet>

      <main className={mainContainerStyles.container}>
        <RoundedCard>
          <Reports />
        </RoundedCard>
      </main>
    </>
  );
};

export default YourReports;
