import {sanitizeUserAssessmentResultFromRaw} from '../../../ui/@sanitizers/user-assessment-result';
import InstillAPI from '../axios-instance';
import isResponseSuccessful from '../../../utils/is-response-successful';

export interface FetchAssessmentResultPayload {
  userAssessmentUuid: string;
  moduleUuid: string;
}

export async function fetchAssessmentResult(
  userAssessmentUuid: string,
  moduleUuid: string
) {
  try {
    const response = await InstillAPI.get(
      `/user-assessments/${userAssessmentUuid}/module/${moduleUuid}/result`
    );

    if (!response.data || !isResponseSuccessful(response)) {
      return Promise.reject();
    }

    return sanitizeUserAssessmentResultFromRaw(response.data);
  } catch (error) {
    console.error(error);
    return Promise.reject();
  }
}
