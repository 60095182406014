import InstillAPI from '../axios-instance';

export interface DeleteValuePayload {
  companyUuid: string;
  valueUuid: string;
}

export async function deleteValue(deleteValuePayload: DeleteValuePayload) {
  const {companyUuid, valueUuid} = deleteValuePayload;

  const response = await InstillAPI.delete(
    `/companies/${companyUuid}/values/${valueUuid}`
  );

  return response;
}
