import {Actions} from '../../../constants/permissions/actions';
import {buildPageTitle} from '../../../services/build-page-title';
import {FunctionComponent, useMemo} from 'react';
import {getUserProfileForCompany} from '../../../services/user-profiles';
import {Helmet} from 'react-helmet-async';
import {Navigate, Route, Routes} from 'react-router';
import {useCurrentAuthUserState} from '../../@atoms/current-auth-user';
import {useSafeCurrentCompany} from '../../@atoms/current-company';
import {useTranslation} from 'react-i18next';
import {useUserPermission} from '../../@hooks/use-user-permission';
import ActionSteps from './action-steps';
import ActionStepStatus from './action-step-status';
import FeedbackBanner from '../../@components/feedback-banner';
import Header from '../@components/header';
import MainContent from '../@components/main-content';

const Apply: FunctionComponent = () => {
  const {t} = useTranslation(['apply', 'applicationPageTitle']);

  const [currentAuthUser] = useCurrentAuthUserState();
  const currentCompany = useSafeCurrentCompany();

  const userProfile = getUserProfileForCompany(
    currentAuthUser!,
    currentCompany
  );

  const can = useUserPermission(userProfile.role);

  const links = useMemo(() => {
    const links = [
      {
        route: '/application/apply/action-steps',
        label: t('components.header.sub-navigation.links.action-steps'),
      },
    ];

    if (can(Actions.VIEW_ACTION_STEP_STATUS)) {
      links.push({
        route: '/application/apply/action-step-status',
        label: t('components.header.sub-navigation.links.action-step-status'),
      });
    }

    return links;
  }, [t, can]);

  return (
    <>
      <Helmet>
        <title>
          {buildPageTitle(
            t('page-title.apply-your-culture', {ns: 'applicationPageTitle'})
          )}
        </title>
      </Helmet>

      <FeedbackBanner text={t('components.feedback-banner.text')} />

      <Header title={t('components.header.title')}>
        <Header.SubNavigation links={links} />
      </Header>

      <MainContent spacing="small">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Navigate replace to="action-steps" />
              </>
            }
          />

          <Route path="/action-steps" element={<ActionSteps />} />
          <Route path="/action-step-status" element={<ActionStepStatus />} />
          <Route path="*" element={<Navigate replace to="action-steps" />} />
        </Routes>
      </MainContent>
    </>
  );
};

export default Apply;
