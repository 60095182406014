import {FunctionComponent, useMemo} from 'react';
import {ReactComponent as IconTrendingDown} from './assets/trending-down.svg';
import {ReactComponent as IconTrendingUp} from './assets/trending-up.svg';
import {Tooltip} from 'react-tooltip';
import {useTranslation} from 'react-i18next';
import styles from './styles.module.scss';

interface ElementProps {
  trendPercentage: number;
}

const TOOLTIP_IDENTIFIER = 'trend-tooltip';

const Item: FunctionComponent<ElementProps> = ({trendPercentage}) => {
  const {t} = useTranslation('measure', {
    keyPrefix:
      'overall-health.components.health-gauges.components.item.components.trend',
  });

  const isDecreasing = useMemo(() => trendPercentage < 0, [trendPercentage]);

  const trendPercentageSanitized = useMemo(
    () => parseFloat(trendPercentage.toFixed(2)),
    [trendPercentage]
  );

  let trendClasses = styles.trend;
  if (isDecreasing) {
    trendClasses = `${trendClasses} ${styles.isDecreasing}`;
  }

  return (
    <>
      <div
        className={trendClasses}
        data-tooltip-id={TOOLTIP_IDENTIFIER}
        data-tooltip-content={t('tooltip-content')}
      >
        {isDecreasing ? (
          <IconTrendingDown className={styles.icon} />
        ) : (
          <IconTrendingUp className={styles.icon} />
        )}

        {t('label', {percentage: trendPercentageSanitized})}
      </div>

      <Tooltip id={TOOLTIP_IDENTIFIER} />
    </>
  );
};

export default Item;
