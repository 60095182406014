import {animated, useTransition} from 'react-spring';
import {buildPageTitle} from '../../../../services/build-page-title';
import {
  CULTURE_DESIGN,
  DEFAULT,
  HEXACO,
} from '../../../../constants/module-algorithms';
import {
  CultureDesignModuleResult,
  GenericModuleResult,
} from '../../../../interfaces/user-assessment-module-result';
import AssessmentHeader from '../../../application/@components/assessment-header/assessment-header';
import {FunctionComponent, useCallback} from 'react';
import {Helmet} from 'react-helmet-async';
import {HexacoProfile} from '../../../../interfaces/hexaco-profile';
import {useAssessmentResult} from '../../../@hooks/queries';
import {useParams} from 'react-router';
import {useTranslation} from 'react-i18next';
import CultureDesignSummary from './@components/culture-design-summary';
import GenericSummary from './@components/generic-summary';
import HexacoSummary from './@components/hexaco-summary';
import Sidebar from './@components/sidebar';
import Spinner from '../../../@components/spinner';
import styles from './styles.module.scss';

const ModuleSummary: FunctionComponent = () => {
  const {t} = useTranslation('applicationPageTitle');

  const sidebarTransition = useTransition(true, {
    from: {x: -500, width: '0%', opacity: 0},
    enter: {x: 0, width: '100%', opacity: 1},
    leave: {x: -500, width: '0%', opacity: 0},
  });

  const {id: assessmentId} = useParams<string>();
  const {moduleId} = useParams<string>();

  const assessmentResult = useAssessmentResult({
    variables: {
      userAssessmentUuid: assessmentId as string,
      moduleUuid: moduleId as string,
    },
    retryDelay: 2000,
  });

  const renderModuleSummary = useCallback(
    (algorithm: string) => {
      if (!assessmentResult) return;

      switch (algorithm) {
        case HEXACO:
          return (
            <HexacoSummary
              moduleResult={
                assessmentResult.data?.moduleResult as HexacoProfile
              }
            />
          );
        case DEFAULT:
          return (
            <GenericSummary
              moduleName={assessmentResult.data?.moduleName ?? ''}
              moduleSlug={assessmentResult.data?.moduleSlug ?? ''}
              moduleResult={
                assessmentResult.data?.moduleResult as GenericModuleResult
              }
            />
          );
        case CULTURE_DESIGN:
          return (
            <CultureDesignSummary
              moduleResult={
                assessmentResult.data?.moduleResult as CultureDesignModuleResult
              }
            />
          );
        default:
          return null;
      }
    },
    [assessmentResult]
  );

  if (!assessmentResult.data) {
    return <Spinner fullScreen />;
  }

  return (
    <>
      <Helmet>
        <title>
          {buildPageTitle([
            assessmentResult.data.moduleName,
            t('page-title.assessments-summary'),
          ])}
        </title>
      </Helmet>

      <div className={styles.wrapper}>
        <AssessmentHeader />

        <div className={styles.container}>
          {sidebarTransition((style, _transition) => (
            <animated.div className={styles.sidebar} style={style}>
              {assessmentResult.data && (
                <Sidebar
                  assessmentId={assessmentId}
                  moduleId={moduleId}
                  result={assessmentResult.data}
                />
              )}
            </animated.div>
          ))}

          <div className={styles.contentWrapper}>
            <div className={styles.content}>
              {renderModuleSummary(assessmentResult.data.moduleAlgorithm)}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModuleSummary;
