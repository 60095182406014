import {FunctionComponent, PropsWithChildren} from 'react';
import styles from './styles.module.scss';

const Content: FunctionComponent<PropsWithChildren<{className?: string}>> = ({
  children,
  ...props
}) => {
  return (
    <tbody className={`${styles.content} ${props.className ?? ''}`}>
      {children}
    </tbody>
  );
};

export default Content;
