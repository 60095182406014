import {
  InvitedToAssessmentPayload,
  InvitedToAssessmentRawPayload,
} from '../../../interfaces/notifications/invite-to-assessment';

const sanitizePayloadFromRaw = (
  data: InvitedToAssessmentRawPayload
): InvitedToAssessmentPayload => {
  return {
    assessmentName: data.assessment_name,
    dueDate: data.due_date,
  };
};

export default sanitizePayloadFromRaw;
