import InstillAPI from '../axios-instance';
import isResponseSuccessful from '../../../utils/is-response-successful';
import {sanitizeCompanyAssessmentEventsFromRaw} from '../../../ui/@sanitizers/company-assessment-events';

export interface FetchCompanyAssessmentEventsPayload {
  companyUuid: string;
  assessmentUuid?: string;
}

export async function fetchCompanyAssessmentEvents(
  companyUuid: string,
  assessmentUuid: string
) {
  try {
    const response = await InstillAPI.get(
      `/companies/${companyUuid}/assessments/${assessmentUuid}/events`
    );

    if (!response.data || !isResponseSuccessful(response)) {
      throw Error('No data received');
    }

    return sanitizeCompanyAssessmentEventsFromRaw(response.data);
  } catch (error) {
    console.error(error);
  }
}
