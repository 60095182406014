import {FunctionComponent} from 'react';
import RadioOption from './@components/radio-option';
import PreviousQuestionLink from '../@components/previous-question-link';
import styles from './styles.module.scss';

interface ElementProps {
  questionText: string;
  lowerBandText: string;
  upperBandText: string;
  onResponse: (value: string) => void;
  onPreviousLinkClick?: () => void;
  answer?: string;
}

const SevenPointLikert: FunctionComponent<ElementProps> = ({
  questionText,
  lowerBandText,
  upperBandText,
  onResponse,
  onPreviousLinkClick,
  answer,
}) => {
  return (
    <>
      <div className={styles.container}>
        {onPreviousLinkClick ? (
          <PreviousQuestionLink onLinkClick={onPreviousLinkClick} />
        ) : null}

        <div className={styles.question}>{questionText}</div>

        <div className={styles.optionsContainer}>
          <div className={styles.bandLabel}>{lowerBandText}</div>

          <div className={styles.options}>
            <RadioOption
              value={'1'}
              name="likert"
              onChange={onResponse}
              isSelected={answer === '1'}
            />
            <RadioOption
              value={'2'}
              name="likert"
              onChange={onResponse}
              isSelected={answer === '2'}
            />

            <RadioOption
              value={'3'}
              name="likert"
              onChange={onResponse}
              isSelected={answer === '3'}
            />
            <RadioOption
              value={'4'}
              name="likert"
              onChange={onResponse}
              isSelected={answer === '4'}
            />
            <RadioOption
              value={'5'}
              name="likert"
              onChange={onResponse}
              isSelected={answer === '5'}
            />
            <RadioOption
              value={'6'}
              name="likert"
              onChange={onResponse}
              isSelected={answer === '6'}
            />
            <RadioOption
              value={'7'}
              name="likert"
              onChange={onResponse}
              isSelected={answer === '7'}
            />

            <span className={styles.line}></span>
          </div>

          <div className={styles.bandLabel}>{upperBandText}</div>
        </div>
      </div>
    </>
  );
};

export default SevenPointLikert;
