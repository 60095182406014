import {AuthUser} from '../../interfaces/auth-user';
import {
  checkInviteToken,
  fetchUserProfileForCompany,
} from '../../repositories/instill/queries';
import {CheckInviteTokenResponse} from '../../repositories/instill/queries/check-invite-token';
import {FunctionComponent, useCallback, useState} from 'react';
import {useAuth0} from '@auth0/auth0-react';
import {useCurrentAuthUserState} from '../@atoms/current-auth-user';
import {useCurrentCompanyState} from '../@atoms/current-company';
import {useEffectOnce} from '../@hooks/use-effect-once';
import {useJoinCompany} from '../@hooks/mutations';
import {useMountedState} from 'react-use';
import {useNavigate} from 'react-router-dom';
import {useParams, useLocation} from 'react-router-dom';
import {USER_PROFILE_STATUS_JOINED} from '../../constants/user-profile-status';
import {useTranslation} from 'react-i18next';
import axios from 'axios';
import Branding from '../application-routes/@components/auth-feedback/@components/branding';
import Button from '../@components/kit/form/button';
import styles from './styles.module.scss';
import TwoPanePage from '../@components/two-pane-page';

interface InvitePaneProps {
  inviteToken: string;
}

interface InviteError {
  reason: 'already_joined' | 'expired' | 'not_found' | 'redeemed' | 'unknown';
}

const userAlreadyJoinedCompany = (authUser: AuthUser, companyUuid: string) => {
  const userProfile = authUser?.userProfiles.find(
    (userProfile) => userProfile.companyUuid === companyUuid
  );

  if (!userProfile) return;

  if (userProfile.status === USER_PROFILE_STATUS_JOINED) {
    return authUser.companies.find((company) => company.uuid === companyUuid);
  }
};

const InvitePane: FunctionComponent<InvitePaneProps> = ({inviteToken}) => {
  const {t} = useTranslation('invite');

  const {loginWithRedirect} = useAuth0();
  const isMounted = useMountedState();
  const navigate = useNavigate();
  const location = useLocation();

  const [inviteTokenResponse, setInviteTokenResponse] =
    useState<CheckInviteTokenResponse>();

  const [error, setError] = useState<InviteError | null>(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
  const [currentAuthUser, setCurrentAuthUser] = useCurrentAuthUserState();
  const [, setCurrentCompany] = useCurrentCompanyState();

  const joinCompany = useJoinCompany();

  const onFetchInviteTokenResponse = useCallback(
    async (inviteToken: string) => {
      try {
        const response = await checkInviteToken(inviteToken);
        if (!response) return;

        const company = userAlreadyJoinedCompany(
          currentAuthUser!,
          response.companyUuid
        );

        if (company) {
          setCurrentCompany(company);
          navigate('/');
          return;
        }

        if (response.tokenRedeemed) {
          setError({reason: 'redeemed'});
        }

        setInviteTokenResponse(response);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          setError(error.response?.data.payload.status);
        }
      }
    },
    [currentAuthUser, setCurrentCompany, navigate]
  );

  const onJoinCompany = useCallback(async () => {
    if (!currentAuthUser) return;

    setIsButtonDisabled(true);

    try {
      const company = await joinCompany.mutateAsync({inviteToken});
      if (!company) return;

      const userProfile = await fetchUserProfileForCompany(company.uuid);
      if (!userProfile) return;

      const userProfiles = currentAuthUser.userProfiles.concat([userProfile]);
      const companies = currentAuthUser.companies.concat([company]);

      setCurrentAuthUser({...currentAuthUser, userProfiles, companies});
      setCurrentCompany(company);

      navigate('/');
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setError(error.response?.data.payload.status);
      }
    }
  }, [
    currentAuthUser,
    inviteToken,
    joinCompany,
    navigate,
    setCurrentAuthUser,
    setCurrentCompany,
  ]);

  const onSignUpAndJoinCompany = useCallback(async () => {
    loginWithRedirect({
      screen_hint: 'signup',
      appState: {returnTo: location.pathname},
    });
  }, [loginWithRedirect, location]);

  useEffectOnce(() => {
    if (!isMounted()) return;

    onFetchInviteTokenResponse(inviteToken);
  });

  if (error) {
    const errorMessage = t([`error.${error.reason}`, 'error.unknown']);

    return (
      <div className={styles.container}>
        <h1 className={styles.title}>{errorMessage}</h1>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{t('title')}</h1>
      {inviteTokenResponse?.companyLogoUrl && (
        <img
          className={styles.companyLogo}
          src={inviteTokenResponse?.companyLogoUrl}
          alt=""
        />
      )}

      {currentAuthUser ? (
        <>
          <p className={styles.text}>
            {t('join-company.text', {
              userName: currentAuthUser.name,
              companyName: inviteTokenResponse?.companyName,
            })}
          </p>

          <Button
            className={styles.button}
            disabled={isButtonDisabled}
            onClick={onJoinCompany}
          >
            {t('join-company.button')}
          </Button>
        </>
      ) : (
        <>
          <p className={styles.text}>
            {t('sign-up-and-join-company.text', {
              companyName: inviteTokenResponse?.companyName,
            })}
          </p>

          <Button className={styles.button} onClick={onSignUpAndJoinCompany}>
            {t('sign-up-and-join-company.button')}
          </Button>
        </>
      )}
    </div>
  );
};

const Invite: FunctionComponent = () => {
  const params = useParams();
  const inviteToken = params.inviteToken;

  if (!inviteToken) return <></>;

  return (
    <TwoPanePage
      mainPane={<InvitePane inviteToken={inviteToken} />}
      secondaryPane={<Branding />}
    />
  );
};

export default Invite;
