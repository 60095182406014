import InstillAPI from '../axios-instance';

export interface UserInvite {
  email: string;
  role: string;
}

export interface InviteUsersPayload {
  userInvites: UserInvite[];
  companyUuid: string;
}

export async function userInvites({
  userInvites,
  companyUuid,
}: {
  userInvites: UserInvite[];
  companyUuid: string;
}) {
  const payload = userInvites.map((userInvite) => {
    return {
      email: userInvite.email,
      role: userInvite.role,
    };
  });

  const response = await InstillAPI.post(
    `/companies/${companyUuid}/user-invites`,
    payload
  );

  return response;
}
