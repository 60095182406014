import {FunctionComponent, PropsWithChildren} from 'react';
import Content from './content';
import Header from './header';
import styles from './styles.module.scss';
import Title from './title';

interface ElementProps {
  variant?: 'with-border' | 'half' | 'full-height';
  spacing?: 'small' | 'xsmall';
}

interface RoundedCardComponent
  extends FunctionComponent<PropsWithChildren<ElementProps>> {
  Content: typeof Content;
  Header: typeof Header;
  Title: typeof Title;
}

const RoundedCard: RoundedCardComponent = ({children, variant, spacing}) => {
  let variantClass = [];

  if (variant) {
    variantClass.push(styles[variant]);
  }

  if (spacing) {
    const spacingClass = styles[`spacing-${spacing}`];
    variantClass.push(spacingClass);
  }

  variantClass.join(' ');

  return <div className={`${styles.box} ${variantClass}`}>{children}</div>;
};

RoundedCard.Content = Content;
RoundedCard.Header = Header;
RoundedCard.Title = Title;

export default RoundedCard;
