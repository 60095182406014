import InstillAPI from '../axios-instance';
import isResponseSuccessful from '../../../utils/is-response-successful';
import {sanitizeHierarchyRecommendationFromRaw} from '../../../ui/@sanitizers/map-your-organization';

export interface HierarchyRecommendationsPayload {
  companyUuid: string;
  userProfileUuid: string;
}

export async function fetchHierarchyRecommendations(
  companyUuid: string,
  userProfileUuid: string
) {
  try {
    const response = await InstillAPI.get(
      `/companies/${companyUuid}/user-profiles/${userProfileUuid}/hierarchy-recommendations`
    );

    if (!response.data || !isResponseSuccessful(response)) {
      return;
    }

    return sanitizeHierarchyRecommendationFromRaw(response.data);
  } catch (error) {
    console.error(error);
  }
}
