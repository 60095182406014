import {
  CultureVitalSign,
  CultureVitalSignRaw,
  InviteSense,
  InviteSenseRaw,
  Meeting,
  MeetingFeedback,
  MeetingFeedbackRaw,
  MeetingListItem,
  MeetingListItemRaw,
  MeetingRaw,
  MeetingSummary,
  MeetingSummaryRaw,
  SentimentalAnalysis,
  SentimentalAnalysisRaw,
  ValueAlignment,
  ValueAlignmentRaw,
  MeetingTopics,
  MeetingTopicsRaw,
} from '../../interfaces/meeting';

export const sanitizeMeetingsFromRaw = (
  data: MeetingListItemRaw[]
): MeetingListItem[] => {
  return data.map((meeting) => sanitizeMeetingListItemFromRaw(meeting));
};

export const sanitizeMeetingListItemFromRaw = (
  data: MeetingListItemRaw
): MeetingListItem => {
  return {
    duration: data.duration,
    name: data.name,
    participantsNames: data.participants_names,
    startDate: data.start_date,
    uuid: data.uuid,
  };
};

export const sanitizeMeetingFromRaw = (data: MeetingRaw): Meeting => {
  return {
    cultureVitalSigns: data.culture_vital_signs.map((cultureVitalSign) =>
      sanitizeCultureVitalSignsFromRaw(cultureVitalSign)
    ),
    sentimentalAnalysis: sanitizeSentimentalAnalysisFromRaw(
      data.sentimental_analysis
    ),
    duration: data.duration,
    name: data.name,
    presignedAudioUrl: data.presigned_audio_url,
    participantsNames: data.participants_names,
    startDate: data.start_date,
    uuid: data.uuid,
    feedback: data.feedback
      ? sanitizeMeetingFeedbackFromRaw(data.feedback)
      : null,
    valuesAlignment: data.values_alignment.map((value) =>
      sanitizeValuesAlignmentFromRaw(value)
    ),
    summary: sanitizeMeetingSummaryFromRaw(data.summary),
  };
};

export const sanitizeCultureVitalSignsFromRaw = (
  data: CultureVitalSignRaw
): CultureVitalSign => {
  return {
    examples: data.examples,
    feedback: data.feedback,
    name: data.name,
    score: data.score,
    slug: data.slug,
    speakerPerformances: data.speaker_performances,
  };
};

export const sanitizeMeetingSummaryFromRaw = (
  data: MeetingSummaryRaw
): MeetingSummary => {
  return {
    summary: data.summary,
    topics: data.topics.map((topic) => sanitizeTopicsFromRaw(topic)),
  };
};

export const sanitizeSentimentalAnalysisFromRaw = (
  data: SentimentalAnalysisRaw
): SentimentalAnalysis => {
  return {
    positive: data?.positive,
    neutral: data?.neutral,
    negative: data?.negative,
  };
};

export const sanitizeTopicsFromRaw = (
  data: MeetingTopicsRaw
): MeetingTopics => {
  return {
    topic: data.topic,
    relevance: data.relevance,
  };
};

export const sanitizeValuesAlignmentFromRaw = (
  data: ValueAlignmentRaw
): ValueAlignment => {
  return {
    examples: data.examples,
    feedback: data.feedback,
    name: data.name,
    score: data.score,
    slug: data.slug,
    speakerPerformances: data.speaker_performances,
  };
};

export const sanitizeInviteSenseForRaw = (
  data: InviteSense
): InviteSenseRaw => {
  return {
    meeting_url: data.meetingUrl,
  };
};

export const sanitizeInviteSenseFromRaw = (
  data: InviteSenseRaw
): InviteSense => {
  return {
    meetingUrl: data.meeting_url,
  };
};

export const sanitizeMeetingFeedbackFromRaw = (
  data: MeetingFeedbackRaw
): MeetingFeedback => {
  return {
    isPositive: data.is_positive,
  };
};

export const sanitizeMeetingFeedbackForRaw = (
  data: MeetingFeedback
): MeetingFeedbackRaw => {
  return {
    is_positive: data.isPositive,
  };
};
