import {createMutation} from 'react-query-kit';
import {updateUserProfileByAdmin} from '../../../repositories/instill/mutations';
import {UpddateUserProfileByAdminPayload} from '../../../repositories/instill/mutations/update-user-profile-by-admin';
import {UserProfile} from '../../../interfaces/user-profile';

export const useUpdateUserProfileByAdmin = createMutation<
  UserProfile | undefined,
  UpddateUserProfileByAdminPayload
>({
  mutationFn: async (variables) => {
    return await updateUserProfileByAdmin(
      variables.companyEmployeePayload,
      variables.companyUuid,
      variables.userProfileUuid
    );
  },
});
