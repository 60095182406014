import {ORDER_BY_PARAMETERS} from '../../../constants/filtering/order-by';
import {sanitizeMeetingsFromRaw} from '../../../ui/@sanitizers/meeting';
import {sanitizeMetaFromRaw} from '../../../ui/@sanitizers/meta';
import InstillAPI from '../axios-instance';

export interface FetchMeetingsPayload {
  companyUuid: string;
  currentPage?: number;
  itemsPerPage?: number;
  limit?: number;
  offset?: number;
  orderBy?: ORDER_BY_PARAMETERS;
  query?: string;
  sortBy?: string;
}

export const fetchMeetings = async (parameters: FetchMeetingsPayload) => {
  const {companyUuid, orderBy, sortBy, query, offset, limit} = parameters;

  let url = `/companies/${companyUuid}/meetings`;
  let queryParams: string[] = [];

  if (offset) {
    queryParams.push(`skip=${offset}`);
  }

  if (limit) {
    queryParams.push(`limit=${limit}`);
  }

  if (orderBy) {
    queryParams.push(`order=${orderBy}`);
  }

  if (sortBy) {
    queryParams.push(`sort_by=${sortBy}`);
  }

  if (query) {
    queryParams.push(`q=${query}`);
  }

  if (queryParams.length) {
    url = `${url}?${queryParams.join('&')}`;
  }

  const response = await InstillAPI.get(url);

  return {
    data: sanitizeMeetingsFromRaw(response.data.data),
    meta: sanitizeMetaFromRaw(response.data.meta),
  };
};
