import {Actions} from '../../../constants/permissions/actions';
import {buildPageTitle} from '../../../services/build-page-title';
import {DEFAULT_TOASTIFY_CONFIGURATION} from '../../../constants/toastify-configuration';
import {EventName} from '../../../constants/analytics/event-name';
import {EventType} from '../../../constants/analytics/event-type';
import {Helmet} from 'react-helmet-async';
import {toast, ToastOptions} from 'react-toastify';
import {useCallback} from 'react';
import {useEffectOnce} from '../../@hooks/use-effect-once';
import {useSafeCurrentCompany} from '../../@atoms/current-company';
import {useTranslation} from 'react-i18next';
import {useUpdateCompany} from '../../@hooks/mutations/use-update-company';
import ContentBox from './@components/content-box';
import FeedbackBanner from '../../@components/feedback-banner';
import Header from '../@components/header';
import InteractiveValues from '../@components/interactive-values';
import mainContainerStyles from '../../../styles/classes/main-container.module.scss';
import MainContent from '../@components/main-content';
import RoundedCard from '../../@components/rounded-card';
import styles from './styles.module.scss';
import useAnalytics from '../../@hooks/use-analytics';

export type PossibleContentType = 'mission' | 'vision' | 'leadership_manifesto';

export interface FormProps {
  mission?: string;
  vision?: string;
  leadershipManifesto?: string;
}

function Design() {
  const {t} = useTranslation(['design', 'applicationPageTitle']);
  const currentCompany = useSafeCurrentCompany();
  const {trackEvent} = useAnalytics();

  const updateCompany = useUpdateCompany();

  const onEditFormSubmit = useCallback(
    async (values: FormProps, fieldTranslationKey: string) => {
      const companyUuid = currentCompany.uuid;

      const response = await updateCompany.mutateAsync({
        payload: values,
        companyUuid,
      });

      const feedbackMessage = t(`feedback-messages.${fieldTranslationKey}`);
      toast.success(feedbackMessage, {
        ...(DEFAULT_TOASTIFY_CONFIGURATION as ToastOptions),
      });

      return response;
    },
    [currentCompany, updateCompany, t]
  );

  useEffectOnce(() => {
    trackEvent({
      eventName: EventName.PAGE_VIEWS.DESIGN,
      eventType: EventType.PAGE_VIEW,
    });
  });

  return (
    <>
      <Helmet>
        <title>
          {buildPageTitle(
            t('page-title.design-your-culture', {ns: 'applicationPageTitle'})
          )}
        </title>
      </Helmet>

      <FeedbackBanner text={t('components.feedback-banner.text')} />

      <Header title={t('components.header.title')} />

      <MainContent>
        <main className={mainContainerStyles.container}>
          <div className={styles.column}>
            <RoundedCard>
              <ContentBox
                action={Actions.EDIT_LEADERSHIP_MANIFESTO}
                eventName={EventName.DESIGN.LEADERSHIP_MANIFESTO}
                fieldName="leadership_manifesto"
                fieldTranslationKey="leadership-manifesto"
                fieldValue={currentCompany.leadershipManifesto}
                onSubmit={onEditFormSubmit}
                title={t('components.leadership-manifesto.title')}
                placeholderText={t(
                  'components.leadership-manifesto.placeholder-text'
                )}
              />
            </RoundedCard>

            <RoundedCard>
              <ContentBox
                action={Actions.EDIT_MISSION}
                eventName={EventName.DESIGN.MISSION}
                fieldName="mission"
                fieldValue={currentCompany.mission}
                onSubmit={onEditFormSubmit}
                placeholderText={t('components.mission.placeholder-text')}
                title={t('components.mission.title')}
              />
            </RoundedCard>

            <RoundedCard>
              <ContentBox
                action={Actions.EDIT_VISION}
                eventName={EventName.DESIGN.VISION}
                fieldName="vision"
                fieldValue={currentCompany.vision}
                onSubmit={onEditFormSubmit}
                placeholderText={t('components.vision.placeholder-text')}
                title={t('components.vision.title')}
              />
            </RoundedCard>
          </div>

          <div className={styles.column}>
            <InteractiveValues />
          </div>
        </main>
      </MainContent>
    </>
  );
}

export default Design;
