import {sanitizeUserActionStepsFromRaw} from '../../../ui/@sanitizers/user-action-steps';
import InstillAPI from '../axios-instance';
import isResponseSuccessful from '../../../utils/is-response-successful';

export interface FetchUserActionStepsPayload {
  companyUuid: string;
  onlyPending?: boolean;
  limit?: number;
}

export const fetchUserActionSteps = async (
  params: FetchUserActionStepsPayload
) => {
  let url = `/companies/${params.companyUuid}/me/action-steps`;
  let queryParams: string[] = [];

  if (params.onlyPending) {
    queryParams.push(`only_pending=${params.onlyPending}`);
  }

  if (params.limit) {
    queryParams.push(`limit=${params.limit}`);
  }

  if (queryParams) {
    url = `${url}?${queryParams.join('&')}`;
  }

  try {
    const response = await InstillAPI.get(url);

    if (!response.data || !isResponseSuccessful(response)) {
      throw Error('No data received');
    }

    return {
      data: sanitizeUserActionStepsFromRaw(response.data),
    };
  } catch (error) {
    console.error(error);
  }
};
