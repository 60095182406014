import {Navigate, Route, Routes} from 'react-router';
import {FunctionComponent} from 'react';
import EmployeesIndex from './index/employees-index';
import EmployeesShow from './show/employees-show';

const Employees: FunctionComponent = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<EmployeesIndex />} />

        <Route path=":id" element={<EmployeesShow />} />

        <Route
          path="*"
          element={<Navigate replace to="/application/people/employees" />}
        />
      </Routes>
    </>
  );
};

export default Employees;
