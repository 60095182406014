import {
  Candidate,
  CandidatesCreate,
  CandidatesCreateRaw,
  CandidateRaw,
  CandidateUpdate,
  CandidateUpdateRaw,
  CandidateProfile,
  CandidateProfileRaw,
} from '../../interfaces/candidate';

export const sanitizeCandidatesFromRaw = (
  data: CandidateRaw[]
): Candidate[] => {
  return data.map((candidate) => {
    return sanitizeCandidateFromRaw(candidate);
  });
};

export const sanitizeCandidateFromRaw = (data: CandidateRaw): Candidate => {
  return {
    assessmentStatus: data.assessment_status,
    dueDate: data.due_date,
    email: data.email,
    isActive: data.is_active,
    jobPositionUuid: data.job_position_uuid,
    name: data.name,
    uuid: data.candidate_uuid,
    valuesCalibrationScore: data.values_calibration_score,
  };
};

export const sanitizeCandidatesCreateForRaw = (
  data: CandidatesCreate
): CandidatesCreateRaw => {
  return {
    due_date: data.dueDate,
    emails: data.emails,
  };
};

export const sanitizeCandidateUpdateForRaw = (
  data: CandidateUpdate
): CandidateUpdateRaw => {
  return {
    due_date: data.dueDate,
    name: data.name,
    is_active: data.isActive,
    job_position_uuid: data.jobPositionUuid,
  };
};

export const sanitizeCandidateProfileFromRaw = (
  data: CandidateProfileRaw
): CandidateProfile => {
  return {
    uuid: data.uuid,
    name: data.name,
    email: data.email,
    createdAt: data.created_at,
    updatedAt: data.updated_at,
  };
};
