import {ACTION_STEP_STATUS_SORTABLE_COLUMNS} from '../../../../../../../../constants/filtering/sort-by';
import {ActionStep} from '../../../../../../../../interfaces/action-step';
import {FunctionComponent} from 'react';
import {SortedColumn} from '../../../../../../../../interfaces/filtering/sorted-column';
import {useTranslation} from 'react-i18next';
import SortButton from '../../../../../../@components/sort-button';
import styles from './styles.module.scss';
import Table from '../../../../../../../@components/table';
import TableRow from './@components/table-row';

interface ElementProps {
  actionSteps: ActionStep[];
  activeSortedColumn: SortedColumn | null;
  onColumnClicked: (column: SortedColumn) => void;
}

const ListView: FunctionComponent<ElementProps> = ({
  activeSortedColumn,
  actionSteps,
  onColumnClicked,
}) => {
  const {t} = useTranslation('apply', {
    keyPrefix:
      'action-step-status.components.action-steps-list.components.list-view',
  });

  return (
    <>
      <Table>
        <Table.Head className={styles.tableHeader}>
          <Table.Row>
            <Table.Header variant="large">
              <SortButton
                currentColumnName={ACTION_STEP_STATUS_SORTABLE_COLUMNS.NAME}
                currentColumnLabel={t('header.name')}
                onColumnClicked={onColumnClicked}
                activeSortedColumn={activeSortedColumn}
              />
            </Table.Header>

            <Table.Header variant="large">
              {t('header.job-position')}
            </Table.Header>

            <Table.Header variant="medium">
              <SortButton
                currentColumnName={
                  ACTION_STEP_STATUS_SORTABLE_COLUMNS.PENDING_ACTION_STEPS
                }
                currentColumnLabel={t('header.pending-action-steps')}
                onColumnClicked={onColumnClicked}
                activeSortedColumn={activeSortedColumn}
              />
            </Table.Header>

            <Table.Header variant="medium">
              <SortButton
                currentColumnName={
                  ACTION_STEP_STATUS_SORTABLE_COLUMNS.COMPLETED_ACTION_STEPS
                }
                currentColumnLabel={t('header.completed-action-steps')}
                onColumnClicked={onColumnClicked}
                activeSortedColumn={activeSortedColumn}
              />
            </Table.Header>
          </Table.Row>
        </Table.Head>

        <Table.Content>
          {actionSteps.map((actionStep) => (
            <TableRow
              key={`${actionStep.name}–${crypto.randomUUID()}`}
              actionStep={actionStep}
            />
          ))}
        </Table.Content>
      </Table>
    </>
  );
};

export default ListView;
