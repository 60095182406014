import {CompanyCoreValue} from '../../../interfaces/company-core-value';
import {createCompanyCoreValues} from '../../../repositories/instill';
import {CreateCompanyCoreValuesPayload} from '../../../repositories/instill/mutations/create-company-core-values';
import {createMutation} from 'react-query-kit';

export const useCreateCompanyCoreValues = createMutation<
  CompanyCoreValue[] | undefined,
  CreateCompanyCoreValuesPayload
>({
  mutationFn: async (variables) => {
    return await createCompanyCoreValues(
      variables.companyCoreValuePayload,
      variables.companyUuid
    );
  },
});
