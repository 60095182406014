import {COMPANIES_SORTABLE_COLUMNS} from '../../../../../../../constants/filtering/sort-by';
import {Company} from '../../../../../../../interfaces/company';
import {
  CompanyUpsert,
  Company as CompanySuperAdmin,
} from '../../../../../../../interfaces/admin/company';
import {DEFAULT_TOASTIFY_CONFIGURATION} from '../../../../../../../constants/toastify-configuration';
import {FunctionComponent, useCallback, useState} from 'react';
import {sanitizeCompanyProfileForRaw} from '../../../../../../@sanitizers/company';
import {SortedColumn} from '../../../../../../../interfaces/filtering/sorted-column';
import {toast, ToastOptions} from 'react-toastify';
import {useCompany} from '../../../../../../@hooks/queries';
import {useTranslation} from 'react-i18next';
import {useUpdateCompany} from '../../../../../../@hooks/mutations/use-update-company';
import CompanyProfileDialog from '../company-profile-dialog';
import getFullUrl from '../../../../../../../utils/get-full-url';
import SortButton from '../../../../../@components/sort-button';
import Table from '../../../../../../@components/table';
import TableRow from './@components/table-row';
import tableStyles from '../../../../../../../styles/classes/table.module.scss';

interface ElementProps {
  activeSortedColumn: SortedColumn | null;
  companies: CompanySuperAdmin[];
  onColumnClicked: (column: SortedColumn) => void;
  onRefreshCompanies: () => void;
}

const ListView: FunctionComponent<ElementProps> = ({
  companies,
  activeSortedColumn,
  onColumnClicked,
  onRefreshCompanies,
}) => {
  const {t} = useTranslation('adminPortal');

  const [selectedCompanyUuid, setSelectedCompanyUuid] = useState<string>();

  const [isCompanyProfileDialogVisible, setIsCompanyProfileDialogVisible] =
    useState(false);

  const company = useCompany({
    enabled: Boolean(selectedCompanyUuid),
    variables: {
      companyUuid: selectedCompanyUuid,
    },
  });

  const updateCompany = useUpdateCompany();

  const onCloseCompanyProfileDialog = useCallback(() => {
    setSelectedCompanyUuid(undefined);
    setIsCompanyProfileDialogVisible(false);
  }, []);

  const onUpdateCompanyProfile = useCallback(
    async (values: CompanyUpsert, company: Company) => {
      let updatedValues = values;
      updatedValues.websiteUrl = getFullUrl(values.websiteUrl);

      if (values.companyLogo === company.companyLogo) {
        updatedValues.companyLogo = undefined;
      }

      const payload = sanitizeCompanyProfileForRaw(updatedValues);

      const response = await updateCompany.mutateAsync({
        payload,
        companyUuid: company.uuid,
      });
      if (!response) return;

      const feedbackMessage = t(
        'components.company-list.feedback-messages.update-company'
      );

      toast.success(feedbackMessage, {
        ...(DEFAULT_TOASTIFY_CONFIGURATION as ToastOptions),
      });

      return response;
    },
    [t, updateCompany]
  );

  const onUpdateCompanyProfileFormSubmit = useCallback(
    async (values: CompanyUpsert, company: Company) => {
      await onUpdateCompanyProfile(values, company);
      onRefreshCompanies();

      setIsCompanyProfileDialogVisible(false);
    },
    [onRefreshCompanies, onUpdateCompanyProfile]
  );

  const onShowCompanyProfileDialog = useCallback(
    (company: CompanySuperAdmin) => {
      setIsCompanyProfileDialogVisible(true);

      setSelectedCompanyUuid(company.uuid);
    },
    [setSelectedCompanyUuid]
  );

  return (
    <>
      <Table>
        <Table.Head className={tableStyles.tableHeader}>
          <Table.Row variant="no-margin">
            <Table.Header variant="large">
              <SortButton
                currentColumnName={COMPANIES_SORTABLE_COLUMNS.NAME}
                currentColumnLabel={t(
                  'components.company-list.components.table.header.name'
                )}
                onColumnClicked={onColumnClicked}
                activeSortedColumn={activeSortedColumn}
              />
            </Table.Header>

            <Table.Header variant="medium">
              <SortButton
                currentColumnName={COMPANIES_SORTABLE_COLUMNS.ACTIVE_USERS}
                currentColumnLabel={t(
                  'components.company-list.components.table.header.active-users'
                )}
                onColumnClicked={onColumnClicked}
                activeSortedColumn={activeSortedColumn}
              />
            </Table.Header>

            <Table.Header variant="medium">
              <SortButton
                currentColumnName={COMPANIES_SORTABLE_COLUMNS.PENDING_INVITES}
                currentColumnLabel={t(
                  'components.company-list.components.table.header.pending-invites'
                )}
                onColumnClicked={onColumnClicked}
                activeSortedColumn={activeSortedColumn}
              />
            </Table.Header>

            <Table.Header variant="medium">
              {t(
                'components.company-list.components.table.header.pricing-plan'
              )}
            </Table.Header>

            <Table.Header variant="medium"></Table.Header>
          </Table.Row>
        </Table.Head>

        <Table.Content>
          {companies.map((company) => (
            <TableRow
              company={company}
              key={`${company.name}–${crypto.randomUUID()}`}
              onNameClicked={onShowCompanyProfileDialog}
              onRefreshCompanies={onRefreshCompanies}
              onUpdateCompanyProfile={onUpdateCompanyProfile}
            />
          ))}
        </Table.Content>
      </Table>

      {company.data && (
        <CompanyProfileDialog
          company={company.data}
          isDialogVisible={isCompanyProfileDialogVisible}
          onClose={onCloseCompanyProfileDialog}
          onSubmit={onUpdateCompanyProfileFormSubmit}
        />
      )}
    </>
  );
};

export default ListView;
