import {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import {FullUserProfile} from '../../../../../../../../interfaces/user-profile';
import profileStyles from '../../../../styles.module.scss';
import styles from './styles.module.scss';
import Card from './@components/card';

interface ElementProps {
  profile: FullUserProfile;
}

const View: FunctionComponent<ElementProps> = ({profile}) => {
  const {t} = useTranslation('application');

  return (
    <ul>
      <li className={styles.boxContent}>
        <div className={profileStyles.boxItemKey}>
          {t(
            'components.profile-details.components.organization-structure.components.view.manager-label'
          )}
        </div>

        <div className={styles.cardContainer}>
          {profile.manager ? (
            <Card user={profile.manager} />
          ) : (
            <div className={profileStyles.emptyValue}>–</div>
          )}
        </div>
      </li>

      <li className={styles.boxContent}>
        <div className={profileStyles.boxItemKey}>
          {t(
            'components.profile-details.components.organization-structure.components.view.reports-label'
          )}
        </div>

        <div className={styles.cardContainer}>
          {profile.reporters && profile.reporters.length > 0 ? (
            profile.reporters.map((report) => (
              <Card key={report.uuid} user={report} />
            ))
          ) : (
            <div className={profileStyles.emptyValue}>–</div>
          )}
        </div>
      </li>
    </ul>
  );
};

export default View;
