import {
  SignatureSelection,
  SignatureSelectionRaw,
  Signature,
  SignatureRaw,
} from '../../interfaces/signature';

export const sanitizeSignatureSelectionFromRaw = (
  data: SignatureSelectionRaw
): SignatureSelection => {
  return {
    letters: data.letters,
    fontFamily: data.font_family,
  };
};

export const sanitizeSignatureSelectionToRaw = (
  data: SignatureSelection
): SignatureSelectionRaw => {
  return {
    letters: data.letters,
    font_family: data.fontFamily,
  };
};

export const sanitizeSignatureFromRaw = (data: SignatureRaw): Signature => {
  return {
    signatureImageUrl: data.signature_image_url ?? null,
    signatureSelection: data.signature_selection
      ? sanitizeSignatureSelectionFromRaw(data.signature_selection)
      : null,

    createdAt: data.created_at,
    updatedAt: data.updated_at ?? null,
  };
};

export const sanitizeSignatureToRaw = (data: Signature): SignatureRaw => {
  return {
    signature_image_url: data.signatureImageUrl ?? null,
    signature_selection: data.signatureSelection
      ? sanitizeSignatureSelectionToRaw(data.signatureSelection)
      : null,
  };
};
