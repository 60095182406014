import {useAuth0} from '@auth0/auth0-react';
import {useTranslation} from 'react-i18next';
import Button from '../../../../../@components/kit/form/button';
import styles from './styles.module.scss';

function AlreadyLinked() {
  const {logout} = useAuth0();
  const {t} = useTranslation('authFeedback');

  return (
    <>
      <h1 className={styles.title}>{t('components.already-linked.title')}</h1>

      <p className={styles.text}>{t('components.already-linked.text')}</p>

      <Button
        className={styles.button}
        onClick={() => logout({returnTo: window.location.origin})}
      >
        {t('components.already-linked.button')}
      </Button>
    </>
  );
}

export default AlreadyLinked;
