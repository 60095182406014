import {buildPageTitle} from '../../../../services/build-page-title';
import {FunctionComponent, useCallback} from 'react';
import {Helmet} from 'react-helmet-async';
import {ORGANIZATION_CHECKLIST_STEP_KEYS} from '../../@components/setup-checklist/setup-checklist';
import {ReactComponent as IconSlack} from '../../../../assets/svg/slack.svg';
import {ReactComponent as IconUsers} from '../../../@components/kit/icons/users.svg';
import {useNavigate} from 'react-router';
import {useTranslation} from 'react-i18next';
import BackButton from '../../@components/back-button';
import Button from '../../../@components/kit/form/button';
import FormFooter from '../../../@components/kit/form/form-footer';
import organizationSetupStyles from '../styles.module.scss';
import styles from './styles.module.scss';

interface ElementProps {
  stepIdentifier: ORGANIZATION_CHECKLIST_STEP_KEYS;
  updateChecklist: (stepIdentifier: ORGANIZATION_CHECKLIST_STEP_KEYS) => void;
}

const NEXT_STEP_ROUTE = '/application/organization-setup/sign-off-on-values';

const InstallSlackApp: FunctionComponent<ElementProps> = ({
  stepIdentifier,
  updateChecklist,
}) => {
  const {t} = useTranslation(['organizationSetup', 'applicationPageTitle']);
  const navigate = useNavigate();

  const onContinueButtonClick = useCallback(() => {
    updateChecklist(stepIdentifier);
    navigate(NEXT_STEP_ROUTE);
  }, [navigate, updateChecklist, stepIdentifier]);

  const slackIntegrationUrl =
    window.env.REACT_APP_SLACK_INTEGRATION_INSTALLATION_URL;

  return (
    <>
      <Helmet>
        <title>
          {buildPageTitle([
            t('page-title.organization-setup.install-slack-app', {
              ns: 'applicationPageTitle',
            }),
            t('page-title.organization-setup.index', {
              ns: 'applicationPageTitle',
            }),
          ])}
        </title>
      </Helmet>

      <BackButton path="/application/organization-setup/invite-your-team" />

      <div className={styles.header}>
        <div className={styles.titleContainer}>
          <h1 className={organizationSetupStyles.headerTitle}>
            {t('install-slack-app.title')}
          </h1>

          <IconUsers className={organizationSetupStyles.headerIcon} />
        </div>
      </div>

      <div className={organizationSetupStyles.description}>
        {t('install-slack-app.description')}
      </div>

      <ul className={styles.content}>
        <li>
          <a
            href={slackIntegrationUrl}
            target="_blank"
            rel="noreferrer noopener"
            className={styles.link}
          >
            <IconSlack className={styles.icon} />

            {t('install-slack-app.slack-button-text')}
          </a>
        </li>
      </ul>

      <FormFooter>
        <Button onClick={onContinueButtonClick}>
          {t('install-slack-app.submit-button')}
        </Button>
      </FormFooter>
    </>
  );
};

export default InstallSlackApp;
