import {FunctionComponent, useState} from 'react';
import Spinner from '../../../../../@components/spinner';
import styles from './styles.module.scss';

interface ElementProps {
  url: string;
}

const Video: FunctionComponent<ElementProps> = ({url}) => {
  const [loading, setLoading] = useState(true);

  return (
    <>
      {loading && <Spinner />}

      <iframe
        width="100%"
        height="350px"
        title="Core Value video"
        src={`${url}?modestbranding=1&rel=0&cc_load_policy=1&iv_load_policy=3&color=white&controls=0&showinfo=0`}
        className={styles.mediaYoutube}
        frameBorder="0"
        allowFullScreen
        onLoad={() => setLoading(false)}
      />
    </>
  );
};

export default Video;
