import {
  ChangeEvent,
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {buildPageTitle} from '../../../../services/build-page-title';
import {dataUritoBlob} from '../../../../utils/files/data-uri-to-blob';
import {fetchPresignedUploadURLResourceType} from '../../../../repositories/instill/queries/fetch-presigned-url-config';
import {Helmet} from 'react-helmet-async';
import {ORGANIZATION_CHECKLIST_STEP_KEYS} from '../../@components/setup-checklist';
import {Signature} from '../../../../interfaces/signature';
import {
  useCreateSignature,
  useFetchPresignedUploadUrl,
  useUpdateCompany,
} from '../../../@hooks/mutations';
import {useCurrentAuthUserState} from '../../../@atoms/current-auth-user';
import {useSafeCurrentCompany} from '../../../@atoms/current-company';
import {useTranslation, Trans} from 'react-i18next';
import {useUploadToPresignedUrl} from '../../../@hooks/mutations';
import Button from '../../../@components/kit/form/button';
import organizationSetupStyles from '../styles.module.scss';
import SignatureBlock from '../../@components/signature-block';
import styles from './styles.module.scss';
import SwitchToggle from '../../../@components/kit/form/switch-toggle';

interface ElementProps {
  stepIdentifier: ORGANIZATION_CHECKLIST_STEP_KEYS;
  updateChecklist: (stepIdentifier: ORGANIZATION_CHECKLIST_STEP_KEYS) => void;
}

const SignOffOnValues: FunctionComponent<ElementProps> = ({
  updateChecklist,
  stepIdentifier,
}) => {
  const {t} = useTranslation(['organizationSetup', 'applicationPageTitle']);

  const [isSignOffEnabled, setIsSignOffEnabled] = useState(true);
  const [signature, setSignature] = useState<Signature | undefined>(undefined);

  const isSubmitButtonDisabled = useMemo(() => {
    if (!isSignOffEnabled) return false;

    return !(
      signature &&
      ((signature.signatureImageUrl && !signature.signatureSelection) ||
        (!signature.signatureImageUrl && signature.signatureSelection))
    );
  }, [isSignOffEnabled, signature]);

  const currentCompany = useSafeCurrentCompany();
  const [currentAuthUser] = useCurrentAuthUserState();

  const updateCompany = useUpdateCompany();
  const fetchPresignedUploadURL = useFetchPresignedUploadUrl();
  const createSignature = useCreateSignature();
  const uploadToPresignedURL = useUploadToPresignedUrl();

  const onSignOffToggled = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const isChecked = event.target.checked;

      setIsSignOffEnabled(isChecked);

      if (isChecked === false) {
        setSignature(undefined);
      }
    },
    []
  );

  const onSubmit = useCallback(async () => {
    const updateCompanyResponse = await updateCompany.mutateAsync({
      payload: {is_signature_required_on_values: isSignOffEnabled},
      companyUuid: currentCompany.uuid,
    });

    if (!updateCompanyResponse) return;

    if (!signature) {
      updateChecklist(stepIdentifier);
      return;
    }

    let signaturePayload = signature;

    if (signature.signatureImageUrl) {
      const config = await fetchPresignedUploadURL.mutateAsync({
        ressource: fetchPresignedUploadURLResourceType.SIGNATURE,
        mime: 'image/png',
        extension: 'png',
        companyUuid: currentCompany.uuid,
      });

      if (!config) return;

      const blob = dataUritoBlob(signature!.signatureImageUrl as string);
      const bucketURL = await uploadToPresignedURL.mutateAsync({
        config,
        file: blob,
      });
      if (!bucketURL) return;

      signaturePayload.signatureImageUrl = bucketURL;
    }

    const signOff = await createSignature.mutateAsync({
      signaturePayload,
      companyUuid: currentCompany.uuid,
    });

    if (!signOff) return;

    updateChecklist(stepIdentifier);
  }, [
    createSignature,
    currentCompany,
    fetchPresignedUploadURL,
    isSignOffEnabled,
    signature,
    stepIdentifier,
    updateChecklist,
    updateCompany,
    uploadToPresignedURL,
  ]);

  return (
    <>
      <Helmet>
        <title>
          {buildPageTitle([
            t('page-title.organization-setup.sign-off-on-values', {
              ns: 'applicationPageTitle',
            }),
            t('page-title.organization-setup.index', {
              ns: 'applicationPageTitle',
            }),
          ])}
        </title>
      </Helmet>

      <div className={organizationSetupStyles.header}>
        <h1 className={organizationSetupStyles.headerTitle}>
          {t('sign-off-on-values.title')}
        </h1>
      </div>

      <div className={organizationSetupStyles.description}>
        {t('sign-off-on-values.description', {
          currentCompany: currentCompany.name,
        })}
      </div>

      <div className={styles.switchContainer}>
        {t('sign-off-on-values.attestation.switch-label')}

        <div className={styles.switch}>
          <SwitchToggle
            isChecked={isSignOffEnabled}
            onChange={onSignOffToggled}
          />
        </div>
      </div>

      <div className={styles.attestationBlock}>
        <Trans
          t={t}
          i18nKey="sign-off-on-values.attestation.text"
          components={{highlight: <span className={styles.highlight} />}}
          values={{
            currentUser: currentAuthUser?.name,
            currentCompany: currentCompany.name,
          }}
        />

        <SignatureBlock
          personName={currentAuthUser?.name ?? ''}
          updateSelection={setSignature}
        />
      </div>

      <Button
        disabled={isSubmitButtonDisabled}
        onClick={onSubmit}
        variant="primary"
      >
        {t('sign-off-on-values.finish-button')}
      </Button>
    </>
  );
};

export default SignOffOnValues;
