import {format} from 'date-fns';
import {utcToZonedTime} from 'date-fns-tz';
import {FunctionComponent, useEffect, useMemo} from 'react';
import {Helmet} from 'react-helmet-async';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router';
import {EventName} from '../../../../../../constants/analytics/event-name';
import {EventType} from '../../../../../../constants/analytics/event-type';
import {buildPageTitle} from '../../../../../../services/build-page-title';
import mainContainerStyles from '../../../../../../styles/classes/main-container.module.scss';
import formatDurationFromSeconds from '../../../../../../utils/format-duration-from-seconds';
import {useSafeCurrentCompany} from '../../../../../@atoms/current-company';
import Spinner from '../../../../../@components/spinner';
import {useMeeting, useTranscript} from '../../../../../@hooks/queries';
import useAnalytics from '../../../../../@hooks/use-analytics';
import Header from '../../../../@components/header';
import MainContent from '../../../../@components/main-content';
import MeetingDetails from './components/meeting-details';
import styles from './styles.module.scss';

const MeetingsShowIndex: FunctionComponent = () => {
  const {t} = useTranslation(['sense', 'applicationPageTitle']);
  const {trackEvent} = useAnalytics();

  const {meetingId: meetingUuid} = useParams<string>();

  const currentCompany = useSafeCurrentCompany();
  const navigate = useNavigate();

  const meeting = useMeeting({
    enabled: Boolean(meetingUuid),
    variables: {
      companyUuid: currentCompany.uuid,
      meetingUuid: meetingUuid,
    },
    onError() {
      navigate('/application/sense/meetings/');
    },
  });

  const transcript = useTranscript({
    variables: {
      companyUuid: currentCompany.uuid,
      meetingUuid: meetingUuid,
    },
  });

  const meetingStartDate = useMemo(() => {
    if (!meeting.data) return;

    const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return format(
      utcToZonedTime(meeting.data.startDate, browserTimezone),
      'PPP, h:mm a'
    );
  }, [meeting]);

  useEffect(() => {
    trackEvent({
      eventName: EventName.PAGE_VIEWS.SENSE.MEETINGS.SHOW.INDEX,
      eventType: EventType.PAGE_VIEW,
    });
  }, [trackEvent]);

  if (meeting.isLoading || transcript.isLoading) return <Spinner />;
  if (!meeting.data || !transcript.data) return <></>;

  return (
    <>
      <Helmet>
        <title>
          {buildPageTitle([
            meeting.data.name,
            t('page-title.sense.meetings.index', {ns: 'applicationPageTitle'}),
            t('page-title.sense.index', {ns: 'applicationPageTitle'}),
          ])}
        </title>
      </Helmet>

      <Header variant="xsmall" title={meeting.data.name}>
        <div className={styles.headerRow}>
          <div className={styles.startDate}>{meetingStartDate}</div>

          <div className={styles.duration}>
            {formatDurationFromSeconds(meeting.data.duration)}
          </div>

          {/* Will uncomment below line once we have the be ready */}
          {/* <HeaderButton /> */}
        </div>
      </Header>

      <MainContent spacing="none">
        <main className={mainContainerStyles.container}>
          <MeetingDetails meeting={meeting.data} transcript={transcript.data} />
        </main>
      </MainContent>
    </>
  );
};

export default MeetingsShowIndex;
