import {Candidate} from '../../../../../../../../interfaces/candidate';
import {DEFAULT_TOASTIFY_CONFIGURATION} from '../../../../../../../../constants/toastify-configuration';
import {EventName} from '../../../../../../../../constants/analytics/event-name';
import {EventType} from '../../../../../../../../constants/analytics/event-type';
import {FunctionComponent, useCallback, useMemo, useState} from 'react';
import {JobPosition} from '../../../../../../../../interfaces/job-position';
import {Option} from '../../../../../../../../interfaces/dropdown-options';
import {ToastOptions, toast} from 'react-toastify';
import {useTranslation} from 'react-i18next';
import Dialog from '../../../../../../../@components/kit/dialog';
import Form, {AddCandidateFormProps} from './@components/form';
import NoOpenPositions from './@components/no-open-positions';
import Summary from './@components/summary';
import useAnalytics from '../../../../../../../@hooks/use-analytics';

interface ElementProps {
  isDialogVisible: boolean;
  jobPositionOptions?: Option[];
  jobPositions?: JobPosition[];
  onClose: () => void;
  onSubmit: ({
    values,
    emails,
  }: {
    values: AddCandidateFormProps;
    emails: string[];
  }) => Promise<Candidate | undefined>;
}

enum ADD_CANDIDATE_STEPS {
  FORM = 'FORM',
  SUMMARY = 'SUMMARY',
}

const AddCandidateDialog: FunctionComponent<ElementProps> = ({
  isDialogVisible,
  jobPositionOptions,
  jobPositions,
  onSubmit,
  onClose,
}) => {
  const {t} = useTranslation('jobs');

  const {trackEvent} = useAnalytics();

  const [currentStep, setCurrentStep] = useState(ADD_CANDIDATE_STEPS.FORM);
  const [formValues, setFormValues] = useState<AddCandidateFormProps>();

  const dialogTitle = useMemo(() => {
    const translationPrefix =
      'candidates.components.candidate-list.components.add-candidate-dialog';

    const translationKey = jobPositionOptions
      ? 'title'
      : 'title-without-open-job-position';

    return t(`${translationPrefix}.${translationKey}`);
  }, [jobPositionOptions, t]);

  const onFormSubmit = useCallback(
    (values: AddCandidateFormProps) => {
      setCurrentStep(ADD_CANDIDATE_STEPS.SUMMARY);
      setFormValues(values);

      trackEvent({
        eventName:
          EventName.JOBS.CANDIDATES.CANDIDATE_DIALOG.FORM.GO_TO_SUMMARY,
        eventType: EventType.BUTTON_CLICKED,
      });
    },
    [trackEvent]
  );

  const onEditValues = useCallback(() => {
    setCurrentStep(ADD_CANDIDATE_STEPS.FORM);

    trackEvent({
      eventName:
        EventName.JOBS.CANDIDATES.CANDIDATE_DIALOG.SUMMARY
          .UPDATE_ASSESSMENT_DETAILS,
      eventType: EventType.BUTTON_CLICKED,
    });
  }, [trackEvent]);

  const onAddCandidates = useCallback(async () => {
    if (!formValues) return;

    const emails = formValues.emails.split(',');

    const response = await onSubmit({values: formValues, emails});
    if (!response) return;

    trackEvent({
      eventName:
        EventName.JOBS.CANDIDATES.CANDIDATE_DIALOG.SUMMARY
          .UPDATE_ASSESSMENT_DETAILS,
      eventType: EventType.BUTTON_CLICKED,
    });

    const feedbackMessage = t(
      'candidates.components.candidate-list.components.add-candidate-dialog.feedback-messages.send-candidate-assessment',
      {count: emails.length}
    );

    toast.success(feedbackMessage, {
      ...(DEFAULT_TOASTIFY_CONFIGURATION as ToastOptions),
    });

    onClose();
  }, [formValues, t, onSubmit, trackEvent, onClose]);

  const onDialogClose = useCallback(() => {
    onClose();

    trackEvent({
      eventName: EventName.JOBS.CANDIDATES.CANDIDATE_DIALOG.FORM.CLOSE_DIALOG,
      eventType: EventType.BUTTON_CLICKED,
    });
  }, [onClose, trackEvent]);

  return (
    <Dialog isOpen={isDialogVisible} onClose={onClose} variant="small">
      <Dialog.Header title={dialogTitle} />

      <Dialog.Content>
        <>
          {currentStep === ADD_CANDIDATE_STEPS.FORM && (
            <>
              {jobPositionOptions ? (
                <Form
                  formValues={formValues}
                  jobPositionOptions={jobPositionOptions}
                  onClose={onDialogClose}
                  onSubmit={onFormSubmit}
                />
              ) : (
                <NoOpenPositions />
              )}
            </>
          )}
        </>

        {currentStep === ADD_CANDIDATE_STEPS.SUMMARY &&
          formValues &&
          jobPositions && (
            <Summary
              formValues={formValues}
              jobPositions={jobPositions}
              onEditValues={onEditValues}
              onSubmit={onAddCandidates}
            />
          )}
      </Dialog.Content>
    </Dialog>
  );
};

export default AddCandidateDialog;
