import {
  AssessmentEvent,
  AssessmentEventRaw,
} from '../../interfaces/assessment-event';
import {sanitizeAssessmentFromRaw} from './assessment';

export const sanitizerAssessmentEventFromRaw = (
  data: AssessmentEventRaw
): AssessmentEvent => {
  return {
    assessment: sanitizeAssessmentFromRaw(data.assessment),
    endDate: data.end_date,
    isActive: data.is_active,
    startDate: data.start_date,
    uuid: data.uuid,
  };
};
