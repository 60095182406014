import {
  AssessmentResponse,
  AssessmentResponseRaw,
} from '../../interfaces/assessment-response';

export const sanitizeAssessmentResponseFromRaw = (
  data: AssessmentResponseRaw[]
): AssessmentResponse[] => {
  return data.map((assessmentResponse) => {
    const {id, question_uuid, value} = assessmentResponse;

    return {
      id,
      createdAt: assessmentResponse.created_at,
      updatedAt: assessmentResponse.updated_at,
      question_uuid,
      value,
    };
  });
};
