import {createMutation} from 'react-query-kit';
import {
  UpdateCompanyCoreValuePayload,
  updateCompanyCoreValue,
} from '../../../repositories/instill/mutations/update-company-core-value';
import {CompanyCoreValue} from '../../../interfaces/company-core-value';

export const useUpdateCompanyCoreValue = createMutation<
  CompanyCoreValue | undefined,
  UpdateCompanyCoreValuePayload
>({
  mutationFn: async (variables) => {
    return await updateCompanyCoreValue(
      variables.payload,
      variables.companyUuid,
      variables.coreValueUuid
    );
  },
});
