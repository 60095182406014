import {
  OrganizationalHealth,
  OrganizationalHealthRaw,
  Dataset,
  DatasetRaw,
} from '../../interfaces/organizational-health';

export function sanitizeDatasetFromRaw(data: DatasetRaw): Dataset {
  return {
    eventId: data.event_id,
    eventStartDate: data.event_start_date,
    eventEndDate: data.event_end_date,
    average: data.average,
  };
}

export function sanitizeOrganizationalHealthFromRaw(
  data: OrganizationalHealthRaw
): OrganizationalHealth {
  return data.map((dimension) => ({
    currentAverage: dimension.current_average,
    dataset: dimension.dataset.map((dataset) =>
      sanitizeDatasetFromRaw(dataset)
    ),
    dimensionName: dimension.dimension_name,
    dimensionSlug: dimension.dimension_slug,
    trendingPercentage: dimension.trending_percentage,
    completedActionStepsPercentage: dimension.completed_action_steps_percentage,
    pendingActionStepsPercentage: dimension.pending_action_steps_percentage,
  }));
}
