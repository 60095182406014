import {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import {
  HierarchialUser,
  NewUser,
} from '../../../../../../interfaces/map-your-organization';
import Button from '../../../../../@components/kit/form/button';
import styles from './styles.module.scss';

interface ElementProps {
  potentialManager?: HierarchialUser | null;
  currentFormValue: NewUser | null;
  onAddManager: () => void;
}
const AddManager: FunctionComponent<ElementProps> = ({
  potentialManager,
  currentFormValue,
  onAddManager,
}) => {
  const {t} = useTranslation('application');

  if (currentFormValue === null && potentialManager) {
    return (
      <span className={styles.text}>
        {t('components.hierarchy-edit.main.add-manager-link-pretext')}

        <button className={styles.link} type="button" onClick={onAddManager}>
          {t('components.hierarchy-edit.main.add-manager-text')}
        </button>
      </span>
    );
  } else if (currentFormValue === null && !potentialManager) {
    return (
      <Button type="button" variant="secondary" onClick={onAddManager}>
        {t('components.hierarchy-edit.main.add-manager-text')}
      </Button>
    );
  }
  return null;
};

export default AddManager;
