import {SendMessageDialogFormProps} from '../../../ui/application/measure/manage-assessments/index/@components/assessments/@components/list-view/@component/send-message-dialog';
import {sanitizeCompanyAssessmentMessagePayloadToRaw} from '../../../ui/@sanitizers/company-assessments';
import InstillAPI from '../axios-instance';
import isResponseSuccessful from '../../../utils/is-response-successful';

export interface SendReminderEmailForAssessmentPayload {
  companyUuid: string;
  assessmentUuid: string;
  payload: SendMessageDialogFormProps;
}

export async function sendReminderEmailForAssessment(
  companyUuid: string,
  assessmentUuid: string,
  payload: SendMessageDialogFormProps
) {
  const sanitizedPayload =
    sanitizeCompanyAssessmentMessagePayloadToRaw(payload);

  const response = await InstillAPI.post(
    `/companies/${companyUuid}/assessments/${assessmentUuid}/send-message`,
    sanitizedPayload
  );

  if (!isResponseSuccessful(response)) {
    throw Error('No data received');
  }

  return response;
}
