import {Company} from '../../../interfaces/company';
import {createMutation} from 'react-query-kit';
import {updateCompany} from '../../../repositories/instill';
import {UpdateCompanyPayload} from '../../../repositories/instill/mutations/update-company';

export const useUpdateCompany = createMutation<
  Company | undefined,
  UpdateCompanyPayload
>({
  mutationFn: async (variables) => {
    return await updateCompany(variables.payload, variables.companyUuid);
  },
});
