import {AxiosResponse} from 'axios';

const SUCCESS_STATUS_CODE = 200;
const NO_CONTENT_SUCCESS_STATUS_CODE = 204;

function isResponseSuccessful(response?: AxiosResponse) {
  return (
    response?.status === SUCCESS_STATUS_CODE ||
    response?.status === NO_CONTENT_SUCCESS_STATUS_CODE
  );
}

export default isResponseSuccessful;
