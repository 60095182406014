import {WEBSITE_PROTOCOL_REGEX} from '../../constants/regex';

function getFullUrl(url?: string) {
  if (!url || url === '') return;

  if (!url.match(WEBSITE_PROTOCOL_REGEX)) {
    return `https://${url}`;
  }

  return url;
}

export default getFullUrl;
