import {formatDate} from '../../../../../utils/date';
import {FunctionComponent, useEffect, useRef} from 'react';
import * as echarts from 'echarts';
import styles from './styles.module.scss';

export interface TimeseriesData {
  color: string;
  data: Array<Array<string | number>>;
  name: string;
}

interface ElementProps {
  lines: Array<TimeseriesData>;
}

const DATE_FORMAT = 'dd MMMM yyyy';

const Tooltip = ({params}: any) => {
  const date = new Date(params[0].data[0]);
  const dateString = formatDate(date.toISOString(), DATE_FORMAT);

  return `
    <div>
      <p>${dateString}</p>

      <p>
        ${params[0].marker} ${params[0].seriesName}: ${params[0].data[1]}%
      </p>
    </div>
  `;
};

const TimeseriesLine: FunctionComponent<ElementProps> = ({lines}) => {
  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (chartRef.current) {
      const chart = echarts.init(chartRef.current);

      const option = {
        tooltip: {
          trigger: 'axis',
          formatter: (params: any) => Tooltip({params}),
        },

        xAxis: {
          type: 'time',
          minInterval: 60 * 60 * 1000 * 24,
        },

        yAxis: {
          type: 'value',
          max: 100,
          min: 0,
          minInterval: 20,
        },

        grid: {
          left: 36,
          right: 36,
          top: '5%',
          bottom: '5%',
          z: 0,
        },

        series: lines.map((line) => ({
          name: line.name,
          data: line.data,
          type: 'line',
          color: line.color,
          smooth: true,
        })),
      };

      chart.setOption(option);

      window.addEventListener('resize', () => {
        chart.resize();
      });
    }
  }, [lines]);

  return <div ref={chartRef} className={styles.chart} />;
};

export default TimeseriesLine;
