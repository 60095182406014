import {buildPageTitle} from '../../../../services/build-page-title';
import {dataUritoBlob} from '../../../../utils/files/data-uri-to-blob';
import {EventName} from '../../../../constants/analytics/event-name';
import {EventType} from '../../../../constants/analytics/event-type';
import {fetchPresignedUploadURLResourceType} from '../../../../repositories/instill/queries/fetch-presigned-url-config';
import {FONT_FAMILIES} from '../../@components/signature-block/constants/constants';
import {FunctionComponent, useCallback, useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {ReactComponent as IconEdit} from '../../../@components/kit/icons/edit.svg';
import {Signature} from '../../../../interfaces/signature';
import {SignOffOnValuesEvent} from '../../../../interfaces/analytics/event-properties';
import {Trans, useTranslation} from 'react-i18next';
import {
  useCreateSignature,
  useFetchPresignedUploadUrl,
} from '../../../@hooks/mutations';
import {useCurrentAuthUserState} from '../../../@atoms/current-auth-user';
import {USER_CHECKLIST_STEP_KEYS} from '../../@components/setup-checklist';
import {useSafeCurrentCompany} from '../../../@atoms/current-company';
import {useUploadToPresignedUrl} from '../../../@hooks/mutations';
import BackButton from '../../@components/back-button';
import Button from '../../../@components/kit/form/button';
import SignatureBlock from '../../@components/signature-block';
import styles from './styles.module.scss';
import useAnalytics from '../../../@hooks/use-analytics';
import userSetupStyles from '../styles.module.scss';

interface ElementProps {
  stepIdentifier: USER_CHECKLIST_STEP_KEYS;
  updateChecklist: (stepIdentifier: USER_CHECKLIST_STEP_KEYS) => void;
}

const SignOffOnValues: FunctionComponent<ElementProps> = ({
  updateChecklist,
  stepIdentifier,
}) => {
  const {t} = useTranslation(['userSetup', 'applicationPageTitle']);
  const [signature, setSignature] = useState<Signature | undefined>(undefined);

  const {trackEvent} = useAnalytics();
  const fetchPresignedUploadURL = useFetchPresignedUploadUrl();
  const uploadToPresignedURL = useUploadToPresignedUrl();
  const createSignature = useCreateSignature();

  const isSubmitButtonDisabled = !(
    signature &&
    ((signature.signatureImageUrl && !signature.signatureSelection) ||
      (!signature.signatureImageUrl && signature.signatureSelection))
  );

  const currentCompany = useSafeCurrentCompany();
  const [currentAuthUser] = useCurrentAuthUserState();

  const trackSignatureEvent = useCallback(() => {
    if (!signature) return;

    let eventProperties = {} as SignOffOnValuesEvent;

    if (signature.signatureImageUrl) {
      eventProperties['selectedSignature'] = 'Custom';
    } else {
      eventProperties['selectedSignature'] = signature.signatureSelection!
        .fontFamily as keyof typeof FONT_FAMILIES;
    }

    trackEvent({
      eventName: EventName.USER_SETUP.SIGN_OFF_ON_VALUES,
      eventType: EventType.SIGNATURE_CLICKED,
      eventProperties,
    });
  }, [trackEvent, signature]);

  const onSubmit = useCallback(async () => {
    if (!signature) return;

    let signaturePayload = signature;

    if (signature.signatureImageUrl) {
      const config = await fetchPresignedUploadURL.mutateAsync({
        ressource: fetchPresignedUploadURLResourceType.SIGNATURE,
        mime: 'image/png',
        extension: 'png',
        companyUuid: currentCompany.uuid,
      });

      if (!config) return;

      const blob = dataUritoBlob(signature!.signatureImageUrl as string);

      const bucketURL = await uploadToPresignedURL.mutateAsync({
        config,
        file: blob,
      });
      if (!bucketURL) return;

      signaturePayload.signatureImageUrl = bucketURL;
    }

    const signOff = await createSignature.mutateAsync({
      signaturePayload,
      companyUuid: currentCompany.uuid,
    });

    if (!signOff) return;

    trackSignatureEvent();
    updateChecklist(stepIdentifier);
  }, [
    createSignature,
    currentCompany,
    fetchPresignedUploadURL,
    signature,
    stepIdentifier,
    trackSignatureEvent,
    updateChecklist,
    uploadToPresignedURL,
  ]);

  return (
    <>
      <Helmet>
        <title>
          {buildPageTitle(
            t('page-title.user-setup.sign-off-on-values', {
              ns: 'applicationPageTitle',
            })
          )}
        </title>
      </Helmet>

      <BackButton path="/application/user-setup/review-company-values" />

      <div className={userSetupStyles.header}>
        <h1 className={userSetupStyles.headerTitle}>
          {t('sign-off-on-values.title')}
        </h1>

        <IconEdit className={userSetupStyles.headerIcon} />
      </div>

      <div className={styles.attestationBlock}>
        <div className={styles.attestationPreText}>
          {t('sign-off-on-values.attestation.pre-text')}
        </div>

        <Trans
          t={t}
          i18nKey="sign-off-on-values.attestation.text"
          components={{highlight: <span className={styles.highlight} />}}
          values={{
            currentUser: currentAuthUser?.name,
            currentCompany: currentCompany.name,
          }}
        />

        <SignatureBlock
          personName={currentAuthUser?.name ?? ''}
          updateSelection={setSignature}
        />
      </div>

      <Button
        disabled={isSubmitButtonDisabled}
        onClick={onSubmit}
        variant="primary"
      >
        {t('sign-off-on-values.finish-button')}
      </Button>
    </>
  );
};

export default SignOffOnValues;
