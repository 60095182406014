import {FunctionComponent, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {CultureVitalSign} from '../../../../../../../../../../interfaces/meeting';
import RoundedCard from '../../../../../../../../../@components/rounded-card';
import SelectedTabView from './@components/selected-tab-view/selected-tab-view';
import List from './@components/list';
import styles from './styles.module.scss';

interface ElementProps {
  cultureVitalSigns: CultureVitalSign[];
}

const CultureVitals: FunctionComponent<ElementProps> = ({
  cultureVitalSigns,
}) => {
  const {t} = useTranslation('sense', {
    keyPrefix:
      'meetings.show.index.components.meeting-details.components.culture-vitals',
  });

  const [activeVitalSignIndex, setActiveVitalSignIndex] = useState<number>(0);

  const onTabClick = (tabIndex: number) => {
    setActiveVitalSignIndex(tabIndex);
  };

  return (
    <div className={styles.wrapper}>
      <RoundedCard>
        <RoundedCard.Title>{t('title')}</RoundedCard.Title>

        <RoundedCard.Content>
          <div className={styles.container}>
            <List
              onTabClick={onTabClick}
              cultureVitalSigns={cultureVitalSigns}
              activeVitalSignIndex={activeVitalSignIndex}
            />

            <SelectedTabView
              cultureVitalSign={cultureVitalSigns[activeVitalSignIndex]}
            />
          </div>
        </RoundedCard.Content>
      </RoundedCard>
    </div>
  );
};

export default CultureVitals;
