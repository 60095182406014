import {buildPageTitle} from '../../../../../services/build-page-title';
import {EventName} from '../../../../../constants/analytics/event-name';
import {EventType} from '../../../../../constants/analytics/event-type';
import {FunctionComponent, useEffect} from 'react';
import {Helmet} from 'react-helmet-async';
import {useTranslation} from 'react-i18next';
import Instruction from './@components/instruction';
import linkSlackStyles from '../../styles.module.scss';
import useAnalytics from '../../../../@hooks/use-analytics';

const LinkSlackUser: FunctionComponent = () => {
  const {t} = useTranslation('applicationPageTitle');

  const {trackEvent} = useAnalytics();

  useEffect(() => {
    trackEvent({
      eventName: EventName.PAGE_VIEWS.LINK_SLACK,
      eventType: EventType.PAGE_VIEW,
    });
  }, [trackEvent]);

  return (
    <>
      <Helmet>
        <title>{buildPageTitle(t('page-title.link-slack'))}</title>
      </Helmet>

      <div className={linkSlackStyles.wrapper}>
        <div className={linkSlackStyles.mainPane}>
          <Instruction />
        </div>
      </div>
    </>
  );
};

export default LinkSlackUser;
