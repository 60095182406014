import {useFormControlContext} from '../../form-control';
import {FieldProps} from 'formik';
import {FunctionComponent} from 'react';
import {OnChangeValue} from 'react-select';
import {selectStyles, selectTheme} from '../styles.module';
import Select from 'react-select';

interface Option {
  label: string;
  value: string;
}

interface ElementProps extends FieldProps {
  isDisabled?: boolean;
  onChangeCallback?: (value: Option) => void;
  options: Option[];
  placeholder?: string;
}

const SearchableSelect: FunctionComponent<ElementProps> = ({
  options,
  placeholder = '',
  form,
  isDisabled,
  field,
  onChangeCallback,
}) => {
  const {hasError} = useFormControlContext();
  const onChange = (option: OnChangeValue<Option, false>) => {
    onChangeCallback && onChangeCallback(option as Option);

    form.setFieldValue(field.name, (option as Option).value);
  };

  const getValue = () => {
    const value = form.values[field.name];
    if (value) {
      return options.find((option) => option.value === value);
    }
    return null;
  };

  return (
    <Select
      styles={selectStyles(hasError)}
      theme={selectTheme}
      menuPlacement="auto"
      onBlur={() => {
        form.setFieldTouched(field.name);
      }}
      value={getValue()}
      placeholder={placeholder}
      onChange={onChange}
      isDisabled={isDisabled}
      options={options}
    />
  );
};

export default SearchableSelect;
