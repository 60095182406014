import React, {useRef} from 'react';
import styles from './styles.module.scss';

interface ChatFormProps {
  children: React.ReactElement | React.ReactElement[];
  disableAutoScroll?: boolean;
  scrollParent?: React.RefObject<HTMLElement> | (() => void);
  onChange?: (state: Record<string, any>) => void;
  onSubmit?: (state: Record<string, any>) => void;
}

const Chat: React.FC<ChatFormProps> = ({
  children,
  disableAutoScroll,
  scrollParent,
  onChange,
  onSubmit,
}) => {
  // handling the case when there's a single child
  const childrenArray = Array.isArray(children) ? children : [children];

  const initialState = childrenArray.reduce(
    (acc, child) => Object.assign(acc, {[child.props.name]: null}),
    {}
  );

  const reducer = (
    state: Record<string, any>,
    {type, payload}: {type: string; payload: any}
  ) => {
    return {...state, [type]: payload};
  };
  const [state, dispatch] = React.useReducer(reducer, initialState);

  React.useEffect(() => {
    // Only call after at least one question is answered
    if (
      Object.keys(state).some((key) => state[key] !== null) &&
      typeof onChange === 'function'
    ) {
      onChange(state);
    }
  });
  React.useEffect(() => {
    // Only call after the last question is answered
    if (
      Object.keys(state).every((key) => state[key] !== null) &&
      typeof onSubmit === 'function'
    ) {
      onSubmit(state);
    }
  });

  const parentRef = useRef<HTMLDivElement>(null);

  scrollParent = scrollParent ?? parentRef;

  if (!children) return null;

  // Logic to render the question only when the previous one has been answered
  // The first one has to be rendered by default though
  return (
    <div className={styles.chatForm} ref={parentRef}>
      <>
        {React.cloneElement(childrenArray[0], {
          dispatch,
          state,
          key: childrenArray[0].props.name,
          noScroll: false,
          scrollParent,
        })}
        {childrenArray.slice(1).map((child, index) => {
          if (state[childrenArray[index].props.name] !== null) {
            return React.cloneElement(child, {
              dispatch,
              key: child.props.name,
              state,
              scrollParent,
              ...(disableAutoScroll && {noScroll: false}),
            });
          }
          return null;
        })}
      </>
    </div>
  );
};

export default Chat;
