import {
  DEFAULT_ORDER_BY_PROPERTY,
  ORDER_BY_PARAMETERS,
  SUPPORTED_ORDER_BY_PARAMETERS,
} from '../../constants/filtering/order-by';
import {SortedColumn} from '../../interfaces/filtering/sorted-column';

interface Parameters {
  sortBy?: string | null;
  orderBy?: string | null;
  supportedColumnsToSort: any[];
}

function getActiveSortedColumnFromQueryParameters(parameters: Parameters) {
  const {sortBy, orderBy, supportedColumnsToSort} = parameters;

  if (!sortBy || !orderBy) return null;

  const isSortByValueSupported = supportedColumnsToSort.includes(sortBy);
  const isOrderByValueSupported = SUPPORTED_ORDER_BY_PARAMETERS.includes(
    orderBy as ORDER_BY_PARAMETERS
  );

  if (!isSortByValueSupported) return null;

  const order = isOrderByValueSupported
    ? (orderBy as ORDER_BY_PARAMETERS)
    : DEFAULT_ORDER_BY_PROPERTY;

  return {
    columnName: sortBy,
    order,
  } as SortedColumn;
}

export default getActiveSortedColumnFromQueryParameters;
