import {createMutation} from 'react-query-kit';
import {Notification} from '../../../interfaces/notification';
import {
  MarkNotificationsAsReadPayload,
  markNotificationsAsRead,
} from '../../../repositories/instill/mutations/mark-notifications-as-read';

export const useMarkNotificationAsRead = createMutation<
  Notification[] | undefined,
  MarkNotificationsAsReadPayload
>({
  mutationFn: async (variables) => {
    return await markNotificationsAsRead(
      variables.companyUuid,
      variables.notificationUuids
    );
  },
});
