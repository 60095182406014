import {ExpectationForm} from '../../../../interfaces/company-core-value';
import {FieldProps, Field} from 'formik';
import {FunctionComponent, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import BehaviourList from './@components/behaviour-list';
import {CoreValue} from '../../../../interfaces/core-value';
import FormGroup from '../../../@components/kit/form/form-group/form-group';
import MutableSelect from '../../../@components/kit/form/hybrid-select/mutable-select/mutable-select';
import styles from './styles.module.scss';

interface ElementProps {
  fieldNamePrefix: string;
  valueName: string;
  valueExpectations: ExpectationForm;
  coreValueOptions: CoreValue[];
}

const BehaviorsForm: FunctionComponent<ElementProps> = ({
  fieldNamePrefix,
  valueName,
  valueExpectations,
  coreValueOptions,
}) => {
  const {t} = useTranslation(['organizationSetup', 'common']);

  const normAndBehaviourByName = useCallback(
    (name: string) => {
      const selectedCoreValue = coreValueOptions?.find(
        (coreValue) => coreValue.name === name
      );

      let normsAndBehaviours = [];

      if (selectedCoreValue) {
        normsAndBehaviours.push(
          ...selectedCoreValue.normsAndBehaviours!.map((normAndBehaviour) => ({
            value: normAndBehaviour.behaviour,
            label: normAndBehaviour.behaviour,
          }))
        );
      }
      return normsAndBehaviours;
    },
    [coreValueOptions]
  );

  const getDefaultValues = useCallback((valueArray: string[]) => {
    return valueArray.map((value) => {
      return {
        value,
        label: value,
      };
    });
  }, []);

  const handleRemoveItem = useCallback(
    (
      form: FieldProps['form'],
      field: FieldProps['field'],
      valueArray: string[],
      itemIndex: number
    ) => {
      form.setFieldValue(
        field.name,
        valueArray.filter((_, index) => index !== itemIndex)
      );
    },
    []
  );

  return (
    <>
      <Field name={`${fieldNamePrefix}.expectations.exceeded`}>
        {({field, form, meta}: FieldProps<string>) => (
          <FormGroup className={styles.formGroup}>
            <label className={styles.tag} htmlFor={field.name}>
              {t('codify-your-values.form.exceeds-expectations.label')}
            </label>

            <div className={styles.behaviorContainer}>
              {normAndBehaviourByName(valueName) && (
                <MutableSelect
                  field={field}
                  form={form}
                  meta={meta}
                  options={normAndBehaviourByName(valueName)}
                  defaultValue={getDefaultValues(valueExpectations.exceeded)}
                  placeholder={t(
                    'codify-your-values.form.exceeds-expectations.placeholder'
                  )}
                  renderValues={false}
                  isMulti={true}
                />
              )}

              <BehaviourList
                list={valueExpectations.exceeded}
                handleRemoveBehavior={(itemIndex: number) =>
                  handleRemoveItem(
                    form,
                    field,
                    valueExpectations.exceeded,
                    itemIndex
                  )
                }
              />
            </div>
          </FormGroup>
        )}
      </Field>

      <Field name={`${fieldNamePrefix}.expectations.met`}>
        {({field, form, meta}: FieldProps<string>) => (
          <FormGroup className={styles.formGroup}>
            <label className={styles.tag} htmlFor={field.name}>
              {t('codify-your-values.form.meets-expectations.label')}
            </label>

            <div className={styles.behaviorContainer}>
              {normAndBehaviourByName(valueName) && (
                <MutableSelect
                  field={field}
                  form={form}
                  meta={meta}
                  options={normAndBehaviourByName(valueName)}
                  defaultValue={getDefaultValues(valueExpectations.met)}
                  placeholder={t(
                    'codify-your-values.form.meets-expectations.placeholder'
                  )}
                  renderValues={false}
                  isMulti={true}
                />
              )}

              <BehaviourList
                list={valueExpectations.met}
                handleRemoveBehavior={(itemIndex: number) =>
                  handleRemoveItem(
                    form,
                    field,
                    valueExpectations.met,
                    itemIndex
                  )
                }
              />
            </div>
          </FormGroup>
        )}
      </Field>

      <Field name={`${fieldNamePrefix}.expectations.missed`}>
        {({field, form, meta}: FieldProps<string>) => (
          <FormGroup className={styles.formGroup}>
            <label className={styles.tag} htmlFor={field.name}>
              {t('codify-your-values.form.misses-expectations.label')}
            </label>

            <div className={styles.behaviorContainer}>
              {normAndBehaviourByName(valueName) && (
                <MutableSelect
                  field={field}
                  form={form}
                  meta={meta}
                  options={normAndBehaviourByName(valueName)}
                  defaultValue={getDefaultValues(valueExpectations.missed)}
                  placeholder={t(
                    'codify-your-values.form.misses-expectations.placeholder'
                  )}
                  renderValues={false}
                  isMulti={true}
                />
              )}

              <BehaviourList
                list={valueExpectations.missed}
                handleRemoveBehavior={(itemIndex: number) =>
                  handleRemoveItem(
                    form,
                    field,
                    valueExpectations.missed,
                    itemIndex
                  )
                }
              />
            </div>
          </FormGroup>
        )}
      </Field>
    </>
  );
};

export default BehaviorsForm;
