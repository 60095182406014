export enum TIMESCALE_VALUES {
  THREE_MONTH = '3M',
  SIX_MONTH = '6M',
  ONE_YEAR = '1Y',
  CUSTOM = 'Custom',
}

export const DEFAULT_TIMESCALE = TIMESCALE_VALUES.THREE_MONTH;

export const SUPPORTED_TIMESCALE_PARAMETERS = [
  TIMESCALE_VALUES.THREE_MONTH,
  TIMESCALE_VALUES.SIX_MONTH,
  TIMESCALE_VALUES.ONE_YEAR,
  TIMESCALE_VALUES.CUSTOM,
];

export const TIMESCALE_VALUES_DELTA = {
  [TIMESCALE_VALUES.THREE_MONTH]: 3,
  [TIMESCALE_VALUES.SIX_MONTH]: 6,
  [TIMESCALE_VALUES.ONE_YEAR]: 12,
};
