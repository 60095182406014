import {
  WORK_HOURS_OPTIONS,
  WORK_MINUTES_OPTIONS,
} from '../../../../../../../../constants/work-style-preferences';
import {Field, FieldProps, useFormikContext} from 'formik';
import {FunctionComponent} from 'react';
import {PROFILE_DETAILS_FORM_VALUES} from '../../../../profile-details';
import {Trans, useTranslation} from 'react-i18next';
import {FullUserProfile} from '../../../../../../../../interfaces/user-profile';
import {WorkStylePreferencesValues} from '../../../../../../../../interfaces/work-style-preferences';
import formatTimeWithTimeZone from '../../../../../../../../utils/format-time-with-time-zone';
import FormControl from '../../../../../../../@components/kit/form/form-control/form-control';
import Label from '../../../../../../../@components/kit/form/label/label';
import profileStyles from '../../../../styles.module.scss';
import Select from '../../../../../../../@components/kit/form/select/select';
import styles from './styles.module.scss';
import Tag from '../../../../../../../@components/tag';

interface ElementProps {
  isInEditMode: boolean;
  profile: FullUserProfile;
  workStyleValues?: WorkStylePreferencesValues;
}

const EditWorkHours: FunctionComponent<{
  profile: FullUserProfile;
}> = ({profile}) => {
  const {t} = useTranslation('common');

  const {errors} = useFormikContext<PROFILE_DETAILS_FORM_VALUES>();

  return (
    <ul className={styles.editionList}>
      <li className={styles.editionListItem}>
        <Label>
          {t('constants.work-style-preferences.keys.start-time.label')}
        </Label>

        <ul className={styles.subList}>
          <li className={styles.subListItem}>
            <Field name="workStylePreferences.workHours.startTimeHour">
              {({field}: FieldProps<string>) => (
                <FormControl
                  variant="floating-error"
                  error={
                    Boolean(
                      errors.workStylePreferences?.workHours?.startTimeHour
                    )
                      ? errors.workStylePreferences?.workHours?.startTimeHour
                      : undefined
                  }
                >
                  <Select id={field.name} {...field}>
                    <option value="" disabled>
                      {t(
                        'constants.work-style-preferences.keys.start-time.hours-label'
                      )}
                    </option>

                    {WORK_HOURS_OPTIONS.map(({label, value}) => (
                      <option key={value} value={value}>
                        {t(label)}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Field>
          </li>

          <li className={styles.subListItem}>
            <Field name="workStylePreferences.workHours.startTimeMinute">
              {({field}: FieldProps<string>) => (
                <FormControl variant="floating-error">
                  <Select id={field.name} {...field}>
                    <option value="" disabled>
                      {t(
                        'constants.work-style-preferences.keys.start-time.minutes-label'
                      )}
                    </option>

                    {WORK_MINUTES_OPTIONS.map(({label, value}) => (
                      <option key={value} value={value}>
                        {t(label)}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Field>
          </li>
        </ul>
      </li>

      <li className={styles.editionListItem}>
        <Label>
          {t('constants.work-style-preferences.keys.end-time.label')}
        </Label>

        <ul className={styles.subList}>
          <li className={styles.subListItem}>
            <Field name="workStylePreferences.workHours.endTimeHour">
              {({field}: FieldProps<string>) => (
                <FormControl
                  variant="floating-error"
                  error={
                    Boolean(errors.workStylePreferences?.workHours?.endTimeHour)
                      ? errors.workStylePreferences?.workHours?.endTimeHour
                      : undefined
                  }
                >
                  <Select id={field.name} {...field}>
                    <option value="" disabled>
                      {t(
                        'constants.work-style-preferences.keys.end-time.hours-label'
                      )}
                    </option>

                    {WORK_HOURS_OPTIONS.map(({label, value}) => (
                      <option key={value} value={value}>
                        {t(label)}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Field>
          </li>

          <li className={styles.editionListItem}>
            <Field name="workStylePreferences.workHours.endTimeMinute">
              {({field}: FieldProps<string>) => (
                <FormControl
                  variant="floating-error"
                  error={
                    Boolean(
                      errors.workStylePreferences?.workHours?.endTimeMinute
                    )
                      ? errors.workStylePreferences?.workHours?.endTimeMinute
                      : undefined
                  }
                >
                  <Select id={field.name} {...field}>
                    <option value="" disabled>
                      {t(
                        'constants.work-style-preferences.keys.end-time.minutes-label'
                      )}
                    </option>

                    {WORK_MINUTES_OPTIONS.map(({label, value}) => (
                      <option key={value} value={value}>
                        {t(label)}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Field>
          </li>
        </ul>
      </li>
    </ul>
  );
};

const ReadOnlyWorkHours: FunctionComponent<{
  profile: FullUserProfile;
}> = ({profile}) => {
  const {t} = useTranslation('application');

  const {workStylePreferences} = profile;

  const formattedStartTime = formatTimeWithTimeZone({
    time: workStylePreferences?.workHours?.startTime,
    timeZone: profile.timeZone,
  });

  const formattedEndTime = formatTimeWithTimeZone({
    time: workStylePreferences?.workHours?.endTime,
    timeZone: profile.timeZone,
  });

  return (
    <>
      {workStylePreferences?.workHours ? (
        <Tag>
          <Trans
            t={t}
            i18nKey="components.profile-details.keys.work-hours-range"
            values={{
              startTime: formattedStartTime,
              endTime: formattedEndTime,
            }}
          />
        </Tag>
      ) : (
        <div className={profileStyles.emptyValue}>–</div>
      )}
    </>
  );
};

const WorkHours: FunctionComponent<ElementProps> = ({
  isInEditMode,
  profile,
}) => {
  if (isInEditMode) {
    return <EditWorkHours profile={profile} />;
  }

  return <ReadOnlyWorkHours profile={profile} />;
};

export default WorkHours;
