import {DimensionHealth} from '../../../../../../../../../../interfaces/organizational-health';
import {FunctionComponent, useMemo} from 'react';
import {ReactComponent as IconLightbulb} from '../../../../../../../../../@components/kit/icons/lightbulb.svg';
import {useTranslation} from 'react-i18next';
import styles from './styles.module.scss';

interface ElementProps {
  trendPercentage: number;
  dimension: DimensionHealth;
}

const ActionStepPercentage: FunctionComponent<ElementProps> = ({
  dimension,
  trendPercentage,
}) => {
  const {t} = useTranslation('measure', {
    keyPrefix:
      'overall-health.components.health-gauges.components.item.action-step-percentage',
  });

  const percentage = useMemo(() => {
    const isDecreasing = trendPercentage < 0;
    let value = 0;

    if (isDecreasing) {
      value = dimension.pendingActionStepsPercentage;
    } else {
      value = dimension.completedActionStepsPercentage;
    }

    return value;
  }, [dimension, trendPercentage]);

  if (!percentage) return null;

  return (
    <>
      <span className={styles.percentage}>
        {t('label', {percentage: percentage})}
      </span>

      <IconLightbulb className={styles.icon} />
    </>
  );
};

export default ActionStepPercentage;
