import {createMutation} from 'react-query-kit';
import {Team} from '../../../interfaces/team';
import {updateTeam} from '../../../repositories/instill';
import {UpdateTeamPayload} from '../../../repositories/instill/mutations/update-team';

export const useUpdateTeam = createMutation<
  Team | undefined,
  UpdateTeamPayload
>({
  mutationFn: async (variables) => {
    return await updateTeam(
      variables.payload,
      variables.teamUuid,
      variables.companyUuid
    );
  },
});
