import {FunctionComponent, TextareaHTMLAttributes} from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';
import AutoresizeTextarea from '../../../../../@components/kit/form/autoresize-textarea';
import styles from './role-item.module.scss';
import Tag from '../../../../../@components/tag';

interface ElementProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label: string;
  name: string;
  isDisabled?: boolean;
}

const RoleItem: FunctionComponent<ElementProps> = ({
  label,
  isDisabled,
  ...props
}) => {
  const {t} = useTranslation('components');
  const [field] = useField(props.name);

  let itemClasses = styles.item;

  if (isDisabled) {
    itemClasses = `${itemClasses} ${styles['is-disabled']}`;
  }

  return (
    <div className={itemClasses}>
      <div className={styles.tag}>
        <Tag>{label}</Tag>
      </div>

      <div className={styles.content}>
        <AutoresizeTextarea
          disabled={isDisabled || false}
          {...field}
          placeholder={t('invite-users.form.roles.placeholder')}
        />
      </div>
    </div>
  );
};

export default RoleItem;
