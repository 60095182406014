import {Field, FieldProps} from 'formik';
import {FunctionComponent} from 'react';
import {FullUserProfile} from '../../../../../../../../interfaces/user-profile';
import {useTranslation} from 'react-i18next';
import {WORK_STYLE_TRANSLATION_MAP} from '../../../../../../../../constants/work-style-preferences';
import {WorkStylePreferencesValues} from '../../../../../../../../interfaces/work-style-preferences';
import Input from '../../../../../../../@components/kit/form/input/input';
import Label from '../../../../../../../@components/kit/form/label';
import profileStyles from '../../../../styles.module.scss';
import Select from '../../../../../../../@components/kit/form/select';
import styles from './styles.module.scss';
import Tag from '../../../../../../../@components/tag';

interface ElementProps {
  isInEditMode: boolean;
  profile: FullUserProfile;
  workStyleValues?: WorkStylePreferencesValues;
}

const EditFeedbackPreferences: FunctionComponent<{
  profile: FullUserProfile;
  workStyleValues?: WorkStylePreferencesValues;
}> = ({profile, workStyleValues}) => {
  const {t} = useTranslation('common');

  return (
    <ul className={styles.editionList}>
      <li className={styles.editionListItem}>
        <Field name="workStylePreferences.feedbackPreferences.deliveryMethod">
          {({field}: FieldProps<string>) => (
            <>
              <Label htmlFor={field.name}>
                {t(
                  'constants.work-style-preferences.keys.feedback-preferences.delivery-method.label'
                )}
              </Label>

              <Input
                {...field}
                type="text"
                placeholder={t(
                  'constants.work-style-preferences.keys.feedback-preferences.delivery-method.placeholder'
                )}
              />
            </>
          )}
        </Field>
      </li>

      <li className={styles.editionListItem}>
        <Field name="workStylePreferences.feedbackPreferences.cadence">
          {({field}: FieldProps<string>) => (
            <>
              <Label htmlFor={field.name}>
                {t(
                  'constants.work-style-preferences.keys.feedback-preferences.cadence.label'
                )}
              </Label>

              <Select id={field.name} {...field}>
                <option value="" disabled>
                  {t(
                    'constants.work-style-preferences.keys.feedback-preferences.cadence.placeholder'
                  )}
                </option>

                {workStyleValues?.feedbackCadence.map((value) => {
                  return (
                    <option key={value} value={value}>
                      {t(
                        WORK_STYLE_TRANSLATION_MAP[
                          value as keyof typeof WORK_STYLE_TRANSLATION_MAP
                        ],
                        {ns: 'common'}
                      )}
                    </option>
                  );
                })}
              </Select>
            </>
          )}
        </Field>
      </li>
    </ul>
  );
};

const ReadOnlyFeedbackPreferences: FunctionComponent<{
  profile: FullUserProfile;
}> = ({profile}) => {
  const {t} = useTranslation();
  const {workStylePreferences} = profile;

  return (
    <ul className={profileStyles.boxItemList}>
      {workStylePreferences?.feedbackPreferences?.deliveryMethod && (
        <li className={profileStyles.boxItemSublistItem}>
          <Tag>{workStylePreferences?.feedbackPreferences.deliveryMethod}</Tag>
        </li>
      )}

      {workStylePreferences?.feedbackPreferences?.cadence && (
        <li className={profileStyles.boxItemSublistItem}>
          <Tag>
            {t(
              WORK_STYLE_TRANSLATION_MAP[
                workStylePreferences?.feedbackPreferences
                  ?.cadence as keyof typeof WORK_STYLE_TRANSLATION_MAP
              ],
              {ns: 'common'}
            )}
          </Tag>
        </li>
      )}
    </ul>
  );
};

const FeedbackPreferences: FunctionComponent<ElementProps> = ({
  isInEditMode,
  profile,
  workStyleValues,
}) => {
  if (isInEditMode) {
    return (
      <EditFeedbackPreferences
        profile={profile}
        workStyleValues={workStyleValues}
      />
    );
  }

  return <ReadOnlyFeedbackPreferences profile={profile} />;
};

export default FeedbackPreferences;
