import {
  ChangeEvent,
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {HierarchialUser} from '../../../../../../interfaces/map-your-organization';
import AvatarPlaceholder from '../../../../../@components/avatar-placeholder';
import Input from '../../../../../@components/kit/form/input/input';
import styles from './styles.module.scss';

interface ElementProps {
  userData: HierarchialUser;
  isDisabled?: boolean;
  onCheck: (value: HierarchialUser) => void;
  onUncheck: (value: HierarchialUser) => void;
}

const UserCheckbox: FunctionComponent<ElementProps> = ({
  userData,
  isDisabled,
  onCheck,
  onUncheck,
}) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (isDisabled) {
      setChecked(false);
    }
  }, [isDisabled]);

  const containerClassName = checked
    ? `${styles.boxContainer} ${styles.checked}`
    : styles.boxContainer;

  const onCheckboxChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const {checked} = event.target;

      setChecked(checked);

      if (checked) {
        onCheck(userData);
      } else {
        onUncheck(userData);
      }
    },
    [onCheck, onUncheck, userData]
  );

  return (
    <>
      <div className={containerClassName}>
        <div>
          {userData.picture ? (
            <img src={userData.picture} alt="" className={styles.picture} />
          ) : (
            <AvatarPlaceholder name={userData.name} />
          )}
        </div>

        <div className={styles.infoContainer}>
          <div className={styles.name}>{userData.name}</div>
          <span className={styles.pill}>{userData.jobTitle}</span>
        </div>

        <div className={styles.checkbox}>
          <Input
            type="checkbox"
            checked={checked}
            disabled={isDisabled}
            onChange={onCheckboxChange}
          />
        </div>
      </div>
    </>
  );
};
export default UserCheckbox;
