import {buildPageTitle} from '../../../../../services/build-page-title';
import {EventName} from '../../../../../constants/analytics/event-name';
import {EventType} from '../../../../../constants/analytics/event-type';
import {FunctionComponent, useCallback, useEffect} from 'react';
import {Helmet} from 'react-helmet-async';
import {
  useCompanyAssessments,
  useCompanyEmployees,
  useTeams,
} from '../../../../@hooks/queries';
import {useSafeCurrentCompany} from '../../../../@atoms/current-company';
import {useTranslation} from 'react-i18next';
import Assessments from './@components/assessments';
import mainContainerStyles from '../../../../../styles/classes/main-container.module.scss';
import RoundedCard from '../../../../@components/rounded-card';
import useAnalytics from '../../../../@hooks/use-analytics';

const ManageAssessmentsIndex: FunctionComponent = () => {
  const {t} = useTranslation('applicationPageTitle');

  const currentCompany = useSafeCurrentCompany();
  const {trackEvent} = useAnalytics();

  const companyAssessments = useCompanyAssessments({
    variables: {
      companyUuid: currentCompany.uuid,
    },
  });

  const companyEmployees = useCompanyEmployees({
    variables: {
      companyUuid: currentCompany.uuid,
    },
  });

  const teams = useTeams({
    variables: {
      companyUuid: currentCompany.uuid,
    },
  });

  const isLoading = companyAssessments.isLoading || companyEmployees.isLoading;

  const onRefreshCompanyAssessments = useCallback(async () => {
    companyAssessments.refetch();
  }, [companyAssessments]);

  useEffect(() => {
    trackEvent({
      eventName: EventName.PAGE_VIEWS.MEASURE.MANAGE_ASSESSMENTS.INDEX,
      eventType: EventType.PAGE_VIEW,
    });
  }, [trackEvent]);

  return (
    <>
      <Helmet>
        <title>
          {buildPageTitle([
            t(
              'applicationPageTitle:page-title.measure-your-culture.manage-assessments.index'
            ),
            t('applicationPageTitle:page-title.measure-your-culture.index'),
          ])}
        </title>
      </Helmet>

      <main className={mainContainerStyles.container}>
        <RoundedCard>
          <Assessments
            companyAssessments={companyAssessments.data}
            companyEmployees={companyEmployees.data?.data}
            teams={teams.data?.data || []}
            isFetchingInProgress={isLoading}
            onRefreshCompanyAssessments={onRefreshCompanyAssessments}
          />
        </RoundedCard>
      </main>
    </>
  );
};

export default ManageAssessmentsIndex;
