import {Actions} from '../../../../../../../constants/permissions/actions';
import {
  ChangeEvent,
  FormEvent,
  FunctionComponent,
  useCallback,
  useState,
} from 'react';
import {DEFAULT_NUMBER_OF_ITEMS_PER_PAGE} from '../../../../../../../constants/filtering/pagination';
import {
  EMPLOYEES_SORTABLE_COLUMNS,
  SUPPORTED_SORTABLE_COLUMNS,
} from '../../../../../../../constants/filtering/sort-by';
import {EventName} from '../../../../../../../constants/analytics/event-name';
import {EventType} from '../../../../../../../constants/analytics/event-type';
import {getUserProfileForCompany} from '../../../../../../../services/user-profiles';
import {
  ORDER_BY_PARAMETERS,
  SUPPORTED_ORDER_BY_PARAMETERS,
} from '../../../../../../../constants/filtering/order-by';
import {ReactComponent as IconPlus} from '../../../../../../@components/kit/icons/plus.svg';
import {SearchTriggeredEvent} from '../../../../../../../interfaces/analytics/event-properties';
import {SortedColumn} from '../../../../../../../interfaces/filtering/sorted-column';
import {StringParam, createEnumParam, useQueryParams} from 'use-query-params';
import {useCompanyEmployees} from '../../../../../../@hooks/queries';
import {useCurrentAuthUserState} from '../../../../../../@atoms/current-auth-user';
import {useDebounce} from 'react-use';
import {useSafeCurrentCompany} from '../../../../../../@atoms/current-company';
import {useTranslation} from 'react-i18next';
import {useUserPermission} from '../../../../../../@hooks/use-user-permission';
import getActiveSortedColumnFromQueryParams from '../../../../../../../utils/get-active-sorted-column-from-query-params';
import InviteUsersDialog from '../../../../@components/invite-users-dialog';
import ListView from './@components/list-view';
import NoResultsBox from '../../../../../@components/no-results-box/no-results-box';
import Pagination from '../../../../../../@components/pagination';
import RoundedCard from '../../../../../../@components/rounded-card';
import SearchForm from '../../../../../@components/search-form';
import Spinner from '../../../../../../@components/spinner';
import styles from './styles.module.scss';
import useAnalytics from '../../../../../../@hooks/use-analytics';
import usePagination from '../../../../../../@hooks/use-pagination';

const MINIMUM_SEARCH_LETTERS = 2;
const DELAY_FOR_SEARCH_DEBOUNCE_IN_MS = 500;

const EmployeeList: FunctionComponent = () => {
  const {t} = useTranslation('people');

  const currentCompany = useSafeCurrentCompany();
  const [currentAuthUser] = useCurrentAuthUserState();

  const userProfile = getUserProfileForCompany(
    currentAuthUser!,
    currentCompany
  );
  const can = useUserPermission(userProfile.role);
  const {trackEvent} = useAnalytics();

  const [queryParameters, setQueryParameters] = useQueryParams({
    orderBy: createEnumParam(SUPPORTED_ORDER_BY_PARAMETERS),
    sortBy: createEnumParam(SUPPORTED_SORTABLE_COLUMNS.EMPLOYEES),
    query: StringParam,
  });

  const isInviteUsersButtonVisible =
    can(Actions.ADD_EMPLOYEE) &&
    !currentCompany.companySettings.isHRISProviderUKG;

  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(
    queryParameters.query
  );

  const [isInviteDialogVisible, setIsInviteDialogVisible] = useState(false);

  const [activeSortedColumn, setActiveSortedColumn] =
    useState<SortedColumn | null>(
      getActiveSortedColumnFromQueryParams({
        orderBy: queryParameters.orderBy,
        sortBy: queryParameters.sortBy,
        supportedColumnsToSort: SUPPORTED_SORTABLE_COLUMNS.EMPLOYEES,
      })
    );

  const {
    currentPage,
    goToNextPage,
    goToPreviousPage,
    offset,
    onResetPagination,
    pageInQueryParams,
    setCurrentPage,
    setPageInQueryParams,
  } = usePagination({itemsPerPage: DEFAULT_NUMBER_OF_ITEMS_PER_PAGE});

  const employees = useCompanyEmployees({
    variables: {
      companyUuid: currentCompany.uuid,
      currentPage,
      itemsPerPage: DEFAULT_NUMBER_OF_ITEMS_PER_PAGE,
      limit: DEFAULT_NUMBER_OF_ITEMS_PER_PAGE,
      offset,
      orderBy: queryParameters.orderBy ?? undefined,
      query: queryParameters.query ?? undefined,
      sortBy:
        (queryParameters.sortBy as EMPLOYEES_SORTABLE_COLUMNS) ?? undefined,
    },
  });

  const onColumnClicked = useCallback(
    (column: SortedColumn) => {
      setActiveSortedColumn(column);

      setQueryParameters({
        ...queryParameters,
        sortBy: column.columnName as EMPLOYEES_SORTABLE_COLUMNS,
        orderBy: column.order as ORDER_BY_PARAMETERS,
      });

      if (!pageInQueryParams) return;

      setCurrentPage(1);
      setPageInQueryParams(1);
    },
    [
      pageInQueryParams,
      setCurrentPage,
      queryParameters,
      setQueryParameters,
      setPageInQueryParams,
    ]
  );

  const onSearch = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setDebouncedSearchQuery(value);

      const eventProperties = {
        searchQuery: value,
      } as SearchTriggeredEvent;

      trackEvent({
        eventName: EventName.PEOPLE.EMPLOYEES.SEARCH,
        eventType: EventType.SEARCH_TRIGGERED,
        eventProperties,
      });
    },
    [trackEvent]
  );

  const onClearSearch = useCallback(
    (event: FormEvent) => {
      event.preventDefault();

      setQueryParameters({
        ...queryParameters,
        query: undefined,
      });

      trackEvent({
        eventName: EventName.PEOPLE.EMPLOYEES.CLEAR_SEARCH,
        eventType: EventType.BUTTON_CLICKED,
      });
    },
    [queryParameters, setQueryParameters, trackEvent]
  );

  useDebounce(
    () => {
      if (
        debouncedSearchQuery !== '' &&
        (debouncedSearchQuery?.length ?? 0) < MINIMUM_SEARCH_LETTERS
      ) {
        return;
      }

      setQueryParameters({
        ...queryParameters,
        query: debouncedSearchQuery,
      });

      if (!pageInQueryParams) return;
      onResetPagination();
    },
    DELAY_FOR_SEARCH_DEBOUNCE_IN_MS,
    [debouncedSearchQuery]
  );

  return (
    <>
      {isInviteDialogVisible && (
        <InviteUsersDialog
          isDialogVisible={isInviteDialogVisible}
          onClose={() => setIsInviteDialogVisible(false)}
          onSuccess={() => setIsInviteDialogVisible(false)}
        />
      )}

      <RoundedCard.Header className={styles.header}>
        <RoundedCard.Title>
          {t('employees.components.employee-list.title')}

          {isInviteUsersButtonVisible && (
            <button
              type="button"
              className={styles.button}
              aria-label={t(
                'employees.components.employee-list.invite-user-button-accessibility-text'
              )}
              onClick={() => setIsInviteDialogVisible(true)}
            >
              <IconPlus className={styles.buttonIcon} />
            </button>
          )}
        </RoundedCard.Title>

        <div>
          <SearchForm
            searchQueryInQueryParams={queryParameters.query}
            onSearch={onSearch}
            placeholder={t(
              'manage-directory.components.employee-list.search-box-placeholder'
            )}
            onClearSearch={onClearSearch}
          />
        </div>
      </RoundedCard.Header>

      <RoundedCard.Content>
        {employees.isLoading ? (
          <Spinner />
        ) : (
          <>
            {employees.data?.data.length ? (
              <>
                <ListView
                  activeSortedColumn={activeSortedColumn}
                  employees={employees.data?.data}
                  onColumnClicked={onColumnClicked}
                />

                {employees.data?.meta && (
                  <Pagination
                    currentPage={currentPage}
                    itemsPerPage={DEFAULT_NUMBER_OF_ITEMS_PER_PAGE}
                    onNextPageLinkClicked={goToNextPage}
                    onPreviousPageLinkClicked={goToPreviousPage}
                    totalItems={employees.data?.meta.totalEntries}
                  />
                )}
              </>
            ) : (
              <NoResultsBox />
            )}
          </>
        )}
      </RoundedCard.Content>
    </>
  );
};

export default EmployeeList;
