import {ReactNode} from 'react';
import styles from './styles.module.scss';
import swooshSource from '../../../assets/images/bg-swoosh.png';

type TwoPanePageProps = {
  mainPane: ReactNode;
  secondaryPane: ReactNode;
};

function TwoPanePage({mainPane, secondaryPane}: TwoPanePageProps) {
  return (
    <div>
      <img src={swooshSource} className={styles.swooshBackground} alt="" />

      <div className={styles.wrapper}>
        <div className={styles.secondaryPane}>{secondaryPane}</div>
        <div className={styles.mainPane}>{mainPane}</div>
      </div>
    </div>
  );
}

export default TwoPanePage;
