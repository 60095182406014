import {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import RoundedCard from '../../../../../../../../../@components/rounded-card';
import styles from './styles.module.scss';

const ProcessingData: FunctionComponent = () => {
  const {t} = useTranslation('sense', {
    keyPrefix:
      'meetings.show.index.components.meeting-details.components.processing-data',
  });

  return (
    <RoundedCard>
      <div className={styles.notReadyContainer}>
        <p className={styles.primaryMessage}>{t('primary-message')}</p>

        <p>{t('secondary-message')}</p>
      </div>
    </RoundedCard>
  );
};

export default ProcessingData;
