import {createQuery} from 'react-query-kit';
import {
  FetchActionStepsPayload,
  fetchActionSteps,
} from '../../../repositories/instill/queries/fetch-action-steps';
import {Meta} from '../../../interfaces/meta';
import getOffsetValue from '../../../utils/get-offset-value';
import {ActionStep} from '../../../interfaces/action-step';

interface FetchActionSteps {
  data: ActionStep[] | undefined;
  meta: Meta;
}

export const useActionSteps = createQuery<
  FetchActionSteps | undefined,
  FetchActionStepsPayload
>({
  primaryKey: 'action-steps',
  queryFn: async ({queryKey: [_, variables]}) => {
    let payload = {
      offset: getOffsetValue({
        offset: variables.offset,
        currentPage: variables.currentPage ?? 0,
        itemsPerPage: variables.itemsPerPage ?? 0,
      }),
      limit: variables.limit,
      companyUuid: variables.companyUuid,
    } as FetchActionStepsPayload;

    if (variables.sortBy) {
      payload['sortBy'] = variables.sortBy;
    }

    if (variables.orderBy) {
      payload['orderBy'] = variables.orderBy;
    }

    if (variables.query) {
      payload['query'] = variables.query;
    }

    const response = await fetchActionSteps(payload);
    if (!response) return;

    return {
      data: response.data,
      meta: response.meta,
    };
  },
});
