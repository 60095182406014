import {
  UserAssessmentRaw,
  UserAssessment,
} from '../../interfaces/user-assessment';
import {sanitizerAssessmentEventFromRaw} from './assessment-event';
import {sanitizeAssessmentResponseFromRaw} from './assessment-response';

export const sanitizeUserAssessmentFromRaw = (
  data: UserAssessmentRaw[]
): UserAssessment[] => {
  return data.map((userAssessment) => {
    return sanitizeSingleUserAssessmentFromRaw(userAssessment);
  });
};

export const sanitizeSingleUserAssessmentFromRaw = (
  data: UserAssessmentRaw
): UserAssessment => {
  let sanitizeUserAssessment = {
    uuid: data.uuid,
    status: data.status,
    assessmentEvent: sanitizerAssessmentEventFromRaw(data.assessment_event),
    completedModulesUuid: data.completed_module_uuids,
  } as UserAssessment;

  const sanitizedUserResponses = data.user_responses
    ? sanitizeAssessmentResponseFromRaw(data.user_responses)
    : [];

  if (sanitizedUserResponses.length > 0) {
    sanitizeUserAssessment = {
      ...sanitizeUserAssessment,
      userResponses: sanitizedUserResponses,
    };
  }

  return sanitizeUserAssessment;
};
