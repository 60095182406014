import {CompanyUpsert} from '../../../../interfaces/admin/company';
import {sanitizeCompanyCreateForRaw} from '../../../../ui/@sanitizers/admin/company';
import InstillAPI from '../../axios-instance';
import isResponseSuccessful from '../../../../utils/is-response-successful/is-response-successful';

export interface CreateCompanyPayload {
  companyPayload: CompanyUpsert;
}

export async function createCompany(companyPayload: CompanyUpsert) {
  const sanitizedPayload = sanitizeCompanyCreateForRaw(companyPayload);

  const response = await InstillAPI.post('/admin/companies', sanitizedPayload);

  if (!isResponseSuccessful(response)) {
    throw Error('No data received');
  }

  return response.data;
}
