import {ORDER_BY_PARAMETERS} from '../../../constants/filtering/order-by';
import {sanitizeMetaFromRaw} from '../../../ui/@sanitizers/meta';
import {sanitizeActionStepsFromRaw} from '../../../ui/@sanitizers/action-step';
import InstillAPI from '../axios-instance';
import isResponseSuccessful from '../../../utils/is-response-successful';

export interface FetchActionStepsPayload {
  companyUuid: string;
  currentPage?: number;
  itemsPerPage?: number;
  limit?: number;
  offset?: number;
  orderBy?: ORDER_BY_PARAMETERS;
  query?: string;
  sortBy?: string;
}

export async function fetchActionSteps(params: FetchActionStepsPayload) {
  const {companyUuid} = params;

  let url = `companies/${companyUuid}/action-steps`;
  let queryParams: string[] = [];

  if (params.offset) {
    queryParams.push(`skip=${params.offset}`);
  }

  if (params.limit) {
    queryParams.push(`limit=${params.limit}`);
  }

  if (params.orderBy) {
    queryParams.push(`order=${params.orderBy}`);
  }

  if (params.sortBy) {
    queryParams.push(`sort_by=${params.sortBy}`);
  }

  if (params.query) {
    queryParams.push(`q=${params.query}`);
  }

  if (queryParams) {
    url = `${url}?${queryParams.join('&')}`;
  }

  try {
    const response = await InstillAPI.get(url);

    if (!response.data || !isResponseSuccessful(response)) {
      throw Error('No data received');
    }

    return {
      data: sanitizeActionStepsFromRaw(response.data.data),
      meta: sanitizeMetaFromRaw(response.data.meta),
    };
  } catch (error) {
    console.error(error);
  }
}
