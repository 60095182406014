import {FunctionComponent} from 'react';
import {ReactComponent as IconCheck} from '../../../../icons/check.svg';
import Input from '../../../../form/input';
import styles from './styles.module.scss';

interface RadioOptionProps {
  value: string;
  name: string;
  isSelected: boolean;
  className?: string;
  onChange: (value: string) => void;
}

const RadioOption: FunctionComponent<RadioOptionProps> = ({
  value,
  name,
  isSelected,
  className,
  onChange,
}) => {
  const uuid = crypto.randomUUID();

  const optionClassName = className
    ? `${className} ${styles.option}`
    : styles.option;

  return (
    <div className={optionClassName}>
      <Input
        type="radio"
        id={uuid}
        className={styles.radio}
        value={value}
        name={name}
        defaultChecked={isSelected}
        onChange={() => onChange(value)}
      />

      <label htmlFor={uuid} className={styles.radioLabel}>
        <IconCheck className={styles.icon} />
      </label>
    </div>
  );
};

export default RadioOption;
