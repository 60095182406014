import {buildPageTitle} from '../../../../services/build-page-title';
import {CodifyYourValuesSaveContinueEvent} from '../../../../interfaces/analytics/event-properties';
import {CoreValueForm} from '../../../../interfaces/company-core-value';
import {EventName} from '../../../../constants/analytics/event-name';
import {EventType} from '../../../../constants/analytics/event-type';
import {FieldArray, Formik, Form} from 'formik';
import {FunctionComponent, useState, useCallback} from 'react';
import {Helmet} from 'react-helmet-async';
import {ORGANIZATION_CHECKLIST_STEP_KEYS} from '../../@components/setup-checklist/setup-checklist';
import {ReactComponent as IconCodifyYourValues} from './assets/pen-tool.svg';
import {sanitizeCompanyCoreValuesToRaw} from '../../../@sanitizers/company-core-values';
import {useCoreValues} from '../../../@hooks/queries';
import {useCreateCompanyCoreValues} from '../../../@hooks/mutations';
import {useMemo} from 'react';
import {useNavigate} from 'react-router';
import {useSafeCurrentCompany} from '../../../@atoms/current-company';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import Button from '../../../@components/kit/form/button/button';
import DeleteValueConfirmationDialog from './@components/value-form-item/@components/delete-value-confirmation-dialog';
import FormError from '../../../@components/kit/form/form-error';
import FormFooter from '../../../@components/kit/form/form-footer';
import styles from './styles.module.scss';
import useAnalytics from '../../../@hooks/use-analytics';
import ValueFormItem from './@components/value-form-item';

interface ElementProps {
  stepIdentifier: ORGANIZATION_CHECKLIST_STEP_KEYS;
  updateChecklist: (stepIdentifier: ORGANIZATION_CHECKLIST_STEP_KEYS) => void;
}

export interface CoreValueFormSchema {
  values: CoreValueForm[];
}

const NEXT_STEP_ROUTE = '/application/organization-setup/invite-your-team/';

const CodifyYourValues: FunctionComponent<ElementProps> = ({
  updateChecklist,
  stepIdentifier,
}) => {
  const currentCompany = useSafeCurrentCompany();
  const {t} = useTranslation([
    'organizationSetup',
    'common',
    'applicationPageTitle',
  ]);
  const navigate = useNavigate();
  const {trackEvent} = useAnalytics();

  const [activeFormIndex, setActiveFormIndex] = useState(0);

  const [selectedValueForDeletion, setSelectedValueForDeletion] =
    useState<CoreValueForm | null>(null);

  const [selectedItemIndexForDeletion, setSelectedItemIndexForDeletion] =
    useState<number>(0);

  const [
    isDeleteValueConfirmationDialogVisible,
    setIsDeleteValueConfirmationDialogVisible,
  ] = useState(false);

  const createCompanyCoreValues = useCreateCompanyCoreValues();

  const validationSchema = Yup.object().shape({
    values: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string()
            .trim()
            .required(t('codify-your-values.form.name.error')),
          description: Yup.string()
            .trim()
            .required(t('codify-your-values.form.description.error')),
        })
      )
      .min(1),
  });

  const coreValues = useCoreValues();

  const EMPTY_VALUE = useMemo(() => {
    return {
      name: '',
      description: '',
      expectations: {
        exceeded: [],
        met: [],
        missed: [],
      },
      media: [],
    };
  }, []);

  const INITIAL_VALUES = {
    values: [EMPTY_VALUE],
  };

  const goToNextStep = useCallback(() => {
    updateChecklist(stepIdentifier);
    navigate(NEXT_STEP_ROUTE);
  }, [navigate, stepIdentifier, updateChecklist]);

  const onSkipButtonClick = useCallback(() => {
    trackEvent({
      eventName: EventName.ACCOUNT_SET_UP.CODIFY_YOUR_VALUES.SKIP_FOR_NOW,
      eventType: EventType.BUTTON_CLICKED,
    });

    goToNextStep();
  }, [trackEvent, goToNextStep]);

  const onAddAnotherValueButtonClick = useCallback(
    (push: any, values: {values: CoreValueForm[]}) => {
      trackEvent({
        eventName:
          EventName.ACCOUNT_SET_UP.CODIFY_YOUR_VALUES.ADD_ANOTHER_VALUE,
        eventType: EventType.BUTTON_CLICKED,
      });

      push(EMPTY_VALUE);
      setActiveFormIndex(values.values.length);
    },
    [trackEvent, EMPTY_VALUE]
  );

  const handleSubmit = useCallback(
    async (values: CoreValueForm[]) => {
      const response = await createCompanyCoreValues.mutateAsync({
        companyCoreValuePayload: sanitizeCompanyCoreValuesToRaw(values),
        companyUuid: currentCompany.uuid,
      });

      if (!response) return;

      trackEvent({
        eventName: EventName.ACCOUNT_SET_UP.CODIFY_YOUR_VALUES.SAVE_CONTINUE,
        eventType: EventType.BUTTON_CLICKED,
        eventProperties: {values} as CodifyYourValuesSaveContinueEvent,
      });

      goToNextStep();
    },
    [createCompanyCoreValues, currentCompany, goToNextStep, trackEvent]
  );

  const showDeleteValueConfirmationDialog = useCallback(
    (value: CoreValueForm, itemIndex: number) => {
      setSelectedValueForDeletion(value);
      setSelectedItemIndexForDeletion(itemIndex);
      setIsDeleteValueConfirmationDialogVisible(true);
    },
    []
  );

  const hideDeleteValueConfirmationDialog = useCallback(() => {
    setIsDeleteValueConfirmationDialogVisible(false);

    setSelectedValueForDeletion(null);
    setSelectedItemIndexForDeletion(0);
  }, []);

  const onConfirm = useCallback(
    (removeFunction: <T>(index: number) => T | undefined) => {
      hideDeleteValueConfirmationDialog();
      removeFunction(selectedItemIndexForDeletion);
    },
    [hideDeleteValueConfirmationDialog, selectedItemIndexForDeletion]
  );

  return (
    <>
      <Helmet>
        <title>
          {buildPageTitle([
            t('page-title.organization-setup.codify-your-values', {
              ns: 'applicationPageTitle',
            }),
            t('page-title.organization-setup.index', {
              ns: 'applicationPageTitle',
            }),
          ])}
        </title>
      </Helmet>

      <div className={styles.header}>
        <div className={styles.title}>
          {t('codify-your-values.form.header.title')}

          <IconCodifyYourValues className={styles.titleIcon} />
        </div>
      </div>

      <div className={styles.description}>
        {t('codify-your-values.form.header.description')}
      </div>

      <Formik
        initialValues={INITIAL_VALUES}
        onSubmit={async (values) => handleSubmit(values.values)}
        validationSchema={validationSchema}
        validateOnMount
      >
        {({values, isValid, setFieldValue}) => (
          <Form>
            {!values.values.length && (
              <FormError>{t('codify-your-values.form.error')}</FormError>
            )}

            <FieldArray name="values">
              {({push, remove}) => (
                <>
                  {values.values.map((value, index) => (
                    <ValueFormItem
                      key={index}
                      activeFormIndex={activeFormIndex}
                      value={value}
                      itemIndex={index}
                      coreValueOptions={coreValues.data}
                      setActiveFormIndex={setActiveFormIndex}
                      setFieldValue={setFieldValue}
                      removeValue={(value, index) =>
                        showDeleteValueConfirmationDialog(value, index)
                      }
                    />
                  ))}

                  <FormFooter>
                    <Button
                      variant="secondary"
                      onClick={() => {
                        onAddAnotherValueButtonClick(push, values);
                      }}
                    >
                      {t('codify-your-values.form.add-another-button')}
                    </Button>

                    <Button type="submit" disabled={!isValid}>
                      {t('codify-your-values.form.submit-button')}
                    </Button>

                    <button
                      type="button"
                      onClick={onSkipButtonClick}
                      className={styles.skipButton}
                    >
                      {t('codify-your-values.form.skip-button')}
                    </button>
                  </FormFooter>

                  <DeleteValueConfirmationDialog
                    isVisible={isDeleteValueConfirmationDialogVisible}
                    onClose={hideDeleteValueConfirmationDialog}
                    onConfirm={() => onConfirm(remove)}
                    value={selectedValueForDeletion}
                  />
                </>
              )}
            </FieldArray>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CodifyYourValues;
