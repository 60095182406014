import {ActionMeta} from 'react-select';
import {CompanyEmployee} from '../../../../../../../../interfaces/company-employee';
import {Field, FieldProps, Form, Formik} from 'formik';
import {FunctionComponent, useCallback, useMemo, useState} from 'react';
import {Option} from '../../../../../../../../interfaces/dropdown-options';
import {ReactComponent as IconSave} from '../../../../../../../@components/kit/icons/save.svg';
import {Team, TeamUpsert} from '../../../../../../../../interfaces/team';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import AutoresizeTextarea from '../../../../../../../@components/kit/form/autoresize-textarea';
import Button from '../../../../../../../@components/kit/form/button';
import FormControl from '../../../../../../../@components/kit/form/form-control';
import FormFooter from '../../../../../../../@components/kit/form/form-footer';
import FormGroup from '../../../../../../../@components/kit/form/form-group';
import Input from '../../../../../../../@components/kit/form/input';
import Label from '../../../../../../../@components/kit/form/label';
import MutableSelect from '../../../../../../../@components/kit/form/hybrid-select/mutable-select';
import Spinner from '../../../../../../../@components/spinner/spinner';
import styles from './styles.module.scss';

const REMOVE_VALUE_ACTION = 'remove-value';

interface ElementProps {
  companyEmployees: CompanyEmployee[];
  onClose?: () => void;
  onSubmit: (values: TeamUpsert) => void;
  team?: Team;
}

const UpsertTeamForm: FunctionComponent<ElementProps> = ({
  companyEmployees,
  onClose,
  onSubmit,
  team,
}) => {
  const {t} = useTranslation('people');

  const initialValues = {
    name: team?.name ?? '',
    description: team?.description ?? '',
    colorHex: team?.colorHex ?? '',
    managerUuid: team?.manager?.uuid ?? '',
    membersUuids: team?.members.map((member) => member.uuid) ?? [],
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required(t('form.error.required', {ns: 'common'}))
      .max(255),
    description: Yup.string().required(
      t('form.error.required', {ns: 'common'})
    ),
    colorHex: Yup.string().required(t('form.error.required', {ns: 'common'})),
    managerUuid: Yup.string().required(
      t('form.error.required', {ns: 'common'})
    ),
    membersUuids: Yup.array()
      .of(Yup.string())
      .min(
        1,
        t(
          'teams.components.team-list.components.create-team-form.components.team-form.error.employees'
        )
      )
      .required(t('form.error.required', {ns: 'common'})),
  });

  const getFilteredCompanyEmployees = useCallback(
    (data: CompanyEmployee[] | undefined) => {
      let employees = [] as any[];

      const manager = team?.manager;
      const members = team?.members;

      // If the team has a manager (UUID), we have to get the full profile from the list of employees
      if (manager) {
        const managerFromData = data?.find(
          (employee) => employee.userProfileUuid === manager.uuid
        );

        if (!managerFromData) return;

        employees.push(managerFromData);
      }

      // If the team has members, we have to get the full profile of each one of them
      if (members) {
        const membersFromData = data?.filter((member) => {
          return team.members?.find(
            (employee) => employee.uuid === member.userProfileUuid
          );
        });

        if (!membersFromData) return;

        employees.push(...membersFromData);
      }

      const unassignedEmployees = data?.filter((employee) => !employee.team);
      if (!unassignedEmployees) return employees;

      const availableEmployees = [...employees, ...unassignedEmployees];

      // We filter to make sure we remove duplicates
      const filteredEmployees = availableEmployees.filter(
        (item, index) => availableEmployees.indexOf(item) === index
      );

      return filteredEmployees;
    },
    [team]
  );

  const [availableEmployees, setAvailableEmployees] = useState<
    CompanyEmployee[] | undefined
  >(() => {
    if (!companyEmployees) return;
    return getFilteredCompanyEmployees(companyEmployees);
  });

  const submitButtonLabel = useMemo(() => {
    const translationKey = Boolean(team)
      ? 'teams.components.team-list.components.create-team-form.components.team-form.update-team-button'
      : 'teams.components.team-list.components.create-team-form.components.team-form.create-team-button';

    return t(translationKey);
  }, [team, t]);

  const employeeOptions = useMemo(() => {
    return (
      availableEmployees?.map((employee: CompanyEmployee) => {
        return {
          label: employee.name,
          value: employee.userProfileUuid,
        };
      }) || []
    );
  }, [availableEmployees]);

  const selectedManagerOption = useMemo(() => {
    return employeeOptions.find(
      (option) => option.value === team?.manager?.uuid
    );
  }, [team, employeeOptions]);

  const selectedMembersOptions = useMemo(() => {
    return team?.members.map((member) => {
      return employeeOptions.find((option) => option.value === member.uuid);
    }) as Option[];
  }, [team, employeeOptions]);

  const onSelectEmployee = useCallback(
    (
      option: Option | Option[],
      actionMeta: ActionMeta<Option>,
      form: FieldProps['form']
    ) => {
      let updateAvailableEmployees;

      // Handles removal of members from the (Multiselect) selected Members field
      if (actionMeta.action === REMOVE_VALUE_ACTION) {
        const removedEmployee = companyEmployees.find(
          (employee) =>
            employee.userProfileUuid === actionMeta.removedValue.value
        );

        removedEmployee &&
          setAvailableEmployees((previousValue) => {
            if (!previousValue) return;
            return [...previousValue, removedEmployee];
          });

        return;
      }

      if (!option) return;

      // Addition of members from Multiselect dropdown
      if (Array.isArray(option)) {
        const optionIds = option.map((singleOption) => singleOption.value);

        updateAvailableEmployees = availableEmployees?.filter((employee) => {
          return !optionIds.includes(employee.userProfileUuid);
        });
      }
      // Addition and removal of manager
      else {
        const value = option.value;
        let selectedEmployee;

        if (option.value !== form.values.managerUuid) {
          selectedEmployee = companyEmployees.find(
            (employee) => employee.userProfileUuid === form.values.managerUuid
          );
        }

        updateAvailableEmployees =
          availableEmployees?.filter(
            (employee: CompanyEmployee) => employee.userProfileUuid !== value
          ) || [];

        if (selectedEmployee) {
          updateAvailableEmployees.push(selectedEmployee);
        }
      }

      setAvailableEmployees(updateAvailableEmployees ?? []);
    },
    [companyEmployees, availableEmployees]
  );

  if (!availableEmployees) return <Spinner />;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={onSubmit}
    >
      {({errors, touched, isValid}) => (
        <Form>
          <Field name="name">
            {({field}: FieldProps<string>) => (
              <FormGroup>
                <FormControl
                  error={
                    Boolean(touched.name && errors.name)
                      ? errors.name
                      : undefined
                  }
                >
                  <Label isRequired htmlFor={field.name}>
                    {t(
                      'teams.components.team-list.components.create-team-form.components.team-form.label.name'
                    )}
                  </Label>

                  <Input
                    type="text"
                    placeholder={t(
                      'teams.components.team-list.components.create-team-form.components.team-form.place-holder.name'
                    )}
                    {...field}
                  />
                </FormControl>
              </FormGroup>
            )}
          </Field>

          <Field name="colorHex">
            {({field}: FieldProps<string>) => (
              <FormGroup>
                <FormControl
                  error={
                    Boolean(touched.colorHex && errors.colorHex)
                      ? errors.colorHex
                      : undefined
                  }
                >
                  <Label isRequired htmlFor={field.name}>
                    {t(
                      'teams.components.team-list.components.create-team-form.components.team-form.label.color'
                    )}
                  </Label>

                  <Input
                    className={styles.colorSelector}
                    type="color"
                    {...field}
                  />
                </FormControl>
              </FormGroup>
            )}
          </Field>

          <Field name="description">
            {({field}: FieldProps<string>) => (
              <FormGroup>
                <FormControl
                  error={
                    Boolean(touched.description && errors.description)
                      ? errors.description
                      : undefined
                  }
                >
                  <Label isRequired htmlFor={field.name}>
                    {t(
                      'teams.components.team-list.components.create-team-form.components.team-form.label.description'
                    )}
                  </Label>

                  <AutoresizeTextarea
                    {...field}
                    value={field.value}
                    placeholder={t(
                      'teams.components.team-list.components.create-team-form.components.team-form.place-holder.description'
                    )}
                  />
                </FormControl>
              </FormGroup>
            )}
          </Field>

          <Field name="managerUuid">
            {({field, form, meta}: FieldProps<string>) => (
              <FormGroup>
                <FormControl
                  error={
                    Boolean(touched.managerUuid && errors.managerUuid)
                      ? errors.managerUuid
                      : undefined
                  }
                >
                  <Label isRequired htmlFor={field.name}>
                    {t(
                      'teams.components.team-list.components.create-team-form.components.team-form.label.manager'
                    )}
                  </Label>

                  {employeeOptions && (
                    <MutableSelect
                      field={field}
                      form={form}
                      meta={meta}
                      onChangeCallback={(value, actionMeta) => {
                        onSelectEmployee(value, actionMeta, form);
                      }}
                      defaultValue={selectedManagerOption}
                      options={employeeOptions}
                      placeholder={t(
                        'teams.components.team-list.components.create-team-form.components.team-form.place-holder.manager'
                      )}
                    />
                  )}
                </FormControl>
              </FormGroup>
            )}
          </Field>

          <Field name="membersUuids">
            {({field, form, meta}: FieldProps<string[]>) => (
              <FormGroup>
                <FormControl
                  error={
                    Boolean(touched.membersUuids && errors.membersUuids)
                      ? (errors.membersUuids as string)
                      : undefined
                  }
                >
                  <Label isRequired htmlFor={field.name}>
                    {t(
                      'teams.components.team-list.components.create-team-form.components.team-form.label.employees'
                    )}
                  </Label>

                  {employeeOptions && (
                    <MutableSelect
                      field={field}
                      form={form}
                      meta={meta}
                      onMultiChangeCallback={(value, actionMeta) => {
                        onSelectEmployee(value, actionMeta, form);
                      }}
                      defaultValue={selectedMembersOptions}
                      options={employeeOptions}
                      placeholder={t(
                        'teams.components.team-list.components.create-team-form.components.team-form.place-holder.employees'
                      )}
                      isMulti={true}
                    />
                  )}
                </FormControl>
              </FormGroup>
            )}
          </Field>

          <FormFooter>
            <Button type="submit" disabled={!isValid} aria-disabled={!isValid}>
              <div className={styles.buttonContainer}>
                {submitButtonLabel}

                <IconSave className={styles.saveIcon} />
              </div>
            </Button>

            {Boolean(team) && (
              <Button type="button" variant="secondary" onClick={onClose}>
                {t(
                  'teams.components.team-list.components.create-team-form.components.team-form.cancel-button'
                )}
              </Button>
            )}
          </FormFooter>
        </Form>
      )}
    </Formik>
  );
};

export default UpsertTeamForm;
