import {createMutation} from 'react-query-kit';
import {createTeam} from '../../../repositories/instill';
import {CreateTeamPayload} from '../../../repositories/instill/mutations/create-team';
import {Team} from '../../../interfaces/team';

export const useCreateTeam = createMutation<
  Team | undefined,
  CreateTeamPayload
>({
  mutationFn: async (variables) => {
    return await createTeam({
      companyUuid: variables.companyUuid,
      payload: variables.payload,
    });
  },
});
