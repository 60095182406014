export enum ORDER_BY_PARAMETERS {
  ASC = 'asc',
  DESC = 'desc',
}

export const DEFAULT_ORDER_BY_PROPERTY = ORDER_BY_PARAMETERS.ASC;

export const SUPPORTED_ORDER_BY_PARAMETERS = [
  ORDER_BY_PARAMETERS.ASC,
  ORDER_BY_PARAMETERS.DESC,
];
