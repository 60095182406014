import {FunctionComponent} from 'react';
import {Route, Routes} from 'react-router';
import LinkSlackUser from './@components/user';
import LinkSlackWorkspace from './@components/workspace';

export const REDIRECT_URL_FOR_USER = '/link-slack/user';
export const REDIRECT_URL_FOR_WORKSPACE = '/link-slack/workspace';

const LinkSlack: FunctionComponent = () => {
  return (
    <>
      <Routes>
        <Route path="/user" element={<LinkSlackUser />} />
        <Route path="/workspace" element={<LinkSlackWorkspace />} />
      </Routes>
    </>
  );
};

export default LinkSlack;
