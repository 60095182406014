import {CompanyAssessmentUpsertPayload} from '../../../interfaces/company-assessments';
import {sanitizeCompanyAssessmentPayloadToRaw} from '../../../ui/@sanitizers/company-assessments';
import InstillAPI from '../axios-instance';
import isResponseSuccessful from '../../../utils/is-response-successful';

export interface UpdateCompanyAssessmentPayload {
  companyUuid: string;
  assessmentUuid: string;
  payload: CompanyAssessmentUpsertPayload;
}

export async function updateCompanyAssessment(
  companyUuid: string,
  assessmentUuid: string,
  payload: CompanyAssessmentUpsertPayload
) {
  const sanitizedPayload = sanitizeCompanyAssessmentPayloadToRaw(payload);

  const response = await InstillAPI.post(
    `/companies/${companyUuid}/assessments/${assessmentUuid}`,
    sanitizedPayload
  );

  if (!isResponseSuccessful(response)) {
    throw Error('No data received');
  }

  return response.data;
}
