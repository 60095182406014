import {FunctionComponent} from 'react';
import {Team} from '../../../../../../../../../../interfaces/team';
import {useTranslation} from 'react-i18next';
import styles from './styles.module.scss';
import Table from '../../../../../../../../../@components/table';
import tableStyles from '../../../../../../../../../../styles/classes/table.module.scss';

interface ElementProps {
  team: Team;
  onShowDetailsDialog: (team: Team) => void;
}

const TableRow: FunctionComponent<ElementProps> = ({
  team,
  onShowDetailsDialog,
}) => {
  const {t} = useTranslation('people', {
    keyPrefix: 'teams.components.team-list.components.list-view',
  });

  return (
    <Table.Row className={tableStyles.tableRow}>
      <Table.Data variant="nameColumn" className={tableStyles.tableData}>
        <div className={tableStyles.mobileHeaderTitle}>{t('header.name')}</div>

        <div
          className={styles.teamColor}
          style={{backgroundColor: team.colorHex}}
        />

        {team.name}
      </Table.Data>

      <Table.Data variant="large" className={tableStyles.tableData}>
        <div className={tableStyles.mobileHeaderTitle}>
          {t('header.description')}
        </div>

        {team.description}
      </Table.Data>

      <Table.Data variant="large" className={tableStyles.tableData}>
        <div className={tableStyles.mobileHeaderTitle}>
          {t('header.manager')}
        </div>
        {team.manager?.name ?? '—'}
      </Table.Data>

      <Table.Data variant="small" className={tableStyles.tableData}>
        <div className={tableStyles.mobileHeaderTitle}>
          {t('header.team-size')}
        </div>

        {team.teamSize}
      </Table.Data>

      <Table.Data variant="xsmall" className={tableStyles.tableData}>
        <button
          onClick={() => onShowDetailsDialog(team)}
          type="button"
          className={styles.actionButton}
        >
          <div className={styles.buttonContainer}>{t('details-button')}</div>
        </button>
      </Table.Data>
    </Table.Row>
  );
};

export default TableRow;
