import {Assessment, AssessmentRaw} from '../../interfaces/assessment';
import {sanitizeModuleFromRaw} from './module';

export const sanitizeAssessmentsFromRaw = (
  data: AssessmentRaw[]
): Assessment[] => {
  return data.map((assessment) => sanitizeAssessmentFromRaw(assessment));
};

export const sanitizeAssessmentFromRaw = (data: AssessmentRaw): Assessment => {
  const {uuid, name, rank, slug, overview} = data;

  return {
    uuid,
    name,
    rank,
    slug,
    overview,
    modules: sanitizeModuleFromRaw(data.modules),
  };
};
