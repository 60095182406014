import {FunctionComponent, useEffect} from 'react';
import {Helmet} from 'react-helmet-async';
import {useTranslation} from 'react-i18next';
import {EventName} from '../../../../constants/analytics/event-name';
import {EventType} from '../../../../constants/analytics/event-type';
import {buildPageTitle} from '../../../../services/build-page-title';
import mainContainerStyles from '../../../../styles/classes/main-container.module.scss';
import RoundedCard from '../../../@components/rounded-card';
import useAnalytics from '../../../@hooks/use-analytics';
import InstillSenseSettings from './@components/instill-sense-settings-form/instill-sense-settings-form';
import NotificationSettingsForm from './@components/notification-settings-form';

const Configurations: FunctionComponent = () => {
  const {t} = useTranslation('applicationPageTitle');

  const {trackEvent} = useAnalytics();

  useEffect(() => {
    trackEvent({
      eventName: EventName.PAGE_VIEWS.SETTINGS.CONFIGURATIONS,
      eventType: EventType.PAGE_VIEW,
    });
  }, [trackEvent]);

  return (
    <>
      <Helmet>
        <title>{buildPageTitle(t('page-title.settings.configurations'))}</title>
      </Helmet>

      <main className={mainContainerStyles.container}>
        <RoundedCard variant="half">
          <NotificationSettingsForm />
        </RoundedCard>

        <RoundedCard variant="half">
          <InstillSenseSettings />
        </RoundedCard>
      </main>
    </>
  );
};

export default Configurations;
