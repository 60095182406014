import {ReactComponent as ArrowIcon} from '../../../../../@components/kit/icons/arrow.svg';
import {ReactComponent as ArrowPaleIcon} from '../../../../../@components/kit/icons/arrow-pale.svg';
import {ReactComponent as CheckIcon} from '../../../../../@components/kit/icons/check-circle.svg';
import {ReactComponent as CursorIcon} from '../../../../../@components/kit/icons/cursor.svg';
import {ReactComponent as LetterIcon} from '../../../../../@components/kit/icons/letter.svg';
import {sendVerificationEmail} from '../../../../../../repositories/instill';
import {useAuth0} from '@auth0/auth0-react';
import {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Button from '../../../../../@components/kit/form/button';
import styles from './styles.module.scss';

interface VerifyYourEmailProps {
  maskedEmail: string;
}

function VerifyYourEmail({maskedEmail}: VerifyYourEmailProps) {
  const {t} = useTranslation('authFeedback');
  const {getIdTokenClaims} = useAuth0();
  const [isEmailSent, setIsEmailSent] = useState<boolean>(false);

  const onSendEmail = useCallback(async () => {
    const idToken = await getIdTokenClaims();

    if (!idToken) return;

    try {
      await sendVerificationEmail(idToken.__raw);
      setIsEmailSent(true);
    } catch (error) {}
  }, [getIdTokenClaims]);

  if (isEmailSent) {
    return (
      <>
        <h1 className={styles.title}>
          {t('components.verify-your-account.email-sent.title')}
        </h1>

        <p className={styles.text}>
          {t('components.verify-your-account.email-sent.text', {
            email: maskedEmail,
          })}
        </p>
      </>
    );
  }

  return (
    <>
      <h1 className={styles.title}>
        {t('components.verify-your-account.title')}
      </h1>

      <p className={styles.text}>
        {t('components.verify-your-account.text', {email: maskedEmail})}
      </p>

      <div className={styles.icons}>
        <LetterIcon className={styles.icon} />
        <ArrowPaleIcon className={styles.icon} />
        <CursorIcon className={styles.icon} />
        <ArrowPaleIcon className={styles.icon} />
        <CheckIcon className={styles.icon} />
      </div>

      <div className={styles.sendEmailContainer}>
        <span className={styles.getEmailText}>
          {t('components.verify-your-account.did-not-get-email')}
        </span>

        <Button className={styles.sendEmailLink} onClick={onSendEmail}>
          {t('components.verify-your-account.resend-email')}
        </Button>

        <ArrowIcon className={styles.icon} />
      </div>
    </>
  );
}

export default VerifyYourEmail;
