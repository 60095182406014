import {CompanyAssessment} from '../../../interfaces/company-assessments';
import {createMutation} from 'react-query-kit';
import {updateCompanyAssessmentSettings} from '../../../repositories/instill/mutations';
import {UpdateCompanyAssessmentSettingsPayload} from '../../../repositories/instill/mutations/update-company-assessment-settings';

export const useUpdateCompanyAssessmentSettings = createMutation<
  CompanyAssessment | undefined,
  UpdateCompanyAssessmentSettingsPayload
>({
  mutationFn: async (variables) => {
    return await updateCompanyAssessmentSettings(
      variables.companyUuid,
      variables.assessmentUuid,
      variables.payload
    );
  },
});
