import {AuthUser} from '../../../interfaces/auth-user';
import {
  fetchCompanyCoreValues,
  updateUserChecklist,
} from '../../../repositories/instill';
import {FunctionComponent, useCallback, useState} from 'react';
import {getUserProfileForCompany} from '../../../services/user-profiles';
import {Navigate, Route, Routes, useLocation, useNavigate} from 'react-router';
import {updateUserProfileForCompany} from '../../../services/user-profiles';
import {useCurrentAuthUserState} from '../../@atoms/current-auth-user';
import {useEffectOnce} from '../../@hooks/use-effect-once';
import {useMountedState} from 'react-use';
import {UserChecklist} from '../../../interfaces/checklist';
import {useSafeCurrentCompany} from '../../@atoms/current-company';
import {useSetup} from '../@contexts/setup-context';
import {useTranslation} from 'react-i18next';
import {Company} from '../../../interfaces/company';
import CreateYourProfile from './create-your-profile';
import Header from '../@components/header';
import mainContainerStyles from '../../../styles/classes/main-container.module.scss';
import MainContent from '../@components/main-content';
import MapYourOrganization from './map-your-organization';
import ReviewCompanyValues from './review-company-values';
import RoundedCard from '../../@components/rounded-card';
import SetupChecklist, {
  ChecklistItem,
  USER_CHECKLIST_STEP_KEYS,
} from '../@components/setup-checklist/setup-checklist';
import SignOffOnValues from './sign-off-on-values';
import styles from './styles.module.scss';
import WorkStyle from './work-style';

const CHECKLIST_STEP_ROUTE_MAPPING = {
  [USER_CHECKLIST_STEP_KEYS.CREATED_PROFILE]: 'create-your-profile',
  [USER_CHECKLIST_STEP_KEYS.MAPPED_ORGANIZATION]: 'map-your-organization',
  [USER_CHECKLIST_STEP_KEYS.SET_WORK_STYLE]: 'enter-your-work-style',
  [USER_CHECKLIST_STEP_KEYS.REVIEWED_COMPANY_VALUES]: 'review-company-values',
  [USER_CHECKLIST_STEP_KEYS.SIGNED_OFF_ON_VALUES]: 'sign-off-on-values',
};

const DASHBOARD_ROUTE = '/application/dashboard';

function getLastCompletedStepIndex(items: ChecklistItem[]) {
  return items.map((step) => step.isActive).lastIndexOf(true);
}

const isCurrentUserAuthorized = (
  company: Company | null,
  authUser: AuthUser | null
) => {
  if (!authUser || !company) return false;
  const userProfile = getUserProfileForCompany(authUser, company);

  if (userProfile.isOnboarded) return true;

  return false;
};

const UserSetup: FunctionComponent = () => {
  const currentCompany = useSafeCurrentCompany();
  const [currentAuthUser, setCurrentAuthUser] = useCurrentAuthUserState();
  const {currentStepIdentifier, setCurrentStepIdentifier} = useSetup();

  const isMounted = useMountedState();
  const location = useLocation();
  const navigate = useNavigate();

  const {t} = useTranslation('application');

  const [checklistData, setChecklistData] = useState<ChecklistItem[] | null>(
    null
  );

  const fetchAndSetCurrentStepIdentifier = useCallback(() => {
    const currentRouteMapping = Object.keys(CHECKLIST_STEP_ROUTE_MAPPING).find(
      (key) => {
        const route =
          CHECKLIST_STEP_ROUTE_MAPPING[
            key as keyof typeof CHECKLIST_STEP_ROUTE_MAPPING
          ];
        return location.pathname.includes(route);
      }
    );

    if (!currentRouteMapping) return;

    const checklistStepBasedOnRoute = Object.keys(
      USER_CHECKLIST_STEP_KEYS
    ).find((key) => {
      const route =
        USER_CHECKLIST_STEP_KEYS[key as keyof typeof USER_CHECKLIST_STEP_KEYS];
      return route === currentRouteMapping;
    });

    if (!checklistStepBasedOnRoute) return;

    setCurrentStepIdentifier(
      USER_CHECKLIST_STEP_KEYS[
        checklistStepBasedOnRoute as keyof typeof USER_CHECKLIST_STEP_KEYS
      ]
    );
  }, [setCurrentStepIdentifier, location]);

  const onCompleteValuesSteps = useCallback(async () => {
    const companyUuid = currentCompany!.uuid;
    const response = await fetchCompanyCoreValues(companyUuid);

    if (!response) return false;

    if (response.length === 0) {
      await updateUserChecklist(
        USER_CHECKLIST_STEP_KEYS.REVIEWED_COMPANY_VALUES,
        companyUuid
      );

      await updateUserChecklist(
        USER_CHECKLIST_STEP_KEYS.SIGNED_OFF_ON_VALUES,
        companyUuid
      );

      return true;
    }

    return false;
  }, [currentCompany]);

  const onCompleteSignOffOnValuesStep = useCallback(async () => {
    const companyUuid = currentCompany.uuid;

    const isSignOffEnabled = currentCompany.isSignatureRequiredOnValues;

    if (!isSignOffEnabled) {
      await updateUserChecklist(
        USER_CHECKLIST_STEP_KEYS.SIGNED_OFF_ON_VALUES,
        companyUuid
      );

      return true;
    }

    return false;
  }, [currentCompany]);

  const formatChecklistResponse = useCallback(
    async (data: UserChecklist) => {
      const reviewValuesItem = {
        isActive: data?.reviewedCompanyValues ?? false,
        key: USER_CHECKLIST_STEP_KEYS.REVIEWED_COMPANY_VALUES,
        label: t(
          'components.main-content.setup-checklist.user-setup.steps.review-company-values'
        ),
      };

      const signOffOnValuesItem = {
        isActive: data?.signedOffOnValues ?? false,
        key: USER_CHECKLIST_STEP_KEYS.SIGNED_OFF_ON_VALUES,
        label: t(
          'components.main-content.setup-checklist.user-setup.steps.sign-off-on-values'
        ),
      };

      const checklistItems = [
        {
          isActive: data?.createdProfile ?? false,
          key: USER_CHECKLIST_STEP_KEYS.CREATED_PROFILE,
          label: t(
            'components.main-content.setup-checklist.user-setup.steps.create-your-profile'
          ),
        },
        {
          isActive: data?.mappedOrganization ?? false,
          key: USER_CHECKLIST_STEP_KEYS.MAPPED_ORGANIZATION,
          label: t(
            'components.main-content.setup-checklist.user-setup.steps.map-your-organization'
          ),
        },
        {
          isActive: data?.setWorkStyle ?? false,
          key: USER_CHECKLIST_STEP_KEYS.SET_WORK_STYLE,
          label: t(
            'components.main-content.setup-checklist.user-setup.steps.enter-your-work-style'
          ),
        },
      ];

      const shouldSkipValuesStep = await onCompleteValuesSteps();
      const shouldSkipSignOff = await onCompleteSignOffOnValuesStep();

      if (!shouldSkipValuesStep) {
        checklistItems.push(reviewValuesItem);

        if (!shouldSkipSignOff) {
          checklistItems.push(signOffOnValuesItem);
        }
      }

      const lastCompletedStepIndex = getLastCompletedStepIndex(checklistItems);

      return checklistItems.map((item, index) => {
        const isCompleted = lastCompletedStepIndex + 1 > index;

        const isActive =
          checklistItems.indexOf(item) === lastCompletedStepIndex + 1;

        return {
          ...item,
          isCompleted,
          isActive,
        };
      });
    },
    [t, onCompleteValuesSteps, onCompleteSignOffOnValuesStep]
  );

  const redirectToStep = useCallback(
    (items: ChecklistItem[]) => {
      const currentStepIndex = items.findIndex(
        (item) => item.key === currentStepIdentifier
      );

      const areAllStepsCompleted = items.every((item) => item.isCompleted);
      const lastCompletedStepIndex = getLastCompletedStepIndex(items);

      if (areAllStepsCompleted) {
        navigate(DASHBOARD_ROUTE);
      }

      if (currentStepIndex !== lastCompletedStepIndex) {
        const key = items[lastCompletedStepIndex].key;
        const route =
          CHECKLIST_STEP_ROUTE_MAPPING[
            key as keyof typeof CHECKLIST_STEP_ROUTE_MAPPING
          ];

        if (currentStepIdentifier === key) return;

        navigate(route);
      }
    },
    [currentStepIdentifier, navigate]
  );

  const onFetchChecklist = useCallback(async () => {
    const userProfile = getUserProfileForCompany(
      currentAuthUser!,
      currentCompany
    );

    const formatedResponse = await formatChecklistResponse(
      userProfile.onboardingChecklist
    );

    redirectToStep(formatedResponse);
    setChecklistData(formatedResponse);
  }, [
    formatChecklistResponse,
    redirectToStep,
    currentAuthUser,
    currentCompany,
  ]);

  const onUpdateChecklist = useCallback(
    async (currentStep: USER_CHECKLIST_STEP_KEYS) => {
      const response = await updateUserChecklist(
        currentStep,
        currentCompany.uuid
      );

      if (!response) return;

      const formatedResponse = await formatChecklistResponse(response);

      const areAllStepsCompleted = formatedResponse.every(
        (item) => item.isCompleted
      );

      if (areAllStepsCompleted) {
        if (!currentAuthUser) return;

        // Setting isOnboarded to true will redirect user to the dashboard
        const userProfiles = updateUserProfileForCompany(
          currentAuthUser,
          currentCompany,
          {isOnboarded: true}
        );

        setCurrentAuthUser({...currentAuthUser, userProfiles});
      }

      setChecklistData(formatedResponse);
    },
    [
      formatChecklistResponse,
      currentCompany,
      currentAuthUser,
      setCurrentAuthUser,
    ]
  );

  useEffectOnce(() => {
    if (!isMounted()) return;

    fetchAndSetCurrentStepIdentifier();
    onFetchChecklist();
  });

  if (isCurrentUserAuthorized(currentCompany, currentAuthUser)) {
    return <Navigate replace to={DASHBOARD_ROUTE} />;
  }

  return (
    <>
      <Header
        surtitle={t('components.header.surtitle', {
          companyName: currentCompany!.name,
        })}
        title={t('components.header.title')}
      />

      <MainContent>
        <main className={mainContainerStyles.container}>
          <RoundedCard>
            <Routes>
              <Route
                path="/"
                element={<Navigate replace to="create-your-profile" />}
              />

              <Route
                path="create-your-profile"
                element={
                  <CreateYourProfile
                    stepIdentifier={USER_CHECKLIST_STEP_KEYS.CREATED_PROFILE}
                    updateChecklist={onUpdateChecklist}
                  />
                }
              />

              <Route
                path="map-your-organization"
                element={
                  <MapYourOrganization
                    stepIdentifier={
                      USER_CHECKLIST_STEP_KEYS.MAPPED_ORGANIZATION
                    }
                    updateChecklist={onUpdateChecklist}
                  />
                }
              />

              <Route
                path="enter-your-work-style"
                element={
                  <WorkStyle
                    stepIdentifier={USER_CHECKLIST_STEP_KEYS.SET_WORK_STYLE}
                    updateChecklist={onUpdateChecklist}
                  />
                }
              />

              <Route
                path="review-company-values"
                element={
                  <ReviewCompanyValues
                    stepIdentifier={
                      USER_CHECKLIST_STEP_KEYS.REVIEWED_COMPANY_VALUES
                    }
                    updateChecklist={onUpdateChecklist}
                  />
                }
              />

              <Route
                path="sign-off-on-values"
                element={
                  <SignOffOnValues
                    stepIdentifier={
                      USER_CHECKLIST_STEP_KEYS.SIGNED_OFF_ON_VALUES
                    }
                    updateChecklist={onUpdateChecklist}
                  />
                }
              />

              <Route
                path="*"
                element={<Navigate replace to="/page-not-found" />}
              />
            </Routes>
          </RoundedCard>
        </main>

        <aside className={styles.sidebar}>
          <div className={styles.sidebarRow}>
            {checklistData && <SetupChecklist checklistData={checklistData} />}
          </div>
        </aside>
      </MainContent>
    </>
  );
};

export default UserSetup;
