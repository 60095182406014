import {Candidate} from '../../../interfaces/candidate';
import {createCandidate} from '../../../repositories/instill';
import {CreateCandidatePayload} from '../../../repositories/instill/mutations/create-candidate';
import {createMutation} from 'react-query-kit';

export const useCreateCandidates = createMutation<
  Candidate | undefined,
  CreateCandidatePayload
>({
  mutationFn: async (variables) => {
    return await createCandidate({
      companyUuid: variables.companyUuid,
      jobPositionUuid: variables.jobPositionUuid,
      payload: variables.payload,
    });
  },
});
