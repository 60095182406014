import {useAuth0} from '@auth0/auth0-react';
import {FunctionComponent, useCallback} from 'react';
import {
  COMPANY_ONBOARDING_COMPLETE_STORAGE_KEY,
  REDIRECT_URL_ON_LOGIN_STORAGE_KEY,
  SELECTED_COMPANY_UUID_KEY,
} from '../../constants/local-storage-keys';

const Logout: FunctionComponent = () => {
  const {logout} = useAuth0();

  const onClearStorage = useCallback(() => {
    localStorage.removeItem(SELECTED_COMPANY_UUID_KEY);
    localStorage.removeItem(REDIRECT_URL_ON_LOGIN_STORAGE_KEY);
    localStorage.removeItem(COMPANY_ONBOARDING_COMPLETE_STORAGE_KEY);
  }, []);

  onClearStorage();

  return <>{logout({returnTo: window.location.origin})}</>;
};

export default Logout;
