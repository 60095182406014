import {
  ChangeEvent,
  FormEvent,
  FunctionComponent,
  useCallback,
  useState,
} from 'react';
import {EventName} from '../../../../../../constants/analytics/event-name';
import {EventType} from '../../../../../../constants/analytics/event-type';
import {
  ACTION_STEP_STATUS_SORTABLE_COLUMNS,
  SUPPORTED_SORTABLE_COLUMNS,
} from '../../../../../../constants/filtering/sort-by';
import {
  ORDER_BY_PARAMETERS,
  SUPPORTED_ORDER_BY_PARAMETERS,
} from '../../../../../../constants/filtering/order-by';
import {SearchTriggeredEvent} from '../../../../../../interfaces/analytics/event-properties';
import {SortedColumn} from '../../../../../../interfaces/filtering/sorted-column';
import {StringParam, createEnumParam, useQueryParams} from 'use-query-params';
import {useActionSteps} from '../../../../../@hooks/queries/use-action-steps';
import {useDebounce} from 'react-use';
import {useSafeCurrentCompany} from '../../../../../@atoms/current-company';
import {useTranslation} from 'react-i18next';
import getActiveSortedColumnFromQueryParameters from '../../../../../../utils/get-active-sorted-column-from-query-params/get-active-sorted-column-from-query-params';
import ListView from './@components/list-view';
import NoResultsBox from '../../../../@components/no-results-box';
import Pagination from '../../../../../@components/pagination';
import RoundedCard from '../../../../../@components/rounded-card/rounded-card';
import SearchForm from '../../../../@components/search-form/search-form';
import Spinner from '../../../../../@components/spinner/spinner';
import styles from './styles.module.scss';
import useAnalytics from '../../../../../@hooks/use-analytics';
import usePagination from '../../../../../@hooks/use-pagination';

const MINIMUM_SEARCH_LETTERS = 2;
const DELAY_FOR_SEARCH_DEBOUNCE_IN_MS = 500;
const NUMBER_OF_ITEMS_PER_PAGE = 20;

const ActionStepsList: FunctionComponent = () => {
  const {t} = useTranslation('apply', {
    keyPrefix: 'action-step-status.components.action-steps-list',
  });

  const currentCompany = useSafeCurrentCompany();
  const {trackEvent} = useAnalytics();

  const [queryParameters, setQueryParameters] = useQueryParams({
    orderBy: createEnumParam(SUPPORTED_ORDER_BY_PARAMETERS),
    sortBy: createEnumParam(SUPPORTED_SORTABLE_COLUMNS.ACTION_STEP_STATUS),
    query: StringParam,
  });

  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(
    queryParameters.query
  );

  const [activeSortedColumn, setActiveSortedColumn] =
    useState<SortedColumn | null>(
      getActiveSortedColumnFromQueryParameters({
        orderBy: queryParameters.orderBy,
        sortBy: queryParameters.sortBy,
        supportedColumnsToSort: SUPPORTED_SORTABLE_COLUMNS.ACTION_STEP_STATUS,
      })
    );

  const {
    currentPage,
    goToNextPage,
    goToPreviousPage,
    onResetPagination,
    offset,
    pageInQueryParams,
    setCurrentPage,
    setPageInQueryParams,
  } = usePagination({itemsPerPage: NUMBER_OF_ITEMS_PER_PAGE});

  const actionSteps = useActionSteps({
    variables: {
      companyUuid: currentCompany.uuid,
      currentPage,
      itemsPerPage: NUMBER_OF_ITEMS_PER_PAGE,
      limit: NUMBER_OF_ITEMS_PER_PAGE,
      offset,
      orderBy: queryParameters.orderBy ?? undefined,
      query: queryParameters.query ?? undefined,
      sortBy:
        (queryParameters.sortBy as ACTION_STEP_STATUS_SORTABLE_COLUMNS) ??
        undefined,
    },
  });

  const onColumnClicked = useCallback(
    (column: SortedColumn) => {
      setActiveSortedColumn(column);

      setQueryParameters({
        ...queryParameters,
        sortBy: column.columnName as ACTION_STEP_STATUS_SORTABLE_COLUMNS,
        orderBy: column.order as ORDER_BY_PARAMETERS,
      });

      if (!pageInQueryParams) return;

      setCurrentPage(1);
      setPageInQueryParams(1);
    },
    [
      pageInQueryParams,
      setCurrentPage,
      queryParameters,
      setQueryParameters,
      setPageInQueryParams,
    ]
  );

  const onSearch = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setDebouncedSearchQuery(value);

      const eventProperties = {
        searchQuery: value,
      } as SearchTriggeredEvent;

      trackEvent({
        eventName: EventName.APPLY.ACTION_STEP_STATUS.SEARCH,
        eventType: EventType.SEARCH_TRIGGERED,
        eventProperties,
      });
    },
    [trackEvent]
  );

  const onClearSearch = useCallback(
    (event: FormEvent) => {
      event.preventDefault();

      setQueryParameters({
        ...queryParameters,
        query: undefined,
      });

      trackEvent({
        eventName: EventName.APPLY.ACTION_STEP_STATUS.CLEAR_SEARCH,
        eventType: EventType.BUTTON_CLICKED,
      });
    },
    [queryParameters, setQueryParameters, trackEvent]
  );

  useDebounce(
    () => {
      if (
        debouncedSearchQuery !== '' &&
        (debouncedSearchQuery?.length ?? 0) < MINIMUM_SEARCH_LETTERS
      ) {
        return;
      }

      setQueryParameters({
        ...queryParameters,
        query: debouncedSearchQuery,
      });

      if (!pageInQueryParams) return;
      onResetPagination();
    },
    DELAY_FOR_SEARCH_DEBOUNCE_IN_MS,
    [debouncedSearchQuery]
  );

  return (
    <>
      <RoundedCard.Header className={styles.header}>
        <RoundedCard.Title>{t('title')}</RoundedCard.Title>

        <SearchForm
          searchQueryInQueryParams={queryParameters.query}
          onSearch={onSearch}
          placeholder={t('components.search-form.placeholder')}
          onClearSearch={onClearSearch}
        />
      </RoundedCard.Header>

      <RoundedCard.Content>
        {actionSteps.isLoading ? (
          <Spinner />
        ) : (
          <>
            {actionSteps.data?.data?.length ? (
              <>
                <ListView
                  activeSortedColumn={activeSortedColumn}
                  actionSteps={actionSteps.data?.data}
                  onColumnClicked={onColumnClicked}
                />

                {actionSteps.data?.meta && (
                  <Pagination
                    currentPage={currentPage}
                    itemsPerPage={NUMBER_OF_ITEMS_PER_PAGE}
                    onNextPageLinkClicked={goToNextPage}
                    onPreviousPageLinkClicked={goToPreviousPage}
                    totalItems={actionSteps.data?.meta.totalEntries}
                  />
                )}
              </>
            ) : (
              <NoResultsBox />
            )}
          </>
        )}
      </RoundedCard.Content>
    </>
  );
};

export default ActionStepsList;
