import {createQuery} from 'react-query-kit';
import {UserAssessment} from '../../../interfaces/user-assessment';
import {
  FetchUserAssessmentPayload,
  fetchUserAssessment,
} from '../../../repositories/instill/queries/fetch-user-assessment';

export const useUserAssessment = createQuery<
  UserAssessment | undefined,
  FetchUserAssessmentPayload
>({
  primaryKey: 'userAssessment',
  queryFn: async ({queryKey: [_, variables]}) => {
    return await fetchUserAssessment(variables.userAssessmentUuid);
  },
});
