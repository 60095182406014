import {FunctionComponent, PropsWithChildren} from 'react';
import styles from './styles.module.scss';

interface ElementProps {
  className?: string;
  variant?: 'vertical';
}

const Header: FunctionComponent<PropsWithChildren<ElementProps>> = ({
  variant,
  children,
  ...props
}) => {
  return (
    <header
      className={`${styles.header} ${props.className ?? ''} ${
        variant ? styles[variant] : ''
      }`}
    >
      {children}
    </header>
  );
};

export default Header;
