import {CoreValue, CoreValueRaw} from '../../interfaces/core-value';

export const sanitizeCoreValuesFromRaw = (
  data: CoreValueRaw[]
): CoreValue[] => {
  return data.map((item: CoreValueRaw) => {
    return {
      name: item.name,
      definition1: item.definition1,
      definition2: item.definition2 ?? null,
      definition3: item.definition3 ?? null,
      synonyms: item.synonyms ?? null,
      normsAndBehaviours: item.norms_and_behaviours?.map((normAndBehaviour) => {
        return {
          behaviour: normAndBehaviour.behaviour,
          notes: normAndBehaviour.notes,
        };
      }),
    };
  });
};
