import {CompanyAssessment} from '../../../../../../../../../../../interfaces/company-assessments';
import {Field, FieldProps, Form, Formik} from 'formik';
import {FunctionComponent, useCallback} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import AutoresizeTextarea from '../../../../../../../../../../@components/kit/form/autoresize-textarea';
import Button from '../../../../../../../../../../@components/kit/form/button';
import Dialog from '../../../../../../../../../../@components/kit/dialog';
import FormControl from '../../../../../../../../../../@components/kit/form/form-control';
import FormFooter from '../../../../../../../../../../@components/kit/form/form-footer';
import FormGroup from '../../../../../../../../../../@components/kit/form/form-group';
import Label from '../../../../../../../../../../@components/kit/form/label';
import styles from './styles.module.scss';

interface ElementProps {
  companyAssessment: CompanyAssessment;
  isVisible: boolean;
  onClose: () => void;
  onSubmit: (
    values: DisableAssessmentFormProps,
    companyAssessment: CompanyAssessment
  ) => void;
}

export interface DisableAssessmentFormProps {
  isActive: boolean;
  message: string;
}

const INITIAL_VALUES = {
  message: '',
} as DisableAssessmentFormProps;

const DisableAssessmentDialog: FunctionComponent<ElementProps> = ({
  companyAssessment,
  isVisible,
  onClose,
  onSubmit,
}) => {
  const {t} = useTranslation('measure');

  const validationSchema = Yup.object().shape({
    message: Yup.string().max(255).nullable(),
  });

  const onFormSubmit = useCallback(
    (values: DisableAssessmentFormProps) => {
      onSubmit(values, companyAssessment);
    },
    [onSubmit, companyAssessment]
  );

  return (
    <Dialog isOpen={isVisible} onClose={onClose} variant="small">
      <Dialog.Header>
        <div className={styles.title}>{companyAssessment.assessment.name}</div>

        <div className={styles.subtitle}>
          {t(
            'manage-assessments.components.assessments.components.disable-assessment-dialog.subtitle'
          )}
        </div>
      </Dialog.Header>

      <Dialog.Content>
        <Formik
          initialValues={INITIAL_VALUES}
          validationSchema={validationSchema}
          validateOnMount
          onSubmit={onFormSubmit}
        >
          {({errors, isValid, touched}) => (
            <Form>
              <div className={styles.contentTitle}>
                <Trans
                  t={t}
                  i18nKey="manage-assessments.components.assessments.components.disable-assessment-dialog.content-title"
                  components={{bold: <b />}}
                  values={{
                    assessmentName: companyAssessment.assessment.name,
                  }}
                />
              </div>

              <div className={styles.description}>
                {t(
                  'manage-assessments.components.assessments.components.disable-assessment-dialog.description'
                )}
              </div>

              <Field name="message">
                {({form, field}: FieldProps<string>) => (
                  <FormGroup>
                    <FormControl
                      error={
                        Boolean(errors.message && touched.message)
                          ? errors.message
                          : undefined
                      }
                    >
                      <Label>
                        {t(
                          'manage-assessments.components.assessments.components.disable-assessment-dialog.form.message.label'
                        )}
                      </Label>

                      <AutoresizeTextarea
                        {...field}
                        placeholder={t(
                          'manage-assessments.components.assessments.components.disable-assessment-dialog.form.message.placeholder'
                        )}
                        maxLength={255}
                      />
                    </FormControl>
                  </FormGroup>
                )}
              </Field>

              <FormFooter>
                <Button disabled={!isValid} type="submit">
                  {t(
                    'manage-assessments.components.assessments.components.disable-assessment-dialog.form.submit-button'
                  )}
                </Button>

                <Button variant="secondary" onClick={onClose}>
                  {t(
                    'manage-assessments.components.assessments.components.disable-assessment-dialog.form.cancel-button'
                  )}
                </Button>
              </FormFooter>
            </Form>
          )}
        </Formik>
      </Dialog.Content>
    </Dialog>
  );
};

export default DisableAssessmentDialog;
