import {DEFAULT_NUMBER_OF_ITEMS_PER_PAGE} from '../../../../../../constants/filtering/pagination';
import {DEFAULT_TOASTIFY_CONFIGURATION} from '../../../../../../constants/toastify-configuration';
import {FunctionComponent, useCallback, useState} from 'react';
import {JobPosition} from '../../../../../../interfaces/job-position';
import {ReactComponent as IconPlus} from '../../../../../@components/kit/icons/plus.svg';
import {sortByCreatedAt} from '../../../../../../utils/sort-by/created-at';
import {ToastOptions, toast} from 'react-toastify';
import {
  useCreateJobPosition,
  useUpdateJobPosition,
} from '../../../../../@hooks/mutations';
import {useJobPositions} from '../../../../../@hooks/queries';
import {useSafeCurrentCompany} from '../../../../../@atoms/current-company';
import {useTranslation} from 'react-i18next';
import getOffsetValue from '../../../../../../utils/get-offset-value';
import JobPositionDialog, {
  JobPositionFormValues,
} from './@components/job-position-dialog';
import List from './@components/list';
import NoResultsBox from '../../../../@components/no-results-box';
import Pagination from '../../../../../@components/pagination';
import RoundedCard from '../../../../../@components/rounded-card';
import Spinner from '../../../../../@components/spinner';
import styles from './styles.module.scss';
import usePagination from '../../../../../@hooks/use-pagination';

const JobPositionList: FunctionComponent = () => {
  const {t} = useTranslation('jobs');

  const currentCompany = useSafeCurrentCompany();

  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [selectedJobPosition, setSelectedJobPosition] = useState<JobPosition>();

  const {currentPage, offset, goToNextPage, goToPreviousPage} = usePagination({
    itemsPerPage: DEFAULT_NUMBER_OF_ITEMS_PER_PAGE,
  });

  const jobPositions = useJobPositions({
    variables: {
      companyUuid: currentCompany.uuid,
      limit: DEFAULT_NUMBER_OF_ITEMS_PER_PAGE,
      offset: getOffsetValue({
        offset,
        currentPage,
        itemsPerPage: DEFAULT_NUMBER_OF_ITEMS_PER_PAGE,
      }),
    },
  });

  const createJobPosition = useCreateJobPosition();
  const updateJobPosition = useUpdateJobPosition();

  const onJobPositionDialogClose = useCallback(() => {
    setSelectedJobPosition(undefined);
    setIsDialogVisible(false);
  }, []);

  const onUpsertJobPosition = useCallback(
    async (values: JobPositionFormValues) => {
      let response;
      let feedbackMessage;

      const isUpdatingJobPosition = Boolean(selectedJobPosition);

      if (isUpdatingJobPosition) {
        response = await updateJobPosition.mutateAsync({
          companyUuid: currentCompany.uuid,
          jobPositionUuid: selectedJobPosition!.uuid,
          values,
        });

        feedbackMessage = t(
          'job-positions.components.job-position-list.update-job-position.success-message'
        );
      } else {
        response = await createJobPosition.mutateAsync({
          companyUuid: currentCompany.uuid,
          values,
        });

        feedbackMessage = t(
          'job-positions.components.job-position-list.add-job-position.success-message'
        );
      }

      if (!response) return;

      toast.success(feedbackMessage, {
        ...(DEFAULT_TOASTIFY_CONFIGURATION as ToastOptions),
      });

      jobPositions.refetch();
      onJobPositionDialogClose();
    },
    [
      createJobPosition,
      currentCompany,
      jobPositions,
      onJobPositionDialogClose,
      selectedJobPosition,
      t,
      updateJobPosition,
    ]
  );

  const onAddJobPositionsButtonClicked = useCallback(() => {
    setIsDialogVisible(true);
  }, []);

  const onJobPositionClicked = useCallback((jobPosition: JobPosition) => {
    setSelectedJobPosition(jobPosition);
    setIsDialogVisible(true);
  }, []);

  return (
    <>
      {isDialogVisible && (
        <JobPositionDialog
          isDialogVisible={isDialogVisible}
          jobPosition={selectedJobPosition}
          onClose={onJobPositionDialogClose}
          onSuccess={onUpsertJobPosition}
        />
      )}

      <RoundedCard.Header>
        <RoundedCard.Title>
          {t('job-positions.components.job-position-list.title')}

          <button
            type="button"
            className={styles.button}
            aria-label={t(
              'job-positions.components.job-position-list.add-job-position.button-accessibility-text'
            )}
            onClick={onAddJobPositionsButtonClicked}
          >
            <IconPlus className={styles.buttonIcon} />
          </button>
        </RoundedCard.Title>
      </RoundedCard.Header>

      <RoundedCard.Content>
        {jobPositions.isLoading ? (
          <Spinner />
        ) : (
          <>
            {jobPositions?.data?.data.length ? (
              <>
                <List
                  jobPositions={sortByCreatedAt(jobPositions.data.data)}
                  onJobPositionClicked={onJobPositionClicked}
                />

                {jobPositions.data.meta.totalEntries && (
                  <Pagination
                    currentPage={currentPage}
                    itemsPerPage={DEFAULT_NUMBER_OF_ITEMS_PER_PAGE}
                    onNextPageLinkClicked={goToNextPage}
                    onPreviousPageLinkClicked={goToPreviousPage}
                    totalItems={jobPositions.data.meta.totalEntries}
                  />
                )}
              </>
            ) : (
              <NoResultsBox
                message={t(
                  'job-positions.components.job-position-list.no-result-message'
                )}
              />
            )}
          </>
        )}
      </RoundedCard.Content>
    </>
  );
};

export default JobPositionList;
