import {ChangeEvent, FunctionComponent} from 'react';
import {useFormControlContext} from '../form-control';
import Item from './@components/item';
import styles from './styles.module.scss';

export interface RadioGroupOption {
  label: string;
  value: string;
}

interface ElementProps {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  options: RadioGroupOption[];
  selectedValue: string;
}

const RadioGroup: FunctionComponent<ElementProps> = ({
  onChange,
  options,
  selectedValue,
}) => {
  let classes = styles.list;
  const {hasError} = useFormControlContext();

  if (hasError) {
    classes = `${classes} ${styles.error}`;
  }

  return (
    <ul className={classes}>
      {options.map((option, index) => (
        <li className={styles.item} key={index}>
          <Item
            hasError={hasError}
            onChange={onChange}
            option={option}
            selectedValue={selectedValue}
          />
        </li>
      ))}
    </ul>
  );
};

export default RadioGroup;
