import {AxiosResponse} from 'axios';
import {createMutation} from 'react-query-kit';
import {updateUserSettings} from '../../../repositories/instill';
import {UpdateUserSettingsPayload} from '../../../repositories/instill/mutations/update-user-settings';

export const useUpdateUserSettings = createMutation<
  AxiosResponse<any, any> | undefined,
  UpdateUserSettingsPayload
>({
  mutationFn: async (variables) => {
    return updateUserSettings({
      companyUuid: variables.companyUuid,
      payload: variables.payload,
    });
  },
});
