import {sanitizeAuthUserFromRaw} from '../../../ui/@sanitizers/auth-user';
import InstillAPI from '../axios-instance';
import isResponseSuccessful from '../../../utils/is-response-successful';

export async function fetchCurrentUser() {
  try {
    const response = await InstillAPI.get('/auth-users/me');

    if (!response.data || !isResponseSuccessful(response)) {
      throw Error('No data received');
    }

    return sanitizeAuthUserFromRaw(response.data);
  } catch (error) {
    console.error(error);
  }
}
