import {
  ChangeEvent,
  FormEvent,
  FunctionComponent,
  useCallback,
  useState,
} from 'react';
import {CompanyCoreValue} from '../../../../../interfaces/company-core-value';
import {CompanyEmployee} from '../../../../../interfaces/company-employee';
import {ReactComponent as IconPlus} from '../../../../@components/kit/icons/plus.svg';
import {StringParam, useQueryParams} from 'use-query-params';
import {useCurrentFullUserProfileState} from '../../../../@atoms/current-full-user-profile';
import {useDebounce} from 'react-use';
import {useKudos} from '../../../../@hooks/queries';
import {useSafeCurrentCompany} from '../../../../@atoms/current-company';
import {useTranslation} from 'react-i18next';
import getOffsetValue from '../../../../../utils/get-offset-value';
import GiveKudosDialog from '../../../@components/kudos-list/@components/give-kudos-dialog';
import ListView from './@components/list-view';
import NoResultsBox from '../../../@components/no-results-box';
import Pagination from '../../../../@components/pagination';
import RemainingKudosDialog from '../../../@components/kudos-list/@components/remaining-kudos-dialog';
import RoundedCard from '../../../../@components/rounded-card';
import SearchForm from '../../../@components/search-form';
import Select from '../../../../@components/kit/form/select/select';
import Spinner from '../../../../@components/spinner';
import styles from './styles.module.scss';
import usePagination from '../../../../@hooks/use-pagination';

interface ElementProps {
  values?: CompanyCoreValue[];
  employees?: CompanyEmployee[];
}

const MINIMUM_SEARCH_LETTERS = 2;
const DELAY_FOR_SEARCH_DEBOUNCE_IN_MS = 500;
const NUMBER_OF_KUDOS_FOR_PAGINATION = 20;

const KudosList: FunctionComponent<ElementProps> = ({values, employees}) => {
  const {t} = useTranslation('kudos');

  const currentCompany = useSafeCurrentCompany();
  const [currentFullUserProfile, setcurrentFullUserProfile] =
    useCurrentFullUserProfileState();

  const [queryParameters, setQueryParameters] = useQueryParams({
    toUserUuid: StringParam,
    valueUuid: StringParam,
    query: StringParam,
  });

  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(
    queryParameters.query
  );

  const [selectedValue, setSelectedValue] = useState(
    queryParameters.valueUuid || ''
  );

  const [selectedEmployee, setSelectedEmployee] = useState(
    queryParameters.toUserUuid || ''
  );

  const [isGiveKudosDialogVisible, setIsGiveKudosDialogVisible] =
    useState(false);

  const [isRemainingKudoDialogVisible, setIsRemainingKudoDialogVisible] =
    useState(false);

  const {
    currentPage,
    offset,
    goToNextPage,
    goToPreviousPage,
    pageInQueryParams,
    setPageInQueryParams,
    onResetPagination,
  } = usePagination({itemsPerPage: NUMBER_OF_KUDOS_FOR_PAGINATION});

  const kudos = useKudos({
    variables: {
      offset: getOffsetValue({
        offset,
        currentPage,
        itemsPerPage: NUMBER_OF_KUDOS_FOR_PAGINATION,
      }),
      companyUuid: currentCompany.uuid,
      limit: NUMBER_OF_KUDOS_FOR_PAGINATION,
      query: queryParameters.query,
      toUserUuid: queryParameters.toUserUuid,
      valueUuid: queryParameters.valueUuid,
    },
  });

  const updateUserProfile = useCallback(() => {
    const kudosRemainingFromProfile = currentFullUserProfile.kudosRemaining;

    if (!kudosRemainingFromProfile) return;

    const kudosRemaining = kudosRemainingFromProfile - 1;

    const userProfile = {
      ...currentFullUserProfile,
      kudosRemaining: kudosRemaining,
    };

    setcurrentFullUserProfile(userProfile);
  }, [currentFullUserProfile, setcurrentFullUserProfile]);

  const onGiveKudosSuccess = useCallback(async () => {
    setIsGiveKudosDialogVisible(false);
    setIsRemainingKudoDialogVisible(true);

    kudos.refetch();
    updateUserProfile();
  }, [kudos, updateUserProfile]);

  const onClearSearch = useCallback(
    (event: FormEvent) => {
      event.preventDefault();

      setQueryParameters({
        ...queryParameters,
        query: undefined,
      });
    },
    [queryParameters, setQueryParameters]
  );

  const onSearch = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    setDebouncedSearchQuery(value);
  }, []);

  const onChangeValues = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const value = event.target.value;

      setSelectedValue(value);
      setSelectedEmployee('');
      setPageInQueryParams(null);

      setQueryParameters({
        ...queryParameters,
        valueUuid: value,
        toUserUuid: null,
      });

      setQueryParameters({
        ...queryParameters,
        valueUuid: value,
        toUserUuid: undefined,
      });

      setPageInQueryParams(1);
    },
    [queryParameters, setPageInQueryParams, setQueryParameters]
  );

  const onChangeEmployees = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const value = event.target.value;

      setSelectedEmployee(value);
      setSelectedValue('');
      setPageInQueryParams(null);

      setQueryParameters({
        ...queryParameters,
        valueUuid: null,
        toUserUuid: value,
      });

      setQueryParameters({
        ...queryParameters,
        valueUuid: undefined,
        toUserUuid: value,
      });

      setPageInQueryParams(1);
    },
    [queryParameters, setPageInQueryParams, setQueryParameters]
  );

  useDebounce(
    () => {
      if (
        debouncedSearchQuery !== '' &&
        (debouncedSearchQuery?.length ?? 0) < MINIMUM_SEARCH_LETTERS
      ) {
        return;
      }

      setQueryParameters({
        ...queryParameters,
        query: debouncedSearchQuery,
      });

      if (!pageInQueryParams) return;
      onResetPagination();
    },
    DELAY_FOR_SEARCH_DEBOUNCE_IN_MS,
    [debouncedSearchQuery]
  );

  return (
    <>
      <RoundedCard.Header>
        <RoundedCard.Title>
          {t('components.kudos-list.title')}

          <button
            type="button"
            className={styles.button}
            aria-label={t(
              'components.kudos-list.give-kudo-button-accessibility-text'
            )}
            onClick={() => setIsGiveKudosDialogVisible(true)}
          >
            <IconPlus className={styles.buttonIcon} />
          </button>
        </RoundedCard.Title>

        <div className={styles.form}>
          <div className={styles.selectContainer}>
            <Select value={selectedValue} onChange={onChangeValues}>
              <option value="" disabled>
                {t('components.kudos-list.value-dropdown-placeholder')}
              </option>

              {values?.map((value) => (
                <option key={value.uuid} value={value.uuid}>
                  {value.name}
                </option>
              ))}
            </Select>

            <Select value={selectedEmployee} onChange={onChangeEmployees}>
              <option value="" disabled>
                {t('components.kudos-list.employee-dropdown-placeholder')}
              </option>

              {employees?.map((employee) => (
                <option
                  key={employee.userProfileUuid}
                  value={employee.userProfileUuid}
                >
                  {employee.name}
                </option>
              ))}
            </Select>
          </div>

          <SearchForm
            onClearSearch={onClearSearch}
            onSearch={onSearch}
            placeholder={t('components.kudos-list.search-box-placeholder')}
            searchQueryInQueryParams={queryParameters.query}
          />
        </div>
      </RoundedCard.Header>

      <RoundedCard.Content>
        {kudos.isLoading ? (
          <Spinner />
        ) : (
          <>
            {kudos.data?.data.length ? (
              <>
                <ListView kudosList={kudos.data?.data} />

                <Pagination
                  currentPage={currentPage}
                  itemsPerPage={NUMBER_OF_KUDOS_FOR_PAGINATION}
                  onNextPageLinkClicked={goToNextPage}
                  onPreviousPageLinkClicked={goToPreviousPage}
                  totalItems={kudos.data?.meta.totalEntries}
                />
              </>
            ) : (
              <NoResultsBox />
            )}
          </>
        )}
      </RoundedCard.Content>

      <GiveKudosDialog
        isVisible={isGiveKudosDialogVisible}
        onClose={() => setIsGiveKudosDialogVisible(false)}
        onGiveKudosSuccess={onGiveKudosSuccess}
      />

      <RemainingKudosDialog
        isVisible={isRemainingKudoDialogVisible}
        onClose={() => setIsRemainingKudoDialogVisible(false)}
      />
    </>
  );
};

export default KudosList;
