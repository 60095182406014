import React, {
  FunctionComponent,
  createContext,
  useContext,
  PropsWithChildren,
} from 'react';
import FormError from '../form-error';
import styles from './styles.module.scss';

interface Props {
  error?: string | null;
  required?: boolean;
  variant?: 'floating-error';
}

interface Context {
  error?: string | null;
  hasError: boolean;
  required: boolean;
}

const FormControlContext = createContext<Context>({
  required: false,
  hasError: false,
});

export const useFormControlContext = () => useContext(FormControlContext);

const FormControl: FunctionComponent<PropsWithChildren<Props>> = ({
  children,
  error,
  required = false,
  variant,
}) => {
  const hasError = Boolean(error);

  const variantClass = variant ? styles[variant] : '';
  const containerClass = `${styles.container} ${variantClass}`;

  return (
    <FormControlContext.Provider
      value={{error, hasError: Boolean(error), required}}
    >
      <div className={containerClass}>
        {children}

        {hasError && (
          <div className={styles.error}>
            <FormError>{error}</FormError>
          </div>
        )}
      </div>
    </FormControlContext.Provider>
  );
};

export default FormControl;
