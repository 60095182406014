import {buildPageTitle} from '../../../../services/build-page-title';
import {EnterYourWorkStyleEvent} from '../../../../interfaces/analytics/event-properties';
import {EventName} from '../../../../constants/analytics/event-name';
import {EventType} from '../../../../constants/analytics/event-type';
import {Formik} from 'formik';
import {FunctionComponent, useCallback} from 'react';
import {Helmet} from 'react-helmet-async';
import {ReactComponent as IconEdit} from '../../../@components/kit/icons/edit.svg';
import {sanitizeWorkStylePreferencesToRaw} from '../../../@sanitizers/work-style-preferences';
import {useNavigate} from 'react-router';
import {USER_CHECKLIST_STEP_KEYS} from '../../@components/setup-checklist';
import {useSafeCurrentCompany} from '../../../@atoms/current-company';
import {useTranslation} from 'react-i18next';
import {useUpdateWorkStylePreferences} from '../../../@hooks/mutations';
import {WorkStylePreferencesFormValues} from '../../../../interfaces/work-style-preferences';
import * as Yup from 'yup';
import Form from './form';
import formatTimeAsString from '../../../../utils/format-time-as-string';
import useAnalytics from '../../../@hooks/use-analytics';
import UserSetupStyles from '../styles.module.scss';

const isValueEqualToEmptyString = (value: string) => {
  return value === '';
};

const INITIAL_VALUES: WorkStylePreferencesFormValues = {
  workHours: {
    startTimeHour: '',
    startTimeMinute: '',
    endTimeHour: '',
    endTimeMinute: '',
  },
  workPlace: '',
  oneOnOneCadence: '',
  averageResponseTimes: {
    phone: '',
    textMessage: '',
    email: '',
    chat: '',
  },
  feedbackPreferences: {
    deliveryMethod: '',
    cadence: '',
  },
};

interface ElementProps {
  stepIdentifier: USER_CHECKLIST_STEP_KEYS;
  updateChecklist: (stepIdentifier: USER_CHECKLIST_STEP_KEYS) => void;
}

const NEXT_STEP_ROUTE = '/application/user-setup/review-company-values';

const WorkStyle: FunctionComponent<ElementProps> = ({
  stepIdentifier,
  updateChecklist,
}) => {
  const {t} = useTranslation(['userSetup', 'applicationPageTitle']);

  const navigate = useNavigate();
  const {trackEvent} = useAnalytics();
  const updateWorkStylePreferences = useUpdateWorkStylePreferences();

  const currentCompany = useSafeCurrentCompany();

  const validationSchema = Yup.object().shape({
    workHours: Yup.object().shape({
      endTimeHour: Yup.string().when('endTimeMinute', {
        is: (value: string) => !!value,
        then: (schema) =>
          schema.required(t('form.error.required', {ns: 'common'})),
        otherwise: (schema) => schema.nullable(),
      }),
      startTimeHour: Yup.string().when('startTimeMinute', {
        is: (value: string) => !!value,
        then: (schema) =>
          schema.required(t('form.error.required', {ns: 'common'})),
        otherwise: (schema) => schema.nullable(),
      }),
    }),
  });

  const onCompleteChecklistStep = useCallback(() => {
    updateChecklist(stepIdentifier);
    navigate(NEXT_STEP_ROUTE);
  }, [stepIdentifier, updateChecklist, navigate]);

  const onSkipButtonClicked = useCallback(() => {
    trackEvent({
      eventName: EventName.USER_SETUP.ENTER_WORK_STYLE.SKIP_FOR_NOW,
      eventType: EventType.BUTTON_CLICKED,
    });

    onCompleteChecklistStep();
  }, [trackEvent, onCompleteChecklistStep]);

  const onTrackValues = useCallback(
    (values: WorkStylePreferencesFormValues) => {
      const eventProperties = {
        averageResponseTimeEmail: isValueEqualToEmptyString(
          values.averageResponseTimes.email
        )
          ? '–'
          : values.averageResponseTimes.email,
        averageResponseTimePhone: isValueEqualToEmptyString(
          values.averageResponseTimes.phone
        )
          ? '–'
          : values.averageResponseTimes.phone,
        averageResponseTimeSlack: isValueEqualToEmptyString(
          values.averageResponseTimes.chat
        )
          ? '–'
          : values.averageResponseTimes.chat,
        averageResponseTimeTextMessage: isValueEqualToEmptyString(
          values.averageResponseTimes.textMessage
        )
          ? '–'
          : values.averageResponseTimes.textMessage,
        endTime:
          formatTimeAsString({
            hour: values.workHours.endTimeHour,
            minute: values.workHours.endTimeMinute,
          }) ?? '–',
        feedbackPreferencesCadence: isValueEqualToEmptyString(
          values.feedbackPreferences.cadence
        )
          ? '–'
          : values.feedbackPreferences.cadence,
        feedbackPreferencesDeliveryMethod: isValueEqualToEmptyString(
          values.feedbackPreferences.deliveryMethod
        )
          ? '–'
          : values.feedbackPreferences.deliveryMethod,
        oneOnOneCadence: isValueEqualToEmptyString(values.oneOnOneCadence)
          ? '–'
          : values.oneOnOneCadence,
        startTime:
          formatTimeAsString({
            hour: values.workHours.startTimeHour,
            minute: values.workHours.startTimeMinute,
          }) ?? '–',
        workPlace: isValueEqualToEmptyString(values.workPlace)
          ? '–'
          : values.workPlace,
      } as EnterYourWorkStyleEvent;

      trackEvent({
        eventName: EventName.USER_SETUP.ENTER_WORK_STYLE.SAVE_CONTINUE,
        eventType: EventType.BUTTON_CLICKED,
        eventProperties,
      });
    },
    [trackEvent]
  );

  const onSubmit = useCallback(
    async (values: WorkStylePreferencesFormValues) => {
      const payload = sanitizeWorkStylePreferencesToRaw(values);

      const updateWorkStyleResponse =
        await updateWorkStylePreferences.mutateAsync({
          workStylePreferences: payload,
          companyUuid: currentCompany.uuid,
        });

      if (!updateWorkStyleResponse) return;

      onTrackValues(values);

      onCompleteChecklistStep();
      navigate(NEXT_STEP_ROUTE);
    },
    [
      currentCompany,
      navigate,
      onCompleteChecklistStep,
      onTrackValues,
      updateWorkStylePreferences,
    ]
  );

  return (
    <>
      <Helmet>
        <title>
          {buildPageTitle(
            t('page-title.user-setup.enter-your-work-style', {
              ns: 'applicationPageTitle',
            })
          )}
        </title>
      </Helmet>

      <div className={UserSetupStyles.header}>
        <div className={UserSetupStyles.titleContainer}>
          <h1 className={UserSetupStyles.headerTitle}>
            {t('work-style.form.header.title')}

            <IconEdit className={UserSetupStyles.headerIcon} />
          </h1>
        </div>
      </div>

      <Formik
        initialValues={INITIAL_VALUES}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={onSubmit}
      >
        {({handleSubmit}) => (
          <Form onSubmit={handleSubmit} onSkip={onSkipButtonClicked} />
        )}
      </Formik>
    </>
  );
};

export default WorkStyle;
