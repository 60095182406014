import {ChangeEvent, FunctionComponent, useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {ToastOptions, toast} from 'react-toastify';
import {DEFAULT_TOASTIFY_CONFIGURATION} from '../../../../../../constants/toastify-configuration';
import headingStyles from '../../../../../../styles/classes/heading.module.scss';
import {useSafeCurrentCompany} from '../../../../../@atoms/current-company';
import Label from '../../../../../@components/kit/form/label';
import SwitchToggle from '../../../../../@components/kit/form/switch-toggle';
import {useUpdateUserSettings} from '../../../../../@hooks/mutations';
import {useCurrentCompanyEmployee} from '../../../../../@hooks/queries';
import styles from './styles.module.scss';

const InstillSenseSettings: FunctionComponent = () => {
  const {t} = useTranslation('settings', {
    keyPrefix: 'configurations.components.auto-join',
  });

  const currentCompany = useSafeCurrentCompany();

  const profile = useCurrentCompanyEmployee({
    variables: {
      companyUuid: currentCompany.uuid,
    },
  });

  const updateUserSettings = useUpdateUserSettings();

  const formValues = useMemo(() => {
    return {
      senseAutoJoin: profile.data?.settings?.senseAutoJoin ?? false,
    };
  }, [profile]);

  const onChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const updatedUserSettings = {
        ...formValues,
        [event.target.value]: event.target.checked,
      };

      const response = await updateUserSettings.mutateAsync({
        companyUuid: currentCompany.uuid,
        payload: updatedUserSettings,
      });

      if (!response) return;

      profile.refetch();

      const feedbackMessage = t('feedback-message');

      toast.success(feedbackMessage, {
        ...(DEFAULT_TOASTIFY_CONFIGURATION as ToastOptions),
      });
    },
    [currentCompany, formValues, profile, t, updateUserSettings]
  );

  return (
    <>
      <h2 className={headingStyles.h3}>{t('title')}</h2>

      <form>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            <SwitchToggle
              isChecked={formValues.senseAutoJoin}
              onChange={onChange}
              value="senseAutoJoin"
              id="auto-join"
            />

            <Label htmlFor="auto-join" className={styles.label}>
              {t('options.auto-join')}
            </Label>
          </li>
        </ul>
      </form>
    </>
  );
};

export default InstillSenseSettings;
