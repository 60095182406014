import {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import {Kudo} from '../../../../../../../interfaces/kudo';
import Table from '../../../../../../@components/table';
import TableRow from './@components/table-row';
import styles from './styles.module.scss';

interface Props {
  kudosList: Kudo[];
}

const ListView: FunctionComponent<Props> = ({kudosList}) => {
  const {t} = useTranslation('kudos');

  return (
    <Table>
      <Table.Head className={styles.tableHeader}>
        <Table.Row variant="no-margin">
          <Table.Header variant="nameColumn">
            {t('components.kudos-list.components.list-view.header.recipient')}
          </Table.Header>

          <Table.Header variant="medium">
            {t('components.kudos-list.components.list-view.header.from')}
          </Table.Header>

          <Table.Header variant="small">
            {t('components.kudos-list.components.list-view.header.value')}
          </Table.Header>

          <Table.Header variant="small">
            {t('components.kudos-list.components.list-view.header.date')}
          </Table.Header>

          <Table.Header variant="large">
            {t('components.kudos-list.components.list-view.header.description')}
          </Table.Header>
        </Table.Row>
      </Table.Head>

      <Table.Content>
        {kudosList.map((kudos) => (
          <TableRow key={kudos.uuid} kudos={kudos} />
        ))}
      </Table.Content>
    </Table>
  );
};

export default ListView;
