import {atom, useRecoilState, useRecoilValue} from 'recoil';
import {AuthUser} from '../../interfaces/auth-user';

const currentAuthUserState = atom<AuthUser | null>({
  key: 'currentAuthUser',
  default: null,
});

export function useCurrentAuthUserState() {
  return useRecoilState(currentAuthUserState);
}

export function useCurrentAuthUser() {
  return useRecoilValue(currentAuthUserState);
}

export function useSafeCurrentAuthUser() {
  const authUser = useRecoilValue(currentAuthUserState);

  if (!authUser) {
    throw Error('Current auth user is null');
  }

  return authUser;
}
