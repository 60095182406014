import {
  CompanyChecklist,
  CompanyChecklistRaw,
  UserChecklist,
  UserChecklistRaw,
} from '../../interfaces/checklist';

export const sanitizeCompanyChecklistFromRaw = (
  data: CompanyChecklistRaw
): CompanyChecklist => {
  return {
    codifiedValue: data.codified_value,
    integratedSlackApp: data.integrated_slack_app,
    invitedTeam: data.invited_team,
    signedOffOnValues: data.signed_off_on_values,
  };
};

export const sanitizeUserChecklistFromRaw = (
  data: UserChecklistRaw
): UserChecklist => {
  return {
    createdProfile: data.created_profile,
    mappedOrganization: data.mapped_organization,
    setWorkStyle: data.set_work_style,
    reviewedCompanyValues: data.reviewed_company_values,
    signedOffOnValues: data.signed_off_on_values,
  };
};
