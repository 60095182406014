import {FunctionComponent} from 'react';
import {
  getAddressSugesstion,
  Suggestion,
} from '../../../../../../services/get-address-autocomplete';
import AsyncSelect from 'react-select/async';
import {OnChangeValue} from 'react-select';
import {FieldProps} from 'formik';
import {selectStyles, selectTheme} from '../styles.module';

interface ElementProps extends FieldProps {
  onChangeCallback?: (value: Suggestion) => void;
  placeholder?: string;
  hasError?: boolean;
}

const LocationAutofillSelect: FunctionComponent<ElementProps> = ({
  onChangeCallback,
  placeholder = '',
  form,
  field,
  hasError = false,
}) => {
  const onChange = (option: OnChangeValue<Suggestion, false>) => {
    onChangeCallback && onChangeCallback(option as Suggestion);

    form.setFieldValue(field.name, (option as Suggestion).value);
  };

  return (
    <AsyncSelect
      loadOptions={getAddressSugesstion}
      styles={selectStyles(hasError)}
      theme={selectTheme}
      menuPlacement="auto"
      placeholder={placeholder}
      onChange={onChange}
      defaultInputValue={form.values[field.name]}
    />
  );
};

export default LocationAutofillSelect;
