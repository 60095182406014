import {Company} from '../../../interfaces/company';
import {createQuery} from 'react-query-kit';
import {fetchCompany} from '../../../repositories/instill';
import {FetchCompanyPayload} from '../../../repositories/instill/queries/fetch-company';

export const useCompany = createQuery<Company | undefined, FetchCompanyPayload>(
  {
    primaryKey: 'company',
    queryFn: async ({queryKey: [_, variables]}) => {
      if (!variables.companyUuid) {
        return Promise.reject();
      }

      return await fetchCompany(variables.companyUuid);
    },
  }
);
