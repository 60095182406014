import {Expectation} from '../../../../../../interfaces/company-core-value';
import {FunctionComponent, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import getExpectationLabelFromNumber from '../../../../../../utils/get-expectation-label-from-number';
import styles from './styles.module.scss';
import Tag from '../../../../../@components/tag';

interface ElementProps {
  expectations: Expectation[];
}

const ExpectationList: FunctionComponent<ElementProps> = ({expectations}) => {
  const {t} = useTranslation(['dashboard', 'common']);

  const skipExpectationLabel = useCallback((level: number) => {
    const displayedExpectationLabel = [] as number[];

    if (displayedExpectationLabel.includes(level)) {
      return true;
    } else {
      displayedExpectationLabel.push(level);
      return false;
    }
  }, []);

  return (
    <ul className={styles.list}>
      {expectations.map((expectation, index) => (
        <li className={styles.item} key={index}>
          <div className={styles.column}>
            {!skipExpectationLabel(expectation.level) ? (
              <Tag>{getExpectationLabelFromNumber(expectation.level, t)}</Tag>
            ) : null}
          </div>

          <div className={styles.column}>
            <ul className={styles.sublist}>
              {expectation.normsAndBehaviour.map((item, index) => (
                <li className={styles.sublistItem} key={index}>
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default ExpectationList;
