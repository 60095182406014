import {createMutation} from 'react-query-kit';
import {JobPosition} from '../../../interfaces/job-position';
import {updateJobPosition} from '../../../repositories/instill';
import {UpdateJobPositionPayload} from '../../../repositories/instill/mutations/update-job-position';

export const useUpdateJobPosition = createMutation<
  JobPosition | undefined,
  UpdateJobPositionPayload
>({
  mutationFn: async (variables) => {
    return await updateJobPosition({...variables});
  },
});
