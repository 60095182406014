import {FunctionComponent, useMemo} from 'react';
import {getUserProfileForCompany} from '../../../../../../services/user-profiles';
import {Link} from 'react-router-dom';
import {ReactComponent as IconBriefcase} from '../../../../../@components/kit/icons/briefcase.svg';
import {ReactComponent as IconLogout} from '../../../../../@components/kit/icons/log-out.svg';
import {ReactComponent as IconShuffle} from '../../../../../@components/kit/icons/shuffle.svg';
import {ReactComponent as IconTool} from '../../../../../@components/kit/icons/tool.svg';
import {ReactComponent as IconUsers} from '../../../../../@components/kit/icons/users.svg';
import {useCurrentAuthUserState} from '../../../../../@atoms/current-auth-user';
import {useSafeCurrentCompany} from '../../../../../@atoms/current-company';
import {useTranslation} from 'react-i18next';
import styles from './styles.module.scss';
import useOnClickOutside from '../../../../../@hooks/use-on-click-outside';

interface ElementProps {
  clickOutsideElement: HTMLElement | null;
  onClose: () => void;
}
const UserNavigation: FunctionComponent<ElementProps> = ({
  clickOutsideElement,
  onClose,
}) => {
  const {t} = useTranslation('application');

  const [currentAuthUser] = useCurrentAuthUserState();
  const currentCompany = useSafeCurrentCompany();
  const userProfile = getUserProfileForCompany(
    currentAuthUser!,
    currentCompany!
  );

  const isSwitchProfilesLinkVisible = useMemo(() => {
    if (!currentAuthUser) return false;

    return currentAuthUser.companies.length > 1;
  }, [currentAuthUser]);

  useOnClickOutside(clickOutsideElement, onClose);

  return (
    <ul>
      {userProfile.isOnboarded && (
        <>
          <li className={styles.item}>
            <Link to="/application/my-profile" className={styles.link}>
              <IconUsers className={styles.icon} />

              <span className={styles.text}>
                {t('components.navigation.links.my-profile')}
              </span>
            </Link>
          </li>

          <li className={styles.item}>
            <Link to="/application/my-company-profile" className={styles.link}>
              <IconBriefcase className={styles.icon} />

              <span className={styles.text}>
                {t('components.navigation.links.my-company-profile')}
              </span>
            </Link>
          </li>
        </>
      )}

      {isSwitchProfilesLinkVisible && (
        <li className={styles.item}>
          <Link to="/select-company" className={styles.link}>
            <IconShuffle className={styles.icon} />

            <span className={styles.text}>
              {t('components.navigation.links.switch-profile')}
            </span>
          </Link>
        </li>
      )}

      {currentAuthUser?.isSuperAdmin && (
        <li className={styles.item}>
          <Link to="/application/admin-portal" className={styles.link}>
            <IconTool className={styles.icon} />

            <span className={styles.text}>
              {t('components.navigation.links.admin-portal')}
            </span>
          </Link>
        </li>
      )}

      <li className={styles.item}>
        <Link to="/logout" className={styles.link}>
          <IconLogout className={styles.icon} />

          <span className={styles.text}>
            {t('components.navigation.links.logout')}
          </span>
        </Link>
      </li>
    </ul>
  );
};

export default UserNavigation;
