import InstillAPI from '../axios-instance';
import isResponseSuccessful from '../../../utils/is-response-successful';

export interface UpsertCandidateAssessmentResponsePayload {
  candidateToken: string;
  candidateUuid: string;
  moduleUuid: string;
  questionUuid: string;
  userAssessmentUuid: string;
  value: string;
}

interface ResponsePayload {
  value: string;
}

export async function upsertCandidateAssessmentResponse(
  candidateAssessmentResponsePayload: ResponsePayload,
  candidateToken: string,
  candidateUuid: string,
  userAssessmentUuid: string,
  moduleUuid: string,
  questionUuid: string
) {
  const response = await InstillAPI.post(
    `/candidate/${candidateUuid}/user-assessment/${userAssessmentUuid}/module/${moduleUuid}/question/${questionUuid}/response`,
    candidateAssessmentResponsePayload,
    {
      headers: {
        Authorization: `Bearer ${candidateToken}`,
      },
    }
  );

  if (!isResponseSuccessful(response)) {
    throw Error('No data received');
  }

  return response.data;
}
