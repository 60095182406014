import {Candidate} from '../../../interfaces/candidate';
import {createQuery} from 'react-query-kit';
import {fetchCandidates} from '../../../repositories/instill/queries';
import {Meta} from '../../../interfaces/meta';
import {ORDER_BY_PARAMETERS} from '../../../constants/filtering/order-by';
import getOffsetValue from '../../../utils/get-offset-value/get-offset-value';

interface FetchCandidatesPayload {
  companyUuid: string;
  currentPage: number;
  itemsPerPage: number;
  jobPositionFilters?: string;
  limit: number;
  offset: number;
  orderBy?: ORDER_BY_PARAMETERS;
  query?: string;
  sortBy?: string;
}

interface FetchCandidatesResponsePayload {
  data: Candidate[];
  meta: Meta;
}

export const useCandidates = createQuery<
  FetchCandidatesResponsePayload | undefined,
  FetchCandidatesPayload
>({
  primaryKey: 'candidates',
  queryFn: async ({queryKey: [_, variables]}) => {
    let payload = {
      offset: getOffsetValue({
        offset: variables.offset,
        currentPage: variables.currentPage,
        itemsPerPage: variables.itemsPerPage,
      }),
      limit: variables.limit,
      companyUuid: variables.companyUuid,
    } as FetchCandidatesPayload;

    if (variables.sortBy) {
      payload['sortBy'] = variables.sortBy;
    }

    if (variables.orderBy) {
      payload['orderBy'] = variables.orderBy;
    }

    if (variables.query) {
      payload['query'] = variables.query;
    }

    if (variables.jobPositionFilters) {
      payload['jobPositionFilters'] = variables.jobPositionFilters;
    }

    return await fetchCandidates(payload);
  },
});
