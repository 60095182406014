import {Field, FieldProps, Form, Formik} from 'formik';
import {FunctionComponent, useCallback, useMemo} from 'react';
import {JobPosition} from '../../../../../../../../interfaces/job-position';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import Button from '../../../../../../../@components/kit/form/button';
import Dialog from '../../../../../../../@components/kit/dialog/dialog';
import FormControl from '../../../../../../../@components/kit/form/form-control';
import FormFooter from '../../../../../../../@components/kit/form/form-footer';
import FormGroup from '../../../../../../../@components/kit/form/form-group';
import Input from '../../../../../../../@components/kit/form/input';
import InputCheckbox from '../../../../../../../@components/kit/form/input-checkbox';
import Label from '../../../../../../../@components/kit/form/label';

interface ElementProps {
  isDialogVisible: boolean;
  jobPosition?: JobPosition;
  onClose: () => void;
  onSuccess: (values: JobPositionFormValues) => void;
}

export interface JobPositionFormValues {
  title: string;
  isActive: boolean;
}

const JobPositionDialog: FunctionComponent<ElementProps> = ({
  isDialogVisible,
  jobPosition,
  onClose,
  onSuccess,
}) => {
  const {t} = useTranslation(['jobs', 'common']);

  const isUpdatingJobPosition = Boolean(jobPosition);

  const submitButtonTranslation = useMemo(() => {
    const translationPrefix =
      'job-positions.components.job-position-list.components.job-position-dialog.form';

    const translationKey = isUpdatingJobPosition
      ? 'update-submit-button'
      : 'submit-button';

    return t(`${translationPrefix}.${translationKey}`);
  }, [isUpdatingJobPosition, t]);

  const dialogTitleTranslation = useMemo(() => {
    const translationPrefix =
      'job-positions.components.job-position-list.components.job-position-dialog.title';

    const translationKey = isUpdatingJobPosition ? 'update' : 'add';

    return t(`${translationPrefix}.${translationKey}`);
  }, [isUpdatingJobPosition, t]);

  const initialValues = useMemo(() => {
    return {
      title: jobPosition?.title ?? '',
      isActive: jobPosition?.isActive ?? true,
    };
  }, [jobPosition]);

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .required(t('form.error.required', {ns: 'common'}))
      .max(255),
  });

  const onSubmit = useCallback(
    (values: JobPositionFormValues) => {
      onSuccess(values);
    },
    [onSuccess]
  );

  const isSubmitButtonDisabled = useCallback(
    (values: JobPositionFormValues, isValid: boolean) => {
      const someValuesHaveChanged = Object.keys(initialValues).some((key) => {
        return (
          initialValues[key as keyof typeof initialValues] !==
          values[key as keyof typeof values]
        );
      });

      if (!someValuesHaveChanged) return true;

      return !isValid;
    },
    [initialValues]
  );

  return (
    <Dialog isOpen={isDialogVisible} onClose={onClose} variant="small">
      <Dialog.Header title={dialogTitleTranslation} />

      <Dialog.Content>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          validateOnMount
        >
          {({values, errors, touched, isValid}) => (
            <Form>
              <FormGroup>
                <Field name="title">
                  {({field}: FieldProps<string>) => (
                    <FormControl
                      error={
                        Boolean(errors.title && touched.title)
                          ? errors.title
                          : undefined
                      }
                    >
                      <Label isRequired>
                        {t(
                          'job-positions.components.job-position-list.components.job-position-dialog.form.label.title'
                        )}
                      </Label>

                      <Input
                        type="text"
                        placeholder={t(
                          'job-positions.components.job-position-list.components.job-position-dialog.form.placeholder.title'
                        )}
                        maxLength={255}
                        {...field}
                      />
                    </FormControl>
                  )}
                </Field>
              </FormGroup>

              <FormGroup>
                <Field name="isActive">
                  {({field}: FieldProps<string>) => (
                    <FormControl>
                      <InputCheckbox
                        disabled={!isUpdatingJobPosition}
                        checked={values.isActive}
                        label={t(
                          'job-positions.components.job-position-list.components.job-position-dialog.form.label.is-active-description'
                        )}
                        {...field}
                      />
                    </FormControl>
                  )}
                </Field>
              </FormGroup>

              <FormFooter>
                <Button
                  disabled={isSubmitButtonDisabled(values, isValid)}
                  type="submit"
                >
                  {submitButtonTranslation}
                </Button>

                <Button variant="secondary" onClick={onClose}>
                  {t(
                    'job-positions.components.job-position-list.components.job-position-dialog.form.cancel-button'
                  )}
                </Button>
              </FormFooter>
            </Form>
          )}
        </Formik>
      </Dialog.Content>
    </Dialog>
  );
};

export default JobPositionDialog;
