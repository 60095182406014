import {buildPageTitle} from '../../../services/build-page-title';
import {CompanyRegistrationEnterYourNameEvent} from '../../../interfaces/analytics/event-properties';
import {EventName} from '../../../constants/analytics/event-name';
import {EventType} from '../../../constants/analytics/event-type';
import {Field, FieldProps, useFormikContext} from 'formik';
import {FormEvent, FunctionComponent, useCallback, useMemo} from 'react';
import {Helmet} from 'react-helmet-async';
import {SignupFormValues} from '../register-company';
import {useTranslation} from 'react-i18next';
import Button from '../../@components/kit/form/button';
import FormControl from '../../@components/kit/form/form-control';
import FormFooter from '../../@components/kit/form/form-footer';
import FormGroup from '../../@components/kit/form/form-group';
import Input from '../../@components/kit/form/input';
import Label from '../../@components/kit/form/label';
import styles from '../styles.module.scss';
import useAnalytics from '../../@hooks/use-analytics';

interface ElementProps {
  onSubmit: () => void;
}

const EnterYourName: FunctionComponent<ElementProps> = ({onSubmit}) => {
  const {t} = useTranslation(['signup', 'common', 'applicationPageTitle']);
  const {values, errors} = useFormikContext<SignupFormValues>();
  const hasErrors = useMemo(() => Boolean(errors.adminName), [errors]);

  const {trackEvent} = useAnalytics();

  const handleSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      onSubmit();
    },
    [onSubmit]
  );

  const onContinueButtonClick = useCallback(() => {
    if (hasErrors) return;

    const eventProperties = {
      adminName: values.adminName,
    } as CompanyRegistrationEnterYourNameEvent;

    trackEvent({
      eventName: EventName.COMPANY_REGISTRATION.ENTER_YOUR_NAME,
      eventType: EventType.CONTINUE_BUTTON_CLICKED,
      eventProperties,
    });
  }, [hasErrors, trackEvent, values]);

  return (
    <>
      <Helmet>
        <title>
          {buildPageTitle([
            t('page-title.signup.enter-your-name', {
              ns: 'applicationPageTitle',
            }),
            t('page-title.signup.index', {ns: 'applicationPageTitle'}),
          ])}
        </title>
      </Helmet>

      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles.formTitle}>{t('enter-your-name.title')}</div>

        <div className={styles.fields}>
          <Field name="adminName">
            {({field, form}: FieldProps<string, SignupFormValues>) => (
              <FormGroup variant="small">
                <FormControl
                  error={
                    form.errors.adminName && form.touched.adminName
                      ? t(form.errors.adminName, {ns: 'common'})
                      : undefined
                  }
                >
                  <Label htmlFor="adminName">
                    {t('enter-your-name.form.admin-name.label')}
                  </Label>

                  <Input
                    type="text"
                    placeholder={t(
                      'enter-your-name.form.admin-name.placeholder'
                    )}
                    id={field.name}
                    {...field}
                  />
                </FormControl>
              </FormGroup>
            )}
          </Field>
        </div>

        <FormFooter>
          <Button
            type="submit"
            disabled={hasErrors}
            onClick={onContinueButtonClick}
          >
            {t('enter-your-name.submit-button')}
          </Button>
        </FormFooter>
      </form>
    </>
  );
};

export default EnterYourName;
