import {sanitizeCompanyChecklistFromRaw} from '../../../ui/@sanitizers/checklist';
import {ORGANIZATION_CHECKLIST_STEP_KEYS} from '../../../ui/application/@components/setup-checklist/setup-checklist';
import InstillAPI from '../axios-instance';

const MAPPING_FOR_API = {
  [ORGANIZATION_CHECKLIST_STEP_KEYS.CODIFIED_VALUE]: 'codified_value',
  [ORGANIZATION_CHECKLIST_STEP_KEYS.INTEGRATED_SLACK_APP]:
    'integrated_slack_app',
  [ORGANIZATION_CHECKLIST_STEP_KEYS.INVITED_TEAM]: 'invited_team',
  [ORGANIZATION_CHECKLIST_STEP_KEYS.SIGNED_OFF_ON_VALUES]:
    'signed_off_on_values',
};

export async function updateCompanyChecklist(
  currentStep: ORGANIZATION_CHECKLIST_STEP_KEYS,
  companyUuid: string
) {
  const payload = {
    [MAPPING_FOR_API[currentStep]]: true,
  };

  const response = await InstillAPI.put(
    `/companies/${companyUuid}/checklist`,
    payload
  );

  return sanitizeCompanyChecklistFromRaw(response.data);
}
