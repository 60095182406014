import format from 'date-fns/format';
import {en} from './locales';

const DEFAULT_DATE_FORMAT = 'MMM do, y';

export function formatDate(date: string, customFormat?: string) {
  const config = {
    locale: en,
  };

  const dateFormat = customFormat ? customFormat : DEFAULT_DATE_FORMAT;
  const updatedDate = new Date(date);

  return format(updatedDate, dateFormat, config);
}

export function isDate(date: string) {
  return !isNaN(new Date(date).getDate());
}
