import {Field, FieldProps, Form, Formik} from 'formik';
import {FormProps, PossibleContentType} from '../../../../design';
import {FunctionComponent, useCallback, useState} from 'react';
import {ReactComponent as IconPlus} from '../../../../../../@components/kit/icons/plus.svg';
import {ReactComponent as IconSave} from '../../../../../../@components/kit/icons/save.svg';
import {useTranslation} from 'react-i18next';
import Button from '../../../../../../@components/kit/form/button';
import FormFooter from '../../../../../../@components/kit/form/form-footer';
import FormGroup from '../../../../../../@components/kit/form/form-group';
import Label from '../../../../../../@components/kit/form/label';
import styles from './styles.module.scss';
import RichTextEditor from '../../../../../../@components/kit/form/rich-text-editor';

interface ElementProps {
  fieldName: PossibleContentType;
  fieldTranslationKey?: string;
  onCancel: () => void;
  onSubmit: (values: FormProps) => void;
  value: string | null;
}

const EditForm: FunctionComponent<ElementProps> = ({
  fieldName,
  fieldTranslationKey,
  onCancel,
  onSubmit,
  value,
}) => {
  const {t} = useTranslation('design');
  const [isInEditMode, setIsInEditMode] = useState(Boolean(value));

  const initialValues = {
    [fieldName]: value ? value : '',
  };

  const onCancelButtonClick = useCallback(() => {
    if (value) {
      onCancel();
    }

    setIsInEditMode(false);
  }, [value, onCancel]);

  const translationPrefix = fieldTranslationKey
    ? fieldTranslationKey
    : fieldName;

  const onEditorStateChange = useCallback(
    (htmlValue: string, form: FieldProps['form'], field: string) => {
      form.setFieldValue(field, htmlValue);
    },
    []
  );
  return (
    <>
      {isInEditMode ? (
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          {() => (
            <Form>
              <Field name={fieldName}>
                {({form, field}: FieldProps<string>) => (
                  <>
                    <FormGroup>
                      <Label>
                        {t(
                          `components.${translationPrefix}.edit-form.title-edit`
                        )}
                      </Label>

                      <RichTextEditor
                        placeholder={t(
                          `components.${translationPrefix}.edit-form.placeholder`
                        )}
                        initialValue={field.value}
                        onChange={(value) =>
                          onEditorStateChange(value, form, field.name)
                        }
                      />
                    </FormGroup>

                    <FormFooter>
                      <Button type="submit" disabled={Boolean(!field.value)}>
                        {t(
                          'components.content-box.components.edit-form.submit-button'
                        )}
                        <IconSave className={styles.buttonIcon} />
                      </Button>

                      <Button
                        type="button"
                        variant="secondary"
                        onClick={onCancelButtonClick}
                      >
                        {t(
                          'components.content-box.components.edit-form.cancel-button'
                        )}
                      </Button>
                    </FormFooter>
                  </>
                )}
              </Field>
            </Form>
          )}
        </Formik>
      ) : (
        <button
          type="button"
          className={styles.button}
          onClick={() => setIsInEditMode(true)}
        >
          <div className={styles.header}>
            {t(`components.${translationPrefix}.edit-form.title`)}

            <IconPlus className={styles.headerIcon} />
          </div>

          <footer className={styles.footer}>
            {t('components.content-box.components.edit-form.question-text')}

            <a
              href={t(
                `components.${translationPrefix}.edit-form.learn-more.url`
              )}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.link}
            >
              {t(`components.${translationPrefix}.edit-form.learn-more.text`)}
            </a>
          </footer>
        </button>
      )}
    </>
  );
};

export default EditForm;
