import {CoreValue} from '../../../interfaces/core-value';
import {createQuery} from 'react-query-kit';
import {fetchCoreValues} from '../../../repositories/instill';
import {sortByName} from '../../../utils/sort-by/name';

export const useCoreValues = createQuery<CoreValue[] | undefined>({
  primaryKey: 'coreValues',
  queryFn: async ({queryKey: [_, variables]}) => {
    const response = await fetchCoreValues();
    if (!response) return;

    return sortByName(response);
  },
});
