import {Actions} from '../../../../../constants/permissions/actions';
import {Company} from '../../../../../interfaces/company';
import {EventType} from '../../../../../constants/analytics/event-type';
import {FormProps, PossibleContentType} from '../../design';
import {FunctionComponent, useCallback, useState} from 'react';
import {getUserProfileForCompany} from '../../../../../services/user-profiles';
import {ReactComponent as IconEdit} from '../../../../@components/kit/icons/edit.svg';
import {useCurrentAuthUserState} from '../../../../@atoms/current-auth-user';
import {useCurrentCompanyState} from '../../../../@atoms/current-company';
import {useUserPermission} from '../../../../@hooks/use-user-permission';
import EditForm from './@components/edit-form';
import RoundedCard from '../../../../@components/rounded-card';
import styles from './styles.module.scss';
import useAnalytics from '../../../../@hooks/use-analytics';
import RichTextContainer from '../../../../@components/rich-text-container/rich-text-container';

interface ElementProps {
  action: Actions;
  eventName: string;
  fieldName: PossibleContentType;
  fieldTranslationKey?: string;
  fieldValue: string | null;
  onSubmit: (
    values: FormProps,
    fieldTranslationKey: string
  ) => Promise<Company | undefined>;
  placeholderText: string;
  title: string;
}

const ContentBox: FunctionComponent<ElementProps> = ({
  action,
  eventName,
  fieldName,
  fieldTranslationKey,
  fieldValue,
  onSubmit,
  placeholderText,
  title,
}) => {
  const [currentAuthUser] = useCurrentAuthUserState();
  const [currentCompany, setCurrentCompany] = useCurrentCompanyState();
  const {trackEvent} = useAnalytics();

  const [isEditFormVisible, setIsEditFormVisible] = useState(
    Boolean(!fieldValue)
  );

  const userProfile = getUserProfileForCompany(
    currentAuthUser!,
    currentCompany!
  );
  const can = useUserPermission(userProfile.role);
  const isCurrentUserAllowedToEdit = can(action);

  const isEditButtonVisible = isCurrentUserAllowedToEdit && Boolean(fieldValue);

  const onEditButtonClick = useCallback(() => {
    setIsEditFormVisible(true);
  }, []);

  const handleSubmit = useCallback(
    async (values: FormProps) => {
      const response = await onSubmit(values, fieldTranslationKey ?? fieldName);

      if (!response) return;

      setCurrentCompany(response);
      setIsEditFormVisible(false);

      trackEvent({
        eventName,
        eventType: EventType.SAVE_BUTTON_CLICKED,
      });
    },
    [
      trackEvent,
      eventName,
      onSubmit,
      setCurrentCompany,
      fieldTranslationKey,
      fieldName,
    ]
  );

  return (
    <>
      <RoundedCard.Header>
        <RoundedCard.Title>{title}</RoundedCard.Title>

        <div className={styles.column}>
          {isEditButtonVisible && (
            <button
              type="button"
              className={styles.headerButton}
              onClick={onEditButtonClick}
            >
              <IconEdit className={styles.headerButtonIcon} />
            </button>
          )}
        </div>
      </RoundedCard.Header>

      <RoundedCard.Content>
        {isCurrentUserAllowedToEdit ? (
          <>
            {isEditFormVisible ? (
              <EditForm
                fieldName={fieldName}
                fieldTranslationKey={fieldTranslationKey}
                value={fieldValue}
                onSubmit={handleSubmit}
                onCancel={() => setIsEditFormVisible(false)}
              />
            ) : (
              <div className={styles.text}>
                <RichTextContainer value={fieldValue as string} />
              </div>
            )}
          </>
        ) : (
          <>
            {fieldValue ? (
              <div className={styles.text}>
                <RichTextContainer value={fieldValue} />
              </div>
            ) : (
              <div className={styles.placeholderText}>{placeholderText}</div>
            )}
          </>
        )}
      </RoundedCard.Content>
    </>
  );
};

export default ContentBox;
