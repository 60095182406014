import {FunctionComponent} from 'react';
import {NavLink} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {ReactComponent as IconChevron} from '../../../@components/kit/icons/chevron.svg';
import styles from './styles.module.scss';

interface ElementProps {
  path?: string;
  label?: string;
  onClick?: () => void;
}

const BackButton: FunctionComponent<ElementProps> = ({
  label,
  path,
  onClick,
}) => {
  const {t} = useTranslation('components');

  const linkContent = (
    <>
      <IconChevron className={styles.icon} />

      <span className={styles.text}>
        {label ? label : t('back-button.button-text')}
      </span>
    </>
  );

  return (
    <div className={styles.container}>
      {path ? (
        <NavLink to={path} className={styles.link}>
          {linkContent}
        </NavLink>
      ) : (
        <button className={styles.link} onClick={onClick}>
          {linkContent}
        </button>
      )}
    </div>
  );
};

export default BackButton;
