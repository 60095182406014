import {sanitizeFullUserProfileFromRaw} from '../../../ui/@sanitizers/user-profile';
import InstillAPI from '../axios-instance';
import isResponseSuccessful from '../../../utils/is-response-successful';

export interface FetchCompanyUserProfilePayload {
  companyUuid: string;
  userProfileUuid?: string;
}

export async function fetchCompanyUserProfile(
  parameters: FetchCompanyUserProfilePayload
) {
  try {
    const response = await InstillAPI.get(
      `/companies/${parameters.companyUuid}/user-profiles/${parameters.userProfileUuid}`
    );

    if (!response.data || !isResponseSuccessful(response)) {
      throw Error('No data received');
    }

    return sanitizeFullUserProfileFromRaw(response.data);
  } catch (error) {
    console.error(error);
  }
}
