import {
  NotificationSettings,
  NotificationSettingsRaw,
} from '../../interfaces/notification-settings';

export const sanitizeNotificationSettingsFromRaw = (
  data: NotificationSettingsRaw
): NotificationSettings => {
  return {
    isEmailEnabled: data.is_email_enabled,
    isSlackEnabled: data.is_slack_enabled,
  };
};

export const sanitizeNotificationSettingsForRaw = (
  data: NotificationSettings
): NotificationSettingsRaw => {
  return {
    is_email_enabled: data.isEmailEnabled,
    is_slack_enabled: data.isSlackEnabled,
  };
};
