import {formatDistanceToNow} from 'date-fns';
import {FunctionComponent, useMemo} from 'react';
import {Notification} from '../../../../../../../../../../interfaces/notification';
import {useCurrentFullUserProfile} from '../../../../../../../../../@atoms/current-full-user-profile';
import {utcToZonedTime} from 'date-fns-tz';
import NotificationEvent from '../../../../../../../../../@components/notification-event';
import styles from './styles.module.scss';

interface ElementProps {
  notification: Notification;
}

const Item: FunctionComponent<ElementProps> = ({notification}) => {
  const currentFullUserProfile = useCurrentFullUserProfile();
  const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timezone = currentFullUserProfile.timeZone ?? browserTimezone;

  const formatedCreatedAt = useMemo(() => {
    return formatDistanceToNow(
      utcToZonedTime(notification.createdAt, timezone),
      {
        addSuffix: true,
      }
    );
  }, [notification, timezone]);

  const itemClassNames = useMemo(() => {
    const className = [styles.item];

    if (!notification.readAt) {
      className.push(styles.isUnread);
    }

    return className.join(' ');
  }, [notification]);

  return (
    <li className={itemClassNames}>
      <NotificationEvent
        event={notification.event}
        payload={notification.payload}
      />

      <span className={styles.date}>{formatedCreatedAt}</span>
    </li>
  );
};

export default Item;
