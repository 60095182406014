import InstillAPI from '../axios-instance';
import isResponseSuccessful from '../../../utils/is-response-successful';
import {sanitizeFullUserProfileFromRaw} from '../../../ui/@sanitizers/user-profile';

export interface FetchUserProfileForCompanyPayload {
  companyUuid: string;
}

export async function fetchUserProfileForCompany(companyUuid: string) {
  try {
    const response = await InstillAPI.get(
      `companies/${companyUuid}/user-profiles/me`
    );

    if (!response.data || !isResponseSuccessful(response)) {
      return;
    }

    return sanitizeFullUserProfileFromRaw(response.data);
  } catch (error) {
    console.error(error);
  }
}
