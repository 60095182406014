import {
  ChangeEvent,
  FunctionComponent,
  useCallback,
  useRef,
  useState,
} from 'react';
import {dataUritoBlob} from '../../../../utils/files/data-uri-to-blob';
import {EventName} from '../../../../constants/analytics/event-name';
import {EventType} from '../../../../constants/analytics/event-type';
import {fetchPresignedUploadURLResourceType} from '../../../../repositories/instill/queries/fetch-presigned-url-config';
import {FieldProps} from 'formik';
import {
  MEDIA_TYPE_IMAGE,
  MEDIA_TYPE_VIDEO,
} from '../../../../constants/core-value-media-type';
import {ReactComponent as IconHelp} from '../../../@components/kit/icons/help-info-icon.svg';
import {ReactComponent as IconUpload} from '../../../@components/kit/icons/file-upload.svg';
import {ReactComponent as IconYoutube} from '../../../@components/kit/icons/youtube.svg';
import {useFetchPresignedUploadUrl} from '../../../@hooks/mutations';
import {useSafeCurrentCompany} from '../../../@atoms/current-company';
import {useTranslation} from 'react-i18next';
import {useUploadToPresignedUrl} from '../../../@hooks/mutations';
import FormControl from '../../../@components/kit/form/form-control';
import FormGroup from '../../../@components/kit/form/form-group';
import HowToGetYoutubeUrlDialog from '../how-to-get-youtube-url-dialog';
import Input from '../../../@components/kit/form/input/input';
import Spinner from '../../../@components/spinner/spinner';
import styles from './styles.module.scss';
import useAnalytics from '../../../@hooks/use-analytics';

interface ElementProps {
  eventNameForEmbedYoutubeVideo?: string;
  eventNameForUploadImageFromComputer?: string;
  field: FieldProps['field'];
  form: FieldProps['form'];
  setMediaDataBase64?: Function;
  typeFieldName: string;
}

const UploadMedia: FunctionComponent<ElementProps> = ({
  eventNameForEmbedYoutubeVideo,
  eventNameForUploadImageFromComputer,
  field,
  form,
  setMediaDataBase64,
  typeFieldName,
}) => {
  const {t} = useTranslation(['components', 'common']);
  const currentCompany = useSafeCurrentCompany();
  const {trackEvent} = useAnalytics();

  const [showEmbedInput, setShowEmbedInput] = useState(false);
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [youtubeEmbedTouched, setYoutubeEmbedTouched] = useState(false);
  const [youtubeEmbedError, setYoutubeEmbedError] = useState(true);

  const fetchPresignedUploadURL = useFetchPresignedUploadUrl();
  const uploadToPresignedURL = useUploadToPresignedUrl();

  const onUploadChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];

      if (!file) return;

      const reader = new FileReader();
      reader.onload = async () => {
        setIsLoading(true);

        const mime = file.type;
        const extension = mime.split('/')[1];
        const dataUri = reader.result as string;
        const blob = dataUritoBlob(dataUri);

        if (!blob) return;

        const config = await fetchPresignedUploadURL.mutateAsync({
          companyUuid: currentCompany.uuid,
          extension: extension,
          mime: mime,
          ressource: fetchPresignedUploadURLResourceType.VALUE_MEDIA,
        });
        if (!config) return;

        const bucketURL = await uploadToPresignedURL.mutateAsync({
          config,
          file: blob,
        });
        if (!bucketURL) return;

        setIsLoading(false);
        setMediaDataBase64?.(dataUri);
        form.setFieldValue(field.name, bucketURL);
        form.setFieldValue(typeFieldName, MEDIA_TYPE_IMAGE);
      };
      reader.readAsDataURL(file);
    },
    [
      currentCompany,
      fetchPresignedUploadURL,
      field,
      form,
      setIsLoading,
      setMediaDataBase64,
      typeFieldName,
      uploadToPresignedURL,
    ]
  );

  const inputRef = useRef<HTMLInputElement>(null);

  const onClick = useCallback(() => {
    if (!inputRef.current) return;

    const eventName = eventNameForUploadImageFromComputer
      ? eventNameForUploadImageFromComputer
      : EventName.COMPONENTS.UPLOAD_MEDIA.UPLOAD_IMAGE_FROM_COMPUTER;

    trackEvent({
      eventName,
      eventType: EventType.BUTTON_CLICKED,
    });

    inputRef.current.click();
  }, [inputRef, trackEvent, eventNameForUploadImageFromComputer]);

  const onShowDialog = useCallback(() => {
    setIsDialogVisible(true);
  }, []);

  const onHideDialog = useCallback(() => {
    setIsDialogVisible(false);
  }, []);

  const onEmbedChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setYoutubeEmbedTouched(true);

      const value = event.target.value;
      if (!value) return;

      const regex = /^(https?:\/\/)?(www\.)?(youtu\.?be)\/.+$/;

      if (!regex.test(value)) {
        setYoutubeEmbedError(true);
        return;
      }

      const embedUrl = `https://www.youtube.com/embed/${value
        .split('/')
        .pop()}`;

      form.setFieldValue(field.name, embedUrl);
      form.setFieldValue(typeFieldName, MEDIA_TYPE_VIDEO);
    },
    [form, field, typeFieldName]
  );

  const onEmbedYoutubeVideoButtonClick = useCallback(() => {
    setShowEmbedInput(true);

    const eventName = eventNameForEmbedYoutubeVideo
      ? eventNameForEmbedYoutubeVideo
      : EventName.COMPONENTS.UPLOAD_MEDIA.EMBED_YOUTUBE_VIDEO;

    trackEvent({
      eventName,
      eventType: EventType.BUTTON_CLICKED,
    });
  }, [trackEvent, eventNameForEmbedYoutubeVideo]);

  const loadEmbedField = () => {
    if (!showEmbedInput) {
      return (
        <button
          className={styles.mediaUploadButton}
          type="button"
          onClick={onEmbedYoutubeVideoButtonClick}
        >
          <span className={styles.iconContainer}>
            <IconYoutube />
          </span>

          {t('upload-media.youtube-embed.button-text')}
        </button>
      );
    } else {
      return (
        <div className={styles.youtubeEmbedArea}>
          <div className={styles.embedFormHeader}>
            <div className={styles.embedFormHeaderColumn}>
              <span className={styles.iconContainer}>
                <IconYoutube />
              </span>

              {t('upload-media.youtube-embed.button-text')}
            </div>

            <div className={styles.embedFormHeaderColumn}>
              <button
                type="button"
                onClick={onShowDialog}
                className={styles.embedeFormHeaderbutton}
              >
                {t('upload-media.youtube-embed.display-dialog-button-text')}

                <IconHelp className={styles.embedFormHeaderIcon} />
              </button>
            </div>
          </div>

          <FormControl
            error={
              youtubeEmbedTouched && youtubeEmbedError
                ? t('upload-media.youtube-embed.invalid-url')
                : null
            }
          >
            <label className={styles.label}>
              {t('upload-media.youtube-embed.label')}
            </label>

            <Input
              type="text"
              placeholder={t('upload-media.youtube-embed.placeholder')}
              onChange={onEmbedChange}
            />
          </FormControl>

          <HowToGetYoutubeUrlDialog
            onClose={onHideDialog}
            isVisible={isDialogVisible}
          />
        </div>
      );
    }
  };

  return (
    <div className={styles.mediaUploadContainer}>
      {isLoading ? (
        <Spinner />
      ) : (
        <FormGroup className={styles.controlGroup}>
          <input
            type="file"
            name={field.name}
            accept="image/png, image/gif, image/jpeg"
            onChange={onUploadChange}
            className={styles.mediaUpload}
            ref={inputRef}
            hidden
          />

          <button
            className={styles.mediaUploadButton}
            onClick={onClick}
            type="button"
          >
            <span className={styles.iconContainer}>
              <IconUpload />
            </span>

            {t('upload-media.image-upload.button-text')}
          </button>

          {loadEmbedField()}
        </FormGroup>
      )}
    </div>
  );
};

export default UploadMedia;
