import {FunctionComponent} from 'react';
import styles from './styles.module.scss';

interface ElementProps {
  participants: string[];
}

const ItemsList: FunctionComponent<ElementProps> = ({participants}) => {
  return (
    <ul className={styles.list}>
      {participants.map((participant, index) => (
        <li className={styles.item} key={index}>
          {participant}
        </li>
      ))}
    </ul>
  );
};
export default ItemsList;
