import {FunctionComponent} from 'react';
import {ReactComponent as UserIcon} from '../../@components/kit/icons/user.svg';
import {useTranslation} from 'react-i18next';
import styles from './styles.module.scss';

interface ElementProps {
  score: number;
}

const UserScoreScale: FunctionComponent<ElementProps> = ({score}) => {
  const {t} = useTranslation('application', {
    keyPrefix: 'components.user-score-scale',
  });

  return (
    <div className={styles.container}>
      <div
        className={styles.score}
        style={{
          left: `max(3%, calc(95% - (103% - ${score}%)))`,
        }}
      >
        <UserIcon className={styles.icon} />
      </div>

      <div className={styles.scale}>
        <div className={styles.scaleLabel}>{t('labels.very-low')}</div>
        <div className={styles.scaleLabel}>{t('labels.low')}</div>
        <div className={styles.scaleLabel}>{t('labels.medium')}</div>
        <div className={styles.scaleLabel}>{t('labels.high')}</div>
        <div className={styles.scaleLabel}>{t('labels.very-high')}</div>
      </div>
    </div>
  );
};

export default UserScoreScale;
