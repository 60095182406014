import {useTranslation} from 'react-i18next';
import styles from './styles.module.scss';
import {FunctionComponent} from 'react';

const Introduction: FunctionComponent = () => {
  const {t} = useTranslation('candidates', {
    keyPrefix: 'register.components.introduction',
  });

  return (
    <div className={styles.container}>
      <h1 className={styles.heading}>{t('heading')}</h1>
      <div>
        <div className={styles.quoteCitation}>{t('quote')}</div>
        <div className={styles.quoteAuthor}>{t('author')}</div>
      </div>
    </div>
  );
};

export default Introduction;
