import {
  HexacoProfile,
  HexacoProfileRaw,
  HexacoScore,
  HexacoScoreRaw,
  PersonaContent,
  PersonaContentRaw,
} from '../../interfaces/hexaco-profile';

export const sanitizeHexacoProfileFromRaw = (
  data: HexacoProfileRaw
): HexacoProfile => {
  return {
    ...sanitizeHexacoScoreFromRaw(data),
    persona: data.persona,
    personaContent: sanitizePersonaContentFromRaw(
      data.persona_content,
      data.persona
    ),
  };
};

export const sanitizeHexacoScoreFromRaw = (
  data: HexacoScoreRaw
): HexacoScore => {
  return {
    agreeableness: data.agreeableness,
    conscientiousness: data.conscientiousness,
    emotionality: data.emotionality,
    extroversion: data.extroversion,
    honesty: data.honesty,
    openness: data.openness,
  };
};

export const sanitizePersonaContentFromRaw = (
  data: PersonaContentRaw,
  personaName: string
): PersonaContent => {
  return {
    name: personaName,
    deepDive: {
      content: data.deep_dive.content,
      headline: data.deep_dive.headline,
    },
    wellFunctioningGroup: {
      content: data.when_in_a_well_functioning_group,
    },
    outOfSync: {
      content: data.when_out_of_sync,
    },
    description: data.description,
    summary: data.summary,
    traits: data.traits,
  };
};
