import {MeetingFeedback} from '../../../interfaces/meeting';
import {
  sanitizeMeetingFeedbackForRaw,
  sanitizeMeetingFeedbackFromRaw,
} from '../../../ui/@sanitizers/meeting';
import isResponseSuccessful from '../../../utils/is-response-successful';
import InstillAPI from '../axios-instance';

export interface UpdateMeetingFeedbackPayload {
  companyUuid: string;
  payload: MeetingFeedback;
  meetingUuid: string;
}

export async function updateMeetingFeedback(
  companyUuid: string,
  payload: MeetingFeedback,
  meetingUuid: string
) {
  const sanitizedPayload = sanitizeMeetingFeedbackForRaw(payload);

  const response = await InstillAPI.put(
    `/companies/${companyUuid}/meetings/${meetingUuid}/feedback`,
    sanitizedPayload
  );

  if (!isResponseSuccessful(response)) {
    throw Error('No data received');
  }

  return sanitizeMeetingFeedbackFromRaw(response.data);
}
