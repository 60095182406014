import {Company} from '../../../../../../../interfaces/company';
import {Field, FieldProps} from 'formik';
import {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import ImageUpload from '../../../../../../@components/kit/form/image-upload';

interface ElementProps {
  onCompanyLogoChange: (
    companyLogo: File,
    form: FieldProps['form'],
    field: FieldProps['field']
  ) => void;
  company?: Company;
}

const UpsertLogoForm: FunctionComponent<ElementProps> = ({
  onCompanyLogoChange,
  company,
}) => {
  const {t} = useTranslation('adminPortal');

  return (
    <>
      <Field name="companyLogo">
        {({field, form}: FieldProps<string>) => (
          <>
            <ImageUpload
              label={t(
                'components.company-list.components.upsert-logo-form.company-logo'
              )}
              fileCallback={(file) => {
                onCompanyLogoChange(file, form, field);
              }}
              pictureUrl={company?.companyLogo}
            />
          </>
        )}
      </Field>
    </>
  );
};

export default UpsertLogoForm;
