import {CompanyCreate} from '../../../interfaces/company';
import {
  sanitizeCompanyCreateForRaw,
  sanitizeCompanyFromRaw,
} from '../../../ui/@sanitizers/company';
import InstillAPI from '../axios-instance';

export interface CreateCompanyPayload {
  companyPayload: CompanyCreate;
}

export async function createCompany(companyPayload: CompanyCreate) {
  const sanitizedCompany = sanitizeCompanyCreateForRaw(companyPayload);
  const response = await InstillAPI.post('/companies', sanitizedCompany);

  const company = sanitizeCompanyFromRaw(response.data);

  return {
    ...company,
    error: null,
  };
}
