import {TeamUpsert} from '../../../interfaces/team';
import {
  sanitizeCreateTeamForRaw,
  sanitizeTeamFromRaw,
} from '../../../ui/@sanitizers/teams';
import InstillAPI from '../axios-instance';

export interface CreateTeamPayload {
  companyUuid: string;
  payload: TeamUpsert;
}

export const createTeam = async (parameters: CreateTeamPayload) => {
  const {companyUuid, payload} = parameters;
  const sanitizedPayload = sanitizeCreateTeamForRaw(payload);

  const response = await InstillAPI.post(
    `/companies/${companyUuid}/teams`,
    sanitizedPayload
  );

  return sanitizeTeamFromRaw(response.data);
};
