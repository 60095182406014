import {FunctionComponent} from 'react';
import {Navigate, Route, Routes} from 'react-router';
import Meetings from './meetings';

const Sense: FunctionComponent = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate replace to="meetings" />} />
        <Route path="/meetings/*" element={<Meetings />} />
        <Route path="*" element={<Navigate replace to="meetings" />} />
      </Routes>
    </>
  );
};

export default Sense;
