export enum COMPANIES_SORTABLE_COLUMNS {
  NAME = 'name',
  ACTIVE_USERS = 'active_users',
  PENDING_INVITES = 'pending_invites',
}

export enum CANDIDATES_SORTABLE_COLUMNS {
  ASSESSMENT_STATUS = 'assessment_status',
  CANDIDATE_NAME = 'name',
  DUE_DATE = 'due_date',
  JOB_POSITION_TITLE = 'job_position_title',
}

export enum EMPLOYEES_SORTABLE_COLUMNS {
  NAME = 'name',
  JOB_TITLE = 'job_title',
  TEAM = 'team',
}

export enum DIRECTORY_EMPLOYEES_SORTABLE_COLUMNS {
  PERMISSION_LEVEL = 'permission_level',
  NAME = 'name',
  JOB_TITLE = 'job_title',
  TEAM = 'team',
  IS_ACTIVE = 'is_active',
  JOINED_DATE = 'joined_date',
}

export enum ACTION_STEP_STATUS_SORTABLE_COLUMNS {
  NAME = 'name',
  PENDING_ACTION_STEPS = 'pending_action_steps',
  COMPLETED_ACTION_STEPS = 'completed_action_steps',
}

export enum TEAMS_SORTABLE_COLUMNS {
  NAME = 'name',
  MANAGER_NAME = 'manager_name',
  TEAM_SIZE = 'team_size',
}

export enum MEETINGS_SORTABLE_COLUMNS {
  NAME = 'name',
  START_DATE = 'start_date',
  DURATION = 'duration',
}

export const SUPPORTED_SORTABLE_COLUMNS = {
  CANDIDATES: [
    CANDIDATES_SORTABLE_COLUMNS.ASSESSMENT_STATUS,
    CANDIDATES_SORTABLE_COLUMNS.CANDIDATE_NAME,
    CANDIDATES_SORTABLE_COLUMNS.DUE_DATE,
    CANDIDATES_SORTABLE_COLUMNS.JOB_POSITION_TITLE,
  ],
  COMPANIES: [
    COMPANIES_SORTABLE_COLUMNS.NAME,
    COMPANIES_SORTABLE_COLUMNS.ACTIVE_USERS,
    COMPANIES_SORTABLE_COLUMNS.PENDING_INVITES,
  ],
  DIRECTORY_EMPLOYEES: [
    DIRECTORY_EMPLOYEES_SORTABLE_COLUMNS.PERMISSION_LEVEL,
    DIRECTORY_EMPLOYEES_SORTABLE_COLUMNS.NAME,
    DIRECTORY_EMPLOYEES_SORTABLE_COLUMNS.JOB_TITLE,
    DIRECTORY_EMPLOYEES_SORTABLE_COLUMNS.TEAM,
    DIRECTORY_EMPLOYEES_SORTABLE_COLUMNS.IS_ACTIVE,
    DIRECTORY_EMPLOYEES_SORTABLE_COLUMNS.JOINED_DATE,
  ],
  EMPLOYEES: [
    EMPLOYEES_SORTABLE_COLUMNS.NAME,
    EMPLOYEES_SORTABLE_COLUMNS.JOB_TITLE,
    EMPLOYEES_SORTABLE_COLUMNS.TEAM,
  ],
  ACTION_STEP_STATUS: [
    ACTION_STEP_STATUS_SORTABLE_COLUMNS.NAME,
    ACTION_STEP_STATUS_SORTABLE_COLUMNS.PENDING_ACTION_STEPS,
    ACTION_STEP_STATUS_SORTABLE_COLUMNS.COMPLETED_ACTION_STEPS,
  ],
  MEETINGS: [
    MEETINGS_SORTABLE_COLUMNS.NAME,
    MEETINGS_SORTABLE_COLUMNS.START_DATE,
    MEETINGS_SORTABLE_COLUMNS.DURATION,
  ],
  TEAMS: [
    TEAMS_SORTABLE_COLUMNS.MANAGER_NAME,
    TEAMS_SORTABLE_COLUMNS.TEAM_SIZE,
    TEAMS_SORTABLE_COLUMNS.NAME,
  ],
};
