import {buildPageTitle} from '../../../../../services/build-page-title';
import {EventName} from '../../../../../constants/analytics/event-name';
import {EventType} from '../../../../../constants/analytics/event-type';
import {FunctionComponent, useEffect} from 'react';
import {Helmet} from 'react-helmet-async';
import {useCompanyUserProfile} from '../../../../@hooks/queries';
import {useNavigate, useParams} from 'react-router';
import {useSafeCurrentCompany} from '../../../../@atoms/current-company';
import {useTranslation} from 'react-i18next';
import mainContainerStyles from '../../../../../styles/classes/main-container.module.scss';
import ProfileDetails from '../../../@components/profile-details';
import RoundedCard from '../../../../@components/rounded-card';
import Spinner from '../../../../@components/spinner';
import useAnalytics from '../../../../@hooks/use-analytics';

const EmployeesShow: FunctionComponent = () => {
  const {t} = useTranslation('applicationPageTitle');
  const {id: employeeUuid} = useParams<string>();

  const {trackEvent} = useAnalytics();
  const currentCompany = useSafeCurrentCompany();
  const navigate = useNavigate();

  const employee = useCompanyUserProfile({
    enabled: Boolean(employeeUuid),
    variables: {
      companyUuid: currentCompany.uuid,
      userProfileUuid: employeeUuid,
    },
    onError() {
      navigate('/application/people/employees/');
    },
  });

  useEffect(() => {
    trackEvent({
      eventName: EventName.PAGE_VIEWS.PEOPLE.EMPLOYEES.SHOW,
      eventType: EventType.PAGE_VIEW,
    });
  }, [trackEvent]);

  return (
    <>
      <Helmet>
        <title>
          {buildPageTitle([
            t('page-title.people.employees.show', {ns: 'applicationPageTitle'}),
            t('page-title.people.index', {ns: 'applicationPageTitle'}),
          ])}
        </title>
      </Helmet>

      <main className={mainContainerStyles.container}>
        <RoundedCard>
          {employee.isLoading ? (
            <Spinner />
          ) : (
            <>{employee.data && <ProfileDetails profile={employee.data} />}</>
          )}
        </RoundedCard>
      </main>
    </>
  );
};

export default EmployeesShow;
