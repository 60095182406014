import {
  ActionStepSentPayload,
  ActionStepSentRawPayload,
} from './notifications/action-step-sent';
import {
  InvitedToAssessmentPayload,
  InvitedToAssessmentRawPayload,
} from './notifications/invite-to-assessment';
import {
  JobPositionEndedPayload,
  JobPositionEndedRawPayload,
} from './notifications/job-position-ended';
import {
  KudoReceivedPayload,
  KudoReceivedRawPayload,
} from './notifications/kudo';

export enum NOTIFICATION_EVENT {
  ACTION_STEP_SENT = 'action_step_sent',
  INVITED_TO_ASSESSMENT = 'invited_to_assessment',
  JOB_POSITION_ENDED = 'job_position_ended',
  KUDO_RECEIVED = 'kudo_received',
}

export interface LiveNotification {
  event: NOTIFICATION_EVENT;
  payload: unknown;
}

export interface LiveNotificationRaw {
  event: NOTIFICATION_EVENT;
  payload: NotificationRaw;
}

export interface KudoReceivedNotification extends LiveNotification {
  event: NOTIFICATION_EVENT.KUDO_RECEIVED;
  payload: KudoReceivedPayload;
}

export interface InvitedToAssessmentNotification extends LiveNotification {
  event: NOTIFICATION_EVENT.INVITED_TO_ASSESSMENT;
  payload: InvitedToAssessmentPayload;
}

export interface JobPositionEndedNotification extends LiveNotification {
  event: NOTIFICATION_EVENT.JOB_POSITION_ENDED;
  payload: JobPositionEndedPayload;
}

export interface ActionStepSentNotification extends LiveNotification {
  event: NOTIFICATION_EVENT.ACTION_STEP_SENT;
  payload: ActionStepSentPayload;
}

export type SupportedNotifications =
  | KudoReceivedNotification
  | InvitedToAssessmentNotification
  | JobPositionEndedNotification
  | ActionStepSentNotification;

export interface Notification {
  createdAt: string;
  event: NOTIFICATION_EVENT;
  payload:
    | KudoReceivedPayload
    | InvitedToAssessmentPayload
    | JobPositionEndedPayload
    | ActionStepSentPayload;
  readAt: string;
  uuid: string;
}

export interface NotificationRaw {
  created_at: string;
  event: NOTIFICATION_EVENT;
  payload:
    | KudoReceivedRawPayload
    | InvitedToAssessmentRawPayload
    | JobPositionEndedRawPayload
    | ActionStepSentRawPayload;
  read_at: string;
  uuid: string;
}

export interface MarkNotificationsAsReadRawPayload {
  notification_uuids: string[];
}
