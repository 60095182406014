import {Transcript, TranscriptRaw} from '../../interfaces/transcript';

export const sanitizeTranscriptFromRaw = (
  data: TranscriptRaw[]
): Transcript[] => {
  return data.map((word) => sanitizeWordFromRaw(word));
};

const sanitizeWordFromRaw = (data: TranscriptRaw): Transcript => {
  return {
    text: data.text,
    start: data.start,
    end: data.end,
    speaker: data.speaker,
  };
};
