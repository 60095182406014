import {FunctionComponent} from 'react';
import {ReactComponent as IconCloseButton} from '../../../../../@components/kit/icons/close.svg';
import styles from './styles.module.scss';

interface ElementProps {
  list: string[];
  handleRemoveBehavior: (itemIndex: number) => void;
}

const BehaviourList: FunctionComponent<ElementProps> = ({
  list,
  handleRemoveBehavior,
}) => {
  return (
    <ul className={styles.list}>
      {list.map((behaviour, itemIndex) => (
        <li className={styles.item} key={itemIndex}>
          <div className={styles.text}>{behaviour}</div>

          <button
            type="button"
            className={styles.iconContainer}
            onClick={() => handleRemoveBehavior(itemIndex)}
          >
            <IconCloseButton className={styles.icon} />
          </button>
        </li>
      ))}
    </ul>
  );
};

export default BehaviourList;
