import {FieldProps} from 'formik';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
  ActionMeta,
  GroupBase,
  OnChangeValue,
  OptionsOrGroups,
} from 'react-select';
import CreatableSelect from 'react-select/creatable';
import {useFormControlContext} from '../../form-control';
import {selectStyles, selectTheme} from '../styles.module';
import styles from './styles.module.scss';

export interface Option {
  label: string;
  value: string;
  payload?: any;
  __isNew__?: boolean;
}

interface MutableSelectProps extends FieldProps {
  className?: string;
  createLabel?: string;
  defaultValue?: Option | Option[];
  disabled?: boolean;
  hasError?: boolean;
  isMulti?: boolean;
  onBlurCapture?: () => void;
  options: OptionsOrGroups<Option, GroupBase<any>>;
  onChangeCallback?: (value: Option, actionMeta: ActionMeta<Option>) => void;
  onMultiChangeCallback?: (
    value: Option[],
    actionMeta: ActionMeta<Option>
  ) => void;
  placeholder?: string;
  renderValues?: boolean;
}

export const MutableSelect = ({
  className,
  placeholder,
  field,
  form,
  options,
  defaultValue,
  createLabel,
  isMulti = false,
  disabled = false,
  renderValues = true,
  onChangeCallback,
  onMultiChangeCallback,
  onBlurCapture,
}: MutableSelectProps) => {
  const {hasError} = useFormControlContext();
  const {t} = useTranslation('components');
  const label = createLabel ? createLabel : t('hybrid-select.default-label');

  const [selectedValues, setSelectedValues] = useState<Option | Option[]>(
    defaultValue || []
  );

  const onChange = (
    option: OnChangeValue<Option | Option[], typeof isMulti>,
    actionMeta: ActionMeta<Option>
  ) => {
    if (!isMulti) {
      onChangeCallback && onChangeCallback(option as Option, actionMeta);
    } else {
      onMultiChangeCallback &&
        onMultiChangeCallback(option as Option[], actionMeta);
    }

    setSelectedValues(isMulti ? (option as Option[]) : (option as Option));

    form.setFieldValue(
      field.name,
      isMulti
        ? (option as Option[]).map((item: Option) => item.value)
        : (option as Option).value
    );
  };

  useEffect(() => {
    if (isMulti) {
      if ((selectedValues as Option[]).length !== field.value.length) {
        const newSelectedValues = (selectedValues as Option[]).filter(
          (item: Option) => field.value.includes(item.value)
        );
        setSelectedValues(newSelectedValues);
      }
    }
  }, [field.value, isMulti, selectedValues]);

  return (
    <CreatableSelect
      className={`${className ?? ''} ${styles.select}`}
      controlShouldRenderValue={renderValues}
      formatCreateLabel={(inputtext: string) => `${label} "${inputtext}"`}
      hideSelectedOptions={true}
      isClearable={false}
      isDisabled={disabled}
      isMulti={isMulti}
      menuPlacement="auto"
      name={field.name}
      onBlur={() => {
        onBlurCapture && onBlurCapture();
        form.setFieldTouched(field.name);
      }}
      onChange={onChange}
      options={options}
      placeholder={placeholder}
      styles={selectStyles(hasError)}
      theme={selectTheme}
      value={selectedValues}
    />
  );
};

export default MutableSelect;
