import {
  Expectation,
  ExpectationForm,
  ExpectationFormToRaw,
  ExpectationRaw,
} from '../../interfaces/company-core-value';

export const sanitizeExpectationsFromRaw = (
  data: ExpectationRaw[]
): Expectation[] => {
  return data.map((item: ExpectationRaw) => {
    return {
      id: item.id,
      level: item.level,
      normsAndBehaviour: item.norms_and_behaviour,
    };
  });
};

export const sanitizeExpectationsFormToRaw = (data: ExpectationForm) => {
  let expectations: ExpectationFormToRaw[] = [];

  expectations = [
    ...expectations,
    ...data.exceeded.map((item) => ({
      level: 2,
      norms_and_behaviour: [item],
    })),
    ...data.met.map((item) => ({
      level: 1,
      norms_and_behaviour: [item],
    })),
    ...data.missed.map((item) => ({
      level: 0,
      norms_and_behaviour: [item],
    })),
  ];

  return expectations;
};

export const sanitizeExpectationsToExpectationsForm = (data: Expectation[]) => {
  let expectations: ExpectationForm = {
    exceeded: [],
    met: [],
    missed: [],
  };

  data.forEach((item: Expectation) => {
    if (item.level === 2) {
      expectations.exceeded.push(item.normsAndBehaviour[0]);
    } else if (item.level === 1) {
      expectations.met.push(item.normsAndBehaviour[0]);
    } else if (item.level === 0) {
      expectations.missed.push(item.normsAndBehaviour[0]);
    }
  });

  return expectations;
};
