import {createKudo} from '../../../repositories/instill';
import {CreateKudoPayload} from '../../../repositories/instill/mutations/create-kudo';
import {createMutation} from 'react-query-kit';
import {Kudo} from '../../../interfaces/kudo';

export const useCreateKudo = createMutation<
  Kudo | undefined,
  CreateKudoPayload
>({
  mutationFn: async (variables) => {
    return await createKudo(variables.companyUuid, variables.payload);
  },
});
