import {ColumnVariant} from '../data';
import {FunctionComponent, PropsWithChildren} from 'react';
import columnStyles from '../data/styles.module.scss';
import styles from './styles.module.scss';

interface ElementProps extends ColumnVariant {
  className?: string;
}

const Header: FunctionComponent<PropsWithChildren<ElementProps>> = ({
  children,
  variant,
  ...props
}) => {
  const variantClass = variant ? columnStyles[variant] : '';

  return (
    <th
      className={`${styles.heading} ${columnStyles.column} ${variantClass} ${
        props.className ?? ''
      }`}
    >
      {children}
    </th>
  );
};

export default Header;
