import {FunctionComponent, useMemo} from 'react';
import {intervalToDuration, formatDuration} from 'date-fns';
import {Module} from '../../../../../../../../interfaces/module';
import {useTranslation} from 'react-i18next';
import styles from './styles.module.scss';
import Tag from '../../../../../../../@components/tag';

interface ElementProps {
  modules: Module[];
  endDate: string;
  completedModulesUuid: string[];
}

const Modules: FunctionComponent<ElementProps> = ({
  modules,
  endDate,
  completedModulesUuid,
}) => {
  const {t} = useTranslation('measure');

  const isModuleCompleted = useMemo(() => {
    return completedModulesUuid.includes(modules[0].uuid);
  }, [completedModulesUuid, modules]);

  const fullDuration = intervalToDuration({
    start: new Date(),
    end: new Date(endDate),
  });

  const moduleExpiry = formatDuration(fullDuration, {
    format: ['years', 'months', 'days'],
  });

  const isExpiryTagVisible = (fullDuration.years ?? 0) < 2;

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          {t('your-assessment.components.assessment.modules.header', {
            totalModuleCount: modules.length,
          })}
        </div>

        <div className={styles.module}>
          <div className={styles.title}>{modules[0].title}</div>

          <div className={styles.tags}>
            <Tag variant="light-purple">
              {t('your-assessment.components.assessment.modules.module-rank', {
                moduleRank: modules[0].rank,
                totalModuleCount: modules.length,
              })}
            </Tag>

            {modules[0].estimatedDuration && (
              <Tag>
                {t(
                  'your-assessment.components.assessment.modules.module-duration',
                  {moduleDuration: modules[0].estimatedDuration}
                )}
              </Tag>
            )}

            {isModuleCompleted ? (
              <Tag variant="light-green">
                {t(
                  'your-assessment.components.assessment.modules.module-completed'
                )}
              </Tag>
            ) : (
              <>
                {isExpiryTagVisible && (
                  <Tag variant="light-blue">
                    {t(
                      'your-assessment.components.assessment.modules.module-expiry',
                      {moduleExpiry: moduleExpiry}
                    )}
                  </Tag>
                )}
              </>
            )}
          </div>

          <div className={styles.description}>{modules[0].description}</div>
        </div>
      </div>
    </>
  );
};

export default Modules;
