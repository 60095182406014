import {Dimension} from '../../../../../../../../../../interfaces/dimension';
import {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import Item from './@components/item';
import styles from './styles.module.scss';

interface ElementProps {
  dimensions: Dimension[];
  onDimensionsChange: (dimensions: Dimension) => void;
  selectedDimensions?: Dimension[];
}

const List: FunctionComponent<ElementProps> = ({
  dimensions,
  onDimensionsChange,
  selectedDimensions,
}) => {
  const {t} = useTranslation('measure');

  const isListDisabled = selectedDimensions!.length <= 1;

  return (
    <div>
      {isListDisabled && (
        <div className={styles.errorContainer}>
          {t(
            'overall-health.components.filters.components.dimensions.components.list.at-least-one-dimension-selected-error-text'
          )}
        </div>
      )}

      <ul>
        {dimensions.map((dimension, index) => (
          <li key={index} className={styles.item}>
            <Item
              dimension={dimension}
              isListDisabled={isListDisabled}
              onDimensionsChange={onDimensionsChange}
              selectedDimensions={selectedDimensions}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default List;
