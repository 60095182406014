import {createMutation} from 'react-query-kit';
import {
  FetchPresignedUploadURLPayload,
  fetchPresignedUploadURL,
} from '../../../repositories/instill/queries/fetch-presigned-url-config';
import {PresignedUploadURLConfig} from '../../../interfaces/presigned-upload-url-config';

export const useFetchPresignedUploadUrl = createMutation<
  PresignedUploadURLConfig | undefined,
  FetchPresignedUploadURLPayload
>({
  mutationFn: async (variables) => {
    return fetchPresignedUploadURL(
      variables.ressource,
      {mime: variables.mime, extension: variables.extension},
      variables.companyUuid ? {companyUuid: variables.companyUuid} : undefined
    );
  },
});
