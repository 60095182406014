import {Candidate} from '../../../../../../../../interfaces/candidate';
import {CANDIDATES_SORTABLE_COLUMNS} from '../../../../../../../../constants/filtering/sort-by';
import {DEFAULT_TOASTIFY_CONFIGURATION} from '../../../../../../../../constants/toastify-configuration';
import {EventName} from '../../../../../../../../constants/analytics/event-name';
import {EventType} from '../../../../../../../../constants/analytics/event-type';
import {FunctionComponent, useCallback, useState} from 'react';
import {JobPosition} from '../../../../../../../../interfaces/job-position';
import {Option} from '../../../../../../../../interfaces/dropdown-options';
import {SortedColumn} from '../../../../../../../../interfaces/filtering/sorted-column';
import {ToastOptions, toast} from 'react-toastify';
import {useCandidateAlignmentResult} from '../../../../../../../@hooks/queries/use-candidate-alignment-result';
import {useSafeCurrentCompany} from '../../../../../../../@atoms/current-company';
import {useTranslation} from 'react-i18next';
import {useUpdateCandidate} from '../../../../../../../@hooks/mutations';
import CandidateResultDialog from './@components/candidate-result-dialog';
import EditDialog, {EditCandidateFormProps} from './@components/edit-dialog';
import SortButton from '../../../../../../@components/sort-button';
import styles from './styles.module.scss';
import TableRow from './@components/table-row';
import useAnalytics from '../../../../../../../@hooks/use-analytics';

interface ElementProps {
  activeSortedColumn: SortedColumn | null;
  candidates: Candidate[];
  jobPositionOptions?: Option[];
  jobPositions: JobPosition[];
  onColumnClicked: (column: SortedColumn) => void;
  onRefreshCandidates: () => void;
}

const ListView: FunctionComponent<ElementProps> = ({
  activeSortedColumn,
  candidates,
  jobPositionOptions,
  jobPositions,
  onColumnClicked,
  onRefreshCandidates,
}) => {
  const {t} = useTranslation('jobs', {
    keyPrefix:
      'candidates.components.candidate-list.components.list-view.table',
  });

  const {trackEvent} = useAnalytics();
  const currentCompany = useSafeCurrentCompany();

  const [isCandidateResultDialogVisible, setIsCandidateResultDialogVisible] =
    useState(false);
  const [isEditDialogVisible, setIsEditDialogVisible] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState<Candidate>();

  const candidateAlignmentResult = useCandidateAlignmentResult({
    enabled: isCandidateResultDialogVisible,
    variables: {
      candidateUuid: selectedCandidate?.uuid,
    },
  });
  const updateCandidate = useUpdateCandidate();

  const onCloseEditDialog = useCallback(() => {
    setIsEditDialogVisible(false);
    setSelectedCandidate(undefined);

    trackEvent({
      eventName: EventName.JOBS.CANDIDATES.EDIT_CANDIDATE_DIALOG.CLOSE_DIALOG,
      eventType: EventType.BUTTON_CLICKED,
    });
  }, [setIsEditDialogVisible, trackEvent]);

  const onEditButtonClicked = useCallback(
    (candidate: Candidate) => {
      setIsEditDialogVisible(true);
      setSelectedCandidate(candidate);

      trackEvent({
        eventName: EventName.JOBS.CANDIDATES.EDIT_CANDIDATE,
        eventType: EventType.BUTTON_CLICKED,
      });
    },
    [setIsEditDialogVisible, trackEvent]
  );

  const onFormSubmit = useCallback(
    async (values: EditCandidateFormProps) => {
      if (!selectedCandidate) return;

      const response = await updateCandidate.mutateAsync({
        companyUuid: currentCompany.uuid,
        candidateUuid: selectedCandidate.uuid,
        payload: {
          ...values,
        },
      });

      if (!response) return;

      onCloseEditDialog();

      const feedbackMessage = t('feedback-messages.candidate-updated');
      toast.success(feedbackMessage, {
        ...(DEFAULT_TOASTIFY_CONFIGURATION as ToastOptions),
      });

      trackEvent({
        eventName:
          EventName.JOBS.CANDIDATES.EDIT_CANDIDATE_DIALOG.UPDATE_CANDIDATE,
        eventType: EventType.BUTTON_CLICKED,
      });

      onRefreshCandidates();
    },
    [
      currentCompany,
      onCloseEditDialog,
      onRefreshCandidates,
      selectedCandidate,
      t,
      trackEvent,
      updateCandidate,
    ]
  );

  const onCandidateNameClicked = useCallback(
    async (candidate: Candidate) => {
      setIsCandidateResultDialogVisible(true);
      setSelectedCandidate(candidate);

      trackEvent({
        eventName:
          EventName.JOBS.CANDIDATES.CANDIDATE_RESULT_DIALOG.OPEN_DIALOG,
        eventType: EventType.BUTTON_CLICKED,
      });
    },
    [trackEvent]
  );

  const onCloseCandidateResultDialog = useCallback(() => {
    setIsCandidateResultDialogVisible(false);
    setSelectedCandidate(undefined);
  }, []);

  return (
    <>
      <div>
        <div className={styles.tableHeader}>
          <div className={`${styles.tableData} ${styles.jobPositionColumn}`}>
            <SortButton
              currentColumnName={CANDIDATES_SORTABLE_COLUMNS.JOB_POSITION_TITLE}
              currentColumnLabel={t('header.job-position')}
              onColumnClicked={onColumnClicked}
              activeSortedColumn={activeSortedColumn}
            />
          </div>

          <div className={`${styles.tableData} ${styles.nameColumn}`}>
            <SortButton
              currentColumnName={CANDIDATES_SORTABLE_COLUMNS.CANDIDATE_NAME}
              currentColumnLabel={t('header.candidate-name')}
              onColumnClicked={onColumnClicked}
              activeSortedColumn={activeSortedColumn}
            />
          </div>

          <div
            className={`${styles.tableData} ${styles.assessmentStatusColumn}`}
          >
            <SortButton
              currentColumnName={CANDIDATES_SORTABLE_COLUMNS.ASSESSMENT_STATUS}
              currentColumnLabel={t('header.assessment-status')}
              onColumnClicked={onColumnClicked}
              activeSortedColumn={activeSortedColumn}
            />
          </div>

          <div className={`${styles.tableData} ${styles.dueDateColumn}`}>
            <SortButton
              currentColumnName={CANDIDATES_SORTABLE_COLUMNS.DUE_DATE}
              currentColumnLabel={t('header.due-date')}
              onColumnClicked={onColumnClicked}
              activeSortedColumn={activeSortedColumn}
            />
          </div>

          <div className={`${styles.tableData} ${styles.alignmentScoreColumn}`}>
            {t('header.alignment-score')}
          </div>

          <div className={`${styles.tableData} ${styles.actionColumn}`}>
            &nbsp;
          </div>
        </div>

        <ul className={styles.content}>
          {candidates.map((candidate) => (
            <TableRow
              candidate={candidate}
              jobPositions={jobPositions}
              key={`${candidate.name}–${crypto.randomUUID()}`}
              onCandidateNameClicked={onCandidateNameClicked}
              onEditButtonClicked={onEditButtonClicked}
            />
          ))}
        </ul>
      </div>

      {selectedCandidate && jobPositionOptions && (
        <EditDialog
          candidate={selectedCandidate}
          isVisible={isEditDialogVisible}
          jobPositionOptions={jobPositionOptions}
          onClose={onCloseEditDialog}
          onSubmit={onFormSubmit}
        />
      )}

      {candidateAlignmentResult.data && selectedCandidate && (
        <CandidateResultDialog
          candidate={selectedCandidate}
          candidateAlignmentResult={candidateAlignmentResult.data}
          isVisible={isCandidateResultDialogVisible}
          onClose={onCloseCandidateResultDialog}
        />
      )}
    </>
  );
};

export default ListView;
