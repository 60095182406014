import {createQuery} from 'react-query-kit';
import {fetchMeeting} from '../../../repositories/instill';
import {FetchMeetingPayload} from '../../../repositories/instill/queries/fetch-meeting';
import {Meeting} from '../../../interfaces/meeting';

export const useMeeting = createQuery<Meeting | undefined, FetchMeetingPayload>(
  {
    primaryKey: 'meeting',
    queryFn: async ({queryKey: [_, variables]}) => {
      if (!variables.meetingUuid) {
        return Promise.reject();
      }

      return fetchMeeting({
        companyUuid: variables.companyUuid,
        meetingUuid: variables.meetingUuid,
      });
    },
  }
);
