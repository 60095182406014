import {Actions} from '../../../../../../../../constants/permissions/actions';
import {CircularProgressbar, buildStyles} from 'react-circular-progressbar';
import {Dimension} from '../../../../../../../../interfaces/dimension';
import {DimensionHealth} from '../../../../../../../../interfaces/organizational-health';
import {FunctionComponent, useCallback, useMemo} from 'react';
import {getUserProfileForCompany} from '../../../../../../../../services/user-profiles';
import {Link} from 'react-router-dom';
import {Tooltip} from 'react-tooltip';
import {useCurrentAuthUserState} from '../../../../../../../@atoms/current-auth-user';
import {useSafeCurrentCompany} from '../../../../../../../@atoms/current-company';
import {useTranslation} from 'react-i18next';
import {useUserPermission} from '../../../../../../../@hooks/use-user-permission';
import ActionStepPercentage from './@components/action-step-percentage';
import LinkItem from './@components/link-item';
import skeletonImageSource from './assets/skeleton-dimension.png';
import styles from './styles.module.scss';
import Trend from './@components/trend';
import {CULTURE_VITAL_SIGN_COLORS} from '../../../../../../../../constants/culture-vital-signs';

const DEFAULT_TRAIL_COLOR = '#f5f5f5';
const TOOLTIP_IDENTIFIER = 'action-step-percentage-tooltip';

interface ElementProps {
  dimensionFromHealthData: DimensionHealth;
  dimensions: Dimension[];
  highlightedDimensionSlug: string | null;
  onDimensionClicked: (dimensionSlug: string) => void;
}

const Item: FunctionComponent<ElementProps> = ({
  dimensions,
  dimensionFromHealthData,
  highlightedDimensionSlug,
  onDimensionClicked,
}) => {
  const {t} = useTranslation('measure');

  const [currentAuthUser] = useCurrentAuthUserState();
  const currentCompany = useSafeCurrentCompany();

  const userProfile = getUserProfileForCompany(
    currentAuthUser!,
    currentCompany
  );

  const can = useUserPermission(userProfile.role);

  const isAllowedToActionStepStatus = can(Actions.VIEW_ACTION_STEP_STATUS);
  const isAllowedToManageAssessments = can(Actions.VIEW_MANAGE_ASSESSMENTS);

  const percentage = useMemo(
    () => parseFloat(dimensionFromHealthData.currentAverage.toFixed(2)),
    [dimensionFromHealthData]
  );

  const isPercentageEqualsToZero = percentage === 0;

  const isDimensionConfigured = useMemo(() => {
    const dimension = dimensions.find(
      (dimension) => dimension.slug === dimensionFromHealthData.dimensionSlug
    );

    return dimension?.isConfigured ?? false;
  }, [dimensions, dimensionFromHealthData]);

  const pathColor =
    CULTURE_VITAL_SIGN_COLORS[
      dimensionFromHealthData.dimensionSlug as keyof typeof CULTURE_VITAL_SIGN_COLORS
    ].NORMAL;

  const isDatasetEmpty = useMemo(() => {
    return !dimensionFromHealthData.dataset.length;
  }, [dimensionFromHealthData]);

  const isSkeletonViewVisible = useMemo(() => {
    return !isDimensionConfigured || isDatasetEmpty;
  }, [isDimensionConfigured, isDatasetEmpty]);

  const message = useMemo(() => {
    if (!isDimensionConfigured) {
      return t(
        'overall-health.components.health-gauges.components.item.manage-assessment'
      );
    }

    if (isDatasetEmpty) {
      return t(
        'overall-health.components.health-gauges.components.item.no-report-available'
      );
    }
  }, [isDimensionConfigured, isDatasetEmpty, t]);

  const onClick = useCallback(() => {
    if (isDatasetEmpty) return;

    onDimensionClicked(dimensionFromHealthData.dimensionSlug);
  }, [isDatasetEmpty, onDimensionClicked, dimensionFromHealthData]);

  return (
    <LinkItem
      dimension={dimensionFromHealthData}
      highlightedDimensionSlug={highlightedDimensionSlug}
      isAllowedToManageAssessments={isAllowedToManageAssessments}
      isDimensionConfigured={isDimensionConfigured}
      onClick={onClick}
    >
      <div
        className={
          isPercentageEqualsToZero ? styles.blurredTitle : styles.title
        }
      >
        {dimensionFromHealthData.dimensionName}
      </div>

      <div className={styles.content}>
        {isSkeletonViewVisible ? (
          <>
            <img
              src={skeletonImageSource}
              className={styles.skeletonImage}
              alt=""
            />

            {isAllowedToManageAssessments && message && (
              <div className={styles.disabledText}>{message}</div>
            )}
          </>
        ) : (
          <>
            <div className={styles.graphContainer}>
              <CircularProgressbar
                value={percentage}
                text={t(
                  'overall-health.components.health-gauges.components.item.percentage-label',
                  {percentage}
                )}
                className={styles.progressbar}
                strokeWidth={10}
                styles={buildStyles({
                  pathColor,
                  trailColor: DEFAULT_TRAIL_COLOR,
                })}
              />
            </div>

            <footer className={styles.footer}>
              <Trend
                trendPercentage={dimensionFromHealthData.trendingPercentage}
              />

              <div className={styles.footerColumn}>
                <div
                  data-tooltip-id={TOOLTIP_IDENTIFIER}
                  data-tooltip-content={t(
                    'overall-health.components.health-gauges.components.item.action-step-percentage.tooltip-content'
                  )}
                >
                  {isAllowedToActionStepStatus ? (
                    <Link
                      to="/application/apply/action-step-status"
                      className={styles.link}
                    >
                      <ActionStepPercentage
                        dimension={dimensionFromHealthData}
                        trendPercentage={
                          dimensionFromHealthData.trendingPercentage
                        }
                      />
                    </Link>
                  ) : (
                    <ActionStepPercentage
                      dimension={dimensionFromHealthData}
                      trendPercentage={
                        dimensionFromHealthData.trendingPercentage
                      }
                    />
                  )}

                  <Tooltip id={TOOLTIP_IDENTIFIER} />
                </div>
              </div>
            </footer>
          </>
        )}
      </div>
    </LinkItem>
  );
};

export default Item;
