import {sanitizeMarkAsReadNotificationsForRaw} from '../../../ui/@sanitizers/notification';
import InstillAPI from '../axios-instance';
import isResponseSuccessful from '../../../utils/is-response-successful';

export interface MarkNotificationsAsReadPayload {
  companyUuid: string;
  notificationUuids: string[];
}

export async function markNotificationsAsRead(
  companyUuid: string,
  notificationUuids: string[]
) {
  const sanitizedPayload =
    sanitizeMarkAsReadNotificationsForRaw(notificationUuids);

  const response = await InstillAPI.put(
    `/companies/${companyUuid}/notifications`,
    sanitizedPayload
  );

  if (!isResponseSuccessful(response)) {
    throw Error('No data received');
  }

  return response.data;
}
