import {KudoVisibilityRaw} from '../../../interfaces/kudo';
import InstillAPI from '../axios-instance';
import isResponseSuccessful from '../../../utils/is-response-successful';

export interface KudoVisibilityPayload {
  companyUuid: string;
  kudoUuid: string;
  payload: KudoVisibilityRaw;
}

export async function updateKudoVisibility(
  companyUuid: string,
  kudoUuid: string,
  payload: KudoVisibilityRaw
) {
  const response = await InstillAPI.put(
    `/companies/${companyUuid}/kudos/${kudoUuid}/visibility`,
    payload
  );

  if (!isResponseSuccessful(response)) {
    throw Error('No data received');
  }

  return response.data;
}
