import React, {FC} from 'react';

import ChatGroup from './chat-group';
import styles from './styles.module.scss';

interface Option {
  label: string;
  value: string;
}

interface SelectProps {
  name: string;
  pretext?: string;
  question: string;
  options: Option[];
  dispatch?: (action: {
    type: string;
    payload: {label: string; value: string};
  }) => void;
  state?: Record<string, any>;
  renderAnswer?: (state: any) => any;
  noScroll?: boolean;
  scrollParent?: React.RefObject<HTMLElement>;
  finaltext?: string;
  onAnswer?: (value: string) => void;
}

export const Select: FC<SelectProps> = ({
  name,
  pretext,
  question,
  options,
  dispatch,
  state,
  renderAnswer,
  noScroll,
  scrollParent,
  finaltext,
  onAnswer,
}) => {
  if (!dispatch || !state) {
    return null;
  }

  return (
    <ChatGroup
      pretext={pretext}
      question={question}
      state={state}
      name={name}
      renderAnswer={renderAnswer}
      noScroll={noScroll}
      scrollParent={scrollParent}
      finaltext={finaltext}
    >
      <div className={styles.optionContainer}>
        {!state[name] &&
          Array.isArray(options) &&
          options.map(({label, value}) => (
            <button
              key={value}
              className={styles.optionButton}
              onClick={() => {
                dispatch({
                  type: name,
                  payload: {label, value},
                });
                onAnswer && onAnswer(value);
              }}
            >
              {label}
            </button>
          ))}
      </div>
    </ChatGroup>
  );
};
