import InstillAPI from '../axios-instance';
import isResponseSuccessful from '../../../utils/is-response-successful';
import {sanitizeSingleUserAssessmentFromRaw} from '../../../ui/@sanitizers/user-assessment';

export interface FetchCandidateAssessmentPayload {
  candidateToken: string;
  candidateUuid: string;
}

export async function fetchCandidateAssessment({
  candidateToken,
  candidateUuid,
}: FetchCandidateAssessmentPayload) {
  try {
    const response = await InstillAPI.get(
      `/candidate/${candidateUuid}/user-assessment`,
      {
        headers: {
          Authorization: `Bearer ${candidateToken}`,
        },
      }
    );

    if (!response.data || !isResponseSuccessful(response)) {
      throw Error('No data received');
    }

    return sanitizeSingleUserAssessmentFromRaw(response.data);
  } catch (error) {
    throw new Error('Could not fetch candidate assessment');
  }
}
