import {createMutation} from 'react-query-kit';
import {
  CreateJobPositionPayload,
  createJobPosition,
} from '../../../repositories/instill/mutations/create-job-position';
import {JobPosition} from '../../../interfaces/job-position';

export const useCreateJobPosition = createMutation<
  JobPosition | undefined,
  CreateJobPositionPayload
>({
  mutationFn: async (variables) => {
    return await createJobPosition({...variables});
  },
});
