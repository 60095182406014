import {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import Button from '../../../../../../../../@components/kit/form/button';
import RoundedCard from '../../../../../../../../@components/rounded-card';

interface ElementProps {
  isInEditMode: boolean;
  onEditButtonClicked: () => void;
}

const Header: FunctionComponent<ElementProps> = ({
  isInEditMode,
  onEditButtonClicked,
}) => {
  const {t} = useTranslation('adminPortal');

  const titleTranslation = isInEditMode
    ? t(
        'components.company-list.components.company-profile-dialog.components.header.update-title'
      )
    : t(
        'components.company-list.components.company-profile-dialog.components.header.title'
      );

  return (
    <RoundedCard.Header>
      <RoundedCard.Title>{titleTranslation}</RoundedCard.Title>

      {!isInEditMode && (
        <Button onClick={onEditButtonClicked}>
          {t(
            'components.company-list.components.company-profile-dialog.components.header.edit-button'
          )}
        </Button>
      )}
    </RoundedCard.Header>
  );
};

export default Header;
