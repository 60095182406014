import {createQuery} from 'react-query-kit';
import {fetchWorkStyleValues} from '../../../repositories/instill/queries';
import {WorkStylePreferencesValues} from '../../../interfaces/work-style-preferences';

export const useWorkStyleValues = createQuery<
  WorkStylePreferencesValues | undefined,
  {}
>({
  primaryKey: 'workStyleValues',
  queryFn: async ({queryKey: [_, variables]}) => {
    return await fetchWorkStyleValues();
  },
});
