import {
  Company,
  CompanyRaw,
  CompanyUpsert,
  CompanyUpsertRaw,
} from '../../../interfaces/admin/company';

export const sanitizeCompaniesFromRaw = (data: CompanyRaw[]) => {
  return data.map(sanitizeCompanyFromRaw);
};

export const sanitizeCompanyFromRaw = (data: CompanyRaw): Company => {
  return {
    companyLogo: data.signed_company_logo_url,
    invitedUsersCount: data.invited_users_count,
    joinedUsersCount: data.joined_users_count,
    name: data.name,
    pricingPlan: data.pricing_plan,
    uuid: data.uuid,
  };
};

export const sanitizeCompanyCreateForRaw = (
  data: CompanyUpsert
): CompanyUpsertRaw => {
  let payload = {
    name: data.name,
    industry: data.industry,
    size_range: data.size,
    is_defined_culture: data.isDefinedCulture ?? false,
    is_signature_required_on_values: data.isSignatureRequiredOnValues ?? false,
    email_domain: data.emailDomain,
  } as CompanyUpsertRaw;

  if (data.websiteUrl) {
    payload['website_url'] = data.websiteUrl;
  }

  if (data.companyLogo) {
    payload['company_logo'] = data.companyLogo;
  }

  return payload;
};
