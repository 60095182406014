import {AuthUser} from '../../../../interfaces/auth-user';
import {Outlet} from 'react-router';
import {useAuth0} from '@auth0/auth0-react';
import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

interface RequireAuthProps {
  user: AuthUser | null;
}

function RequireAuth({user}: RequireAuthProps) {
  const {
    isLoading: auth0IsLoading,
    error: auth0Error,
    isAuthenticated,
    loginWithRedirect,
  } = useAuth0();

  const location = useLocation();

  useEffect(() => {
    if (!user) {
      loginWithRedirect({appState: {returnTo: location.pathname}});
    }
  }, [
    isAuthenticated,
    loginWithRedirect,
    auth0Error,
    auth0IsLoading,
    user,
    location,
  ]);

  if (!user) return <></>;

  return <Outlet />;
}

export default RequireAuth;
