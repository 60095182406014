import {TFunction} from 'i18next';

const EXPECTATION_MAPPING = {
  0: 'constants.expectation-levels.misses-expectations',
  1: 'constants.expectation-levels.meets-expectations',
  2: 'constants.expectation-levels.exceeds-expectations',
};

function getExpectationLabelFromNumber(
  expectationLevel: number,
  translationService: TFunction
) {
  return translationService(
    EXPECTATION_MAPPING[expectationLevel as keyof typeof EXPECTATION_MAPPING],
    {ns: 'common'}
  );
}

export default getExpectationLabelFromNumber;
