import {FunctionComponent, useState, useCallback} from 'react';
import PreviousQuestionLink from '../@components/previous-question-link';
import {useDebounce} from 'react-use';
import * as Slider from '@radix-ui/react-slider';
import styles from './styles.module.scss';

interface ElementProps {
  questionText: string;
  onResponse: (value: string) => void;
  onPreviousLinkClick?: () => void;
  answer?: string;
}

const DELAY_FOR_SLIDER_DEBOUNCE_IN_MS = 1000;
const MIN_SLIDER_VALUE = 3;
const MAX_SLIDER_VALUE = 7;

const NumericalSlider: FunctionComponent<ElementProps> = ({
  questionText,
  onResponse,
  onPreviousLinkClick,
  answer,
}) => {
  const [value, setValue] = useState<string | undefined>();

  useDebounce(
    () => {
      if (!value) return;

      onResponse(value);
    },
    DELAY_FOR_SLIDER_DEBOUNCE_IN_MS,
    [value]
  );

  const onChange = useCallback(
    (value: number[]) => {
      setValue(value[0].toString());
    },
    [setValue]
  );

  const defaultValue = value || answer || '0';

  return (
    <>
      <div className={styles.container}>
        {onPreviousLinkClick ? (
          <PreviousQuestionLink onLinkClick={onPreviousLinkClick} />
        ) : null}

        <div className={styles.question}>{questionText}</div>

        <div className={styles.sliderContainer}>
          <Slider.Root
            className={styles.slider}
            defaultValue={[Number(defaultValue)]}
            max={MAX_SLIDER_VALUE}
            step={MIN_SLIDER_VALUE}
            onValueChange={onChange}
          >
            <Slider.Track className={styles.sliderTrack}>
              <Slider.Range className={styles.sliderRange} />
            </Slider.Track>
            <Slider.Thumb className={styles.sliderThumb} />
          </Slider.Root>
          <div className={styles.sliderValue}>{defaultValue}</div>
        </div>
      </div>
    </>
  );
};

export default NumericalSlider;
