import {
  ChangeEvent,
  FormEvent,
  FunctionComponent,
  useCallback,
  useState,
} from 'react';
import {
  COMPANIES_SORTABLE_COLUMNS,
  SUPPORTED_SORTABLE_COLUMNS,
} from '../../../../../constants/filtering/sort-by';
import {CompanyUpsert} from '../../../../../interfaces/admin/company';
import {CreateCompanyPayload} from '../../../../../repositories/instill/mutations/admin/create-company';
import {DEFAULT_NUMBER_OF_ITEMS_PER_PAGE} from '../../../../../constants/filtering/pagination';
import {DEFAULT_TOASTIFY_CONFIGURATION} from '../../../../../constants/toastify-configuration';
import {EventName} from '../../../../../constants/analytics/event-name';
import {EventType} from '../../../../../constants/analytics/event-type';
import {ReactComponent as IconPlus} from '../../../../@components/kit/icons/plus.svg';
import {SearchTriggeredEvent} from '../../../../../interfaces/analytics/event-properties';
import {SortedColumn} from '../../../../../interfaces/filtering/sorted-column';
import {StringParam, createEnumParam, useQueryParams} from 'use-query-params';
import {SUPPORTED_ORDER_BY_PARAMETERS} from '../../../../../constants/filtering/order-by';
import {toast, ToastOptions} from 'react-toastify';
import {useCompanies, useCurrentUser} from '../../../../@hooks/queries';
import {useCreateCompanyForAdminPortal} from '../../../../@hooks/mutations';
import {useCurrentAuthUserState} from '../../../../@atoms/current-auth-user';
import {useDebounce} from 'react-use';
import {useTranslation} from 'react-i18next';
import AddCompanyDialog from './@components/add-company-dialog';
import Button from '../../../../@components/kit/form/button';
import getActiveSortedColumnFromQueryParams from '../../../../../utils/get-active-sorted-column-from-query-params';
import getFullUrl from '../../../../../utils/get-full-url/get-full-url';
import ListView from './@components/list-view';
import NoResultsBox from '../../../@components/no-results-box';
import Pagination from '../../../../@components/pagination';
import RoundedCard from '../../../../@components/rounded-card';
import SearchForm from '../../../@components/search-form';
import Spinner from '../../../../@components/spinner';
import styles from './styles.module.scss';
import useAnalytics from '../../../../@hooks/use-analytics';
import usePagination from '../../../../@hooks/use-pagination';

const MINIMUM_SEARCH_LETTERS = 2;
const DELAY_FOR_SEARCH_DEBOUNCE_IN_MS = 500;

const CompanyList: FunctionComponent = () => {
  const {t} = useTranslation(['adminPortal', 'applicationPageTitle']);

  const {trackEvent} = useAnalytics();
  const [, setCurrentAuthUser] = useCurrentAuthUserState();

  const [isAddCompanyDialogVisible, setIsAddCompanyDialogVisible] =
    useState(false);

  const [isReadyToFetchUser, setIsReadytoFetchUser] = useState(false);

  const {
    currentPage,
    goToNextPage,
    goToPreviousPage,
    offset,
    onResetPagination,
    pageInQueryParams,
    setCurrentPage,
    setPageInQueryParams,
  } = usePagination({itemsPerPage: DEFAULT_NUMBER_OF_ITEMS_PER_PAGE});

  const [queryParameters, setQueryParameters] = useQueryParams({
    orderBy: createEnumParam(SUPPORTED_ORDER_BY_PARAMETERS),
    sortBy: createEnumParam(SUPPORTED_SORTABLE_COLUMNS.COMPANIES),
    query: StringParam,
  });

  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(
    queryParameters.query
  );

  const companies = useCompanies({
    variables: {
      currentPage,
      itemsPerPage: DEFAULT_NUMBER_OF_ITEMS_PER_PAGE,
      limit: DEFAULT_NUMBER_OF_ITEMS_PER_PAGE,
      offset,
      orderBy: queryParameters.orderBy ?? undefined,
      query: queryParameters.query ?? undefined,
      sortBy: queryParameters.sortBy ?? undefined,
    },
  });

  useCurrentUser({
    enabled: isReadyToFetchUser,
    variables: {},
    onSuccess: (response) => {
      if (!response) return;

      setCurrentAuthUser(response);
      setIsReadytoFetchUser(false);
    },
  });

  const createCompany = useCreateCompanyForAdminPortal();

  const [activeSortedColumn, setActiveSortedColumn] =
    useState<SortedColumn | null>(
      getActiveSortedColumnFromQueryParams({
        orderBy: queryParameters.orderBy,
        sortBy: queryParameters.sortBy,
        supportedColumnsToSort: SUPPORTED_SORTABLE_COLUMNS.COMPANIES,
      })
    );

  const onColumnClicked = useCallback(
    (column: SortedColumn) => {
      setActiveSortedColumn(column);

      setQueryParameters({
        ...queryParameters,
        sortBy: column.columnName as COMPANIES_SORTABLE_COLUMNS,
        orderBy: column.order,
      });

      if (!pageInQueryParams) return;

      setCurrentPage(1);
      setPageInQueryParams(1);
    },
    [
      pageInQueryParams,
      queryParameters,
      setQueryParameters,
      setCurrentPage,
      setPageInQueryParams,
    ]
  );

  const onSearch = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setDebouncedSearchQuery(value);

      const eventProperties = {
        searchQuery: value,
      } as SearchTriggeredEvent;

      trackEvent({
        eventName: EventName.ADMIN_PORTAL.COMPANY_LIST.SEARCH,
        eventType: EventType.SEARCH_TRIGGERED,
        eventProperties,
      });
    },
    [trackEvent]
  );

  const onClearSearch = useCallback(
    (event: FormEvent) => {
      event.preventDefault();

      setQueryParameters({
        ...queryParameters,
        query: undefined,
      });

      trackEvent({
        eventName: EventName.PEOPLE.EMPLOYEES.CLEAR_SEARCH,
        eventType: EventType.BUTTON_CLICKED,
      });
    },
    [queryParameters, setQueryParameters, trackEvent]
  );

  const onAddCompanyButtonClicked = useCallback(() => {
    trackEvent({
      eventName: EventName.ADMIN_PORTAL.COMPANY_LIST.CREATE_NEW_COMPANY,
      eventType: EventType.BUTTON_CLICKED,
    });

    setIsAddCompanyDialogVisible(true);
  }, [trackEvent]);

  const onCloseAddCompanyDialog = useCallback(() => {
    setIsAddCompanyDialogVisible(false);
  }, []);

  const onRefreshCompanies = useCallback(() => {
    companies.refetch();
  }, [companies]);

  const onCreateCompanyConfirm = useCallback(
    async (values: CompanyUpsert) => {
      const payload = {
        companyPayload: {
          ...values,
          websiteUrl: getFullUrl(values.websiteUrl),
        },
      } as CreateCompanyPayload;

      const response = await createCompany.mutateAsync(payload);

      if (!response) return;

      setPageInQueryParams(1);
      setIsAddCompanyDialogVisible(false);

      const feedbackMessage = t(
        'components.company-list.feedback-messages.add-company'
      );

      toast.success(feedbackMessage, {
        ...(DEFAULT_TOASTIFY_CONFIGURATION as ToastOptions),
      });

      trackEvent({
        eventName: EventName.ADMIN_PORTAL.COMPANY_LIST.CREATE_NEW_COMPANY,
        eventType: EventType.BUTTON_CLICKED,
      });

      companies.refetch();
      setIsReadytoFetchUser(true);
    },
    [companies, createCompany, setPageInQueryParams, t, trackEvent]
  );

  useDebounce(
    () => {
      if (
        debouncedSearchQuery !== '' &&
        (debouncedSearchQuery?.length ?? 0) < MINIMUM_SEARCH_LETTERS
      ) {
        return;
      }

      setQueryParameters({
        ...queryParameters,
        query: debouncedSearchQuery,
      });

      if (!pageInQueryParams) return;
      onResetPagination();
    },
    DELAY_FOR_SEARCH_DEBOUNCE_IN_MS,
    [debouncedSearchQuery]
  );

  return (
    <>
      <RoundedCard.Header className={styles.header}>
        <RoundedCard.Title>
          {t('components.company-list.title')}
        </RoundedCard.Title>

        <div className={styles.searchFormContainer}>
          <div className={styles.searchForm}>
            <SearchForm
              onClearSearch={onClearSearch}
              onSearch={onSearch}
              placeholder={t('components.company-list.search-box.placeholder')}
              searchQueryInQueryParams={queryParameters.query}
            />
          </div>

          <Button onClick={onAddCompanyButtonClicked}>
            <span className={styles.searchFormButtonText}>
              {t('components.company-list.add-company-button')}
            </span>

            <IconPlus className={styles.searchFormButtonIcon} />
          </Button>
        </div>
      </RoundedCard.Header>

      <RoundedCard.Content>
        {companies.isLoading ? (
          <Spinner />
        ) : (
          <>
            {companies.data?.data.length ? (
              <>
                <ListView
                  activeSortedColumn={activeSortedColumn}
                  companies={companies.data?.data}
                  onColumnClicked={onColumnClicked}
                  onRefreshCompanies={onRefreshCompanies}
                />

                {companies.data?.meta.totalEntries && (
                  <Pagination
                    currentPage={currentPage}
                    itemsPerPage={DEFAULT_NUMBER_OF_ITEMS_PER_PAGE}
                    onNextPageLinkClicked={goToNextPage}
                    onPreviousPageLinkClicked={goToPreviousPage}
                    totalItems={companies.data?.meta.totalEntries}
                  />
                )}
              </>
            ) : (
              <>
                <NoResultsBox />
              </>
            )}
          </>
        )}
      </RoundedCard.Content>

      {isAddCompanyDialogVisible && (
        <AddCompanyDialog
          isVisible={isAddCompanyDialogVisible}
          onClose={onCloseAddCompanyDialog}
          onSubmit={onCreateCompanyConfirm}
        />
      )}
    </>
  );
};

export default CompanyList;
