import {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import {SevenPointLikertOptionsWithLabels} from '../../../../../interfaces/question';
import RadioOption from './@components/radio-option';
import PreviousQuestionLink from '../@components/previous-question-link';
import styles from './styles.module.scss';

interface ElementProps {
  questionText: string;
  options?: SevenPointLikertOptionsWithLabels;
  subQuestionText?: string;
  onResponse: (value: string) => void;
  onPreviousLinkClick?: () => void;
  answer?: string;
}

const SevenPointLikertWithLabels: FunctionComponent<ElementProps> = ({
  questionText,
  subQuestionText,
  options,
  onResponse,
  onPreviousLinkClick,
  answer,
}) => {
  const {t} = useTranslation('components');

  const defaultLabels = [
    t('questionnaire.seven-point-likert-with-label.not-at-all-important'),
    t('questionnaire.seven-point-likert-with-label.low-importance'),
    t('questionnaire.seven-point-likert-with-label.slightly-important'),
    t('questionnaire.seven-point-likert-with-label.neutral'),
    t('questionnaire.seven-point-likert-with-label.moderately-important'),
    t('questionnaire.seven-point-likert-with-label.very-important'),
    t('questionnaire.seven-point-likert-with-label.extremely-important'),
  ];

  const labels = options ? options.labels : defaultLabels;

  return (
    <>
      <div className={styles.container}>
        {onPreviousLinkClick ? (
          <PreviousQuestionLink onLinkClick={onPreviousLinkClick} />
        ) : null}

        <div className={styles.question}>{questionText}</div>

        {subQuestionText && (
          <div className={styles.subQuestion}>{subQuestionText}</div>
        )}

        <div className={styles.options}>
          {labels.map((label, index) => {
            const updatedIndex = index + 1;

            return (
              <RadioOption
                isSelected={answer === updatedIndex.toString()}
                key={index}
                label={label}
                name="likert-with-label"
                onChange={onResponse}
                value={updatedIndex.toString()}
              />
            );
          })}

          <span className={styles.line}></span>
        </div>
      </div>
    </>
  );
};

export default SevenPointLikertWithLabels;
