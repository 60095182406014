import {sanitizeCompanyCoreValueFromRaw} from '../../../ui/@sanitizers/company-core-values';
import InstillAPI from '../axios-instance';
import isResponseSuccessful from '../../../utils/is-response-successful';

export interface CompanyCoreValuePayload {
  companyUuid: string;
  valueUuid?: string;
}

export async function fetchCompanyCoreValue(
  valueUuid: string,
  companyUuid: string
) {
  if (!valueUuid) return;

  try {
    const response = await InstillAPI.get(
      `/companies/${companyUuid}/values/${valueUuid}`
    );

    if (!response.data || !isResponseSuccessful(response)) {
      throw Error('No data received');
    }

    return sanitizeCompanyCoreValueFromRaw(response.data);
  } catch (error) {
    console.error(error);
  }
}
