import {formatDate} from '../../../../../../../../../utils/date';
import {FunctionComponent} from 'react';
import {Kudo} from '../../../../../../../../../interfaces/kudo';
import {useTranslation} from 'react-i18next';
import AvatarPlaceholder from '../../../../../../../../@components/avatar-placeholder';
import styles from './styles.module.scss';
import Table from '../../../../../../../../@components/table';
import tableStyles from '../../../../../../../../../styles/classes/table.module.scss';
import Tag from '../../../../../../../../@components/tag';
import RichTextContainer from '../../../../../../../../@components/rich-text-container';

interface ElementProps {
  kudos: Kudo;
}

const TableRow: FunctionComponent<ElementProps> = ({kudos}) => {
  const {t} = useTranslation('kudos');

  return (
    <Table.Row className={tableStyles.tableRow}>
      <Table.Data variant="nameColumn" className={tableStyles.tableData}>
        <div className={tableStyles.mobileHeaderTitle}>
          {t('components.kudos-list.components.list-view.header.recipient')}
        </div>

        <div className={tableStyles.overflowContainer}>
          {kudos.toUser.picture ? (
            <img
              src={kudos.toUser.picture}
              className={styles.profilePicture}
              alt=""
            />
          ) : (
            <div className={styles.avatarContainer}>
              <AvatarPlaceholder
                size="small"
                variant="dark-blue"
                name={kudos.toUser.name}
              />
            </div>
          )}

          <div className={tableStyles.overflowContent}>{kudos.toUser.name}</div>
        </div>
      </Table.Data>

      <Table.Data variant="medium" className={tableStyles.tableData}>
        <div className={tableStyles.mobileHeaderTitle}>
          {t('components.kudos-list.components.list-view.header.from')}
        </div>

        {kudos.fromUser.name ? (
          <div>{kudos.fromUser.name}</div>
        ) : (
          <div className={styles.emptyValue}>—</div>
        )}
      </Table.Data>

      <Table.Data variant="small" className={tableStyles.tableData}>
        <div className={tableStyles.mobileHeaderTitle}>
          {t('components.kudos-list.components.list-view.header.value')}
        </div>

        <Tag>{kudos.value.name}</Tag>
      </Table.Data>

      <Table.Data variant="small" className={tableStyles.tableData}>
        <div className={tableStyles.mobileHeaderTitle}>
          {t('components.kudos-list.components.list-view.header.date')}
        </div>

        {formatDate(kudos.createdAt)}
      </Table.Data>

      <Table.Data variant="large" className={tableStyles.tableData}>
        <div className={tableStyles.mobileHeaderTitle}>
          {t('components.kudos-list.components.list-view.header.description')}
        </div>

        <div className={styles.description}>
          <RichTextContainer value={kudos.description} />
        </div>
      </Table.Data>
    </Table.Row>
  );
};

export default TableRow;
