import {createMutation} from 'react-query-kit';
import {AxiosResponse} from 'axios';
import {deleteTeam} from '../../../repositories/instill';
import {DeleteTeamPayload} from '../../../repositories/instill/mutations/delete-team';

export const useDeleteTeam = createMutation<
  AxiosResponse<any, any> | undefined,
  DeleteTeamPayload
>({
  mutationFn: async (variables) => {
    return await deleteTeam({
      companyUuid: variables.companyUuid,
      teamUuid: variables.teamUuid,
    });
  },
});
