import {FunctionComponent, PropsWithChildren, useCallback} from 'react';
import {Location, Navigate, Route, Routes, useLocation} from 'react-router';
import {ToastContainer} from 'react-toastify';
import {AuthUser} from '../../interfaces/auth-user';
import {Company} from '../../interfaces/company';
import {getUserProfileForCompany} from '../../services/user-profiles';
import {useCurrentAuthUserState} from '../@atoms/current-auth-user';
import {useSafeCurrentCompany} from '../@atoms/current-company';
import useAnalytics from '../@hooks/use-analytics';
import {useEffectOnce} from '../@hooks/use-effect-once';
import Assessments from '../assessments';
import AssessmentsSummary from '../assessments-summary';
import MobileNavigation from './@components/mobile-navigation';
import Navigation from './@components/sidebar-navigation';
import NotificationContextProvider from './@contexts/notification-context';
import SetupContextProvider from './@contexts/setup-context';
import WebSocketContextProvider from './@contexts/web-socket-context';
import AdminPortal from './admin-portal';
import Apply from './apply';
import Dashboard from './dashboard';
import Design from './design';
import Jobs from './jobs';
import Kudos from './kudos';
import LinkSlack from './link-slack';
import Measure from './measure';
import MyCompanyProfile from './my-company-profile';
import MyProfile from './my-profile';
import OrganizationSetup from './organization-setup';
import People from './people';
import Sense from './sense';
import Settings from './settings';
import styles from './styles.module.scss';
import UserSetup from './user-setup';
import Values from './values';

const ORGANIZATION_SETUP_PATH = 'organization-setup';
const USER_SETUP_PATH = 'user-setup';

interface PageContentTemplateProps {
  isNavigationVisible?: boolean;
}

const shouldRedirectToOrganizationSetup = (
  company: Company,
  authUser: AuthUser | null,
  currentLocation: Location
) => {
  if (company.creatorUuid !== authUser?.uuid) return false;
  if (company.isOnboarded) return false;
  if (currentLocation.pathname.includes(ORGANIZATION_SETUP_PATH)) return false;

  return true;
};

const shouldRedirectToUserSetup = (
  company: Company,
  authUser: AuthUser | null,
  currentLocation: Location
) => {
  if (company.creatorUuid === authUser?.uuid) return false;

  if (!authUser) return false;
  const userProfile = getUserProfileForCompany(authUser, company);

  if (userProfile.isOnboarded) return false;
  if (currentLocation.pathname.includes(USER_SETUP_PATH)) return false;

  return true;
};

const PageContentTemplate: FunctionComponent<
  PropsWithChildren<PageContentTemplateProps>
> = ({children, isNavigationVisible = true}) => {
  return (
    <>
      {isNavigationVisible && (
        <>
          <Navigation variant="standalone" />
          <MobileNavigation />
        </>
      )}

      <div
        className={
          isNavigationVisible ? styles.container : styles.fullWidthContainer
        }
      >
        {children}
      </div>
    </>
  );
};

const Application: FunctionComponent = () => {
  const currentCompany = useSafeCurrentCompany();
  const [currentAuthUser] = useCurrentAuthUserState();

  const location = useLocation();
  const {setUserIdentifier, setUserProperties} = useAnalytics();

  const onTrackDefaultProperties = useCallback(() => {
    setUserProperties([
      {key: 'Company UUID', value: currentCompany.uuid},
      {key: 'Company Name', value: currentCompany.name},
    ]);

    if (!currentAuthUser?.email) return;
    setUserIdentifier(currentAuthUser.email);
  }, [currentCompany, currentAuthUser, setUserIdentifier, setUserProperties]);

  useEffectOnce(() => {
    onTrackDefaultProperties();
  });

  if (
    shouldRedirectToOrganizationSetup(currentCompany, currentAuthUser, location)
  ) {
    return <Navigate replace to={ORGANIZATION_SETUP_PATH} />;
  }

  if (shouldRedirectToUserSetup(currentCompany, currentAuthUser, location)) {
    return <Navigate replace to={USER_SETUP_PATH} />;
  }

  return (
    <div className={styles.wrapper}>
      <WebSocketContextProvider>
        <NotificationContextProvider>
          <ToastContainer />

          <Routes>
            <Route path="/" element={<Navigate replace to="dashboard" />} />

            <Route
              path="organization-setup/*"
              element={
                <SetupContextProvider>
                  <PageContentTemplate isNavigationVisible={false}>
                    <OrganizationSetup />
                  </PageContentTemplate>
                </SetupContextProvider>
              }
            />

            <Route
              path="apply/*"
              element={
                <PageContentTemplate>
                  <Apply />
                </PageContentTemplate>
              }
            />

            <Route
              path="dashboard/*"
              element={
                <PageContentTemplate>
                  <Dashboard />
                </PageContentTemplate>
              }
            />

            <Route
              path="settings/*"
              element={
                <PageContentTemplate>
                  <Settings />
                </PageContentTemplate>
              }
            />

            <Route
              path="kudos"
              element={
                <PageContentTemplate>
                  <Kudos />
                </PageContentTemplate>
              }
            />

            <Route
              path="values/*"
              element={
                <PageContentTemplate>
                  <Values />
                </PageContentTemplate>
              }
            />

            <Route
              path="design/*"
              element={
                <PageContentTemplate>
                  <Design />
                </PageContentTemplate>
              }
            />

            <Route
              path="measure/*"
              element={
                <PageContentTemplate>
                  <Measure />
                </PageContentTemplate>
              }
            />

            <Route
              path="people/*"
              element={
                <PageContentTemplate>
                  <People />
                </PageContentTemplate>
              }
            />

            <Route
              path="jobs/*"
              element={
                <PageContentTemplate>
                  <Jobs />
                </PageContentTemplate>
              }
            />

            <Route
              path="my-profile"
              element={
                <PageContentTemplate>
                  <MyProfile />
                </PageContentTemplate>
              }
            />

            <Route
              path="my-company-profile"
              element={
                <PageContentTemplate>
                  <MyCompanyProfile />
                </PageContentTemplate>
              }
            />

            <Route
              path="user-setup/*"
              element={
                <SetupContextProvider>
                  <PageContentTemplate isNavigationVisible={false}>
                    <UserSetup />
                  </PageContentTemplate>
                </SetupContextProvider>
              }
            />

            <Route path="assessments/*" element={<Assessments />} />

            <Route
              path="assessments-summary/*"
              element={<AssessmentsSummary />}
            />

            <Route path="link-slack/*" element={<LinkSlack />} />

            <Route
              path="admin-portal/"
              element={
                <PageContentTemplate>
                  <AdminPortal />
                </PageContentTemplate>
              }
            />

            <Route
              path="sense/*"
              element={
                <PageContentTemplate>
                  <Sense />
                </PageContentTemplate>
              }
            />

            <Route
              path="*"
              element={<Navigate replace to="/page-not-found" />}
            />
          </Routes>
        </NotificationContextProvider>
      </WebSocketContextProvider>
    </div>
  );
};

export default Application;
