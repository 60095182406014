import {FunctionComponent, ButtonHTMLAttributes} from 'react';
import styles from './styles.module.scss';

// Constants
const VARIANT_PRIMARY = 'primary';
const VARIANT_SECONDARY = 'secondary';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: typeof VARIANT_PRIMARY | typeof VARIANT_SECONDARY;
};

const Button: FunctionComponent<ButtonProps> = ({children, ...props}) => {
  const {type, variant} = props;

  const buttonType = type ?? 'button';

  const isSecondary = variant && variant === VARIANT_SECONDARY;
  const className = isSecondary ? styles.secondaryButton : styles.button;

  return (
    <button className={className} type={buttonType} {...props}>
      {children}
    </button>
  );
};

export default Button;
