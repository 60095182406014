import {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import {USER_PROFILE_STATUS_INVITED} from '../../../../../../../../../../constants/user-profile-status';
import {RelatedUserProfile} from '../../../../../../../../../../interfaces/user-profile';
import AvatarPlaceholder from '../../../../../../../../../@components/avatar-placeholder';
import Tag from '../../../../../../../../../@components/tag/tag';
import styles from './styles.module.scss';

interface ElementProps {
  user: RelatedUserProfile;
}

const Card: FunctionComponent<ElementProps> = ({user}) => {
  const {t} = useTranslation('application');

  return (
    <div className={styles.container}>
      <div>
        {user.picture ? (
          <img src={user.picture} alt="" className={styles.picture} />
        ) : (
          <AvatarPlaceholder name={user.name} />
        )}
      </div>

      <div className={styles.content}>
        <div className={styles.name}>{user.name}</div>

        {user.jobTitle ? (
          <Tag>{user.jobTitle}</Tag>
        ) : (
          user.status === USER_PROFILE_STATUS_INVITED && (
            <Tag variant="dark">
              {t(
                'components.profile-details.components.organization-structure.components.invited-label'
              )}
            </Tag>
          )
        )}
      </div>
    </div>
  );
};

export default Card;
