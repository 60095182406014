import {createMutation} from 'react-query-kit';
import {upsertUserHierarchy} from '../../../repositories/instill';
import {UpsertUserHierarchyPayload} from '../../../repositories/instill/mutations/upsert-user-hierarchy';
import {UserProfile} from '../../../interfaces/user-profile';

export const useUpsertUserHierarchy = createMutation<
  UserProfile | undefined,
  UpsertUserHierarchyPayload
>({
  mutationFn: async (variables) => {
    return await upsertUserHierarchy(
      variables.userHierarchyPayload,
      variables.userProfileUuid,
      variables.companyUuid
    );
  },
});
