import {FunctionComponent, PropsWithChildren} from 'react';
import styles from './styles.module.scss';

interface ElementProps {
  variant?: 'no-margin';
  className?: string;
}

const Row: FunctionComponent<PropsWithChildren<ElementProps>> = ({
  children,
  variant,
  ...props
}) => {
  const variantClass = variant ? styles[variant] : '';

  return (
    <tr className={`${styles.row} ${props.className ?? ''} ${variantClass}`}>
      {children}
    </tr>
  );
};

export default Row;
