import {
  CompanyAssessment,
  CompletionInfo,
} from '../../../../../../../../../../../interfaces/company-assessments';
import {
  ChangeEvent,
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {sortByName} from '../../../../../../../../../../../utils/sort-by/name';
import {useTranslation} from 'react-i18next';
import Dialog from '../../../../../../../../../../@components/kit/dialog';
import Input from '../../../../../../../../../../@components/kit/form/input';
import styles from './styles.module.scss';

interface ElementProps {
  companyAssessment: CompanyAssessment;
  isVisible: boolean;
  onClose: () => void;
}

const PopulationDialog: FunctionComponent<ElementProps> = ({
  companyAssessment,
  isVisible,
  onClose,
}) => {
  const {t} = useTranslation('measure');

  const [searchQuery, setSearchQuery] = useState('');
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [populationList, setPopulationList] = useState(
    companyAssessment.completionInfo
  );

  const onSearchInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setSearchQuery(value);

      if (value === '') {
        setIsSearchActive(false);
      } else {
        setIsSearchActive(true);
      }

      const filteredWithQuery = companyAssessment.completionInfo.filter(
        (employee) => {
          if (employee.name.toLowerCase().includes(value.toLowerCase())) {
            return employee;
          }

          return null;
        }
      );

      setPopulationList(filteredWithQuery);
    },
    [companyAssessment.completionInfo]
  );

  const filteredPopulation = useMemo(() => {
    const sortedList = sortByName(populationList);

    return sortedList.reduce(
      (memo, employee) => {
        const isCompleted = employee.isCompleted;

        if (isCompleted) {
          return {
            ...memo,
            completed: [...memo['completed'], employee],
          };
        }

        return {
          ...memo,
          notCompleted: [...memo['notCompleted'], employee],
        };
      },
      {
        completed: [] as CompletionInfo[],
        notCompleted: [] as CompletionInfo[],
      } as Record<string, CompletionInfo[]>
    );
  }, [populationList]);

  return (
    <Dialog isOpen={isVisible} onClose={onClose} variant="medium">
      <Dialog.Header>
        <h1 className={styles.title}>{companyAssessment.assessment.name}</h1>

        <div className={styles.subtitle}>
          {t(
            'manage-assessments.components.assessments.components.population-dialog.subtitle'
          )}
        </div>
      </Dialog.Header>

      <Dialog.Content>
        <form className={styles.form}>
          <Input
            type="search"
            value={searchQuery}
            onChange={onSearchInputChange}
            placeholder={t(
              'manage-assessments.components.assessments.components.population-dialog.input-placeholder'
            )}
          />
        </form>

        <div className={styles.columnContainer}>
          <div className={styles.column}>
            <h2 className={styles.contentTitle}>
              {t(
                'manage-assessments.components.assessments.components.population-dialog.completed-title'
              )}
            </h2>

            {filteredPopulation.completed.length ? (
              <ul className={styles.list}>
                {filteredPopulation.completed.map(
                  (employee: CompletionInfo, index: number) => (
                    <li key={index} className={styles.listItem}>
                      <h2>{employee.name}</h2>
                    </li>
                  )
                )}
              </ul>
            ) : (
              <div className={styles.disabledBox}>
                {isSearchActive ? (
                  <>
                    {t(
                      'manage-assessments.components.assessments.components.population-dialog.no-result'
                    )}
                  </>
                ) : (
                  <>
                    {t(
                      'manage-assessments.components.assessments.components.population-dialog.nobody-completed-assessment'
                    )}
                  </>
                )}
              </div>
            )}
          </div>

          <div className={styles.column}>
            <h2 className={styles.contentTitle}>
              {t(
                'manage-assessments.components.assessments.components.population-dialog.not-completed-title'
              )}
            </h2>

            {filteredPopulation.notCompleted.length ? (
              <ul className={styles.list}>
                {filteredPopulation.notCompleted.map(
                  (employee: CompletionInfo, index: number) => (
                    <li key={index} className={styles.listItem}>
                      <h2>{employee.name}</h2>
                    </li>
                  )
                )}
              </ul>
            ) : (
              <div className={styles.disabledBox}>
                {isSearchActive ? (
                  <>
                    {t(
                      'manage-assessments.components.assessments.components.population-dialog.no-result'
                    )}
                  </>
                ) : (
                  <>
                    {t(
                      'manage-assessments.components.assessments.components.population-dialog.everybody-completed-assessment'
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </Dialog.Content>
    </Dialog>
  );
};

export default PopulationDialog;
