import {CoreValueForm} from '../../../../../../../../interfaces/company-core-value';
import {Field, FieldProps} from 'formik';
import {FunctionComponent} from 'react';
import {
  MEDIA_TYPE_IMAGE,
  MEDIA_TYPE_VIDEO,
} from '../../../../../../../../constants/core-value-media-type';
import {ReactComponent as IconPenEdit} from '../../../../../../../@components/kit/icons/edit.svg';
import {useTranslation} from 'react-i18next';
import FormGroup from '../../../../../../../@components/kit/form/form-group';
import styles from './styles.module.scss';
import UploadMedia from '../../../../../../@components/upload-media';

interface ElementProps {
  formValues: CoreValueForm;
}

const MediaForm: FunctionComponent<ElementProps> = ({formValues}) => {
  const {t} = useTranslation('values');

  const mediaType = formValues.media[0]?.type ?? undefined;
  const mediaUrl = formValues.media[0]?.url ?? undefined;

  const loadPreview = () => {
    if (!mediaType) return;

    if (mediaType === MEDIA_TYPE_IMAGE) {
      return <img src={mediaUrl} className={styles.mediaImage} alt="" />;
    } else if (mediaType === MEDIA_TYPE_VIDEO) {
      return (
        <iframe
          title="video"
          src={`${mediaUrl}?modestbranding=1&rel=0&cc_load_policy=1&iv_load_policy=3&color=white&controls=0&showinfo=0`}
          className={styles.mediaYoutube}
          frameBorder="0"
          allowFullScreen
        />
      );
    }
  };

  return (
    <Field name={`coreValue.media[0].url`}>
      {({field, form}: FieldProps<string>) => (
        <FormGroup>
          <div className={styles.mediaUploadTitle}>
            {t('components.value.components.media-form.upload-media.label')}

            {mediaUrl && (
              <button
                type="button"
                className={styles.button}
                onClick={() => {
                  form.setFieldValue(`coreValue.media[0].url`, '');
                }}
              >
                <IconPenEdit className={styles.editIcon} />
              </button>
            )}
          </div>

          {mediaUrl ? (
            loadPreview()
          ) : (
            <UploadMedia
              form={form}
              field={field}
              typeFieldName={`coreValue.media[0].type`}
            />
          )}
        </FormGroup>
      )}
    </Field>
  );
};

export default MediaForm;
