import {CompanyAssessment} from '../../../interfaces/company-assessments';
import {createMutation} from 'react-query-kit';
import {UpdateCompanyAssessmentPayload} from '../../../repositories/instill/mutations/update-company-assessment';
import {updateCompanyAssessment} from '../../../repositories/instill/mutations';

export const useUpdateCompanyAssessment = createMutation<
  CompanyAssessment | undefined,
  UpdateCompanyAssessmentPayload
>({
  mutationFn: async (variables) => {
    return await updateCompanyAssessment(
      variables.companyUuid,
      variables.assessmentUuid,
      variables.payload
    );
  },
});
