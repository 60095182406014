import {createMutation} from 'react-query-kit';
import {upsertCandidateAssessmentResponse} from '../../../repositories/instill';
import {UpsertCandidateAssessmentResponsePayload} from '../../../repositories/instill/mutations/upsert-candidate-assessment-response';

export const useUpsertCandidateAssessmentResponse = createMutation<
  {value: string} | undefined,
  UpsertCandidateAssessmentResponsePayload
>({
  mutationFn: async (variables) => {
    return await upsertCandidateAssessmentResponse(
      {value: variables.value},
      variables.candidateToken,
      variables.candidateUuid,
      variables.userAssessmentUuid,
      variables.moduleUuid,
      variables.questionUuid
    );
  },
});
