export enum CULTURE_VITAL_SIGN_SLUGS {
  BURNOUT = 'burnout',
  INCLUSION = 'inclusion',
  INNOVATION = 'innovation',
  PSYCHOLOGICAL_SAFETY = 'psychological_safety',
  TRUST = 'trust',
}

export const CULTURE_VITAL_SIGN_KEYS = {
  [CULTURE_VITAL_SIGN_SLUGS.BURNOUT]: 'burnout',
  [CULTURE_VITAL_SIGN_SLUGS.INCLUSION]: 'inclusion',
  [CULTURE_VITAL_SIGN_SLUGS.INNOVATION]: 'innovation',
  [CULTURE_VITAL_SIGN_SLUGS.PSYCHOLOGICAL_SAFETY]: 'psychological-safety',
  [CULTURE_VITAL_SIGN_SLUGS.TRUST]: 'trust',
};

export const SUPPORTED_CULTURE_VITAL_SIGNS = [
  CULTURE_VITAL_SIGN_SLUGS.BURNOUT,
  CULTURE_VITAL_SIGN_SLUGS.INCLUSION,
  CULTURE_VITAL_SIGN_SLUGS.INNOVATION,
  CULTURE_VITAL_SIGN_SLUGS.PSYCHOLOGICAL_SAFETY,
  CULTURE_VITAL_SIGN_SLUGS.TRUST,
];

// Used in Overall Health dashboard
export const DEFAULT_CULTURE_VITAL_SIGNS = [CULTURE_VITAL_SIGN_SLUGS.INCLUSION];

export const CULTURE_VITAL_SIGN_COLORS = {
  [CULTURE_VITAL_SIGN_SLUGS.BURNOUT]: {
    NORMAL: '#23cf14',
    LIGHT: '#E9FDE8',
  },
  [CULTURE_VITAL_SIGN_SLUGS.INCLUSION]: {NORMAL: '#94e0f8', LIGHT: '#E7F8FD'},
  [CULTURE_VITAL_SIGN_SLUGS.INNOVATION]: {NORMAL: '#07d4e1', LIGHT: '#E6FDFE'},
  [CULTURE_VITAL_SIGN_SLUGS.PSYCHOLOGICAL_SAFETY]: {
    NORMAL: '#eda232',
    LIGHT: '#FDF4E8',
  },
  [CULTURE_VITAL_SIGN_SLUGS.TRUST]: {NORMAL: '#773cf3', LIGHT: '#EEE7FE'},
};
