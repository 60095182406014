import {FunctionComponent} from 'react';
import {OrganizationalHealth} from '../../../../../../interfaces/organizational-health';
import {Splide, SplideSlide} from '@splidejs/react-splide';
import Item from './@components/item';
import {Dimension} from '../../../../../../interfaces/dimension';

interface ElementProps {
  dimensions: Dimension[];
  highlightedDimensionSlug: string | null;
  onDimensionClicked: (dimensionSlug: string) => void;
  organizationalHealth: OrganizationalHealth;
}

const HealthGauges: FunctionComponent<ElementProps> = ({
  dimensions,
  highlightedDimensionSlug,
  onDimensionClicked,
  organizationalHealth,
}) => {
  const carouselOptions = {
    gap: '24px',
    arrows: false,
    perPage: 4,
    autoHeight: true,
    snap: true,
    breakpoints: {
      1190: {
        perPage: 3,
      },
      1024: {
        perPage: 2,
      },
      640: {
        perPage: 1,
      },
    },
  };

  return (
    <Splide options={carouselOptions}>
      {organizationalHealth.map((dimension) => (
        <SplideSlide key={dimension.dimensionSlug}>
          <Item
            dimensionFromHealthData={dimension}
            dimensions={dimensions}
            highlightedDimensionSlug={highlightedDimensionSlug}
            onDimensionClicked={onDimensionClicked}
          />
        </SplideSlide>
      ))}
    </Splide>
  );
};

export default HealthGauges;
