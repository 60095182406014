import {buildPageTitle} from '../../../../services/build-page-title';
import {EventName} from '../../../../constants/analytics/event-name';
import {EventType} from '../../../../constants/analytics/event-type';
import {FunctionComponent, useCallback} from 'react';
import {Helmet} from 'react-helmet-async';
import {HierarchyEdit} from '../../@components/hierarchy-edit';
import {ReactComponent as IconMap} from '../../../@components/kit/icons/map.svg';
import {useCurrentFullUserProfile} from '../../../@atoms/current-full-user-profile';
import {useNavigate} from 'react-router';
import {USER_CHECKLIST_STEP_KEYS} from '../../@components/setup-checklist/setup-checklist';
import {useTranslation} from 'react-i18next';
import BackButton from '../../@components/back-button';
import useAnalytics from '../../../@hooks/use-analytics';
import userSetupStyles from '../styles.module.scss';

interface ElementProps {
  stepIdentifier: USER_CHECKLIST_STEP_KEYS;
  updateChecklist: (stepIdentifier: USER_CHECKLIST_STEP_KEYS) => void;
}
const NEXT_STEP_ROUTE = '/application/user-setup/enter-your-work-style';

export const MapYourOrganization: FunctionComponent<ElementProps> = ({
  stepIdentifier,
  updateChecklist,
}) => {
  const {t} = useTranslation(['userSetup', 'applicationPageTitle']);

  const navigate = useNavigate();
  const currentFullUserProfile = useCurrentFullUserProfile();
  const {trackEvent} = useAnalytics();

  const onCompleteStep = useCallback(() => {
    trackEvent({
      eventName: EventName.USER_SETUP.MAP_YOUR_ORGANIZATION.SAVE_CONTINUE,
      eventType: EventType.BUTTON_CLICKED,
    });

    updateChecklist(stepIdentifier);
    navigate(NEXT_STEP_ROUTE);
  }, [trackEvent, stepIdentifier, updateChecklist, navigate]);

  const onSecondaryButtonClick = useCallback(() => {
    trackEvent({
      eventName: EventName.USER_SETUP.MAP_YOUR_ORGANIZATION.SKIP_FOR_NOW,
      eventType: EventType.BUTTON_CLICKED,
    });

    updateChecklist(stepIdentifier);
    navigate(NEXT_STEP_ROUTE);
  }, [trackEvent, stepIdentifier, updateChecklist, navigate]);

  return (
    <>
      <Helmet>
        <title>
          {buildPageTitle(
            t('page-title.user-setup.map-your-organization', {
              ns: 'applicationPageTitle',
            })
          )}
        </title>
      </Helmet>

      <BackButton path="/application/user-setup/create-your-profile" />

      <div className={userSetupStyles.header}>
        <h1 className={userSetupStyles.headerTitle}>
          {t('map-your-organization.title')}
        </h1>

        <IconMap className={userSetupStyles.headerIcon} />
      </div>

      <div className={userSetupStyles.line} />

      <HierarchyEdit
        addDirectManagerEventName={
          EventName.USER_SETUP.MAP_YOUR_ORGANIZATION.ADD_DIRECT_MANAGER
        }
        addDirectReportsEventName={
          EventName.USER_SETUP.MAP_YOUR_ORGANIZATION.ADD_DIRECT_REPORT
        }
        addJobTitleEventName={
          EventName.USER_SETUP.MAP_YOUR_ORGANIZATION.ADD_JOB_TITLE
        }
        onSecondaryButtonClick={onSecondaryButtonClick}
        onSubmitSuccess={onCompleteStep}
        primaryButtonLabel={t('map-your-organization.submit-button-text')}
        profile={currentFullUserProfile}
        secondaryButtonLabel={t('map-your-organization.skip-button-text')}
      />
    </>
  );
};

export default MapYourOrganization;
