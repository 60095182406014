export const isRichTextFilled = (value: string | null): boolean => {
  if (!value || value === '<p></p>') return false;
  return true;
};

export const getInnerTextLength = (fieldValueWithHtml: string) => {
  const element = document.createElement('div');
  element.innerHTML = fieldValueWithHtml;

  const length = element.innerText.length;
  element.remove();
  return length;
};
