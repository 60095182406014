import 'yup-phone';
import {BasicProfileInformationForm} from '../';
import {fetchPresignedUploadURLResourceType} from '../../../../../repositories/instill/queries/fetch-presigned-url-config';
import {Formik, Field, FieldProps, Form} from 'formik';
import {FunctionComponent, useCallback} from 'react';
import {
  PHONE_NUMBER_VALIDATION_COUNTRY_CODE,
  PHONE_NUMBER_VALIDATION_STRICT,
} from '../../../../../constants/validation-schema';
import {timeZoneOptions} from '../../../../../constants/time-zone-options';
import {useCurrentAuthUserState} from '../../../../@atoms/current-auth-user';
import {useCurrentFullUserProfile} from '../../../../@atoms/current-full-user-profile';
import {useFetchPresignedUploadUrl} from '../../../../@hooks/mutations';
import {useSafeCurrentCompany} from '../../../../@atoms/current-company';
import {useTranslation} from 'react-i18next';
import {useUploadToPresignedUrl} from '../../../../@hooks/mutations';
import * as Yup from 'yup';
import Button from '../../../../@components/kit/form/button';
import FormControl from '../../../../@components/kit/form/form-control';
import FormFooter from '../../../../@components/kit/form/form-footer';
import ImageUpload from '../../../../@components/kit/form/image-upload';
import Input from '../../../../@components/kit/form/input';
import Label from '../../../../@components/kit/form/label';
import SearchableSelect from '../../../../@components/kit/form/hybrid-select/searchable-select';
import styles from './styles.module.scss';

interface ElementProps {
  onSubmit: (values: BasicProfileInformationForm) => void;
}

const BasicProfileInformation: FunctionComponent<ElementProps> = ({
  onSubmit,
}) => {
  const {t} = useTranslation(['userSetup', 'common']);

  const [currentAuthUser] = useCurrentAuthUserState();
  const currentFullUserProfile = useCurrentFullUserProfile();
  const currentCompany = useSafeCurrentCompany();

  const fetchPresignedUploadURL = useFetchPresignedUploadUrl();
  const uploadToPresignedURL = useUploadToPresignedUrl();

  const EMPTY_VALUES: BasicProfileInformationForm = {
    picture: currentFullUserProfile.picture || '',
    name: currentFullUserProfile.name || '',
    preferredName: currentFullUserProfile.preferredName || '',
    workEmail: currentFullUserProfile.workEmail || currentAuthUser!.email,
    personalEmail: currentFullUserProfile.personalEmail || '',
    phoneNumber: currentFullUserProfile.phoneNumber || '',
    jobTitle: currentFullUserProfile.jobTitle || '',
    timeZone: currentFullUserProfile.timeZone || '',
  };

  const validationSchema = Yup.object()
    .shape(
      {
        name: Yup.string()
          .required(t('form.error.required', {ns: 'common'}))
          .max(255),
        workEmail: Yup.string()
          .email(t('form.error.bad-email-format', {ns: 'common'}))
          .required(t('form.error.required', {ns: 'common'})),
        personalEmail: Yup.string()
          .email(t('form.error.bad-email-format', {ns: 'common'}))
          .nullable(),
        phoneNumber: Yup.string().when('phoneNumber', {
          is: (value: string) => value?.length > 0,
          then: Yup.string()
            .phone(
              PHONE_NUMBER_VALIDATION_COUNTRY_CODE,
              PHONE_NUMBER_VALIDATION_STRICT,
              t('form.error.bad-phone-number-format', {ns: 'common'})
            )
            .required(),
          otherwise: Yup.string(),
        }),
        jobTitle: Yup.string()
          .required(t('form.error.required', {ns: 'common'}))
          .max(255),
        timeZone: Yup.string().required(
          t('form.error.required', {ns: 'common'})
        ),
      },
      [['phoneNumber', 'phoneNumber']]
    )
    .defined();

  const handleProfilePictureChange = useCallback(
    async (
      picture: File,
      form: FieldProps['form'],
      field: FieldProps['field']
    ) => {
      if (!picture) return;

      const config = await fetchPresignedUploadURL.mutateAsync({
        ressource: fetchPresignedUploadURLResourceType.USER_PROFILE,
        mime: picture.type,
        extension: picture.name.split('.').pop() as string,
        companyUuid: currentCompany.uuid,
      });

      if (!config) return;

      const bucketURL = await uploadToPresignedURL.mutateAsync({
        config,
        file: picture,
      });
      if (!bucketURL) return;

      form.setFieldValue(field.name, bucketURL);
    },
    [currentCompany, uploadToPresignedURL, fetchPresignedUploadURL]
  );

  return (
    <>
      <p>{t('create-your-profile.basic-profile-information.title')}</p>
      <Formik
        initialValues={EMPTY_VALUES}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        validateOnMount
      >
        {({errors, touched, isValid}) => (
          <Form>
            <div className={styles.formGrid}>
              <Field name="picture">
                {({field, form}: FieldProps<string>) => (
                  <div className={styles.profilePictureContainer}>
                    <Label htmlFor={field.name}>
                      {t(
                        'create-your-profile.basic-profile-information.form.profile-image.label'
                      )}
                    </Label>

                    <ImageUpload
                      label={t(
                        'create-your-profile.basic-profile-information.form.profile-image.component-label'
                      )}
                      fileCallback={(file) => {
                        handleProfilePictureChange(file, form, field);
                      }}
                      pictureUrl={currentFullUserProfile.picture}
                    />
                  </div>
                )}
              </Field>

              <Field name="name">
                {({field}: FieldProps<string>) => (
                  <div className={styles.nameContainer}>
                    <FormControl
                      error={
                        Boolean(errors.name && touched.name)
                          ? errors.name
                          : undefined
                      }
                    >
                      <Label isRequired htmlFor={field.name}>
                        {t(
                          'create-your-profile.basic-profile-information.form.name.label'
                        )}
                      </Label>

                      <Input
                        type="text"
                        placeholder={t(
                          'create-your-profile.basic-profile-information.form.name.placeholder'
                        )}
                        {...field}
                      />
                    </FormControl>
                  </div>
                )}
              </Field>

              <Field name="preferredName">
                {({field}: FieldProps<string>) => (
                  <div className={styles.preferredNameContainer}>
                    <FormControl>
                      <Label htmlFor={field.name}>
                        {t(
                          'create-your-profile.basic-profile-information.form.preferred-name.label'
                        )}
                      </Label>

                      <Input
                        type="text"
                        placeholder={t(
                          'create-your-profile.basic-profile-information.form.preferred-name.placeholder'
                        )}
                        {...field}
                      />
                    </FormControl>
                  </div>
                )}
              </Field>

              <Field name="workEmail">
                {({field}: FieldProps<string>) => (
                  <div className={styles.workEmailContainer}>
                    <FormControl
                      error={
                        Boolean(errors.workEmail && touched.workEmail)
                          ? errors.workEmail
                          : undefined
                      }
                    >
                      <Label isRequired htmlFor={field.name}>
                        {t(
                          'create-your-profile.basic-profile-information.form.email.label'
                        )}
                      </Label>

                      <Input
                        type="text"
                        placeholder={t(
                          'create-your-profile.basic-profile-information.form.email.placeholder'
                        )}
                        {...field}
                        disabled
                      />
                    </FormControl>
                  </div>
                )}
              </Field>

              <Field name="personalEmail">
                {({field}: FieldProps<string>) => (
                  <div className={styles.personalEmailContainer}>
                    <FormControl
                      error={
                        Boolean(errors.personalEmail && touched.personalEmail)
                          ? errors.personalEmail
                          : undefined
                      }
                    >
                      <Label htmlFor={field.name}>
                        {t(
                          'create-your-profile.basic-profile-information.form.personal-email.label'
                        )}
                      </Label>

                      <Input
                        type="text"
                        placeholder={t(
                          'create-your-profile.basic-profile-information.form.personal-email.placeholder'
                        )}
                        {...field}
                      />
                    </FormControl>
                  </div>
                )}
              </Field>

              <Field name="phoneNumber">
                {({field}: FieldProps<string>) => (
                  <div className={styles.phoneNumberContainer}>
                    <FormControl
                      error={
                        Boolean(errors.phoneNumber && touched.phoneNumber)
                          ? errors.phoneNumber
                          : undefined
                      }
                    >
                      <Label htmlFor={field.name}>
                        {t(
                          'create-your-profile.basic-profile-information.form.phone-number.label'
                        )}
                      </Label>

                      <Input
                        type="text"
                        placeholder={t(
                          'create-your-profile.basic-profile-information.form.phone-number.placeholder'
                        )}
                        {...field}
                      />
                    </FormControl>
                  </div>
                )}
              </Field>

              <Field name="jobTitle">
                {({field}: FieldProps<string>) => (
                  <div className={styles.jobTitleContainer}>
                    <FormControl
                      error={
                        Boolean(errors.jobTitle && touched.jobTitle)
                          ? errors.jobTitle
                          : undefined
                      }
                    >
                      <Label isRequired htmlFor={field.name}>
                        {t(
                          'create-your-profile.basic-profile-information.form.title-at-company.label'
                        )}
                      </Label>

                      <Input
                        type="text"
                        placeholder={t(
                          'create-your-profile.basic-profile-information.form.title-at-company.placeholder'
                        )}
                        {...field}
                      />
                    </FormControl>
                  </div>
                )}
              </Field>

              <Field name="timeZone">
                {({field, form, meta}: FieldProps<string>) => (
                  <div className={styles.timeZoneContainer}>
                    <FormControl
                      error={
                        Boolean(errors.timeZone && touched.timeZone)
                          ? errors.timeZone
                          : undefined
                      }
                    >
                      <Label isRequired htmlFor={field.name}>
                        {t(
                          'create-your-profile.basic-profile-information.form.time-zone.label'
                        )}
                      </Label>

                      <SearchableSelect
                        placeholder={t(
                          'create-your-profile.basic-profile-information.form.time-zone.placeholder'
                        )}
                        form={form}
                        field={field}
                        meta={meta}
                        options={timeZoneOptions}
                      />
                    </FormControl>
                  </div>
                )}
              </Field>
            </div>

            <FormFooter>
              <Button type="submit" disabled={!isValid}>
                {t(
                  'create-your-profile.basic-profile-information.form.save-button-text'
                )}
              </Button>
            </FormFooter>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default BasicProfileInformation;
