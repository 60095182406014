import {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import styles from './styles.module.scss';

const SidePanel: FunctionComponent = () => {
  const {t} = useTranslation('candidates', {
    keyPrefix: 'components.side-panel',
  });

  return (
    <div className={styles.container}>
      <h1 className={styles.heading}>{t('heading')}</h1>

      <div>
        <div className={styles.title}>{t('title')}</div>
        <div className={styles.paragraph}>{t('paragraph')}</div>
      </div>
    </div>
  );
};

export default SidePanel;
