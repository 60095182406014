import {
  ChangeEvent,
  FormEvent,
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  CANDIDATES_SORTABLE_COLUMNS,
  SUPPORTED_SORTABLE_COLUMNS,
} from '../../../../../../constants/filtering/sort-by';
import {AddCandidateFormProps} from './@components/add-candidate-dialog/@components/form';
import {EventName} from '../../../../../../constants/analytics/event-name';
import {EventType} from '../../../../../../constants/analytics/event-type';
import {
  ORDER_BY_PARAMETERS,
  SUPPORTED_ORDER_BY_PARAMETERS,
} from '../../../../../../constants/filtering/order-by';
import {ReactComponent as IconPlus} from '../../../../../@components/kit/icons/plus.svg';
import {SortedColumn} from '../../../../../../interfaces/filtering/sorted-column';
import {StringParam, createEnumParam, useQueryParams} from 'use-query-params';
import {useCreateCandidates} from '../../../../../@hooks/mutations/use-create-candidates';
import {useDebounce} from 'react-use';
import {useJobPositions, useCandidates} from '../../../../../@hooks/queries';
import {useSafeCurrentCompany} from '../../../../../@atoms/current-company';
import {useTranslation} from 'react-i18next';
import AddCandidateDialog from './@components/add-candidate-dialog';
import getActiveSortedColumnFromQueryParameters from '../../../../../../utils/get-active-sorted-column-from-query-params';
import ListView from './@components/list-view';
import NoResultsBox from '../../../../@components/no-results-box';
import Pagination from '../../../../../@components/pagination';
import RoundedCard from '../../../../../@components/rounded-card';
import SearchForm from '../../../../@components/search-form';
import Select from '../../../../../@components/kit/form/select';
import Spinner from '../../../../../@components/spinner';
import styles from './styles.module.scss';
import useAnalytics from '../../../../../@hooks/use-analytics';
import usePagination from '../../../../../@hooks/use-pagination';

const NUMBER_OF_ITEMS_PER_PAGE = 20;
const MINIMUM_SEARCH_LETTERS = 2;
const DELAY_FOR_SEARCH_DEBOUNCE_IN_MS = 500;

const CandidateList: FunctionComponent = () => {
  const {t} = useTranslation('jobs');

  const {trackEvent} = useAnalytics();
  const currentCompany = useSafeCurrentCompany();

  const [isAddCandidateDialogVisible, setIsAddCandidateDialogVisible] =
    useState(false);

  const [selectedJobPosition, setSelectedJobPosition] = useState<string>();

  const [queryParameters, setQueryParameters] = useQueryParams({
    jobPositionFilters: StringParam,
    orderBy: createEnumParam(SUPPORTED_ORDER_BY_PARAMETERS),
    query: StringParam,
    sortBy: createEnumParam(SUPPORTED_SORTABLE_COLUMNS.CANDIDATES),
  });

  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(
    queryParameters.query
  );

  const {
    currentPage,
    goToNextPage,
    goToPreviousPage,
    offset,
    onResetPagination,
    pageInQueryParams,
    setCurrentPage,
    setPageInQueryParams,
  } = usePagination({
    itemsPerPage: NUMBER_OF_ITEMS_PER_PAGE,
  });

  const candidates = useCandidates({
    variables: {
      companyUuid: currentCompany.uuid,
      currentPage,
      itemsPerPage: NUMBER_OF_ITEMS_PER_PAGE,
      limit: NUMBER_OF_ITEMS_PER_PAGE,
      offset,
      orderBy: queryParameters.orderBy ?? undefined,
      jobPositionFilters: queryParameters.jobPositionFilters ?? undefined,
      query: queryParameters.query ?? undefined,
      sortBy:
        (queryParameters.sortBy as CANDIDATES_SORTABLE_COLUMNS) ?? undefined,
    },
  });

  const jobPositions = useJobPositions({
    variables: {
      companyUuid: currentCompany.uuid,
    },
  });

  const addCandidates = useCreateCandidates();

  const isFetchingData = candidates.isLoading || jobPositions.isLoading;

  const [activeSortedColumn, setActiveSortedColumn] =
    useState<SortedColumn | null>(
      getActiveSortedColumnFromQueryParameters({
        orderBy: queryParameters.orderBy,
        sortBy: queryParameters.sortBy,
        supportedColumnsToSort: SUPPORTED_SORTABLE_COLUMNS.CANDIDATES,
      })
    );

  const jobPositionOptions = useMemo(() => {
    if (!jobPositions.data?.data) return;

    return jobPositions.data?.data
      .filter((jobPosition) => jobPosition.isActive)
      .map((jobPosition) => ({
        label: jobPosition.title,
        value: jobPosition.uuid,
      }));
  }, [jobPositions]);

  const onChangeJobPositionFilter = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const value = event.target.value ? event.target.value : undefined;

      setSelectedJobPosition(value);

      setQueryParameters({
        ...queryParameters,
        jobPositionFilters: value,
      });
    },
    [queryParameters, setQueryParameters]
  );

  const onSearch = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setDebouncedSearchQuery(value);
  }, []);

  const onClearSearch = useCallback(
    (event: FormEvent) => {
      event.preventDefault();

      setQueryParameters({
        ...queryParameters,
        query: undefined,
      });
    },
    [queryParameters, setQueryParameters]
  );

  const onColumnClicked = useCallback(
    (column: SortedColumn) => {
      setActiveSortedColumn(column);

      setQueryParameters({
        ...queryParameters,
        sortBy: column.columnName as CANDIDATES_SORTABLE_COLUMNS,
        orderBy: column.order as ORDER_BY_PARAMETERS,
      });

      if (!pageInQueryParams) return;

      setCurrentPage(1);
      setPageInQueryParams(1);
    },
    [
      queryParameters,
      pageInQueryParams,
      setCurrentPage,
      setQueryParameters,
      setPageInQueryParams,
    ]
  );

  const onShowAddCandidateDialog = useCallback(() => {
    setIsAddCandidateDialogVisible(true);

    trackEvent({
      eventName: EventName.JOBS.CANDIDATES.ADD_CANDIDATE,
      eventType: EventType.BUTTON_CLICKED,
    });
  }, [trackEvent]);

  const onHideAddCandidateDialog = useCallback(() => {
    setIsAddCandidateDialogVisible(false);
  }, []);

  const onAddCandidates = useCallback(
    async ({
      values,
      emails,
    }: {
      values: AddCandidateFormProps;
      emails: string[];
    }) => {
      const response = await addCandidates.mutateAsync({
        companyUuid: currentCompany.uuid,
        jobPositionUuid: values!.jobPosition,
        payload: {
          dueDate: values.dueDate,
          emails,
        },
      });

      candidates.refetch();

      return response;
    },
    [addCandidates, candidates, currentCompany]
  );

  const onRefreshCandidates = useCallback(() => {
    candidates.refetch();
  }, [candidates]);

  useDebounce(
    () => {
      if (
        debouncedSearchQuery !== '' &&
        (debouncedSearchQuery?.length ?? 0) < MINIMUM_SEARCH_LETTERS
      ) {
        return;
      }

      setQueryParameters({
        ...queryParameters,
        query: debouncedSearchQuery,
      });

      if (!pageInQueryParams) return;
      onResetPagination();
    },
    DELAY_FOR_SEARCH_DEBOUNCE_IN_MS,
    [debouncedSearchQuery]
  );

  return (
    <>
      {isAddCandidateDialogVisible && (
        <AddCandidateDialog
          isDialogVisible={isAddCandidateDialogVisible}
          jobPositionOptions={jobPositionOptions}
          jobPositions={jobPositions.data?.data}
          onClose={onHideAddCandidateDialog}
          onSubmit={onAddCandidates}
        />
      )}

      <RoundedCard.Header>
        <div className={styles.header}>
          <RoundedCard.Title>
            {t('candidates.components.candidate-list.title')}

            <button
              type="button"
              className={styles.button}
              aria-label={t(
                'candidates.components.candidate-list.add-candidate-button-accessibility-text'
              )}
              onClick={onShowAddCandidateDialog}
            >
              <IconPlus className={styles.icon} />
            </button>
          </RoundedCard.Title>

          <div className={styles.headerColumn}>
            {jobPositions.data?.data && (
              <div className={styles.dropdownContainer}>
                <Select
                  value={selectedJobPosition}
                  onChange={onChangeJobPositionFilter}
                >
                  <option value="">
                    {t(
                      'candidates.components.candidate-list.job-position-filter.placeholder'
                    )}
                  </option>

                  {jobPositions.data?.data?.map((jobPosition) => (
                    <option key={jobPosition.uuid} value={jobPosition.uuid}>
                      {jobPosition.title}
                    </option>
                  ))}
                </Select>
              </div>
            )}

            <SearchForm
              searchQueryInQueryParams={queryParameters.query}
              onSearch={onSearch}
              placeholder={t(
                'candidates.components.candidate-list.search-box-placeholder'
              )}
              onClearSearch={onClearSearch}
            />
          </div>
        </div>
      </RoundedCard.Header>

      <RoundedCard.Content>
        {isFetchingData ? (
          <Spinner />
        ) : (
          <>
            {candidates.data?.data.length && jobPositions.data?.data.length ? (
              <>
                <ListView
                  activeSortedColumn={activeSortedColumn}
                  candidates={candidates.data?.data}
                  jobPositions={jobPositions.data?.data}
                  jobPositionOptions={jobPositionOptions}
                  onColumnClicked={onColumnClicked}
                  onRefreshCandidates={onRefreshCandidates}
                />

                {candidates.data?.meta?.totalEntries && (
                  <Pagination
                    currentPage={currentPage}
                    itemsPerPage={NUMBER_OF_ITEMS_PER_PAGE}
                    onNextPageLinkClicked={goToNextPage}
                    onPreviousPageLinkClicked={goToPreviousPage}
                    totalItems={candidates.data?.meta.totalEntries}
                  />
                )}
              </>
            ) : (
              <NoResultsBox />
            )}
          </>
        )}
      </RoundedCard.Content>
    </>
  );
};

export default CandidateList;
