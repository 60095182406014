import {JobPositionCreate} from '../../../interfaces/job-position';
import {sanitizeJobPositionCreateForRaw} from '../../../ui/@sanitizers/job-position';
import InstillAPI from '../axios-instance';
import isResponseSuccessful from '../../../utils/is-response-successful';

export interface CreateJobPositionPayload {
  companyUuid: string;
  values: JobPositionCreate;
}

export async function createJobPosition(parameters: CreateJobPositionPayload) {
  const {values, companyUuid} = parameters;

  const sanitizedPayload = sanitizeJobPositionCreateForRaw(values);
  const response = await InstillAPI.post(
    `/companies/${companyUuid}/job-positions`,
    sanitizedPayload
  );

  if (!isResponseSuccessful(response)) {
    throw Error('No data received');
  }

  return response.data;
}
