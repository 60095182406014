import {FunctionComponent, PropsWithChildren} from 'react';
import styles from './styles.module.scss';

interface ElementProps {
  title?: string;
}

const Title: FunctionComponent<PropsWithChildren<ElementProps>> = ({
  title,
  children,
}) => {
  return <div className={styles.title}>{children ? children : title}</div>;
};

export default Title;
