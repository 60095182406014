import {Actions} from '../../../../constants/permissions/actions';
import {FunctionComponent, useCallback, useMemo} from 'react';
import {getUserProfileForCompany} from '../../../../services/user-profiles';
import {Link, NavLink} from 'react-router-dom';
import {ReactComponent as IconAperture} from '../../../@components/kit/icons/aperture.svg';
import {ReactComponent as IconDesign} from './assets/pen-tool.svg';
import {ReactComponent as IconHome} from './assets/home.svg';
import {ReactComponent as IconLogout} from '../../../@components/kit/icons/log-out.svg';
import {ReactComponent as IconMeasure} from '../../../@components/kit/icons/activity.svg';
import {ReactComponent as IconPeople} from '../../../@components/kit/icons/users.svg';
import {ReactComponent as IconSettings} from '../../../@components/kit/icons/settings.svg';
import {ReactComponent as IconTool} from './assets/tool.svg';
import {ReactComponent as IconUserPlus} from '../../../@components/kit/icons/user-plus.svg';
import {ReactComponent as IconUsers} from '../../../@components/kit/icons/users.svg';
import {useCurrentAuthUserState} from '../../../@atoms/current-auth-user';
import {useSafeCurrentCompany} from '../../../@atoms/current-company';
import {useTranslation} from 'react-i18next';
import {useUserPermission} from '../../../@hooks/use-user-permission';
import logoSource from './assets/logo.png';
import styles from './styles.module.scss';

interface ElementProps {
  variant?: 'standalone' | 'mobile';
  onNavigationLinkClicked?: () => void;
}

const SidebarNavigation: FunctionComponent<ElementProps> = ({
  variant,
  onNavigationLinkClicked,
}) => {
  const {t} = useTranslation('application');

  const [currentAuthUser] = useCurrentAuthUserState();
  const currentCompany = useSafeCurrentCompany();

  const membership = getUserProfileForCompany(currentAuthUser!, currentCompany);
  const can = useUserPermission(membership.role);

  const containerClassName = variant
    ? `${styles[variant]} ${styles.container}`
    : styles.container;

  const onLinkClicked = useCallback(() => {
    if (!onNavigationLinkClicked) return;

    onNavigationLinkClicked();
  }, [onNavigationLinkClicked]);

  const isJobsLinkVisible = useMemo(() => {
    return can(Actions.VIEW_JOBS);
  }, [can]);

  return (
    <div className={containerClassName}>
      <div className={styles.wrapper}>
        <nav className={styles.navigation}>
          <img
            src={logoSource}
            alt=""
            className={styles.logo}
            role="presentation"
          />

          <ul className={styles.list}>
            <li className={styles.item}>
              <NavLink
                className={({isActive}) =>
                  isActive ? styles.activeLink : styles.link
                }
                onClick={onLinkClicked}
                to="/application/dashboard"
              >
                <IconHome className={styles.linkIcon} />

                <span className={styles.linkText}>
                  {t('components.navigation.links.home')}
                </span>
              </NavLink>
            </li>

            <li className={styles.item}>
              <NavLink
                className={({isActive}) =>
                  isActive ? styles.activeLink : styles.link
                }
                onClick={onLinkClicked}
                to="/application/design"
              >
                <IconDesign className={styles.linkIcon} />

                <span className={styles.linkText}>
                  {t('components.navigation.links.design')}
                </span>
              </NavLink>
            </li>

            <li className={styles.item}>
              <NavLink
                className={({isActive}) =>
                  isActive ? styles.activeLink : styles.link
                }
                onClick={onLinkClicked}
                to="/application/measure"
              >
                <IconMeasure className={styles.linkIcon} />

                <span className={styles.linkText}>
                  {t('components.navigation.links.measure')}
                </span>
              </NavLink>
            </li>

            <li className={styles.item}>
              <NavLink
                className={({isActive}) =>
                  isActive ? styles.activeLink : styles.link
                }
                onClick={onLinkClicked}
                to="/application/apply"
              >
                <IconTool className={styles.linkIcon} />

                <span className={styles.linkText}>
                  {t('components.navigation.links.apply')}
                </span>
              </NavLink>
            </li>

            <li className={styles.item}>
              <NavLink
                className={({isActive}) =>
                  isActive ? styles.activeLink : styles.link
                }
                onClick={onLinkClicked}
                to="/application/sense"
              >
                <IconAperture className={styles.linkIcon} />

                <span className={styles.linkText}>
                  {t('components.navigation.links.sense')}
                </span>
              </NavLink>
            </li>

            <li className={styles.item}>
              <NavLink
                className={({isActive}) =>
                  isActive ? styles.activeLink : styles.link
                }
                onClick={onLinkClicked}
                to="/application/people"
              >
                <IconPeople className={styles.linkIcon} />

                <span className={styles.linkText}>
                  {t('components.navigation.links.people')}
                </span>
              </NavLink>
            </li>

            {isJobsLinkVisible && (
              <li className={styles.item}>
                <NavLink
                  className={({isActive}) =>
                    isActive ? styles.activeLink : styles.link
                  }
                  onClick={onLinkClicked}
                  to="/application/jobs"
                >
                  <IconUserPlus className={styles.linkIcon} />

                  <span className={styles.linkText}>
                    {t('components.navigation.links.jobs')}
                  </span>
                </NavLink>
              </li>
            )}

            <li className={styles.mobileItem}>
              <NavLink
                className={({isActive}) =>
                  isActive ? styles.activeLink : styles.link
                }
                onClick={onLinkClicked}
                to="/application/my-profile"
              >
                <IconUsers className={styles.linkIcon} />

                <span className={styles.linkText}>
                  {t('components.navigation.links.my-profile')}
                </span>
              </NavLink>
            </li>
          </ul>
        </nav>

        <nav className={styles.navigation}>
          <ul className={styles.list}>
            <li className={styles.item}>
              <NavLink
                to="/application/settings"
                className={({isActive}) =>
                  isActive ? styles.activeLink : styles.link
                }
                onClick={onLinkClicked}
              >
                <IconSettings className={styles.linkIcon} />

                <span className={styles.linkText}>
                  {t('components.navigation.links.settings')}
                </span>
              </NavLink>
            </li>

            <li className={styles.item}>
              <Link
                to="/logout"
                className={styles.link}
                onClick={onLinkClicked}
              >
                <IconLogout className={styles.linkIcon} />

                <span className={styles.linkText}>
                  {t('components.navigation.links.logout')}
                </span>
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default SidebarNavigation;
