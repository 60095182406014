import {useTranslation} from 'react-i18next';
import {FunctionComponent} from 'react';
import styles from './styles.module.scss';
import RoundedCard from '../../../../../@components/rounded-card';

const NoAssessmentsBox: FunctionComponent = () => {
  const {t} = useTranslation('measure');

  return (
    <>
      <RoundedCard>
        <RoundedCard.Content>
          <div className={styles.container}>
            <div className={styles.title}>
              {t('your-assessment.components.no-assessment-box.title')}
            </div>

            <div className={styles.text}>
              {t('your-assessment.components.no-assessment-box.text')}
            </div>
          </div>
        </RoundedCard.Content>
      </RoundedCard>
    </>
  );
};

export default NoAssessmentsBox;
