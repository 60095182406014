import {buildPageTitle} from '../../../../services/build-page-title';
import {EventName} from '../../../../constants/analytics/event-name';
import {EventType} from '../../../../constants/analytics/event-type';
import {FunctionComponent, useEffect} from 'react';
import {Helmet} from 'react-helmet-async';
import {useCurrentFullUserProfile} from '../../../@atoms/current-full-user-profile';
import {useTranslation} from 'react-i18next';
import {useUserAssessments} from '../../../@hooks/queries';
import Assessment from './@components/assessment';
import mainContainerStyles from '../../../../styles/classes/main-container.module.scss';
import NoAssessmentsBox from './@components/no-assessments-box';
import Spinner from '../../../@components/spinner';
import styles from './styles.module.scss';
import useAnalytics from '../../../@hooks/use-analytics';

const YourAssessments: FunctionComponent = () => {
  const {t} = useTranslation('applicationPageTitle');

  const currentFullUserProfile = useCurrentFullUserProfile();
  const {trackEvent} = useAnalytics();

  const assessments = useUserAssessments({
    variables: {
      userProfileUuid: currentFullUserProfile.uuid,
    },
  });

  useEffect(() => {
    trackEvent({
      eventName: EventName.PAGE_VIEWS.MEASURE.YOUR_ASSESSMENTS,
      eventType: EventType.PAGE_VIEW,
    });
  }, [trackEvent]);

  return (
    <>
      <Helmet>
        <title>
          {buildPageTitle([
            t('page-title.measure-your-culture.your-assessments', {
              ns: 'applicationPageTitle',
            }),
            t('page-title.measure-your-culture.index', {
              ns: 'applicationPageTitle',
            }),
          ])}
        </title>
      </Helmet>

      <main className={mainContainerStyles.container}>
        {assessments.isLoading ? (
          <Spinner />
        ) : (
          <>
            {assessments.data?.length ? (
              <ul className={styles.list}>
                {assessments.data?.map((assessment) => (
                  <li key={assessment.uuid} className={styles.item}>
                    <Assessment assessment={assessment} />
                  </li>
                ))}
              </ul>
            ) : (
              <NoAssessmentsBox />
            )}
          </>
        )}
      </main>
    </>
  );
};

export default YourAssessments;
