import {useAuth0} from '@auth0/auth0-react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as ArrowPaleIcon} from '../../../../../@components/kit/icons/arrow-pale.svg';
import {ReactComponent as ExitIcon} from '../../../../../@components/kit/icons/exit-icon.svg';
import {ReactComponent as SadSmileyIcon} from '../../../../../@components/kit/icons/sad-smiley.svg';
import {ReactComponent as CursorIcon} from '../../../../../@components/kit/icons/cursor.svg';
import Button from '../../../../../@components/kit/form/button';
import styles from './styles.module.scss';

function GenericError() {
  const {logout} = useAuth0();
  const {t} = useTranslation('authFeedback');

  return (
    <>
      <h1 className={styles.title}>{t('components.generic-error.title')}</h1>
      <p className={styles.text}>{t('components.generic-error.text')}</p>

      <div className={styles.icons}>
        <ExitIcon className={styles.icon} />
        <ArrowPaleIcon className={styles.icon} />
        <CursorIcon className={styles.icon} />
        <ArrowPaleIcon className={styles.icon} />
        <SadSmileyIcon className={styles.icon} />
      </div>

      <Button
        className={styles.button}
        onClick={() => logout({returnTo: window.location.origin})}
      >
        {t('components.generic-error.button')}
      </Button>
    </>
  );
}

export default GenericError;
