import {Field, FieldProps} from 'formik';
import {FunctionComponent, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {USER_PROFILE_STATUS_JOINED} from '../../../../../../constants/user-profile-status';
import {FullUserProfile} from '../../../../../../interfaces/user-profile';
import {useSafeCurrentCompany} from '../../../../../@atoms/current-company';
import AvatarPlaceholder from '../../../../../@components/avatar-placeholder';
import Button from '../../../../../@components/kit/form/button';
import ImageUpload from '../../../../../@components/kit/form/image-upload/image-upload';
import Tag from '../../../../../@components/tag';
import styles from './styles.module.scss';

interface ElementProps {
  handleSubmit: () => void;
  isInEditMode: boolean;
  isSaveButtonDisabled: boolean;
  isSourceEqualsToMyProfile: boolean;
  onCancelButtonClicked: (callback: () => void) => void;
  onEditButtonClicked: () => void;
  onProfilePictureChange: (
    picture: File,
    form: FieldProps['form'],
    field: FieldProps['field']
  ) => void;
  profile: FullUserProfile;
  resetForm: () => void;
}

const Header: FunctionComponent<ElementProps> = ({
  handleSubmit,
  isInEditMode,
  isSaveButtonDisabled,
  isSourceEqualsToMyProfile,
  onCancelButtonClicked,
  onEditButtonClicked,
  onProfilePictureChange,
  profile,
  resetForm,
}) => {
  const {t} = useTranslation('application');

  const currentCompany = useSafeCurrentCompany();

  const isEditButtonVisible = useMemo(() => {
    if (isInEditMode) return false;

    return isSourceEqualsToMyProfile;
  }, [isInEditMode, isSourceEqualsToMyProfile]);

  const isActionColumnVisible = useMemo(() => {
    if (!isInEditMode) {
      if (
        isSourceEqualsToMyProfile &&
        profile.status === USER_PROFILE_STATUS_JOINED
      )
        return true;
      if (isEditButtonVisible) return true;
    }

    if (isInEditMode) return true;
  }, [isInEditMode, isSourceEqualsToMyProfile, profile, isEditButtonVisible]);

  const isHRISProviderUKG = currentCompany.companySettings.isHRISProviderUKG;

  return (
    <header className={styles.header}>
      <div className={styles.column}>
        <div className={styles.profileContainer}>
          <div className={styles.pictureContainer}>
            <Field name="picture">
              {({field, form}: FieldProps<string>) => (
                <>
                  {isInEditMode ? (
                    <ImageUpload
                      label={t(
                        'components.profile-details.components.header.profile-picture'
                      )}
                      fileCallback={(file) => {
                        onProfilePictureChange(file, form, field);
                      }}
                      pictureUrl={profile.picture}
                      disabled={isHRISProviderUKG}
                    />
                  ) : (
                    <>
                      {profile.picture ? (
                        <img
                          src={profile.picture}
                          className={styles.picture}
                          alt=""
                        />
                      ) : (
                        <AvatarPlaceholder
                          size="medium"
                          variant="dark-blue"
                          name={profile.name}
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </Field>
          </div>

          <div className={styles.profile}>
            <div className={styles.name}>{profile.name}</div>

            {profile.jobTitle && (
              <div className={styles.jobTitle}>
                <Tag>{profile.jobTitle}</Tag>
              </div>
            )}
          </div>
        </div>
      </div>

      {isActionColumnVisible && (
        <div className={styles.column}>
          <ul className={styles.actions}>
            {isInEditMode ? (
              <>
                <li className={styles.actionItem}>
                  <Button
                    variant="secondary"
                    onClick={() => onCancelButtonClicked(resetForm)}
                  >
                    {t(
                      'components.profile-details.components.header.cancel-button'
                    )}
                  </Button>
                </li>

                <li className={styles.actionItem}>
                  <Button
                    onClick={handleSubmit}
                    disabled={isSaveButtonDisabled}
                  >
                    {t(
                      'components.profile-details.components.header.save-button'
                    )}
                  </Button>
                </li>
              </>
            ) : (
              <>
                {isSourceEqualsToMyProfile &&
                  profile.status === USER_PROFILE_STATUS_JOINED && (
                    <li className={styles.actionItem}>
                      <Link
                        to={`/application/people/employees/${profile.uuid}`}
                        className={styles.link}
                      >
                        {t(
                          'components.profile-details.components.header.see-public-profile'
                        )}
                      </Link>
                    </li>
                  )}

                {isEditButtonVisible && (
                  <li className={styles.actionItem}>
                    <Button onClick={onEditButtonClicked}>
                      {t(
                        'components.profile-details.components.header.edit-button'
                      )}
                    </Button>
                  </li>
                )}
              </>
            )}
          </ul>
        </div>
      )}
    </header>
  );
};

export default Header;
