import {
  FunctionComponent,
  TextareaHTMLAttributes,
  useLayoutEffect,
  useRef,
} from 'react';
import {useFormControlContext} from '../form-control';
import styles from './styles.module.scss';

interface AutoresizeTextareaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  variant?: 'disabled';
  hasError?: boolean;
}

const AutoresizeTextarea: FunctionComponent<AutoresizeTextareaProps> = ({
  variant,
  ...props
}) => {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const {hasError} = useFormControlContext();

  useLayoutEffect(() => {
    if (!textareaRef.current) return;

    textareaRef.current.style.height = 'inherit';
    textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
  }, [props.value]);

  let className = styles.textarea;

  if (hasError) {
    className = `${className} ${styles.error}`;
  }

  if (variant) {
    className = `${className} ${styles[variant]}`;
  }

  return (
    <>
      <textarea className={className} ref={textareaRef} rows={1} {...props} />
    </>
  );
};

export default AutoresizeTextarea;
