import {ArrayParam, StringParam, useQueryParams} from 'use-query-params';
import {buildPageTitle} from '../../../../services/build-page-title';
import {
  DEFAULT_TIMESCALE,
  SUPPORTED_TIMESCALE_PARAMETERS,
} from '../../../../constants/timescale-value';
import {Dimension} from '../../../../interfaces/dimension';
import {EventName} from '../../../../constants/analytics/event-name';
import {EventType} from '../../../../constants/analytics/event-type';
import {fetchOrganizationalHealth} from '../../../../repositories/instill';
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {Helmet} from 'react-helmet-async';
import {isDate} from '../../../../utils/date';
import {OrganizationalHealth} from '../../../../interfaces/organizational-health';
import {OVERALL_HEALTH_FILTERS_STORAGE_KEY} from '../../../../constants/local-storage-keys';
import {
  TIMESCALE_VALUES,
  TIMESCALE_VALUES_DELTA,
} from '../../../../constants/timescale-value';
import {TimeseriesData} from '../../../@components/kit/charts/timeseries-line';
import {useDimensions} from '../../../@hooks/queries';
import {useSafeCurrentCompany} from '../../../@atoms/current-company';
import {useTransition, animated} from 'react-spring';
import {useTranslation} from 'react-i18next';
import Filters from './@components/filters';
import HealthGauges from './@components/health-gauges';
import mainContainerStyles from '../../../../styles/classes/main-container.module.scss';
import RoundedCard from '../../../@components/rounded-card';
import Spinner from '../../../@components/spinner';
import styles from './styles.module.scss';
import TimeseriesLine from '../../../@components/kit/charts/timeseries-line';
import useAnalytics from '../../../@hooks/use-analytics';
import {
  CULTURE_VITAL_SIGN_COLORS,
  CULTURE_VITAL_SIGN_SLUGS,
  SUPPORTED_CULTURE_VITAL_SIGNS,
} from '../../../../constants/culture-vital-signs';

export interface CustomTimescale {
  startDate: Date | null;
  endDate: Date | null;
}

interface FetchOrganizationalHealthPayload {
  dimensions?: string[];
  endDate?: string;
  startDate?: string;
  timescale?: TIMESCALE_VALUES;
}

interface FiltersInStorage {
  dimensions?: string[];
  endDate?: string;
  startDate?: string;
  timescale?: TIMESCALE_VALUES;
}

interface GetSelectedDimensions {
  defaultDimensions: Dimension[];
  dimensions: Dimension[];
  queryParams?: Dimension[];
  storage?: string[];
}

const OverallHealth: FunctionComponent = () => {
  const {t} = useTranslation(['measure', 'applicationPageTitle']);

  const {trackEvent} = useAnalytics();
  const currentCompany = useSafeCurrentCompany();

  const [selectedDimensions, setSelectedDimensions] = useState<Dimension[]>();
  const [highlightedDimensionSlug, setHighlightedDimensionSlug] = useState<
    string | null
  >(null);

  const [organizationalHealthData, setOrganizationalHealthData] =
    useState<OrganizationalHealth>();

  const [queryParameters, setQueryParameters] = useQueryParams({
    dimensions: ArrayParam,
    endDate: StringParam,
    startDate: StringParam,
    timescale: StringParam,
  });

  const isLoading = !Boolean(organizationalHealthData);

  const dimensions = useDimensions({
    variables: {
      companyUuid: currentCompany.uuid,
    },
    onSuccess: (data) => {
      onFetchData(data);
    },
  });

  const isTimeseriesLineVisible = useMemo(() => {
    return organizationalHealthData?.some(
      (dimension) => dimension.dataset.length
    );
  }, [organizationalHealthData]);

  const filters = useMemo(() => {
    const filtersFromStorage = localStorage.getItem(
      OVERALL_HEALTH_FILTERS_STORAGE_KEY
    );

    return filtersFromStorage ? JSON.parse(filtersFromStorage) : '';
  }, []);

  const [selectedTimescale, setSelectedTimescale] = useState<
    TIMESCALE_VALUES | undefined
  >(() => {
    if (queryParameters.timescale) {
      return queryParameters.timescale as TIMESCALE_VALUES;
    }

    if (
      filters?.timescale &&
      SUPPORTED_TIMESCALE_PARAMETERS.includes(filters?.timescale)
    ) {
      return filters.timescale;
    }

    return DEFAULT_TIMESCALE;
  });

  const onDimensionClicked = useCallback(
    (slug: string) => {
      if (highlightedDimensionSlug === slug) {
        setHighlightedDimensionSlug(null);
        return;
      }

      setHighlightedDimensionSlug(slug);
    },
    [highlightedDimensionSlug]
  );

  const getDatesFromTimescale = useCallback(
    async (value?: TIMESCALE_VALUES) => {
      const now = new Date();

      const startDate = now;
      const endDate = new Date();

      switch (value) {
        case TIMESCALE_VALUES.THREE_MONTH:
          startDate.setMonth(
            now.getMonth() -
              TIMESCALE_VALUES_DELTA[TIMESCALE_VALUES.THREE_MONTH]
          );
          break;
        case TIMESCALE_VALUES.SIX_MONTH:
          startDate.setMonth(
            now.getMonth() - TIMESCALE_VALUES_DELTA[TIMESCALE_VALUES.SIX_MONTH]
          );
          break;
        case TIMESCALE_VALUES.ONE_YEAR:
          startDate.setMonth(
            now.getMonth() - TIMESCALE_VALUES_DELTA[TIMESCALE_VALUES.ONE_YEAR]
          );
          break;
        case TIMESCALE_VALUES.CUSTOM:
        default:
          break;
      }

      return {startDate, endDate};
    },
    []
  );

  const onGetDates = useCallback(
    async (parameters: {
      endDate?: string;
      startDate?: string;
      timescale?: TIMESCALE_VALUES;
    }) => {
      const {timescale, startDate, endDate} = parameters;

      let updatedStartDate;
      let updatedEndDate;

      const defaultDates = await getDatesFromTimescale(
        timescale ?? selectedTimescale
      );

      updatedStartDate = defaultDates.startDate.toISOString();
      updatedEndDate = defaultDates.endDate.toISOString();

      if (timescale === TIMESCALE_VALUES.CUSTOM) {
        if (startDate && endDate) {
          if (isDate(startDate) && isDate(endDate)) {
            updatedStartDate = startDate;
            updatedEndDate = endDate;
          }
        } else if (queryParameters.startDate && queryParameters.endDate) {
          if (
            isDate(queryParameters.startDate) &&
            isDate(queryParameters.endDate)
          ) {
            updatedStartDate = queryParameters.startDate;
            updatedEndDate = queryParameters.endDate;
          }
        } else if (filters.startDate && filters.endDate) {
          if (isDate(filters.startDate) && isDate(filters.endDate)) {
            updatedStartDate = filters.startDate;
            updatedEndDate = filters.endDate;
          }
        }
      }

      return {startDate: updatedStartDate, endDate: updatedEndDate};
    },
    [filters, getDatesFromTimescale, queryParameters, selectedTimescale]
  );

  const onFetchOrganizationalHealth = useCallback(
    async (parameters: FetchOrganizationalHealthPayload) => {
      const timescale = parameters.timescale ?? filters.timescale;

      let dimensions = (parameters?.dimensions ??
        queryParameters.dimensions) as string[];

      if (!dimensions) {
        dimensions = selectedDimensions!.map((dimension) => dimension.slug);
      }

      const {startDate, endDate} = await onGetDates({
        timescale,
        startDate:
          parameters.startDate ?? queryParameters.startDate?.toString(),
        endDate: parameters.endDate ?? queryParameters.endDate?.toString(),
      });

      const companyUuid = currentCompany.uuid;

      const payload = {
        companyUuid,
        dimensionsSlug: dimensions,
        endDate: endDate,
        startDate: startDate,
      };

      const response = await fetchOrganizationalHealth(payload);
      if (!response) return;

      setOrganizationalHealthData(response);
    },
    [currentCompany, filters, onGetDates, queryParameters, selectedDimensions]
  );

  const onGetDefaultDimensions = useCallback((dimensions: Dimension[]) => {
    return dimensions.filter((dimension) => {
      const slug = dimension.slug as CULTURE_VITAL_SIGN_SLUGS;

      if (!SUPPORTED_CULTURE_VITAL_SIGNS.includes(slug)) return false;

      return SUPPORTED_CULTURE_VITAL_SIGNS.includes(slug);
    });
  }, []);

  const onGetDimensionsBasedOnSlugs = useCallback(
    (slugs: string[], dimensions: Dimension[]) => {
      if (!slugs || !dimensions) return;

      return slugs.flatMap((dimensionFromQueryParam) => {
        return dimensions.filter((dimension) => {
          return dimension.slug === dimensionFromQueryParam;
        });
      });
    },
    []
  );

  const onGetSelectedDimensions = useCallback(
    (parameters: GetSelectedDimensions) => {
      const {queryParams, storage, dimensions, defaultDimensions} = parameters;

      if (storage) {
        if (!dimensions) return;

        return onGetDimensionsBasedOnSlugs(storage, dimensions);
      }

      if (queryParams && queryParams.length > 0) return queryParams;

      return defaultDimensions;
    },
    [onGetDimensionsBasedOnSlugs]
  );

  const onFetchData = useCallback(
    async (dimensions: Dimension[] | undefined) => {
      if (!dimensions) return;

      const defaultDimensions = await onGetDefaultDimensions(dimensions);
      const queryParams = queryParameters.dimensions
        ? onGetDimensionsBasedOnSlugs(
            queryParameters.dimensions as string[],
            dimensions
          )
        : [];

      const selectedDimensions = onGetSelectedDimensions({
        storage: filters?.dimensions,
        queryParams,
        defaultDimensions: defaultDimensions ?? dimensions[0],
        dimensions,
      });

      setSelectedDimensions(selectedDimensions);

      // setQueryParam
      // dimensions
      onFetchOrganizationalHealth({
        timescale: queryParameters.timescale as TIMESCALE_VALUES,
        dimensions: selectedDimensions!.map((dimension) => dimension.slug),
      });
    },
    [
      filters,
      onFetchOrganizationalHealth,
      onGetDefaultDimensions,
      onGetDimensionsBasedOnSlugs,
      onGetSelectedDimensions,
      queryParameters,
    ]
  );

  const onUpdateFiltersForStorage = useCallback(
    (parameters: FiltersInStorage) => {
      const filtersFromStorage = localStorage.getItem(
        OVERALL_HEALTH_FILTERS_STORAGE_KEY
      );

      const currentFilters = filtersFromStorage
        ? JSON.parse(filtersFromStorage)
        : '';

      const payload = {
        ...currentFilters,
        ...parameters,
      };

      if (payload.timescale !== TIMESCALE_VALUES.CUSTOM) {
        payload.startDate = undefined;
        payload.endDate = undefined;
      }

      localStorage.setItem(
        OVERALL_HEALTH_FILTERS_STORAGE_KEY,
        JSON.stringify(payload)
      );
    },
    []
  );

  const onTimescaleChange = useCallback(
    (value: TIMESCALE_VALUES) => {
      setSelectedTimescale(value);
      setQueryParameters({
        ...queryParameters,
        timescale: value,
      });

      if (value !== TIMESCALE_VALUES.CUSTOM) {
        setQueryParameters({
          ...queryParameters,
          timescale: value,
          startDate: null,
          endDate: null,
        });
      }

      onUpdateFiltersForStorage({timescale: value});
      onFetchOrganizationalHealth({timescale: value});
    },
    [
      onFetchOrganizationalHealth,
      onUpdateFiltersForStorage,
      queryParameters,
      setQueryParameters,
    ]
  );

  const onDimensionsChange = useCallback(
    (dimension: Dimension) => {
      if (!selectedDimensions) return;

      const isDimensionAlreadySelected = selectedDimensions.includes(dimension);
      const updatedDimensions = [...selectedDimensions];

      if (isDimensionAlreadySelected) {
        const indexOfElementToRemove = selectedDimensions.indexOf(dimension);
        updatedDimensions.splice(indexOfElementToRemove, 1);
      } else {
        updatedDimensions.push(dimension);
      }

      const dimensionSlugs = updatedDimensions.map(
        (dimension) => dimension.slug
      );

      setSelectedDimensions(updatedDimensions);

      setQueryParameters({
        ...queryParameters,
        dimensions: dimensionSlugs,
      });

      onUpdateFiltersForStorage({dimensions: dimensionSlugs});
      onFetchOrganizationalHealth({dimensions: dimensionSlugs});
    },
    [
      onFetchOrganizationalHealth,
      onUpdateFiltersForStorage,
      queryParameters,
      selectedDimensions,
      setQueryParameters,
    ]
  );

  const onCustomTimescaleSelected = useCallback(
    ({startDate, endDate}: CustomTimescale) => {
      if (!startDate || !endDate) return;

      const startDateAsString = startDate.toISOString();
      const endDateAsString = endDate.toISOString();

      setSelectedTimescale(TIMESCALE_VALUES.CUSTOM);

      setQueryParameters({
        ...queryParameters,
        timescale: TIMESCALE_VALUES.CUSTOM,
        startDate: startDateAsString,
        endDate: endDateAsString,
      });

      onUpdateFiltersForStorage({
        endDate: endDateAsString,
        startDate: startDateAsString,
        timescale: TIMESCALE_VALUES.CUSTOM,
      });

      onFetchOrganizationalHealth({
        endDate: endDateAsString,
        startDate: startDateAsString,
        timescale: TIMESCALE_VALUES.CUSTOM,
      });
    },
    [
      onFetchOrganizationalHealth,
      onUpdateFiltersForStorage,
      queryParameters,
      setQueryParameters,
    ]
  );

  const transitions = useTransition(isLoading, {
    from: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
    },
    leave: {
      opacity: 0,
    },
  });

  const processDataForTimeseries = useCallback(() => {
    const data = organizationalHealthData;
    if (!data) return;

    return data.map((dimension) => {
      const isSelected = highlightedDimensionSlug === dimension.dimensionSlug;

      let color =
        CULTURE_VITAL_SIGN_COLORS[
          dimension.dimensionSlug as keyof typeof CULTURE_VITAL_SIGN_COLORS
        ].NORMAL;

      if (highlightedDimensionSlug && !isSelected) {
        color = `${color}40`;
      }

      return {
        name: dimension.dimensionName,
        data: dimension.dataset.map((dataPoint) => [
          dataPoint.eventStartDate,
          dataPoint.average,
        ]),
        color,
      };
    });
  }, [highlightedDimensionSlug, organizationalHealthData]);

  useEffect(() => {
    trackEvent({
      eventName: EventName.PAGE_VIEWS.MEASURE.OVERALL_HEALTH,
      eventType: EventType.PAGE_VIEW,
    });
  }, [trackEvent]);

  if (!selectedDimensions) return null;

  return (
    <>
      <Helmet>
        <title>
          {buildPageTitle([
            t('page-title.measure-your-culture.overall-health', {
              ns: 'applicationPageTitle',
            }),
            t('page-title.measure-your-culture.index', {
              ns: 'applicationPageTitle',
            }),
          ])}
        </title>
      </Helmet>

      {dimensions.data?.length ? (
        <main className={mainContainerStyles.containerVertical}>
          <RoundedCard spacing="small">
            <div className={styles.overlay}>
              {transitions(
                (props, item) =>
                  item && (
                    <animated.div
                      className={styles.overlayContainer}
                      style={props}
                    >
                      <Spinner variant="large" />
                    </animated.div>
                  )
              )}
            </div>

            {dimensions && (
              <Filters
                dimensions={dimensions.data}
                endDateInQueryParams={queryParameters.endDate}
                onCustomTimescaleSelected={onCustomTimescaleSelected}
                onDimensionsChange={onDimensionsChange}
                onTimescaleChange={onTimescaleChange}
                selectedDimensions={selectedDimensions}
                selectedTimescale={selectedTimescale}
                startDateInQueryParams={queryParameters.startDate}
              />
            )}
          </RoundedCard>

          {organizationalHealthData && (
            <>
              <div className={styles.healthGaugesContainer}>
                <HealthGauges
                  dimensions={dimensions.data}
                  highlightedDimensionSlug={highlightedDimensionSlug}
                  onDimensionClicked={onDimensionClicked}
                  organizationalHealth={organizationalHealthData}
                />
              </div>

              {isTimeseriesLineVisible && (
                <div className={styles.chartContainer}>
                  <RoundedCard spacing="small">
                    <div className={styles.chart}>
                      <TimeseriesLine
                        lines={processDataForTimeseries() as TimeseriesData[]}
                      />
                    </div>
                  </RoundedCard>
                </div>
              )}
            </>
          )}
        </main>
      ) : (
        <main className={mainContainerStyles.containerVertical}>
          <RoundedCard>
            {t('overall-health.errors.no-dimension-available')}
          </RoundedCard>
        </main>
      )}
    </>
  );
};

export default OverallHealth;
