import {
  ChangeEvent,
  FormEvent,
  FunctionComponent,
  useCallback,
  useRef,
  useState,
} from 'react';
import {ReactComponent as IconSearch} from '../../../@components/kit/icons/search.svg';
import {ReactComponent as IconClearSearch} from '../../../@components/kit/icons/x.svg';
import styles from './styles.module.scss';

const SEARCH_QUERY_MAX_LENGTH = 50;

interface ElementProps {
  onSearch?: (event: ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  searchQueryInQueryParams?: string | null;
  onClearSearch: (event: FormEvent) => void;
}

const SearchForm: FunctionComponent<ElementProps> = ({
  onSearch,
  placeholder,
  searchQueryInQueryParams,
  onClearSearch,
}) => {
  const [showSearchClearButton, setShowSearchClearButton] =
    useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const onKeyDown = useCallback(() => {
    setShowSearchClearButton(true);
  }, [setShowSearchClearButton]);

  const onClearSearchButtonClicked = useCallback(
    (event: FormEvent) => {
      onClearSearch(event);

      inputRef.current!.value = '';
      setShowSearchClearButton(false);
    },
    [onClearSearch]
  );

  return (
    <form className={styles.form}>
      <div className={styles.inputContainer}>
        <IconSearch className={styles.searchIcon} />

        <input
          className={styles.input}
          defaultValue={searchQueryInQueryParams ?? ''}
          maxLength={SEARCH_QUERY_MAX_LENGTH}
          onChange={onSearch}
          onKeyDown={onKeyDown}
          placeholder={placeholder}
          ref={inputRef}
          type="search"
        />

        {showSearchClearButton && (
          <button
            className={styles.button}
            onClick={onClearSearchButtonClicked}
          >
            <IconClearSearch className={styles.clearSearchIcon} />
          </button>
        )}
      </div>
    </form>
  );
};

export default SearchForm;
