import {ORDER_BY_PARAMETERS} from '../../../constants/filtering/order-by';
import {sanitizeNotificationMetaFromRaw} from '../../../ui/@sanitizers/notification';
import {sanitizeTeamsFromRaw} from '../../../ui/@sanitizers/teams';
import InstillAPI from '../axios-instance';
import isResponseSuccessful from '../../../utils/is-response-successful';

export interface FetchTeamsPayload {
  companyUuid: string;
  currentPage?: number;
  itemsPerPage?: number;
  limit?: number;
  offset?: number;
  orderBy?: ORDER_BY_PARAMETERS;
  query?: string;
  sortBy?: string;
}

export async function fetchTeams(parameters: FetchTeamsPayload) {
  const {companyUuid, offset, orderBy, sortBy, query, limit} = parameters;

  let url = `/companies/${companyUuid}/teams`;
  let queryParams: string[] = [];

  if (offset) {
    queryParams.push(`skip=${offset}`);
  }

  if (limit) {
    queryParams.push(`limit=${limit}`);
  }

  if (orderBy) {
    queryParams.push(`order=${orderBy}`);
  }

  if (sortBy) {
    queryParams.push(`sort_by=${sortBy}`);
  }

  if (query) {
    queryParams.push(`q=${query}`);
  }

  if (queryParams) {
    url = `${url}?${queryParams.join('&')}`;
  }

  try {
    const response = await InstillAPI.get(url);

    if (response.data.length || !isResponseSuccessful(response)) {
      return;
    }

    return {
      data: sanitizeTeamsFromRaw(response.data.data),
      meta: sanitizeNotificationMetaFromRaw(response.data.meta),
    };
  } catch (error) {
    console.error(error);
  }
}
