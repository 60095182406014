import {EventName} from '../../../../../../constants/analytics/event-name';
import {EventType} from '../../../../../../constants/analytics/event-type';
import {FunctionComponent, useCallback} from 'react';
import {Link} from 'react-router-dom';
import {ActionStepTitleLinkClickedEvent} from '../../../../../../interfaces/analytics/event-properties';
import {UserActionStep} from '../../../../../../interfaces/action-step';
import {useTranslation} from 'react-i18next';
import RoundedCard from '../../../../../@components/rounded-card';
import styles from './styles.module.scss';
import useAnalytics from '../../../../../@hooks/use-analytics';

interface ElementProps {
  actionSteps?: UserActionStep[];
}

const ActionStepsList: FunctionComponent<ElementProps> = ({actionSteps}) => {
  const {t} = useTranslation('apply', {
    keyPrefix: 'action-steps.components.action-steps-list',
  });

  const {trackEvent} = useAnalytics();

  const onLinkClicked = useCallback(
    (actionStep: UserActionStep) => {
      const eventProperties = {
        title: actionStep.title,
      } as ActionStepTitleLinkClickedEvent;

      trackEvent({
        eventName: EventName.ACTION_STEPS.NAVIGATE_FROM_DASHBOARD_HIGHLIGHT,
        eventType: EventType.LINK_CLICKED,
        eventProperties,
      });
    },
    [trackEvent]
  );

  return (
    <>
      {actionSteps?.length ? (
        <RoundedCard.Content>
          <ul className={styles.list}>
            {actionSteps?.map((actionStep, index) => (
              <li className={styles.item} key={index}>
                <Link
                  className={styles.link}
                  onClick={() => onLinkClicked(actionStep)}
                  to={`/application/apply/action-steps?uuid=${actionStep.uuid}`}
                >
                  <div>
                    <strong>{actionStep.title} :</strong> {actionStep.subtitle}
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        </RoundedCard.Content>
      ) : (
        <div>{t('no-action-steps')}</div>
      )}
    </>
  );
};

export default ActionStepsList;
