import {Actions} from '../../../../../constants/permissions/actions';
import {buildPageTitle} from '../../../../../services/build-page-title';
import {EventName} from '../../../../../constants/analytics/event-name';
import {EventType} from '../../../../../constants/analytics/event-type';
import {FunctionComponent, useEffect, useMemo} from 'react';
import {Helmet} from 'react-helmet-async';
import {useCheckPermissionOrRedirect} from '../../../../@hooks/use-check-permission-or-redirect';
import {useCompanyAssessmentEvents} from '../../../../@hooks/queries';
import {useNavigate} from 'react-router';
import {useParams} from 'react-router';
import {useSafeCurrentCompany} from '../../../../@atoms/current-company';
import {useTranslation} from 'react-i18next';
import AssessmentEvents from './@components/assessment-events';
import mainContainerStyles from '../../../../../styles/classes/main-container.module.scss';
import RoundedCard from '../../../../@components/rounded-card';
import useAnalytics from '../../../../@hooks/use-analytics';

const ManageAssessmentsShow: FunctionComponent = () => {
  const {t} = useTranslation('applicationPageTitle');
  const navigate = useNavigate();

  const currentCompany = useSafeCurrentCompany();
  const {trackEvent} = useAnalytics();

  const hasAccess = useCheckPermissionOrRedirect(
    Actions.VIEW_MANAGE_ASSESSMENTS,
    '/application/measure'
  );

  const {id: assessmentId} = useParams<string>();

  const companyAssessmentEvents = useCompanyAssessmentEvents({
    enabled: Boolean(assessmentId),
    variables: {
      companyUuid: currentCompany.uuid,
      assessmentUuid: assessmentId,
    },
    onSettled(_data, error) {
      if (error) {
        navigate('/application/measure/manage-assessments');
      }
    },
  });

  const assessmentName = useMemo(() => {
    return companyAssessmentEvents?.data?.assessmentName ?? '';
  }, [companyAssessmentEvents]);

  useEffect(() => {
    trackEvent({
      eventName: EventName.PAGE_VIEWS.MEASURE.MANAGE_ASSESSMENTS.SHOW,
      eventType: EventType.PAGE_VIEW,
    });
  }, [trackEvent]);

  if (!hasAccess) return null;

  return (
    <>
      <Helmet>
        <title>
          {buildPageTitle([
            assessmentName,
            t(
              'applicationPageTitle:page-title.measure-your-culture.manage-assessments.index'
            ),
            t('applicationPageTitle:page-title.measure-your-culture.index'),
          ])}
        </title>
      </Helmet>

      <main className={mainContainerStyles.container}>
        <RoundedCard>
          <AssessmentEvents
            assessmentEvents={companyAssessmentEvents.data}
            isFetchingInProgress={companyAssessmentEvents.isLoading}
          />
        </RoundedCard>
      </main>
    </>
  );
};

export default ManageAssessmentsShow;
