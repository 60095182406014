import {sanitizeCompanyCoreValuesFromRaw} from '../../../ui/@sanitizers/company-core-values';
import InstillAPI from '../axios-instance';
import isResponseSuccessful from '../../../utils/is-response-successful';

export interface CompanyCoreValuePayload {
  companyUuid: string;
}

export async function fetchCompanyCoreValues(companyUuid: string) {
  try {
    const response = await InstillAPI.get(`/companies/${companyUuid}/values`);

    if (!response.data || !isResponseSuccessful(response)) {
      throw Error('No data received');
    }

    return sanitizeCompanyCoreValuesFromRaw(response.data);
  } catch (error) {
    console.error(error);
  }
}
