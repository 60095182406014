import {NavLink} from 'react-router-dom';
import {FunctionComponent} from 'react';
import styles from './styles.module.scss';

interface ElementProps {
  links: {
    route: string;
    label: string;
  }[];
}

const SubNavigation: FunctionComponent<ElementProps> = ({links}) => {
  return (
    <ul className={styles.list}>
      {links &&
        links.map((link, index) => (
          <li key={index} className={styles.item}>
            <NavLink
              to={link.route}
              className={({isActive}) =>
                isActive ? styles.activeLink : styles.link
              }
            >
              {link.label}
            </NavLink>
          </li>
        ))}
    </ul>
  );
};

export default SubNavigation;
