import {Candidate} from '../../../../../../../../../../interfaces/candidate';
import {Formik, Form, Field, FieldProps} from 'formik';
import {FunctionComponent, useCallback, useMemo} from 'react';
import {Option} from '../../../../../../../../../../interfaces/dropdown-options';
import {ReactComponent as IconCalendar} from '../../../../../../../../../@components/kit/icons/calendar.svg';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import Button from '../../../../../../../../../@components/kit/form/button';
import DatePicker from 'react-datepicker';
import Dialog from '../../../../../../../../../@components/kit/dialog';
import FormControl from '../../../../../../../../../@components/kit/form/form-control';
import FormFooter from '../../../../../../../../../@components/kit/form/form-footer';
import FormGroup from '../../../../../../../../../@components/kit/form/form-group';
import Input from '../../../../../../../../../@components/kit/form/input/input';
import InputCheckbox from '../../../../../../../../../@components/kit/form/input-checkbox';
import Label from '../../../../../../../../../@components/kit/form/label';
import SearchableSelect from '../../../../../../../../../@components/kit/form/hybrid-select/searchable-select';
import styles from './styles.module.scss';

interface ElementProps {
  candidate: Candidate;
  jobPositionOptions: Option[];
  isVisible: boolean;
  onClose: () => void;
  onSubmit: (values: EditCandidateFormProps) => void;
}

export interface EditCandidateFormProps {
  name: string;
  dueDate: Date;
  jobPositionUuid: string;
  isActive: boolean;
}

const EditDialog: FunctionComponent<ElementProps> = ({
  candidate,
  isVisible,
  jobPositionOptions,
  onClose,
  onSubmit,
}) => {
  const {t} = useTranslation('jobs');

  const initialValues = useMemo(() => {
    return {
      dueDate: new Date(candidate.dueDate),
      email: candidate.email,
      jobPositionUuid: candidate.jobPositionUuid,
      name: candidate.name,
      isActive: candidate.isActive,
    } as EditCandidateFormProps;
  }, [candidate]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('form.error.required', {ns: 'common'})),
    dueDate: Yup.date()
      .min(new Date())
      .required(t('form.error.required', {ns: 'common'})),
    jobPositionUuid: Yup.string().required(
      t('form.error.required', {ns: 'common'})
    ),
  });

  const isSubmitButtonDisabled = useCallback(
    (
      isValid: boolean,
      initialValues: EditCandidateFormProps,
      values: EditCandidateFormProps
    ) => {
      const someValuesHaveChanged = Object.keys(initialValues).some((key) => {
        return (
          initialValues[key as keyof typeof initialValues] !==
          values[key as keyof typeof values]
        );
      });

      if (!someValuesHaveChanged) return true;

      return !isValid;
    },
    []
  );

  const onDueDateChanged = useCallback(
    (form: FieldProps['form'], field: FieldProps['field'], value: Date) => {
      form.setFieldValue(field.name, value);
    },
    []
  );

  return (
    <Dialog isOpen={isVisible} onClose={onClose}>
      <Dialog.Header>
        <div className={styles.title}>{candidate.name}</div>

        <div className={styles.subtitle}>
          {t(
            'candidates.components.candidate-list.components.list-view.components.edit-dialog.title'
          )}
        </div>
      </Dialog.Header>

      <Dialog.Content>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnMount
          onSubmit={onSubmit}
        >
          {({values, errors, touched, isValid}) => (
            <Form>
              <Field name="name">
                {({field}: FieldProps<string>) => (
                  <FormGroup>
                    <FormControl
                      error={
                        Boolean(errors.name || touched.name)
                          ? errors.name
                          : undefined
                      }
                    >
                      <Label>
                        {t(
                          'candidates.components.candidate-list.components.list-view.components.edit-dialog.form.label.name'
                        )}
                      </Label>

                      <Input
                        type="text"
                        placeholder={t(
                          'candidates.components.candidate-list.components.list-view.components.edit-dialog.form.placeholder.name'
                        )}
                        {...field}
                      />
                    </FormControl>
                  </FormGroup>
                )}
              </Field>

              <Field name="email">
                {({field}: FieldProps<string>) => (
                  <FormGroup>
                    <Label>
                      {t(
                        'candidates.components.candidate-list.components.list-view.components.edit-dialog.form.label.email'
                      )}
                    </Label>

                    <Input type="text" disabled={true} {...field} />
                  </FormGroup>
                )}
              </Field>

              <Field name="dueDate">
                {({form, field}: FieldProps<string>) => (
                  <FormGroup>
                    <FormControl>
                      <Label>
                        {t(
                          'candidates.components.candidate-list.components.list-view.components.edit-dialog.form.label.due-date'
                        )}
                      </Label>

                      <div className={styles.calendarContainer}>
                        <DatePicker
                          minDate={new Date()}
                          onChange={(value: Date) =>
                            onDueDateChanged(form, field, value)
                          }
                          required
                          selected={values.dueDate}
                        />

                        <IconCalendar className={styles.calendarIcon} />
                      </div>
                    </FormControl>
                  </FormGroup>
                )}
              </Field>

              <Field name="jobPositionUuid">
                {(fieldProps: FieldProps) => (
                  <FormGroup>
                    <FormControl
                      error={
                        Boolean(
                          errors.jobPositionUuid || touched.jobPositionUuid
                        )
                          ? errors.jobPositionUuid
                          : undefined
                      }
                    >
                      <Label>
                        {t(
                          'candidates.components.candidate-list.components.list-view.components.edit-dialog.form.label.job-position'
                        )}
                      </Label>

                      <SearchableSelect
                        {...fieldProps}
                        options={jobPositionOptions}
                      />
                    </FormControl>
                  </FormGroup>
                )}
              </Field>

              <Field name="isActive">
                {({field}: FieldProps) => (
                  <FormGroup>
                    <FormControl
                      error={
                        Boolean(errors.isActive || touched.isActive)
                          ? errors.isActive
                          : undefined
                      }
                    >
                      <InputCheckbox
                        checked={values.isActive}
                        label={t(
                          'candidates.components.candidate-list.components.list-view.components.edit-dialog.form.label.is-active'
                        )}
                        {...field}
                      />
                    </FormControl>
                  </FormGroup>
                )}
              </Field>

              <FormFooter>
                <Button
                  disabled={isSubmitButtonDisabled(
                    isValid,
                    initialValues,
                    values
                  )}
                  type="submit"
                >
                  {t(
                    'candidates.components.candidate-list.components.list-view.components.edit-dialog.form.submit-button'
                  )}
                </Button>

                <Button variant="secondary" onClick={onClose}>
                  {t(
                    'candidates.components.candidate-list.components.list-view.components.edit-dialog.form.cancel-button'
                  )}
                </Button>
              </FormFooter>
            </Form>
          )}
        </Formik>
      </Dialog.Content>
    </Dialog>
  );
};

export default EditDialog;
