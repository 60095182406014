import {ChangeEvent, FunctionComponent, useCallback} from 'react';
import {CompanyAssessment} from '../../../../../../../../../../../interfaces/company-assessments';
import {Field, FieldProps, Form, Formik} from 'formik';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import AutoresizeTextarea from '../../../../../../../../../../@components/kit/form/autoresize-textarea';
import Button from '../../../../../../../../../../@components/kit/form/button';
import Dialog from '../../../../../../../../../../@components/kit/dialog';
import FormControl from '../../../../../../../../../../@components/kit/form/form-control';
import FormFooter from '../../../../../../../../../../@components/kit/form/form-footer';
import FormGroup from '../../../../../../../../../../@components/kit/form/form-group';
import Label from '../../../../../../../../../../@components/kit/form/label';
import RadioGroup from '../../../../../../../../../../@components/kit/form/radio-group';
import styles from './styles.module.scss';

interface ElementProps {
  companyAssessment: CompanyAssessment | null;
  isVisible: boolean;
  onClose: () => void;
  onSubmit: (values: SendMessageDialogFormProps) => void;
}

enum POPULATION {
  ALL_INVITED = 'ALL_INVITED',
  NOT_COMPLETED = 'NOT_COMPLETED',
}

export interface SendMessageDialogFormProps {
  message: string;
  sendTo: string;
}

const INITIAL_VALUES = {
  message: '',
  sendTo: '',
} as SendMessageDialogFormProps;

const SendMessageDialog: FunctionComponent<ElementProps> = ({
  companyAssessment,
  isVisible,
  onClose,
  onSubmit,
}) => {
  const {t} = useTranslation('measure');

  const validationSchema = Yup.object().shape({
    message: Yup.string()
      .max(255)
      .required(t('form.error.required', {ns: 'common'})),
    sendTo: Yup.string().required(t('form.error.required', {ns: 'common'})),
  });

  const onPopulationChange = useCallback(
    (
      event: ChangeEvent<HTMLInputElement>,
      form: FieldProps['form'],
      field: FieldProps['field']
    ) => {
      form.setFieldValue(field.name, event.target.value);
    },
    []
  );

  const populationOptions = [
    {
      label: t(
        'manage-assessments.components.assessments.components.send-message-dialog.form.send-to.values.all'
      ),
      value: POPULATION.ALL_INVITED,
    },
    {
      label: t(
        'manage-assessments.components.assessments.components.send-message-dialog.form.send-to.values.not-yet-finish'
      ),
      value: POPULATION.NOT_COMPLETED,
    },
  ];

  return (
    <Dialog isOpen={isVisible} onClose={onClose}>
      <Dialog.Header>
        <div className={styles.title}>{companyAssessment?.assessment.name}</div>

        <div className={styles.subtitle}>
          {t(
            'manage-assessments.components.assessments.components.send-message-dialog.subtitle'
          )}
        </div>
      </Dialog.Header>

      <Dialog.Content>
        <Formik
          initialValues={INITIAL_VALUES}
          validationSchema={validationSchema}
          validateOnMount
          onSubmit={onSubmit}
        >
          {({isValid, values}) => (
            <Form>
              <Field name="message">
                {({form, field}: FieldProps<string>) => (
                  <FormGroup>
                    <FormControl>
                      <Label isRequired>
                        {t(
                          'manage-assessments.components.assessments.components.send-message-dialog.form.message.label'
                        )}
                      </Label>

                      <AutoresizeTextarea
                        {...field}
                        placeholder={t(
                          'manage-assessments.components.assessments.components.send-message-dialog.form.message.placeholder'
                        )}
                        maxLength={255}
                      />
                    </FormControl>
                  </FormGroup>
                )}
              </Field>

              <Field name="sendTo">
                {({form, field}: FieldProps<string>) => (
                  <FormGroup>
                    <FormControl>
                      <Label isRequired>
                        {t(
                          'manage-assessments.components.assessments.components.send-message-dialog.form.send-to.label'
                        )}
                      </Label>

                      <RadioGroup
                        {...field}
                        onChange={(value) =>
                          onPopulationChange(value, form, field)
                        }
                        options={populationOptions}
                        selectedValue={field.value}
                      />
                    </FormControl>
                  </FormGroup>
                )}
              </Field>

              <FormFooter>
                <Button disabled={!isValid} type="submit">
                  {t(
                    'manage-assessments.components.assessments.components.send-message-dialog.form.submit-button'
                  )}
                </Button>

                <Button variant="secondary" onClick={onClose}>
                  {t(
                    'manage-assessments.components.assessments.components.send-message-dialog.form.cancel-button'
                  )}
                </Button>
              </FormFooter>
            </Form>
          )}
        </Formik>
      </Dialog.Content>
    </Dialog>
  );
};

export default SendMessageDialog;
