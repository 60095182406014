import {createMutation} from 'react-query-kit';
import {upsertAssessmentResponse} from '../../../repositories/instill';
import {UpsertAssessmentResponsePayload} from '../../../repositories/instill/mutations/upsert-assessment-response';

export const useUpsertAssessmentResponse = createMutation<
  {value: string} | undefined,
  UpsertAssessmentResponsePayload
>({
  mutationFn: async (variables) => {
    return await upsertAssessmentResponse(
      {value: variables.value},
      variables.userAssessmentUuid,
      variables.moduleUuid,
      variables.questionUuid
    );
  },
});
