import {createMutation} from 'react-query-kit';
import {FullUserProfile} from '../../../interfaces/user-profile';
import {updateMeUserProfile} from '../../../repositories/instill';
import {UpdateMyUserProfilePayload} from '../../../repositories/instill/mutations/update-me-user-profile';

export const useUpdateMyUserProfile = createMutation<
  FullUserProfile | undefined,
  UpdateMyUserProfilePayload
>({
  mutationFn: async (variables) => {
    return await updateMeUserProfile(variables.payload, variables.companyUuid);
  },
});
