import {createQuery} from 'react-query-kit';
import {fetchKudos} from '../../../repositories/instill';
import {FetchKudosPayload} from '../../../repositories/instill/queries/fetch-kudos';
import {Kudo} from '../../../interfaces/kudo';
import {Meta} from '../../../interfaces/meta';

interface FetchKudos {
  data: Kudo[];
  meta: Meta;
}

export const useKudos = createQuery<FetchKudos | undefined, FetchKudosPayload>({
  primaryKey: 'kudos',
  queryFn: async ({queryKey: [_, variables]}) => {
    let payload = {
      offset: variables.offset,
      limit: variables.limit,
      companyUuid: variables.companyUuid,
    } as FetchKudosPayload;

    if (variables.valueUuid) payload['valueUuid'] = variables.valueUuid;
    if (variables.toUserUuid) payload['toUserUuid'] = variables.toUserUuid;

    return fetchKudos({
      ...payload,
      companyUuid: variables.companyUuid,
      limit: variables.limit,
    });
  },
});
