import {FunctionComponent, LabelHTMLAttributes} from 'react';
import {ReactComponent as IconAlert} from '../../../kit/icons/alert-circle.svg';
import {useFormControlContext} from '../form-control';
import styles from './styles.module.scss';

interface ElementProps extends LabelHTMLAttributes<HTMLLabelElement> {
  hasError?: boolean;
  isIconVisible?: boolean;
  isRequired?: boolean;
}

const Label: FunctionComponent<ElementProps> = ({
  children,
  isIconVisible = true,
  isRequired = false,
  ...props
}) => {
  let classes = styles.label;
  const {hasError} = useFormControlContext();

  if (hasError) {
    classes = `${classes} ${styles.error}`;
  }

  if (props.className) {
    classes = `${classes} ${props.className}`;
  }

  if (!isIconVisible) {
    classes = `${classes} ${styles.withoutIcon}`;
  }

  return (
    <label {...props} className={classes}>
      {isIconVisible ? <IconAlert className={styles.labelIcon} /> : <></>}
      {children}

      {isRequired && <> *</>}
    </label>
  );
};

export default Label;
