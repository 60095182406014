import {Company} from '../../../../interfaces/company';
import {CompanyUpsert} from '../../../../interfaces/admin/company';
import {
  EMAIL_DOMAIN_REGEX,
  WEBSITE_URL_REGEX,
} from '../../../../constants/regex';
import {fetchPresignedUploadURLResourceType} from '../../../../repositories/instill/queries/fetch-presigned-url-config';
import {FieldProps, Form, Formik} from 'formik';
import {FunctionComponent, useCallback, useState} from 'react';
import {useFetchPresignedUploadUrl} from '../../../@hooks/mutations';
import {useSafeCurrentCompany} from '../../../@atoms/current-company';
import {useTranslation} from 'react-i18next';
import {useUploadToPresignedUrl} from '../../../@hooks/mutations';
import * as Yup from 'yup';
import Button from '../../../@components/kit/form/button';
import styles from './styles.module.scss';
import UpsertCompanyForm from '../../admin-portal/@components/company-list/@components/upsert-company-form';
import UpsertLogoForm from '../../admin-portal/@components/company-list/@components/upsert-logo-form';

interface ElementProps {
  company?: Company;
  isConfirmationStepNecessary: boolean;
  onClose: () => void;
  onSubmit: (values: CompanyUpsert) => void;
}

const CompanyUpsertForm: FunctionComponent<ElementProps> = ({
  company,
  isConfirmationStepNecessary,
  onSubmit,
  onClose,
}) => {
  const {t} = useTranslation('adminPortal');

  const currentCompany = useSafeCurrentCompany();

  const [formValues, setFormValues] = useState<CompanyUpsert | null>();
  const [isConfirmationStepVisible, setIsConfirmationStepVisible] =
    useState(false);

  const fetchPresignedUploadURL = useFetchPresignedUploadUrl();
  const uploadToPresignedURL = useUploadToPresignedUrl();

  const initialValues: CompanyUpsert = {
    companyLogo: company?.companyLogo ?? '',
    emailDomain: company?.emailDomain ?? '',
    industry: company?.industry ?? '',
    isDefinedCulture: company?.isCultureDefined ?? true,
    isSignatureRequiredOnValues: company?.isSignatureRequiredOnValues ?? false,
    name: company?.name ?? '',
    size: company?.size ?? '',
    websiteUrl: company?.websiteUrl ?? '',
  };

  const validationSchema = Yup.object()
    .shape({
      name: Yup.string().required(t('form.error.required', {ns: 'common'})),
      industry: Yup.string().required(t('form.error.required', {ns: 'common'})),
      size: Yup.string().required(t('form.error.required', {ns: 'common'})),
      websiteUrl: Yup.string()
        .matches(WEBSITE_URL_REGEX, 'form.error.required')
        .nullable(),
      emailDomain: Yup.string()
        .required(t('form.error.required', {ns: 'common'}))
        .matches(
          EMAIL_DOMAIN_REGEX,
          t('form.error.bad-email-domain-format', {ns: 'common'})
        ),
    })
    .defined();

  const onCompanyLogoChange = useCallback(
    async (
      companyLogo: File,
      form: FieldProps['form'],
      field: FieldProps['field']
    ) => {
      if (!companyLogo) return;

      const config = await fetchPresignedUploadURL.mutateAsync({
        ressource: fetchPresignedUploadURLResourceType.COMPANY_LOGO,
        mime: companyLogo.type,
        extension: companyLogo.name.split('.').pop() as string,
        companyUuid: currentCompany.uuid,
      });
      if (!config) return;

      const bucketURL = await uploadToPresignedURL.mutateAsync({
        config,
        file: companyLogo,
      });
      if (!bucketURL) return;

      form.setFieldValue(field.name, bucketURL);
    },
    [currentCompany, uploadToPresignedURL, fetchPresignedUploadURL]
  );

  const onFormSubmit = useCallback(
    (values: CompanyUpsert) => {
      if (isConfirmationStepNecessary) {
        setIsConfirmationStepVisible(true);
        setFormValues(values);
      } else {
        onSubmit(values);
      }
    },
    [isConfirmationStepNecessary, onSubmit]
  );

  const onConfirmationButtonClicked = useCallback(() => {
    if (!formValues) return;

    onSubmit(formValues);
  }, [onSubmit, formValues]);

  const onCancelButtonClicked = useCallback(() => {
    setIsConfirmationStepVisible(false);
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={onFormSubmit}
    >
      {() => (
        <Form>
          <div className={styles.content}>
            <div className={styles.logo}>
              <UpsertLogoForm
                company={company}
                onCompanyLogoChange={onCompanyLogoChange}
              />
            </div>

            <div className={styles.formContent}>
              <UpsertCompanyForm
                isInEditionMode={Boolean(company)}
                onClose={onClose}
              />
            </div>
          </div>

          {isConfirmationStepVisible && (
            <div className={styles.overlay}>
              <div className={styles.overlayTitle}>
                {t(
                  'components.company-list.components.upsert-form.confirmation-step.title'
                )}
              </div>

              <div className={styles.overlayFooter}>
                <Button variant="secondary" onClick={onCancelButtonClicked}>
                  {t(
                    'components.company-list.components.upsert-form.confirmation-step.cancel-button'
                  )}
                </Button>

                <Button onClick={onConfirmationButtonClicked}>
                  {t(
                    'components.company-list.components.upsert-form.confirmation-step.confirm-button'
                  )}
                </Button>
              </div>
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default CompanyUpsertForm;
