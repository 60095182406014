import {CandidatesCreate} from '../../../interfaces/candidate';
import {
  sanitizeCandidatesCreateForRaw,
  sanitizeCandidateFromRaw,
} from '../../../ui/@sanitizers/candidate';
import InstillAPI from '../axios-instance';
import isResponseSuccessful from '../../../utils/is-response-successful/is-response-successful';

export interface CreateCandidatePayload {
  companyUuid: string;
  jobPositionUuid: string;
  payload: CandidatesCreate;
}

export async function createCandidate(parameters: CreateCandidatePayload) {
  const {companyUuid, jobPositionUuid, payload} = parameters;
  const sanitizedPayload = sanitizeCandidatesCreateForRaw(payload);

  const response = await InstillAPI.post(
    `/companies/${companyUuid}/job-positions/${jobPositionUuid}/candidates`,
    sanitizedPayload
  );

  if (!isResponseSuccessful(response)) {
    throw Error('No data received');
  }

  return sanitizeCandidateFromRaw(response.data);
}
