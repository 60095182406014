import {FunctionComponent} from 'react';
import {Route, Routes} from 'react-router';
import AssessmentSummary from './@components/assessment-summary';
import ModuleSummary from './@components/module-summary';

const AssessmentsSummary: FunctionComponent = () => {
  return (
    <Routes>
      <Route path=":id/modules/:moduleId" element={<ModuleSummary />} />

      <Route path=":id/" element={<AssessmentSummary />} />
    </Routes>
  );
};

export default AssessmentsSummary;
