import styles from './styles.module.scss';
import AccountLinked from './@components/account-linked';
import AlreadyLinked from './@components/already-linked';
import VerifyYourEmail from './@components/verify-your-email';
import GenericError from './@components/generic-error';
import TwoPanePage from '../../../@components/two-pane-page';
import Branding from './@components/branding';
import EmailVerified from './@components/email-verified';
import CompanyEmailRequired from './@components/company-email-required';
import InstillRegistrationRequired from './@components/instill-registration-required';

interface AuthFeedbackProps {
  accountLinked?: boolean;
  emailVerified?: boolean;
  providers?: string[];
  errorPayload?:
    | {
        status: 'email_not_verified';
        masked_email: string;
      }
    | {
        status: 'already_linked';
      }
    | {
        status: 'auth0_error';
      }
    | {
        status: 'company_email_required';
      }
    | {
        status: 'instill_registration_required';
      };
}

function renderFeedbackComponent({
  accountLinked,
  emailVerified,
  errorPayload,
  providers = [],
}: AuthFeedbackProps) {
  let component;

  if (accountLinked) {
    component = <AccountLinked providers={providers} />;
  } else if (emailVerified) {
    component = <EmailVerified />;
  } else {
    switch (errorPayload?.status) {
      case 'email_not_verified':
        component = <VerifyYourEmail maskedEmail={errorPayload.masked_email} />;
        break;
      case 'already_linked':
        component = <AlreadyLinked />;
        break;
      case 'auth0_error':
        component = <GenericError />;
        break;
      case 'company_email_required':
        component = <CompanyEmailRequired />;
        break;
      case 'instill_registration_required':
        component = <InstillRegistrationRequired />;
        break;
      default:
        component = <GenericError />;
        break;
    }
  }

  return <div className={styles.feedback}>{component}</div>;
}

function AuthFeedback(props: AuthFeedbackProps) {
  return (
    <TwoPanePage
      mainPane={renderFeedbackComponent(props)}
      secondaryPane={<Branding />}
    />
  );
}

export default AuthFeedback;
