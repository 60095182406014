import {
  sanitizeNotificationMetaFromRaw,
  sanitizeNotificationsFromRaw,
} from '../../../ui/@sanitizers/notification';
import InstillAPI from '../axios-instance';
import isResponseSuccessful from '../../../utils/is-response-successful';

export interface FetchNotificationPayload {
  companyUuid: string;
  limit?: number;
  offset?: number;
  currentPage?: number;
}

export async function fetchNotifications(parameters: FetchNotificationPayload) {
  const {companyUuid, offset, limit} = parameters;

  let url = `/companies/${companyUuid}/notifications`;
  let queryParams: string[] = [];

  if (offset) {
    queryParams.push(`skip=${offset}`);
  }

  if (limit) {
    queryParams.push(`limit=${limit}`);
  }

  if (queryParams) {
    url = `${url}?${queryParams.join('&')}`;
  }

  try {
    const response = await InstillAPI.get(url);

    if (response.data.length || !isResponseSuccessful(response)) {
      return;
    }

    return {
      data: sanitizeNotificationsFromRaw(response.data.data),
      meta: sanitizeNotificationMetaFromRaw(response.data.meta),
    };
  } catch (error) {
    console.error(error);
  }
}
