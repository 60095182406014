import {
  Module,
  CompletionInfo,
} from '../../../../../../../../../../../../../../../interfaces/company-assessment-events';
import {
  ChangeEvent,
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {sortByName} from '../../../../../../../../../../../../../../../utils/sort-by/name';
import {useTranslation} from 'react-i18next';
import Dialog from '../../../../../../../../../../../../../../@components/kit/dialog';
import Input from '../../../../../../../../../../../../../../@components/kit/form/input';
import styles from './styles.module.scss';
import {NavLink} from 'react-router-dom';
import {fetchPdfReportUrl} from '../../../../../../../../../../../../../../../repositories/instill';

interface ElementProps {
  module: Module;
  isVisible: boolean;
  onClose: () => void;
  isViewAction: boolean;
}

const PopulationDialog: FunctionComponent<ElementProps> = ({
  module,
  isVisible,
  onClose,
  isViewAction,
}) => {
  const {t} = useTranslation('measure');

  const [searchQuery, setSearchQuery] = useState('');
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [populationList, setPopulationList] = useState(module.completionInfo);

  const onGenerateReportClick = useCallback(
    async (employee: CompletionInfo) => {
      if (!employee.userAssessmentUuid || !module.uuid) return;

      const response = await fetchPdfReportUrl(
        employee.userAssessmentUuid,
        module.uuid
      );
      if (!response) return;

      window.open(response, '_blank');
    },
    [module.uuid]
  );

  const onSearchInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setSearchQuery(value);

      if (value === '') {
        setIsSearchActive(false);
      } else {
        setIsSearchActive(true);
      }

      const filteredWithQuery = module.completionInfo.filter((employee) => {
        if (employee.name.toLowerCase().includes(value.toLowerCase())) {
          return employee;
        }

        return null;
      });

      setPopulationList(filteredWithQuery);
    },
    [module.completionInfo]
  );

  const filteredPopulation = useMemo(() => {
    const sortedList = sortByName(populationList);

    return sortedList.filter((employee) => employee.isCompleted);
  }, [populationList]);

  return (
    <Dialog isOpen={isVisible} onClose={onClose} variant="medium">
      <Dialog.Header>
        <h1 className={styles.title}>{module.name}</h1>

        <div className={styles.subtitle}>
          {t(
            'manage-assessments.components.assessments.components.population-dialog.subtitle'
          )}
        </div>
      </Dialog.Header>

      <Dialog.Content>
        <form className={styles.form}>
          <Input
            type="search"
            value={searchQuery}
            onChange={onSearchInputChange}
            placeholder={t(
              'manage-assessments.components.assessments.components.population-dialog.input-placeholder'
            )}
          />
        </form>

        <div>
          <h2 className={styles.contentTitle}>
            {t(
              'manage-assessments.components.assessments.components.population-dialog.completed-title'
            )}
          </h2>

          {filteredPopulation.length ? (
            <ul className={styles.list}>
              {filteredPopulation.map(
                (employee: CompletionInfo, index: number) => (
                  <li key={index} className={styles.listItem}>
                    {isViewAction ? (
                      <NavLink
                        to={`/assessments-summary/${employee.userAssessmentUuid}/modules/${module.uuid}`}
                        className={styles.actionButton}
                      >
                        {employee.name}
                      </NavLink>
                    ) : (
                      <button
                        className={styles.actionButton}
                        onClick={() => onGenerateReportClick(employee)}
                        type="button"
                      >
                        {employee.name}
                      </button>
                    )}
                  </li>
                )
              )}
            </ul>
          ) : (
            <div className={styles.disabledBox}>
              {isSearchActive ? (
                <>
                  {t(
                    'manage-assessments.components.assessments.components.population-dialog.no-result'
                  )}
                </>
              ) : (
                <>
                  {t(
                    'manage-assessments.components.assessments.components.population-dialog.nobody-completed-assessment'
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </Dialog.Content>
    </Dialog>
  );
};

export default PopulationDialog;
