import {
  CompanyEmployee,
  CompanyEmployeeRaw,
  CompanyEmployeeTeam,
  CompanyEmployeeTeamRaw,
  CompanyEmployeeUpdate,
  CompanyEmployeeUpdateToRaw,
  CompanyManager,
  CompanyManagerRaw,
} from '../../interfaces/company-employee';

export const sanitizeCompanyEmployeesFromRaw = (
  data: CompanyEmployeeRaw[]
): CompanyEmployee[] => {
  return data.map((employee) => {
    return sanitizeCompanyEmployeeFromRaw(employee);
  });
};

export const sanitizeCompanyEmployeeFromRaw = (
  data: CompanyEmployeeRaw
): CompanyEmployee => {
  let payload = {
    hexacoTrait: data.hexaco_trait,
    jobTitle: data.job_title,
    manager: data.manager ? sanitizeCompanyManagerFromRaw(data.manager) : null,
    name: data.name,
    picture: data.signed_picture_url,
    team: data.team ? sanitizeCompanyEmployeeTeamFromRaw(data.team) : null,
    userProfileUuid: data.user_profile_uuid,
    workEmail: data.work_email,
  } as CompanyEmployee;

  if (data.joined_date) {
    payload['joinedDate'] = data.joined_date;
  }

  if ('is_active' in data) {
    payload['isActive'] = data.is_active;
  }

  if (data.role) {
    payload['role'] = data.role;
  }

  return payload;
};

export const sanitizeCompanyManagerFromRaw = (
  data: CompanyManagerRaw
): CompanyManager => {
  return {
    name: data.name,
    userProfileUuid: data.user_profile_uuid,
    role: data.role,
  };
};

export const sanitizeCompanyEmployeeToRaw = (
  data: CompanyEmployeeUpdate
): CompanyEmployeeUpdateToRaw => {
  let payload = {} as CompanyEmployeeUpdateToRaw;

  if (data.role) {
    payload['role'] = data.role;
  }

  if (data.isActive) {
    payload['is_active'] = data.isActive === 'true';
  }

  return payload;
};

export const sanitizeCompanyEmployeeTeamFromRaw = (
  data: CompanyEmployeeTeamRaw
): CompanyEmployeeTeam => {
  return {
    uuid: data.uuid,
    name: data.name,
    colorHex: data.color_hex,
  };
};
