import {FunctionComponent, InputHTMLAttributes} from 'react';
import styles from './styles.module.scss';

interface ElementProps extends InputHTMLAttributes<HTMLInputElement> {
  isChecked: boolean;
}

const SwitchToggle: FunctionComponent<ElementProps> = ({
  isChecked = false,
  onChange,
  ...props
}) => {
  const uuid = crypto.randomUUID();
  const inputId = props.id ?? uuid;

  return (
    <>
      <input
        checked={isChecked}
        className={styles.checkbox}
        id={inputId}
        onChange={onChange}
        type="checkbox"
        {...props}
      />

      <label
        htmlFor={inputId}
        className={`${styles.label} ${isChecked ? styles.isChecked : ''}`}
      >
        <span className={styles.button} />
      </label>
    </>
  );
};

export default SwitchToggle;
