import {buildPageTitle} from '../../services/build-page-title';
import {Helmet} from 'react-helmet-async';
import {useTranslation} from 'react-i18next';
import {FunctionComponent} from 'react';
import Error from './@components/error';
import Quote from './@components/quote';
import TwoPanePage from '../@components/two-pane-page';

const PageNotFound: FunctionComponent = () => {
  const {t} = useTranslation('applicationPageTitle');

  return (
    <>
      <Helmet>
        <title>{buildPageTitle(t('page-title.page-not-found'))}</title>
      </Helmet>

      <TwoPanePage mainPane={<Quote />} secondaryPane={<Error />} />
    </>
  );
};

export default PageNotFound;
