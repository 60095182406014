import {FunctionComponent} from 'react';
import {Navigate, Route, Routes} from 'react-router';
import CandidateAssessment from './assessment';
import Feedback from './feedback';
import RegisterCandidate from './register';

const Candidates: FunctionComponent = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate replace to="/page-not-found" />} />
      <Route path="register/" element={<RegisterCandidate />} />
      <Route path="assessment/" element={<CandidateAssessment />} />
      <Route path="feedback/" element={<Feedback />} />

      <Route path="*" element={<Navigate replace to="/page-not-found" />} />
    </Routes>
  );
};

export default Candidates;
