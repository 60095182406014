import {CompanyCoreValue} from '../../../../interfaces/company-core-value';
import {DEFAULT_TOASTIFY_CONFIGURATION} from '../../../../constants/toastify-configuration';
import {EventName} from '../../../../constants/analytics/event-name';
import {EventType} from '../../../../constants/analytics/event-type';
import {FullUserProfile} from '../../../../interfaces/user-profile';
import {FunctionComponent, useCallback, useMemo, useState} from 'react';
import {Link} from 'react-router-dom';
import {sanitizeKudoVisibilityToRaw} from '../../../@sanitizers/kudo';
import {SendKudoEvent} from '../../../../interfaces/analytics/event-properties';
import {toast, ToastOptions} from 'react-toastify';
import {Trans, useTranslation} from 'react-i18next';
import {useCurrentFullUserProfileState} from '../../../@atoms/current-full-user-profile';
import {useKudos} from '../../../@hooks/queries';
import {useSafeCurrentCompany} from '../../../@atoms/current-company';
import {useUpdateKudoVisibility} from '../../../@hooks/mutations';
import Button from '../../../@components/kit/form/button/button';
import GiveKudosDialog from './@components/give-kudos-dialog/give-kudos-dialog';
import Item from './@components/item';
import RemainingKudosDialog from './@components/remaining-kudos-dialog';
import styles from './styles.module.scss';
import useAnalytics from '../../../@hooks/use-analytics';

export enum KUDOS_SOURCES {
  MY_PROFILE = 'MY_PROFILE',
  VALUE = 'VALUE',
}

interface ElementProps {
  maximumNumberOfKudos: number;
  prefixEventName?: string;
  source: KUDOS_SOURCES;
  user?: FullUserProfile;
  value?: CompanyCoreValue;
}

const DEFAULT_NUMBER_OF_KUDOS = 5;

const KudosList: FunctionComponent<ElementProps> = ({
  maximumNumberOfKudos,
  prefixEventName,
  source,
  user,
  value,
}) => {
  const {t} = useTranslation('application');

  const currentCompany = useSafeCurrentCompany();
  const [currentFullUserProfile, setCurrentFullUserProfile] =
    useCurrentFullUserProfileState();
  const {buildEventName, trackEvent} = useAnalytics();

  const kudos = useKudos({
    variables: {
      companyUuid: currentCompany.uuid,
      limit: maximumNumberOfKudos ?? DEFAULT_NUMBER_OF_KUDOS,
      valueUuid: value?.uuid,
      toUserUuid: user?.uuid,
    },
  });

  const kudosData = kudos.data?.data;

  const [isRemainingKudoDialogVisible, setIsRemainingKudoDialogVisible] =
    useState(false);

  const [isGiveKudosDialogVisible, setIsGiveKudosDialogVisible] =
    useState(false);

  const seeMoreLinkUrl = useMemo(() => {
    const url = '/application/kudos';

    if (user) {
      return `${url}?to_user_uuid=${user.uuid}`;
    }

    if (value) {
      return `${url}?value_uuid=${value.uuid}`;
    }

    return;
  }, [user, value]);

  const isSeeMoreLinkVisible = useMemo(() => {
    if (!kudos.data?.meta) return;

    return kudos.data.meta?.totalEntries > maximumNumberOfKudos;
  }, [kudos, maximumNumberOfKudos]);

  const isCurrentUserEqualsToProfileUser = useMemo(() => {
    return user?.uuid === currentFullUserProfile.uuid;
  }, [user, currentFullUserProfile]);

  const onShowGiveKudosDialog = useCallback(() => {
    const eventProperties = {
      valueName: value?.name ?? undefined,
      employeeName: user?.name ?? undefined,
    } as SendKudoEvent;

    trackEvent({
      eventName: buildEventName(
        EventName.COMPONENTS.KUDOS.RECOGNIZE_COLLEAGUE,
        prefixEventName
      ),
      eventType: EventType.BUTTON_CLICKED,
      eventProperties,
    });

    setIsGiveKudosDialogVisible(true);
  }, [buildEventName, trackEvent, prefixEventName, value, user]);

  const onHideGiveKudosDialog = useCallback(() => {
    trackEvent({
      eventName: buildEventName(
        EventName.COMPONENTS.KUDOS.CANCEL_KUDO,
        prefixEventName
      ),
      eventType: EventType.BUTTON_CLICKED,
    });

    setIsGiveKudosDialogVisible(false);
  }, [buildEventName, trackEvent, prefixEventName]);

  const updateUserProfile = useCallback(() => {
    const kudosRemainingFromProfile = currentFullUserProfile.kudosRemaining;

    if (!kudosRemainingFromProfile) return;

    const kudosRemaining = kudosRemainingFromProfile - 1;

    const fullUserProfile = {
      ...currentFullUserProfile,
      kudosRemaining: kudosRemaining,
    };

    setCurrentFullUserProfile(fullUserProfile);
  }, [currentFullUserProfile, setCurrentFullUserProfile]);

  const updateKudoVisibility = useUpdateKudoVisibility();

  const onUpdateKudoVisibility = useCallback(
    async (kudoUuid: string, isHidden: boolean) => {
      const companyUuid = currentCompany.uuid;
      const payload = sanitizeKudoVisibilityToRaw({isHidden: !isHidden});

      const response = await updateKudoVisibility.mutateAsync({
        companyUuid,
        kudoUuid,
        payload,
      });
      if (!response) return;

      const selectedKudo = kudosData?.find((kudo) => kudo.uuid === kudoUuid);

      const eventName = isHidden
        ? EventName.COMPONENTS.KUDOS.UNHIDE_KUDO
        : EventName.COMPONENTS.KUDOS.HIDE_KUDO;

      const eventProperties = {
        valueName: selectedKudo?.value.name ?? undefined,
        employeeName: selectedKudo?.toUser.name ?? undefined,
      } as SendKudoEvent;

      trackEvent({
        eventName: buildEventName(eventName, prefixEventName),
        eventType: EventType.BUTTON_CLICKED,
        eventProperties,
      });

      const translationKey = isHidden
        ? 'components.kudos-list.show-kudo-success-message'
        : 'components.kudos-list.hide-kudo-success-message';

      const feedbackMessage = t(translationKey);
      toast.success(feedbackMessage, {
        ...(DEFAULT_TOASTIFY_CONFIGURATION as ToastOptions),
      });

      kudos.refetch();
    },
    [
      buildEventName,
      currentCompany,
      kudos,
      kudosData,
      prefixEventName,
      t,
      trackEvent,
      updateKudoVisibility,
    ]
  );

  const onGiveKudosSuccess = useCallback(async () => {
    kudos.refetch();
    await updateUserProfile();

    const eventProperties = {
      valueName: value?.name ?? undefined,
      employeeName: user?.name ?? undefined,
    } as SendKudoEvent;

    trackEvent({
      eventName: buildEventName(
        EventName.COMPONENTS.KUDOS.SEND_KUDO_TO_COLLEAGUE,
        prefixEventName
      ),
      eventProperties,
    });

    setIsRemainingKudoDialogVisible(true);
  }, [
    buildEventName,
    prefixEventName,
    trackEvent,
    value,
    kudos,
    user,
    updateUserProfile,
  ]);

  return (
    <>
      {kudosData?.length ? (
        <ul className={styles.list}>
          {kudosData.map((kudo, index) => (
            <li className={styles.item} key={index}>
              <Item
                kudo={kudo}
                onUpdateKudoVisibility={onUpdateKudoVisibility}
              />
            </li>
          ))}
        </ul>
      ) : (
        <>
          {isCurrentUserEqualsToProfileUser && (
            <div className={styles.noKudos}>
              {t('components.kudos-list.no-kudo-for-user')}
            </div>
          )}
        </>
      )}

      {isSeeMoreLinkVisible && seeMoreLinkUrl && (
        <div className={styles.linkContainer}>
          <Link to={seeMoreLinkUrl} className={styles.link}>
            {t('components.kudos-list.see-more-button')}
          </Link>
        </div>
      )}

      {!isCurrentUserEqualsToProfileUser && (
        <>
          <footer className={styles.footer}>
            <Button onClick={onShowGiveKudosDialog} variant="secondary">
              <div>
                {source === KUDOS_SOURCES.VALUE ? (
                  <Trans
                    t={t}
                    i18nKey="components.kudos-list.recognize-button-with-value"
                    components={{bold: <b />}}
                    values={{
                      valueName: value?.name,
                    }}
                  />
                ) : (
                  <Trans
                    t={t}
                    i18nKey="components.kudos-list.recognize-button-with-user"
                    components={{bold: <b />}}
                    values={{
                      userName: user?.name,
                    }}
                  />
                )}
              </div>
            </Button>

            <GiveKudosDialog
              isVisible={isGiveKudosDialogVisible}
              onClose={onHideGiveKudosDialog}
              onGiveKudosSuccess={onGiveKudosSuccess}
              user={user}
              value={value}
            />

            <RemainingKudosDialog
              isVisible={isRemainingKudoDialogVisible}
              onClose={() => setIsRemainingKudoDialogVisible(false)}
            />
          </footer>
        </>
      )}
    </>
  );
};

export default KudosList;
