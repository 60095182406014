import {EventName} from '../../../../constants/analytics/event-name';
import {EventType} from '../../../../constants/analytics/event-type';
import {FunctionComponent, useCallback} from 'react';
import {Link} from 'react-router-dom';
import {ReactComponent as IconCheck} from '../../../@components/kit/icons/check.svg';
import {useTranslation} from 'react-i18next';
import logoSource from '../../../../assets/images/logo.png';
import styles from './styles.module.scss';
import useAnalytics from '../../../@hooks/use-analytics';

interface ElementProps {
  isProgressSavedVisible?: boolean;
}

const AssessmentHeader: FunctionComponent<ElementProps> = ({
  isProgressSavedVisible,
}) => {
  const {t} = useTranslation('assessments');
  const {trackEvent} = useAnalytics();

  const onDashboardButtonClicked = useCallback(() => {
    trackEvent({
      eventName: EventName.ASSESSMENTS.RETURN_TO_DASHBOARD,
      eventType: EventType.BUTTON_CLICKED,
    });
  }, [trackEvent]);

  return (
    <div className={styles.header}>
      <img src={logoSource} alt="" className={styles.logo} />

      <div className={styles.content}>
        {isProgressSavedVisible && (
          <div className={styles.progressSaved}>
            {t('components.header.progress-saved')}

            <IconCheck className={styles.icon} />
          </div>
        )}

        <Link
          to="/application/dashboard"
          className={styles.backLink}
          onClick={onDashboardButtonClicked}
        >
          {t('components.header.back-button')}
        </Link>
      </div>
    </div>
  );
};

export default AssessmentHeader;
