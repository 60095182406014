import React, {useState} from 'react';
import ChatGroup from './chat-group';
import styles from './styles.module.scss';

interface InputProps {
  name: string;
  pretext?: string;
  question: string;
  type?: string;
  placeholder?: string;
  validate?: (value: string) => boolean;
  renderAnswer?: (state: any) => any;
  dispatch?: (action: {type: string; payload: string}) => void;
  state?: any;
  width?: number | number[];
  noScroll?: boolean;
  scrollParent?: React.RefObject<HTMLElement>;
  finaltext?: string;
  onAnswer?: (value: string) => void;
}

export const Input: React.FC<InputProps> = ({
  name,
  pretext,
  question,
  type,
  placeholder = 'Type your answer...',
  validate,
  renderAnswer,
  dispatch,
  state,
  noScroll,
  scrollParent,
  finaltext,
  onAnswer,
}) => {
  return (
    <ChatGroup
      pretext={pretext}
      question={question}
      state={state}
      name={name}
      noScroll={noScroll}
      renderAnswer={renderAnswer}
      scrollParent={scrollParent}
      finaltext={finaltext}
    >
      <InputSection
        state={state}
        name={name}
        type={type}
        placeholder={placeholder}
        dispatch={dispatch}
        validate={validate}
        onAnswer={onAnswer}
      />
    </ChatGroup>
  );
};

interface InputSectionProps {
  state?: any;
  name: string;
  type?: string;
  placeholder?: string;
  validate?: (value: string) => boolean;
  dispatch?: (action: {type: string; payload: string}) => void;
  onAnswer?: (value: string) => void;
}

const InputSection: React.FC<InputSectionProps> = ({
  state,
  name,
  type,
  placeholder,
  validate,
  dispatch,
  onAnswer,
}) => {
  const [value, set] = useState('');

  if (!dispatch) return null;

  return (
    <div className={styles.inputContainer}>
      {!state[name] && (
        <input
          className={styles.input}
          value={value}
          placeholder={placeholder}
          onChange={(e: any) => set(e.target.value)}
          type={type}
          aria-label={name}
        />
      )}
      {!state[name] && (
        <button
          className={styles.sendButton}
          onClick={() => {
            if (onAnswer) {
              onAnswer(value);
            }
            if (typeof validate !== 'function') {
              return dispatch({type: name, payload: value});
            }
            if (validate(value)) {
              return dispatch({type: name, payload: value});
            }
          }}
        >
          <svg width="24" height="24" fill="white" viewBox="0 0 495 495">
            <path d="M165 457a8 8 0 0012 6l55-37-67-33v64zM492 32a8 8 0 00-9-1L8 264a14 14 0 000 26l125 60 251-206-220 220 157 75a14 14 0 0019-8L494 41c2-3 1-6-2-9z" />
          </svg>
        </button>
      )}
    </div>
  );
};

Input.defaultProps = {
  placeholder: 'Type your answer…',
  width: [320, 400],
};
