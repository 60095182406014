import {FunctionComponent, PropsWithChildren} from 'react';
import styles from './styles.module.scss';

const VARIANT_LARGE = 'large';
const VARIANT_MEDIUM = 'medium';
const VARIANT_SMALL = 'small';
const VARIANT_XLARGE = 'xlarge';
const VARIANT_XSMALL = 'xsmall';
const VARIANT_XXSMALL = 'xxsmall';
const VARIANT_NAME_COLUMN = 'nameColumn';

export interface ColumnVariant {
  variant?:
    | typeof VARIANT_LARGE
    | typeof VARIANT_MEDIUM
    | typeof VARIANT_NAME_COLUMN
    | typeof VARIANT_SMALL
    | typeof VARIANT_XLARGE
    | typeof VARIANT_XSMALL
    | typeof VARIANT_XXSMALL
    | typeof VARIANT_XSMALL;
}

interface ElementProps extends ColumnVariant {
  className?: string;
}

const Data: FunctionComponent<PropsWithChildren<ElementProps>> = ({
  children,
  variant,
  ...props
}) => {
  const variantClass = variant ? styles[variant] : '';

  return (
    <td className={`${styles.column} ${variantClass} ${props.className ?? ''}`}>
      {children}
    </td>
  );
};

export default Data;
