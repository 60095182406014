import {FunctionComponent} from 'react';
import {KudoReceivedPayload} from '../../../../../interfaces/notifications/kudo';
import {Link} from 'react-router-dom';
import {Trans, useTranslation} from 'react-i18next';
import {TRANSLATION_KEY_PREFIX} from '../../notification-event';
import styles from '../../styles.module.scss';

interface ElementProps {
  payload: KudoReceivedPayload;
}

const KudoReceived: FunctionComponent<ElementProps> = ({payload}) => {
  const {t} = useTranslation('common', {keyPrefix: TRANSLATION_KEY_PREFIX});

  return (
    <Link
      to={`/application/kudos?kudoUuid=${payload.kudoUuid}`}
      className={styles.link}
    >
      <Trans
        t={t}
        i18nKey="kudo-received"
        components={{bold: <b />}}
        values={{
          fromUserName: payload.fromUserName,
          valueName: payload.valueName,
        }}
      />
    </Link>
  );
};

export default KudoReceived;
