export const EventName = {
  ACCOUNT_SET_UP: {
    CODIFY_YOUR_VALUES: {
      ADD_ANOTHER_VALUE:
        'Account Set Up – Codify Your Values – Add another value',
      EMBED_YOUTUBE:
        'Account Set Up – Codify Your Values – Embed Youtube Video',
      PREFIX: 'Account Set Up – Codify Your Values',
      SAVE_CONTINUE: 'Account Set Up – Codify Your Values – Save & Continue',
      SKIP_FOR_NOW: 'Account Set Up – Codify Your Values – Skip for now',
      UPLOAD_IMAGE_FROM_COMPUTER:
        'Account Set Up – Codify Your Values – Upload Image from computer',
      UPLOAD_MEDIA:
        'Account Set Up – Codify Your Values – Upload Media or a Training Module',
    },
  },
  ASSESSMENTS: {
    RETURN_TO_DASHBOARD: 'Assessments – Return to dashboard',
  },
  APPLY: {
    ACTION_STEP_STATUS: {
      CLEAR_SEARCH: 'Apply – Action Step Status – Clear search',
      SEARCH: 'Apply – Action Step Status – Search',
    },
  },
  CANDIDATES: {
    REGISTER: {
      INVALID_TOKEN: 'Candidates – Register – Invalid token',
    },
    ASSESSMENT: {
      QUESTION_ANSWERED: 'Candidates – Assessment – Question answered',
    },
  },
  COMPANY_REGISTRATION: {
    ENTER_YOUR_NAME: 'Company Registration – Enter your name',
    TELL_US_ABOUT_COMPANY:
      'Company Registration – Tell us a bit about your company',
    PERSONAL_INFORMATION: 'Company Registration – Personal Information',
  },
  COMPONENTS: {
    INTERACTIVE_VALUES: {
      CREATE_NEW_VALUE: 'Interactive Values – Create new value',
      EMBED_YOUTUBE_VIDEO:
        'Interactive Values – New Value dialog – Embed YouTube Video',
      SAVE_VALUE: 'Interactive Values – New Value dialog – Save value',
      UPLOAD_IMAGE_FROM_COMPUTER:
        'Interactive Values – New Value dialog – Upload Image from computer',
    },
    HIERARCHY_EDIT: {
      ADD_DIRECT_MANAGER: 'Hierarchy Edit – Add direct manager',
      ADD_DIRECT_REPORT: 'Hierarchy Edit – Add direct report',
      ADD_JOB_TITLE: 'Hierarchy Edit – Add job title',
    },
    NOTIFICATION_CENTER: {
      SHOW_NOFICIATIONS: 'Notification Center – Show notifications',
      LOAD_MORE: 'Notification Center – Load more',
    },
    KUDOS: {
      RECOGNIZE_COLLEAGUE: 'Kudos – Recognize a colleague',
      SEND_KUDO_TO_COLLEAGUE: 'Kudos – Send kudo to a colleague',
      CANCEL_KUDO: 'Kudos – Cancel kudo',
      HIDE_KUDO: 'Kudos – Hide kudo',
      UNHIDE_KUDO: 'Kudos – Unhide kudo',
    },
    UPLOAD_MEDIA: {
      EMBED_YOUTUBE_VIDEO: 'Upload Media Component – Embed Youtube Video',
      UPLOAD_IMAGE_FROM_COMPUTER:
        'Upload Media Component – Upload Image from computer',
    },
  },
  DESIGN: {
    LEADERSHIP_MANIFESTO: 'Design – Leadership Manifesto',
    MISSION: 'Design – Mission',
    VISION: 'Design – Vision',
  },
  HOME: {
    CULTURE_CHECKLIST: {
      LINK_CLICKED: 'Home – Culture Checklist – Link clicked',
    },
    MEETINGS_LIST: {
      LINK_CLICKED: 'Home – Meetings List – Link clicked',
    },
    PLAYBOOKS: {
      LEARN_MORE: 'Home – Playbooks Just For You – Learn more',
      LINK_CLICKED: 'Home – Playbooks Just For You – Link clicked',
    },
  },
  ACTION_STEPS: {
    DETAILS: 'Action Steps – Details',
    NAVIGATE_FROM_DASHBOARD: 'Dashboard – Action Steps',
    NAVIGATE_FROM_DASHBOARD_HIGHLIGHT: 'Dashboard – Action Steps – Details',
    ACTION_STEP_DETAIL_DIALOG: {
      CLOSE_DIALOG: 'Action Steps – Action Step Dialog – Close',
      OPEN_CONFIRM_DIALOG:
        'Action Steps – Action Step Dialog – Open Confirm Dialog',
      COMPLETE_DIALOG: {
        COMPLETE:
          'Action Steps – Action Step Dialog – Complete Dialog – Complete',
        CANCEL: 'Action Steps – Action Step Dialog – Complete Dialog – Cancel',
      },
    },
  },
  JOBS: {
    CANDIDATES: {
      ADD_CANDIDATE: 'Jobs – Candidates – Add candidate',
      EDIT_CANDIDATE: 'Jobs – Candidates – Edit Candidate',
      EDIT_CANDIDATE_DIALOG: {
        UPDATE_CANDIDATE: 'Jobs – Candidates – Edit Candidate – Update',
        CLOSE_DIALOG: 'Jobs – Candidates – Edit Candidate – Cancel',
      },
      CANDIDATE_RESULT_DIALOG: {
        OPEN_DIALOG: 'Jobs – Candidates – Candidate Result Dialog – Open',
      },
      CANDIDATE_DIALOG: {
        FORM: {
          CLOSE_DIALOG:
            'Jobs – Candidates – Initiate Candidate Assessment – Cancel',
          GO_TO_SUMMARY:
            'Jobs – Candidates – Initiate Candidate Assessment – Next',
        },
        SUMMARY: {
          INITIATE_ASSESSMENT:
            'Jobs – Candidates – Confirmation – Initiate Assessment',
          UPDATE_ASSESSMENT_DETAILS:
            'Jobs – Candidates – Confirmation – Update Assessment Details',
        },
      },
    },
  },
  PAGE_VIEWS: {
    ASSESSMENT_DETAILS: 'Page View – Assessment',
    APPLY: {
      ACTION_STEPS: 'Page View – Apply – Action Steps',
      ACTION_STEP_STATUS: 'Page View – Apply – Action Step Status',
    },
    DASHBOARD: 'Page View – Dashboard',
    CANDIDATES: {
      REGISTER: 'Page View – Candidates – Register',
      ASSESSMENT: 'Page View – Candidates – Assessment',
      FEEDBACK: 'Page View – Candidates – Feedback',
    },
    VALUE: {
      INDEX: 'Page View – Value',
    },
    DESIGN: 'Page View – Design',
    JOBS: {
      JOB_POSITIONS: 'Page View – Jobs – Job Positions',
      CANDIDATES: 'Page View – Jobs – Candidates',
    },
    KUDOS: 'Page View – Kudos',
    MY_PROFILE: 'Page View – My Profile',
    LINK_SLACK: 'Page View – Link Slack',
    MEASURE: {
      OVERALL_HEALTH: 'Page View – Measure – Overall Health',
      YOUR_ASSESSMENTS: 'Page View – Measure – Your Assessments',
      YOUR_REPORTS: 'Page View – Measure – Your Reports',
      MANAGE_ASSESSMENTS: {
        INDEX: 'Page View – Measure – Manage Assessments – List',
        SHOW: 'Page View – Measure – Manage Assessments – Events',
      },
    },
    ORGANIZATION_SETUP: 'Page View – Organization Setup',
    PEOPLE: {
      EMPLOYEES: {
        INDEX: 'Page View – People – Employees – List',
        SHOW: 'Page View – People – Employees – Show',
      },
      TEAMS: 'Page View – People – Teams',
      MANAGE_DIRECTORY: 'Page View – People – Manage Directory',
    },
    SETUP: 'Page View - Setup',
    SENSE: {
      MEETINGS: {
        INDEX: 'Page View - Sense - Meetings - List',
        SHOW: {
          INDEX: 'Page View - Sense - Meetings - Show',
          TRANSCRIPT: 'Page View - Sense - Meetings - Show - Transcript',
        },
      },
    },
    USER_SETUP: 'Page View - User Setup',
    SETTINGS: {
      CONFIGURATIONS: 'Page View – Settings – Configurations',
      MANAGE_COMPANY_PROFILE: 'Page View – Settings – Manage Company Profile',
    },
    ADMIN_PORTAL: 'Page View – Admin Portal',
  },
  MEASURE: {
    MANAGE_ASSESSMENTS: {
      EVENTS: {
        VIEW_REPORT: 'Measure – Manage Assessments – Events – View Report',
        DOWNLOAD_REPORT:
          'Measure – Manage Assessments – Events – Download Report',
      },
      POPULATION_DIALOG: 'Measure – Manage Assessments – Population Dialog',
      SEND_ASSESSMENT: 'Measure – Manage Assessments – Send assessment',
      SEND_MESSAGE:
        'Measure – Manage Assessments – Send message dialog – Send message',
      TURN_OFF_ASSESSMENT: 'Measure – Manage Assessments – Turn off assessment',
      UPDATE_SETTINGS:
        'Measure – Manage Assessments – Update setting dialog – Update',
      VIEW_EVENTS: 'Measure – Manage Assessments – View Events',
    },
    YOUR_REPORTS: {
      VIEW_RESULT: 'Measure – Your Reports – View Results',
    },
    YOUR_ASSESSMENTS: {
      VIEW_RESULT: 'Measure – Your Assessments – View Results',
      START_ASSESSMENT: 'Measure – Your Assessments – Start assessment',
    },
  },
  PEOPLE: {
    EMPLOYEES: {
      CLEAR_SEARCH: 'People – Employees – Clear search',
      PROFILE_CLICK_MANAGER_COLUMN:
        'People – Employees – Manager column – Profile',
      PROFILE_CLICK_NAME_COLUMN: 'People – Employees – Name column – Profile',
      SEARCH: 'People – Employees – Search',
    },
    MANAGE_DIRECTORY: {
      CLEAR_SEARCH: 'People – Manage Directory – Clear search',
      INVITE_USERS: 'People – Manage Directory – Invite users',
      PERMISSIONS_CHANGES: 'People – Manage Directory – Permissions changes',
      PROFILE_CLICK_MANAGER_COLUMN:
        'People – Manage Directory – Manager column – Profile',
      PROFILE_CLICK_NAME_COLUMN:
        'People – Manage Directory – Name column – Profile',
      SEARCH: 'People – Manage Directory – Search',
      SEND_INVITES: 'People – Manage Directory – Invite users – Send invites',
      SEND_REMINDER: 'People – Manage Directory – Send reminder',
      STATUS_CHANGES: 'People – Manage Directory – Active/Inactive changes',
    },
    TEAMS: {
      CLEAR_SEARCH: 'People – Teams – Clear search',
      SEARCH: 'People – Teams – Search',
      CREATE_TEAM: {
        OPEN_DIALOG: 'People – Teams – Create Team dialog – Open',
        CLOSE_DIALOG: 'People – Teams – Create Team dialog – Close',
        SUCCESS: 'People – Teams – Create Team dialog – Create',
      },
      DETAILS_DIALOG: {
        OPEN_DIALOG: 'People – Teams – Details Dialog – Open',
        CLOSE_DIALOG: 'People – Teams – Details Dialog – Cancel',
        UPDATE_TEAM: 'People – Teams – Details Dialog – Update',
        DELETE_TEAM: 'People – Teams – Details Dialog – Delete',
        PROFILE: 'People – Teams – Details Dialog – Profile',
      },
    },
  },
  SENSE: {
    INDEX: {
      INVITE_SENSE: 'Meetings – Index – Invite Sense',
      CLEAR_SEARCH: 'Meetings – Index – Clear search',
      SEARCH: 'Meetings – Index – Search',
    },
    SHOW: {
      MEETING_DETAILS: {
        COMPLETE_TRANSCRIPT: 'Meetings – Meeting Details – Complete Transcript',
      },
    },
  },
  ADMIN_PORTAL: {
    COMPANY_LIST: {
      CLEAR_SEARCH: 'Company List – Clear search',
      CREATE_NEW_COMPANY: 'Admin Portal – Company List – Create New Company',
      SAVE_COMPANY:
        'Admin Portal - Company List - New Company dialog – Save Company',
      SEARCH: 'Company List – Search',
    },
  },
  PREFIXES: {
    HOME: 'Home',
    VALUES_SHOW: 'Interactive Values – Details',
  },
  PROFILE: {
    ORGANIZATION_STRUCTURE: {
      ADD_DIRECT_MANAGER:
        'Profile Details – Organization Structure – Add direct manager',
      ADD_DIRECT_REPORT:
        'Profile Details – Organization Structure – Add direct report',
      ADD_JOB_TITLE: 'Profile Details – Organization Structure – Add job title',
    },
  },
  USER_SETUP: {
    CREATE_YOUR_PROFILE: {
      BASIC_PROFILE: {
        SAVE_CONTINUE:
          'User Profile Set Up – Create your profile – Basic profile – Save & Continue',
      },
      PERZONALIZE_YOUR_PROFILE: {
        SKIP_FOR_NOW:
          'User Profile Set Up – Create your profile – Personalize your profile – Skip for now',
        SAVE_CONTINUE:
          'User Profile Set Up – Create Your Profile – Personalize your profile – Save & Continue',
      },
    },
    ENTER_WORK_STYLE: {
      SAVE_CONTINUE: 'User Profile Set Up – Enter work style – Save & continue',
      SKIP_FOR_NOW:
        'User Profile Set Up – Map Your Organization – Skip for now',
    },
    MAP_YOUR_ORGANIZATION: {
      ADD_DIRECT_MANAGER:
        'User Profile Set Up – Map Your Organization – Add direct manager',
      ADD_DIRECT_REPORT:
        'User Profile Set Up – Map Your Organization – Add direct report',
      ADD_JOB_TITLE:
        'User Profile Set Up – Map Your Organization – Add job title',
      SAVE_CONTINUE:
        'User Profile Set Up – Map Your Organization – Save & Continue',
      SKIP_FOR_NOW:
        'User Profile Set Up – Map Your Organization – Skip for now',
    },
    REVIEW_COMPANY_VALUES: 'User Profile Set Up – Review Company Value',
    SIGN_OFF_ON_VALUES: 'User Profile Set Up – Sign Off on Values',
  },
  VALUES: {
    SHOW: {
      DELETE_VALUE: 'Interactive Values – Details – Delete value',
      EDIT_VALUE: 'Interactive Values – Details – Edit value',
    },
  },
};
