import {Field, FieldProps, Form, Formik} from 'formik';
import {FunctionComponent, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import Button from '../../../../@components/kit/form/button';
import FormControl from '../../../../@components/kit/form/form-control';
import FormFooter from '../../../../@components/kit/form/form-footer';
import FormGroup from '../../../../@components/kit/form/form-group';
import Input from '../../../../@components/kit/form/input';
import Label from '../../../../@components/kit/form/label';
import styles from './styles.module.scss';
import {useUpdateCandidateProfile} from '../../../../@hooks/mutations';
import {useNavigate} from 'react-router';

interface ElementProps {
  candidateToken: string;
  candidateUuid: string;
}

interface RegisterCandidateFormValues {
  name: string;
}

const RegisterCandidateForm: FunctionComponent<ElementProps> = ({
  candidateToken,
  candidateUuid,
}) => {
  const {t} = useTranslation('candidates', {
    keyPrefix: 'register.components.register-candidate-form',
  });

  const navigate = useNavigate();

  const INITIAL_VALUES = {
    name: '',
  };

  const VALIDATION_SCHEMA = Yup.object().shape({
    name: Yup.string().required(),
  });

  const updateCandidateProfile = useUpdateCandidateProfile();

  const onSubmit = useCallback(
    async (values: RegisterCandidateFormValues) => {
      const response = await updateCandidateProfile.mutateAsync({
        name: values.name,
        candidateToken,
      });

      if (response) {
        navigate('/candidates/assessment', {
          state: {
            candidateToken,
            candidateUuid: candidateUuid,
          },
        });
      }
    },
    [candidateToken, candidateUuid, navigate, updateCandidateProfile]
  );

  return (
    <>
      <Formik
        initialValues={INITIAL_VALUES}
        onSubmit={onSubmit}
        validationSchema={VALIDATION_SCHEMA}
        validateOnMount
      >
        {({isValid}) => (
          <Form className={styles.form}>
            <div className={styles.fields}>
              <Field name="name">
                {({
                  field,
                  form,
                }: FieldProps<string, RegisterCandidateFormValues>) => (
                  <FormGroup variant="small">
                    <FormControl
                      error={
                        form.errors.name && form.touched.name
                          ? t('error')
                          : undefined
                      }
                    >
                      <Label htmlFor="name">{t('label')}</Label>

                      <Input
                        type="text"
                        placeholder={t('placeholder')}
                        id={field.name}
                        {...field}
                      />
                    </FormControl>
                  </FormGroup>
                )}
              </Field>
            </div>

            <FormFooter>
              <Button type="submit" disabled={!isValid}>
                {t('submit-button')}
              </Button>
            </FormFooter>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default RegisterCandidateForm;
