import {Theme} from 'react-select';

const selectStyles = (hasError?: boolean) => {
  return {
    indicatorSeparator: () => ({
      display: 'none',
    }),
    placeholder: (provided: any, state: any) => ({
      ...provided,
      color: (() => {
        if (hasError) {
          return '#8e1818';
        } else if (state.isDisabled) {
          return '#bcbec7';
        } else {
          return '#5e5f64';
        }
      })(),
    }),
    input: (provided: any) => ({
      ...provided,
      color: (() => {
        if (hasError) {
          return '#8e1818';
        } else {
          return '#000';
        }
      })(),
    }),
    option: (provided: any) => ({
      ...provided,
      fontFamily: '"TT Interfaces", sans-serif',
      fontSize: '14px',
      padding: '14px 12px',
      cursor: 'pointer',
    }),
    dropdownIndicator: (provided: any, state: any) => ({
      ...provided,
      color: (() => {
        if (hasError) {
          return '#8e1818';
        } else if (state.isDisabled) {
          return '#bcbec7';
        } else {
          return '5e5f64';
        }
      })(),
    }),
    container: (provided: any) => ({
      ...provided,
      width: '100%',
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      'padding': (() => {
        if (state.isMulti) {
          return '0 12px 6px 0';
        } else {
          return '0 12px 6px 12px';
        }
      })(),
      'border': '0',
      'borderBottom': (() => {
        if (hasError) {
          return '1px solid #8e1818';
        } else if (state.isDisabled) {
          return '1px solid #bcbec7';
        } else {
          return '1px solid #000';
        }
      })(),
      'borderRadius': '0',
      'boxShadow': 'none',
      'fontSize': '14px',
      'fontFamily': '"TT Interfaces", sans-serif',
      'color': '#000',
      'lineHeight': '1.4',
      'background': 'none',

      '&:hover': {
        borderBottom: '1px solid #000',
      },
    }),
    valueContainer: (provided: any, state: any) => ({
      ...provided,
      width: 200,
      padding: '0',
      margin: '0',
    }),
    multiValue: (provided: any, state: any) => ({
      ...provided,
      border: '1px solid #d0d2d8',
      background: 'none',
      borderRadius: '5px',
      padding: '4px',
      fontSize: '14px',
      opacity: (() => {
        if (state.isDisabled) {
          return '0.5';
        } else {
          return '1';
        }
      })(),
    }),
  };
};

const selectTheme = (theme: Theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#fafafb',
  },
});

export {selectStyles, selectTheme};
