import {formatDuration, intervalToDuration} from 'date-fns';

function formatDurationFromSeconds(durationInSeconds: number) {
  const date = new Date();

  const endDate = new Date(
    date.setTime(date.getTime() + durationInSeconds * 1000)
  );

  const fullDuration = intervalToDuration({
    start: new Date(),
    end: endDate,
  });

  return formatDuration(fullDuration, {
    format: ['days', 'hours', 'minutes'],
  });
}

export default formatDurationFromSeconds;
