import {CompanyEmployeeTeam} from '../../../interfaces/company-employee';
import {FunctionComponent} from 'react';
import styles from './styles.module.scss';

interface ElementProps {
  team: CompanyEmployeeTeam;
}

const EmployeeTeamTag: FunctionComponent<ElementProps> = ({team}) => {
  if (!team) return <></>;

  return (
    <>
      <div className={styles.container}>
        <div
          style={{backgroundColor: team.colorHex}}
          className={styles.color}
        />

        <div className={styles.name}>{team.name}</div>
      </div>
    </>
  );
};

export default EmployeeTeamTag;
