import {createQuery} from 'react-query-kit';
import {Transcript} from '../../../interfaces/transcript';
import {
  FetchTranscriptPayload,
  fetchTranscript,
} from '../../../repositories/instill/queries/fetch-transcript';

export const useTranscript = createQuery<
  Transcript[] | undefined,
  FetchTranscriptPayload
>({
  primaryKey: 'transcript',
  queryFn: async ({queryKey: [_, variables]}) => {
    if (!variables.meetingUuid) {
      return Promise.reject();
    }

    return fetchTranscript({
      companyUuid: variables.companyUuid,
      meetingUuid: variables.meetingUuid,
    });
  },
});
