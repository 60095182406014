import {UserSettings, UserSettingsRaw} from '../../interfaces/settings';

export const sanitizeUserSettingsFromRaw = (
  data: UserSettingsRaw
): UserSettings => {
  return {
    senseAutoJoin: data.sense_auto_join,
  };
};

export const sanitizeUserSettingsForRaw = (
  data: UserSettings
): UserSettingsRaw => {
  return {
    sense_auto_join: data.senseAutoJoin,
  };
};
