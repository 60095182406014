import {sanitizeDimensionsFromRaw} from '../../../ui/@sanitizers/dimension';
import InstillAPI from '../axios-instance';
import isResponseSuccessful from '../../../utils/is-response-successful';

export interface FetchDimensionsPayload {
  companyUuid: string;
}

export async function fetchDimensions(companyUuid: string) {
  try {
    const response = await InstillAPI.get(
      `/companies/${companyUuid}/organizational-health/dimensions`
    );

    if (!response.data || !isResponseSuccessful(response)) {
      throw Error('No data received');
    }

    return sanitizeDimensionsFromRaw(response.data);
  } catch (error) {
    console.error(error);
  }
}
