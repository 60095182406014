export const SENTIMENTAL_ANALYSIS_COLORS = {
  POSITVE: {
    pathColor: '#6ed29c',
    trailColor: '#f5f5f5',
  },
  NEUTRAL: {
    pathColor: '#f6c75c',
    trailColor: '#f5f5f5',
  },
  NEGATIVE: {
    pathColor: '#ff3b6b',
    trailColor: '#f5f5f5',
  },
};
