import {Module, ModuleRaw} from '../../interfaces/module';
import {sanitizeQuestionFromRaw} from './questions';

export const sanitizeModuleFromRaw = (data: ModuleRaw[]): Module[] => {
  return data.map((module) => {
    const {description, name, notes, rank, title, uuid} = module;

    let sanitizedModule: Module = {
      description,
      estimatedDuration: module.estimated_duration,
      name,
      notes,
      questions: sanitizeQuestionFromRaw(module.questions),
      rank,
      title,
      uuid,
    };

    return sanitizedModule;
  });
};
