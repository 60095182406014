import {createQuery} from 'react-query-kit';
import {
  FetchCandidateAlignmentResultPayload,
  fetchCandidateAlignmentResult,
} from '../../../repositories/instill/queries/fetch-candidate-alignment-result';
import {CandidateAlignmentResult} from '../../../interfaces/candidate-alignment-result';

export const useCandidateAlignmentResult = createQuery<
  CandidateAlignmentResult | undefined,
  FetchCandidateAlignmentResultPayload
>({
  primaryKey: 'candidateAlignmentResult',
  queryFn: async ({queryKey: [_, variables]}) => {
    if (!variables.candidateUuid) {
      return Promise.reject();
    }

    return await fetchCandidateAlignmentResult(variables.candidateUuid);
  },
});
