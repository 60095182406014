import {Actions} from '../../../constants/permissions/actions';
import {FunctionComponent, useMemo} from 'react';
import {Navigate, Route, Routes, useNavigate} from 'react-router';
import {useCheckPermissionOrRedirect} from '../../@hooks/use-check-permission-or-redirect';
import {useTranslation} from 'react-i18next';
import Candidates from './candidates';
import FeedbackBanner from '../../@components/feedback-banner';
import Header from '../@components/header';
import JobPosition from './job-positions';
import MainContent from '../@components/main-content';

const Jobs: FunctionComponent = () => {
  const {t} = useTranslation('jobs');

  const navigate = useNavigate();
  const hasAccess = useCheckPermissionOrRedirect(
    Actions.VIEW_JOBS,
    '/application/jobs'
  );

  const links = useMemo(() => {
    return [
      {
        route: '/application/jobs/job-positions',
        label: t('components.header.sub-navigation.links.job-positions'),
      },
      {
        route: '/application/jobs/candidates',
        label: t('components.header.sub-navigation.links.candidates'),
      },
    ];
  }, [t]);

  if (!hasAccess) {
    navigate('/application/dashboard');
    return null;
  }

  return (
    <>
      <FeedbackBanner text={t('components.feedback-banner.text')} />

      <Header spacing="small" title={t('components.header.title')}>
        <Header.SubNavigation links={links} />
      </Header>

      <MainContent spacing="small">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Navigate replace to="job-positions" />
              </>
            }
          />

          <Route path="/job-positions" element={<JobPosition />} />
          <Route path="/candidates" element={<Candidates />} />
          <Route path="*" element={<Navigate replace to="job-positions" />} />
        </Routes>
      </MainContent>
    </>
  );
};

export default Jobs;
