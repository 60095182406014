import {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import {CompanyAssessment} from '../../../../../../../../../../../interfaces/company-assessments';
import Dialog from '../../../../../../../../../../@components/kit/dialog';
import styles from './styles.module.scss';

interface ElementProps {
  companyAssessment: CompanyAssessment | null;
  isVisible: boolean;
  onClose: () => void;
}

const DetailsDialog: FunctionComponent<ElementProps> = ({
  companyAssessment,
  isVisible,
  onClose,
}) => {
  const {t} = useTranslation('measure');

  return (
    <Dialog isOpen={isVisible} onClose={onClose}>
      <Dialog.Header>
        <div className={styles.title}>{companyAssessment?.assessment.name}</div>

        <div className={styles.subtitle}>
          {t(
            'manage-assessments.components.assessments.components.details-dialog.subtitle'
          )}
        </div>
      </Dialog.Header>

      <Dialog.Content>{companyAssessment?.assessment.overview}</Dialog.Content>
    </Dialog>
  );
};

export default DetailsDialog;
