import {buildPageTitle} from '../../../services/build-page-title';
import {FunctionComponent} from 'react';
import {Helmet} from 'react-helmet-async';
import {useSafeCurrentCompany} from '../../@atoms/current-company';
import {useTranslation} from 'react-i18next';
import CompanyProfile from '../../@components/company-profile';
import Header from '../@components/header';
import mainContainerStyles from '../../../styles/classes/main-container.module.scss';
import MainContent from '../@components/main-content';
import RoundedCard from '../../@components/rounded-card';

const MycompanyProfile: FunctionComponent = () => {
  const {t} = useTranslation(['myCompanyProfile', 'applicationPageTitle']);

  const currentCompany = useSafeCurrentCompany();

  return (
    <>
      <Helmet>
        <title>
          {buildPageTitle(
            t('page-title.my-company-profile', {
              ns: 'applicationPageTitle',
            })
          )}
        </title>
      </Helmet>

      <Header title={t('components.header.title')} />

      <MainContent>
        <main className={mainContainerStyles.container}>
          <RoundedCard>
            <CompanyProfile company={currentCompany} />
          </RoundedCard>
        </main>
      </MainContent>
    </>
  );
};

export default MycompanyProfile;
