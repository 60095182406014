export const COMPANY_SIZE_OPTIONS = [
  {
    label: 'constants.company-size.0-to-10',
    value: '0-10',
  },
  {
    label: 'constants.company-size.11-to-50',
    value: '11-50',
  },
  {
    label: 'constants.company-size.51-to-100',
    value: '51-100',
  },
  {
    label: 'constants.company-size.101-to-200',
    value: '101-200',
  },
  {
    label: 'constants.company-size.201-to-500',
    value: '201-500',
  },
  {
    label: 'constants.company-size.501-to-1000',
    value: '501-1000',
  },
  {
    label: 'constants.company-size.1001-to-5000',
    value: '1001-5000',
  },
  {
    label: 'constants.company-size.5000-plus',
    value: '5000+',
  },
];
