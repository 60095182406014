import {Actions} from '../../../../constants/permissions/actions';
import {buildPageTitle} from '../../../../services/build-page-title';
import {EventName} from '../../../../constants/analytics/event-name';
import {EventType} from '../../../../constants/analytics/event-type';
import {FunctionComponent, useEffect} from 'react';
import {Helmet} from 'react-helmet-async';
import {useCheckPermissionOrRedirect} from '../../../@hooks/use-check-permission-or-redirect';
import {useTranslation} from 'react-i18next';
import EmployeeList from './@components/employee-list';
import mainContainerStyles from '../../../../styles/classes/main-container.module.scss';
import RoundedCard from '../../../@components/rounded-card';
import useAnalytics from '../../../@hooks/use-analytics';

const ManageDirectory: FunctionComponent = () => {
  const {t} = useTranslation(['applicationPageTitle', 'people']);

  const {trackEvent} = useAnalytics();

  const hasAccess = useCheckPermissionOrRedirect(
    Actions.VIEW_MANAGE_DIRECTORY,
    '/application/people'
  );

  useEffect(() => {
    trackEvent({
      eventName: EventName.PAGE_VIEWS.PEOPLE.MANAGE_DIRECTORY,
      eventType: EventType.PAGE_VIEW,
    });
  }, [trackEvent]);

  if (!hasAccess) return null;

  return (
    <>
      <Helmet>
        <title>
          {buildPageTitle([
            t('page-title.people.manage-directory', {
              ns: 'applicationPageTitle',
            }),
            t('page-title.people.index', {ns: 'applicationPageTitle'}),
          ])}
        </title>
      </Helmet>

      <main className={mainContainerStyles.container}>
        <RoundedCard>
          <EmployeeList />
        </RoundedCard>
      </main>
    </>
  );
};

export default ManageDirectory;
