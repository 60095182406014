import {ChangeEvent, FunctionComponent, useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {ToastOptions, toast} from 'react-toastify';
import {DEFAULT_TOASTIFY_CONFIGURATION} from '../../../../../../constants/toastify-configuration';
import headingStyles from '../../../../../../styles/classes/heading.module.scss';
import {useSafeCurrentCompany} from '../../../../../@atoms/current-company';
import Label from '../../../../../@components/kit/form/label';
import SwitchToggle from '../../../../../@components/kit/form/switch-toggle';
import {useUpdateNotificationSettings} from '../../../../../@hooks/mutations';
import {useNotificationSettings} from '../../../../../@hooks/queries';
import styles from './styles.module.scss';

const NotificationSettingsForm: FunctionComponent = () => {
  const {t} = useTranslation('settings');

  const currentCompany = useSafeCurrentCompany();

  const notificationSettings = useNotificationSettings({
    variables: {
      companyUuid: currentCompany.uuid,
    },
  });

  const updateNotificationSettings = useUpdateNotificationSettings();

  const formValues = useMemo(() => {
    return {
      isEmailEnabled: notificationSettings.data?.isEmailEnabled ?? false,
      isSlackEnabled: notificationSettings.data?.isSlackEnabled ?? false,
    };
  }, [notificationSettings]);

  const onChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const updatedSettings = {
        ...formValues,
        [event.target.value]: event.target.checked,
      };

      const response = await updateNotificationSettings.mutateAsync({
        companyUuid: currentCompany.uuid,
        payload: updatedSettings,
      });

      if (!response) return;

      notificationSettings.refetch();

      const feedbackMessage = t(
        'configurations.components.notification-settings-form.feedback-messages.update-success'
      );

      toast.success(feedbackMessage, {
        ...(DEFAULT_TOASTIFY_CONFIGURATION as ToastOptions),
      });
    },
    [
      currentCompany,
      formValues,
      notificationSettings,
      t,
      updateNotificationSettings,
    ]
  );

  return (
    <>
      <h2 className={headingStyles.h3}>
        {t('configurations.components.notification-settings-form.title')}
      </h2>

      <form>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            <SwitchToggle
              isChecked={formValues.isEmailEnabled}
              onChange={onChange}
              value="isEmailEnabled"
              id="email"
            />

            <Label htmlFor="email" className={styles.label}>
              {t(
                'configurations.components.notification-settings-form.options.email'
              )}
            </Label>
          </li>

          {/*
            The Slack notification is hidden for now because we're doing a production release
              early next week (Week of May 15th) and the Slack integration won't be ready for that release.

          <li className={styles.listItem}>
            <SwitchToggle
              isChecked={formValues.isSlackEnabled}
              onChange={onChange}
              value="isSlackEnabled"
              id="slack"
            />

            <Label htmlFor="slack" className={styles.label}>
              {t(
                'configurations.components.notification-settings-form.options.slack'
              )}
            </Label>
          </li> */}
        </ul>
      </form>
    </>
  );
};

export default NotificationSettingsForm;
