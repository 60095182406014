import {CultureChecklistLinkClickedEvent} from '../../../../../interfaces/analytics/event-properties';
import {EventName} from '../../../../../constants/analytics/event-name';
import {FunctionComponent, useCallback, useMemo} from 'react';
import {Module, ModuleForChecklist} from '../../../../../interfaces/module';
import {NavLink} from 'react-router-dom';
import {ReactComponent as IconCheck} from '../../../../@components/kit/icons/check.svg';
import {useCurrentFullUserProfile} from '../../../../@atoms/current-full-user-profile';
import {UserAssessment} from '../../../../../interfaces/user-assessment';
import {useTranslation} from 'react-i18next';
import {useUserAssessments} from '../../../../@hooks/queries';
import RoundedCard from '../../../../@components/rounded-card';
import styles from './styles.module.scss';
import useAnalytics from '../../../../@hooks/use-analytics';
import {ASSESSMENT_STATUS} from '../../../../../constants/assessments';

const getModuleListBasedOnAssessments = (assessments: UserAssessment[]) => {
  let list = [];

  // Sort the assessment by rank
  list = assessments.sort(
    (firstUserAssessment, secondUserAssessment) =>
      firstUserAssessment.assessmentEvent.assessment.rank -
      secondUserAssessment.assessmentEvent.assessment.rank
  );

  // Sort the modules of the already sorted assessments
  list.map((userAssessment) => {
    return userAssessment.assessmentEvent.assessment.modules?.sort(
      (firstModule: Module, secondModule: Module) =>
        firstModule.rank - secondModule.rank
    );
  });

  // Add `assessment uuid` to each module so we can navigate to the right page
  // Remove the level UserAssessment and AssessmentEvent and go directly to Assessment and Modules
  list = list.map((userAssessment) => {
    return {
      ...userAssessment.assessmentEvent.assessment,
      modules: userAssessment.assessmentEvent.assessment.modules?.map(
        (module: any) => {
          return {
            ...module,
            assessmentUuid: userAssessment.uuid,
            isCompleted: userAssessment.completedModulesUuid.includes(
              module.uuid
            ),
            status: userAssessment.status,
          };
        }
      ),
    };
  });

  // Flatten everything to get a list of sorted modules
  list = list.flatMap((assessment) => {
    return assessment.modules?.flatMap((module: any) => module);
  });

  // Remove the assessments that are completed
  list = list.filter((item) => !item.isCompleted);

  return list;
};

const CultureCheckList: FunctionComponent = () => {
  const {t} = useTranslation('dashboard');

  const currentFullUserProfile = useCurrentFullUserProfile();
  const {trackEvent} = useAnalytics();

  const userAssessments = useUserAssessments({
    variables: {
      userProfileUuid: currentFullUserProfile.uuid,
    },
  });

  const modules = useMemo(() => {
    if (!userAssessments.data) return;

    return getModuleListBasedOnAssessments(userAssessments.data);
  }, [userAssessments]);

  const trackLinkClicked = useCallback(
    (module: ModuleForChecklist) => {
      trackEvent({
        eventName: EventName.HOME.CULTURE_CHECKLIST.LINK_CLICKED,
        eventProperties: {
          linkTitle: module.title,
        } as CultureChecklistLinkClickedEvent,
      });
    },
    [trackEvent]
  );

  return (
    <RoundedCard>
      <RoundedCard.Header variant="vertical">
        <RoundedCard.Title>
          {t('components.culture-checklist.title')}
        </RoundedCard.Title>

        <div className={styles.description}>
          {t('components.culture-checklist.description')}
        </div>
      </RoundedCard.Header>

      <RoundedCard.Content>
        {!userAssessments.isLoading && (
          <>
            {modules?.length ? (
              <ul className={styles.list}>
                {modules &&
                  modules.map((module, index) => {
                    const checkboxClassName = module.isCompleted
                      ? `${styles.checkboxInput} ${styles.completed}`
                      : styles.checkboxInput;

                    const isStatusInProgress =
                      module.status === ASSESSMENT_STATUS.STARTED;

                    return (
                      <li className={styles.item} key={index}>
                        <NavLink
                          className={styles.link}
                          onClick={() => trackLinkClicked(module)}
                          to={`/assessments/${module.assessmentUuid}/modules/${module.uuid}`}
                        >
                          <div className={styles.checkbox}>
                            <div className={checkboxClassName}>
                              <IconCheck className={styles.checkboxIcon} />
                            </div>
                          </div>

                          <div className={styles.title}>
                            {module.title}

                            {isStatusInProgress && (
                              <div className={styles.titleNote}>
                                {t('components.culture-checklist.in-progress')}
                              </div>
                            )}
                          </div>
                        </NavLink>
                      </li>
                    );
                  })}
              </ul>
            ) : (
              <div className={styles.completedAssessments}>
                {t(
                  'components.culture-checklist.all-assessments-are-completed'
                )}
              </div>
            )}
          </>
        )}

        <div className={styles.item}>
          <NavLink to="/application/kudos" className={styles.link}>
            {t('components.culture-checklist.links.kudos')}
          </NavLink>
        </div>
      </RoundedCard.Content>
    </RoundedCard>
  );
};

export default CultureCheckList;
