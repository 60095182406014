import {FunctionComponent} from 'react';
import {ReactComponent as IconAccount} from '../../../../../@components/kit/icons/settings.svg';
import {ReactComponent as IconAssessments} from '../../../../../@components/kit/icons/activity.svg';
import {ReactComponent as IconCheck} from '../../../../../@components/kit/icons/check.svg';
import {ReactComponent as IconContent} from '../../../../../@components/kit/icons/edit.svg';
import {ReactComponent as IconUsers} from '../../../../../@components/kit/icons/users.svg';
import {useTranslation} from 'react-i18next';
import Dialog from '../../../../../@components/kit/dialog';
import styles from './styles.module.scss';

interface ElementProps {
  isOpen: boolean;
  onClose: () => void;
}

const Permissions: FunctionComponent<ElementProps> = ({isOpen, onClose}) => {
  const {t} = useTranslation('components');

  return (
    <>
      <Dialog isOpen={isOpen} onClose={onClose}>
        <Dialog.Header
          title={t('invite-users.form.permissions.dialog.title')}
        />

        <Dialog.Content>
          <div className={styles.table}>
            <div className={styles.tableHead}>
              <div className={styles.tableRow}>
                <div className={styles.tableData}>&nbsp;</div>

                <div className={styles.tableData}>
                  {t('invite-users.form.permissions.table.headers.members')}
                </div>

                <div className={styles.tableData}>
                  {t('invite-users.form.permissions.table.headers.managers')}
                </div>

                <div className={styles.tableData}>
                  {t('invite-users.form.permissions.table.headers.admins')}
                </div>

                <div className={styles.tableData}>
                  {t('invite-users.form.permissions.table.headers.owners')}
                </div>
              </div>
            </div>

            <div className={styles.tableBody}>
              <div className={styles.tableRow}>
                <div className={styles.tableSeparator}>
                  <IconContent className={styles.icon} />

                  {t('invite-users.form.permissions.table.headers.content')}
                </div>
              </div>

              <div className={styles.tableRow}>
                <div className={styles.tableData}>
                  {t(
                    'invite-users.form.permissions.table.view-values-mission-vision'
                  )}
                </div>

                <div className={styles.tableData}>
                  <IconCheck className={styles.icon} />
                </div>

                <div className={styles.tableData}>
                  <IconCheck className={styles.icon} />
                </div>

                <div className={styles.tableData}>
                  <IconCheck className={styles.icon} />
                </div>

                <div className={styles.tableData}>
                  <IconCheck className={styles.icon} />
                </div>
              </div>

              <div className={styles.tableRow}>
                <div className={styles.tableData}>
                  {t(
                    'invite-users.form.permissions.table.edit-values-mission-vision'
                  )}
                </div>

                <div className={styles.tableData}>&nbsp;</div>

                <div className={styles.tableData}>&nbsp;</div>

                <div className={styles.tableData}>
                  <IconCheck className={styles.icon} />
                </div>

                <div className={styles.tableData}>
                  <IconCheck className={styles.icon} />
                </div>
              </div>

              <div className={styles.tableRow}>
                <div className={styles.tableSeparator}>
                  <IconUsers className={styles.icon} />

                  {t('invite-users.form.permissions.table.headers.users')}
                </div>
              </div>

              <div className={styles.tableRow}>
                <div className={styles.tableData}>
                  {t('invite-users.form.permissions.table.add-remove-users')}
                </div>

                <div className={styles.tableData}>&nbsp;</div>

                <div className={styles.tableData}>&nbsp;</div>

                <div className={styles.tableData}>
                  <IconCheck className={styles.icon} />
                </div>

                <div className={styles.tableData}>
                  <IconCheck className={styles.icon} />
                </div>
              </div>

              <div className={styles.tableRow}>
                <div className={styles.tableData}>
                  {t(
                    'invite-users.form.permissions.table.change-users-permissions'
                  )}
                </div>

                <div className={styles.tableData}>&nbsp;</div>

                <div className={styles.tableData}>&nbsp;</div>

                <div className={styles.tableData}>
                  <IconCheck className={styles.icon} />
                </div>

                <div className={styles.tableData}>
                  <IconCheck className={styles.icon} />
                </div>
              </div>

              <div className={styles.tableRow}>
                <div className={styles.tableSeparator}>
                  <IconAssessments className={styles.icon} />

                  {t('invite-users.form.permissions.table.headers.assessments')}
                </div>
              </div>

              <div className={styles.tableRow}>
                <div className={styles.tableData}>
                  {t('invite-users.form.permissions.table.take-assessments')}
                </div>

                <div className={styles.tableData}>
                  <IconCheck className={styles.icon} />
                </div>

                <div className={styles.tableData}>
                  <IconCheck className={styles.icon} />
                </div>

                <div className={styles.tableData}>
                  <IconCheck className={styles.icon} />
                </div>

                <div className={styles.tableData}>
                  <IconCheck className={styles.icon} />
                </div>
              </div>

              <div className={styles.tableRow}>
                <div className={styles.tableData}>
                  {t(
                    'invite-users.invite-users.form.permissions.table.view-your-own-results'
                  )}
                </div>

                <div className={styles.tableData}>
                  <IconCheck className={styles.icon} />
                </div>

                <div className={styles.tableData}>
                  <IconCheck className={styles.icon} />
                </div>

                <div className={styles.tableData}>
                  <IconCheck className={styles.icon} />
                </div>

                <div className={styles.tableData}>
                  <IconCheck className={styles.icon} />
                </div>
              </div>

              <div className={styles.tableRow}>
                <div className={styles.tableData}>
                  {t(
                    'invite-users.form.permissions.table.view-your-teams-results'
                  )}
                </div>

                <div className={styles.tableData}>&nbsp;</div>

                <div className={styles.tableData}>
                  <IconCheck className={styles.icon} />
                </div>

                <div className={styles.tableData}>
                  <IconCheck className={styles.icon} />
                </div>

                <div className={styles.tableData}>
                  <IconCheck className={styles.icon} />
                </div>
              </div>

              <div className={styles.tableRow}>
                <div className={styles.tableData}>
                  {t('invite-users.form.permissions.table.view-all-results')}
                </div>

                <div className={styles.tableData}>&nbsp;</div>

                <div className={styles.tableData}>&nbsp;</div>

                <div className={styles.tableData}>
                  <IconCheck className={styles.icon} />
                </div>

                <div className={styles.tableData}>
                  <IconCheck className={styles.icon} />
                </div>
              </div>

              <div className={styles.tableRow}>
                <div className={styles.tableData}>
                  {t('invite-users.form.permissions.table.manage-assessments')}
                </div>

                <div className={styles.tableData}>&nbsp;</div>

                <div className={styles.tableData}>&nbsp;</div>

                <div className={styles.tableData}>
                  <IconCheck className={styles.icon} />
                </div>

                <div className={styles.tableData}>
                  <IconCheck className={styles.icon} />
                </div>
              </div>

              <div className={styles.tableRow}>
                <div className={styles.tableSeparator}>
                  <IconAccount className={styles.icon} />

                  {t('invite-users.form.permissions.table.headers.account')}
                </div>
              </div>

              <div className={styles.tableRow}>
                <div className={styles.tableData}>
                  {t(
                    'invite-users.form.permissions.table.change-subscription-plan'
                  )}
                </div>

                <div className={styles.tableData}>&nbsp;</div>

                <div className={styles.tableData}>&nbsp;</div>

                <div className={styles.tableData}>&nbsp;</div>

                <div className={styles.tableData}>
                  <IconCheck className={styles.icon} />
                </div>
              </div>

              <div className={styles.tableRow}>
                <div className={styles.tableData}>
                  {t('invite-users.form.permissions.table.delete-account')}
                </div>

                <div className={styles.tableData}>&nbsp;</div>

                <div className={styles.tableData}>&nbsp;</div>

                <div className={styles.tableData}>&nbsp;</div>

                <div className={styles.tableData}>
                  <IconCheck className={styles.icon} />
                </div>
              </div>
            </div>
          </div>
        </Dialog.Content>
      </Dialog>
    </>
  );
};

export default Permissions;
