import {useEffect} from 'react';

export type Handler = (event: TouchEvent | MouseEvent) => void;

function useOnClickOutside<Container extends Element>(
  container: Container | null,
  handler: Handler
) {
  useEffect(() => {
    const listener: Handler = (event) => {
      if (!container || !event.target) return;
      if (!(event.target instanceof Node)) return;
      if (container.contains(event.target)) return;

      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [container, handler]);
}

export default useOnClickOutside;
