import {CompanyEmployee} from '../../../../../../../../interfaces/company-employee';
import {FunctionComponent} from 'react';
import {TeamUpsert} from '../../../../../../../../interfaces/team';
import {useTranslation} from 'react-i18next';
import Dialog from '../../../../../../../@components/kit/dialog/dialog';
import UpsertTeamForm from '../upsert-team-form';

interface ElementProps {
  companyEmployees: CompanyEmployee[];
  isDialogVisible: boolean;
  onClose: () => void;
  onSubmit: (formValues: TeamUpsert) => void;
}

const CreateTeamDialog: FunctionComponent<ElementProps> = ({
  companyEmployees,
  isDialogVisible,
  onClose,
  onSubmit,
}) => {
  const {t} = useTranslation('people', {
    keyPrefix: 'teams.components.team-list.components.create-team-form',
  });

  return (
    <Dialog isOpen={isDialogVisible} onClose={onClose}>
      <Dialog.Header title={t('header')} />

      <Dialog.Content>
        <UpsertTeamForm
          companyEmployees={companyEmployees}
          onSubmit={onSubmit}
        />
      </Dialog.Content>
    </Dialog>
  );
};

export default CreateTeamDialog;
