import {Field, FieldProps, Form, Formik} from 'formik';
import {FunctionComponent, useCallback} from 'react';
import {Option} from '../../../../../../../../../../interfaces/dropdown-options';
import {ReactComponent as IconCalendar} from '../../../../../../../../../@components/kit/icons/calendar.svg';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import AutoresizeTextarea from '../../../../../../../../../@components/kit/form/autoresize-textarea';
import Button from '../../../../../../../../../@components/kit/form/button';
import DatePicker from 'react-datepicker';
import FormControl from '../../../../../../../../../@components/kit/form/form-control';
import FormFooter from '../../../../../../../../../@components/kit/form/form-footer';
import FormGroup from '../../../../../../../../../@components/kit/form/form-group';
import Label from '../../../../../../../../../@components/kit/form/label';
import SearchableSelect from '../../../../../../../../../@components/kit/form/hybrid-select/searchable-select';
import styles from './styles.module.scss';

interface ElementProps {
  jobPositionOptions: Option[];
  formValues?: AddCandidateFormProps;
  onClose: () => void;
  onSubmit: (values: AddCandidateFormProps) => void;
}

export interface AddCandidateFormProps {
  emails: string;
  dueDate: Date;
  jobPosition: string;
}

const ADDITIONAL_NUMBER_OF_DAYS_FOR_DUE_DATE = 10;
const TODAY_DATE = new Date();
const DEFAULT_DUE_DATE = new Date(
  TODAY_DATE.setDate(
    TODAY_DATE.getDate() + ADDITIONAL_NUMBER_OF_DAYS_FOR_DUE_DATE
  )
);

const AddCandidateForm: FunctionComponent<ElementProps> = ({
  jobPositionOptions,
  formValues,
  onClose,
  onSubmit,
}) => {
  const {t} = useTranslation('jobs');

  const validateEmails = useCallback((emails: string | undefined) => {
    if (!emails) return true;

    const invitedEmails = emails.split(',');
    const trimmedEmails = invitedEmails.map((email) => email.trim());

    const emailListSchema = Yup.array().of(Yup.string().email());

    return emailListSchema.isValidSync(trimmedEmails);
  }, []);

  const initialValues: AddCandidateFormProps = {
    emails: formValues?.emails ?? '',
    dueDate: formValues?.dueDate ?? DEFAULT_DUE_DATE,
    jobPosition: formValues?.jobPosition ?? '',
  };

  const validationSchema = Yup.object().shape({
    emails: Yup.string()
      .test('member', '', (value) => validateEmails(value))
      .required(t('form.error.required', {ns: 'common'})),
    dueDate: Yup.date()
      .min(new Date())
      .required(t('form.error.required', {ns: 'common'})),
    jobPosition: Yup.string().required(
      t('form.error.required', {ns: 'common'})
    ),
  });

  const onDueDateChanged = useCallback(
    (form: FieldProps['form'], field: FieldProps['field'], value: Date) => {
      form.setFieldValue(field.name, value);
    },
    []
  );

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({errors, isValid, touched, values}) => (
          <Form>
            <Field name="jobPosition">
              {(fieldProps: FieldProps) => (
                <FormGroup>
                  <FormControl
                    error={
                      Boolean(errors.jobPosition || touched.jobPosition)
                        ? errors.jobPosition
                        : undefined
                    }
                  >
                    <Label>
                      {t(
                        'candidates.components.candidate-list.components.add-candidate-dialog.components.form.job-position.label'
                      )}
                    </Label>

                    <SearchableSelect
                      {...fieldProps}
                      options={jobPositionOptions}
                      placeholder={t(
                        'candidates.components.candidate-list.components.add-candidate-dialog.components.form.job-position.placeholder'
                      )}
                    />
                  </FormControl>
                </FormGroup>
              )}
            </Field>

            <Field name="dueDate">
              {({form, field}: FieldProps<string>) => (
                <FormGroup>
                  <FormControl>
                    <Label>
                      {t(
                        'candidates.components.candidate-list.components.add-candidate-dialog.components.form.due-date.label'
                      )}
                    </Label>

                    <div className={styles.calendarContainer}>
                      <DatePicker
                        minDate={new Date()}
                        onChange={(value: Date) =>
                          onDueDateChanged(form, field, value)
                        }
                        required
                        selected={values.dueDate}
                      />

                      <IconCalendar className={styles.calendarIcon} />
                    </div>
                  </FormControl>
                </FormGroup>
              )}
            </Field>

            <Field name="emails">
              {({field}: FieldProps<string>) => (
                <FormGroup>
                  <FormControl
                    error={
                      Boolean(errors.emails || touched.emails)
                        ? errors.emails
                        : undefined
                    }
                  >
                    <Label>
                      {t(
                        'candidates.components.candidate-list.components.add-candidate-dialog.components.form.candidate-emails.label'
                      )}
                    </Label>

                    <AutoresizeTextarea
                      {...field}
                      placeholder={t(
                        'candidates.components.candidate-list.components.add-candidate-dialog.components.form.candidate-emails.placeholder'
                      )}
                    />
                  </FormControl>
                </FormGroup>
              )}
            </Field>

            <FormFooter>
              <Button variant="secondary" onClick={onClose}>
                {t(
                  'candidates.components.candidate-list.components.add-candidate-dialog.components.form.footer.cancel-button'
                )}
              </Button>

              <Button disabled={!isValid} type="submit">
                {t(
                  'candidates.components.candidate-list.components.add-candidate-dialog.components.form.footer.next-button'
                )}
              </Button>
            </FormFooter>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddCandidateForm;
