import {FunctionComponent} from 'react';
import {Link} from 'react-router-dom';
import {useCurrentAuthUserState} from '../../../../../@atoms/current-auth-user';
import {useEffect} from 'react';
import {useNavigate} from 'react-router';
import {useTranslation} from 'react-i18next';
import styles from './styles.module.scss';

const EMAIL_VERIIFIED_STORAGE_KEY = 'Instill-emailVerified';

const EmailVerified: FunctionComponent = () => {
  const {t} = useTranslation('authFeedback');
  const navigate = useNavigate();
  const [, setCurrentAuthUser] = useCurrentAuthUserState();

  useEffect(() => {
    // We clear the user in order to force re-fetching the user.
    localStorage.setItem(EMAIL_VERIIFIED_STORAGE_KEY, 'true');
    setCurrentAuthUser(null);

    const timer = setTimeout(() => {
      navigate('/');
    }, 5000);

    return () => clearTimeout(timer);
  }, [navigate, setCurrentAuthUser]);

  return (
    <>
      <h1 className={styles.title}>{t('components.email-verified.title')}</h1>
      <p className={styles.text}>{t('components.email-verified.text')}</p>

      <Link className={styles.link} to="/">
        {t('components.email-verified.button')}
      </Link>
    </>
  );
};

export default EmailVerified;
