import {ORDER_BY_PARAMETERS} from '../../../constants/filtering/order-by';
import {sanitizeCandidatesFromRaw} from '../../../ui/@sanitizers/candidate';
import {sanitizeMetaFromRaw} from '../../../ui/@sanitizers/meta';
import InstillAPI from '../axios-instance';
import isResponseSuccessful from '../../../utils/is-response-successful';

export interface FetchCandidatePayload {
  companyUuid: string;
  jobPositionFilters?: string;
  limit?: number;
  offset?: number;
  orderBy?: ORDER_BY_PARAMETERS;
  query?: string;
  sortBy?: string;
}

export async function fetchCandidates(parameters: FetchCandidatePayload) {
  const {
    companyUuid,
    jobPositionFilters,
    limit,
    offset,
    orderBy,
    query,
    sortBy,
  } = parameters;

  let url = `/companies/${companyUuid}/candidates`;
  let queryParams: string[] = [];

  if (offset) {
    queryParams.push(`skip=${offset}`);
  }

  if (limit) {
    queryParams.push(`limit=${limit}`);
  }

  if (orderBy) {
    queryParams.push(`order=${orderBy}`);
  }

  if (sortBy) {
    queryParams.push(`sort_by=${sortBy}`);
  }

  if (query) {
    queryParams.push(`q=${query}`);
  }

  if (jobPositionFilters) {
    queryParams.push(`job_position_filters=${jobPositionFilters}`);
  }

  if (queryParams) {
    url = `${url}?${queryParams.join('&')}`;
  }

  try {
    const response = await InstillAPI.get(url);

    if (!response.data || !isResponseSuccessful(response)) {
      throw Error('No data received');
    }

    return {
      data: sanitizeCandidatesFromRaw(response.data.data),
      meta: sanitizeMetaFromRaw(response.data.meta),
    };
  } catch (error) {
    console.error(error);
  }
}
