import {sanitizeCompanyCoreValuesFromRaw} from '../../../ui/@sanitizers/company-core-values';
import {CoreValueFormToRaw} from '../../../interfaces/company-core-value';
import InstillAPI from '../axios-instance';

export interface CreateCompanyCoreValuesPayload {
  companyCoreValuePayload: CoreValueFormToRaw[];
  companyUuid: string;
}

export async function createCompanyCoreValues(
  companyCoreValuePayload: CoreValueFormToRaw[],
  companyUuid: string
) {
  const response = await InstillAPI.post(
    `/companies/${companyUuid}/values`,
    companyCoreValuePayload
  );

  return sanitizeCompanyCoreValuesFromRaw(response.data);
}
