import {FunctionComponent, PropsWithChildren} from 'react';
import Content from './content';
import Data from './data';
import Head from './head';
import Header from './header';
import Row from './row';
import styles from './styles.module.scss';

interface TableComponent
  extends FunctionComponent<PropsWithChildren<{className?: string}>> {
  Content: typeof Content;
  Data: typeof Data;
  Head: typeof Head;
  Header: typeof Header;
  Row: typeof Row;
}

const Table: TableComponent = ({children, ...props}) => {
  return (
    <table className={`${styles.table} ${props.className ?? ''}`}>
      {children}
    </table>
  );
};

Table.Content = Content;
Table.Data = Data;
Table.Head = Head;
Table.Header = Header;
Table.Row = Row;

export default Table;
