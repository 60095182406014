import {Candidate} from '../../../../../../../../../../interfaces/candidate';
import {CandidateAlignmentResult} from '../../../../../../../../../../interfaces/candidate-alignment-result';
import {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import Dialog from '../../../../../../../../../@components/kit/dialog';
import RadarChart from '../../../../../../../../../@components/radar-chart';
import styles from './styles.module.scss';
import UserScoreScale from '../../../../../../../../../@components/user-score-scale';

interface ElementProps {
  candidate: Candidate;
  candidateAlignmentResult: CandidateAlignmentResult;
  isVisible: boolean;
  onClose: () => void;
}

const CandidateResultDialog: FunctionComponent<ElementProps> = ({
  candidate,
  candidateAlignmentResult,
  isVisible,
  onClose,
}) => {
  const {t} = useTranslation('jobs', {
    keyPrefix:
      'candidates.components.candidate-list.components.list-view.components',
  });

  return (
    <Dialog isOpen={isVisible} onClose={onClose} variant="medium">
      <Dialog.Header>
        <div className={styles.title}>{candidate.name}</div>

        <div className={styles.subtitle}>
          {t('candidate-result-dialog.title')}
        </div>
      </Dialog.Header>

      <Dialog.Content>
        <ul className={styles.list}>
          <li className={styles.item}>
            <div className={styles.itemKey}>
              {t('candidate-result-dialog.list-keys.fit-score')}
            </div>

            <div className={styles.itemValue}>
              <UserScoreScale score={candidateAlignmentResult.alignmentScore} />
            </div>
          </li>

          <li className={styles.item}>
            <div className={styles.itemKey}>
              {t('candidate-result-dialog.list-keys.fit-narrative')}
            </div>

            <div className={styles.itemValue}>
              {candidateAlignmentResult.fitDescription}
            </div>
          </li>

          <li className={styles.item}>
            <div className={styles.itemKey}>
              {t('candidate-result-dialog.list-keys.hexaco-plot')}
            </div>

            <div className={styles.itemValue}>
              <div className={styles.hexacoPlot}>
                <RadarChart
                  hexacoScore={candidateAlignmentResult.hexacoScore}
                />
              </div>
            </div>
          </li>

          <li className={styles.item}>
            <div className={styles.itemKey}>
              {t('candidate-result-dialog.list-keys.positive-impacts')}
            </div>

            <div className={styles.itemValue}>
              <ul className={styles.itemList}>
                {candidateAlignmentResult.positiveNotes.map((note, index) => (
                  <li key={index} className={styles.listItem}>
                    {note}
                  </li>
                ))}
              </ul>
            </div>
          </li>

          <li className={styles.item}>
            <div className={styles.itemKey}>
              {t('candidate-result-dialog.list-keys.potential-challenges')}
            </div>

            <div className={styles.itemValue}>
              <ul className={styles.itemList}>
                {candidateAlignmentResult.negativeNotes.map((note, index) => (
                  <li key={index} className={styles.listItem}>
                    {note}
                  </li>
                ))}
              </ul>
            </div>
          </li>
        </ul>
      </Dialog.Content>
    </Dialog>
  );
};

export default CandidateResultDialog;
