import {UserActionStep, UserActionStepRaw} from '../../interfaces/action-step';

export const sanitizeUserActionStepsFromRaw = (
  data: UserActionStepRaw[]
): UserActionStep[] => {
  return data.map((actionStep) => sanitizeUserActionStepFromRaw(actionStep));
};

export const sanitizeUserActionStepFromRaw = (
  data: UserActionStepRaw
): UserActionStep => {
  return {
    createdAt: data.created_at,
    description: data.description,
    dimension: data.dimension,
    id: data.id,
    status: data.status,
    subtitle: data.subtitle,
    title: data.title,
    target: data.target,
    uuid: data.uuid,
  };
};
