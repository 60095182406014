import {Field, FieldProps, Form, Formik} from 'formik';
import {FunctionComponent, useCallback, useMemo} from 'react';
import {useCompanySelector} from '../@contexts/company-selector-context';
import {useCurrentAuthUserState} from '../@atoms/current-auth-user';
import {useNavigate} from 'react-router';
import {useTranslation} from 'react-i18next';
import Button from '../@components/kit/form/button';
import SearchableSelect from '../@components/kit/form/hybrid-select/searchable-select';
import styles from './styles.module.scss';

const SelectCompany: FunctionComponent = () => {
  const {t} = useTranslation('selectCompany');

  const [currentAuthUser] = useCurrentAuthUserState();
  const {onSelectCompany} = useCompanySelector();
  const navigate = useNavigate();

  const companyOptions = useMemo(() => {
    const companies = currentAuthUser?.companies.map((company) => ({
      label: company.name,
      value: company.uuid,
    }));

    if (!companies) return;

    return companies
      .filter((value) => value.label)
      .sort((firstItem, secondItem) => {
        return firstItem.label.localeCompare(secondItem.label);
      });
  }, [currentAuthUser]);

  const handleSubmit = useCallback(
    (values: {companyUuid: string}) => {
      const selectedCompany = currentAuthUser?.companies.find(
        (company) => company.uuid === values.companyUuid
      );
      if (!selectedCompany) return;

      onSelectCompany(selectedCompany);

      return navigate('/application', {replace: true});
    },
    [currentAuthUser, navigate, onSelectCompany]
  );

  const INITIAL_VALUES = {
    companyUuid: '',
  };

  if (!currentAuthUser || !companyOptions) {
    return null;
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.title}>{t('title')}</div>

        <Formik initialValues={INITIAL_VALUES} onSubmit={handleSubmit}>
          <Form className={styles.form}>
            <Field name="companyUuid">
              {(fieldProps: FieldProps) => (
                <SearchableSelect
                  {...fieldProps}
                  options={companyOptions}
                  placeholder={t('placeholder')}
                />
              )}
            </Field>

            <Button type="submit">{t('button-text')}</Button>
          </Form>
        </Formik>
      </div>
    </>
  );
};

export default SelectCompany;
