import {
  BasicProfileSaveCotinueEvent,
  PersonalizeYourProfileSaveContinueEvent,
} from '../../../../interfaces/analytics/event-properties';
import {buildPageTitle} from '../../../../services/build-page-title';
import {EventName} from '../../../../constants/analytics/event-name';
import {EventType} from '../../../../constants/analytics/event-type';
import {FunctionComponent, useCallback, useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {PersonalizeUserProfile} from '../../../../interfaces/user-profile';
import {ReactComponent as IconUser} from '../../../@components/kit/icons/user.svg';
import {
  sanitizePersonalizeUserProfileToRaw,
  sanitizeUserProfileUpdateToRaw,
} from '../../../@sanitizers/user-profile';
import {useCurrentFullUserProfileState} from '../../../@atoms/current-full-user-profile';
import {useNavigate} from 'react-router';
import {USER_CHECKLIST_STEP_KEYS} from '../../@components/setup-checklist/setup-checklist';
import {useSafeCurrentCompany} from '../../../@atoms/current-company';
import {useTranslation} from 'react-i18next';
import {useUpdateMyUserProfile} from '../../../@hooks/mutations/use-update-my-user-profile';
import {useUserProfileForCompany} from '../../../@hooks/queries';
import BackButton from '../../@components/back-button';
import BasicProfileInformation from './basic-profile-information';
import PersonalizeYourProfile from './personalize-your-profile';
import ProgressBar from '../../../@components/progress-bar';
import styles from './styles.module.scss';
import useAnalytics from '../../../@hooks/use-analytics';
import userSetupStyles from '../styles.module.scss';

const BASIC_PROFILE_INFORMATION = 'basic-profile-information';
const PERSONALIZE_YOUR_PROFILE = 'personalize-your-profile';

type ProfileCreationSteps =
  | typeof BASIC_PROFILE_INFORMATION
  | typeof PERSONALIZE_YOUR_PROFILE;

const STEP_NUMBERS = {
  [BASIC_PROFILE_INFORMATION]: 1,
  [PERSONALIZE_YOUR_PROFILE]: 2,
};

export interface BasicProfileInformationForm {
  jobTitle: string;
  name: string;
  personalEmail?: string;
  phoneNumber?: string;
  picture: string;
  preferredName?: string;
  timeZone: string;
  workEmail: string;
}

interface ElementProps {
  stepIdentifier: USER_CHECKLIST_STEP_KEYS;
  updateChecklist: (stepIdentifier: USER_CHECKLIST_STEP_KEYS) => void;
}

const NEXT_STEP_ROUTE = '/application/user-setup/map-your-organization';

const CreateYourProfile: FunctionComponent<ElementProps> = ({
  updateChecklist,
  stepIdentifier,
}) => {
  const {t} = useTranslation(['userSetup', 'applicationPageTitle']);

  const navigate = useNavigate();
  const {trackEvent} = useAnalytics();

  const [currentStep, setCurrentStep] = useState<ProfileCreationSteps>(
    BASIC_PROFILE_INFORMATION
  );
  const currentCompany = useSafeCurrentCompany();
  const [, setCurrentFullUserProfile] = useCurrentFullUserProfileState();

  const onCompleteChecklistStep = useCallback(() => {
    trackEvent({
      eventName:
        EventName.USER_SETUP.CREATE_YOUR_PROFILE.PERZONALIZE_YOUR_PROFILE
          .SKIP_FOR_NOW,
      eventType: EventType.BUTTON_CLICKED,
    });

    updateChecklist(stepIdentifier);
    navigate(NEXT_STEP_ROUTE);
  }, [trackEvent, stepIdentifier, updateChecklist, navigate]);

  const userProfile = useUserProfileForCompany({
    variables: {
      companyUuid: currentCompany.uuid,
    },
    onSuccess: (data) => {
      if (!data) return;
      setCurrentFullUserProfile(data);
    },
  });

  const UpdateMyUserProfilePayload = useUpdateMyUserProfile();

  const goToStep = useCallback(
    (step: ProfileCreationSteps) => {
      setCurrentStep(step);
    },
    [setCurrentStep]
  );

  const trackBasicProfileValues = useCallback(
    (values: BasicProfileInformationForm) => {
      const eventProperties = {
        jobTitle: values.jobTitle,
        name: values.name,
        profilePictureUrl: values.picture,
        timeZone: values.timeZone,
        workEmail: values.workEmail,
      } as BasicProfileSaveCotinueEvent;

      if (values.personalEmail) {
        eventProperties['personalEmail'] = values.personalEmail;
      }

      if (values.phoneNumber) {
        eventProperties['phoneNumber'] = values.phoneNumber;
      }

      if (values.preferredName) {
        eventProperties['preferredName'] = values.preferredName;
      }

      trackEvent({
        eventName:
          EventName.USER_SETUP.CREATE_YOUR_PROFILE.BASIC_PROFILE.SAVE_CONTINUE,
        eventType: EventType.BUTTON_CLICKED,
        eventProperties,
      });
    },
    [trackEvent]
  );

  const trackPersonalizeYourProfileValues = useCallback(
    (values: PersonalizeUserProfile) => {
      const eventProperties = {} as PersonalizeYourProfileSaveContinueEvent;

      if (values.funFact) {
        eventProperties['funFact'] = values.funFact;
      }

      if (values.linkedinUrl) {
        eventProperties['linkedinUrl'] = values.linkedinUrl;
      }

      if (values.location) {
        eventProperties['location'] = values.location;
      }

      if (values.pronouns) {
        eventProperties['pronouns'] = values.pronouns;
      }

      if (values.shortBio) {
        eventProperties['shortBio'] = values.shortBio;
      }

      trackEvent({
        eventName:
          EventName.USER_SETUP.CREATE_YOUR_PROFILE.PERZONALIZE_YOUR_PROFILE
            .SAVE_CONTINUE,
        eventType: EventType.BUTTON_CLICKED,
        eventProperties,
      });
    },
    [trackEvent]
  );

  const onBasicProfileInformationSubmit = useCallback(
    async (values: BasicProfileInformationForm) => {
      const payload = sanitizeUserProfileUpdateToRaw(values);
      const userProfile = await UpdateMyUserProfilePayload.mutateAsync({
        payload,
        companyUuid: currentCompany.uuid,
      });

      if (!userProfile) return;

      trackBasicProfileValues(values);

      setCurrentFullUserProfile(userProfile);
      goToStep(PERSONALIZE_YOUR_PROFILE);
    },
    [
      currentCompany,
      goToStep,
      setCurrentFullUserProfile,
      trackBasicProfileValues,
      UpdateMyUserProfilePayload,
    ]
  );

  const onPersonalizeYourProfileSubmit = useCallback(
    async (values: PersonalizeUserProfile) => {
      const payload = sanitizePersonalizeUserProfileToRaw(values);

      const updatedProfile = await UpdateMyUserProfilePayload.mutateAsync({
        payload,
        companyUuid: currentCompany.uuid,
      });

      if (!updatedProfile) return;

      trackPersonalizeYourProfileValues(values);

      setCurrentFullUserProfile(updatedProfile);
      onCompleteChecklistStep();
    },
    [
      currentCompany,
      onCompleteChecklistStep,
      setCurrentFullUserProfile,
      trackPersonalizeYourProfileValues,
      UpdateMyUserProfilePayload,
    ]
  );

  return (
    <>
      <Helmet>
        <title>
          {buildPageTitle(
            t('page-title.user-setup.create-your-profile', {
              ns: 'applicationPageTitle',
            })
          )}
        </title>
      </Helmet>

      {currentStep === PERSONALIZE_YOUR_PROFILE && (
        <BackButton onClick={() => goToStep(BASIC_PROFILE_INFORMATION)} />
      )}

      <div className={styles.headerWrapper}>
        <div className={styles.header}>
          <h1 className={userSetupStyles.headerTitle}>
            {t('create-your-profile.title')}
          </h1>

          <IconUser className={userSetupStyles.headerIcon} />
        </div>

        <ProgressBar
          currentProgress={STEP_NUMBERS[currentStep]}
          total={2}
          variant="small"
        />
      </div>

      {!userProfile.isLoading && (
        <>
          {currentStep === BASIC_PROFILE_INFORMATION && (
            <BasicProfileInformation
              onSubmit={onBasicProfileInformationSubmit}
            />
          )}

          {currentStep === PERSONALIZE_YOUR_PROFILE && (
            <PersonalizeYourProfile
              onSubmit={onPersonalizeYourProfileSubmit}
              onSkip={onCompleteChecklistStep}
            />
          )}
        </>
      )}
    </>
  );
};

export default CreateYourProfile;
