import {
  CandidateAlignmentResult,
  CandidateAlignmentResultRaw,
} from '../../interfaces/candidate-alignment-result';
import {sanitizeCoreValuesFromRaw} from './core-values';
import {sanitizeHexacoScoreFromRaw} from './hexaco-profile';

export const sanitizeCandidateAlignmentResultFromRaw = (
  data: CandidateAlignmentResultRaw
): CandidateAlignmentResult => {
  return {
    alignmentScore: data.alignment_score,
    fitDescription: data.fit_description,
    personalValues: sanitizeCoreValuesFromRaw(data.personal_values),
    hexacoScore: sanitizeHexacoScoreFromRaw(data.hexaco_scores),
    positiveNotes: data.positive_notes,
    negativeNotes: data.negative_notes,
  };
};
