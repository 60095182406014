import {
  CULTURE_VITAL_SIGN_KEYS,
  CULTURE_VITAL_SIGN_SLUGS,
} from '../../../../../../constants/culture-vital-signs';
import {FunctionComponent, useMemo} from 'react';
import {GenericModuleResult} from '../../../../../../interfaces/user-assessment-module-result';
import {SCORES} from '../../../../../../constants/module-scores';
import {useTranslation} from 'react-i18next';
import styles from './styles.module.scss';
import Tag from '../../../../../@components/tag';
import Takeaways from './@components/takeaways';
import UserScoreScale from '../../../../../@components/user-score-scale';

interface ElementProps {
  moduleName: string;
  moduleSlug: string;
  moduleResult: GenericModuleResult;
}

const GenericSummary: FunctionComponent<ElementProps> = ({
  moduleName,
  moduleSlug,
  moduleResult,
}) => {
  const {t} = useTranslation(['assessmentSummary', 'moduleResultFeedback']);

  const scoreValue = useMemo(() => {
    if (moduleResult.userScore < 20) {
      return SCORES.VERY_LOW;
    } else if (moduleResult.userScore < 40) {
      return SCORES.LOW;
    } else if (moduleResult.userScore < 60) {
      return SCORES.MEDIUM;
    } else if (moduleResult.userScore < 80) {
      return SCORES.HIGH;
    } else {
      return SCORES.VERY_HIGH;
    }
  }, [moduleResult.userScore]);

  const TAG_VARIANT = {
    [SCORES.VERY_LOW]: 'light-red',
    [SCORES.LOW]: 'light-red',
    [SCORES.MEDIUM]: 'light-yellow',
    [SCORES.HIGH]: 'light-green',
    [SCORES.VERY_HIGH]: 'light-green',
  };

  const SCORE_TAG_TRANSLATION_KEY = {
    [SCORES.VERY_LOW]:
      'components.module-summary.components.generic-summary.tags.very-low',
    [SCORES.LOW]:
      'components.module-summary.components.generic-summary.tags.low',
    [SCORES.MEDIUM]:
      'components.module-summary.components.generic-summary.tags.medium',
    [SCORES.HIGH]:
      'components.module-summary.components.generic-summary.tags.high',
    [SCORES.VERY_HIGH]:
      'components.module-summary.components.generic-summary.tags.very-high',
  };

  const SUMMARY_TRANSLATION_KEY = `modules.${
    CULTURE_VITAL_SIGN_KEYS[moduleSlug as CULTURE_VITAL_SIGN_SLUGS]
  }`;

  const SUMMARY_TRANSLATION_PREFIX = {
    [SCORES.VERY_LOW]: `${SUMMARY_TRANSLATION_KEY}.very-low`,
    [SCORES.LOW]: `${SUMMARY_TRANSLATION_KEY}.low`,
    [SCORES.MEDIUM]: `${SUMMARY_TRANSLATION_KEY}.medium`,
    [SCORES.HIGH]: `${SUMMARY_TRANSLATION_KEY}.high`,
    [SCORES.VERY_HIGH]: `${SUMMARY_TRANSLATION_KEY}.very-high`,
  };

  const summaryTranslationPrefix = SUMMARY_TRANSLATION_PREFIX[scoreValue];

  return (
    <>
      <div className={styles.titleRow}>
        <h1 className={styles.heading}>{moduleName}</h1>
        <Tag variant={TAG_VARIANT[scoreValue] as any}>
          {t(SCORE_TAG_TRANSLATION_KEY[scoreValue])}
        </Tag>
      </div>

      <p>
        {t(`${summaryTranslationPrefix}.primary-feedback`, {
          ns: 'moduleResultFeedback',
        })}
      </p>

      <UserScoreScale score={moduleResult.userScore} />

      <div className={styles.secondaryTitle}>
        {t(
          'components.module-summary.components.generic-summary.subheadings.reflection'
        )}
      </div>

      <div className={styles.reflectionDescription}>
        {t(`${summaryTranslationPrefix}.reflection`, {
          ns: 'moduleResultFeedback',
        })}
      </div>

      <div className={styles.secondaryTitle}>
        {t(
          'components.module-summary.components.generic-summary.subheadings.takeaways'
        )}
      </div>

      <Takeaways
        firstTakeaway={t(`${summaryTranslationPrefix}.takeaways.one`, {
          ns: 'moduleResultFeedback',
        })}
        secondTakeaway={t(`${summaryTranslationPrefix}.takeaways.two`, {
          ns: 'moduleResultFeedback',
        })}
        thirdTakeaway={t(`${summaryTranslationPrefix}.takeaways.three`, {
          ns: 'moduleResultFeedback',
        })}
      />
    </>
  );
};

export default GenericSummary;
