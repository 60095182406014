import {AuthUser} from '../../../interfaces/auth-user';
import {COMPANY_ONBOARDING_COMPLETE_STORAGE_KEY} from '../../../constants/local-storage-keys';
import {Company} from '../../../interfaces/company';
import {CompanyChecklist} from '../../../interfaces/checklist';
import {FunctionComponent, useCallback, useMemo} from 'react';
import {Navigate, Route, Routes, useLocation} from 'react-router';
import {useChecklistData} from '../../@hooks/queries';
import {useCurrentAuthUserState} from '../../@atoms/current-auth-user';
import {useCurrentCompanyState} from '../../@atoms/current-company';
import {useEffectOnce} from '../../@hooks/use-effect-once';
import {useMountedState} from 'react-use';
import {useNavigate} from 'react-router-dom';
import {useSetup} from '../@contexts/setup-context';
import {useTranslation} from 'react-i18next';
import {useUpdateChecklist} from '../../@hooks/mutations';
import CodifyYourValues from './codify-your-values';
import Header from '../@components/header';
import InstallSlackApp from './install-slack-app/install-slack-app';
import InviteYourTeam from './invite-your-team/invite-your-team';
import MainContent from '../@components/main-content';
import RoundedCard from '../../@components/rounded-card';
import SetupChecklist, {
  ORGANIZATION_CHECKLIST_STEP_KEYS,
  ChecklistItem,
} from '../@components/setup-checklist/setup-checklist';
import SignOffOnValues from './sign-off-on-values';
import styles from './styles.module.scss';

// We define a mapping based on the steps to make sure we can easily redirect
// the user if he tries to access a route without having completed the previous steps
const CHECKLIST_STEP_ROUTE_MAPPING = {
  [ORGANIZATION_CHECKLIST_STEP_KEYS.CODIFIED_VALUE]: 'codify-your-values',
  [ORGANIZATION_CHECKLIST_STEP_KEYS.INTEGRATED_SLACK_APP]: 'install-slack-app',
  [ORGANIZATION_CHECKLIST_STEP_KEYS.INVITED_TEAM]: 'invite-your-team',
  [ORGANIZATION_CHECKLIST_STEP_KEYS.SIGNED_OFF_ON_VALUES]: 'sign-off-on-values',
};

const DASHBOARD_ROUTE = '/application/dashboard';

function getLastCompletedStepIndex(items: ChecklistItem[]) {
  return items.map((step) => step.isActive).lastIndexOf(true);
}

const isCurrentUserAuthorized = (
  company: Company | null,
  authUser: AuthUser | null
) => {
  if (company?.creatorUuid !== authUser?.uuid) return true;
  if (company?.isOnboarded) return true;

  return false;
};

const OrganizationSetup: FunctionComponent = () => {
  const [currentAuthUser] = useCurrentAuthUserState();
  const [currentCompany, setCurrentCompany] = useCurrentCompanyState();
  const {currentStepIdentifier, setCurrentStepIdentifier} = useSetup();

  const {t} = useTranslation('application');
  const isMounted = useMountedState();
  const location = useLocation();
  const navigate = useNavigate();

  const checklistData = useChecklistData({
    variables: {
      companyUuid: currentCompany!.uuid,
    },
    onSuccess(response) {
      if (!response) return;
      const formatedResponse = formatChecklistResponse(response);

      redirectToStep(formatedResponse);
    },
  });

  const updateChecklist = useUpdateChecklist();

  const fetchAndSetCurrentStepIdentifier = useCallback(() => {
    const currentRouteMapping = Object.keys(CHECKLIST_STEP_ROUTE_MAPPING).find(
      (key) => {
        const route =
          CHECKLIST_STEP_ROUTE_MAPPING[
            key as keyof typeof CHECKLIST_STEP_ROUTE_MAPPING
          ];
        return location.pathname.includes(route);
      }
    );

    if (!currentRouteMapping) return;

    const checklistStepBasedOnRoute = Object.keys(
      ORGANIZATION_CHECKLIST_STEP_KEYS
    ).find((key) => {
      const route =
        ORGANIZATION_CHECKLIST_STEP_KEYS[
          key as keyof typeof ORGANIZATION_CHECKLIST_STEP_KEYS
        ];
      return route === currentRouteMapping;
    });

    if (!checklistStepBasedOnRoute) return;

    setCurrentStepIdentifier(
      ORGANIZATION_CHECKLIST_STEP_KEYS[
        checklistStepBasedOnRoute as keyof typeof ORGANIZATION_CHECKLIST_STEP_KEYS
      ]
    );
  }, [setCurrentStepIdentifier, location]);

  const formatChecklistResponse = useCallback(
    (data: CompanyChecklist) => {
      const checklistItems = [
        {
          isActive: data?.codifiedValue ?? false,
          key: ORGANIZATION_CHECKLIST_STEP_KEYS.CODIFIED_VALUE,
          label: t(
            'components.main-content.setup-checklist.organization-setup.steps.codify-values'
          ),
        },
        {
          isActive: data?.invitedTeam ?? false,
          key: ORGANIZATION_CHECKLIST_STEP_KEYS.INVITED_TEAM,
          label: t(
            'components.main-content.setup-checklist.organization-setup.steps.invite-team'
          ),
        },
        {
          isActive: data?.integratedSlackApp ?? false,
          key: ORGANIZATION_CHECKLIST_STEP_KEYS.INTEGRATED_SLACK_APP,
          label: t(
            'components.main-content.setup-checklist.organization-setup.steps.install-app'
          ),
        },
        {
          isActive: data?.signedOffOnValues ?? false,
          key: ORGANIZATION_CHECKLIST_STEP_KEYS.SIGNED_OFF_ON_VALUES,
          label: t(
            'components.main-content.setup-checklist.organization-setup.steps.sign-off-on-values'
          ),
        },
      ];

      const lastCompletedStepIndex = getLastCompletedStepIndex(checklistItems);

      return checklistItems.map((item, index) => {
        const isCompleted = lastCompletedStepIndex + 1 > index;

        const isActive =
          checklistItems.indexOf(item) === lastCompletedStepIndex + 1;

        return {
          ...item,
          isCompleted,
          isActive,
        };
      });
    },
    [t]
  );

  const redirectToStep = useCallback(
    (items: ChecklistItem[]) => {
      const currentStepIndex = items.findIndex(
        (item) => item.key === currentStepIdentifier
      );

      const areAllStepsCompleted = items.every((item) => item.isCompleted);
      const lastCompletedStepIndex = getLastCompletedStepIndex(items);

      if (areAllStepsCompleted) {
        navigate(DASHBOARD_ROUTE);
      }

      if (currentStepIndex !== lastCompletedStepIndex) {
        const key = items[lastCompletedStepIndex].key;

        const route =
          CHECKLIST_STEP_ROUTE_MAPPING[
            key as keyof typeof CHECKLIST_STEP_ROUTE_MAPPING
          ];

        if (currentStepIdentifier === key) return;

        navigate(route);
      }
    },
    [currentStepIdentifier, navigate]
  );

  const checklistItems = useMemo(() => {
    if (!checklistData.data) return;

    return formatChecklistResponse(checklistData.data);
  }, [checklistData, formatChecklistResponse]);

  const onUpdateChecklist = useCallback(
    async (currentStep: ORGANIZATION_CHECKLIST_STEP_KEYS) => {
      const response = await updateChecklist.mutateAsync({
        currentStep,
        companyUuid: currentCompany!.uuid,
      });

      if (!response) return;

      const formatedResponse = formatChecklistResponse(response);
      const areAllStepsCompleted = formatedResponse.every(
        (item) => item.isCompleted
      );

      if (areAllStepsCompleted) {
        if (!currentCompany) return;

        // setting isOnboarded to true will redirect user to the dashboard.
        localStorage.setItem(COMPANY_ONBOARDING_COMPLETE_STORAGE_KEY, 'true');
        setCurrentCompany({...currentCompany, isOnboarded: true});
      }

      checklistData.refetch();
    },
    [
      checklistData,
      formatChecklistResponse,
      updateChecklist,
      currentCompany,
      setCurrentCompany,
    ]
  );

  useEffectOnce(() => {
    if (!isMounted()) return;

    fetchAndSetCurrentStepIdentifier();
  });

  if (isCurrentUserAuthorized(currentCompany, currentAuthUser)) {
    return <Navigate replace to={DASHBOARD_ROUTE} />;
  }

  return (
    <>
      <Header
        surtitle={t('components.header.surtitle', {
          companyName: currentCompany!.name,
        })}
        title={t('components.header.title')}
      />

      <MainContent>
        <main className={styles.mainContainer}>
          <RoundedCard>
            <Routes>
              <Route
                path="/"
                element={
                  <Navigate
                    replace
                    to={
                      CHECKLIST_STEP_ROUTE_MAPPING[
                        ORGANIZATION_CHECKLIST_STEP_KEYS.CODIFIED_VALUE
                      ]
                    }
                  />
                }
              />

              <Route
                path={
                  CHECKLIST_STEP_ROUTE_MAPPING[
                    ORGANIZATION_CHECKLIST_STEP_KEYS.CODIFIED_VALUE
                  ]
                }
                element={
                  <CodifyYourValues
                    stepIdentifier={
                      ORGANIZATION_CHECKLIST_STEP_KEYS.CODIFIED_VALUE
                    }
                    updateChecklist={onUpdateChecklist}
                  />
                }
              />

              <Route
                path={
                  CHECKLIST_STEP_ROUTE_MAPPING[
                    ORGANIZATION_CHECKLIST_STEP_KEYS.INVITED_TEAM
                  ]
                }
                element={
                  <InviteYourTeam
                    stepIdentifier={
                      ORGANIZATION_CHECKLIST_STEP_KEYS.INVITED_TEAM
                    }
                    updateChecklist={onUpdateChecklist}
                  />
                }
              />
              <Route
                path={
                  CHECKLIST_STEP_ROUTE_MAPPING[
                    ORGANIZATION_CHECKLIST_STEP_KEYS.INTEGRATED_SLACK_APP
                  ]
                }
                element={
                  <InstallSlackApp
                    stepIdentifier={
                      ORGANIZATION_CHECKLIST_STEP_KEYS.INTEGRATED_SLACK_APP
                    }
                    updateChecklist={onUpdateChecklist}
                  />
                }
              />
              <Route
                path={
                  CHECKLIST_STEP_ROUTE_MAPPING[
                    ORGANIZATION_CHECKLIST_STEP_KEYS.SIGNED_OFF_ON_VALUES
                  ]
                }
                element={
                  <SignOffOnValues
                    stepIdentifier={
                      ORGANIZATION_CHECKLIST_STEP_KEYS.SIGNED_OFF_ON_VALUES
                    }
                    updateChecklist={onUpdateChecklist}
                  />
                }
              />

              <Route
                path="*"
                element={<Navigate replace to="/page-not-found" />}
              />
            </Routes>
          </RoundedCard>
        </main>

        <aside className={styles.sidebar}>
          <div className={styles.sidebarRow}>
            {checklistData.data && (
              <SetupChecklist checklistData={checklistItems} />
            )}
          </div>
        </aside>
      </MainContent>
    </>
  );
};

export default OrganizationSetup;
