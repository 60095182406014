import {buildPageTitle} from '../../../services/build-page-title';
import {EventName} from '../../../constants/analytics/event-name';
import {EventType} from '../../../constants/analytics/event-type';
import {FunctionComponent, useEffect} from 'react';
import {Helmet} from 'react-helmet-async';
import {useTranslation} from 'react-i18next';
import SidePanel from '../@components/side-panel';
import styles from './styles.module.scss';
import TwoPanePage from '../../@components/two-pane-page';
import useAnalytics from '../../@hooks/use-analytics';

const Feedback: FunctionComponent = () => {
  const {t} = useTranslation(['candidates', 'applicationPageTitle']);

  const {trackEvent} = useAnalytics();

  useEffect(() => {
    trackEvent({
      eventName: EventName.PAGE_VIEWS.CANDIDATES.FEEDBACK,
      eventType: EventType.PAGE_VIEW,
    });
  }, [trackEvent]);

  return (
    <>
      <Helmet>
        <title>
          {buildPageTitle(
            t('page-title.candidates.feedback', {ns: 'applicationPageTitle'})
          )}
        </title>
      </Helmet>

      <TwoPanePage
        mainPane={
          <div className={styles.mainPane}>
            <h1 className={styles.heading}>{t('feedback.heading')}</h1>

            <div className={styles.description}>
              {t('feedback.description')}
            </div>
          </div>
        }
        secondaryPane={<SidePanel />}
      />
    </>
  );
};

export default Feedback;
