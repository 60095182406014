import {Media, MediaRaw} from '../../interfaces/company-core-value';

export const sanitizeMediaValueFromRaw = (data: MediaRaw[]): Media[] => {
  return data.map((item: MediaRaw) => {
    return {
      type: item.type,
      url: item.signed_url ?? item.url,
    };
  });
};
