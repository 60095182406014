import {AuthUser, AuthUserRaw} from '../../interfaces/auth-user';
import {sanitizeCompanyFromRaw} from './company';
import {sanitizeUserProfileFromRaw} from './user-profile';

export const sanitizeAuthUserFromRaw = (data: AuthUserRaw): AuthUser => {
  return {
    companies: data.companies.map(sanitizeCompanyFromRaw),
    createdAt: data.created_at,
    email: data.email,
    id: data.id,
    isEmailVerified: data.is_email_verified,
    userProfiles: data.user_profiles.map(sanitizeUserProfileFromRaw),
    name: data.name,
    picture: data.picture,
    updatedAt: data.updated_at,
    uuid: data.uuid,
    isSuperAdmin: data.is_super_admin,
  };
};
