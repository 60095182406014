import {buildPageTitle} from '../../../../services/build-page-title';
import {EventName} from '../../../../constants/analytics/event-name';
import {EventType} from '../../../../constants/analytics/event-type';
import {FunctionComponent, useCallback, useEffect} from 'react';
import {Helmet} from 'react-helmet-async';
import {StringParam, useQueryParam} from 'use-query-params';
import {useSafeCurrentCompany} from '../../../@atoms/current-company';
import {useTranslation} from 'react-i18next';
import {useUserActionSteps} from '../../../@hooks/queries/use-user-action-steps';
import ActionStepsList from './@components/action-steps-list';
import mainContainerStyles from '../../../../styles/classes/main-container.module.scss';
import RoundedCard from '../../../@components/rounded-card';
import useAnalytics from '../../../@hooks/use-analytics';

const ActionSteps: FunctionComponent = () => {
  const {t} = useTranslation('applicationPageTitle');

  const {trackEvent} = useAnalytics();
  const currentCompany = useSafeCurrentCompany();

  const actionSteps = useUserActionSteps({
    variables: {
      companyUuid: currentCompany.uuid,
    },
  });

  const [actionStepUuidInQueryParams] = useQueryParam('uuid', StringParam);

  const onRefreshActionSteps = useCallback(() => {
    actionSteps.refetch();
  }, [actionSteps]);

  useEffect(() => {
    trackEvent({
      eventName: EventName.PAGE_VIEWS.APPLY.ACTION_STEPS,
      eventType: EventType.PAGE_VIEW,
    });
  }, [trackEvent]);

  return (
    <>
      <Helmet>
        <title>
          {buildPageTitle([
            t('page-title.apply.action-steps'),
            t('page-title.apply.index'),
          ])}
        </title>
      </Helmet>

      <main className={mainContainerStyles.container}>
        <RoundedCard>
          <ActionStepsList
            actionSteps={actionSteps.data?.data}
            actionStepUuidInQueryParams={actionStepUuidInQueryParams}
            onRefreshActionSteps={onRefreshActionSteps}
          />
        </RoundedCard>
      </main>
    </>
  );
};

export default ActionSteps;
