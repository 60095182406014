import {Expectation} from '../../../../../../../../interfaces/company-core-value';
import {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import getExpectationLabelFromNumber from '../../../../../../../../utils/get-expectation-label-from-number';
import styles from './styles.module.scss';
import Tag from '../../../../../../../@components/tag';

interface ElementProps {
  expectations: Expectation[];
}

const ExpectationList: FunctionComponent<ElementProps> = ({expectations}) => {
  const {t} = useTranslation(['values', 'common']);

  const scopedExpectations = expectations.reduce((memo, item) => {
    if (memo[item.level]) {
      return {
        ...memo,
        [item.level]: [...memo[item.level], item.normsAndBehaviour],
      };
    }

    return {
      ...memo,
      [item.level]: item.normsAndBehaviour,
    };
  }, {} as Record<string, string[]>);

  const orderedExpectations = Object.keys(scopedExpectations).sort().reverse();

  return (
    <ul className={styles.list}>
      {orderedExpectations.map((key) => (
        <li className={styles.item} key={key}>
          <div className={styles.column}>
            <Tag>{getExpectationLabelFromNumber(parseInt(key, 10), t)}</Tag>
          </div>

          <div className={styles.column}>
            <ul className={styles.sublist}>
              {scopedExpectations[key].map((item: string, index: number) => (
                <li className={styles.sublistItem} key={index}>
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default ExpectationList;
