import InstillAPI from '../axios-instance';
import isResponseSuccessful from '../../../utils/is-response-successful';
import {sanitizeNotificationSettingsFromRaw} from '../../../ui/@sanitizers/notification-settings';

export async function fetchNotificationSettings(companyUuid: string) {
  try {
    const response = await InstillAPI.get(
      `/companies/${companyUuid}/user-profiles/me/notification-settings`
    );

    if (!response.data || !isResponseSuccessful(response)) {
      throw Error('No data received');
    }

    return sanitizeNotificationSettingsFromRaw(response.data);
  } catch (error) {
    console.error(error);
  }
}
