import {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import styles from './styles.module.scss';

interface ElementProps {
  currentPage: number;
  itemsPerPage: number;
  onNextPageLinkClicked: () => void;
  onPreviousPageLinkClicked: () => void;
  totalItems: number;
}

const Pagination: FunctionComponent<ElementProps> = ({
  currentPage,
  onNextPageLinkClicked,
  onPreviousPageLinkClicked,
  itemsPerPage,
  totalItems,
}) => {
  const {t} = useTranslation('components');

  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const isCurrentPageFirst = currentPage === 1;
  const isCurrentPageLast = currentPage === totalPages;

  if (totalItems <= itemsPerPage) return null;

  return (
    <>
      <div className={styles.container}>
        <button
          className={styles.button}
          onClick={onPreviousPageLinkClicked}
          disabled={isCurrentPageFirst}
          aria-label={t('pagination.previous-button-accessibility-text')}
        >
          {t('pagination.previous')}
        </button>

        <span className={styles.pageNumbers}>
          {t('pagination.current-page-identifier', {currentPage, totalPages})}
        </span>

        <button
          className={styles.button}
          onClick={onNextPageLinkClicked}
          disabled={isCurrentPageLast}
          aria-label={t('pagination.next-button-accessibility-text')}
        >
          {t('pagination.next')}
        </button>
      </div>
    </>
  );
};

export default Pagination;
