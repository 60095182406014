// Vendor
import {formatInTimeZone} from 'date-fns-tz';
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';
import setSeconds from 'date-fns/setSeconds';

const DEFAULT_TIME_FORMAT = 'HH:mm:ss zzz';

interface Parameters {
  time?: string;
  timeZone: string | null;
  customFormat?: string;
}

function formatTimewithTimezone(parameters: Parameters) {
  const {time, timeZone, customFormat} = parameters;

  if (!time) return;
  if (!timeZone) return time;

  const timezone = timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [hours, minutes, seconds] = time.split(':');

  // We don't care about the date, what we want if only the time.
  // That is why we create a new Date and affect hours, minutes and seconds.
  let formattedTime = new Date();
  formattedTime = setHours(formattedTime, parseFloat(hours));
  formattedTime = setMinutes(formattedTime, parseFloat(minutes));
  formattedTime = setSeconds(formattedTime, parseFloat(seconds));

  const format = customFormat ?? DEFAULT_TIME_FORMAT;

  return formatInTimeZone(formattedTime, timezone, format);
}

export default formatTimewithTimezone;
