import {Question} from '../../interfaces/question';

export const sanitizeQuestionFromRaw = (data: Question[]): Question[] => {
  return data.map((question) => {
    const {description, options, rank, title, type, uuid} = question;

    return {
      description,
      options,
      rank,
      title,
      type,
      uuid,
    };
  });
};
