import {
  sanitizeCandidateFromRaw,
  sanitizeCandidateUpdateForRaw,
} from '../../../ui/@sanitizers/candidate';
import InstillAPI from '../axios-instance';

export interface UpdateCandidatePayload {
  candidateUuid: string;
  companyUuid: string;
  payload: {
    dueDate: Date;
    name: string;
    jobPositionUuid: string;
    isActive: boolean;
  };
}

export async function updateCandidate(parameters: UpdateCandidatePayload) {
  const {candidateUuid, companyUuid, payload} = parameters;
  const updatedPayload = sanitizeCandidateUpdateForRaw(payload);

  const response = await InstillAPI.put(
    `/companies/${companyUuid}/candidates/${candidateUuid}`,
    updatedPayload
  );

  return sanitizeCandidateFromRaw(response.data);
}
