import {EventType} from '../../../../../../constants/analytics/event-type';
import {Field, FieldArray, FieldArrayRenderProps, FieldProps} from 'formik';
import {FunctionComponent, useCallback, useState} from 'react';
import {MapYourOrganizationForm} from '../../../../../../interfaces/map-your-organization';
import {useTranslation} from 'react-i18next';
import AddRecruit from './@components/add-recruit';
import Button from '../../../../../@components/kit/form/button';
import styles from './styles.module.scss';
import useAnalytics from '../../../../../@hooks/use-analytics';

interface ElementProps {
  addJobTitleEventName: string;
  values: MapYourOrganizationForm;
}

export const RecruitsForm: FunctionComponent<ElementProps> = ({
  addJobTitleEventName,
  values,
}) => {
  const [activeRecruitIndex, setActiveRecruitIndex] = useState<number>(-1);

  const {t} = useTranslation('application');
  const {trackEvent} = useAnalytics();

  const onAddRecruit = useCallback(
    (push: FieldArrayRenderProps['push'], index: number) => {
      trackEvent({
        eventName: addJobTitleEventName,
        eventType: EventType.BUTTON_CLICKED,
      });

      setActiveRecruitIndex(index);
      push({
        jobTitle: '',
      });
    },
    [trackEvent, addJobTitleEventName]
  );

  return (
    <FieldArray name="recruits">
      {({push, remove}) => (
        <>
          {values.recruits?.map((recruit, index) => (
            <Field name={`recruits.${index}`} key={index}>
              {(FieldProps: FieldProps<string>) => (
                <AddRecruit
                  {...FieldProps}
                  value={recruit}
                  itemIndex={index}
                  activeIndex={activeRecruitIndex}
                  setActiveIndex={setActiveRecruitIndex}
                  deleteItem={remove}
                />
              )}
            </Field>
          ))}

          <div className={styles.pageGroup}>
            <Button
              type="button"
              variant="secondary"
              onClick={() => onAddRecruit(push, values.recruits!.length)}
            >
              {t(
                'components.hierarchy-edit.components.recruits-form.add-recruit-text'
              )}
            </Button>
          </div>
        </>
      )}
    </FieldArray>
  );
};

export default RecruitsForm;
