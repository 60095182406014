import {createMutation} from 'react-query-kit';
import {
  CreateSignaturePayload,
  createSignature,
} from '../../../repositories/instill/mutations/create-signature';
import {Signature} from '../../../interfaces/signature';

export const useCreateSignature = createMutation<
  Signature | undefined,
  CreateSignaturePayload
>({
  mutationFn: async (variables) => {
    return await createSignature(
      variables.signaturePayload,
      variables.companyUuid
    );
  },
});
