import {buildPageTitle} from '../../../services/build-page-title';
import {EventName} from '../../../constants/analytics/event-name';
import {EventType} from '../../../constants/analytics/event-type';
import {FunctionComponent, useEffect} from 'react';
import {Helmet} from 'react-helmet-async';
import {useCurrentCompanyEmployee} from '../../@hooks/queries';
import {useNavigate} from 'react-router';
import {useSafeCurrentCompany} from '../../@atoms/current-company';
import {useTranslation} from 'react-i18next';
import Header from '../@components/header';
import mainContainerStyles from '../../../styles/classes/main-container.module.scss';
import MainContent from '../@components/main-content';
import ProfileDetails, {SOURCES} from '../@components/profile-details';
import RoundedCard from '../../@components/rounded-card';
import useAnalytics from '../../@hooks/use-analytics';

const MyProfile: FunctionComponent = () => {
  const {t} = useTranslation(['applicationPageTitle', 'myProfile']);

  const currentCompany = useSafeCurrentCompany();
  const navigate = useNavigate();
  const {trackEvent} = useAnalytics();

  const profile = useCurrentCompanyEmployee({
    variables: {
      companyUuid: currentCompany.uuid,
    },
    onError() {
      navigate('/application');
    },
  });

  useEffect(() => {
    trackEvent({
      eventName: EventName.PAGE_VIEWS.MY_PROFILE,
      eventType: EventType.PAGE_VIEW,
    });
  }, [trackEvent]);

  return (
    <>
      <Helmet>
        <title>
          {buildPageTitle(
            t('page-title.my-profile', {ns: 'applicationPageTitle'})
          )}
        </title>
      </Helmet>

      <Header title={t('components.header.title', {ns: 'myProfile'})} />

      <MainContent>
        <main className={mainContainerStyles.container}>
          <RoundedCard>
            {profile.data && (
              <ProfileDetails
                source={SOURCES.MY_PROFILE}
                profile={profile.data}
              />
            )}
          </RoundedCard>
        </main>
      </MainContent>
    </>
  );
};

export default MyProfile;
