import axios from 'axios';

export interface Suggestion {
  label: string;
  value: string;
}

export const getAddressSugesstion = async (query: string): Promise<any> => {
  const TOKEN = window.env.REACT_APP_MAPBOX_TOKEN;

  const response = await axios.get(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?access_token=${TOKEN}&limit=5`
  );
  const data = await response.data;

  const results: Suggestion[] = data.features.map((feature: any) => {
    return {
      value: feature.place_name,
      label: feature.place_name,
    };
  });

  return results;
};
