import {FunctionComponent} from 'react';
import {ReactComponent as IconCheck} from '../../../../icons/check.svg';
import Input from '../../../../form/input';
import styles from './styles.module.scss';

interface RadioOptionProps {
  className?: string;
  label: string;
  name: string;
  value: string;
  isSelected: boolean;
  onChange: (value: string) => void;
}

const RadioOption: FunctionComponent<RadioOptionProps> = ({
  className,
  label,
  name,
  value,
  isSelected,
  onChange,
}) => {
  const uuid = crypto.randomUUID();

  const optionClassName = className
    ? `${className} ${styles.option}`
    : styles.option;

  return (
    <div className={optionClassName}>
      <label htmlFor={uuid} className={styles.label}>
        <div>
          <Input
            type="radio"
            id={uuid}
            className={styles.radio}
            value={value}
            name={name}
            defaultChecked={isSelected}
            onChange={() => onChange(value)}
          />

          <div className={styles.fakeRadio}>
            <IconCheck className={styles.icon} />
          </div>
        </div>

        <div className={styles.text}>{label}</div>
      </label>
    </div>
  );
};

export default RadioOption;
