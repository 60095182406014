import {FunctionComponent, PropsWithChildren} from 'react';
import DOMPurify from 'dompurify';

interface ElementProps {
  value: string;
  className?: string;
}

const RichTextContainer: FunctionComponent<PropsWithChildren<ElementProps>> = ({
  value,
  ...props
}) => {
  const purifiedValue = DOMPurify.sanitize(value);

  return (
    <div
      {...props}
      dangerouslySetInnerHTML={{
        __html: purifiedValue,
      }}
    />
  );
};

export default RichTextContainer;
