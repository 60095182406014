export interface CompanySettings {
  isHRISProviderUKG: boolean;
}

export interface CompanySettingsRaw {
  hris_provider: HRIS_PROVIDER | null;
}

export enum HRIS_PROVIDER {
  UKG = 'UKG',
}
