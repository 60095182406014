import {UserReport, UserReportRaw} from '../../interfaces/user-report';
import {sanitizerAssessmentEventFromRaw} from './assessment-event';

export const sanitizeUserReportFromRaw = (
  data: UserReportRaw[]
): UserReport[] => {
  return data.map((userAssessment) => {
    return sanitizeSingleUserReportFromRaw(userAssessment);
  });
};

export const sanitizeSingleUserReportFromRaw = (
  data: UserReportRaw
): UserReport => {
  return {
    uuid: data.uuid,
    assessmentEvent: sanitizerAssessmentEventFromRaw(data.assessment_event),
  };
};
