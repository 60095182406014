import {DEFAULT_TOASTIFY_CONFIGURATION} from '../../../../../../../../../../constants/toastify-configuration';
import {FunctionComponent, useCallback, useState} from 'react';
import {
  Meeting,
  MeetingFeedback as MeetingFeedbackInterface,
} from '../../../../../../../../../../interfaces/meeting';
import {toast, ToastOptions} from 'react-toastify';
import {useSafeCurrentCompany} from '../../../../../../../../../@atoms/current-company';
import {useTranslation} from 'react-i18next';
import {useUpdateMeetingFeedback} from '../../../../../../../../../@hooks/mutations/use-update-meeting-feedback';
import Button from '../../../../../../../../../@components/kit/form/button';
import RoundedCard from '../../../../../../../../../@components/rounded-card';
import styles from './styles.module.scss';

interface ElementProps {
  meeting: Meeting;
}

const MeetingFeedback: FunctionComponent<ElementProps> = ({meeting}) => {
  const {t} = useTranslation('sense', {
    keyPrefix:
      'meetings.show.index.components.meeting-details.components.meeting-feedback',
  });

  const currentCompany = useSafeCurrentCompany();
  const updateMeetingFeedback = useUpdateMeetingFeedback();

  const [isFeedbackPositive, setIsFeedbackPositive] = useState(() => {
    return meeting.feedback?.isPositive;
  });

  const updateFeedback = useCallback(
    async (payload: MeetingFeedbackInterface) => {
      const response = await updateMeetingFeedback.mutateAsync({
        companyUuid: currentCompany.uuid,
        payload,
        meetingUuid: meeting?.uuid,
      });

      if (!response) return;

      const feedbackMessage = t('feedback-messages.update-success');

      toast.success(feedbackMessage, {
        ...(DEFAULT_TOASTIFY_CONFIGURATION as ToastOptions),
      });

      return response;
    },
    [currentCompany, meeting, t, updateMeetingFeedback]
  );

  const onFeedbackButtonClicked = useCallback(
    async (isPositive: boolean) => {
      const payload = {isPositive};

      const response = await updateFeedback(payload);
      if (!response) return;

      setIsFeedbackPositive(response.isPositive);
    },
    [updateFeedback]
  );

  return (
    <div className={styles.wrapper}>
      <RoundedCard>
        <RoundedCard.Title>{t('title')}</RoundedCard.Title>

        <RoundedCard.Content>
          {t('question')}

          <ul className={styles.list}>
            <li>
              <Button
                variant={isFeedbackPositive ? 'primary' : 'secondary'}
                onClick={() => onFeedbackButtonClicked(true)}
              >
                {t('options.yes')}
              </Button>
            </li>

            <li>
              <Button
                variant={!isFeedbackPositive ? 'primary' : 'secondary'}
                onClick={() => onFeedbackButtonClicked(false)}
              >
                {t('options.no')}
              </Button>
            </li>
          </ul>
        </RoundedCard.Content>
      </RoundedCard>
    </div>
  );
};

export default MeetingFeedback;
