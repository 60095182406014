import {CircularProgressbar, buildStyles} from 'react-circular-progressbar';
import {FunctionComponent} from 'react';
import {SENTIMENTAL_ANALYSIS_COLORS} from '../../../../../../../../../../constants/sentimental-analysis';
import {SentimentalAnalysis as SentimentalAnalysisInterface} from '../../../../../../../../../../interfaces/meeting';
import {useTranslation} from 'react-i18next';
import RoundedCard from '../../../../../../../../../@components/rounded-card';
import styles from './styles.module.scss';

interface ElementProps {
  analysis: SentimentalAnalysisInterface;
}

const strokeWidth = 4;

const SentimentalAnalysis: FunctionComponent<ElementProps> = ({analysis}) => {
  const {t} = useTranslation('sense', {
    keyPrefix:
      'meetings.show.index.components.meeting-details.components.sentimental-analysis',
  });

  return (
    <RoundedCard variant="full-height">
      <RoundedCard.Title>{t('title')}</RoundedCard.Title>

      <RoundedCard.Content>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            <CircularProgressbar
              value={analysis.positive}
              text={t('percentage.label', {
                percentage: analysis.positive,
              })}
              className={styles.progressbar}
              strokeWidth={strokeWidth}
              styles={buildStyles(SENTIMENTAL_ANALYSIS_COLORS.POSITVE)}
            />
            {t('percentage.positive.label')}
          </li>

          <li className={styles.listItem}>
            <CircularProgressbar
              value={analysis.neutral}
              text={t('percentage.label', {
                percentage: analysis.neutral,
              })}
              className={styles.progressbar}
              strokeWidth={strokeWidth}
              styles={buildStyles(SENTIMENTAL_ANALYSIS_COLORS.NEUTRAL)}
            />
            {t('percentage.neutral.label')}
          </li>

          <li className={styles.listItem}>
            <CircularProgressbar
              value={analysis.negative}
              text={t('percentage.label', {
                percentage: analysis.negative,
              })}
              className={styles.progressbar}
              strokeWidth={strokeWidth}
              styles={buildStyles(SENTIMENTAL_ANALYSIS_COLORS.NEGATIVE)}
            />
            {t('percentage.negative.label')}
          </li>
        </ul>
      </RoundedCard.Content>
    </RoundedCard>
  );
};

export default SentimentalAnalysis;
