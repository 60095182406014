import {ORDER_BY_PARAMETERS} from '../../../constants/filtering/order-by';
import {sanitizeCompaniesFromRaw} from '../../../ui/@sanitizers/admin/company';
import {sanitizeMetaFromRaw} from '../../../ui/@sanitizers/meta';
import InstillAPI from '../axios-instance';
import isResponseSuccessful from '../../../utils/is-response-successful';

export interface FetchCompaniesPayload {
  limit?: number;
  offset?: number;
  query?: string;
  orderBy?: ORDER_BY_PARAMETERS;
  sortBy?: string;
}

export async function fetchCompanies(params: FetchCompaniesPayload) {
  let url = `/admin/companies`;
  let queryParams: string[] = [];

  if (params.offset) {
    queryParams.push(`skip=${params.offset}`);
  }

  if (params.limit) {
    queryParams.push(`limit=${params.limit}`);
  }

  if (params.orderBy) {
    queryParams.push(`order=${params.orderBy}`);
  }

  if (params.sortBy) {
    queryParams.push(`sort_by=${params.sortBy}`);
  }

  if (params.query) {
    queryParams.push(`q=${params.query}`);
  }

  if (queryParams) {
    url = `${url}?${queryParams.join('&')}`;
  }

  try {
    const response = await InstillAPI.get(url);

    if (!response.data || !isResponseSuccessful(response)) {
      throw Error('No data received');
    }

    return {
      data: sanitizeCompaniesFromRaw(response.data.data),
      meta: sanitizeMetaFromRaw(response.data.meta),
    };
  } catch (error) {
    console.error(error);
  }
}
