import {FunctionComponent} from 'react';
import {UserActionStep} from '../../../../../../interfaces/action-step';
import Table from '../../../../../@components/table';
import TableRow from './@components/table-row';

interface ElementProps {
  handleClick: (userActionStep: UserActionStep) => void;
  actionSteps: UserActionStep[];
  actionStepUuidInQueryParams?: string | null;
}

const TableBody: FunctionComponent<ElementProps> = ({
  handleClick,
  actionSteps,
  actionStepUuidInQueryParams,
}) => {
  return (
    <Table.Content>
      {actionSteps.map((actionStep) => (
        <TableRow
          handleClick={handleClick}
          key={actionStep.uuid}
          actionStep={actionStep}
          actionStepUuidInQueryParams={actionStepUuidInQueryParams}
        />
      ))}
    </Table.Content>
  );
};

export default TableBody;
