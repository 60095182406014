import {AuthUser} from '../../../interfaces/auth-user';
import {Company} from '../../../interfaces/company';
import {FunctionComponent, PropsWithChildren} from 'react';
import {getUserProfileForCompany} from '../../../services/user-profiles';
import {Location, Navigate, useLocation} from 'react-router';
import {useCurrentAuthUserState} from '../../@atoms/current-auth-user';
import {useSafeCurrentCompany} from '../../@atoms/current-company';

const ORGANIZATION_SETUP_PATH = 'organization-setup';
const USER_SETUP_PATH = 'user-setup';

const shouldRedirectToOrganizationSetup = (
  company: Company,
  authUser: AuthUser | null,
  currentLocation: Location
) => {
  if (company.creatorUuid !== authUser?.uuid) return false;
  if (company.isOnboarded) return false;
  if (currentLocation.pathname.includes(ORGANIZATION_SETUP_PATH)) return false;

  return true;
};

const shouldRedirectToUserSetup = (
  company: Company,
  authUser: AuthUser | null,
  currentLocation: Location
) => {
  if (company.creatorUuid === authUser?.uuid) return false;

  if (!authUser) return false;
  const userProfile = getUserProfileForCompany(authUser, company);

  if (userProfile.isOnboarded) return false;
  if (currentLocation.pathname.includes(USER_SETUP_PATH)) return false;

  return true;
};

const OnboardingFlow: FunctionComponent<PropsWithChildren<{}>> = ({
  children,
}) => {
  const currentCompany = useSafeCurrentCompany();
  const [currentAuthUser] = useCurrentAuthUserState();
  const location = useLocation();

  if (
    shouldRedirectToOrganizationSetup(currentCompany, currentAuthUser, location)
  ) {
    return <Navigate replace to={ORGANIZATION_SETUP_PATH} />;
  }

  if (shouldRedirectToUserSetup(currentCompany, currentAuthUser, location)) {
    return <Navigate replace to={USER_SETUP_PATH} />;
  }

  return <>{children}</>;
};

export default OnboardingFlow;
