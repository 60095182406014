import {FunctionComponent, useEffect, useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {useTranslation} from 'react-i18next';
import {EventName} from '../../../../../constants/analytics/event-name';
import {EventType} from '../../../../../constants/analytics/event-type';
import {buildPageTitle} from '../../../../../services/build-page-title';
import mainContainerStyles from '../../../../../styles/classes/main-container.module.scss';
import RoundedCard from '../../../../@components/rounded-card';
import useAnalytics from '../../../../@hooks/use-analytics';
import Header from '../../../@components/header';
import MainContent from '../../../@components/main-content';
import HeaderButton from './@components/header-button';
import InviteSenseDialog from './@components/invite-sense-dialog';
import MeetingsList from './@components/meetings-list';

const MeetingsIndex: FunctionComponent = () => {
  const {t} = useTranslation(['sense', 'applicationPageTitle']);

  const [isInviteSenseDialogVisible, setIsInviteSenseDialogVisible] =
    useState(false);

  const onOpenInviteSenseModal = () => {
    setIsInviteSenseDialogVisible(true);
  };

  const onCloseInviteSenseModal = () => {
    setIsInviteSenseDialogVisible(false);
  };

  const {trackEvent} = useAnalytics();

  useEffect(() => {
    trackEvent({
      eventName: EventName.PAGE_VIEWS.SENSE.MEETINGS.INDEX,
      eventType: EventType.PAGE_VIEW,
    });
  }, [trackEvent]);

  return (
    <>
      <Helmet>
        <title>
          {buildPageTitle([
            t('page-title.sense.meetings.index', {ns: 'applicationPageTitle'}),
            t('page-title.sense.index', {ns: 'applicationPageTitle'}),
          ])}
        </title>
      </Helmet>

      <Header title={t('components.header.title')}>
        <HeaderButton onClick={onOpenInviteSenseModal} />
      </Header>

      <MainContent>
        <main className={mainContainerStyles.container}>
          <RoundedCard>
            <MeetingsList />
          </RoundedCard>

          <InviteSenseDialog
            isDialogVisible={isInviteSenseDialogVisible}
            onClose={onCloseInviteSenseModal}
          />
        </main>
      </MainContent>
    </>
  );
};

export default MeetingsIndex;
