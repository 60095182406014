import {useCheckPermissionOrRedirect} from '../../../@hooks/use-check-permission-or-redirect';
import {Actions} from '../../../../constants/permissions/actions';
import {FunctionComponent} from 'react';
import {Navigate, Route, Routes} from 'react-router';
import ManageAssessmentsIndex from './index/manage-assessments-index';
import ManageAssessmentsShow from './show/manage-assessments-show';

const ManageAssessments: FunctionComponent = () => {
  const hasAccess = useCheckPermissionOrRedirect(
    Actions.VIEW_MANAGE_ASSESSMENTS,
    '/application/measure'
  );
  if (!hasAccess) return null;

  return (
    <>
      <Routes>
        <Route path="/" element={<ManageAssessmentsIndex />} />

        <Route path=":id" element={<ManageAssessmentsShow />} />

        <Route
          path="*"
          element={<Navigate replace to="/application/dashboard" />}
        />
      </Routes>
    </>
  );
};

export default ManageAssessments;
