import {EventName} from '../../../../../../constants/analytics/event-name';
import {EventType} from '../../../../../../constants/analytics/event-type';
import {Notification} from '../../../../../../interfaces/notification';
import {ReactComponent as IconBell} from '../../../../../@components/kit/icons/bell.svg';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useMarkNotificationAsRead} from '../../../../../@hooks/mutations';
import {useNotification as useNotificationContext} from '../../../../@contexts/notification-context';
import {useNotifications} from '../../../../../@hooks/queries';
import {useSafeCurrentCompany} from '../../../../../@atoms/current-company';
import List from './@components/list';
import styles from './styles.module.scss';
import useAnalytics from '../../../../../@hooks/use-analytics';
import usePagination from '../../../../../@hooks/use-pagination';

const ITEMS_PER_PAGE = 5;

const NotificationCenter = () => {
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const [hasUnreadNotifications, setHasUnreadNotifications] = useState(false);
  const [hasMoreDataToLoad, setHasMoreDataToLoad] = useState(true);
  const [notifications, setNotifications] = useState<Notification[]>();
  const [temporaryReadNotifications, setTemporaryReadNotifications] =
    useState<Notification[]>();

  const currentCompany = useSafeCurrentCompany();
  const {trackEvent} = useAnalytics();
  const {updatedPayload} = useNotificationContext();

  const {currentPage, offset, goToNextPage} = usePagination({
    itemsPerPage: ITEMS_PER_PAGE,
    updateQueryParameter: false,
  });

  const markNotificationsAsRead = useMarkNotificationAsRead();

  const notificationsHook = useNotifications({
    enabled: hasMoreDataToLoad,
    variables: {
      companyUuid: currentCompany.uuid,
      currentPage,
      itemsPerPage: ITEMS_PER_PAGE,
      limit: ITEMS_PER_PAGE,
      offset,
    },
    onSuccess: (response) => {
      if (!response?.data?.length) return;

      const newNotifications = notifications
        ? [...notifications, ...response.data]
        : [...response.data];

      setHasUnreadNotifications(response?.meta.extras.hasUnreadNotifications);

      if (response.meta?.totalEntries <= newNotifications?.length) {
        setHasMoreDataToLoad(false);
        setHasUnreadNotifications(false);

        onMarkNotificationsAsRead(newNotifications);
      }

      setNotifications(newNotifications);
    },
  });

  const onMarkNotificationsAsRead = useCallback(
    async (notifications: Notification[] | undefined) => {
      if (!notifications || !hasUnreadNotifications) return;

      const unreadNotifications = notifications.filter((notification) => {
        return !temporaryReadNotifications?.includes(notification);
      });

      setTemporaryReadNotifications(unreadNotifications);

      const notificationUuids = unreadNotifications.map(
        (notification) => notification.uuid
      );

      await markNotificationsAsRead.mutateAsync({
        companyUuid: currentCompany.uuid,
        notificationUuids,
      });
    },
    [
      currentCompany,
      hasUnreadNotifications,
      markNotificationsAsRead,
      temporaryReadNotifications,
    ]
  );

  const onLoadMoreButtonClicked = useCallback(() => {
    if (!hasMoreDataToLoad) return;
    if (notificationsHook.isLoading || !notificationsHook.data) return;

    trackEvent({
      eventName: EventName.COMPONENTS.NOTIFICATION_CENTER.LOAD_MORE,
      eventType: EventType.BUTTON_CLICKED,
    });

    goToNextPage();
    onMarkNotificationsAsRead(notifications);
  }, [
    goToNextPage,
    hasMoreDataToLoad,
    notifications,
    notificationsHook,
    onMarkNotificationsAsRead,
    trackEvent,
  ]);

  const onShowNotificationDropdown = useCallback(() => {
    trackEvent({
      eventName: EventName.COMPONENTS.NOTIFICATION_CENTER.SHOW_NOFICIATIONS,
      eventType: EventType.BUTTON_CLICKED,
    });

    setIsDropdownVisible(!isDropdownVisible);
  }, [trackEvent, isDropdownVisible]);

  const listContainerClassName = isDropdownVisible
    ? `${styles.listContainer} ${styles.isVisible}`
    : styles.listContainer;

  const unreadNotificationClassName = useMemo(() => {
    return hasUnreadNotifications
      ? `${styles.unreadNotifications} ${styles.isVisible}`
      : styles.unreadNotifications;
  }, [hasUnreadNotifications]);

  useEffect(() => {
    if (!updatedPayload) return;

    setHasUnreadNotifications(true);
    setNotifications((currentNotifications = []) => {
      return [updatedPayload, ...currentNotifications];
    });
  }, [updatedPayload]);

  if (!currentCompany.isOnboarded) {
    return <></>;
  }

  return (
    <div className={styles.container} ref={setContainer}>
      <button
        onClick={onShowNotificationDropdown}
        type="button"
        className={styles.button}
      >
        <IconBell className={styles.buttonIcon} />

        <div className={unreadNotificationClassName} />
      </button>

      <div className={listContainerClassName}>
        <List
          clickOutsideElement={container}
          hasMoreDataToLoad={hasMoreDataToLoad}
          notifications={notifications}
          onClose={() => setIsDropdownVisible(false)}
          onLoadMore={onLoadMoreButtonClicked}
        />
      </div>
    </div>
  );
};

export default NotificationCenter;
