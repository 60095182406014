import {formatDate} from '../../../../../../../../../../../utils/date';
import {FunctionComponent, useMemo} from 'react';
import {Link} from 'react-router-dom';
import {MeetingListItem} from '../../../../../../../../../../../interfaces/meeting';
import {Tooltip} from 'react-tooltip';
import {useTranslation} from 'react-i18next';
import formatDurationFromSeconds from '../../../../../../../../../../../utils/format-duration-from-seconds';
import styles from './styles.module.scss';
import Table from '../../../../../../../../../../@components/table';
import tableStyles from '../../../../../../../../../../../styles/classes/table.module.scss';

interface ElementProps {
  meeting: MeetingListItem;
}

const MAXIMUM_NUMBER_OF_PARTICIPANTS = 5;
const MAXIMUM_NUMBER_OF_VISIBLE_PARTICIPANT_NAMES = 2;
const TOOLTIP_IDENTIFIER = 'meeting-more-participants-tooltip';

const TableRow: FunctionComponent<ElementProps> = ({meeting}) => {
  const {t} = useTranslation('sense', {
    keyPrefix: 'meetings.index.components.meeting-list.components.list-view',
  });

  const participants = useMemo(() => {
    const participantsLength = meeting.participantsNames.length;
    const sortedList = meeting.participantsNames.sort();

    if (participantsLength >= MAXIMUM_NUMBER_OF_PARTICIPANTS) {
      return t('components.table-row.participants.more-than-five', {
        firstTwoParticipantsName: sortedList
          .slice(0, MAXIMUM_NUMBER_OF_VISIBLE_PARTICIPANT_NAMES)
          .join(', '),
        remainingParticipantCount:
          participantsLength - MAXIMUM_NUMBER_OF_VISIBLE_PARTICIPANT_NAMES,
      });
    }

    return sortedList.join(', ');
  }, [meeting, t]);

  return (
    <Table.Row className={tableStyles.tableRow}>
      <Table.Data variant="nameColumn" className={tableStyles.tableData}>
        <div className={tableStyles.mobileHeaderTitle}>{t('header.name')}</div>

        {meeting.name}
      </Table.Data>

      <Table.Data variant="medium" className={tableStyles.tableData}>
        <div className={tableStyles.mobileHeaderTitle}>{t('header.date')}</div>

        {formatDate(meeting.startDate)}
      </Table.Data>

      <Table.Data variant="large" className={tableStyles.tableData}>
        <div className={tableStyles.mobileHeaderTitle}>
          {t('header.participants')}
        </div>

        <div
          data-tooltip-id={TOOLTIP_IDENTIFIER}
          data-tooltip-content={meeting.participantsNames.sort().join(', ')}
        >
          {participants}

          <Tooltip
            className={styles.tooltipContainer}
            id={TOOLTIP_IDENTIFIER}
            clickable
          />
        </div>
      </Table.Data>

      <Table.Data variant="medium" className={tableStyles.tableData}>
        <div className={tableStyles.mobileHeaderTitle}>
          {t('header.duration')}
        </div>

        {formatDurationFromSeconds(meeting.duration)}
      </Table.Data>

      <Table.Data variant="small" className={tableStyles.tableData}>
        <Link to={`${meeting.uuid}`} className={styles.link}>
          {t('components.table-row.see-details-button')}
        </Link>
      </Table.Data>
    </Table.Row>
  );
};

export default TableRow;
