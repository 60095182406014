import {FunctionComponent} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useCurrentFullUserProfile} from '../../../../../@atoms/current-full-user-profile';
import Button from '../../../../../@components/kit/form/button';
import Dialog from '../../../../../@components/kit/dialog';
import FormFooter from '../../../../../@components/kit/form/form-footer';

interface ElementProps {
  isVisible: boolean;
  onClose: () => void;
}

const RemainingKudoDialog: FunctionComponent<ElementProps> = ({
  isVisible,
  onClose,
}) => {
  const {t} = useTranslation('application');

  const currentFullUserProfile = useCurrentFullUserProfile();
  const remainingKudos = currentFullUserProfile.kudosRemaining ?? 0;

  return (
    <Dialog isOpen={isVisible} onClose={onClose}>
      <Dialog.Header
        title={t(
          'components.kudos-list.components.remaining-kudo-dialog.title'
        )}
      />

      <Dialog.Content>
        {remainingKudos > 0 ? (
          <Trans
            t={t}
            i18nKey="components.kudos-list.components.remaining-kudo-dialog.message"
            components={{bold: <b />}}
            values={{
              kudoLeft: remainingKudos,
            }}
          />
        ) : (
          <>
            {t(
              'components.kudos-list.components.remaining-kudo-dialog.no-kudos-left'
            )}
          </>
        )}

        <FormFooter>
          <Button onClick={onClose}>
            {t(
              'components.kudos-list.components.remaining-kudo-dialog.confirm-button'
            )}
          </Button>
        </FormFooter>
      </Dialog.Content>
    </Dialog>
  );
};

export default RemainingKudoDialog;
