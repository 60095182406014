import {createQuery} from 'react-query-kit';
import {
  FetchTeamsPayload,
  fetchTeams,
} from '../../../repositories/instill/queries/fetch-teams';
import {Meta} from '../../../interfaces/meta';
import {Team} from '../../../interfaces/team';
import getOffsetValue from '../../../utils/get-offset-value';

interface FetchTeams {
  data: Team[] | undefined;
  meta: Meta;
}

export const useTeams = createQuery<FetchTeams | undefined, FetchTeamsPayload>({
  primaryKey: 'teams',
  queryFn: async ({queryKey: [_, variables]}) => {
    let payload = {
      offset: getOffsetValue({
        offset: variables.offset,
        currentPage: variables.currentPage ?? 0,
        itemsPerPage: variables.itemsPerPage ?? 0,
      }),
      limit: variables.limit,
      companyUuid: variables.companyUuid,
    } as FetchTeamsPayload;

    if (variables.sortBy) {
      payload['sortBy'] = variables.sortBy;
    }

    if (variables.orderBy) {
      payload['orderBy'] = variables.orderBy;
    }

    if (variables.query) {
      payload['query'] = variables.query;
    }

    const response = await fetchTeams(payload);
    if (!response) return;

    return {
      data: response.data,
      meta: response.meta,
    };
  },
});
