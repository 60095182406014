import InstillAPI from '../axios-instance';
import {sanitizeCompanyFromRaw} from '../../../ui/@sanitizers/company';

export interface JoinCompanyPayload {
  inviteToken: string;
}

export async function joinCompany(inviteToken: string) {
  const response = await InstillAPI.post(`/user-invites/${inviteToken}/join`);

  return sanitizeCompanyFromRaw(response.data);
}
