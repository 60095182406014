import {
  CompanyCoreValue,
  CoreValueForm,
} from '../../../../../../interfaces/company-core-value';
import {FunctionComponent, useCallback, useState} from 'react';
import {Form, Formik} from 'formik';
import {sanitizeExpectationsToExpectationsForm} from '../../../../../@sanitizers/expectations';
import {useTranslation} from 'react-i18next';
import ExpectationList from './@components/expectation-list';
import Header from './@components/header';
import KudosList, {KUDOS_SOURCES} from '../../../../@components/kudos-list';
import MediaContent from '../../../../@components/media-content/media-content';
import MediaForm from './@components/media-form';
import quickPollSource from './assets/quick-poll.png';
import styles from './styles.module.scss';
import Tag from '../../../../../@components/tag/tag';
import ValueForm from './@components/value-form/value-form';
import valuesCheckupSource from './assets/values-checkup.png';
import {EventName} from '../../../../../../constants/analytics/event-name';
import RichTextContainer from '../../../../../@components/rich-text-container';

interface ElementProps {
  value: CompanyCoreValue;
  onDelete: () => void;
  onEditValueConfirm: (coreValue: CoreValueForm) => void;
}

const MAXIMUM_NUMBER_OF_KUDOS = 5;

const Value: FunctionComponent<ElementProps> = ({
  onDelete,
  onEditValueConfirm,
  value,
}) => {
  const {t} = useTranslation('values');

  const [isEditMode, setIsEditMode] = useState(false);

  const currentValueRender = (
    <>
      <div className={styles.row}>
        <div className={styles.rowHeader}>
          <div className={styles.rowHeaderTitle}>
            {t('components.value.description')}
          </div>
        </div>

        <div className={styles.rowContent}>
          <RichTextContainer value={value.description} />
        </div>
      </div>

      {value?.expectations && value.expectations.length > 0 && (
        <div className={styles.row}>
          <div className={styles.rowHeader}>
            <div className={styles.rowHeaderTitle}>
              {t('components.value.behaviours')}
            </div>
          </div>

          <div className={styles.rowContent}>
            <ExpectationList expectations={value.expectations} />
          </div>
        </div>
      )}
    </>
  );

  const handleEdit = useCallback(
    async (value: CoreValueForm) => {
      await onEditValueConfirm(value);
    },
    [onEditValueConfirm]
  );

  return (
    <>
      <Formik
        initialValues={{
          coreValue: {
            name: value.name,
            description: value.description,
            expectations: sanitizeExpectationsToExpectationsForm(
              value!.expectations || []
            ),
            media: value.media.length > 0 ? value.media : [],
          },
        }}
        onSubmit={(values) => {
          handleEdit(values.coreValue);
        }}
      >
        {({values}) => (
          <Form>
            <Header
              isEditMode={isEditMode}
              onDelete={onDelete}
              setIsEditMode={setIsEditMode}
            />

            <div className={styles.container}>
              <div className={styles.column}>
                {isEditMode
                  ? value && <ValueForm formValues={values.coreValue} />
                  : currentValueRender}

                <div className={`${styles.row} ${styles.dimmed}`}>
                  <div className={styles.rowHeader}>
                    <div className={styles.rowHeaderTitle}>
                      {t('components.value.values-checkup')}
                    </div>

                    <Tag variant="light-blue">{t('constants.coming-soon')}</Tag>
                  </div>

                  <div className={styles.rowContent}>
                    <img src={valuesCheckupSource} alt="" />
                  </div>
                </div>
              </div>

              <div className={styles.column}>
                {!isEditMode && value.media.length > 0 ? (
                  <div className={styles.row}>
                    <div className={styles.rowHeader}>
                      <div className={styles.rowHeaderTitle}>
                        {t('components.value.media')}
                      </div>
                    </div>

                    <div className={styles.rowContent}>
                      <MediaContent media={value.media} />
                    </div>
                  </div>
                ) : null}

                {isEditMode && <MediaForm formValues={values.coreValue} />}

                <div className={styles.row}>
                  <div className={styles.rowHeader}>
                    <div className={styles.rowHeaderTitle}>
                      {t('components.value.activity-feed')}
                    </div>
                  </div>

                  <div className={styles.rowContent}>
                    <div className={styles.kudosContainer}>
                      <KudosList
                        maximumNumberOfKudos={MAXIMUM_NUMBER_OF_KUDOS}
                        prefixEventName={EventName.PREFIXES.VALUES_SHOW}
                        source={KUDOS_SOURCES.VALUE}
                        value={value}
                      />
                    </div>
                  </div>
                </div>

                <div className={`${styles.row} ${styles.dimmed}`}>
                  <div className={styles.rowHeader}>
                    <div className={styles.rowHeaderTitle}>
                      {t('components.value.quick-poll')}
                    </div>

                    <Tag variant="light-blue">{t('constants.coming-soon')}</Tag>
                  </div>

                  <div className={styles.rowContent}>
                    <img src={quickPollSource} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Value;
