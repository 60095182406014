import {FunctionComponent} from 'react';
import {useTransition, animated} from 'react-spring';
import styles from './styles.module.scss';
import useEscape from '../../../../../@hooks/use-escape';
import Navigation from '../../../sidebar-navigation';

interface ElementProps {
  isOpen: boolean;
  onClose: () => void;
}

const Menu: FunctionComponent<ElementProps> = ({isOpen, onClose}) => {
  useEscape(onClose);

  const transitions = useTransition(isOpen, {
    from: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
    },
    leave: {
      opacity: 0,
    },
  });

  return (
    <>
      {transitions(
        (props, item) =>
          item && (
            <animated.div className={styles.container} style={props}>
              <Navigation variant="mobile" onNavigationLinkClicked={onClose} />
            </animated.div>
          )
      )}
    </>
  );
};

export default Menu;
