import {FunctionComponent} from 'react';
import {TIMESCALE_VALUES} from '../../../../../../../../../../constants/timescale-value';
import styles from './styles.module.scss';

interface ElementProps {
  elementIdentifier: TIMESCALE_VALUES;
  label: string;
  onClick: (value: TIMESCALE_VALUES) => void;
  selectedElement?: TIMESCALE_VALUES;
}

const Item: FunctionComponent<ElementProps> = ({
  elementIdentifier,
  label,
  selectedElement,
  onClick,
}) => {
  const selectedClass =
    selectedElement === elementIdentifier ? styles.selected : null;

  return (
    <button
      onClick={() => onClick(elementIdentifier)}
      type="button"
      className={`${styles.button} ${selectedClass}`}
    >
      {label}
    </button>
  );
};

export default Item;
