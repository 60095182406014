import {FunctionComponent} from 'react';
import {useSafeCurrentCompany} from '../../../../../../../../../@atoms/current-company';
import {useTranslation} from 'react-i18next';
import {ValueAlignment} from '../../../../../../../../../../interfaces/meeting';
import ListItem from './@components/list-item';
import RoundedCard from '../../../../../../../../../@components/rounded-card';
import styles from './styles.module.scss';

interface ElementProps {
  values: ValueAlignment[];
}

const ValuesAlignment: FunctionComponent<ElementProps> = ({values}) => {
  const {t} = useTranslation('sense', {
    keyPrefix:
      'meetings.show.index.components.meeting-details.components.values-alignment',
  });

  const currentCompany = useSafeCurrentCompany();

  return (
    <div className={styles.wrapper}>
      <RoundedCard>
        <RoundedCard.Title>
          {t('title', {
            companyName: currentCompany.name,
          })}
        </RoundedCard.Title>

        <RoundedCard.Content>
          <ul className={styles.list}>
            {values.map((value, index) => (
              <ListItem key={index} value={value} />
            ))}
          </ul>
        </RoundedCard.Content>
      </RoundedCard>
    </div>
  );
};

export default ValuesAlignment;
