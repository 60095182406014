export enum POPULATION {
  ALL_INVITED = 'ALL_INVITED',
  NOT_COMPLETED = 'NOT_COMPLETED',
}

export const ASSESSMENT_POPULATION = [
  {
    label: 'constants.assessment-population.all',
    value: POPULATION.ALL_INVITED,
  },
  {
    label: 'constants.assessment-population.not-yet-finish',
    value: POPULATION.NOT_COMPLETED,
  },
];
